import Collapse from "../../Collapse";
import {CompanyTables} from "./CompanyTables";

export const CommunicationsAndReporting = ({glossaryData, data, refCommunications, translations}) => {

    return <Collapse
        title={translations?.['communications_and_reporting.communications_and_reporting.headers.communications_and_reporting']}
        glossaryData={glossaryData}
        currentSectionName={translations?.['communications_and_reporting.communications_and_reporting.headers.communications_and_reporting']}
        currentRef={refCommunications}
        moreInfoTitle={translations?.['communications_and_reporting.communications_and_reporting.titles.more_info']}
        moreInfoData={translations?.['communications_and_reporting.communications_and_reporting.more_info.communications_and_reporting']}
    >
        {
            Object.keys(data?.contact_details).map((key)=>(
                <CompanyTables
                    key={key}
                    id={data?.id}
                    adbData={data?.contact_details[key]}
                    companyName={data?.contact_details[key].title?.toUpperCase()}
                    hasNameUpdate={key !== 'adb' && key !== 'dispute_board_members'}
                    nameKey={key}
                    translations={translations}
                />
            ))
        }
    </Collapse>
};
