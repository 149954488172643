import React from 'react';
import './index.scss';

const Modal = ({closeModal, ...props}) => {
    const {
        title,
        ref,
        children,
        className
    } = props

    return(
        <div className={`modal ${className}`} ref={ref}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5>{title}</h5>
                    <div
                        onClick={() => {
                            closeModal(false);
                        }}
                        className="modal-close"
                    >
                        <img src="/assets/icons/close-mark.svg" alt="close" />
                    </div>
                </div>
                {children}
            </div>
            <div
                onClick={() => {
                    closeModal(false);
                }}
                className="modal-bg"
            ></div>
        </div>
    )
}

export default Modal;