import React, {useEffect} from 'react';
import {useSelector} from "react-redux";

function QuarterlyFinancingPlan({pageTranslations}) {
	const financing_plan = useSelector(state => state?.quarterlyReducer?.online_view?.sections?.financing_plan) ?? null;
	const sections_title = useSelector(state => state?.quarterlyReducer?.sections_title) ?? null;
	
	if (!financing_plan) {
		return <div></div>
	}
	
	return (
			<div className="online__content-table">
				<p className="online__content-boldTitle">
					2. {pageTranslations?.['financing_plan.index.headers.financing_plan']}
				</p>
				<div className={"quarterly-online-table"}>
					<table border="1">
						<thead>
						<tr className={"row_gray"}>
							<th>{pageTranslations?.['financing_plan.index.table_headers.source_of_fund']}</th>
							<th>{pageTranslations?.['financing_plan.index.table_headers.description']}</th>
							<th>{pageTranslations?.['financing_plan.index.table_headers.amount_millions']}</th>
							<th>{pageTranslations?.['financing_plan.index.table_headers.share_percent']}</th>
						</tr>
						</thead>
						<tbody>
						{financing_plan?.data?.map((item, idx) => (
								<tr key={idx}>
									<td>{item?.source_of_fund}</td>
									<td>{item?.description}</td>
									<td>${item?.amount}</td>
									<td>{item?.share}</td>
								</tr>
						))}
						<tr>
							<td></td>
							<td className={"row_gray"}>{pageTranslations?.['financing_plan.index.titles.total_project_cost']}</td>
							<td>{financing_plan?.total_amount}</td>
							<td>{financing_plan?.total_percent}</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>
	);
}

export default QuarterlyFinancingPlan;
