import React, {useState} from 'react';
import useDebounce from "../../global/useDebounce";
import {useLazyGetSystemErrorsQuery} from "../../store/service/adbService";
import Table from "./Table";
import Toolbar from "./Toolbar";

const SystemErrors = ({translations}) => {
    const [event, setEvent] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchErrorCode, setSearchErrorCode] = useState('');
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    const debouncedSearchTerm = useDebounce(searchTerm, 500);
    const debouncedSearchErrorCode = useDebounce(searchErrorCode, 500);

    const tableHeader = [
        translations["system_errors.index.table_headers.first_name"],
        translations["system_errors.index.table_headers.last_name"],
        translations["system_errors.index.table_headers.company_name"],
        translations["system_errors.index.table_headers.timestamp"],
        translations["system_errors.index.table_headers.location"],
        translations["system_errors.index.table_headers.device"],
        translations["system_errors.index.table_headers.error_code"],
        translations["system_errors.index.table_headers.error_message"],
        translations["system_errors.index.table_headers.referer"]
    ];
    const dataFetcher = useLazyGetSystemErrorsQuery;

    const exportEndpoint =`/audit-log/system-errors/export/csv?`;

    return (
        <>
            <Toolbar
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                startDate={startDate}
                endDate={endDate}
                setDateRange={setDateRange}
                translations={translations}
                setEvent={setEvent}
                event={event}
                exportEndpoint={exportEndpoint}
                isHasSelect={false}
                isSearchErrorCode={true}
                searchErrorCode={searchErrorCode}
                setSearchErrorCode={setSearchErrorCode}
                debouncedSearchTerm={debouncedSearchTerm}
                debouncedSearchErrorCode={debouncedSearchErrorCode}
                exportFileName='system-errors'
                searchPlaceholder={translations['system_errors.index.actions.search_by_name_company']}
                searchErrorCPlaceholder={translations['system_errors.index.actions.error_code']}
            />
            <Table
                dateRange={dateRange}
                tableHeader={tableHeader}
                dataFetcher={dataFetcher}
                searchErrorCode={searchErrorCode}
                debouncedSearchTerm={debouncedSearchTerm}
                debouncedSearchErrorCode={debouncedSearchErrorCode}
                emptyDataText={"No Activities"}
            />
        </>
    );
}

export default SystemErrors;
