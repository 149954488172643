import moment from "moment";
import Select from "react-select";
import {useSelector} from "react-redux";
import React, {useEffect, useState} from 'react';

const customStyles = {
	menu: (provided, state = false) => ({
		...provided,
		width: "100%",
		color: state.selectProps.menuColor,
		cursor: "pointer",
		zIndex: 50,
		overflow: "hidden",
	}),

	control: (_, { selectProps: {  } }) => ({
		width: '100%',
		border: "1px solid rgba(0, 0, 0, 0.1)",
		display: "flex",
		background: "#FFFFFF",
		borderRadius: 8,
		padding: 8,
	}),
};

function QuarterlyHeader({handleChangeDate, selectedDate, pageTranslations}) {
	const [formattedDate, setFormattedDate] = useState(null)
	const localeLan = localStorage.getItem("local_lan" );
	moment.locale(localeLan);

	const data = useSelector(state => state?.quarterlyReducer?.online_view);
	const submitted_reports = useSelector(state => state?.quarterlyReducer?.submitted_reports);
	useEffect(()=>{
		setFormattedDate (selectedDate
			? selectedDate
			: {
				label: data?.report_date
					? moment(data.report_date, "D MMMM YYYY", true).isValid()
						? moment(data.report_date, "D MMMM YYYY")?.format("DD/MM/yyyy")
						: moment(data.report_date)?.format("DD/MM/yyyy")
					: null
				,
				value: data?.id
			}
		);

	},[data, selectedDate])

	return (
			<div className="online__top">
				<h1 className="online__title">
					{pageTranslations?.['index.index.headers.quarterly_reports']}
				</h1>
				<div className="online__calendar reporting-select">
					<Select
						options={submitted_reports}
						value={formattedDate}
						styles={{
							...customStyles,
							menuPortal: (base) => ({ ...base, zIndex: 9999 }),
						}}
						isSearchable={true}
						className='select_input'
						placeholder={pageTranslations?.['index.index.actions.click_to_select_a_date']}
						onChange={(date) => handleChangeDate(date)}
					/>
				</div>
			</div>
	);
}

export default QuarterlyHeader;
