import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { useErrorHandler } from "../../../global/ErrorsHandler";
import {useGetSectorQuery, useLazyGetAllCountriesQuery, useLazyGetContractTypeQuery,} from "../../../store/service/adbService";
import { setLoader } from "../../../store/slice/dashboard";

const customStyles = {
    menu: (provided, state = false) => ({
        ...provided,
        width: "100%",
        color: state.selectProps.menuColor,
        cursor: "pointer",
    }),

    control: (_, { selectProps: { width } }) => ({
        width: "100%",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        display: "flex",
        marginTop: 8,
        background: "#F9F9FB",
        borderRadius: 8,
        padding: 2,
        cursor: "pointer",
    }),
};

export const EditProjectStep1 = ({setSteps, data, translations}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const allEnterDatas = JSON.parse(localStorage.getItem("all-edit-data"));
    const enterDatas = JSON.parse(localStorage.getItem("edit-data"));
    const [dataTr, setDataTr] = useState([])
    const [state, setState] = useState({
        contract_title: enterDatas?.contract_title ?? allEnterDatas?.contract_title ?? "",
        contract_number: enterDatas?.contract_number ?? allEnterDatas?.contract_number ?? "",
        contract_type_id: enterDatas?.contract_type_id ?? {value: allEnterDatas?.contract_details?.contract_type?.id, label: allEnterDatas?.contract_details?.contract_type?.name} ??  null,
        contract_project_summary: enterDatas?.contract_project_summary ?? allEnterDatas?.contract_project_summary ?? "",
        contract_scope: enterDatas?.contract_scope ?? allEnterDatas?.contract_details?.contract_scope ?? "",
        contract_location: enterDatas?.contract_location ?? allEnterDatas?.contract_location ?? "",
        country_id: {value: allEnterDatas?.contract_details?.country?.id, label: allEnterDatas?.contract_details?.country?.name} ?? enterDatas?.country_id ?? null,
        original_contract_amount: enterDatas?.original_contract_amount ?? allEnterDatas?.contract_details?.original_contract_amount ?? "",
        sector_id: {value: allEnterDatas?.contract_details?.sector?.id, label: allEnterDatas?.contract_details?.sector?.name} ?? enterDatas?.sector_id  ??  null
    });

    const [contractType, setContractType] = useState(null);
    const [countries, setCountries] = useState(null);
    const [sector, setSector] = useState(null);

    const {
        data: sectorData,
        isError: sectorDataIsError,
        isLoading: sectorDataIsLoading,
        isSuccess: sectorDataIsSuccess,
        error: sectorDatasError,
    } = useGetSectorQuery();

    const [getContractTypes, {
        data: contractTypes,
        isError: contractTypesIsError,
        isLoading: contractTypesIsLoading,
        isSuccess: contractTypesIsSuccess,
        error: contractTypesError,
    }] = useLazyGetContractTypeQuery();

    useEffect(() => {
        if(translations){
            setDataTr(translations);
        }
    }, [translations])

    useEffect(() => {
        getContractTypes({
            per_page: 1000,
        })
    }, [])

    const [getCountries, {
        data: allCountries,
        isError: countriesIsError,
        isLoading: countriesIsLoading,
        isSuccess: countriesIsSuccess,
        error: countriesError,
    }] = useLazyGetAllCountriesQuery();

    useEffect(() => {
        getCountries({
            per_page: 1000,
        })
    }, [])

    const [errorHandler, setErrorHandler] = useErrorHandler([
        contractTypesError,
        countriesError,
    ]);

    useEffect(() => {
        selectValues(contractTypes?.data?.data, setContractType);
        selectValues(allCountries?.data?.data, setCountries);
        selectValues(sectorData?.data?.data, setSector);
    }, [contractTypes, allCountries, sectorData]);

    const selectValues = (data, setter) => {
        if (data) {
            let selectData = data.map((item) => ({
                value: item.id,
                label: item.name,
            }));
            setter(selectData);
        }
    };

    useEffect(() => {
        contractTypesIsLoading || countriesIsLoading
            ? dispatch(setLoader(true))
            : dispatch(setLoader(false));
    }, [contractTypesIsLoading, countriesIsLoading]);

    const inputHandler = (e) => {
        setState((old) => ({ ...old, [e.target.id]: e.target.value }));
    };

    const submitHandler = (e) => {
        e.preventDefault();
        let body = {
            contract_title: state?.contract_title,
            contract_number: state?.contract_number,
            contract_type_id: state?.contract_type_id,
            contract_project_summary: state?.contract_project_summary,
            contract_scope: state?.contract_scope,
            contract_location: state?.contract_location,
            country_id: state?.country_id?.value,
            original_contract_amount: +state?.original_contract_amount?.toString()?.split(',').join(''),
            sector_id: state?.sector_id
        };
        let s = [];

        for (let key in body) {
            if (!body[key] || body[key] === "Invalid date" || body[key] === "") {
                toast.error("Please enter " + key?.split('_')?.join(' '), {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
                s.push("false");
            }
        }
        let validateTitle = location?.state
            ? location?.state?.allProjects?.data?.filter(
                (item) => item.project_name === state.contract_title
            )
            : true;

        if (validateTitle?.length > 0) {
            toast.error("The contract title has already been taken.", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }
        let validateTitle2 =
            validateTitle === true ? true : validateTitle?.length === 0;

        if (s.length === 0 && validateTitle2) {
            localStorage.setItem("edit-data", JSON.stringify(body));
            // navigate("/create-project/step-2");
            setSteps(2)
        }
    };

    return (
        <form className="create__form">
            <div className="create__form-content">
                <div className="create__form-row">
                    <div className="create__form-label">{dataTr?.['main.edit_projects_details.titles.contract_title']}</div>
                    <div className="create__form-input">
                        <input
                            type="text"
                            placeholder="Text"
                            id="contract_title"
                            defaultValue={state?.contract_title}
                            onChange={inputHandler}
                            maxLength={190}
                        />
                    </div>
                </div>
                <div className="create__form-row">
                    <div className="create__form-label">{dataTr?.['main.edit_projects_details.titles.contract_number']}</div>
                    <div className="create__form-input">
                        <input
                            type="text"
                            placeholder="Number"
                            id="contract_number"
                            defaultValue={state?.contract_number}
                            onChange={inputHandler}
                            maxLength={190}
                        />
                    </div>
                </div>
                <div className="create__form-row">
                    <div className="create__form-label">{dataTr?.['main.edit_projects_details.titles.contract_type']}</div>
                    <div className="create__form-input">
                        <Select
                            menuPortalTarget={document.body}
                            styles={({...customStyles, menuPortal: base => ({...base, zIndex: 9999})})}
                            options={contractType}
                            value={state?.contract_type_id}
                            onChange={(selected) =>
                                setState((old) => ({ ...old, contract_type_id: selected }))
                            }
                        />
                    </div>
                </div>
                <div className="create__form-row">
                    <div className="create__form-label">{dataTr?.['main.edit_projects_details.titles.sector']}</div>
                    <div className="create__form-input">
                        <Select
                            menuPortalTarget={document.body}
                            styles={({...customStyles, menuPortal: base => ({...base, zIndex: 9999})})}
                            options={sector}
                            value={state?.sector_id}
                            onChange={(selected) =>
                                setState((old) => ({ ...old, sector_id: selected }))
                            }
                        />
                    </div>
                </div>
                <div className="create__form-row">
                    <div className="create__form-label">{dataTr?.['main.edit_projects_details.titles.contract_project_summary']}</div>
                    <div className="create__form-input">
            <textarea
                rows={3}
                type="text"
                placeholder="Text"
                id="contract_project_summary"
                defaultValue={state?.contract_project_summary}
                onChange={inputHandler}
                maxLength={2500}
            />
                    </div>
                </div>
                <div className="create__form-row">
                    <div className="create__form-label">{dataTr?.['main.edit_projects_details.titles.contract_scope']}</div>
                    <div className="create__form-input">
                        <input
                            type="text"
                            placeholder="Text"
                            id="contract_scope"
                            defaultValue={state?.contract_scope}
                            onChange={inputHandler}
                            maxLength={190}
                        />
                    </div>
                </div>
                <div className="create__form-row">
                    <div className="create__form-label">{dataTr?.['main.edit_projects_details.titles.location_of_site']}</div>
                    <div className="create__form-input">
                        <input
                            type="text"
                            placeholder="Text"
                            id="contract_location"
                            defaultValue={state?.contract_location}
                            onChange={inputHandler}
                            maxLength={190}
                        />
                    </div>
                </div>
                <div className="create__form-row">
                    <div className="create__form-label">{dataTr?.['main.edit_projects_details.titles.country']}</div>
                    <div className="create__form-input">
                        <Select
                            menuPortalTarget={document.body}
                            styles={({...customStyles, menuPortal: base => ({...base, zIndex: 9999})})}
                            options={countries}
                            value={state?.country_id}
                            onChange={(selected) =>
                                setState((old) => ({ ...old, country_id: selected }))
                            }
                        />
                    </div>
                </div>
                <div className="create__form-row">
                    <div className="create__form-label">{dataTr?.['main.edit_projects_details.titles.original_contract_amount']}</div>
                    <div className="create__form-input amount">
                        <div>USD</div>
                        <input
                            placeholder="$ Enter amount"
                            id="original_contract_amount"
                            value={state?.original_contract_amount}
                            onChange={(e) => {
                                let newValue = e.target.value.replace(/,/g, "");
                                let abc = newValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                let formattedValue = abc.replace(/[^\d,]+/g, '')
                                setState((old) => ({
                                    ...old,
                                    original_contract_amount: formattedValue,
                                }));
                            }}
                            maxLength={190}
                        />
                    </div>
                </div>
            </div>
            <div className="create__form-br"></div>
            <div className="create__form-btns">
                <div
                    className="create__form-back"
                    onClick={() => {
                        localStorage.removeItem("create-data");
                        navigate(`/project/${data?.id}`);
                    }}
                >
                    {dataTr?.['main.edit_projects_details.actions.back']}
                </div>
                <button onClick={submitHandler} className="create__form-next">
                    <span>{dataTr?.['main.edit_projects_details.actions.next']}</span>
                    <span>
            <img src="/assets/icons/next-icon.svg" alt="icon" />
          </span>
                </button>
            </div>
        </form>
    );
};
