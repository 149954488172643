import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	online_view: null,
	sections_title: null,
	submitted_reports: null,
	side_bar: null,
};

const quarterlySlice = createSlice({
	name: 'quarterly',
	initialState,
	reducers: {
		setOnlineView(state, action) {
			state.online_view = action.payload;
		},
		setSidebar(state, action) {
			state.side_bar = action.payload;
		},
		setSectionsTitle(state, action) {
			state.sections_title = action.payload;
		},
		setSubmittedReports(state, action) {
			state.submitted_reports = action.payload;
		}
	}
})

export const {
	setOnlineView,
	setSectionsTitle,
	setSubmittedReports,
	setSidebar
} = quarterlySlice.actions

export default quarterlySlice.reducer
