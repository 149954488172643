import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {rememberDevice} from "../../../store/slice/mfAuth";
import {useLazyGetRememberDevicePageQuery} from "../../../store/service/adbService";
import AuthWrapper from "../../../components/AuthWrapper";
import AxelCheckbox from "../../../UI/AxelCheckbox";
import './../index.scss';

const RememberDevice = ({    }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [pageData, setPageData] = useState({});
    const [isChecked, setIsChecked] = useState(false);

    const [getRememberDevicePageData] = useLazyGetRememberDevicePageQuery();
    const mfaData = useSelector((state) => state.mfaReducer.mfaData);
    const mfaToken = localStorage.getItem("mfa_token")
    const isRememberDevice = useSelector((state) => state.mfaReducer.remember_device);
    const checkboxHandler =(id, isChecked) => {
        setIsChecked( isChecked);
    };

    const rememberDevice30Days = (isRemember) => {
        const rememberDeviceData ={
            isRemember:isRemember,
            startedTimeStamp : new Date(),
        }
        localStorage.setItem('rememberDevice',  JSON.stringify(rememberDeviceData));
    }

    let submitHandler = () => {
        localStorage.setItem("user_token", mfaData?.token);
        rememberDevice30Days(isChecked);
        dispatch(rememberDevice(false))
        navigate("/project-list");
    }

    useEffect(() => {
        if (isRememberDevice){
            getRememberDevicePageData(mfaToken)
                .unwrap()
                .then(fulfilled => {
                    setPageData(fulfilled?.data?.translations)
                })
                .catch(rejected => console.error(rejected))
        }
        else {
            navigate("/login");
        }
    }, []);

    return(
        <>
            { Object.keys(pageData).length > 0 && (
                <AuthWrapper className='rememberDevice'>
                    <h1 className="auth__title mf-auth__title">{pageData['actions.remember_my_device']}</h1>
                    <p className="auth__descr mf-auth__description">
                        {pageData['titles.by_clicking_remember_device']}
                    </p>
                    <p className="auth__descr mf-auth__description">
                        {pageData['titles.for_security_purposes']}
                    </p>
                    <div className="mf-auth__checkBoxContainer">
                        <AxelCheckbox
                            id="remember-device"
                            className="mf-auth__inputContainer__inputField"
                            placeholder="Enter code"
                            checked={isChecked}
                            handler={checkboxHandler}
                        />
                        <p>{pageData['actions.remember_my_device']}</p>
                    </div>

                    <button
                        className={`mf-auth__submitBtn mf-auth__submitBtn_allow`}
                        onClick={submitHandler}>
                        {pageData['actions.continue']}
                    </button>

                </AuthWrapper>
                )
            }
        </>
    )
}

export default RememberDevice;