import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    filteredData: null,
    statisticData: null
};

const statisticSlice = createSlice({
    name: 'management-dashboard',
    initialState,
    reducers: {
        setFilteredData(state, action) {
            state.filteredData = action.payload
        },
        setStatisticData(state, action) {
            state.statisticData = action.payload
        },
    }
})

export const {
    setFilteredData,
    setStatisticData
} = statisticSlice.actions

export default statisticSlice.reducer