import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Wrapper from "../../components/Wrapper";
import { CreateProjectStep1 } from "../../components/CreateProject/Step1";
import { CreateProjectStep2 } from "../../components/CreateProject/Step2";
import { CreateProjectStep3 } from "../../components/CreateProject/Step3";
import { CreateProjectStep4 } from "../../components/CreateProject/Step4";
import { useLazyGetReportsDetailsTranslationsQuery } from "../../store/service/adbService";
import "./index.scss";

export const CreateProjectPage = () => {
  const route = useLocation().pathname;
  const [translations, setTranslations] = useState([])

  const [
    getReportsDetailsTranslations,
    {
      data: reportDetailsTranslationsData,
      isError: reportDetailsTranslationsError,
      isLoading: reportDetailsTranslationsLoading
    }] = useLazyGetReportsDetailsTranslationsQuery()

    useEffect(() => {
      getReportsDetailsTranslations()
    }, [])

    useEffect(() => {
      setTranslations(reportDetailsTranslationsData?.data?.translations)
    }, [reportDetailsTranslationsData])

  return (
    <Wrapper>
      <div className="create__box">
        <h1 className="create__title">{translations?.['main.edit_projects_details.titles.create_project']}</h1>
        <div className="create__tabs">
          <div
            className={`create__tab one ${
              +route.match(/\d+/g)[0] >= 1 ? "active" : ""
            }`}
          >
            {translations?.['main.edit_projects_details.headers.contract_details']}
          </div>
          <div
            className={`create__tab two ${
              +route.match(/\d+/g)[0] >= 2 ? "active" : "0"
            }`}
          >
            {translations?.['main.edit_projects_details.headers.contract_dates']}
          </div>
          <div
            className={`create__tab two ${
              +route.match(/\d+/g)[0] >= 3 ? "active" : ""
            }`}
          >
            {translations?.['main.edit_projects_details.headers.active_parties']}
          </div>
          <div
            className={`create__tab three ${
              +route.match(/\d+/g)[0] >= 4 ? "active" : ""
            }`}
          >
            {translations?.['main.edit_projects_details.headers.project_sections']}
          </div>
        </div>
        <div className="create__content">
          <div style={+route.match(/\d+/g)[0] === 1 ? {display: 'block'} : {display: 'none'}}>
            <CreateProjectStep1 translations={translations}/>
          </div>
          <div style={+route.match(/\d+/g)[0] === 2 ? {display: 'block'} : {display: 'none'}}>
            <CreateProjectStep2 translations={translations}/>
          </div>
          <div style={+route.match(/\d+/g)[0] === 3 ? {display: 'block'} : {display: 'none'}}>
            <CreateProjectStep3 translations={translations}/>
          </div>
          <div style={+route.match(/\d+/g)[0] === 4 ? {display: 'block'} : {display: 'none'}}>
            <CreateProjectStep4 translations={translations}/>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
