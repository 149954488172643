import {toast} from "react-toastify";

export function errorHandle(errorData){
    if ( errorData.data.message){
        toast.error(errorData.data.message)
    }
    errorData.data?.errors && Object.keys(errorData.data.errors).forEach((index) =>{
        for (const [, value] of Object.entries(errorData.data.errors[index])) {
            toast.error(value)
        }
    })
}

