import React, {useEffect, useState} from "react";
import moment from "moment/moment";
import {toast} from "react-toastify";
import {setLoader} from "../../../../store/slice/dashboard";
import {useUpdateProjectStatusMutation} from "../../../../store/service/adbService";
import {useDispatch, useSelector} from "react-redux";
import {useErrorHandler} from "../../../../global/ErrorsHandler";
import CustomDatePicker from "../../../CustomDatePicker";

export const ProjectStatus = ({data, translations}) => {
    const dispatch = useDispatch()
    const [statusEdit, setStatusEdit] = useState(false)
    const [plannedEdit, setPlannedEdit] = useState(false)
    const [actualEdit, setActualEdit] = useState(false)
    const [expectedEdit, setExpectedEdit] = useState(false)
    const [statusData, setStatusData] = useState(data?.project_monitoring)
    const user = useSelector((state) => state?.adbReducer?.user);
    const [currentItem, setCurrentItem] = useState({})

    const [
        updateProjectStatus,
        {
            data: updateProjectStatusData,
            isError: updateProjectStatusIsError,
            isLoading: updateProjectStatusIsLoading,
            isSuccess: updateProjectStatusIsSuccess,
            error: updateProjectStatusError,
        },
    ] = useUpdateProjectStatusMutation();


    useEffect(() => {
        const d = () => {
            dispatch(setLoader(false));
        };
        updateProjectStatusIsLoading
            ? dispatch(setLoader(true))
            : d();
    }, [
        updateProjectStatusIsLoading,
        updateProjectStatusIsError
    ]);

    useEffect(() => {
        setStatusData(data?.project_monitoring)
        setStatusEdit(false)
        setExpectedEdit(false)
        setActualEdit(false)
        setPlannedEdit(false)
    }, [
        updateProjectStatusIsSuccess,
        data,
        updateProjectStatusIsError
    ]);

    const submitHandler = (e) => {
        e.preventDefault();

        const dataUpdated = {
            status_as_of_date: currentItem?.status_as_of_date && moment(currentItem?.status_as_of_date).format(
                "YYYY-MM-DD"
            ),
            planned_progress: currentItem?.planned_progress,
            actual_progress: currentItem?.actual_progress,
            expected_completion_date: currentItem?.expected_completion_date && moment(currentItem?.expected_completion_date).format(
                "YYYY-MM-DD"
            ),
        }

        if (currentItem) {
            updateProjectStatus({id: data?.id, body: dataUpdated})
            // updateExecutiveSummary({ id, body: { resume: text } });
        } else {
            toast.error("Please enter summary", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }
    };

    const [errorHandler, setErrorHandler] = useErrorHandler([
        updateProjectStatusError
    ]);


    return (
        <div className="project__status">
            <form onSubmit={(e) => submitHandler(e)} className="project__status-content">
                <div className="project__status-box">
                    <p className="project__status-subtitle">
                        {translations?.['project_monitoring.project_monitoring.titles.project_status_as_of_date']}
                    </p>
                    {
                        statusEdit ?
                            <div className="project__block-item-calendar project-table__field">
                                <CustomDatePicker
                                    portalId="root-portal"
                                    autoComplete="off"
                                    id="approved_date"
                                    placeholderText={translations?.['main.main.placeholder.dd_mm_yyyy']}
                                    dateFormat="dd/MM/yyyy"
                                    value={ currentItem?.status_as_of_date ?
                                        moment(currentItem?.status_as_of_date).toDate() :
                                        statusData?.status_as_of_date ? moment(statusData?.status_as_of_date).format("DD/MM/YYYY") : ''}
                                    selected={
                                        currentItem?.status_as_of_date
                                            ? moment(currentItem?.status_as_of_date).toDate()
                                            : ""
                                    }
                                    onChange={(date) => {
                                        setCurrentItem((old) => ({
                                            ...old,
                                            status_as_of_date: date,
                                        }));
                                    }}
                                />
                            </div> :
                            <span className="project__status-text">
                                {statusData?.status_as_of_date ? moment(statusData?.status_as_of_date).format("DD/MM/YYYY") : '-'}
                            </span>
                    }
                    {
                        user?.permissions?.edit_project &&
                        <div className="project__block-btns">
                            {statusEdit && (
                                <div
                                    className="project__block-cancel"
                                    onClick={() => {
                                        setStatusEdit(false)
                                    }}
                                >
                                    <img src="/assets/icons/close-mark.svg" alt="close"/>
                                    {translations?.['project_monitoring.project_monitoring.actions.cancel']}
                                </div>
                            )}
                            {statusEdit ? (
                                <button type="submit" className="project__block-save">
                                    <img src="/assets/icons/save-arrow.svg" alt="arrow"/>
                                    {translations?.['project_monitoring.project_monitoring.actions.save']}
                                </button>
                            ) : (
                                <div
                                    onClick={() => {
                                        setStatusEdit(true)
                                    }}
                                    className="project__block-update"
                                >
                                    {translations?.['project_monitoring.project_monitoring.actions.update']}
                                </div>
                            )}
                        </div>
                    }
                </div>
                <div className="project__status-box">
                    <p className="project__status-subtitle">
                        {translations?.['project_monitoring.project_monitoring.titles.planned_progress']}
                    </p>
                    <span className="project__status-text">
                        {statusData?.planned_progress ? statusData?.planned_progress : '0'}%
                    </span>
                </div>
                <div className="project__status-box">
                    <p className="project__status-subtitle">
                        {translations?.['project_monitoring.project_monitoring.titles.actual_progress']}
                    </p>
                    <span className="project__status-text">
                        {statusData?.actual_progress ? statusData?.actual_progress : '0'}%
                    </span>
                </div>
                <div className="project__status-box">
                    <p className="project__status-subtitle">
                        {translations?.['project_monitoring.project_monitoring.titles.expected_completion_date']}
                    </p>
                    {
                        expectedEdit ?
                            <div className="project__block-item-calendar project-table__field">
                                <CustomDatePicker
                                    portalId="root-portal"
                                    autoComplete="off"
                                    id="approved_date"
                                    placeholderText={translations?.['main.main.placeholder.dd_mm_yyyy']}
                                    dateFormat="dd/MM/yyyy"
                                    value={ currentItem?.expected_completion_date ?
                                        moment(currentItem?.expected_completion_date).toDate() :
                                        statusData?.expected_completion_date ? moment(statusData?.expected_completion_date).format("DD/MM/YYYY") : ''}
                                    selected={
                                        currentItem?.expected_completion_date
                                            ? moment(currentItem?.expected_completion_date).toDate()
                                            : ""
                                    }
                                    onChange={(date) => {
                                        setCurrentItem((old) => ({
                                            ...old,
                                            expected_completion_date: date,
                                        }));
                                    }}
                                />
                            </div> :
                            <span className="project__status-text">
                                {statusData?.expected_completion_date ? moment(statusData?.expected_completion_date).format("DD/MM/YYYY") : '-'}
                            </span>
                    }
                    {
                        user?.permissions?.edit_project &&
                        <div className="project__block-btns">
                            {expectedEdit && (
                                <div
                                    className="project__block-cancel"
                                    onClick={() => {
                                        setExpectedEdit(false)
                                    }}
                                >
                                    <img src="/assets/icons/close-mark.svg" alt="close"/>
                                    {translations?.['project_monitoring.project_monitoring.actions.cancel']}
                                </div>
                            )}
                            {expectedEdit ? (
                                <button type="submit" className="project__block-save">
                                    <img src="/assets/icons/save-arrow.svg" alt="arrow"/>
                                    {translations?.['project_monitoring.project_monitoring.actions.save']}
                                </button>
                            ) : (
                                <div
                                    onClick={() => {
                                        setExpectedEdit(true)
                                    }}
                                    className="project__block-update"
                                >
                                    {translations?.['project_monitoring.project_monitoring.actions.update']}
                                </div>
                            )}
                        </div>
                    }
                </div>
            </form>
            <div className="project__status-content">
                <div className="project__status-box dark">
                    <p className="project__status-subtitle">
                        {translations?.['project_monitoring.project_monitoring.titles.original_contract_completion_date']}
                    </p>
                    <span className="project__status-text">
                        {statusData?.original_completion_date ? moment(statusData?.original_completion_date).format("DD/MM/YYYY") : '-  '}
                    </span>
                </div>
                <div className="project__status-box dark">
                    <p className="project__status-subtitle">
                        {translations?.['project_monitoring.project_monitoring.titles.current_contract_completion_date']}
                    </p>
                    <span className="project__status-text">
                        {statusData?.current_contract_completion_date ? moment(statusData?.current_contract_completion_date).format("DD/MM/YYYY") : '-'}
                    </span>
                </div>
                <div className="project__status-box dark">
                    <p className="project__status-subtitle">
                        {translations?.['project_monitoring.project_monitoring.titles.project_status_time_elapsed']}
                    </p>
                    <span className="project__status-text">
                        {statusData?.status_time_elapsed ? statusData?.status_time_elapsed : '0'}%
                    </span>
                </div>
                <div className="project__status-box dark">
                    <p className="project__status-subtitle">
                        {translations?.['project_monitoring.project_monitoring.titles.variance_to_completion']}
                    </p>
                    <span className={`${statusData?.variance_to_completion < 0 ? 'project__status-text-red' : 'project__status-text'}`}>
                        {statusData?.expected_completion_date ? statusData?.variance_to_completion : '0'} {translations?.['project_monitoring.project_monitoring.titles.day_s']}
                    </span>
                </div>
            </div>
        </div>
    )
}