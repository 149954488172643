import axios from 'axios'
import React, { useEffect, useState } from 'react'
import {useParams} from 'react-router-dom';
import useLastPathname from "../../../global/useLastPathname";


export default function LarpForDoc({ openTier, openSection}) {
  const [data, setData] = useState([])
  const [allData, setAllData] = useState([])
  const lastPathname = useLastPathname();
  const params = useParams()
  const [docTranslations, setDocTranslations] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/chart/larp-dashboard/${params?.id}`,
        {
          headers: {
            "AppLocale": lastPathname
          }
        }
        )
        .then((res) => {
          const arr = Object.keys(res?.data?.data?.data)
              .filter(key => key !== 'total')
              .map(key => res?.data?.data?.data[key]);
          setDocTranslations(res?.data?.data?.translations)
          const startIndex = +params?.start - 1;
          const endIndex = +params?.end;
          const slicedArray = arr?.slice(startIndex, startIndex + endIndex);
          setAllData(slicedArray)
        })
  }, [])

  useEffect(() => {
    if (allData?.length > 0) {
      const newData = allData?.map(item => {
        const foundation = item?.tiers?.filter(elem => elem?.tier === docTranslations?.['foundation'])[0];
        const towerErection = item?.tiers?.filter(elem => elem?.tier === docTranslations?.['tower_erection'])[0];
        const lineStringing = item?.tiers?.filter(elem => elem?.tier === docTranslations?.['line_stringing'])[0];
        return { ...item, tiers: [foundation, towerErection, lineStringing] };
      });
      
      let convertedData = convertHeight(newData)
      setData(convertedData)
    }
  }, [allData]);

  const filterAffectedPersons = (elem, item, type) => {
    const s = elem?.affected_persons?.filter((person) => person?.payment_status === type)
    openTier({...elem, affected_persons: s, section: item?.section, report_date: item?.report_date})
  }

  const borderRadius = (type, elem) => {
    const {total_paid, total_unpaid, total_escrow} = elem
    if (type === 'paid') {
      if (total_escrow == 0 && total_unpaid == 0) {
        return {borderTopLeftRadius: 4, borderTopRightRadius: 4, borderBottomLeftRadius: 4, borderBottomRightRadius: 4}
      }
      if ((total_escrow == 0 && total_paid != 0 && total_unpaid != 0) || (total_escrow != 0 && total_paid != 0 && total_unpaid != 0) || (total_escrow != 0 && total_paid != 0 && total_unpaid == 0)) {
        return {borderBottomLeftRadius: 4, borderBottomRightRadius: 4}
      }
    }
    if (type === 'escrow') {
      if (total_paid == 0 && total_unpaid == 0) {
        return {borderTopLeftRadius: 4, borderTopRightRadius: 4, borderBottomLeftRadius: 4, borderBottomRightRadius: 4}
      }
      if (total_escrow !== 0 && total_paid != 0 && total_unpaid == 0) {
        return {borderTopLeftRadius: 4, borderTopRightRadius: 4}
      }
      if (total_escrow !== 0 && total_paid == 0 && total_unpaid != 0) {
        return {borderBottomLeftRadius: 4, borderBottomRightRadius: 4}
      }
    }

    if (type === 'unpaid') {
      if (total_paid == 0 && total_escrow == 0) {
        return {borderTopLeftRadius: 4, borderTopRightRadius: 4, borderBottomLeftRadius: 4, borderBottomRightRadius: 4}
      }
      if ((total_paid != 0 && total_escrow == 0) || (total_paid != 0 && total_escrow != 0) || (total_paid == 0 && total_escrow != 0)) {
        return {borderTopLeftRadius: 4, borderTopRightRadius: 4}
      }
    }
  }

  const convertHeight = (s) => {
    const a = s.map(item => {
      const b = { ...item };
      b.tiers = b.tiers.map(elem => {
        const unpaid = percentToInt(elem?.unpaid);
        const escrow = percentToInt(elem?.escrow);
        const paid = percentToInt(elem?.paid);
        const smallest = findSmallestNonZeroItem([{value: unpaid, label: 'unpaid'}, {value: escrow, label: 'escrow'}, {value: paid, label: 'paid'}], 'value')
        const maxNumber = findMaxNonZeroItem([{value: unpaid, label: 'unpaid'}, {value: escrow, label: 'escrow'}, {value: paid, label: 'paid'}], 'value');
        let height_unpaid = `${unpaid}%` ;
        let height_escrow = `${escrow}%` ;
        let height_paid = `${paid}%`;

        if (+maxNumber?.value > 80) {
          if (maxNumber.label === 'unpaid') {
            height_unpaid = escrow == 0 && paid == 0 ? '100%' : (escrow == 0 || paid == 0) ? '90%' : `80%`
            height_escrow = escrow == 0 ? '0%' : `10%`
            height_paid = paid == 0 ? '0%' : `10%`
            return { ...elem, height_unpaid, height_escrow, height_paid};
          } else if (maxNumber.label === 'escrow') {
            height_unpaid = unpaid == 0 ? '0%' : `10%`
            height_escrow = unpaid == 0 && paid == 0 ? '100%' : (unpaid == 0 || paid == 0) ? '90%' : `80%`
            height_paid = paid == 0 ? '0%' : `10%`
            return { ...elem, height_unpaid, height_escrow, height_paid};
          } else {
            height_unpaid = unpaid == 0 ? '0%' : `10%`
            height_escrow = escrow == 0 ? '0%' : `10%`
            height_paid = unpaid == 0 && escrow == 0 ? '100%' : (unpaid == 0 || escrow == 0) ? '90%' : `80%`;
            return { ...elem, height_unpaid, height_escrow, height_paid};
          }
        } 

        let count = 0;
        
        if (smallest?.value < 10 && smallest?.value !== null) {
          count = (smallest?.value - 10) * -1;
          if (smallest.label === 'unpaid') {
            height_unpaid = `10%`
          } else if (smallest.label === 'escrow') {
            height_escrow = `10%`
          } else {
            height_paid = `10%`
          }

          if (maxNumber.label === 'unpaid') {
            height_unpaid = `${maxNumber.value - count}%`
          } else if (maxNumber.label === 'escrow') {
            height_escrow = `${maxNumber.value - count}%`
          } else {
            height_paid = `${maxNumber.value - count}%`
          }
        }
        return { ...elem, height_unpaid, height_escrow, height_paid};
      });
      return b;
    });

    return a
  }

  function findMaxNonZeroItem(arr, key) {
    if (!arr.length) {
      return null;
    }
  
    const nonZeroItems = arr.filter(item => item[key] !== 0);
  
    if (!nonZeroItems.length) {
      return null;
    }
  
    // Find the object with the smallest non-zero value
    let MaxtNonZeroItem = nonZeroItems[0];
  
    for (let i = 1; i < nonZeroItems.length; i++) {
      if (nonZeroItems[i][key] > MaxtNonZeroItem[key]) {
        MaxtNonZeroItem = nonZeroItems[i];
      }
    }
  
    return MaxtNonZeroItem;
  }

  const percentToInt = (percent) => {
    if (percent != 0 || percent != '0%') {
      return +String(percent).replace('%', '')
    }
    return 0
  }

  function findSmallestNonZeroItem(arr, key) {
    if (!arr.length) {
      return null;
    }
  
    // Filter out objects where the value is zero
    const nonZeroItems = arr.filter(item => item[key] !== 0);
  
    if (!nonZeroItems.length) {
      return null;
    }
  
    // Find the object with the smallest non-zero value
    let smallestNonZeroItem = nonZeroItems[0];
  
    for (let i = 1; i < nonZeroItems.length; i++) {
      if (nonZeroItems[i][key] < smallestNonZeroItem[key]) {
        smallestNonZeroItem = nonZeroItems[i];
      }
    }
  
    return smallestNonZeroItem;
  }

  const heightPaidWithEscrow = (elem1, elem2) => {
    // return ((+elem1?.replace('%', '') + +elem2?.replace('%', '')) * 197 / 100 - 22 - 7) / 2 + - 11 + 'px'
    let a = +elem1?.replace('%', '');
    let b = +elem2?.replace('%', '');
    return ((((a + b) * 170) / 100) / 2) - 18 - 10 + 'px'
  }

  const centerHeightPaidWithEscrow = (elem1, elem2) => {
    let a = +elem1?.replace('%', '');
    let b = +elem2?.replace('%', '');
    return (((a + b) * 170) / 100) + 'px'
  }

  return (
    <>
        <div className="larp-db__df larp-db__aj">
          <div className="larp-db__colors">
            <div className="larp-db__color paid">
              <div></div>
              <p>{docTranslations?.['paid']}</p>
            </div>
            <div className="larp-db__color escrowed">
              <div></div>
              <p>{docTranslations?.['escrowed']}</p>
            </div>
            <div className="larp-db__color unpaid">
              <div></div>
              <p>{docTranslations?.['unpaid']}</p>
            </div>
          </div>
        </div>
      <div className="larp-db__table" style={{height: '92vh'}}>
      <div className="larp-db__row">
        <div className="larp-db__th" style={{height: 187}}>
          {docTranslations?.['foundation']}
        </div>
        <div className="larp-db__th" style={{height: 187}}>
          {docTranslations?.['line_stringing']}
        </div>
        <div className="larp-db__th"  style={{height: 187}}>
          {docTranslations?.['tower_erection']}
        </div>
        <div className="larp-db__th end"></div>
      </div>

      <div className="larp-db__table-scroll">
        {data?.map((item, index) => (
          <div className="larp-db__row" key={index} style={{width: 102}}>
            {item?.tiers?.map((elem, i) => (              
              <div className="larp-db__td" key={i} style={{padding: 0, paddingRight: 40, width: '102px', height: 187}}>
                <div className="larp-db__td-radius" style={{height: 170}}>
                  {elem?.unpaid !== '0%' && (
                    <div className={`larp-db__chart full unpaid ${elem?.unpaid == '100%' ? 'gray' : ''}`} onClick={() => {filterAffectedPersons(elem, item, 'Unpaid')}} style={{height: elem?.height_unpaid, ...borderRadius('unpaid', elem)}}>{elem?.unpaid}</div>
                  )}
                  {elem?.escrow !== '0%' && (
                    <div className="larp-db__chart full escrowed" onClick={() => {filterAffectedPersons(elem, item, 'Escrow')}} style={{height: elem?.height_escrow, ...borderRadius('escrow', elem)}}>
                      {elem?.escrow}
                      {elem?.escrow !== '100%' && elem?.total_paid !== 0 && (
                        <div className="larp-db__top-arrow" style={{transform: 'translateX(70.5%);'}}>
                          <img src="/assets/icons/larp-end-arrow.svg" alt="arrow" className='end' />
                          <span style={{height: heightPaidWithEscrow(elem?.height_paid, elem?.height_escrow)}}></span>
                          <img src="/assets/icons/larp-start-arrow.svg" alt="arrow" className='start' />
                        </div>
                      )}
                    </div>
                  )}
                  {elem?.paid !== '0%' && (
                    <div className="larp-db__chart full paid" onClick={() => {filterAffectedPersons(elem, item, 'Paid')}} style={{height: elem?.height_paid, ...borderRadius('paid', elem)}}>
                      {elem?.paid}
                      {elem?.paid !== '100%' && elem?.total_escrow != 0 && (
                        <div className="larp-db__top-arrow" style={{transform: 'translateX(70.5%);'}}>
                          <img src="/assets/icons/larp-start-arrow.svg" alt="arrow" className='start' />
                          {/* <span style={{height: `calc(((${elem?.height_paid} + ${elem?.height_escrow}) / 2) - 22px - 7px)`}}></span> */}
                          <span style={{height: heightPaidWithEscrow(elem?.height_paid, elem?.height_escrow)}}></span>
                          <img src="/assets/icons/larp-end-arrow.svg" alt="arrow" className='end' />
                        </div>
                      )}
                    </div>
                  )}


                  {elem?.escrow === '0%' && elem?.paid === '0%' && elem?.unpaid === 0 && (
                    <div className="larp-db__chart full gray" style={{height: '100%'}}>0%</div>
                  )}
                  {elem?.total_escrow != 0 && elem?.total_paid != 0 && (
                    // <div className="larp-db__chart-percent" style={{height: `calc(((${elem?.height_paid} + ${elem?.height_escrow}))`, transform: elem?.total_percent !== '100%' ? 'translateX(60px)' : +elem?.total_percent.replace('%', '') < 10 ? 'translateX(65px)' : 'translateX(58px)'}}>{elem?.total_percent}</div>
                    <div className="larp-db__chart-percent" style={{height: centerHeightPaidWithEscrow(elem?.height_paid, elem?.height_escrow)}}>
                      <span>{elem?.total_percent}</span>
                    </div>
                  )}
                </div>
              </div>
            ))}
            <div className="larp-db__td end" style={{width: '100%'}} onClick={() => {
              openSection(item)
            }}>
              <div className="larp-db__td-name">{ item?.section }</div>
            </div>
          </div>
        ))}
      </div>
    </div>
    </>

  )
}