import React, {useRef, useState} from 'react'
import CashflowOfContracts from "./CashflowOfContracts";
import PhysicalProgressOfContracts from './PhysicalProgressOfContracts'
import RiskRegisterOfContracts from "./RiskRegisterOfContracts";
import MoreInfoModal from "../../../components/MoreInfoModal";

const ListOfTables = ( { translations, data, hiddenSectionHandler}) => {
    const sectionsRef = useRef();
    const [isOpenMoreInfo, setOpenMoreInfo] = useState(false);

    return  (
        <section id='lists_of_tables' ref={sectionsRef}>
            {isOpenMoreInfo &&
                <MoreInfoModal
                    title= {translations?.['lists_of_tables.index.headers.lists_of_tables']}
                    moreInfo={translations?.['lists_of_tables.index.more_info.more_info']}
                    close={()=>setOpenMoreInfo(false)}
                />
            }
            <div className="editReport__sectionHeader">
                <span  onClick={() => hiddenSectionHandler(sectionsRef)}/>
                <h2  onClick={() => hiddenSectionHandler(sectionsRef)}>{translations?.['lists_of_tables.index.headers.lists_of_tables']}</h2>
                <button className="editReport__sectionHeader__moreInfo" onClick={()=>setOpenMoreInfo(!isOpenMoreInfo)}>
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.99805 6.33325V9.43992" stroke="#767676" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M6.99805 5.05322C7.27419 5.05322 7.49805 4.82937 7.49805 4.55322C7.49805 4.27708 7.27419 4.05322 6.99805 4.05322C6.7219 4.05322 6.49805 4.27708 6.49805 4.55322C6.49805 4.82937 6.7219 5.05322 6.99805 5.05322Z" fill="#767676"/>
                        <path d="M6.9987 13.1666C10.4045 13.1666 13.1654 10.4057 13.1654 6.99992C13.1654 3.59416 10.4045 0.833252 6.9987 0.833252C3.59294 0.833252 0.832031 3.59416 0.832031 6.99992C0.832031 10.4057 3.59294 13.1666 6.9987 13.1666Z" stroke="#767676" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    {translations?.['lists_of_tables.index.titles.more_info']}
                </button>
            </div>
            <hr />
            <PhysicalProgressOfContracts
                data={data?.physical_progress_of_contracts}
                translations={translations}
            />
            <CashflowOfContracts
                data={data?.cashflow_of_contracts}
                translations={translations}
            />
            <hr/>
            <RiskRegisterOfContracts
                sectionData={data?.risk_register_of_contracts}
                translations={translations}
            />

        </section>
    )

}


export default ListOfTables;
