import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import React, { useEffect, useRef, useState } from 'react';
import {
  useCreateFinancialRatiosMutation,
  useCreateFinancialSubRatiosMutation,
  useDeleteFinancialRatiosMutation,
  useDeleteFinancialSubRatiosMutation,
  useLazyGetFinancialRatiosQuery,
  useUpdateSubmissionsAuditedMutation,
} from "../../store/service/adbService";
import { errorHandle } from "../../global/errorHandle";
import { setLoader } from "../../store/slice/dashboard";
import AdvancedItemEdit from "./Covenants/AdvancedItemEdit";
import moment from "moment";

const Table = ({ tableData, reportId, translations, url, isOpenEdit, setIsOpenEdit, isReportSubmitted }) => {
  const headers = [
        translations?.['covenants.financial_covenants.table_headers.description_of_financial_ratio'],
        translations?.['covenants.financial_covenants.table_headers.agency'],
        translations?.['covenants.financial_covenants.table_headers.fiscal_year'],
        translations?.['covenants.financial_covenants.table_headers.unit_of_measurement'],
        translations?.['covenants.financial_covenants.table_headers.frequency_of_compliance'],
        translations?.['covenants.financial_covenants.table_headers.linked_to_aefs'],
  ];
  const ratioHeaders = [
    translations?.['covenants.financial_covenants.table_headers.actual_value'],
    translations?.['covenants.financial_covenants.table_headers.compiled'],
    translations?.['covenants.financial_covenants.table_headers.compliance_with_financial_covenants_provided_by_auditor'],
    translations?.['covenants.financial_covenants.table_headers.target_criteria'],
    translations?.['covenants.financial_covenants.table_headers.target_date'],
    translations?.['covenants.financial_covenants.table_headers.target_value'],
    translations?.['covenants.financial_covenants.table_headers.compiled_date']
  ];
  const financialRatioFields = [
    'description',
    'agency',
    'fiscal year',
    'unit_of_measurement',
    'frequency_of_compliance',
    'linked_to_aefs'
  ]
  const dispatch = useDispatch();
  const headerRef = useRef();
  const [auditedData, setAuditedData] = useState({tableData});
  const [cellWidths, setCellWidths] = useState([]);
  const [currentRow, setCurrentRow] = useState(null);
  const [expandedRow, setExpandedRow] = useState(false);
  const [isOpenRatioEdit, setIsOpenRatioEdit] = useState(false);

  const [
    getFinancialRatios,
    {
      data: financialRatiosData,
      isLoading: financialRatiosIsLoading,
      error: financialRatiosError,
      isError: financialRatiosIsError,
      isSuccess: financialRatiosIsSuccess,
    },
  ] = useLazyGetFinancialRatiosQuery();

  const [deleteFinancialRatios, {
    data: deleteFinancialRatiosData,
    isLoading: deleteFinancialRatiosIsLoading,
    error: deleteFinancialRatiosError,
    isError: deleteFinancialRatiosIsError,
    isSuccess: deleteFinancialRatiosIsSuccess
  }] = useDeleteFinancialRatiosMutation();

  const [deleteFinancialSubRatios, {
    data: deleteFinancialSubData,
    isLoading: deleteFinancialSubIsLoading,
    error: deleteFinancialSubError,
    isError: deleteFinancialSubIsError,
    isSuccess: deleteFinancialSubIsSuccess
  }] = useDeleteFinancialSubRatiosMutation();

  useEffect(() => {
    if (tableData) {
      let editedData = tableData.map((item) => {
        return { ...item, isOpened: false };
      });
      setAuditedData(editedData);
    }
  }, [tableData]);

  const updateFinancialRatios = () => {
    getFinancialRatios(reportId);
  };

  useEffect(() => {
    if (financialRatiosIsSuccess) {
      setAuditedData(financialRatiosData?.data);
    }
  }, [financialRatiosIsSuccess, financialRatiosData]);

  useEffect(() => {
    if (financialRatiosIsError) {
      toast.error(financialRatiosError?.data.message);
      errorHandle(financialRatiosError);
    }
    if (deleteFinancialRatiosIsError) {
      toast.error(deleteFinancialRatiosError?.data.message);
      errorHandle(deleteFinancialRatiosError);
    }
    if (deleteFinancialRatiosIsError) {
      toast.error(deleteFinancialSubError?.data.message);
      errorHandle(deleteFinancialSubError);
    }

  }, [financialRatiosError, financialRatiosIsError,deleteFinancialRatiosError, deleteFinancialRatiosIsError,deleteFinancialSubError, deleteFinancialSubIsError]);

  useEffect(() => {
    financialRatiosIsLoading ||
    deleteFinancialSubIsLoading ||
    deleteFinancialRatiosIsLoading
      ? dispatch(setLoader(true))
      : dispatch(setLoader(false));
  }, [financialRatiosIsLoading, deleteFinancialSubIsLoading, deleteFinancialRatiosIsLoading]);

  useEffect(() => {
    if (headerRef.current) {
      const widths = Array.from(headerRef.current.querySelectorAll('th')).map(th => th?.offsetWidth  );
      setCellWidths(widths);
    }
  }, [headerRef]);

  useEffect(() => {
    if (deleteFinancialRatiosIsSuccess) {
      toast.success(deleteFinancialRatiosData.message);
      updateFinancialRatios();
    }
    if (deleteFinancialSubIsSuccess) {
      toast.success(deleteFinancialSubData.message);
      updateFinancialRatios();

    }
    }, [deleteFinancialRatiosIsSuccess, deleteFinancialSubIsSuccess]);

  const toggleOpening = (id, v, ratioId = null) => {
    let changedData;
    if (ratioId) {
      changedData = auditedData.map(item => {
        if (item?.id === id) {
          let changedRatios = item?.ratios?.map(ratio => {
            if(ratio?.id === ratioId) {
              return { ...ratio, isOpened: v }
            }
            return ratio
          })
          return { ...item, ratios: changedRatios }
        }
        return item;
      })
      setAuditedData(changedData);

    } else {
      changedData = auditedData.map(item => {
        if (item?.id === id) {
          return { ...item, isOpened: v }
        }
        return item;
      })
      setAuditedData(changedData);
    }
  }
  return (
    <table className='report__table' ref={headerRef} style={{ paddingBottom: '50px' }}>
      <thead className="report__table__header">
      <tr className="report__table__row">
        {headers.map((item, index) => <th  key={index}>{item}</th>)}
        <th></th>
      </tr>
      </thead>
      <tbody className="report__table__body">
      {auditedData?.length ?
        auditedData?.map((item, index) => (
          <React.Fragment key={index}>
          {!item?.isOpened ? (
            <tr className="report__table__row">
              {Object.keys(item)?.map((key, idx) => (
                key !== 'ratios' && key !== 'id' && key !== 'isOpened' && (
                    <td style={{ width: (cellWidths[idx-1] )}} key={idx}>{item[key]}</td>
                )
              ))}
              <td className="project__larp-btns end-btns" style={{ width: (cellWidths[cellWidths.length -1 ] )}}>
                {currentRow === item?.id && expandedRow ? (
                    <button
                      onClick={() => {
                        setExpandedRow(false);
                        setCurrentRow(null);
                      }}
                    >
                      <img
                        src="/assets/icons/arrow-up-larp.svg"
                        alt="arrow"
                      />
                    </button>
                  ) :
                  <img
                    className="project__block-expand"
                    onClick={() => {
                      setCurrentRow(item?.id);
                      setExpandedRow(true);
                    }}
                    src="/assets/icons/arrow-down-larp.svg"
                    alt="arrow"
                  />
                }
              </td>
              {!isReportSubmitted && <td style={{padding: 0, width: 0, minWidth: 'auto'}}>
                <div className="editReport__table__settingsButtons">
                  <button
                      className="editReport__table__deleteBtn"
                      onClick={() => deleteFinancialRatios(item?.id)}
                  >
                    {translations?.['covenants.loan_covenants.actions.delete']}
                  </button>
                  <button
                      className="editReport__table__updateBtn"
                      onClick={() => toggleOpening(item?.id, true)}
                  >
                    {translations?.['covenants.loan_covenants.actions.update']}
                  </button>
                </div>
              </td>}
            </tr>
          ) : (
            <AdvancedItemEdit
              url={url}
              updateById
              headerRef={headerRef}
              itemData={item}
              reportId={reportId}
              setIsOpenEdit={(v) => toggleOpening(item?.id, v)}
              getItems={updateFinancialRatios}
              cancelTranslation={translations?.['covenants.financial_covenants.actions.cancel']}
              saveTranslation={translations?.['covenants.financial_covenants.actions.save']}
              useUpdateItemMutation={useUpdateSubmissionsAuditedMutation}
              fields={Object.keys(item).filter(key => key !== 'id' && key !== 'ratios' && key !== 'isOpened')}
              feldLabels ={headers}
              cellWidths={cellWidths}
              isShowLabel={false}
              placeHolders={[
                translations?.['index.index.placeholder.text'],
                translations?.['index.index.placeholder.text'],
                translations?.['index.index.placeholder.number'],
                translations?.['index.index.placeholder.text'],
                translations?.['index.index.placeholder.text'],
                translations?.['index.index.placeholder.number'],
              ]}
            />
          )}


            {/*ratios block*/}
            {
              (currentRow === item?.id && expandedRow) ? (
                <React.Fragment>
                  <div className="expandedRow__bg" style={{width: '100%'}}>
                    <div className="editReport__sectionHeader">
                      <h4>
                        TITLE
                      </h4>
                      {!isReportSubmitted &&
                        <button
                          className="editReport__sectionHeader__addRowBtn"
                          onClick={() => setIsOpenRatioEdit(true)}>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25Z" stroke="#0052CC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M11.8687 9.22595L11.9111 14.6141" stroke="#0052CC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M9.1958 11.8988L14.584 11.9412" stroke="#0052CC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                          {translations?.['covenants.loan_covenants.actions.add_row']}
                        </button>
                      }
                    </div>
                    <table>
                      <thead className="report__table__header">
                      <tr className="report__table__row">
                        {ratioHeaders.map((header, headerIndx) => <th style={{width: (cellWidths[headerIndx])}} key={headerIndx}>{header}</th>)}
                      </tr>
                      </thead>
                      <tbody className="report__table__body">
                      { item?.ratios?.length>0 &&
                        item?.ratios?.map((ratio, ratioIndx) => (
                          <React.Fragment key={index}>
                            {!ratio?.isOpened ? (
                              <tr key={ratioIndx} className="report__table__row">
                                  <td style={{wordWrap: 'break-word', width: (cellWidths[0])}} key={0}>{ratio?.actual_value}</td>
                                  <td style={{wordWrap: 'break-word', width: (cellWidths[1])}} key={1}>{ratio?.compiled}</td>
                                  <td style={{wordWrap: 'break-word', width: (cellWidths[2])}} key={2}>{ratio?.compliance_with_financial_covenants_provided_by_auditor}</td>
                                  <td style={{wordWrap: 'break-word', width: (cellWidths[3])}} key={3}>{ratio?.target_criteria}</td>
                                  <td style={{wordWrap: 'break-word', width: (cellWidths[4])}} key={4}>{ratio?.target_date ? moment(ratio?.target_date, "YYYY-MM-DD").format("DD/MM/YYYY") : ''}</td>
                                  <td style={{wordWrap: 'break-word', width: (cellWidths[5])}} key={5}>{ratio?.target_value}</td>
                                  <td style={{wordWrap: 'break-word', width: (cellWidths[6])}} key={6}>{ratio?.compiled_date ? moment(ratio?.compiled_date, "YYYY-MM-DD").format("DD/MM/YYYY") : ''}</td>
                                {!isReportSubmitted &&
                                    <td style={{ padding: 0, width: 0}}>
                                      <div className="editReport__table__settingsButtons">
                                        <button
                                            className="editReport__table__deleteBtn"
                                            onClick={() => deleteFinancialSubRatios(ratio?.id)}
                                        >
                                          {translations?.['covenants.loan_covenants.actions.delete']}
                                        </button>
                                        <button
                                            className="editReport__table__updateBtn"
                                            onClick={() => toggleOpening(item?.id, true, ratio?.id)}
                                        >
                                          {translations?.['covenants.loan_covenants.actions.update']}
                                        </button>
                                      </div>
                                  </td>
                                }
                              </tr>
                            ) : (
                              <AdvancedItemEdit
                                url={'quarterly-report/covenants/financial-ratios/ratios/update'}
                                updateById
                                headerRef={headerRef}
                                itemData={ratio}
                                reportId={reportId}
                                setIsOpenEdit={(v) => toggleOpening(item?.id, v, ratio?.id)}
                                getItems={updateFinancialRatios}
                                cancelTranslation={translations?.['covenants.financial_covenants.actions.cancel']}
                                saveTranslation={translations?.['covenants.financial_covenants.actions.save']}
                                useUpdateItemMutation={useUpdateSubmissionsAuditedMutation}
                                // fields={Object.keys(ratio).filter(key => key !== 'id' && key !== 'financial_ration_id' && key !== 'isOpened')}
                                fields={['actual_value', 'compiled', 'compliance_with_financial_covenants_provided_by_auditor', 'target_criteria', 'target_date', 'target_value', 'compiled_date']}
                                feldLabels ={ratioHeaders}
                                cellWidths={cellWidths}
                                isShowLabel={false}
                                placeHolders={[
                                  translations?.['index.index.placeholder.text'],
                                  translations?.['index.index.placeholder.text'],
                                  translations?.['index.index.placeholder.text'],
                                  translations?.['index.index.placeholder.text'],
                                  translations?.['index.index.placeholder.choose_date'],
                                  translations?.['index.index.placeholder.number'],
                                  translations?.['index.index.placeholder.choose_date'],
                                ]}
                              />
                            )}
                          </React.Fragment>
                        ))
                      }
                      {
                        isOpenRatioEdit && (

                          <AdvancedItemEdit
                            ratio={true}
                            url={'quarterly-report/covenants/financial-ratios/ratios/update'}
                            updateById
                            headerRef={headerRef}
                            reportId={item?.id}
                            getItems={updateFinancialRatios}
                            cancelTranslation={translations?.['covenants.financial_covenants.actions.cancel']}
                            saveTranslation={translations?.['covenants.financial_covenants.actions.save']}
                            useCreateItemMutation={useCreateFinancialSubRatiosMutation}
                            useUpdateItemMutation={useUpdateSubmissionsAuditedMutation}
                            fields={['actual_value', 'compiled', 'compliance_with_financial_covenants_provided_by_auditor', 'target_criteria', 'target_date', 'target_value', 'compiled_date']}
                            feldLabels ={ratioHeaders}
                            cellWidths={cellWidths}
                            isShowLabel={false}
                            setIsOpenEdit={setIsOpenRatioEdit}
                            placeHolders={[
                              translations?.['index.index.placeholder.text'],
                              translations?.['index.index.placeholder.text'],
                              translations?.['index.index.placeholder.text'],
                              translations?.['index.index.placeholder.text'],
                              translations?.['index.index.placeholder.choose_date'],
                              translations?.['index.index.placeholder.number'],
                              translations?.['index.index.placeholder.choose_date'],
                            ]}
                          />
                        )
                      }
                      </tbody>
                    </table>

                  </div>
                </React.Fragment>
              ) : null
            }
        </React.Fragment>
      )) : null
      }
      {
        isOpenEdit && (

          <AdvancedItemEdit
            url={url}
            updateById
            headerRef={headerRef}
            reportId={reportId}
            getItems={updateFinancialRatios}
            cancelTranslation={translations?.['covenants.financial_covenants.actions.cancel']}
            saveTranslation={translations?.['covenants.financial_covenants.actions.save']}
            useCreateItemMutation={useCreateFinancialRatiosMutation}
            useUpdateItemMutation={useUpdateSubmissionsAuditedMutation}
            fields={financialRatioFields}
            feldLabels ={headers}
            cellWidths={cellWidths}
            isShowLabel={false}
            setIsOpenEdit={setIsOpenEdit}
            placeHolders={[
              translations?.['index.index.placeholder.text'],
              translations?.['index.index.placeholder.text'],
              translations?.['index.index.placeholder.number'],
              translations?.['index.index.placeholder.text'],
              translations?.['index.index.placeholder.text'],
              translations?.['index.index.placeholder.number'],
            ]}
          />
        )
      }
      </tbody>
    </table>
  );
};

export default Table;
