import moment from "moment";
import { debounce, isEmpty } from 'lodash';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Cell, Label, Pie, PieChart, ResponsiveContainer, Tooltip} from "recharts";
import ElementDetails from "../ElementDetails";
import DownloadCSV from "../../../components/DownloadCSV";
import './index.scss'
import {useSelector} from "react-redux";

 const ItemDetails = ({ active, payload }) => {
     if (active && payload && payload.length) {
         return  <div className="itemDetails">
             <div className="itemDetails__triangle"></div>
             {payload[0]?.payload?.hover}
         </div>
     }
 }

const ElementStatistics = ({projectData, selectedDate, statisticsPage}) =>{
    const filteredData = useSelector((state) => state?.statisticReducer?.filteredData);
    const pieRef = useRef(null);
    const pieChartOutsideHandler = useRef(null);
    const [data, setData] = useState([]);
    const [selectedCellIndex, setSelectedCellIndex] = useState(null);
    const [clickedCellIndex, setClickedCellIndex] = useState(null);
    const debouncedSetSelectedCellIndex = debounce(setSelectedCellIndex, 100);

    const renderTooltipContent = ({active, payload}) => {
        if ( clickedCellIndex !== null || selectedCellIndex === clickedCellIndex) {
            return <ElementDetails statisticsPage={statisticsPage} data={data[clickedCellIndex]}/>;
        }
        else if (selectedCellIndex !== null && selectedCellIndex !==clickedCellIndex) {
            return <ItemDetails
                active={active}
                payload={payload}
            />;
        }
        return <ItemDetails />;

    };

    const handleCellClick = (index) => {
        setClickedCellIndex(index);
    };
    const handleMouseLeave = () => {
        setSelectedCellIndex(null);
    };
    const handleCellHover = (index) => {
        debouncedSetSelectedCellIndex(index)
        if(selectedCellIndex !==clickedCellIndex){
            setClickedCellIndex(null)
        }
    };

    useEffect(() => {
        const notAllZero = Object.keys(projectData.data)?.filter(key => projectData.data[key].percent !== 0);
        if(isEmpty(notAllZero)) {
            setData([
                    {name: statisticsPage?.['index.index.titles.no_data'], value: 1, color: '#dedfe1'}
                ]
            );
        }
       else setData(
            Object.keys(projectData.data)?.map((key) => (
                {name: key, value: projectData.data[key].percent, ...projectData.data[key]}
            ))
        );

    }, [projectData.data]);

    useEffect(() => {
        pieChartOutsideHandler.current = (event) => {
            if (!pieRef.current || pieRef.current.contains(event.target)) {
                return;
            }
            setClickedCellIndex(null)
        };
    }, []);

    useEffect(function () {
        document.addEventListener('mouseleave', handleMouseLeave);
        document.addEventListener('mouseout', handleMouseLeave);

        return () => {
            document.removeEventListener('mouseleave', handleMouseLeave);
            document.removeEventListener('mouseout', handleMouseLeave);

        };
    }, [pieRef, selectedCellIndex]);

    useEffect(function () {
        document.addEventListener('mousedown', pieChartOutsideHandler.current);
        document.addEventListener('touchstart', pieChartOutsideHandler.current);

        return () => {
            document.removeEventListener('mousedown', pieChartOutsideHandler.current);
            document.removeEventListener('touchstart', pieChartOutsideHandler.current);
        };
    }, [pieRef, setClickedCellIndex]);

    const downloadCSVUrl = useMemo(() => {
        const selectedStProjectIdQueries = filteredData?.selectedProjects?.map(item => `projects[]=${item.id}`).join('&');
        return `/statistics/export/csv?${selectedDate ? `date=${moment(selectedDate)?.format("YYYY-MM")}&` : ''}${selectedStProjectIdQueries}&chart=${projectData?.chart}`;
    }, [selectedDate, filteredData?.selectedProjects]);

    return(
        <div className="elementStatistics">
            <div className="elementStatistics__header">
                <h5>
                    {projectData.title}
                </h5>
                <DownloadCSV
                    url={downloadCSVUrl}
                    fileName="Management dashboard"
                    tooltipTitle={statisticsPage?.['index.index.actions.download_csv']}
                    className="elementStatistics__header__donwLoadBtn"
                />
            </div>
            <div className="elementStatistics__pieChartContainer" ref={pieRef} >
                <ResponsiveContainer  width={190} >
                    <PieChart >
                        <Pie

                            data={data}
                            dataKey="value"
                            nameKey="name"
                            color="#000000"
                            fill="#8884d8"
                            cx="50%"
                            cy="50%"
                            innerRadius={50}
                        >
                            {

                                data?.map((el,idx) => (
                                    <React.Fragment key={`fragment-${idx}`}>
                                        <Cell  key={`cell-${idx}`}
                                               fill={el.color}
                                               onMouseEnter={() => handleCellHover(idx)}
                                               onMouseLeave={() => debouncedSetSelectedCellIndex(null)}
                                               onClick={() => handleCellClick(idx)}
                                               stroke={clickedCellIndex === idx  ? '#fff' : '#F4F5F6' }
                                               strokeWidth={clickedCellIndex === idx? '3px' : '0'}
                                        />
                                        {el.name===statisticsPage?.['index.index.titles.no_data']&&<Label
                                            key={`cell-${idx}`}
                                            value={el.name}
                                            position="center"
                                            fill="grey"
                                            style={{
                                                fontSize: "14px",
                                                fontWeight: "400",
                                                fontFamily: "Rubik"
                                            }}
                                        />}
                                    </React.Fragment>
                                ))
                            }
                        </Pie>
                        {data[0]?.name!==statisticsPage?.['index.index.titles.no_data'] && <Tooltip
                            active={true}
                            position={ ( clickedCellIndex !== null || selectedCellIndex === clickedCellIndex)? {x: -140  } : {}}
                            allowEscapeViewBox={{x: true, y: true}}
                            content={renderTooltipContent}
                            wrapperStyle={{
                                zIndex: 1000,
                                visibility: clickedCellIndex !== null || clickedCellIndex !== selectedCellIndex ? 'visible' : 'hidden'
                            }}
                        />}
                    </PieChart>
                </ResponsiveContainer>
            </div>
            <div className="elementStatistics__data">
                {projectData.data && Object.keys(projectData.data).map((key)=>(
                        <div className="elementStatistics__data__item" key={key}>
                            <span style={{background:projectData.data[key].color}} className="elementStatistics__data__colorSpan"></span>
                            {projectData.data[key].title}
                        </div>
                ))}
            </div>
        </div>
    )
}

export default ElementStatistics;