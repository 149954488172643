import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setLoader } from "../store/slice/dashboard";

export const useErrorHandler = (errors) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    for (let i = 0; i <= errors?.length; i++) {
      if (errors[i]) {
        if (
          errors[i]?.status === 401 ||
          errors[i]?.originalStatus === 401 ||
          errors[i]?.data?.message === "Your account has been deactivated."
        ) {
          // toast.error("Please login first", {
          //   position: toast.POSITION.TOP_RIGHT,F
          //   autoClose: 8000,
          // });
          if (!localStorage?.getItem("user_token")) {
            localStorage.removeItem("user_token");
            setTimeout(() => {
              navigate("/login");
            }, 500);
          }
          return;
        } else {
          if (
            errors[i]?.data?.errors?.length > 0 ||
            errors[i]?.data?.errors[Object.keys(errors[i]?.data?.errors)]
          ) {
            toast.error(
              errors[i]?.data?.errors[
                Object.keys(errors[i]?.data?.errors)[0]
              ][0],
              {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 8000,
              }
            );
          } else {
            if (
              errors[i]?.data?.message === "Access denied" ||
              errors[i]?.data?.message === "Project not found"
            ) {
              navigate("/project-list");
            }
            toast.error(errors[i]?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 8000,
            });
          }
        }
        break;
      }
      dispatch(setLoader(false));
    }
  }, errors);

  return [];
};
