import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import Modal from "../../../components/Modal";
import Timer from "../../../components/Timer";
import AxelInput from "../../../UI/AxelInput";
import {parseHtml} from "../../../global/parseHtml";
import AuthWrapper from "../../../components/AuthWrapper";
import {errorHandle} from "../../../global/errorHandle";
import {formatEmail, formatPhoneNumber} from "../../../global/utils";
import {
    useLazyGetMFAConfirmPageQuery, useLazyGetNotReceiveCodeTextQuery,
    useLazyMFAuthQuery,
    useLazyMFConfirmQuery
} from "../../../store/service/adbService";
import {setLoader} from "../../../store/slice/dashboard";
import {updateMfaData} from "../../../store/slice/mfAuth";
import './../index.scss';

const EnterCode = ({    }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [pageData, setPageData] = useState({});
    const [receiveCodeData, setReceiveCodeData] = useState({});
    const [isShowResend, setIsShowResend] = useState(false);
    const [isOpenMoreInfo, setIsOpenMoreInfo] = useState(false);
    const [code, setCode] = useState('');
    const hasPageBeenRendered = useRef(false)

    const mfaType = useSelector((state) => state.mfaReducer.mfa_method_type);
    const mfaToken = useSelector((state) => state.mfaReducer.mfa_token);
    const isMfaConfirm = useSelector((state) => state.mfaReducer.mfa_confirm);

    const [getNotReceiveCodeTranslations,
        {data: howToReceiveCodeTrans}
    ] = useLazyGetNotReceiveCodeTextQuery();

    const [mfAuthRequest, {
        isLoading: mfAuthIsLoading,
        isError: isErrorMfAuth,
        error: errorMfAuth,
        isSuccess: isSuccessMfAuth,
        data: mfAuthRequestData,
    }] = useLazyMFAuthQuery();

    const [getAuthPageData] = useLazyGetMFAConfirmPageQuery();
    const [mFConfirmRequest, {data, isLoading, error, isError, isSuccess}] = useLazyMFConfirmQuery();

    const inputHandler = (event) => {
        const inputValue = event.target.value;
        if (inputValue.length <= 6 && /^\d*$/.test(inputValue)) {
            setCode(inputValue);
        }
    };

    const submitHandler =  (e) => {
        e.preventDefault();
         mFConfirmRequest({
            type:mfaType,
            code: code,
            mfa_token:mfaToken
        })
    }

    const reSendCode = async () => {
        await mfAuthRequest({
            type: mfaType,
            mfa_token:mfaToken
        })
            .then(() => {
                setIsShowResend(false);
            })
    }

    const handleTimerEnd = () => {
        setIsShowResend(true);
    };

    useEffect(() => {
        if (hasPageBeenRendered.current){
           dispatch(updateMfaData({
               data: data?.data,
               mfa_method: false,
               mfa_confirm: false,
               remember_device:true
           }));
            localStorage.setItem("mfa_token", data?.data?.mfa_token);
            localStorage.setItem("local_lan", data?.data?.locale?.code )
        }
        hasPageBeenRendered.current=true;
    }, [data]);

    useEffect(() => {
        isLoading || mfAuthIsLoading ? dispatch(setLoader(true)) : dispatch(setLoader(false));
    }, [ isLoading, mfAuthIsLoading]);

    useEffect(() => {
        if (isSuccess){
            navigate('/remember-device');
        }
        if (isError){
            errorHandle(error)
        }
    },[isSuccess, error, isError]);

    useEffect(() => {
        if (isErrorMfAuth){
            errorHandle(errorMfAuth)
        }
    }, [isErrorMfAuth,errorMfAuth]);

    useEffect(() => {
        if (isSuccessMfAuth){
            toast.success(mfAuthRequestData?.message)
        }
    }, [isSuccessMfAuth,errorMfAuth]);

    useEffect(() => {
        if (mfaToken && isMfaConfirm){
            getAuthPageData(mfaToken)
                .unwrap()
                .then(fulfilled => {
                    setPageData(fulfilled)
                })
                .catch(rejected => console.error(rejected))
            mfaType && getNotReceiveCodeTranslations({type: mfaType, mfa_token: mfaToken})
        } else {
             navigate("/login");
        }
    }, []);

    useEffect(()=>{
        if(howToReceiveCodeTrans?.data){
            setReceiveCodeData(howToReceiveCodeTrans.data?.translations)
        }
    },[howToReceiveCodeTrans]);

    return(
        <AuthWrapper className='enterCode'>
            <h1 className="auth__title mf-auth__title"> {pageData?.data?.translations['headers.enter_code']}  </h1>
            <p className="auth__descr mf-auth__description">
                {pageData?.data?.translations['titles.enter_code_sent_to']} {` `}
                {mfaType==='google' && pageData?.data?.data[mfaType]}
                {mfaType==='email' && formatEmail(pageData?.data?.data[mfaType])}
                {mfaType==='phone' && formatPhoneNumber(pageData?.data?.data[mfaType])}
            </p>
            <form action="#" onSubmit={submitHandler}>
                <div className="mf-auth__inputFiledContainer">
                    <AxelInput
                        id="enter-code"
                        className="mf-auth__inputContainer__inputField"

                        placeholder={pageData?.data?.translations['titles.enter_code']}
                        value={code}
                        type="number"
                        maxLength={6}
                        handler={inputHandler}
                    />
                    <div className='enterCode__wrapper'>
                        <button className='enterCode__greyBtn' type="button" onClick={()=>setIsOpenMoreInfo(true)}>
                            {receiveCodeData?.['headers.did_not_receive_code']}
                        </button>
                        {isShowResend ? <button
                                type="button"
                                onClick={reSendCode}>
                                {pageData?.data?.translations['actions.resend_code']}
                            </button>:
                            <Timer onTimerEnd={handleTimerEnd} />
                        }
                    </div>
                </div>
                <button
                    className={`mf-auth__submitBtn ${code.length < 6 ? 'mf-auth__submitBtn_disable' : 'mf-auth__submitBtn_allow'}`}
                    disabled={code.length < 6}
                    type="submit"
                >
                    {pageData?.data?.translations['actions.submit']}
                </button>
            </form>
            <button className='enterCode__greyBtn enterCode_center ' onClick={()=>navigate("/authentication")}>
                {pageData?.data?.translations['actions.choose_another_method']}
            </button>
            <p className="mf-auth__info">
                {pageData?.data?.translations['titles.contact_us']}
                  <a href={`mailto:${pageData?.data?.translations['titles.contact_us_email']}`}>
                   {pageData?.data?.translations['titles.contact_us_email']}
                </a>
            </p>

            {isOpenMoreInfo && <Modal
                className={"moreInfo"}
                closeModal={setIsOpenMoreInfo}
                title={receiveCodeData?.['headers.did_not_receive_code']}
            >
                {mfaType ==='email' && <>
                    <p>
                        {receiveCodeData?.['titles.if_you_do_not_receive_email']}
                    </p>
                    <div className="moreInfo__infoContainer">
                        <h5>{receiveCodeData?.['headers.check_your_spam_folder']}</h5>
                        <ul>
                            <li>  {receiveCodeData?.['titles.check_any_other_folders']}</li>
                            <li>  {receiveCodeData?.['titles.update_your_email_application']}</li>
                            <li>   {parseHtml(receiveCodeData?.['titles.add_email_contact'])}</li>
                        </ul>
                    </div>
                    <div className="moreInfo__infoContainer">
                        <h5>{receiveCodeData?.['headers.click_resend_code']}</h5>
                        <ul>
                            <li>  {parseHtml(receiveCodeData?.['titles.you_may_need_to_contact_your_email'])}</li>
                        </ul>
                    </div>
                </>}
                {mfaType ==='phone' && <>
                    <p>
                        {receiveCodeData?.['headers.if_you_did_not_receive_message']}
                    </p>
                    <div className="moreInfo__infoContainer">
                        <h5>{receiveCodeData?.['headers.click_resend_code_phone']}</h5>
                        <ul>
                            <li>  {receiveCodeData?.['titles.check_your_phone']}</li>
                            <li>  {receiveCodeData?.['titles.confirm_that_phone_number']}</li>
                            <li>  {receiveCodeData?.['titles.contact_your_mobile_service_provider']}</li>
                            <li>  {receiveCodeData?.['titles.wait_few_moments_for_message_to_arrive']}</li>
                            <li>  {receiveCodeData?.['titles.you_may_need_to_try_alternate_method']}</li>
                        </ul>
                    </div>
                </>}
                {mfaType ==='google' && <>
                    <p>
                        {receiveCodeData?.['headers.if_you_did_not_receive_code']}
                    </p>
                    <div className="moreInfo__infoContainer">
                        <h5>{receiveCodeData?.['headers.click_resend_code_google']}</h5>
                        <ul>
                            <li>  {receiveCodeData?.['titles.open_your_authenticator_app']}</li>
                            <li>  {receiveCodeData?.['titles.check_that_time_on_your_device']}</li>
                            <li>  {receiveCodeData?.['titles.ensure_you_are_looking_at_correct_account']}</li>
                            <li>  {receiveCodeData?.['titles.if_you_have_multiple_accounts']}</li>
                            <li>  {receiveCodeData?.['titles.if_you_recently_changed_or_reset_your_device']}</li>
                            <li>  {receiveCodeData?.['titles.you_may_need_to_try_alternate_method']}</li>
                        </ul>
                    </div>
                </>}
            </Modal>}
        </AuthWrapper>
    )
}

export default EnterCode;