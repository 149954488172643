import axios from "axios";
import {toast} from "react-toastify";
import {setLoader} from "../store/slice/dashboard";

const exportSectionAsCSV = (url, fileName, dispatch) => {
    const lanCode = localStorage.getItem("local_lan");
    dispatch(setLoader(true));
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + localStorage.getItem("user_token"),
        "AppLocale": `${lanCode ? lanCode : 'en'}`
    };

    axios
        .get(`${process.env.REACT_APP_API_URL}${url}`, {
            headers: headers,
            responseType: "blob",
        })
        .then(({ data: blob }) => {
            const link = document.createElement("a");
            const url = URL.createObjectURL(blob);
            link.href = url;
            link.download = `${fileName}.csv`;
            link.click();
            dispatch(setLoader(false));
        })
        .catch((e) => {
            if (e.response && e.response.data) {
                const reader = new FileReader();
                reader.onload = () => {
                    try {
                        const errorData = JSON.parse(reader.result);
                        toast.error(
                            errorData?.message
                                ? errorData.message
                                : "Something went wrong.",
                            {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 3000,
                            }
                        );
                    } catch (err) {
                        toast.error("Something went wrong.", {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    }
                    dispatch(setLoader(false));
                };
                reader.readAsText(e.response.data);
            } else {
                toast.error("Something went wrong.", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
                dispatch(setLoader(false));
            }
        });
};

export default exportSectionAsCSV;