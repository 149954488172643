import React, {useEffect, useState} from 'react';
import {
    useDeleteDocumentMutation, useGetAllProjectDocumentsMutation,
    useLazyGetAllProjectsQuery,
    useUpdateDocumentMutation
} from "../../../store/service/adbService";
import Select from "react-select";
import axios from "axios";
import TemplateComponent from "../TemplateComponent";
import {useDispatch, useSelector} from "react-redux";
import DocumentComponent from "../../DocumentComponent";
import {setLoader} from "../../../store/slice/dashboard";

const customStyles = {
    menu: (provided, state = false) => ({
        ...provided,
        width: "100%",
        color: state.selectProps.menuColor,
        cursor: "pointer",
        zIndex: 50,
        overflow: "hidden",
    }),

    control: (_, { selectProps: { width } }) => ({
        width: "100%",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        display: "flex",
        background: "#FFFFFF",
        borderRadius: 8,
        padding: 8,
    }),
};

const Index = ({translations}) => {
    const user = useSelector((state) => state?.adbReducer?.user);
    const [currentPage, setCurrentPage] = useState(1);
    const [projectOptions, setProjectOptions] = useState([]);
    const [selectedProject, setSelectedProject] = useState();
    const dispatch = useDispatch();

    const [
        deleteDocument,
        {
            data: deleteDocumentData,
            isError: deleteDocumentIsError,
            isLoading: deleteDocumentIsLoading,
            isSuccess: deleteDocumentIsSuccess,
            error: deleteDocumentError,
        },
    ] = useDeleteDocumentMutation();

    const [
        updateDocument,
        {
            data: updateDocumentData,
            isError: updateDocumentIsError,
            isLoading: updateDocumentIsLoading,
            isSuccess: updateDocumentIsSuccess,
            error: updateDocumentError,
        },
    ] = useUpdateDocumentMutation();

    const [
        getAllProjects,
        {
            data: allProjects,
            isError: projectsIsError,
            isLoading: projectsLoading,
            isSuccess: projectsSuccess,
            error: projectsError,
        },
    ] = useLazyGetAllProjectsQuery();

    const [
        getAllProjectDocuments,
        {
            data: allProjectDocuments,
            isError: projectDocumentsIsError,
            isLoading: projectDocumentsLoading,
            isSuccess: projectDocumentsSuccess,
            error: projectDocumentsError,
        }
    ] = useGetAllProjectDocumentsMutation()

    useEffect(() => {
        getAllProjects({
            per_page: 1000,
            page: currentPage,
        });
    }, [currentPage]);

    useEffect(() => {
        if (selectedProject){
            getAllProjectDocuments({projectId: selectedProject?.value})
        }
    }, [selectedProject, deleteDocumentIsLoading, updateDocumentIsLoading]);

    useEffect(() => {
        if (allProjects?.data?.data) {
            let data = allProjects?.data?.data?.map((item) => {
                return {
                    value: item?.id,
                    label: item?.project_name,
                };
            });
            setProjectOptions(data);
        }
    }, [allProjects]);

    useEffect(() => {
        if (deleteDocumentIsLoading || updateDocumentIsLoading) {
            dispatch(setLoader(true));
        }
        if (!deleteDocumentIsLoading && !updateDocumentIsLoading) {
            dispatch(setLoader(false));
        }

    }, [deleteDocumentIsLoading, updateDocumentIsLoading]);

    return (
        <div>
            <div className="s-page__document">
                <p>{translations?.['report_documents.index.titles.project']}</p>
                <Select
                    options={projectOptions}
                    styles={{
                        ...customStyles,
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    isSearchable={true}
                    placeholder={translations?.['report_documents.index.placeholder.select_project']}
                    value={selectedProject}
                    onChange={(selected) => {
                        setSelectedProject(selected);
                    }}
                />
            </div>
            <div className="contract-management__form">
                {allProjectDocuments?.data?.map((item, index)=>{
                    return(
                        <div className='document-row'>
                            <DocumentComponent
                                deleteDocument={deleteDocument}
                                updateDocument={updateDocument}
                                doc={item}
                                key={index}
                                role={user?.role}
                                translations={translations}
                            />
                        </div>
                    )
                })}
            </div>
        </div>
    );
};

export default Index;