import React from "react";
import "./index.scss";

export const DeleteModal = ({ 
  id, 
  action, 
  setDeleteModal, 
  clarifyingText, 
  isPhoto, 
  isContact, 
  modalTitle,
  cencelText,
  deleteText
 }) => {
  return (
    <div className="project__block-modal">
      <div className="project__block-modal-content">
        <div className="project__block-modal-header">
          <div className="project__block-modal-title">{modalTitle || 'Confirm your action'}</div>
          <div
            className="project__block-modal-close"
            onClick={() => setDeleteModal(null)}
          >
            <img src="/assets/icons/close-mark.svg" alt="close" />
          </div>
        </div>
        <div className="project__block-modal-text">
            {clarifyingText ? clarifyingText : `You are going to delete this ${isPhoto ? 'photo' : isContact ? 'contact' : 'row'} are you sure?`}
        </div>
        <div className="project__block-modal-btns">
          <div
            className="project__block-modal-cancel"
            onClick={() => setDeleteModal(null)}
          >
            {cencelText || 'Cancel'}
          </div>
          <div
            className="project__block-modal-delete"
            onClick={() => {
              action(id);
              setDeleteModal(null);
            }}
          >
            {deleteText || 'Delete'}
          </div>
        </div>
      </div>
      <div
        className="project__block-modal-bg"
        onClick={() => setDeleteModal(null)}
      ></div>
    </div>
  );
};
