import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {useLazyGetAllCompaniesQuery, useUpdateProjectMutation} from "../../../store/service/adbService";
import { useErrorHandler } from "../../../global/ErrorsHandler";
import { toast } from "react-toastify";
import { setLoader } from "../../../store/slice/dashboard";
import AxelCheckbox from '../../../UI/AxelCheckbox'

const customStyles = {
    menu: (provided, state = false) => ({
        ...provided,
        width: "100%",
        color: state.selectProps.menuColor,
        cursor: "pointer",
    }),

    control: (_, { selectProps: { width } }) => ({
        width: "100%",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        display: "flex",
        marginTop: 8,
        background: "#F9F9FB",
        borderRadius: 8,
        padding: 2,
        cursor: "pointer",
    }),
};

export const EditProjectStep3 = ({setSteps, translations}) => {
    const dispatch = useDispatch();
    const location = useLocation()
    const navigate = useNavigate();
    const params = {
        project: location?.pathname?.match(/\/\d+/g, "")[0].replace(/\//g, ""),
      };
    const allEnterDatas = JSON.parse(localStorage.getItem("all-edit-data"));
    const enterDatas = JSON.parse(localStorage.getItem("edit-data"));
    const [dataTr, setDataTr] = useState([])
    const [options, setOptions] = useState([]);

    const [state, setState] = useState({
        adb: enterDatas?.adb_id ?? {value: allEnterDatas?.contract_details?.adb?.id, label: allEnterDatas?.contract_details?.adb?.long_name} ?? null,
        not_applicable_adb: allEnterDatas?.contract_details?.adb?.not_applicable ?? false,
        executing: enterDatas?.executing_agency_id ?? {value: allEnterDatas?.contract_details?.executing_agency?.id, label: allEnterDatas?.contract_details?.executing_agency?.long_name} ?? null,
        not_applicable_executing_agency: allEnterDatas?.contract_details?.executing_agency?.not_applicable ?? false,
        contractor: enterDatas?.contractor_id ?? {value: allEnterDatas?.contract_details?.contractor?.id, label: allEnterDatas?.contract_details?.contractor?.long_name} ?? null,
        not_applicable_contractor: allEnterDatas?.contract_details?.contractor?.not_applicable ?? false,
        designConsultant: enterDatas?.design_consultant_id ?? {value: allEnterDatas?.contract_details?.design_consultant?.id, label: allEnterDatas?.contract_details?.design_consultant?.long_name} ?? null,
        not_applicable_design_consultant: allEnterDatas?.contract_details?.design_consultant?.not_applicable ?? false,
        projectManager: enterDatas?.project_manager_id ?? {value: allEnterDatas?.contract_details?.project_manager?.id, label: allEnterDatas?.contract_details?.project_manager?.long_name} ?? null,
        not_applicable_project_manager: allEnterDatas?.contract_details?.project_manager?.not_applicable ?? false,
        implementing_agency: enterDatas?.implementing_agency_id ?? {value: allEnterDatas?.contract_details?.implementing_agency?.id, label: allEnterDatas?.contract_details?.implementing_agency?.long_name} ?? null,
        not_applicable_implementing_agency: allEnterDatas?.contract_details?.implementing_agency?.not_applicable ?? false,
        supplier: enterDatas?.supplier_id ?? {value: allEnterDatas?.contract_details?.supplier?.id, label: allEnterDatas?.contract_details?.supplier?.long_name} ?? null,
        not_applicable_supplier: allEnterDatas?.contract_details?.supplier?.not_applicable ?? false,
        engineer: enterDatas?.engineer_id ?? {value: allEnterDatas?.contract_details?.engineer?.id, label: allEnterDatas?.contract_details?.engineer?.long_name} ?? null,
        not_applicable_engineer: allEnterDatas?.contract_details?.engineer?.not_applicable ?? false,
        consultant: enterDatas?.consultant_id ?? {value: allEnterDatas?.contract_details?.consultant?.id, label: allEnterDatas?.contract_details?.consultant?.long_name} ?? null,
        not_applicable_consultant: allEnterDatas?.contract_details?.consultant?.not_applicable ?? false,
    });

    useEffect(() => {
        if(translations){
            setDataTr(translations);
        }
    }, [translations])
    const [
        editProject,
        {
            data: createData,
            isSuccess: createSuccess,
            error: createError,
            isError: createIsError,
            isLoading: createLoading,
        },
    ] = useUpdateProjectMutation();

    const [getCompanies, {
        data: companies,
        error: companiesError,
        isLoading: companiesIsLoading,
        isError: companiesIsError,
        isSuccess: companiesIsSuccess,
    }] = useLazyGetAllCompaniesQuery();

    useEffect(() => {
        getCompanies({
            per_page: 1000,
        })
    }, [])
    useEffect(() => {
        if (companies?.data?.data) {
            let data = companies?.data?.data?.map((item) => ({
                value: item?.id,
                label: item?.long_name,
            }));
            setOptions(data);
        }
    }, [companies]);

    const [errorHandler, setErrorHandler] = useErrorHandler([
        createError,
        companiesError,
    ]);
    useEffect(() => {
        companiesIsLoading || createLoading
            ? dispatch(setLoader(true))
            : dispatch(setLoader(false));
    }, [
        companiesIsLoading,
        createLoading,
    ]);
    useEffect(() => {
        if (createSuccess) {
            toast.success("Project updated", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
            localStorage.removeItem("create-data");
            navigate(`/project/${params?.project}`);
        }
    }, [createSuccess]);
    const submitHandler = (e) => {
        e.preventDefault();
        let body = {

        };

        let s = [];
        for (let key in body) {
            if (!body[key] || body[key] === "Invalid date" || body[key] === "") {
                toast.error("Please enter " + key?.split('_')?.join(' '), {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
                s.push("false");
            }
        }
        if (s.length === 0) {
            if (s.length === 0) {
                localStorage.setItem(
                    "edit-data",
                    JSON.stringify({
                        ...JSON.parse(localStorage.getItem("edit-data")),
                        ...body,
                        adb_id: state?.adb?.value ?? null,
                        executing_agency_id: state?.executing?.value ?? null,
                        contractor_id: state?.contractor?.value ?? null,
                        design_consultant_id: state?.designConsultant?.value ?? null,
                        project_manager_id: state?.projectManager?.value ?? null,
                        implementing_agency_id: state?.implementing_agency?.value ?? null,
                        supplier_id: state?.supplier?.value ?? null,
                        engineer_id: state?.engineer?.value ?? null,
                        consultant_id: state?.consultant?.value ?? null,
                        not_applicable_adb: state?.not_applicable_adb,
                        not_applicable_executing_agency: state?.not_applicable_executing_agency,
                        not_applicable_implementing_agency: state?.not_applicable_implementing_agency,
                        not_applicable_contractor: state?.not_applicable_contractor,
                        not_applicable_supplier: state?.not_applicable_supplier,
                        not_applicable_design_consultant: state?.not_applicable_design_consultant,
                        not_applicable_project_manager: state?.not_applicable_project_manager,
                        not_applicable_engineer: state?.not_applicable_engineer,
                        not_applicable_consultant: state?.not_applicable_consultant,
                    })
                );
                setSteps(4)
            }
        }
    };

    return (
        <form className="create__form">
            <div className='create__subTitle'>{translations?.['main.edit_projects_details.titles.by_selecting_checkbox_active_parties']}</div>
            <div className="create__form-content">
                <div className="create__form-row">
                    <AxelCheckbox id="not_applicable_adb" label={dataTr?.['main.edit_projects_details.titles.adb']} defaultChecked={state?.not_applicable_adb} handler={(id, status) => {
                        setState((old) => ({...old, not_applicable_adb: status}))
                    }} />
                    <div className="create__form-input">
                        <Select
                            menuPortalTarget={document.body}
                            styles={({...customStyles, menuPortal: base => ({...base, zIndex: 9999})})}
                            options={options}
                            value={state?.adb}
                            onChange={(selected) =>
                                setState((old) => ({ ...old, adb: selected }))
                            }
                        />
                    </div>
                </div>
                <div className="create__form-row">
                    <AxelCheckbox id="not_applicable_executing_agency" defaultChecked={state?.not_applicable_executing_agency} label={dataTr?.['main.edit_projects_details.titles.executing_agency']} handler={(id, status) => {
                        setState((old) => ({...old, [id]: status}))
                    }} />
                    <div className="create__form-input">
                        <Select
                            menuPortalTarget={document.body}
                            styles={({...customStyles, menuPortal: base => ({...base, zIndex: 9999})})}
                            options={options}
                            value={state?.executing}
                            onChange={(selected) =>
                                setState((old) => ({ ...old, executing: selected }))
                            }
                        />
                    </div>
                </div>
                <div className="create__form-row">
                    <AxelCheckbox id="not_applicable_implementing_agency" defaultChecked={state?.not_applicable_implementing_agency} label={dataTr?.['main.edit_projects_details.titles.implementing_agency']} handler={(id, status) => {
                        setState((old) => ({...old, [id]: status}))
                    }} />
                    <div className="create__form-input">
                        <Select
                            menuPortalTarget={document.body}
                            styles={({...customStyles, menuPortal: base => ({...base, zIndex: 9999})})}
                            options={options}
                            value={state?.implementing_agency}
                            onChange={(selected) =>
                                setState((old) => ({ ...old, implementing_agency: selected }))
                            }
                        />
                    </div>
                </div>
                <div className="create__form-row">
                    <AxelCheckbox id="not_applicable_contractor" defaultChecked={state?.not_applicable_contractor} label={dataTr?.['main.edit_projects_details.titles.contractor']} handler={(id, status) => {
                        setState((old) => ({...old, [id]: status}))
                    }} />
                    <div className="create__form-input">
                        <Select
                            menuPortalTarget={document.body}
                            styles={({...customStyles, menuPortal: base => ({...base, zIndex: 9999})})}
                            options={options}
                            value={state?.contractor}
                            onChange={(selected) =>
                                setState((old) => ({ ...old, contractor: selected }))
                            }
                        />
                    </div>
                </div>
                <div className="create__form-row">
                    <AxelCheckbox id="not_applicable_supplier" defaultChecked={state?.not_applicable_supplier} label={dataTr?.['main.edit_projects_details.titles.supplier']} handler={(id, status) => {
                        setState((old) => ({...old, [id]: status}))
                    }} />
                    <div className="create__form-input">
                        <Select
                            menuPortalTarget={document.body}
                            styles={({...customStyles, menuPortal: base => ({...base, zIndex: 9999})})}
                            options={options}
                            value={state?.supplier}
                            onChange={(selected) =>
                                setState((old) => ({ ...old, supplier: selected }))
                            }
                        />
                    </div>
                </div>
                <div className="create__form-row">
                    <AxelCheckbox id="not_applicable_design_consultant" defaultChecked={state?.not_applicable_design_consultant} label={dataTr?.['main.edit_projects_details.titles.design_consultant']} handler={(id, status) => {
                        setState((old) => ({...old, [id]: status}))
                    }} />
                    <div className="create__form-input">
                        <Select
                            menuPortalTarget={document.body}
                            styles={({...customStyles, menuPortal: base => ({...base, zIndex: 9999})})}
                            options={options}
                            value={state?.designConsultant}
                            onChange={(selected) =>
                                setState((old) => ({ ...old, designConsultant: selected }))
                            }
                        />
                    </div>
                </div>
                <div className="create__form-row">
                    <AxelCheckbox id="not_applicable_project_manager" defaultChecked={state?.not_applicable_project_manager} label={dataTr?.['main.edit_projects_details.titles.project_manager']} handler={(id, status) => {
                        setState((old) => ({...old, [id]: status}))
                    }} />
                    <div className="create__form-input">
                        <Select
                            menuPortalTarget={document.body}
                            styles={({...customStyles, menuPortal: base => ({...base, zIndex: 9999})})}
                            options={options}
                            value={state.projectManager}
                            onChange={(selected) =>
                                setState((old) => ({ ...old, projectManager: selected }))
                            }
                        />
                    </div>
                </div>
                <div className="create__form-row">
                    <AxelCheckbox id="not_applicable_engineer" defaultChecked={state?.not_applicable_engineer} label={dataTr?.['main.edit_projects_details.titles.engineer']} handler={(id, status) => {
                        setState((old) => ({...old, [id]: status}))
                    }} />
                    <div className="create__form-input">
                        <Select
                            menuPortalTarget={document.body}
                            styles={({...customStyles, menuPortal: base => ({...base, zIndex: 9999})})}
                            options={options}
                            value={state.engineer}
                            onChange={(selected) =>
                                setState((old) => ({ ...old, engineer: selected }))
                            }
                        />
                    </div>
                </div>
                <div className="create__form-row">
                    <AxelCheckbox id="not_applicable_consultant" defaultChecked={state?.not_applicable_consultant} label={dataTr?.['main.edit_projects_details.titles.consultant_other']} handler={(id, status) => {
                        setState((old) => ({...old, [id]: status}))
                    }} />
                    <div className="create__form-input">
                        <Select
                            menuPortalTarget={document.body}
                            styles={({...customStyles, menuPortal: base => ({...base, zIndex: 9999})})}
                            options={options}
                            value={state.consultant}
                            onChange={(selected) =>
                                setState((old) => ({ ...old, consultant: selected }))
                            }
                        />
                    </div>
                </div>
            </div>
            <div className="create__form-br"></div>
            <div className="create__form-btns">
                <div
                    className="create__form-back"
                    onClick={() => {
                        setSteps(2)
                    }}
                >
                    {dataTr?.['main.edit_projects_details.actions.back']}
                </div>
                <button onClick={submitHandler} className="create__form-next">
                    <span>{dataTr?.['main.edit_projects_details.actions.next']}</span>
                    <span>
            <img src="/assets/icons/next-icon.svg" alt="icon" />
          </span>
                </button>
            </div>
        </form>
    );
};
