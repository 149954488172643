import React, {useEffect, useRef, useState} from 'react';
import {setLoader} from "../../../../store/slice/dashboard";
import {
    useGetAllGlossaryQuery,
    useGetProgramOverviewQuery,
    useUpdateProgramOverviewMutation
} from "../../../../store/service/adbService";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {Cell, Pie, PieChart, ResponsiveContainer} from "recharts";
import MoreInfoModal from "../../../MoreInfoModal";

export const ProgramOverview = ({data, glossaryData, translations}) => {
    const dispatch = useDispatch()
    const [infoModal, setInfoModal] = useState(false)
    const [moreInfo, setMoreInfo] = useState(null)
    const [edit, setEdit] = useState(false)
    const [text, setText] = useState('')
    const [prevText, setPrevText] = useState('')
    const [pieData, setPieData] = useState([])
    const user = useSelector((state) => state?.adbReducer?.user);
    const [programData, setProgramData] = useState('')
    const expandedRef = useRef(null)
    const [expanded, setExpanded] = useState(false)

    const [
        updateProgramOverview,
        {
            data: updateProgramOverviewData,
            isError: updateProgramOverviewIsError,
            isLoading: updateProgramOverviewIsLoading,
            isSuccess: updateProgramOverviewIsSuccess,
            error: updateProgramOverviewError,
        },
    ] = useUpdateProgramOverviewMutation();

    useEffect(() => {
        if (data) {
            setProgramData(data?.program_overview)
            setText(data?.program_overview)
        }
    }, [data])

    useEffect(() => {
        const d = () => {
            dispatch(setLoader(false));
            setEdit(false)
        };

        updateProgramOverviewIsLoading
            ? dispatch(setLoader(true))
            : d();
    }, [
        updateProgramOverviewIsLoading,
        pieData
    ]);

    useEffect(() => {
        if (data) {
            setPieData([
                {name: "Overall progress", value: +data?.program_pie_chart?.overall_progress},
                {name: "Time elapsed", value: +data?.program_pie_chart?.time_elapsed},
                {name: "Time remaining", value: +data?.program_pie_chart?.time_remaining},
            ])
            setText(programData)
            setPrevText(programData ?? "Please enter overview");
        }
        setMoreInfo(glossaryData?.data?.filter(el => el?.section_name === "Program Overview")[0])
    }, [glossaryData, data, programData])

    const submitHandler = (e) => {
        e.preventDefault();
        if (text.length > 0) {
            updateProgramOverview({id: data?.id, body: {program_overview: text}})
            setExpanded(false)
            // updateExecutiveSummary({ id, body: { resume: text } });
        } else {
            toast.error("Please enter summary", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }
    };

    const COLORS = ["#0CAF60", "#ECC949", "#3375D6"];

    return (
        <div className="project__program-overview">
            {infoModal && (
                <MoreInfoModal
                    title={translations?.['project_monitoring.program_overview.headers.program_overview']}
                    moreInfo={translations?.['project_monitoring.program_overview.more_info.program_overview']}
                    close={()=> setInfoModal(false)}
                />
            )}
            <div className="project__program">
                <div className="project__program-subtitles">
                    <div className="project__program-subtitle">
                        {translations?.['project_monitoring.program_overview.headers.program_overview']}
                        <span onClick={() => setInfoModal(!infoModal)} className="project__block-more">
                        <span className="img">
                        <img src="/assets/icons/info.svg" alt="info"/>
                    </span>
                    <span  className="project__program-text">{translations?.['project_monitoring.critical_path.titles.more_info']}</span>
                    </span>
                    </div>
                </div>
                <div className="project__program-content">
                    <div className={`project__program-left ${expanded ? 'expanded-input' : ''} ${edit ? 'edit' : ''}`}>
                        {edit ? (
                            <textarea
                                className={`${expanded ? 'project__block-expandedverview' : ''}`}
                                ref={expandedRef}
                                rows={7}
                                defaultValue={programData}
                                placeholder={translations?.['project_monitoring.program_overview.placeholder.please_enter']}
                                maxLength={2000}
                                onChange={(e) => {
                                    setText(e.target.value);
                                }}
                            />
                        ) : (
                            <p className="project__program-desc">
                                {text}
                            </p>
                        )}
                        {
                            user?.permissions?.edit_project &&
                            <div className="project__block-btns">
                                {edit && (
                                    <div
                                        className="project__block-cancel"
                                        onClick={() => {
                                            setText(prevText);
                                            setEdit(false);
                                        }}
                                    >
                                        <img src="/assets/icons/close-mark.svg" alt="close" />
                                        {translations?.['project_monitoring.program_overview.actions.cancel']}
                                    </div>
                                )}
                                {edit ? (
                                    <button onClick={(e) => submitHandler(e)} type="submit" className="project__block-save">
                                        <img src="/assets/icons/save-arrow.svg" alt="arrow" />
                                        {translations?.['project_monitoring.program_overview.actions.save']}
                                    </button>
                                ) : (
                                    <div
                                        onClick={() => {
                                            setEdit(true);
                                        }}
                                        className="project__block-update"
                                    >
                                        {translations?.['project_monitoring.program_overview.actions.edit']}
                                    </div>
                                )}
                            </div>
                        }
                    </div>
                    <div className="project__program-right">
                        <div className="project__program-circle">
                            <PieChart height={152} width={152}>
                                <Pie
                                    data={pieData}
                                    dataKey="value"
                                    nameKey="name"
                                    color="#000000"
                                    fill="#8884d8"
                                    innerRadius={35}
                                    cx="50%"
                                    cy="50%"
                                    key={"pie_chart_2"}
                                >
                                    {
                                        pieData?.map((el,idx) => (
                                            <Cell  key={`${el.value}-${idx}`}
                                                   fill={COLORS[idx % COLORS.length]}
                                            />
                                        ))
                                    }
                                </Pie>
                            </PieChart>
                        </div>
                        <div className="project__program-stats">
                            <div className="project__program-stat">
                                <div className="project__program-color green"></div>
                                <p>{translations?.['project_monitoring.program_overview.titles.overall_progress']} <span>{data?.program_pie_chart?.overall_progress ? data?.program_pie_chart?.overall_progress.toFixed(1) : '0'}%</span></p>
                            </div>
                            <div className="project__program-stat">
                                <div className="project__program-color yellow"></div>
                                <p>{translations?.['project_monitoring.program_overview.titles.time_elapsed']} <span>{data?.program_pie_chart?.time_elapsed ? data?.program_pie_chart?.time_elapsed.toFixed(1) : '0'}%</span></p>
                            </div>
                            <div className="project__program-stat">
                                <div className="project__program-color blue"></div>
                                <p>{translations?.['project_monitoring.program_overview.titles.time_remaining']} <span>{data?.program_pie_chart?.time_remaining ? data?.program_pie_chart?.time_remaining.toFixed(1) : '0'}%</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
