import React from "react";
import { useState } from "react";
import classNames from "classnames";

import "./index.scss";

export default function AxelInput({
  placeholder = "Placeholder",
  value = "",
  id,
  name,
  handler,
  error = "Error",
  errorShow = false,
  type = "text",
  src,
  autoFocus = false,
  hideCustomPaceHolder,
  disabled,
  maxLength=190,
  className, ...input
}) {
  const [typeInput, setTypeInput] = useState(type);

  const iconHandler = () => {
    typeInput === "password" ? setTypeInput("text") : setTypeInput("password");
  };

  return (
    <div
        className={classNames('a-input', {['error']:errorShow,  ['active']: value !== ""})}
    >
      <div className={classNames('input', className)}>
        {src && <div className="a-input-img">
          <img src={src} alt=""/>
        </div>}
        <input
          id={id}
          value={value}
          name={name}
          maxLength={maxLength}
          type={type === "password" ? typeInput : type}
          onChange={handler}
          autoComplete="off"
          disabled={disabled}
          autoFocus={autoFocus}
          placeholder={placeholder}
          {...input}
        />
        {hideCustomPaceHolder && placeholder && placeholder !== "Placeholder" && <label htmlFor={id}>{placeholder}</label>}
        {typeInput === "password" ? (
          <img
            src="/assets/icons/n-show-pass.svg"
            alt="icon"
            onClick={iconHandler}
          />
        ) : (
          type === "password" && (
            <img
              src="/assets/icons/show-password.svg"
              alt="icon"
              onClick={iconHandler}
            />
          )
        )}
      </div>

      {errorShow && <div className="a-input__error">{error}</div>}
    </div>
  );
}
