import React, {useEffect, useState} from 'react';
import '../ReportModal/index.scss'

export default function RedirectModal({redirectModal, close, translations}) {
    useEffect(() => {
        if (!redirectModal) {
            setTimeout(() => {
                window.location.reload();
            }, 0)
        }
    }, [redirectModal])
    return (
        <div className="reportModal">
            <div className="reportModal__content">
                <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                    <h2 className="reportModal__title">{translations?.['main.main.headers.report_submitted']}</h2>
                    <img src="/assets/icons/close.svg" alt="exit" onClick={() => {
                        close()
                        setTimeout(() => {
                            window.location.reload();
                        }, 500)
                    }}/>
                </div>
                <p className="reportModal__attention">
                    {translations?.['main.main.titles.to_download_report_please_go_to']}
                </p>
            </div>
            <div className="reportModal__bg" onClick={() => {
                close()
                setTimeout(() => {
                    window.location.reload();
                }, 500)
            }}></div>
        </div>
    );
};