import React from 'react';
import {useSelector} from "react-redux";

function QuarterlyOtherCovenants({pageTranslations}) {
	const other_covenants = useSelector(state => state?.quarterlyReducer?.online_view?.sections?.covenants?.other_covenants) ?? null;
	const sections_title = useSelector(state => state?.quarterlyReducer?.sections_title) ?? null;
	
	if (!other_covenants) {
		return <div></div>
	}
	
	return (
			<>
				<p style={{marginTop: 50}}>6.6 {pageTranslations?.['covenants.other_covenants.headers.other_covenants']}</p>
				<div className={"quarterly-online-table"} style={{marginTop: 20}}>
					<table border="1">
						<thead>
						<tr className={"row_gray"}>
							<th>{pageTranslations?.['covenants.other_covenants.table_headers.schedule']}</th>
							<th>{pageTranslations?.['covenants.other_covenants.table_headers.para_no']}</th>
							<th colSpan={2}>{pageTranslations?.['covenants.other_covenants.table_headers.description']}</th>
							<th>{pageTranslations?.['covenants.other_covenants.table_headers.due_date']}</th>
							<th>{pageTranslations?.['covenants.other_covenants.table_headers.compiled_date']}</th>
							<th>{pageTranslations?.['covenants.other_covenants.table_headers.delays_months']}</th>
							<th>{pageTranslations?.['covenants.other_covenants.table_headers.compiled']}</th>
							<th>{pageTranslations?.['covenants.other_covenants.table_headers.status_as_of']}</th>
						</tr>
						</thead>
						<tbody>
						{other_covenants?.map((item, index) => (
								<tr key={index}>
									<td>{item?.schedule}</td>
									<td>{item?.para_no}</td>
									<td colSpan={2} dangerouslySetInnerHTML={{__html: item?.description}}></td>
									<td>{item?.due_date}</td>
									<td>{item?.compiled_date}</td>
									<td>{item?.delays}</td>
									<td>{item?.compiled}</td>
									<td>{item?.status_as_of}</td>
								</tr>
						))}
						</tbody>
					</table>
				</div>
			</>
	);
}

export default QuarterlyOtherCovenants;
