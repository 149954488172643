import React, { useEffect, useState } from "react";
import { useNavigate, useParams} from "react-router-dom";
import Wrapper from "../../components/Wrapper";
import { EditProjectStep1 } from "../../components/EditProject/Step1";
import { EditProjectStep2 } from "../../components/EditProject/Step2";
import { EditProjectStep3 } from "../../components/EditProject/Step3";
import { useLazyGetProjectQuery, useLazyGetReportsDetailsTranslationsQuery } from "../../store/service/adbService";
import { useDispatch } from "react-redux";
import { setLoader } from "../../store/slice/dashboard";
import {EditProjectStep4} from "../../components/EditProject/Step4";
import {toast} from "react-toastify";

export const EditProjectPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [steps, setSteps] = useState(1);
  const [visible, setVisible] = useState(false);
  const [translations, setTranslations] = useState([])

  const [
    getReportsDetailsTranslations,
    {
      data: reportDetailsTranslationsData,
      isError: reportDetailsTranslationsError,
      isLoading: reportDetailsTranslationsLoading
    }] = useLazyGetReportsDetailsTranslationsQuery()

    useEffect(() => {
      getReportsDetailsTranslations()
    }, [])

    useEffect(() => {
      setTranslations(reportDetailsTranslationsData?.data?.translations)
    }, [reportDetailsTranslationsData])

  const [
    trigger,
    { data, isError, error },
  ] = useLazyGetProjectQuery();

  const params = {
    project: id,
  };

  useEffect(() => {
    if (!id || id === 'undefined') {
      return navigate("/404")
    }
    else {
      dispatch(setLoader(true));
      localStorage.removeItem("all-edit-data");
    }
  }, []);


  useEffect(() => {
    if (!data?.data) {
      trigger(params?.project);
    } else {
      setVisible(true);
      localStorage.setItem("all-edit-data", JSON.stringify(data?.data));
    }
  }, [data]);

  useEffect(() => {
    if (isError) {
      dispatch(setLoader(false));
      toast.error(error?.data?.message ?? error?.data?.message);
      navigate("/project-list");
      console.clear();
    }
  }, [isError, error]);

  return (
    <Wrapper>
      {visible ? (
        <div className="create__box">
          <h1 className="create__title">{translations?.['main.edit_projects_details.headers.edit_project']}</h1>
          <div className="create__tabs">
            <div className={`create__tab one ${steps == 1 ? "active" : ""}`}>
              {translations?.['main.edit_projects_details.headers.contract_details']}
            </div>
            <div className={`create__tab two ${steps == 2 ? "active" : ""}`}>
              {translations?.['main.edit_projects_details.headers.contract_dates']}
            </div>
            <div className={`create__tab two ${steps == 3 ? "active" : ""}`}>
              {translations?.['main.edit_projects_details.headers.active_parties']}
            </div>
            <div className={`create__tab three ${steps == 4 ? "active" : ""}`}>
              {translations?.['main.edit_projects_details.headers.project_sections']}
            </div>
          </div>
          <div className="create__content">
              <div style={steps === 1 ? {display: 'block'} : {display: 'none'}}>
                <EditProjectStep1 data={data?.data} setSteps={setSteps} translations={translations}/>
              </div>
              <div style={steps === 2 ? {display: 'block'} : {display: 'none'}}>
                <EditProjectStep2 data={data?.data} setSteps={setSteps} translations={translations}/>
              </div>
              <div style={steps === 3 ? {display: 'block'} : {display: 'none'}}>
                <EditProjectStep3 data={data?.data} setSteps={setSteps} translations={translations}/>
              </div>
              <div style={steps === 4 ? {display: 'block'} : {display: 'none'}}>
                <EditProjectStep4 data={data?.data} setSteps={setSteps} translations={translations}/>
              </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </Wrapper>
  );
};
