import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import './index.scss';

const COLORS = ['#0CAF60', '#ECC949', '#E7984F', '#E28383'];

const RiskLevelMonitoring = ({ data, translations }) => {
    const [pieData, setPieData] = useState([]);

    useEffect(() => {
        if (data) {
            setPieData([
                { name: "Low", value: data.low },
                { name: "Moderate", value: data.moderate },
                { name: "Substantial", value: data.substantial },
                { name: "High", value: data.high }
            ]);
        }
    }, [data]);

    const riskLevels = [
        { name: translations?.['lists_of_tables.risk_register_of_contracts.titles.low'], value: data.low, color: COLORS[0] },
        { name: translations?.['lists_of_tables.risk_register_of_contracts.titles.moderate'], value: data.moderate, color: COLORS[1] },
        { name: translations?.['lists_of_tables.risk_register_of_contracts.titles.substantial'], value: data.substantial, color: COLORS[2] },
        { name: translations?.['lists_of_tables.risk_register_of_contracts.titles.high'], value: data.high, color: COLORS[3] },
    ];

    return (
        <div className="risk-level-monitoring">
            <div className="risk-level-header">
                <h3>{translations?.['lists_of_tables.risk_register_of_contracts.titles.risk_level']}</h3>
            </div>
            <div className="risk-level-content">
                <div className="risk-level-chart">
                    <PieChart width={172} height={172}>
                        <Pie
                            data={pieData}
                            cx={76}
                            cy={76}
                            innerRadius={50}
                            outerRadius={80}
                            fill="#8884d8"
                            paddingAngle={5}
                            dataKey="value"
                        >
                            {pieData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                    </PieChart>
                </div>
                <div className="risk-level-cards">
                    {riskLevels.map((level, index) => (
                        <div key={index} className="risk-level-card">
                            <div className="risk-level-info">
                                <div className="risk-level-color" style={{ backgroundColor: level.color }}></div>
                                <p className="risk-level-name">{level.name}</p>
                            </div>
                            <p className="risk-level-value">{(level.value).toFixed(2)}%</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default RiskLevelMonitoring;
