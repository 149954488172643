export const customData = [
  {
    id: 5,
    section_definition: "Text",
    implementation_status: "Text",
    compliance_report_due_date: new Date(17 / 6 / 2022),
    compliance_report_complete: false,
    compliance_report_actual_date: new Date(17 / 6 / 2022),
    current_status_or_comments: "text",
    edit: false,
  },
];
