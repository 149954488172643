import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import FinancialRatio from "./FinancialRatios";
import Table from "../Table";
import MyEditor from "../../../components/EditorConvertToHTML";
import { parseHtml } from "../../../global/parseHtml";
import {
    useLazyGetFinancialCovenantsQuery,
    useUpdateFinancialCovenantsMutation,
} from "../../../store/service/adbService";
import { toast } from "react-toastify";
import { errorHandle } from "../../../global/errorHandle";
import { setLoader } from "../../../store/slice/dashboard";

const FinancialCovenants = ({ data, reportId, translations, sectionId, isReportSubmitted, sections}) => {
    const dispatch = useDispatch();

    const [editingSection, setEditingSection] = useState(null);
    const [editorContent, setEditorContent] = useState('');
    const [textareaData, setTextareaData] = useState(data || {});

    const [
        getFinancialCovenants,
        {
            data: newFinancialCovenantsData,
            isLoading: financialCovenantsIsLoading,
            error: financialCovenantsError,
            isError: financialCovenantsIsError,
            isSuccess: financialCovenantsIsSuccess,
        },
    ] = useLazyGetFinancialCovenantsQuery();

    const [
        updateFinancialCovenants,
        {
            data: updateFinancialCovenantsData,
            isLoading: updateFinancialCovenantsIsLoading,
            error: updateFinancialCovenantsError,
            isError: updateFinancialCovenantsIsError,
            isSuccess: updateFinancialCovenantsIsSuccess,
        },
    ] = useUpdateFinancialCovenantsMutation();

    const handleUpdateClick = (section, initialValue) => {
        setEditingSection(section);
        setEditorContent(initialValue);
    };

    const handleCancel = () => {
        setEditingSection(null);
        setEditorContent('');
    };

    const handleSave = (newContent) => {
        setEditingSection(null);
        setEditorContent('');

        const formData = new FormData();
        formData.append(editingSection, newContent);

        updateFinancialCovenants({
            id: reportId,
            body: formData
        });
    };

    useEffect(() => {
        setTextareaData(data);
    }, [data]);

    useEffect(() => {
        if (financialCovenantsIsSuccess) {
            setTextareaData(newFinancialCovenantsData?.data);
        }
    }, [financialCovenantsIsSuccess, newFinancialCovenantsData]);

    useEffect(() => {
        if (updateFinancialCovenantsIsSuccess) {
            getFinancialCovenants(reportId)
            toast.success(updateFinancialCovenantsData?.message);
        }
    }, [updateFinancialCovenantsIsSuccess, updateFinancialCovenantsData]);


    useEffect(() => {
        if (financialCovenantsIsError) {
            toast.error(financialCovenantsError?.data.message);
            errorHandle(financialCovenantsError);
        }
        if (updateFinancialCovenantsIsError) {
            toast.error(updateFinancialCovenantsError?.data.message);
            errorHandle(updateFinancialCovenantsError);
        }
    }, [
        updateFinancialCovenantsError, updateFinancialCovenantsIsError,
        financialCovenantsError, financialCovenantsIsError
    ]);

    useEffect(() => {
        financialCovenantsIsLoading ||
        updateFinancialCovenantsIsLoading
            ? dispatch(setLoader(true))
            : dispatch(setLoader(false));
    }, [financialCovenantsIsLoading]);

    return (
        <div className="financialCovenants" id={sectionId}>
            {sections?.hasOwnProperty(sectionId) &&  <>
                <div className="editReport__sectionHeader">
                    <h3>{translations?.['covenants.financial_covenants.headers.financial_covenants']}</h3>
                </div>
                <div className="table-container">
                    <h4>{translations?.['covenants.financial_covenants.headers.submission_of_audited_project_accounts']}</h4>
                    {data?.submission_of_audited_project_accounts &&
                        <Table
                            tableData={data?.submission_of_audited_project_accounts}
                            reportId={reportId}
                            translations={translations}
                            url={'quarterly-report/covenants/submissions/audited'}
                            isReportSubmitted={isReportSubmitted}
                        />
                    }
                </div>
                <hr />
                <div className="table-container">
                    <h4>{translations?.['covenants.financial_covenants.headers.submission_of_agency_financial_statements']}</h4>
                    {data?.submission_of_agency_financial_statements &&
                        <Table
                            tableData={data?.submission_of_agency_financial_statements}
                            reportId={reportId}
                            translations={translations}
                            url={'quarterly-report/covenants/submissions/agency'}
                            isReportSubmitted={isReportSubmitted}
                        />
                    }
                </div>
                <div className="summary-section">
                    <div className="summary-sub-item">
                        <strong className='summary-sub-item__fieldTitle'>{translations?.['development_objectives.development_impact.titles.current_project']}</strong>
                        {editingSection === 'overall_compliance_rating' ? (
                            <MyEditor
                                cencelTitle={translations?.['covenants.financial_covenants.actions.cancel']}
                                saveTitle={translations?.['covenants.financial_covenants.actions.save']}
                                initialValue={editorContent}
                                onSave={handleSave}
                                onCancel={handleCancel}
                            />
                        ) : (
                            <>
                                <div className={'html-text-styled'}>{parseHtml(textareaData?.overall_compliance_rating) || ''}</div>
                                {!isReportSubmitted && <div className="summary-sub-item__settingContainer">
                                    <button
                                        className="summary-sub-item__updateBtn"
                                        onClick={() => handleUpdateClick('overall_compliance_rating', textareaData?.overall_compliance_rating)}
                                    >
                                        {translations?.['covenants.financial_covenants.actions.update']}
                                    </button>
                                </div>}
                            </>
                        )}
                    </div>
                    <div className="summary-sub-item">
                        <strong className='summary-sub-item__fieldTitle'>{translations?.['covenants.financial_covenants.titles.findings_actions_taken_or_other_remarks']}</strong>
                        {editingSection === 'findings' ? (
                            <MyEditor
                                cencelTitle={translations?.['covenants.financial_covenants.actions.cancel']}
                                saveTitle={translations?.['covenants.financial_covenants.actions.save']}
                                initialValue={editorContent}
                                onSave={handleSave}
                                onCancel={handleCancel}
                            />
                        ) : (
                            <>
                                <div className={'html-text-styled'}>{parseHtml(textareaData?.findings) || ''}</div>
                                {!isReportSubmitted && <div className="summary-sub-item__settingContainer">
                                    <button
                                        className="summary-sub-item__updateBtn"
                                        onClick={() => handleUpdateClick('findings', textareaData?.findings)}
                                    >
                                        {translations?.['covenants.financial_covenants.actions.update']}
                                    </button>
                                </div>}
                            </>
                        )}
                    </div>
                </div>
                <hr />
                <FinancialRatio
                    reportId={reportId}
                    tableData={data?.financial_ratios_to_be_compiled}
                    translations={translations}
                    url={'quarterly-report/covenants/financial-ratios/update'}
                    isReportSubmitted={isReportSubmitted}
                />
            </>
            }
        </div>
    );
};

export default FinancialCovenants;
