import React, {useEffect, useState} from "react";
import { useLocation } from "react-router-dom";
import {useDispatch} from "react-redux";
import Wrapper from "../../components/Wrapper";
import CreateQReportStep1 from "./CreateQReportStep1";
import CreateQReportStep2 from "./CreateQReportStep2";
import CreateQReportStep3 from "./CreateQReportStep3";
import {errorHandle} from "../../global/errorHandle";
import {setLoader} from "../../store/slice/dashboard";
import {useGetQuarterlyReportEditPageQuery} from "../../store/service/adbService";
import "./index.scss";

const emptyDataText = "No Activities";

const CreateQuarterlyReport = () => {
    const route = useLocation().pathname;
    const dispatch = useDispatch();
    const location = useLocation();
    const [pageTranslations, setPageTranslations] = useState({})

    const { data: createQuarterlyReportPage, error, isLoading: isLoadingCreateQuarterlyReportPage } = useGetQuarterlyReportEditPageQuery();

    useEffect(()=>{
        if (createQuarterlyReportPage?.data) {
            setPageTranslations(createQuarterlyReportPage?.data?.translations)
        }
    }, [createQuarterlyReportPage]);

    useEffect(() => {
        isLoadingCreateQuarterlyReportPage ?
            dispatch(setLoader(true)) :
            dispatch(setLoader(false))
    }, [isLoadingCreateQuarterlyReportPage]);

    if (error) {
        errorHandle(error);
        return (
            <div style={{ height: '70vh', width: '100%', display: "flex", justifyContent: "center", alignItems: 'center', flexDirection: "column" }}>
                <img src="/assets/images/projects.png" alt="" />
                <span style={{ color: "#767676", fontSize: 16, fontWeight: 500 }}>{emptyDataText}</span>
            </div>
        )
    }

    return (
        <Wrapper classname="createQReportContainer">
            <div className="create__box">
                <h1 className="create__title">{location.pathname.includes("/edit-quarterly-data")  ? pageTranslations?.['index.headers.edit_quarterly_report'] : pageTranslations?.['index.headers.create_quarterly_report']}</h1>
                <div className="create__tabs" style={{background:"none"}}>
                    <div
                        className={`create__tab one ${
                            +route.match(/\d+/g)[0] >= 1 ? "active" : ""
                        }`}
                    >
                        {pageTranslations?.['report_details.headers.report_details']}
                    </div>
                    <div
                        className={`create__tab two ${
                            +route.match(/\d+/g)[0] >= 2 ? "active" : "0"
                        }`}
                    >
                        {pageTranslations?.['included_projects.headers.included_projects']}
                    </div>
                    <div
                        className={`create__tab three ${
                            +route.match(/\d+/g)[0] >= 3 ? "active" : ""
                        }`}
                    >
                        {pageTranslations?.['report_sections.headers.report_sections']}
                    </div>
                </div>
                <div className="create__content">
                    <div style={+route.match(/\d+/g)[0] === 1 ? {display: 'block'} : {display: 'none'}}><CreateQReportStep1 pageTranslations={pageTranslations} /></div>
                    <div style={+route.match(/\d+/g)[0] === 2 ? {display: 'block'} : {display: 'none'}}><CreateQReportStep2 pageTranslations={pageTranslations}/></div>
                    <div style={+route.match(/\d+/g)[0] === 3 ? {display: 'block'} : {display: 'none'}}><CreateQReportStep3 pageTranslations={pageTranslations}/></div>
                </div>
            </div>
        </Wrapper>
    );
};
export default CreateQuarterlyReport;