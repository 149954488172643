import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import {
    useCreateProjectDisputeMutation,
    useDeleteProjectDisputeMutation,
    useUpdateDisputesOverviewMutation,
    useUpdateProjectDisputeMutation,
} from "../../../store/service/adbService";
import { DeleteModal } from "../DeleteModal";
import { useErrorHandler } from "../../../global/ErrorsHandler";
import { setLoader } from "../../../store/slice/dashboard";
import { useDispatch, useSelector } from "react-redux";
import MoreInfoModal from "../../MoreInfoModal";

const customStyles = {
    menu: (provided, state = false) => ({
        ...provided,
        width: "150%",
        color: state.selectProps.menuColor,
        cursor: "pointer",
        height: 150,
        overflow: "hidden",
    }),

    control: (_, { selectProps: { width } }) => ({
        width: "100%",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        display: "flex",
        background: "#FFFFFF",
        borderRadius: 8,
    }),
};

export const Disputes = ({ data, glossaryData, refDisputes, translations }) => {
    const dispatch = useDispatch();
    const [disputesData, setDisputesData] = useState(
        data?.disputes?.map((el) => ({ ...el, edit: false }))
    );
    const [text, setText] = useState(
        data?.disputes_overview ?? translations?.["executive_summary.executive_summary.placeholder.please_enter"]
    );
    const [edit, setEdit] = useState(false);
    const [prevText, setPrevText] = useState(data?.disputes_overview ?? "");
    const [tableHeaders, setTableHeaders] = useState([]);
    const [infoModal, setInfoModal] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [newItem, setNewItem] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);
    const [show, setShow] = useState(true);
    const user = useSelector((state) => state?.adbReducer?.user);
    const [editable, setEditable] = useState(false);
    const [moreInfo, setMoreInfo] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);
    const scrollRef = useRef(null);
    const descrRef = useRef(null);
    const [decrExpanded, setDecrExpanded] = useState(false);
    const expandedRef = useRef(null);
    const [expanded, setExpanded] = useState(false);

    const [
        updateDisputesOverview,
        {
            data: updateDisputesOverviewData,
            isError: updateDisputesOverviewIsError,
            isLoading: updateDisputesOverviewIsLoading,
            isSuccess: updateDisputesOverviewIsSuccess,
            error: updateDisputesOverviewError,
        },
    ] = useUpdateDisputesOverviewMutation();

    const [
        createProjectDispute,
        {
            data: createProjectDisputeData,
            isError: createProjectDisputeIsError,
            isLoading: createProjectDisputeIsLoading,
            isSuccess: createProjectDisputeIsSuccess,
            error: createProjectDisputesError,
        },
    ] = useCreateProjectDisputeMutation();

    const [
        updateProjectDispute,
        {
            data: updateProjectDisputeData,
            isError: updateProjectDisputeIsError,
            isLoading: updateProjectDisputeIsLoading,
            isSuccess: updateProjectDisputeIsSuccess,
            error: updateProjectDisputeError,
        },
    ] = useUpdateProjectDisputeMutation();

    const [
        deleteProjectDispute,
        {
            data: deleteProjectDisputeData,
            isError: deleteProjectDisputeIsError,
            isLoading: deleteProjectDisputeIsLoading,
            isSuccess: deleteProjectDisputeIsSuccess,
            error: deleteProjectDisputeError,
        },
    ] = useDeleteProjectDisputeMutation();

    useEffect(() => {
        if(translations){
            const headers = [
                {
                    tooltip_name: translations?.['disputes.disputes.table_headers.dispute_description'],
                    tooltip_text: translations?.['disputes.disputes.tooltips.dispute_description']
                },
                {
                    tooltip_name: translations?.['disputes.disputes.table_headers.current_status'],
                    tooltip_text: translations?.['disputes.disputes.tooltips.current_status']
                },
            ]
            setTableHeaders(
                headers.map((item) => ({
                    ...item,
                    showTooltip: false,
                }))
            );
        }
    }, [translations])

    useEffect(() => {
        if (glossaryData?.data?.length) {
            setMoreInfo(
                glossaryData?.data?.filter((el) => el?.section_name === "Disputes")[0]
            );
        }
        setEdit(false);
    }, [updateDisputesOverviewIsSuccess, glossaryData]);

    useEffect(() => {
        if (data) {
            setDisputesData(data?.disputes?.map((el) => ({ ...el, edit: false })));
            setText(data?.disputes_overview ?? "");
            setPrevText(data?.disputes_overview ?? "");
        }
    }, [data]);

    useEffect(() => {
        const d = () => {
            setDisputesData((old) => {
                let data = old?.map((item) => ({ ...item, edit: false }));
                return data;
            });
            dispatch(setLoader(false));
        };

        createProjectDisputeIsLoading ||
        deleteProjectDisputeIsLoading ||
        updateDisputesOverviewIsLoading ||
        updateProjectDisputeIsLoading
            ? dispatch(setLoader(true))
            : d();
    }, [
        createProjectDisputeIsLoading,
        deleteProjectDisputeIsLoading,
        updateDisputesOverviewIsLoading,
        updateProjectDisputeIsLoading,
    ]);

    const [errorHandler, setErrorHandler] = useErrorHandler([
        updateDisputesOverviewError,
    ]);
    const submitHandler = (e) => {
        e.preventDefault();
        if (text.length > 0) {
            updateDisputesOverview({
                id: data?.id,
                body: { disputes_overview: text },
            });
        } else {
            toast.error("Please enter summary", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }
    };

    const setterData = (input) => {
        const body = {
            project_id: data?.id,
            description: input?.description,
        };

        let s = [];

        for (let key in body) {
            if (key !== "comment") {
                if (!body[key] || body[key] === "Invalid date" || body[key] === "") {
                    toast.error("Please enter " + key?.split("_")?.join(" "), {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    });
                    s.push("false");
                }
            }
        }

        if (s.length === 0) {
            return body;
        } else {
            return null;
        }
    };
    const submitDispute = (e, item) => {
        e.preventDefault();
        if (newItem) {
            const dataCreated = setterData(currentItem);
            if (dataCreated) {
                createProjectDispute({...dataCreated, status: currentItem?.status});
                setCurrentItem(null);
                setNewItem(false);
                setCurrentRow(null);
            }
        } else {
            const dataUpdated = setterData(currentItem);
            if (dataUpdated) {
                updateProjectDispute({
                    id: item?.id,
                    body: {...dataUpdated, status: currentItem?.status},
                });
                setCurrentItem(null);
                setNewItem(false);
                setCurrentRow(null);
            }
        }
    };

    return (
        <div className="project__white-box" ref={refDisputes}>
            <div className="project__disputes">
                {infoModal && (
                    <MoreInfoModal
                        title={translations?.['disputes.disputes.headers.disputes']}
                        moreInfo={translations?.['disputes.disputes.more_info.disputes']}
                        close={()=> setInfoModal(false)}
                    />
                )}

                {deleteModal?.show && (
                    <DeleteModal
                        id={deleteModal?.id}
                        action={deleteProjectDispute}
                        setDeleteModal={setDeleteModal}
                        cencelText={translations?.['main.main.actions.cancel']}
                        deleteText={translations?.['main.main.actions.delete']}
                        modalTitle={translations?.['main.main.headers.confirm_your_action']}
                        clarifyingText={translations?.['main.main.titles.you_are_going_to_delete_this_row']}
                    />
                )}
                <div
                    id={refDisputes}
                    className={`project__block ${show ? "" : "close"}`}
                >
                    <div className="d-f">
                        <div
                            className="project__block-title"
                            onClick={() => setShow(!show)}
                        >
                            <span>{translations?.['disputes.disputes.headers.disputes']}</span>
                        </div>
                        <span
                            className="project__block-more"
                            onClick={() => setInfoModal(!infoModal)}
                        >
              <span className="img">
                <img src="/assets/icons/info.svg" alt="info" />
              </span>
              <span className="text">{translations?.['disputes.disputes.titles.more_info']}</span>
            </span>
                    </div>
                    <div
                        className={`project__block-content executive-summary ${
                            edit ? "edit" : ""
                        }`}
                    >
                        <div className="gray-box">
                            <form onSubmit={(e) => submitHandler(e)} className="content">
                                <div className="text">
                                    {edit ? (
                                        <textarea
                                            className={`${
                                                expanded ? "project__block-expandedTextArea" : ""

                                            }`}
                                            ref={expandedRef}
                                            onFocus={() => setExpanded(true)}
                                            rows={20}
                                            maxLength={2500}
                                            defaultValue={
                                                text === "Please enter overview" ? "" : text
                                            }
                                            placeholder={translations?.["executive_summary.executive_summary.placeholder.please_enter"]}
                                            onChange={(e) => {
                                                setText(e.target.value);
                                            }}
                                        />
                                    ) : (
                                        <p>{text}</p>
                                    )}
                                </div>
                                {user?.permissions?.edit_project && (
                                    <div className="project__block-btns">
                                        {edit && (
                                            <div
                                                className="project__block-cancel"
                                                onClick={() => {
                                                    setText(prevText);
                                                    setEdit(false);
                                                }}
                                            >
                                                <img src="/assets/icons/close-mark.svg" alt="close" />
                                                {translations?.['disputes.disputes.actions.cancel']}
                                            </div>
                                        )}
                                        {edit ? (
                                            <button
                                                onClick={() => setExpanded(false)}
                                                type="submit"
                                                className="project__block-save"
                                            >
                                                <img src="/assets/icons/save-arrow.svg" alt="arrow" />
                                                {translations?.['disputes.disputes.actions.save']}
                                            </button>
                                        ) : (
                                            <div
                                                onClick={() => {
                                                    setEdit(true);
                                                }}
                                                className="project__block-update"
                                            >
                                                {translations?.['disputes.disputes.actions.update']}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </form>
                        </div>
                    </div>
                    <div className="project__block-subtitles">
                        <div></div>
                        {user?.permissions?.edit_project && (
                            <div
                                onClick={() => {
                                    let randomId = Math.floor(Math.random() * 1000);
                                    setCurrentRow(randomId);
                                    scrollRef !== null && scrollRef.current.scrollIntoView();
                                    if (!newItem) {
                                        setNewItem(true);
                                        setEditable(true);
                                        setDisputesData((old) => [
                                            ...old,
                                            {
                                                id: randomId,
                                                description: "",
                                                status: "",
                                                edit: true,
                                            },
                                        ]);
                                        setCurrentItem({
                                            id: randomId,
                                            description: "",
                                            status: "",
                                            edit: true,
                                        });
                                    }
                                }}
                                className="project__block-add-row"
                            >
                <span>
                  <img src="/assets/icons/blue-plus.svg" alt="plus" />
                </span>
                                <span>{translations?.['disputes.disputes.actions.add_row']}</span>
                            </div>
                        )}
                    </div>
                    <div
                        className={`project__block-content permits
                            ${editable ? "editable" : ""}
                        `}
                    >
                        <div
                            className={`project__block-table project-table ${
                                currentItem ? "n" : ""
                            }`}
                        >
                            <div className={`project-table__header`} ref={scrollRef}>
                                {tableHeaders?.map((el, idx) => {
                                    return (
                                        <div
                                            key={idx}
                                            onMouseEnter={(event) => {
                                                setTableHeaders(
                                                    tableHeaders.map((item) => ({
                                                        ...item,
                                                        showTooltip: el.tooltip_name === item.tooltip_name
                                                    }))
                                                );
                                            }}
                                            onMouseLeave={() => {
                                                setTableHeaders(
                                                    tableHeaders.map((item) => ({
                                                        ...item,
                                                        showTooltip: false,
                                                    }))
                                                );
                                            }}
                                            className="project-table__th header_description"
                                        >
                                            {el?.showTooltip ? (
                                                <span
                                                    className={
                                                        el?.tooltip_text?.length > 20
                                                            ? "lengthy"
                                                            : "not-lengthy"
                                                    }
                                                >
                          {el?.tooltip_text}
                        </span>
                                            ) : (
                                                ""
                                            )}
                                            {el?.tooltip_name}
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="project-table__body">
                                {disputesData?.map((item, index) => (
                                    <div key={index}>
                                        {index !== 0 && currentRow === item?.id && (
                                            <div className={`project-table__header`} key={index}>
                                                {tableHeaders?.map((el, idx) => {
                                                    return (
                                                        <div key={idx} className="project-table__th">
                                                            {el?.showTooltip ? (
                                                                <span
                                                                    className={
                                                                        el?.tooltip_text?.length > 20
                                                                            ? "lengthy"
                                                                            : "not-lengthy"
                                                                    }
                                                                >
                                  {el?.tooltip_text}
                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                            {el?.tooltip_name}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                        <form
                                            className={`project-table__row ${
                                                item?.edit ? "edit" : currentItem ? "n" : ""
                                            } ${
                                                Boolean(item.submitted) ||
                                                !user?.permissions?.edit_project
                                                    ? "submitted"
                                                    : ""
                                            }`}
                                            id={item?.id}
                                            key={item?.id}
                                            onSubmit={(e) => submitDispute(e, item)}
                                        >
                                            <div className="project-table__td payment_description" style={{width: '50%'}}>
                                                <span>{item?.description}</span>
                                                <div className="project__block-input project-table__field">
                          <textarea
                              className={`${
                                  decrExpanded ? "project__block-expandedInput" : ""
                              }`}
                              ref={descrRef}
                              style={{maxWidth: decrExpanded ? '80%' : ''}}
                              onFocus={() => setDecrExpanded(true)}
                              onBlur={() => setDecrExpanded(false)}
                              maxLength={190}
                              type="text"
                              placeholder={translations?.['executive_summary.executive_summary.placeholder.please_enter']}
                              defaultValue={item?.description}
                              onChange={(e) => {
                                  setCurrentItem((old) => ({
                                      ...old,
                                      description: e.target.value,
                                  }));
                              }}
                          />
                                                </div>
                                            </div>
                                            <div className="project-table__td">
                                                <span>{item?.status}</span>
                                                <div className="project__block-input project-table__field">
                                                    <input
                                                        maxLength={190}
                                                        type="text"
                                                        defaultValue={item?.status}
                                                        onChange={(e) => {
                                                            setCurrentItem((old) => ({
                                                                ...old,
                                                                status: e.target.value,
                                                            }));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="project__block-btns">
                                                {currentItem ? (
                                                    <div
                                                        className="project__block-cancel"
                                                        onClick={() => {
                                                            setCurrentRow(null);
                                                            let array = disputesData?.map((item) => {
                                                                item.edit = false;
                                                                return item;
                                                            });
                                                            setCurrentItem(null);
                                                            setDisputesData(array);
                                                            if (newItem) {
                                                                setNewItem(false);
                                                                let s = disputesData?.filter(
                                                                    (el, i) => i !== disputesData.length - 1
                                                                );
                                                                setDisputesData(s);
                                                            }
                                                        }}
                                                    >
                                                        <img
                                                            src="/assets/icons/close-mark.svg"
                                                            alt="close"
                                                        />
                                                        {translations?.['disputes.disputes.actions.cancel']}
                                                    </div>
                                                ) : (
                                                    <div
                                                        className="project__block-delete"
                                                        onClick={() => {
                                                            setDeleteModal({ id: item?.id, show: true });
                                                        }}
                                                    >
                                                        <img
                                                            src="/assets/icons/trash-icon.svg"
                                                            alt="arrow"
                                                        />
                                                        {translations?.['disputes.disputes.actions.delete']}
                                                    </div>
                                                )}
                                                {currentItem ? (
                                                    <button className="project__block-save">
                                                        <img
                                                            src="/assets/icons/save-arrow.svg"
                                                            alt="arrow"
                                                        />
                                                        {translations?.['disputes.disputes.actions.save']}
                                                    </button>
                                                ) : (
                                                    <div
                                                        onClick={() => {
                                                            setCurrentRow(item?.id);
                                                            setCurrentItem(item);
                                                            setEditable(true);
                                                            setDisputesData(() => {
                                                                let array = disputesData?.map((elem) => {
                                                                    if (elem?.id === item?.id) {
                                                                        elem.edit = true;
                                                                    }
                                                                    return elem;
                                                                });
                                                                return array;
                                                            });
                                                        }}
                                                        className="project__block-update"
                                                    >
                                                        {translations?.['disputes.disputes.actions.update']}
                                                    </div>
                                                )}
                                            </div>
                                        </form>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
