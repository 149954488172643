import {useEffect} from "react";
import {useLocation} from "react-router-dom";

const RouteWatcher = () => {
    const location = useLocation();

    useEffect(() => {
        const isEditProjecttPath = location.pathname.startsWith('/edit-project');
        const isCreateProjectPath = location.pathname.startsWith('/create-project');
        const isCreateReportPath = location.pathname.startsWith('/create-quarterly-report');
        const isEditReportPath = location.pathname.startsWith('/edit-quarterly-data');


        if ( !isCreateProjectPath){
            localStorage.removeItem('create-data');
        }
        if (!isEditProjecttPath ){
            localStorage.removeItem('edit-data');
        }

        if (!isCreateReportPath && !isEditReportPath) {
            localStorage.removeItem('create-report-data');
        }
        if (!isEditReportPath) {
            localStorage.removeItem('edit-report-data');
        }
    }, [location]);

    return null;
};


export default RouteWatcher;