import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DownloadCSV from "../../components/DownloadCSV";

export default function ColorInfoComponent({id, report_id, colorTitles, actionTitles}) {
    const [color, setColor] = useState([])
    const navigate = useNavigate();
    const downloadCSVUrl=`/larp/section/export/csv/${report_id}`;

    useEffect(() => {
        if(colorTitles){
            setColor(colorTitles)
        }
    }, [colorTitles])

    return (
        <div className="larp-db__df larp-db__aj">
            <div className="larp-db__colors">
                <div className="larp-db__color paid">
                    <div></div>
                    <p>{color[0]}</p>
                </div>
                <div className="larp-db__color escrowed">
                    <div></div>
                    <p>{color[1]}</p>
                </div>
                <div className="larp-db__color unpaid">
                    <div></div>
                    <p>{color[2]}</p>
                </div>
            </div>
            <div className="larp-db__buttondContainer">
                <DownloadCSV
                    btnTitle={actionTitles[0]}
                    url={downloadCSVUrl}
                    fileName="affected_persons"
                    tooltipTitle="Download LARP Dashboard Data"
                />
                <button onClick={() => {
                    navigate(`/online-view/${report_id}`, {state: {prevLocation: `/larp-dashboard/${id}`, larp: true}})
                }} to={`/online-view/${report_id}`} className="larp-db__link_blue">
                    <span>{actionTitles[1]}</span>
                    <span><img src="/assets/icons/arrow-blue-right.png" alt="arrow" /></span>
                </button>
            </div>

        </div>
    )
}
