import React from 'react';
import {useSelector} from "react-redux";
import ContentLoader from "react-content-loader";


function QuarterlyTitle(props) {
	const data = useSelector(state => state?.quarterlyReducer?.online_view);
	
	if (!data) {
	
	}
	
	return (
			<div className={"flex flex-col items-center"}>
				{data ? (
						<React.Fragment>
							<h1 className="online__content-title" style={{marginBottom: 10}}> {data?.report_name} </h1>
							<h2 className={"text-2xl"}>{data?.implementing_agency}</h2>
							<h2 className={"text-2xl"}>Loan / Grant No. {data?.loan_grant_number}</h2>
							<h2 className={"text-2xl"}>{data?.country}</h2>
							<img src={data?.cover} alt="" style={{width: 400}} loading={"lazy"}/>
							<h2 style={{marginTop: 20}} className={"text-2xl"}>{data?.report_number}</h2>
							<h2 className={"text-2xl"}>{data?.report_date}</h2>
						</React.Fragment>
				) : (
						<div style={{marginTop: 50}}>
							<ContentLoader
									speed={2}
									width={500}
									height={400}
									viewBox="0 0 400 400"
									backgroundColor="#f3f3f3"
									foregroundColor="#ecebeb"
									{...props}
							>
								<rect x="20" y="10" rx="0" ry="0" width="500" height="37.5"/>
								<rect x="20" y="57.5" rx="0" ry="0" width="500" height="37.5"/>
								<rect x="20" y="107.5" rx="0" ry="0" width="500" height="37.5"/>
								<rect x="20" y="157.5" rx="0" ry="0" width="500" height="266"/>
							</ContentLoader>
						</div>
				)}
			
			</div>
	);
}

export default QuarterlyTitle;
