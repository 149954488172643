import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {errorHandle} from "../../global/errorHandle";
import {setLoader} from "../../store/slice/dashboard";
import { useLazyGetGlossaryQuery } from "../../store/service/adbService";
import AxelInput from "../../UI/AxelInput";
import Wrapper from "../../components/Wrapper";
import "./index.scss";

export default function ManageGlossary() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([])
  const [searchText, setSearchText] = useState("");
  const user = useSelector((state) => state?.adbReducer?.user);

  const filteredPages = data?.filter(project =>
      project?.name.toLowerCase().includes(searchText.toLowerCase())
  );

  const [getAllGlossaryData, {
    data: glossaryData,
    error: glossaryError,
    isLoading: glossaryIsLoading,
    isSuccess: glossaryIsSuccess,
    isError: glossaryIsError,
  }] = useLazyGetGlossaryQuery();


  useEffect(() => {
    if (glossaryIsSuccess){
      setData(glossaryData?.data);
      localStorage.setItem("glossary", JSON.stringify(glossaryData?.data));
    }
  }, [glossaryIsSuccess]);

  useEffect(() => {
    glossaryIsLoading ?
        dispatch(setLoader(true)) :
        dispatch(setLoader(false))
  }, [glossaryIsLoading]);

  useEffect(() => {
    if (glossaryIsError){
      errorHandle(glossaryError)
    }
  }, [glossaryIsError, glossaryError]);

  useEffect(() => {
    if(user){
      if (!user?.permissions?.glossary) {
        navigate("/project-list");
      }
      else {
        getAllGlossaryData();
      }
    }

  }, [user]);

  useEffect(() => {
    if (!localStorage.getItem("user_token")) {
      navigate("/login");
    }
  }, []);

  const renderList = () => {
    return filteredPages?.map(item => (
        <Link to={`/manage-glossary/${item?.name}`} state={item} className="glossary__item" key={item?.name}>
          <p className="glossary__item-text">{item?.name}</p>
          <div className="glossary__item-right">
            <div className="glossary__item-arrow">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M18.5303 12.5303C18.8232 12.2374 18.8232 11.7626 18.5303 11.4697L14.5303 7.46967C14.2374 7.17678 13.7626 7.17678 13.4697 7.46967C13.1768 7.76256 13.1768 8.23744 13.4697 8.53033L16.1893 11.25L6 11.25C5.58579 11.25 5.25 11.5858 5.25 12C5.25 12.4142 5.58579 12.75 6 12.75L16.1893 12.75L13.4697 15.4697C13.1768 15.7626 13.1768 16.2374 13.4697 16.5303C13.7626 16.8232 14.2374 16.8232 14.5303 16.5303L18.5303 12.5303Z" fill="#2A3650"/>
                <path d="M18.5303 11.4697C18.8232 11.7626 18.8232 12.2374 18.5303 12.5303L14.5303 16.5303C14.2374 16.8232 13.7626 16.8232 13.4697 16.5303C13.1768 16.2374 13.1768 15.7626 13.4697 15.4697L16.9708 12.0025L13.4697 8.53033C13.1768 8.23744 13.1768 7.76256 13.4697 7.46967C13.7626 7.17678 14.2374 7.17678 14.5303 7.46967L18.5303 11.4697Z" fill="#2A3650"/>
              </svg>
            </div>
          </div>
        </Link>
    ))
  }

  return (
      <Wrapper classname="glossary">
        <div className="glossary__header">
          <h1 className="text-dark text-[36px] dashboard__title">Pages</h1>
          <AxelInput
              className="glossary__header__search"
              src={'/assets/icons/search.svg'}
              placeholder='Search'
              value={searchText}
              handler={(e) => setSearchText(e.target.value)}
          />
        </div>
        <div className="glossary__list">
          {renderList()}
        </div>
      </Wrapper>
  );
}
