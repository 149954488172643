import moment from "moment";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import { useErrorHandler } from "../../../../global/ErrorsHandler";
import {
    useCreateComplaintsMutation,
    useDeleteComplaintsMutation,
    useLazyGetAllCompaniesQuery,
    useUpdateComplaintsMutation,
} from "../../../../store/service/adbService";
import { setLoader } from "../../../../store/slice/dashboard";
import { Switch } from "../../../../UI/Switch";
import { DeleteModal } from "../../DeleteModal";
import MoreInfoModal from "../../../MoreInfoModal";
import CustomDatePicker from "../../../CustomDatePicker";

const customStyles = {
    menu: (provided, state = false) => ({
        ...provided,
        width: "150%",
        color: state.selectProps.menuColor,
        cursor: "pointer",
        overflow: "hidden",
    }),

    control: (_, { selectProps: { width } }) => ({
        width: "100%",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        display: "flex",
        background: "#FFFFFF",
        borderRadius: 8,
    }),
};

export const Complaints = ({ id, complaintsData, glossaryData, mainData, translations }) => {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [show, setShow] = useState(true);
    const [currentItem, setCurrentItem] = useState(null);
    const [newItem, setNewItem] = useState(false);
    const [options, setOptions] = useState([]);
    const [decrExpanded, setDecrExpanded] = useState(false);
    const [editable, setEditable] = useState(false);
    const [deleteModal, setDeleteModal] = useState(null);
    const [moreInfo, setMoreInfo] = useState(null);
    const [tableHeaders, setTableHeaders] = useState([]);
    const [infoModal, setInfoModal] = useState(false);
    const [currentRow, setCurrentRow] = useState(null);
    const scrollRef = useRef(null);
    const user = useSelector((state) => state?.adbReducer?.user);
    const expandedRef = useRef(null);
    const [expanded, setExpanded] = useState(false);
    const localeLan = localStorage.getItem("local_lan" );
    moment.locale(localeLan);

    const [
        createComplaints,
        {
            data: createComplaintsData,
            isError: createComplaintsIsError,
            isLoading: createComplaintsIsLoading,
            isSuccess: createComplaintsIsSuccess,
            error: createComplaintsError,
        },
    ] = useCreateComplaintsMutation();

    const [
        updateComplaints,
        {
            data: updateComplaintsData,
            isError: updateComplaintsIsError,
            isLoading: updateComplaintsIsLoading,
            isSuccess: updateComplaintsIsSuccess,
            error: updateComplaintsError,
        },
    ] = useUpdateComplaintsMutation();

    const [
        deleteComplaints,
        {
            data: deleteComplaintsData,
            isError: deleteComplaintsIsError,
            isLoading: deleteComplaintsIsLoading,
            isSuccess: deleteComplaintsIsSuccess,
            error: deleteComplaintsError,
        },
    ] = useDeleteComplaintsMutation();

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                let array = data?.map((item) => {
                    item.edit = false;
                    return item;
                });
                setEditable(false);
                setCurrentItem(null);
                setData(array);
                if (newItem) {
                    setNewItem(false);
                    let s = data?.filter((el, i) => i !== data.length - 1);
                    setData(s);
                }
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, [data]);

    useEffect(() => {
        if (complaintsData?.complaints) {
            let data = complaintsData?.complaints?.map((item) => ({
                id: item?.id,
                date: item?.date,
                affected_person: item?.affected_person,
                name: item?.name,
                description: item?.description,
                comment: item?.comment,
                responsible_party: {
                    value: item?.responsible_party,
                    label: item?.responsible_party,
                },
                closed: item?.closed,
                project_id: id,
                edit: false,
                submitted: item?.submitted,
                old_report: item?.old_report,
                submitted_date: item?.submitted_date,
            }));
            setEditable(false);
            setData(data);
        }
    }, [complaintsData?.complaints]);

    const [
        getCompanies,
        {
            data: companies,
            error: companiesError,
            isLoading: companiesIsLoading,
            isError: companiesIsError,
            isSuccess: companiesIsSuccess,
        },
    ] = useLazyGetAllCompaniesQuery();

    useEffect(() => {
        getCompanies({
            per_page: 1000,
        });
    }, []);

    useEffect(() => {
        if (mainData?.responsible_parties) {
            let data = mainData?.responsible_parties?.map((item) => {
                return {
                    value: item,
                    label: item,
                };
            });
            setOptions(data);
        }
    }, [mainData]);

    const [errorHandler, setErrorHandler] = useErrorHandler([
        createComplaintsError,
        updateComplaintsError,
        deleteComplaintsError,
    ]);

    useEffect(() => {
        if(translations){
            const headers = [
                {
                    tooltip_name: translations?.['environment_social_health_safety_and_security_obligations.complaints_grievances.table_headers.name_of_complainant'],
                    tooltip_text: translations?.['environment_social_health_safety_and_security_obligations.complaints_grievances.tooltips.name_of_complainant']
                },
                {
                    tooltip_name: translations?.['environment_social_health_safety_and_security_obligations.complaints_grievances.table_headers.description'],
                    tooltip_text: translations?.['environment_social_health_safety_and_security_obligations.complaints_grievances.tooltips.description']
                },
                {
                    tooltip_name: translations?.['environment_social_health_safety_and_security_obligations.complaints_grievances.table_headers.date_filed'],
                    tooltip_text: translations?.['environment_social_health_safety_and_security_obligations.complaints_grievances.tooltips.date_filed']
                },
                {
                    tooltip_name: translations?.['environment_social_health_safety_and_security_obligations.complaints_grievances.table_headers.affected_persons'],
                    tooltip_text: translations?.['environment_social_health_safety_and_security_obligations.complaints_grievances.tooltips.affected_persons']
                },
                {
                    tooltip_name: translations?.['environment_social_health_safety_and_security_obligations.complaints_grievances.table_headers.responsible_party'],
                    tooltip_text: translations?.['environment_social_health_safety_and_security_obligations.complaints_grievances.tooltips.responsible_party']
                },
                {
                    tooltip_name: translations?.['environment_social_health_safety_and_security_obligations.complaints_grievances.table_headers.current_status_or_comments'],
                    tooltip_text: translations?.['environment_social_health_safety_and_security_obligations.complaints_grievances.tooltips.current_status_or_comments']
                },
                {
                    tooltip_name: translations?.['environment_social_health_safety_and_security_obligations.complaints_grievances.table_headers.closed'],
                    tooltip_text: translations?.['environment_social_health_safety_and_security_obligations.complaints_grievances.tooltips.closed']
                },
            ]

            setTableHeaders(
                headers.map((item) => ({
                    ...item,
                    showTooltip: false,
                }))
            );
        }
    }, [translations])

    useEffect(() => {
        if (glossaryData?.data?.length) {
            setMoreInfo(
                glossaryData?.data?.filter(
                    (el) => el?.section_name === "Complaints (Grievances)"
                )[0]
            );
        }
        setData((old) => {
            let data = old.map((item) => ({ ...item, edit: false }));
            return data;
        });
        setEditable(false);
    }, [
        createComplaintsIsSuccess,
        updateComplaintsIsSuccess,
        deleteComplaintsIsSuccess,
        glossaryData,
    ]);

    useEffect(() => {
        const d = () => {
            dispatch(setLoader(false));
        };

        createComplaintsIsLoading ||
        updateComplaintsIsLoading ||
        deleteComplaintsIsLoading
            ? dispatch(setLoader(true))
            : d();
    }, [
        createComplaintsIsLoading,
        updateComplaintsIsLoading,
        deleteComplaintsIsLoading,
    ]);

    const setterData = (input) => {
        const body = {
            affected_person: input?.affected_person,
            name: input?.name,
            description: input?.description,
            date: moment(input?.date).format("YYYY-MM-DD"),
            project_id: id,
            responsible_party: input?.responsible_party?.value,
        };

        if (input?.responsible_party?.value) {
            body.responsible_party = input?.responsible_party?.value;
        }

        let s = [];

        for (let key in body) {
            if (!body[key] || body[key] === "Invalid date" || body[key] === "") {
                toast.error("Please enter " + key?.split("_")?.join(" "), {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
                s.push("false");
            }
        }

        if (s.length === 0) {
            return body;
        } else {
            return null;
        }
    };

    const submitHandler = (e, item) => {
        e.preventDefault();
        if (newItem) {
            const dataCreated = setterData(currentItem);
            if (dataCreated) {
                createComplaints({
                    ...dataCreated,
                    closed: currentItem?.closed,
                    comment: currentItem?.comment,
                });
                setCurrentItem(null);
                setNewItem(false);
                setCurrentRow(null);
            }
        } else {
            const dataUpdated = setterData(currentItem);
            if (dataUpdated) {
                updateComplaints({
                    id: item?.id,
                    body: {
                        ...dataUpdated,
                        closed: currentItem?.closed,
                        comment: currentItem?.comment,
                    },
                });
                setCurrentItem(null);
                setNewItem(false);
                setCurrentRow(null);
            }
        }
    };

    return (
        <div className="project__ssemp">
            {infoModal && (
                <MoreInfoModal
                    title={translations?.['environment_social_health_safety_and_security_obligations.complaints_grievances.headers.complaints_grievances']}
                    moreInfo={translations?.['']}
                    close={()=> setInfoModal(false)}
                />
            )}

            {deleteModal?.show && (
                <DeleteModal
                    id={deleteModal?.id}
                    action={deleteComplaints}
                    setDeleteModal={setDeleteModal}
                    cencelText={translations?.['main.main.actions.cancel']}
                    deleteText={translations?.['main.main.actions.delete']}
                    modalTitle={translations?.['main.main.headers.confirm_your_action']}
                    clarifyingText={translations?.['main.main.titles.you_are_going_to_delete_this_row']}
                />
            )}
            <div className="project__block-subtitles">
                <div className="project__block-subtitle">{translations?.['environment_social_health_safety_and_security_obligations.complaints_grievances.headers.complaints_grievances']}</div>
                {user?.permissions?.edit_project && (
                    <div
                        onClick={() => {
                            let randomId = Math.floor(Math.random() * 1000);
                            setCurrentRow(randomId);
                            scrollRef !== null && scrollRef.current.scrollIntoView();
                            if (!newItem) {
                                setNewItem(true);
                                setEditable(true);
                                setData((old) => [
                                    ...old,
                                    {
                                        id: randomId,
                                        affected_person: "",
                                        name: "",
                                        description: "",
                                        responsible_party: null,
                                        closed: false,
                                        date: null,
                                        comment: "",
                                        edit: true,
                                    },
                                ]);
                                setCurrentItem({
                                    id: randomId,
                                    affected_person: "",
                                    name: "",
                                    description: "",
                                    comment: "",
                                    responsible_party: null,
                                    closed: false,
                                    date: null,
                                    edit: true,
                                });
                            }
                        }}
                        className="project__block-add-row"
                    >
            <span>
              <img src="/assets/icons/blue-plus.svg" alt="plus" />
            </span>
                        <span>{translations?.['environment_social_health_safety_and_security_obligations.complaints_grievances.actions.add_row']}</span>
                    </div>
                )}
            </div>
            <div
                className={`project__block-content permits ${
                    editable ? "editable" : ""
                }`}
            >
                <div
                    className={`project__block-table project-table ${
                        currentItem ? "n" : ""
                    }`}
                >
                    <div className={`table__header`} ref={scrollRef} style={{gridTemplateColumns: '16% 16% 14% 14% 12% 16% 10%'}}>
                        {tableHeaders?.map((el, idx) => {
                            return (
                                <div
                                    key={idx}
                                    onMouseEnter={(event) => {
                                        setTableHeaders(
                                            tableHeaders.map((item) => ({
                                                ...item,
                                                showTooltip: el.tooltip_name === item.tooltip_name,
                                            }))
                                        );
                                    }}
                                    onMouseLeave={() => {
                                        setTableHeaders(
                                            tableHeaders.map((item) => ({
                                                ...item,
                                                showTooltip: false,
                                            }))
                                        );
                                    }}
                                    className="project-table__th"
                                >
                                    {el?.showTooltip ? (
                                        <span
                                            className={
                                                el?.tooltip_text?.length > 20
                                                    ? "lengthy"
                                                    : "not-lengthy"
                                            }
                                        >
                      {el?.tooltip_text}
                    </span>
                                    ) : (
                                        ""
                                    )}
                                    {el?.tooltip_name}
                                </div>
                            );
                        })}
                    </div>
                    <div className="project-table__body">
                        {data?.map((item, index) => (
                            <div key={index}>
                                {index !== 0 && currentRow === item?.id && (
                                    <div className={`table__header`} key={index} style={{gridTemplateColumns: '16% 16% 14% 14% 12% 16% 10%'}}>
                                        {tableHeaders?.map((el, idx) => {
                                            return (
                                                <div key={idx} className="project-table__th">
                                                    {el?.showTooltip ? (
                                                        <span
                                                            className={
                                                                el?.tooltip_text?.length > 20
                                                                    ? "lengthy"
                                                                    : "not-lengthy"
                                                            }
                                                        >
                              {el?.tooltip_text}
                            </span>
                                                    ) : (
                                                        ""
                                                    )}
                                                    {el?.tooltip_name}
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                                <form
                                    className={`table__row ${
                                        item?.edit ? "edit" : currentItem ? "n" : ""
                                    } ${
                                        Boolean(item.submitted) || !user?.permissions?.edit_project
                                            ? "submitted"
                                            : ""
                                    }`}
                                    id={item?.id}
                                    style={{gridTemplateColumns: '16% 16% 14% 14% 12% 16% 10%'}}
                                    key={item?.id}
                                    onSubmit={(e) => submitHandler(e, item)}
                                >
                                    <div className="project-table__td">
                                        <span>{item?.name}</span>
                                        <div className="project__block-input project-table__field">
                      <textarea
                          maxLength={190}
                          type="text"
                          defaultValue={item?.name}
                          onChange={(e) => {
                              setCurrentItem((old) => ({
                                  ...old,
                                  name: e.target.value,
                              }));
                          }}
                      />
                                        </div>
                                    </div>
                                    <div className="project-table__td">
                                        <span>{item?.description}</span>
                                        <div className="project__block-input project-table__field">
                      <textarea
                          className={`${
                              expanded ? "project__block-expandedInput" : ""
                          }`}
                          ref={expandedRef}
                          onFocus={() => setExpanded(true)}
                          onBlur={() => setExpanded(false)}
                          maxLength={190}
                          type="text"
                          defaultValue={item?.description}
                          onChange={(e) => {
                              setCurrentItem((old) => ({
                                  ...old,
                                  description: e.target.value,
                              }));
                          }}
                      />
                                        </div>
                                    </div>
                                    <div className="project-table__td">
                    <span>
                      {moment(item?.date).format("DD/MM/YYYY") ===
                      "Invalid date" ||
                      !moment(item?.date).format("DD/MM/YYYY")
                          ? ""
                          : moment(item?.date).format("DD/MM/YYYY")}
                    </span>
                                        <div className="project__block-item-calendar project-table__field">
                                            <CustomDatePicker
                                                portalId="root-portal"
                                                autoComplete="off"
                                                id="approved_date"
                                                placeholderText={translations?.['main.main.placeholder.dd_mm_yyyy']}
                                                dateFormat="dd/MM/yyyy"
                                                selected={
                                                    currentItem?.date
                                                        ? moment(currentItem?.date).toDate()
                                                        : ""
                                                }
                                                onChange={(date) => {
                                                    setCurrentItem((old) => ({
                                                        ...old,
                                                        date: date,
                                                    }));
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="project-table__td">
                                        <span>{item?.affected_person}</span>
                                        <div className="project__block-input project-table__field">
                                            <input
                                                maxLength={190}
                                                type="text"
                                                defaultValue={item?.axffected_person}
                                                onChange={(e) => {
                                                    setCurrentItem((old) => ({
                                                        ...old,
                                                        affected_person: e.target.value,
                                                    }));
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="project-table__td">
                    <span>
                      {item?.responsible_party?.label
                          ? item?.responsible_party?.label
                          : ""}
                    </span>
                                        <div className="project__block-input project-table__field select">
                                            <Select
                                                options={options}
                                                isSearchable={false}
                                                menuPortalTarget={document.body}
                                                styles={{
                                                    ...customStyles,
                                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                }}
                                                value={currentItem?.responsible_party}
                                                onChange={(selected) => {
                                                    setCurrentItem((old) => ({
                                                        ...old,
                                                        responsible_party: selected,
                                                    }));
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="project-table__td">
                                        <span>{item?.comment}</span>
                                        <div className="project__block-input project-table__field">
                      <textarea
                          className={`${
                              decrExpanded ? "project__block-expandedInput" : ""
                          }`}
                          maxLength={190}
                          type="text"
                          defaultValue={item?.comment}
                          onFocus={() => setDecrExpanded(true)}
                          onBlur={() => setDecrExpanded(false)}
                          onChange={(e) => {
                              setCurrentItem((old) => ({
                                  ...old,
                                  comment: e.target.value,
                              }));
                          }}
                      />
                                        </div>
                                    </div>
                                    <div className="project-table__td">
                                        <Switch
                                            value={item?.closed}
                                            id={index + 1312}
                                            disabled={item?.edit ? false : true}
                                            checked={item?.edit ? currentItem?.closed : item?.closed}
                                            handler={(value) => {
                                                setCurrentItem((old) => ({
                                                    ...old,
                                                    closed: value,
                                                }));
                                            }}
                                        />
                                    </div>
                                    <div className="project__block-btns">
                                        {currentItem ? (
                                            <div
                                                className="project__block-cancel"
                                                onClick={() => {
                                                    setCurrentRow(null);
                                                    let array = data?.map((item) => {
                                                        item.edit = false;
                                                        return item;
                                                    });
                                                    setCurrentItem(null);
                                                    setData(array);
                                                    if (newItem) {
                                                        setNewItem(false);
                                                        let s = data?.filter(
                                                            (el, i) => i !== data.length - 1
                                                        );
                                                        setData(s);
                                                    }
                                                }}
                                            >
                                                <img src="/assets/icons/close-mark.svg" alt="close" />
                                                {translations?.['environment_social_health_safety_and_security_obligations.complaints_grievances.actions.cancel']}
                                            </div>
                                        ) : (
                                            <div
                                                className="project__block-delete"
                                                onClick={() => {
                                                    setDeleteModal({ id: item?.id, show: true });
                                                }}
                                            >
                                                <img src="/assets/icons/trash-icon.svg" alt="arrow" />
                                                {translations?.['environment_social_health_safety_and_security_obligations.complaints_grievances.actions.delete']}
                                            </div>
                                        )}
                                        {currentItem ? (
                                            <button className="project__block-save">
                                                <img src="/assets/icons/save-arrow.svg" alt="arrow" />
                                                {translations?.['environment_social_health_safety_and_security_obligations.complaints_grievances.actions.save']}
                                            </button>
                                        ) : (
                                            <div
                                                onClick={() => {
                                                    setCurrentRow(item?.id);
                                                    setCurrentItem(item);
                                                    setEditable(true);
                                                    setData(() => {
                                                        let array = data?.map((elem) => {
                                                            if (elem?.id === item?.id) {
                                                                elem.edit = true;
                                                            }
                                                            return elem;
                                                        });
                                                        return array;
                                                    });
                                                }}
                                                className="project__block-update"
                                            >
                                                {translations?.['environment_social_health_safety_and_security_obligations.complaints_grievances.actions.update']}
                                            </div>
                                        )}
                                    </div>
                                </form>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
