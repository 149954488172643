import React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import './index.scss';

const ElementDetails = ({data, statisticsPage}) =>{
    const location = useLocation();
    const navigate = useNavigate();

    const viewReport = (report_id) => {
        navigate(`/online-view/${report_id}`, {
            state: {
                id: report_id,
                prevLocation: location?.pathname,
            },
        });
    };

    return(
       <div className="elementDetailsCotainer">
           <div className="elementDetails">
               <span className="elementDetails__triangle"/>
               <div className="elementDetails__header">
                   <div className="elementDetails__header__statistic">
                    <span
                        className="elementDetails__header__statistic__colorEl"
                        style={{background:data?.color}}
                    />
                       <span className="elementDetails__header__statistic__values">{data?.title}</span>
                   </div>
                   <span className="elementDetails__header__projectsCount">
                    {data?.details?.length} {statisticsPage?.['index.index.titles.project_s']}
                </span>
               </div>
               {data?.details?.map((item, index) =>(
                   <div className="elementDetails__item" key={item?.project_title}>
                       <div className="elementDetails__item__header">
                           <div>
                               <h6>
                                   {item?.project_title}
                               </h6>
                               <p>
                                   {item?.contract_number}
                               </p>
                           </div>
                           <a className="elementDetails__item__header__viewReportBtn"
                              onClick={()=>viewReport(item.report_id.value)}>
                               {item.report_id.title}
                           </a>
                       </div>
                       <div className="elementDetails__item__wrapper">
                           <div className="elementDetails__item__wrapper__item">
                               <span className="elementDetails__item__wrapper__item__title" style={{overflowWrap: 'break-word'}}>{item?.ea_contractor_consultant_supplier.title}</span>
                               <span className="elementDetails__item__wrapper__item__description">{item?.ea_contractor_consultant_supplier.value}</span>
                           </div>
                           <div className="elementDetails__item__wrapper__item">
                               <span className="elementDetails__item__wrapper__item__title">{item?.country.title}</span>
                               <span className="elementDetails__item__wrapper__item__description">{item?.country.value}</span>
                           </div>
                           <div className="elementDetails__item__wrapper__item">
                               <span className="elementDetails__item__wrapper__item__title">{item?.region.title}</span>
                               <span className="elementDetails__item__wrapper__item__description"> {item?.region.value}</span>
                           </div>
                           <div className="elementDetails__item__wrapper__item">
                               <span className="elementDetails__item__wrapper__item__title">{item?.sector.title}</span>
                               <span className="elementDetails__item__wrapper__item__description">{item?.sector.value}</span>
                           </div>
                       </div>
                       <div className="elementDetails__item__contractDetailsContainer">
                           {data?.details[index]?.extended_data &&  Object.keys(data?.details[index]?.extended_data)?.map((key)=>(
                               <div className="elementDetails__item__contractDetails" key={key}>
                                   <span className="elementDetails__item__contractDetails__description">{data?.details[index]?.extended_data[key]?.title}:</span>
                                   <span className="elementDetails__item__contractDetails__value">{data?.details[index]?.extended_data[key]?.value}</span>
                               </div>
                           ))}
                       </div>
                   </div>
               ))}

           </div>
       </div>
    )
}
export default ElementDetails;