import React, {useEffect, useState} from "react";
import {Cell, Pie, PieChart} from "recharts";
import axios from "axios";
import {useLocation} from "react-router-dom";
import useLastPathname from "../../global/useLastPathname";
import './index.scss'

export const ChartPie = ({id, translations,reportId, isPage}) => {
    const location = useLocation();
    const lastPathname = useLastPathname();
    const [pieData, setPieData] = useState([]);
    const [docTranslations, setDocTranslations] = useState([]);
    const COLORS = ["#0CAF60", "#ECC949", "#3375D6"];


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/chart/pie/${isPage ? `${reportId}`:  location?.pathname?.split('/')[2]}`,{
            headers: {
                "AppLocale": lastPathname
            }
        })
            .then((res) => {
                let response = res?.data?.data?.data;
                setDocTranslations(res?.data?.data?.translations)
                setPieData([
                    {name: "Overall progress", value: +response?.overall_progress},
                    {name: "Time elapsed", value: +response?.time_elapsed},
                    {name: "Time remaining", value: +response?.time_remaining},
                ])
            })

    }, [])

    return (
        <div style={{width: '100%'}} className="project__program-right">
            <div className="project__program-circle">
                <PieChart height={152} width={152}>
                    <Pie
                        animationDuration={0}
                        data={pieData}
                        dataKey="value"
                        nameKey="name"
                        color="#000000"
                        fill="#8884d8"
                        innerRadius={35}
                        cx="50%"
                        cy="50%"
                    >
                        {
                            pieData?.map((el,idx) => (
                                <Cell  key={`cell-${idx}`}
                                       fill={COLORS[idx % COLORS.length]}
                                />
                            ))
                        }
                    </Pie>
                </PieChart>
            </div>
            <div className="project__program-stats">
                <div className="project__program-stat">
                    <div className="project__program-color green"></div>
                    <p>{translations?.['project_monitoring.program_overview.titles.overall_progress'] || docTranslations?.['overall_progress']} <span>{pieData[0]?.value ? pieData[0]?.value.toFixed(1) : '0'}%</span></p>
                </div>
                <div className="project__program-stat">
                    <div className="project__program-color yellow"></div>
                    <p>{translations?.['project_monitoring.program_overview.titles.time_elapsed'] || docTranslations?.['time_elapsed']} <span>{pieData[1]?.value ? pieData[1]?.value?.toFixed(1) : '0'}%</span></p>
                </div>
                <div className="project__program-stat">
                    <div className="project__program-color blue"></div>
                    <p>{translations?.['project_monitoring.program_overview.titles.time_remaining'] || docTranslations?.['time_remaining']} <span>{pieData[2]?.value ? pieData[2]?.value?.toFixed(1) : '0'}%</span></p>
                </div>
            </div>
        </div>
    )
}