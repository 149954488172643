import moment from "moment/moment";
import isEmpty from "lodash/isEmpty";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useRef, useState} from 'react';
import Wrapper from "../../components/Wrapper";
import ElementStatistics from "./ElementStatistics";
import StatisticFilter from "../../components/StatisticFilter";
import CustomDatePicker from "../../components/CustomDatePicker";
import {
    useLazyGetStatisticsQuery,
    useLazyGetStatisticsPageQuery,
} from "../../store/service/adbService";
import {setLoader} from "../../store/slice/dashboard";
import {setFilteredData, setStatisticData} from "../../store/slice/statistic";
import './index.scss';
import DashboardDetailModal from "./DashboardDetailModal";

const ManagementDashboard = () =>{
   const localeLan = localStorage.getItem("local_lan" );
 moment.locale(localeLan);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const filteredData = useSelector((state) => state?.statisticReducer?.filteredData);
    const statisticData = useSelector((state) => state?.statisticReducer?.statisticData);

    const hasPageBeenRendered = useRef(false)
    const [statisticsPage,setStatisticsPage] = useState({});
    const [isModalOpen,setIsModalOpen] = useState(false);
    const [isOpenProjectDetailModal,setIsOpenProjectDetailModal] = useState(false);
    const [selectedDate, setSelectedDate] = useState(filteredData?.data);

    const [selectedProjects, setSelectedProjects] = useState(filteredData?.selectedProjects);
    const [selectedValues, setSelectedValues] = useState(filteredData?.selectedValues);
    const [selectAllIsChecked, setSelectAllIsChecked] = useState(false);
    const [projects, setProjects] = useState(filteredData?.projects);
    const [getStatistics, {
        isLoading: statisticsDataIsLoading,
        isFetching: statisticsDataIsFetching,
    }] = useLazyGetStatisticsQuery();

    const[
        getStatisticsPage,
        {
            data: statisticsPageData,
            isLoading: statisticsPageDataLoading,
        }]
        = useLazyGetStatisticsPageQuery();

    const getStatisticsRequest = async (params) => {
        await getStatistics({
            date: selectedDate? moment(selectedDate)?.format("YYYY-MM") : '',
            projects: params})
            .then((res) => {
                dispatch(setStatisticData(res.data.data));
                toast.success(res.data.message);
            })
            .catch((error) => toast.error(error))
    }

    useEffect(() => {
        if (!localStorage.getItem("user_token")) {
           return  navigate("/login");
        }
        getStatisticsPage();
    }, []);

    useEffect(() => {
        setStatisticsPage(statisticsPageData?.data?.translations);
    }, [statisticsPageData]);

    useEffect(() => {
        statisticsPageDataLoading || statisticsDataIsFetching || statisticsDataIsLoading ? dispatch(setLoader(true)) : dispatch(setLoader(false))
    }, [ statisticsDataIsLoading, statisticsDataIsFetching, statisticsPageDataLoading ]);

    useEffect(() => {
        if (hasPageBeenRendered.current) {
            const queryParams = selectedProjects?.map(item => `projects[]=${item.id}`).join('&');
            if (queryParams){
                getStatisticsRequest(queryParams).then(() =>{
                    setIsModalOpen(false);
                    dispatch(setFilteredData({
                        data: selectedDate,
                        selectedValues,
                        selectedProjects,
                        projects
                    }))
                })
            }
        }
        hasPageBeenRendered.current=true

    }, [selectedDate, selectedProjects]);

    return(
        <Wrapper classname="statistics">
            <div className="statistics__header">
                <h1>{statisticsPage?.['index.index.headers.management_dashboard']}</h1>
                <div  className="statistics__header__rightSide">
                    {statisticData && <div className="statistics__header__datePickerContainer reporting-select">
                        <CustomDatePicker
                            selected={selectedDate && selectedDate}
                            placeholderText={statisticsPage?.['index.index.actions.choose_month']}
                            onChange={(date) => {
                                setSelectedDate(date);
                            }}
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                        />
                    </div>}
                    <button
                        className="statistics__header__filterBtn"
                        onClick={()=> setIsModalOpen(true)}
                    >
                        <img src='/assets/icons/filter.svg' alt="filter icon"/>
                        {statisticsPage?.['index.index.actions.filter']}
                    </button>
                </div>

            </div>
            {statisticData &&
                <div className="statistics__toolBar">
                    <button onClick={()=>setIsOpenProjectDetailModal(true)}>
                      {statisticsPage?.['index.index.titles.viewed_projects']}: {statisticData?.['total_projects']}
                    </button>
                </div>
            }
            <div className="statistics__content">
                {isEmpty(statisticData) &&
                    <div className="statistics__content__emptyContentContainer">
                        <img src="/assets/images/projects.png" alt=""/>
                        <span style={{color: "#767676", fontSize: 16, fontWeight: 500}}>{statisticsPage?.['index.index.headers.no_statistics']}</span>
                    </div>
                }
                {statisticData && Object.keys(statisticData).map((key) => {
                    if(key!=='total_projects' && key!=='project_list'){
                        return <ElementStatistics
                            key={key}
                            statisticsPage={statisticsPage}
                            projectData={statisticData[key]}
                            selectedDate={selectedDate}
                        />
                    }
                })}
            </div>
            {
                isOpenProjectDetailModal &&
                <DashboardDetailModal
                  statisticsPage={statisticsPage}
                    data={statisticData.project_list}
                    closeModal={setIsOpenProjectDetailModal}
                />
            }
            {
                isModalOpen &&
                <StatisticFilter
                    statisticsPage={statisticsPage}
                    date={selectedDate}
                    selectedProjects={selectedProjects}
                    setSelectedProjects={setSelectedProjects}
                    closeModal={ setIsModalOpen}
                    selectedValues={selectedValues}
                    setSelectedValues={setSelectedValues}
                    selectAllIsChecked={selectAllIsChecked}
                    setSelectAllIsChecked={setSelectAllIsChecked}
                    projects ={projects}
                    setProjects={setProjects}

                />
            }
        </Wrapper>
    )
}

export default ManagementDashboard;