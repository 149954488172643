import { toast } from "react-toastify";

const useErrorsHandler = () => {
  const error = (errors) => {
    if (Array.isArray(errors?.data?.errors)) {
    } else {
      toast.error(errors?.data?.errors.error, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 8000,
      });
    }
  };

  return {
    error,
  };
};

export default useErrorsHandler;
