import React, {useEffect, useState} from "react";
import InfoModal from "../../../InfoModal";

export const ComplaintStatistics = ({complaintsData, variationsData, glossaryData, translations}) => {
    const [infoModal, setInfoModal] = useState(false)
    const [moreInfo, setMoreInfo] = useState(null)

    useEffect(() => {
        setMoreInfo(glossaryData?.data?.filter(el => el?.section_name === "Gender Tracking")[0])
    }, [glossaryData])

    return (
        <>
            <div className="project__complaints">
                {infoModal && (
                    <InfoModal
                        moreInfo={moreInfo}
                        close={() => setInfoModal(!infoModal)}
                    />
                )}
                <div className="project__gender-content">
                    <div style={{display: "flex", alignItems: "center"}}>
                        <div className="project__block-subtitle">{translations?.['environment_social_health_safety_and_security_obligations.complaint_statistics.headers.complaint_statistics']}</div>
                    </div>
                </div>
                <div className="project__status-content">
                    <div className="project__complaints-box">
                        <p className="project__gender-subtitle">
                            {translations?.['environment_social_health_safety_and_security_obligations.complaint_statistics.titles.total_complaints']}
                        </p>
                        <span className="project__status-text">
                           {complaintsData?.complaint_statistics?.total ? complaintsData?.complaint_statistics?.total : '-'}
                    </span>
                    </div>
                    <div className="project__complaints-box">
                        <p className="project__gender-subtitle">
                            {translations?.['environment_social_health_safety_and_security_obligations.complaint_statistics.titles.total_complaints_open']}
                        </p>
                        <span className="project__status-text">
                           {complaintsData?.complaint_statistics?.total_open ? complaintsData?.complaint_statistics?.total_open : '-'}
                    </span>
                    </div>
                    <div className="project__complaints-box">
                        <p className="project__gender-subtitle">
                            {translations?.['environment_social_health_safety_and_security_obligations.complaint_statistics.titles.total_complaints_closed']}
                        </p>
                        <span className="project__status-text">
                           {complaintsData?.complaint_statistics?.total_closed ? complaintsData?.complaint_statistics?.total_closed : '-'}
                    </span>
                    </div>
                </div>
            </div>
        </>
    )
}