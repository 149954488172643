import React, {useRef} from "react";
import {useEffect} from "react";
import {useState} from "react";
import {toast} from "react-toastify";
import {useErrorHandler} from "../../../../global/ErrorsHandler";
import {useUpdateCriticalPathMutation} from "../../../../store/service/adbService";
import {setLoader} from "../../../../store/slice/dashboard";
import {useDispatch, useSelector} from "react-redux";
import MoreInfoModal from "../../../MoreInfoModal";

export const CriticalPath = ({glossaryData, data, translations}) => {
    const dispatch = useDispatch()
    const [text, setText] = useState(data ?? "Please enter critical path");
    const [edit, setEdit] = useState(false);
    const [moreInfo, setMoreInfo] = useState(null)
    const user = useSelector((state) => state?.adbReducer?.user);
    const [prevText, setPrevText] = useState(
        data ?? "Please enter critical path"
    );
    const [infoModal, setInfoModal] = useState(false)
    const [criticalData, setCriticalData] = useState('')
    const expandedRef = useRef(null)
    const [expanded, setExpanded] = useState(false)

    useEffect(() => {
        if (data) {
            setCriticalData(data?.critical_path)
        }
    }, [data])

    useEffect(() => {
        setMoreInfo(glossaryData?.data?.filter(el => el?.section_name === "Critical Path")[0])
        if (criticalData?.length) {
            setText(criticalData)
            setPrevText(criticalData)
        }
    }, [data, glossaryData, criticalData]);

    const [
        updateCriticalPath,
        {
            data: updateCriticalPathData,
            isError: updateCriticalPathIsError,
            isLoading: updateCriticalPathIsLoading,
            isSuccess: updateCriticalPathIsSuccess,
            error: updateCriticalPathError,
        },
    ] = useUpdateCriticalPathMutation();

    useEffect(() => {
        const d = () => {
            dispatch(setLoader(false));
            setEdit(false)
        };

        updateCriticalPathIsLoading
            ? dispatch(setLoader(true))
            : d();
    }, [
        updateCriticalPathIsLoading
    ]);


    const [errorHandler, setErrorHandler] = useErrorHandler([
        updateCriticalPathError
    ]);

    const submitHandler = (e) => {
        e.preventDefault();

        if (text.length > 0) {
            updateCriticalPath({id: data?.id, body: {critical_path_overview: text}})
        } else {
            toast.error("Please enter summary", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }
    };


    return (
        <>
            {infoModal && (
                <MoreInfoModal
                    title={translations?.['project_monitoring.critical_path.headers.critical_path']}
                    moreInfo={translations?.['project_monitoring.critical_path.more_info.critical_path']}
                    close={()=> setInfoModal(false)}
                />
            )}
            <div className={`project__block`}>
                <div className="project__block-hr"></div>
                <div className="project__program-subtitles">
                    <div className="project__program-subtitle">
                        {translations?.['project_monitoring.critical_path.headers.critical_path']}
                        <span onClick={() => setInfoModal(!infoModal)} className="project__block-more">
                        <span className="img">
                        <img src="/assets/icons/info.svg" alt="info"/>
                    </span>
                    <span  className="project__program-text">{translations?.['project_monitoring.critical_path.titles.more_info']}</span>
                    </span>
                    </div>
                </div>
                <div
                    className={`project__block-content executive-summary ${
                        edit ? "edit" : ""
                    }`}
                >
                    <div className="gray-box">
                        <form onSubmit={submitHandler} className="content">
                            <div className="text">
                                {edit ? (
                                    <textarea
                                        className={`${expanded ? 'project__block-expandedTextArea' : ''}`}
                                        ref={expandedRef}
                                        onFocus={() => setExpanded(true)}
                                        rows={5}
                                        defaultValue={criticalData}
                                        placeholder="Please enter critical path"
                                        maxLength={2000}
                                        onChange={(e) => {
                                            setText(e.target.value);
                                        }}
                                    />
                                ) : (
                                    <p className="project__program-desc">{criticalData}</p>
                                )}
                            </div>
                            {
                                user?.permissions?.edit_project &&
                                <div className="project__block-btns">
                                    {edit && (
                                        <div
                                            className="project__block-cancel"
                                            onClick={() => {
                                                setText(prevText);
                                                setEdit(false);
                                            }}
                                        >
                                            <img src="/assets/icons/close-mark.svg" alt="close"/>
                                            {translations?.['project_monitoring.critical_path.actions.cancel']}
                                        </div>
                                    )}
                                    {edit ? (
                                        <button onClick={() => setExpanded(false)} type="submit" className="project__block-save">
                                            <img src="/assets/icons/save-arrow.svg" alt="arrow"/>
                                            {translations?.['project_monitoring.critical_path.actions.save']}
                                        </button>
                                    ) : (
                                        <div
                                            onClick={() => {
                                                setEdit(true);
                                            }}
                                            className="project__block-update"
                                        >
                                            {translations?.['project_monitoring.critical_path.actions.edit']}
                                        </div>
                                    )}
                                </div>
                            }
                        </form>
                    </div>
                </div>
            </div>
        </>

    );
};
