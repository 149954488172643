import isEmpty from "lodash/isEmpty";
import {toast} from "react-toastify";
import React, {useEffect, useMemo, useState} from "react";
import {useDispatch} from "react-redux";
import {setLoader} from "../../../store/slice/dashboard";
import {useCopyNotificationsMutation} from "../../../store/service/adbService";
import Modal from "../../../components/Modal";
import AxelInput from "../../../UI/AxelInput";
import AxelCheckbox from "../../../UI/AxelCheckbox";
import AxelButton from "../../../UI/AxelButton";
import {errorHandle} from "../../../global/errorHandle";

const CopySettings = ({
                          title,
                          modalTitle,
                          modalDescription,
                          searchPlaceholder,
                          cancelButton,
                          confirmButton,
                          projects,
                          mainProjectId,
                          updateSections,
                          noProjectsDesc
}) => {
    const dispatch = useDispatch();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [initialProjects, setInitialProjects] = useState([]);
    const [selectedProjects, setSelectedProjects] = useState([]);
    const [searchText, setSearchText] = useState("");

    const filteredProjects = selectedProjects?.filter(project =>
        (project?.contract_title.toLowerCase().includes(searchText.toLowerCase()) ||
        project?.contract_number.toLowerCase().includes(searchText.toLowerCase())) &&
        project.project_id !== mainProjectId
    );

    const [copyNotifications, {
        data: copyNotificationsData,
        isLoading: isLoadingCopyNotifications,
        isSuccess: isSuccessCopyNotifications,
        isError: isErrorCopyNotifications,
        error: errorCopyNotifications,

    }] = useCopyNotificationsMutation();

    const handleSelectProjects = (id, checked) => {
        setSelectedProjects(oldData => oldData.map(project => {
            if (project.project_id == id) {
                return {
                    ...project,
                    isActiveToCopy: checked
                };
            }
            return project;
        }))
    }

    const projectsToCopy = useMemo(()=>{
        const proj= selectedProjects?.filter(project => {
            if (project.isActiveToCopy) {
                return project.project_id;
            }
        });
        return proj
    },[selectedProjects])

    const handleCancelButton =(value) => {
        setIsOpenModal(value);
        setSelectedProjects(initialProjects);
        setSearchText("");
    }

    const handleConfirmButton = async () => {
        const formData = new FormData();
        selectedProjects.filter(project => {
            if (project.isActiveToCopy) {
                return project.project_id;
            }
        }).forEach(filtered => formData.append(`projects[]`, filtered.project_id))

        copyNotifications({
            projectId: mainProjectId,
            body: formData
        }).then(() => { setInitialProjects(selectedProjects)})
    }

    useEffect(() => {
        setSelectedProjects(projects);
        setInitialProjects(projects);
    }, [projects]);

    useEffect(() => {
        if (isSuccessCopyNotifications){
            toast.success(copyNotificationsData.message)
            updateSections();
            setIsOpenModal(false);
        }
    }, [isSuccessCopyNotifications]);
    
    useEffect(() => {
        if (isErrorCopyNotifications){
            errorHandle(errorCopyNotifications)
        }
    }, [ isErrorCopyNotifications, errorCopyNotifications]);

    useEffect(() => {
        isLoadingCopyNotifications ? dispatch(setLoader(true))
            : dispatch(setLoader(false));
    }, [ isLoadingCopyNotifications ]);

    return(
        <>
            <button className='nSettings__copyBtn' onClick={()=> {
                setIsOpenModal(true)
            }}>
                <img src='/assets/icons/copy-without-bg.svg' alt='collapse icon'/>
                {title}
            </button>
            {isOpenModal && <Modal
                title={modalTitle}
                closeModal={handleCancelButton}
            >
                <div className='nSettings__copySettings fade-in'>
                    <p>
                        {modalDescription}
                    </p>
                    <AxelInput
                        className="nSettings__copySettings__search"
                        src={'/assets/icons/search.svg'}
                        placeholder={searchPlaceholder}
                        value={searchText}
                        handler={(e) => setSearchText(e.target.value)}
                    />
                    <div className="nSettings__copySettings__projects" >
                        {filteredProjects?.map(project => (
                            <div className="nSettings__copySettings__projects__project" key={project.project_id}>
                                <span>{project?.contract_title} {'  '}{project?.contract_number}</span>
                                <AxelCheckbox
                                    id={project?.project_id}
                                    checked={!!project?.isActiveToCopy}
                                    handler={handleSelectProjects}
                                />
                            </div>
                        ))}
                        { isEmpty(filteredProjects) && <div className="statisticFilter__form__notFoundDat">
                            <img src={'/assets/images/emptyStatisticPlaceHolder.png'} alt="empty file image"/>
                            <p>{noProjectsDesc}</p>
                        </div>}
                    </div>

                    <div className="nSettings__copySettings__btnWrapper">
                        <AxelButton
                            classname='nSettings__copySettings__cancelButton'
                            handler={()=>handleCancelButton(false)}
                        >
                            {cancelButton}
                        </AxelButton>
                        <AxelButton
                            handler={handleConfirmButton} classname={`${ projectsToCopy?.length  > 0 ? '' :'nSettings__copySettings__btnDisabled'}`}
                            disabled={projectsToCopy.length<=0}>{confirmButton}
                        </AxelButton>
                    </div>
                </div>

            </Modal>}
        </>
    )
}

export default CopySettings;