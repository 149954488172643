import moment from "moment/moment";
import React, {useEffect, useState} from 'react';
import Modal from "../Modal";
import AxelInput from "../../UI/AxelInput";
import './index.scss';
import {useDuplicateQuarterlyReportMutation} from "../../store/service/adbService";
import {toast} from "react-toastify";
import {setLoader} from "../../store/slice/dashboard";
import {errorHandle} from "../../global/errorHandle";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import CustomDatePicker from "../CustomDatePicker";

const CreateReportModal = (
    {
        closeModal,
        title,
        reportId,
        cancelButton,
        confirmButton,
        reportNumberTitle,
        reportDateTitle,
        reportNumberPlaceholder,
        datePickerPlaceholder,
    }
) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [reportNumber, setReportNumber] = useState('');
    const [reportDate, setReportDate] = useState('');

    const [duplicateQuarterlyReport, {
        data: reportDuplicateData,
        isLoading: reportDuplicateIsLoading,
        error: reportDuplicateError,
        isError: reportDuplicateIsError,
        isSuccess: reportDuplicateIsSuccess,
    }] = useDuplicateQuarterlyReportMutation();

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append("report_date", moment(reportDate, "DD MMM, YYYY").format("YYYY-MM-DD"));
        formData.append("report_number", reportNumber);

        duplicateQuarterlyReport({
            reportId: reportId,
            body: formData
        })
    };

    const cancelHandle = () => {
        setReportNumber('');
        setReportDate('');
        closeModal(false);
    }
    useEffect(()=>{
        if (reportDuplicateIsSuccess) {
            toast.success(reportDuplicateData.message);
            closeModal(false)
            navigate(`/edit-quarterly-report/${reportDuplicateData?.data?.report_id}`)
        }
    }, [reportDuplicateIsSuccess, reportDuplicateData]);

    useEffect(() => {
        reportDuplicateIsLoading ?
            dispatch(setLoader(true)) :
            dispatch(setLoader(false))
    }, [reportDuplicateIsLoading]);

    useEffect(() => {
        if (reportDuplicateIsError){
            toast.error(reportDuplicateError?.data?.message)
            errorHandle(reportDuplicateError);
        }
    },[reportDuplicateIsError, reportDuplicateError])

    return(
        <Modal closeModal={closeModal} title={title}>
            <form onSubmit={handleSubmit} className='reportForm'>
                <div className='reportForm__fieldContainer'>
                    <label htmlFor="report_number" >
                        {reportNumberTitle} <span > *</span>
                    </label>
                    <AxelInput
                        maxLength={190}
                        type="text"
                        name='report_number'
                        onChange={(e) => setReportNumber(e.target.value)}
                        value={reportNumber}
                        placeholder={reportNumberPlaceholder}
                    />
                </div>
                <div className='reportForm__fieldContainer'>
                    <label htmlFor="report_date">
                        {reportDateTitle} <span > *</span>
                    </label>
                    <div className="reportForm__calendar">
                        <CustomDatePicker
                            autoComplete="off"
                            id="report_date"
                            dateFormat="dd MMM, yyyy"
                            placeholderText={datePickerPlaceholder}
                            selected={
                                reportDate && moment(reportDate, "DD MMM, YYYY").isValid()
                                    ? moment(reportDate, "DD MMM, YYYY").toDate()
                                    : null
                            }
                            onChange={(date) => setReportDate(moment(date).format("DD MMM, YYYY"))}
                        />
                        <label htmlFor="report_date" >
                            <img src="/assets/icons/calendar.svg" alt="calendar" />
                        </label>
                    </div>
                </div>
                <div className='reportForm__buttons'>
                    <button type="button" onClick={cancelHandle} className='reportForm__buttons_cancel'>
                        {cancelButton}
                    </button>
                    <button type="submit" className='reportForm__buttons_confirm'>
                        {confirmButton}
                    </button>
                </div>
        </form>

    </Modal>
    )
}

export default CreateReportModal;