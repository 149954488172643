import React from "react";
import classNames from "classnames";

import "./index.scss";

export default function AxelButton({ text, classname, disabled = false, handler, children }) {
  return (
    <button className={classNames('a-button', {[classname] : true})} onClick={handler} disabled={disabled}>
      {children}
      {text}
    </button>
  );
}
