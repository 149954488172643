import {toast} from "react-toastify";
import React, {useRef, useState} from 'react';
import ReactCodeInput from "react-verification-code-input";
import Timer from "../Timer";
import AxelButton from "../../UI/AxelButton";
import './index.scss';

const VerificationCode  =  (props) => {
    const {
        itemValue,
        btnTitle,
        verifyFunc,
        resendFunc,
        description,
        resentButtonText
    } = props;

    const [isShowResend, setIsShowResend] = useState(false);
    const [code, setCode] = useState(null);

    const handleTimerEnd = () => {
        setIsShowResend(true);
    };

    const codeHandler = (code) => {
        setCode(code)
    }

    const handleSubmit = () => {
        if (code?.length === 6) {
            verifyFunc(code);
        } else {
            toast.error('Enter valid code!')
        }
    }

    return(
        <div className="modal-mainContainer">
            <div className="modal-text">
                {description}
                <span> {itemValue}</span>
            </div>
            <ReactCodeInput
                className={'auth__form-codeField'}
                fieldWidth={48} onChange={codeHandler}
                fieldHeight={48} autoFocus={false}/>

            {isShowResend ? <button className="modal-resend" onClick={()=>{setIsShowResend(false); resendFunc()}}>
                {resentButtonText}
            </button>:   <Timer onTimerEnd={handleTimerEnd} classname="modal-timer" />
            }
            <AxelButton
                handler={handleSubmit}
                className="modal-sure"
            >
                {btnTitle}
            </AxelButton>
        </div>
    )
}

export default VerificationCode;