import React, {useEffect, useState} from 'react';
import {
	useGetQuarterlyDetails, useGetQuarterlySectionsTitle
} from "../../api/quarterly/quarterly.api";
import {useLocation, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import QuarterlySidebar from "./QuarterlySidebar";
import QuarterlyBottomPanel from "./QuarterlyBottomPanel";
import QuarterlyHeader from "./QuarterlyHeader";
import QuarterlyTitle from "./QuarterlyTitle";
import QuarterlyLoadGrant from "./QuarterlyLoadGrant";
import QuarterlyFinancingPlan from "./QuarterlyFinancingPlan";
import QuarterlyLoanUtilization from "./QuarterlyLoanUtilization";
import QuarterlyDevelopmentObjectives from "./QuarterlyDevelopmentObjectives";
import QuarterlyImplementationProgress from "./QuarterlyImplementationProgress";
import QuarterlyFinancialCovenants from "./QuarterlyFinancialCovenants";
import QuarterlyLoanCovenants from "./QuarterlyLoanCovenants";
import QuarterlySafeguardsCovenants from "./QuarterlySafeguardsCovenants";
import QuarterlyEnvironmentalCovenants from "./QuarterlyEnvironmentalCovenants";
import QuarterlyGenderCovenants from "./QuarterlyGenderCovenants";
import QuarterlyOtherCovenants from "./QuarterlyOtherCovenants";
import QuarterlyListsTables from "./QuarterlyListsTables";
import QuarterlySidePhotographs from "./QuarterlySidePhotographs";
import {setOnlineView} from "../../store/slice/quarterly.slice";
import moment from "moment";
import {useLazyGetQuarterlyReportDetailsPageQuery} from "../../store/service/adbService";

function QuarterlyOnlineView() {
	const dispatch = useDispatch()
	const sections_title = useSelector(state => state?.quarterlyReducer?.side_bar) || [];
	const submitted_reports = useSelector(state => state?.quarterlyReducer?.submitted_reports);
	const [refs, setRefs] = useState({});
	const location = useLocation();
	const params = useParams()
	const [selectedDate, setSelectedDate] = useState(null);
	const [id, setId] = useState(location?.state?.id ?? params?.id);
	const [activeSection, setActiveSection] = useState('');
	const [pageTranslations, setPageTranslations] = useState({})

	const [
		getQuarterlyReportDetailsPage,
		{data: quarterlyReportDetailsPageData}
	] = useLazyGetQuarterlyReportDetailsPageQuery();

	useEffect(() => {
		getQuarterlyReportDetailsPage()
	}, [])

	useEffect(() => {
		if(quarterlyReportDetailsPageData?.data){
			setPageTranslations(quarterlyReportDetailsPageData?.data?.translations)
		}
	}, [quarterlyReportDetailsPageData])
	
	useEffect(() => {
		dispatch(setOnlineView(null))
	}, []);
	
	useGetQuarterlyDetails(id, selectedDate?.label);
	useGetQuarterlySectionsTitle();
	
	const handleChangeDate = (date) => {
		setSelectedDate(date);
		let report_id = submitted_reports?.filter(
				(el) =>
						moment(el?.label).format("YYYY-MM-DD") ===
						moment(date?.label)?.format("YYYY-MM-DD")
		)[0].value
		setId(report_id)
	};
	
	useEffect(() => {
		if (sections_title.length > 0) {
			const newRefs = sections_title.reduce((acc, section) => {
				acc[section.key] = React.createRef();
				return acc;
			}, {});
			setRefs(newRefs);
		}
	}, [sections_title]);
	
	
	const handleScrollToSection = (key) => {
		refs[key]?.current?.scrollIntoView({behavior: 'auto', block: 'start', inline: 'start'});
	};
	
	const handleScroll = () => {
		const scrollPosition = window.scrollY + 50;
		
		sections_title.forEach((section, i) => {
			const ref = refs[section.key]?.current;
			if (ref) {
				const sectionTop = ref.offsetTop;
				const sectionBottom = sectionTop + ref.offsetHeight;
				if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
					setActiveSection(section.key);
				}
			}
		});
	};
	
	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [refs, sections_title]);
	
	
	return (
			<div className="quarterly-online">
				<QuarterlySidebar
						sections_title={sections_title}
						refs={refs}
						onLinkClick={handleScrollToSection}
						activeSection={activeSection}
				/>
				<div className="online__scroll">
					<QuarterlyBottomPanel pageTranslations={pageTranslations}/>
					
					<div className="online__content">
						<div ref={refs[sections_title[0]?.key]} id={sections_title[0]?.key} className="online__content-container">
							<QuarterlyHeader
									selectedDate={selectedDate}
									handleChangeDate={handleChangeDate}
									pageTranslations={pageTranslations}
							/>
							<QuarterlyTitle/>
						</div>
						<div ref={refs[sections_title[1]?.key]} id={sections_title[1]?.key}>
							<QuarterlyLoadGrant pageTranslations={pageTranslations}/>
						</div>
						<div ref={refs[sections_title[2]?.key]} id={sections_title[2]?.key}>
							<QuarterlyFinancingPlan pageTranslations={pageTranslations}/>
						</div>
						<div ref={refs[sections_title[3]?.key]} id={sections_title[3]?.key}>
							<QuarterlyLoanUtilization pageTranslations={pageTranslations}/>
						</div>
						<div ref={refs[sections_title[4]?.key]} id={sections_title[4]?.key}>
							<QuarterlyDevelopmentObjectives pageTranslations={pageTranslations}/>
						</div>
						<div ref={refs[sections_title[5]?.key]} id={sections_title[5]?.key}>
							<QuarterlyImplementationProgress pageTranslations={pageTranslations}/>
						</div>
						<div ref={refs[sections_title[6]?.key]} id={sections_title[6]?.key}>
							<QuarterlyFinancialCovenants pageTranslations={pageTranslations}/>
						</div>
						<div ref={refs[sections_title[7]?.key]} id={sections_title[7]?.key}>
							<QuarterlyLoanCovenants pageTranslations={pageTranslations}/>
						</div>
						<div ref={refs[sections_title[8]?.key]} id={sections_title[8]?.key}>
							<QuarterlySafeguardsCovenants pageTranslations={pageTranslations}/>
						</div>
						<div ref={refs[sections_title[9]?.key]} id={sections_title[9]?.key}>
							<QuarterlyEnvironmentalCovenants pageTranslations={pageTranslations}/>
						</div>
						<div ref={refs[sections_title[10]?.key]} id={sections_title[10]?.key}>
							<QuarterlyGenderCovenants pageTranslations={pageTranslations}/>
						</div>
						<div ref={refs[sections_title[11]?.key]} id={sections_title[11]?.key}>
							<QuarterlyOtherCovenants pageTranslations={pageTranslations}/>
						</div>
						<div ref={refs[sections_title[12]?.key]} id={sections_title[12]?.key}>
							<QuarterlyListsTables pageTranslations={pageTranslations}/>
						</div>
						<div ref={refs[sections_title[13]?.key]} id={sections_title[13]?.key}>
							<QuarterlySidePhotographs pageTranslations={pageTranslations}/>
						</div>
					</div>
				</div>
			
			</div>);
}

export default QuarterlyOnlineView;
