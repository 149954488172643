import React, {useEffect, useState} from "react";
import AxelInput from "../../UI/AxelInput";
import {formatString} from "../../global/formatString";

const ColumnHeaders = ({data, tabData, setSelectedValues, selectedValues, hasBeenRendered}) => {
    const [originalGlossary, setOriginalGlossary] = useState(tabData?.glossary?.find(item =>item.language === 'English'));

    const inputHandler = (fieldName,id, value) => {
        hasBeenRendered.current = false;
        setSelectedValues((old) => {
            if (data.translations[fieldName].value === value) {
                const { [fieldName]: _, ...remaining } = old;
                return remaining;
            }
            return { ...old, [id]: value };
        });
    };


    useEffect(() => {
        setOriginalGlossary(tabData?.glossary?.find(item =>item.language === 'English'));
    }, [tabData, data]);
    return (
        <div className="glossaryContent__tranlastionSection glossaryContent__tranlastionSection_row">
            {Object.keys(data?.translations)?.map((key)=>{
                return(
                    <div
                        key={key}
                        className={`glossaryContent__tranlastionSection__item 
                        ${Object.keys(data?.translations).length >= 10 ? 'glossaryContent__tranlastionSection__item_width45' : ''}`}
                    >
                        <label htmlFor="long_name" className="contact__modal-label">
                            { formatString(key) }
                            { data?.language !== originalGlossary?.language &&
                                <div className="glossaryContent__tranlastionSection__item__info">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.99935 17.7077C14.2565 17.7077 17.7077 14.2565 17.7077 9.99935C17.7077 5.74215 14.2565 2.29102 9.99935 2.29102C5.74215 2.29102 2.29102 5.74215 2.29102 9.99935C2.29102 14.2565 5.74215 17.7077 9.99935 17.7077Z" stroke="#8A909D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M9.57015 11.8066C9.11361 11.8066 8.81847 11.5068 8.81847 11.0687V10.8981C8.81847 10.1833 9.26579 9.71294 9.92524 9.28407C10.6262 8.84136 10.9398 8.54622 10.9398 7.96978C10.9398 7.36106 10.4095 6.95985 9.73155 6.95985C9.15972 6.95985 8.73085 7.20888 8.51872 7.84066C8.39421 8.14502 8.12212 8.32026 7.78548 8.32026C7.33816 8.32026 7.06608 8.03895 7.07069 7.6193C7.07069 7.40717 7.12142 7.18121 7.2321 6.96446C7.6333 6.15283 8.60173 5.65478 9.80534 5.65478C11.4286 5.65478 12.5953 6.51714 12.5953 7.94672C12.5953 8.82292 12.1434 9.42242 11.3041 9.95275C10.5386 10.4277 10.3357 10.6445 10.2803 11.1287C10.2803 11.161 10.2711 11.2071 10.2711 11.2394C10.2065 11.5945 9.96674 11.8066 9.57015 11.8066ZM9.5886 14.4536C9.0721 14.4536 8.65706 14.057 8.65706 13.5544C8.65706 13.0563 9.0721 12.6597 9.5886 12.6597C10.1005 12.6597 10.5201 13.0563 10.5201 13.5544C10.5201 14.057 10.1005 14.4536 9.5886 14.4536Z" fill="#8A909D"/>
                                    </svg>
                                    <span className="glossaryContent__tranlastionSection__item__tooltip" >
                                        {originalGlossary?.translations?.[key]?.value}
                                    </span>
                                </div>
                            }
                        </label>
                        <AxelInput
                            maxLength={190}
                            type="text"
                            name={key}
                            handler={(e)=>inputHandler(key, data.translations[key].id, e.target.value)}
                            value={selectedValues?.[ data.translations[key].id] ?? data.translations[key]?.value}
                            placeholder="Enter first name"
                        />
                    </div>
                )
            })}
        </div>
    )
}

export default ColumnHeaders;