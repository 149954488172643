import axios from "axios";
import {useLocation} from "react-router-dom";
import {Cell, Pie, PieChart} from "recharts";
import React, {useEffect, useMemo, useState} from "react";
import useLastPathname from "../../global/useLastPathname";
import './index.scss'

export const ChartRisk = ({ reportId }) => {
    const location = useLocation()
    const lastPathname = useLastPathname();
    const [response, setResponse] = useState([])
    const [pieData, setPieData] = useState([])
    const [translations, setTranslations] = useState({})
    const [COLORS, setColors ] = useState(["#0CAF60", "#ECC949", "#E7984F", '#E68484']);



    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/chart/risk-management/${reportId ? reportId : location?.pathname?.split('/')[2]}`,
            {
            headers: {
                "AppLocale": lastPathname
            }}
        )
            .then((res) => {
                const data = res?.data?.data?.data;
                setResponse(data);
                setTranslations(res?.data?.data?.translations)
                if (!data?.Low && !data?.moderate && !data?.substantial && !data?.high) {
                    setPieData([
                        {name: "Low", value: 1 },
                        {name: "Moderate", value: 1},
                        {name: "Substantial", value: 1},
                        {name: "High", value: 1},
                    ])
                    setColors(["#9a9e9a", "#9a9e9a", "#9a9e9a", "#9a9e9a"])
                } else {
                    setPieData([
                        {name: "Low", value: +data?.low},
                        {name: "Moderate", value: +data?.moderate},
                        {name: "Substantial", value: +data?.substantial},
                        {name: "High", value: +data?.high},
                    ])
                    setColors(["#0CAF60", "#ECC949", "#E7984F", '#E68484'])
                }
            })

    }, [reportId])

    return (
        <div style={{width: '100%'}} className="project__program-right">
            <div className="project__program-circle">
                <PieChart height={152} width={152}>
                    <Pie
                        animationDuration={0}
                        data={pieData}
                        dataKey="value"
                        nameKey="name"
                        color="#000000"
                        fill="#8884d8"
                        innerRadius={35}
                        cx="50%"
                        cy="50%"
                    >
                        {
                            pieData?.map((el,idx) => (
                                <Cell  key={`cell-${idx}`}
                                       fill={COLORS[idx % COLORS.length]}
                                />
                            ))
                        }
                    </Pie>
                </PieChart>
            </div>
            <div className="project__program-stats">
                <div className="project__program-stat">
                    <div className="project__program-color green"></div>
                    <p>{translations?.low}<span>{response?.low || '0'}%</span></p>
                </div>
                <div className="project__program-stat">
                    <div className="project__program-color yellow"></div>
                    <p>{translations?.moderate}<span>{response?.moderate || '0'}%</span></p>
                </div>
                <div className="project__program-stat">
                    <div className="project__program-color orange"></div>
                    <p>{translations?.substantial}<span>{response?.substantial || '0'}%</span></p>
                </div>
                <div className="project__program-stat">
                    <div className="project__program-color red"></div>
                    <p>{translations?.high}<span>{response?.high || '0'}%</span></p>
                </div>
            </div>
        </div>
    )
}