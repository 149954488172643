import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import AxelCheckbox from "../../UI/AxelCheckbox";
import {errorHandle} from "../../global/errorHandle";
import {setLoader} from "../../store/slice/dashboard";
import {
    useCreateQuarterlyReportMutation,
    useLazyGetQuarterlyReportSectionsQuery,
    useUpdateQuarterlyReportMutation
} from "../../store/service/adbService";

const CreateQReportStep3 = ({pageTranslations}) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isSelectAll, setIsSelectAll] = useState(false);
    const enterData = JSON.parse(localStorage.getItem("create-report-data"));
    const  reportData = JSON.parse(localStorage.getItem("edit-report-data"));

    const [selectedSections, setSelectedSections] = useState([]);
    const [sections, setSections] = useState(enterData?.sections  ?? reportData?.sections  ??  null);

    const [getQuarterlyReportSections, {
        data: sectionsData,
        isLoading: sectionsIsLoading,
        error: sectionsError,
        isError: sectionsIsError,
    }] = useLazyGetQuarterlyReportSectionsQuery();

    const [createQuarterlyReport, {
        data: createReportData,
        isLoading: createReportIsLoading,
        error: createReportError,
        isSuccess: createReportSuccess,
        isError: createReportIsError,
    }] = useCreateQuarterlyReportMutation();

    const [updateQuarterlyReport, {
        data: updatReportData,
        isLoading: updatReportIsLoading,
        error: updatReportError,
        isSuccess: updatReportSuccess,
        isError: updatReportIsError,
    }] = useUpdateQuarterlyReportMutation();


    const handleSelectSections = (key, checked) => {
        setSelectedSections(prevSections => {
            return {
                ...prevSections,
                [key]: checked ? 1 : 0,
            };
        });
    };

    const handleSelectAllSections = (isChecked) => {
        setIsSelectAll(isChecked)
        setSelectedSections(prevSections => {
            return Object.keys(prevSections).reduce((acc, key) => {
                acc[key] = isChecked ? 1 : 0;
                return acc;
            }, {});
        });
    };

    const backToPrevPage = () => {
        localStorage.removeItem("create-data");
        if (location?.pathname === "/edit-quarterly-data/step-3") {
            navigate("/edit-quarterly-data/step-2");
        }
        else if (location?.pathname === "/create-quarterly-report/step-3") {
            navigate("/create-quarterly-report/step-2");
        }
    }

    useEffect(() => {
        getQuarterlyReportSections()
    }, [])

    useEffect(() => {
        if (selectedSections) {
            const allSelected = Object.values(selectedSections).every(value => value === 1);

            if (allSelected) {
                setIsSelectAll(true)
            } else {
                setIsSelectAll(false)
            }
        }
    }, [selectedSections]);

    useEffect(() => {
        if (sectionsData?.data) {
            setSections(sectionsData?.data);
            const initialSelectedSections = Object.keys(sectionsData.data).reduce((acc, key) => {
                acc[key] = reportData?.sections?.[key] ? 1 : 0;
                return acc;
            }, {});

            const allSelected = Object.values(initialSelectedSections).every(value => value === 1);

            if (allSelected) {
                setIsSelectAll(true)
            } else {
                setIsSelectAll(false)
            }

            setSelectedSections(initialSelectedSections);
        }
    }, [sectionsData]);

    useEffect(() => {
       if (createReportSuccess){
           toast.success(createReportData.message);
           localStorage.removeItem("create-report-data");
           navigate("/quarterly-reports");
       }
    }, [createReportSuccess])

    useEffect(() => {
        if (updatReportSuccess){
            toast.success(updatReportData.message);
            navigate(`/edit-quarterly-report/${reportData?.current_report_id}`);
            localStorage.removeItem("create-report-data");
            localStorage.removeItem("edit-report-data");
        }
    }, [updatReportSuccess])

    useEffect(() => {
        if (sectionsIsError){
            errorHandle(sectionsError)
        }
        if (createReportIsError){
            errorHandle(createReportError)
        }
        if (updatReportIsError){
            errorHandle(updatReportError)
        }
    }, [sectionsError, sectionsIsError, createReportError, createReportIsError, updatReportError, updatReportIsError])

    useEffect(() => {
        sectionsIsLoading || createReportIsLoading || updatReportIsLoading
            ? dispatch(setLoader(true))
            : dispatch(setLoader(false));
    }, [sectionsIsLoading, createReportIsLoading]);

    const submitHandler = (e) => {
        e.preventDefault();
        const formData = new FormData();
        if (enterData){
            Object.keys(enterData)?.map(key => {
                if (key !== 'projects'){
                    formData.append(key, enterData[key])
                }
            })
        }
        enterData?.projects.forEach(projectsId => formData.append(`projects[]`, projectsId))
        Object.keys(selectedSections)?.map(key => formData.append(key, selectedSections[key]))

        if (reportData?.id) {
            updateQuarterlyReport({quarterlyReportId: reportData?.id, body: formData})
        }
        else {
            createQuarterlyReport(
                formData,
            )
        }
    };


    return (
        <form className="create__form">
            <div className="create__form-content">
                <div className="projectsContainer">
                    <div className="projectInfoWrapper" >
                        <AxelCheckbox
                            id='select-all'
                            checked={isSelectAll}
                            handler={()=>handleSelectAllSections(!isSelectAll)}
                        />
                        <div className="projectInfo">
                            {pageTranslations?.['report_sections.actions.select_all']}
                        </div>
                    </div>
                    {
                        selectedSections ? Object.keys(selectedSections)?.map((oneKey)=>{
                            return (
                                <div className="projectInfoWrapper" key={oneKey}>
                                    <AxelCheckbox
                                        id={oneKey}
                                        checked={selectedSections?.[oneKey]}
                                        handler={handleSelectSections}
                                    />
                                    <div className="projectInfo">
                                        {sections?.[oneKey]}
                                    </div>
                                </div>
                            )
                        }):
                            <div style={{ height: '70vh', width: '100%', display: "flex", justifyContent: "center", alignItems: 'center', flexDirection: "column" }}>
                                <img src="/assets/images/projects.png" alt="" />
                                <span style={{ color: "#767676", fontSize: 16, fontWeight: 500 }}>Not found sections</span>
                            </div>
                    }
                </div>
            </div>
            <div className="create__form-br"></div>
            <div className="create__form-btns">
                <div
                    className="create__form-back"
                    onClick={backToPrevPage}
                >
                    {pageTranslations?.['report_sections.actions.back']}
                </div>
                <button onClick={submitHandler} className="create__form-next">
                    <span>{reportData?.id ? pageTranslations?.['report_sections.actions.save'] :  pageTranslations?.['report_sections.actions.create']}</span>
                    <span>
                        <img src="/assets/icons/next-icon.svg" alt="icon" />
                    </span>
                </button>
            </div>
        </form>
    );
};


export default CreateQReportStep3;