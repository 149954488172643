import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: false,
  user: null,
};

export const adbSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setUserData: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const { setLoader, setUserData } = adbSlice.actions;

export default adbSlice.reducer;
