import React, {useEffect, useRef, useState} from 'react';
import './index.scss';
import {useDispatch} from "react-redux";
import {useLazyGetProjectContractAwardQuery, useUpdateProjectContractAwardMutation} from "../../../store/service/adbService";
import {toast} from "react-toastify";
import {setLoader} from "../../../store/slice/dashboard";
import {errorHandle} from "../../../global/errorHandle";
import ItemEdit from "../LoanUtilization/ItemEdit";

const PCAandDisbursPerfomance = ({reportId, translations, data, isReportSubmitted}) => {
    const dispatch = useDispatch();
    const tableRef = useRef();
    const itemRef = useRef();
    const thRefs = useRef([]);
    const [tdWidths, setTdWidths] = useState([]);
    const [pContractAward, setPrContractAward ]= useState(data?.project_contract_award)
    const [isOpenEdit, setIsOpenEdit ]= useState({isShow: false});

    const [getPrContractAward, {
        data: newPrContractAwardData,
        isLoading: prContractAwardIsLoading,
        error: prContractAwardError,
        isSuccess: prContractAwardIsSuccess,
    }]= useLazyGetProjectContractAwardQuery();

    const [updatePrContractAward, {
        data: updatePrContractAwardData,
        isSuccess: updatePrContractAwardIsSuccess,
        isLoading: updatePrContractAwardIsLoading,
        error: updatePrContractAwardError,
    }]= useUpdateProjectContractAwardMutation();

    const handleMouseLeave = (event) => {
        if (!itemRef.current || !itemRef.current.contains(event.target)) {
            setIsOpenEdit(false)
        }
    };

    useEffect(() => {
        setPrContractAward(data?.project_contract_award)
    }, [data]);

    useEffect(() => {
        if (pContractAward?.current?.data && thRefs.current) {
            const widths = thRefs.current?.offsetWidth;
            setTdWidths(widths);
        }
    }, [pContractAward, thRefs.current]);

    useEffect(() => {
        if (prContractAwardIsSuccess){
            setPrContractAward(newPrContractAwardData?.data)
        }
    }, [prContractAwardIsSuccess, newPrContractAwardData]);

    useEffect(() => {
        if (updatePrContractAwardIsSuccess){
            toast.success(updatePrContractAwardData.message);
            getPrContractAward(reportId);
            setIsOpenEdit(null)
        }
    }, [updatePrContractAwardIsSuccess]);

    useEffect(() => {
        prContractAwardIsLoading ||
        updatePrContractAwardIsLoading
            ? dispatch(setLoader(true))
            : dispatch(setLoader(false));
    }, [updatePrContractAwardIsLoading, prContractAwardIsLoading]);

    useEffect(() => {
        if (updatePrContractAwardError) {
            errorHandle(updatePrContractAwardError);
        }
        if (prContractAwardError) {
            errorHandle(prContractAwardError);
        }
    }, [updatePrContractAwardError, prContractAwardError])

    useEffect(function () {
        document.addEventListener('mousedown', handleMouseLeave);
        document.addEventListener('touchstart', handleMouseLeave);

        return () => {
            document.removeEventListener('mousedown', handleMouseLeave);
            document.removeEventListener('touchstart', handleMouseLeave);

        };
    }, [tableRef]);


    return (
        <div className='PCWADP'>
            <div className="editReport__sectionHeader">
                <h3>
                    {translations?.['loan_utilization.project_contract_award.headers.project_contract_award']}
                </h3>
            </div>
            {pContractAward?.current?.data &&
                <div className="table-container">
                    <table className="custom-table">
                        <thead className="header">
                        <tr className="header__yearsRow">
                            <th ></th>
                            <th ></th>
                            <th ref={thRefs }>
                                {pContractAward?.current?.year}
                            </th>
                        </tr>
                        <tr className="header__monthRow">
                            <th></th>
                            <th>{translations?.['loan_utilization.project_contract_award.titles.cumulative_as_of']}</th>
                            {pContractAward?.current?.data?.map((item, index) =>
                                <th
                                    key={index}
                                    style={{
                                        width: tdWidths ? `${tdWidths / pContractAward?.current?.data?.length}px` : 'auto'
                                    }}
                                >
                                    {item?.quarter}
                                    {' '}
                                    {item?.period}
                                </th>
                            )}
                            <th
                                style={{
                                    width: tdWidths ? `${tdWidths / pContractAward?.current?.data?.length}px` : 'auto'
                                }}>
                                {translations?.['loan_utilization.project_contract_award.titles.total']}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr className="header__yearsRow">
                            <td >{translations?.['loan_utilization.project_contract_award.headers.contract_award']}</td>
                            <td ></td>
                            {
                                pContractAward?.current?.data.map((key, index) =><td key={index}></td>)
                            }
                            <td ></td>
                        </tr>
                        <tr>
                            <td>{translations?.['loan_utilization.project_contract_award.titles.eops_target']}</td>
                            <td>{pContractAward?.last_total?.contract_award_eops_target}</td>
                            {pContractAward?.current?.data?.map((item, index) =>
                                {
                                    return(
                                        <td
                                            onClick={()=>setIsOpenEdit({
                                                isShow: !isOpenEdit?.isShow,
                                                id:item.id,
                                                key:'contract_award_eops_target'
                                            })}
                                            style={{
                                                width: tdWidths ? `${tdWidths / pContractAward?.current?.data?.length}px` : 'auto'
                                            }}
                                            key={`contract_award_projection_${index}`}
                                        >
                                            {item.contract_award_eops_target}
                                            {(isOpenEdit?.id === item.id && isOpenEdit?.key === 'contract_award_eops_target' && isOpenEdit?.isShow && !isReportSubmitted)  &&
                                                <ItemEdit
                                                    itemRef={itemRef}
                                                    itemData={item}
                                                    itemKey={'contract_award_eops_target'}
                                                    updateMethod={updatePrContractAward}
                                                    applyBtnTitle={translations?.['loan_utilization.index.actions.apply']}
                                                    placeHolder={translations?.['index.index.placeholder.number']}
                                                />}
                                        </td>
                                    )
                                }
                            )}

                            <td style={{width: tdWidths ? `${tdWidths / pContractAward?.current?.data?.length}px` : 'auto'}}>
                                {pContractAward?.current_total?.contract_award_eops_target}
                            </td>
                        </tr>
                        <tr>
                            <td>{translations?.['loan_utilization.project_contract_award.titles.achievable_estimate']}</td>
                            <td>{pContractAward?.last_total?.contract_award_achievable_estimate}</td>
                            {pContractAward?.current?.data?.map((item, index) =>
                                {
                                    return(
                                        <td
                                            onClick={()=>setIsOpenEdit({
                                                isShow: !isOpenEdit?.isShow,
                                                id:item.id,
                                                key:'contract_award_achievable_estimate'
                                            })}
                                            style={{
                                                width: tdWidths ? `${tdWidths / pContractAward?.current?.data?.length}px` : 'auto'
                                            }}
                                            key={`contract_award_achievable_estimate_${index}`}
                                        >
                                            {item.contract_award_achievable_estimate}
                                            {(isOpenEdit?.id === item.id && isOpenEdit?.key === 'contract_award_achievable_estimate' && isOpenEdit?.isShow && !isReportSubmitted)  &&
                                                <ItemEdit
                                                    itemRef={itemRef}
                                                    itemData={item}
                                                    itemKey={'contract_award_achievable_estimate'}
                                                    updateMethod={updatePrContractAward}
                                                    applyBtnTitle={translations?.['loan_utilization.index.actions.apply']}
                                                    placeHolder={translations?.['index.index.placeholder.number']}
                                                />}
                                        </td>
                                    )
                                }
                            )}
                            <td style={{width: tdWidths ? `${tdWidths / pContractAward?.current?.data?.length}px` : 'auto'}}>
                                {pContractAward?.current_total?.contract_award_achievable_estimate}
                            </td>
                        </tr>
                        <tr>
                            <td>{translations?.['loan_utilization.project_contract_award.titles.actual']}</td>
                            <td>{pContractAward?.last_total?.contract_award_actual}</td>
                            {pContractAward?.current?.data?.map((item, index) =>
                                {
                                    return(
                                        <td
                                            onClick={()=>setIsOpenEdit({
                                                isShow: !isOpenEdit?.isShow,
                                                id:item.id,
                                                key:'contract_award_actual'
                                            })}
                                            style={{
                                                width: tdWidths ? `${tdWidths / pContractAward?.current?.data?.length}px` : 'auto'
                                            }}
                                            key={`contract_award_actual_${index}`}
                                        >
                                            {item.contract_award_actual}
                                            {(isOpenEdit?.id === item.id && isOpenEdit?.key === 'contract_award_actual' && isOpenEdit?.isShow && !isReportSubmitted)  &&
                                                <ItemEdit
                                                    itemRef={itemRef}
                                                    itemData={item}
                                                    itemKey={'contract_award_actual'}
                                                    updateMethod={updatePrContractAward}
                                                    applyBtnTitle={translations?.['loan_utilization.index.actions.apply']}
                                                    placeHolder={translations?.['index.index.placeholder.number']}
                                                />}
                                        </td>
                                    )
                                }
                            )}
                            <td style={{width: tdWidths ? `${tdWidths / pContractAward?.current?.data?.length}px` : 'auto'}}>
                                {pContractAward?.current_total?.contract_award_actual}
                            </td>
                        </tr>
                        <tr>
                            <td>{translations?.['loan_utilization.project_contract_award.titles.percent_of_eops_target']}</td>
                            <td>{pContractAward?.last_total?.contract_award_eops_target_percent}</td>
                            {pContractAward?.current?.data?.map((item, index) =>
                                {
                                    return(
                                        <td
                                            onClick={()=>setIsOpenEdit({
                                                isShow: !isOpenEdit?.isShow,
                                                id:item.id,
                                                key:'contract_award_eops_target_percent'
                                            })}
                                            style={{
                                                width: tdWidths ? `${tdWidths / pContractAward?.current?.data?.length}px` : 'auto'
                                            }}
                                            key={`contract_award_eops_target_percent_${index}`}
                                        >
                                            {item.contract_award_eops_target_percent}
                                            {(isOpenEdit?.id === item.id && isOpenEdit?.key === 'contract_award_eops_target_percent' && isOpenEdit?.isShow && !isReportSubmitted)  &&
                                                <ItemEdit
                                                    itemRef={itemRef}
                                                    itemData={item}
                                                    itemKey={'contract_award_eops_target_percent'}
                                                    updateMethod={updatePrContractAward}
                                                    applyBtnTitle={translations?.['loan_utilization.index.actions.apply']}
                                                    placeHolder={translations?.['index.index.placeholder.number']}
                                                />}
                                        </td>
                                    )
                                }
                            )}
                            <td style={{width: tdWidths ? `${tdWidths / pContractAward?.current?.data?.length}px` : 'auto'}}>
                                {pContractAward?.current_total?.contract_award_eops_target_percent}
                            </td>
                        </tr>
                        <tr style={{marginBottom:'8px'}}>
                            <td>{translations?.['loan_utilization.project_contract_award.titles.percent_of_achievable_estimate']}</td>
                            <td>{pContractAward?.last_total?.contract_award_achievable_estimate_percent}</td>
                            {pContractAward?.current?.data?.map((item, index) =>
                                {
                                    return(
                                        <td
                                            onClick={()=>setIsOpenEdit({
                                                isShow: !isOpenEdit?.isShow,
                                                id:item.id,
                                                key:'contract_award_achievable_estimate_percent'
                                            })}
                                            style={{
                                                width: tdWidths ? `${tdWidths / pContractAward?.current?.data?.length}px` : 'auto'
                                            }}
                                            key={`contract_award_achievable_estimate_percent_${index}`}
                                        >
                                            {item.contract_award_achievable_estimate_percent}
                                            {(isOpenEdit?.id === item.id && isOpenEdit?.key === 'contract_award_achievable_estimate_percent' && isOpenEdit?.isShow && !isReportSubmitted)  &&
                                                <ItemEdit
                                                    itemRef={itemRef}
                                                    itemData={item}
                                                    itemKey={'contract_award_achievable_estimate_percent'}
                                                    updateMethod={updatePrContractAward}
                                                    applyBtnTitle={translations?.['loan_utilization.index.actions.apply']}
                                                    placeHolder={translations?.['index.index.placeholder.number']}
                                                />}
                                        </td>
                                    )
                                }
                            )}
                            <td style={{width: tdWidths ? `${tdWidths / pContractAward?.current?.data?.length}px` : 'auto'}}>
                                {pContractAward?.current_total?.contract_award_achievable_estimate_percent}
                            </td>
                        </tr>

                        {/*Disbursement*/}
                        <tr className="header__yearsRow">
                            <td >{translations?.['loan_utilization.index.headers.disbursement']}</td>
                            <td ></td>
                            <td ></td>
                            {
                                pContractAward?.current?.data.map((key, index) =><th key={index}></th>)
                            }
                        </tr>
                        <tr>
                            <td>{translations?.['loan_utilization.project_contract_award.titles.eops_target']}</td>
                            <td>{pContractAward?.last_total?.disbursement_projection_eops_target}</td>
                            {pContractAward?.current?.data?.map((item, index) =>
                                <td
                                    style={{
                                        width: tdWidths ? `${tdWidths / pContractAward?.current?.data?.length}px` : 'auto'
                                    }}
                                    onClick={()=>setIsOpenEdit({
                                        isShow: !isOpenEdit?.isShow,
                                        id:item.id,
                                        key:'disbursement_projection_eops_target'
                                    })}
                                    key={`disbursement_projection_eops_target_${index}`}
                                >
                                    {item.disbursement_projection_eops_target}
                                    {(isOpenEdit?.id === item.id && isOpenEdit?.key === 'disbursement_projection_eops_target' && isOpenEdit?.isShow && !isReportSubmitted)  &&
                                        <ItemEdit
                                            itemRef={itemRef}
                                            itemData={item}
                                            itemKey={'disbursement_projection_eops_target'}
                                            updateMethod={updatePrContractAward}
                                            applyBtnTitle={translations?.['loan_utilization.index.actions.apply']}
                                            placeHolder={translations?.['index.index.placeholder.number']}
                                        />}
                                </td>
                            )}
                            <td style={{width: tdWidths ? `${tdWidths / pContractAward?.current?.data?.length}px` : 'auto'}}>
                                {pContractAward?.current_total?.disbursement_projection_eops_target}
                            </td>
                        </tr>
                        <tr>
                            <td>{translations?.['loan_utilization.project_contract_award.titles.achievable_estimate']}</td>
                            <td>{pContractAward?.last_total?.disbursement_projection_achievable_estimate}</td>
                            {pContractAward?.current?.data?.map((item, index) =>
                                <td
                                    style={{
                                        width: tdWidths ? `${tdWidths / pContractAward?.current?.data?.length}px` : 'auto'
                                    }}
                                    onClick={()=>setIsOpenEdit({
                                        isShow: !isOpenEdit?.isShow,
                                        id:item.id,
                                        key:'disbursement_projection_achievable_estimate'
                                    })}
                                    key={`disbursement_projection_achievable_estimate_${index}`}
                                >
                                    {item.disbursement_projection_achievable_estimate}
                                    {(isOpenEdit?.id === item.id && isOpenEdit?.key === 'disbursement_projection_achievable_estimate' && isOpenEdit?.isShow && !isReportSubmitted)  &&
                                        <ItemEdit
                                            itemRef={itemRef}
                                            itemData={item}
                                            itemKey={'disbursement_projection_achievable_estimate'}
                                            updateMethod={updatePrContractAward}
                                            applyBtnTitle={translations?.['loan_utilization.index.actions.apply']}
                                            placeHolder={translations?.['index.index.placeholder.number']}
                                        />}
                                </td>
                            )}
                            <td style={{width: tdWidths ? `${tdWidths / pContractAward?.current?.data?.length}px` : 'auto'}}>
                                {pContractAward?.current_total?.disbursement_projection_achievable_estimate}
                            </td>
                        </tr>
                        <tr>
                            <td>{translations?.['loan_utilization.project_contract_award.titles.actual']}</td>
                            <td>{pContractAward?.last_total?.disbursement_projection_actual}</td>

                            {pContractAward?.current?.data?.map((item, index) =>
                                <td
                                    style={{
                                        width: tdWidths ? `${tdWidths / pContractAward?.current?.data?.length}px` : 'auto'
                                    }}
                                    onClick={()=>setIsOpenEdit({
                                        isShow: !isOpenEdit?.isShow,
                                        id:item.id,
                                        key:'disbursement_projection_actual'
                                    })}
                                    key={`disbursement_projection_actual_${index}`}
                                >
                                    {item.disbursement_projection_actual}
                                    {(isOpenEdit?.id === item.id && isOpenEdit?.key === 'disbursement_projection_actual' && isOpenEdit?.isShow && !isReportSubmitted)  &&
                                        <ItemEdit
                                            itemRef={itemRef}
                                            itemData={item}
                                            itemKey={'disbursement_projection_actual'}
                                            updateMethod={updatePrContractAward}
                                            applyBtnTitle={translations?.['loan_utilization.index.actions.apply']}
                                            placeHolder={translations?.['index.index.placeholder.number']}
                                        />}
                                </td>
                            )}
                            <td style={{width: tdWidths ? `${tdWidths / pContractAward?.current?.data?.length}px` : 'auto'}}>
                                {pContractAward?.current_total?.disbursement_projection_actual}
                            </td>
                        </tr>
                        <tr>
                            <td>{translations?.['loan_utilization.project_contract_award.titles.percent_of_eops_target']}</td>
                            <td>{pContractAward?.last_total?.disbursement_projection_eops_target_percent}</td>

                            {pContractAward?.current?.data?.map((item, index) =>
                                <td
                                    style={{
                                        width: tdWidths ? `${tdWidths / pContractAward?.current?.data?.length}px` : 'auto'
                                    }}
                                    onClick={()=>setIsOpenEdit({
                                        isShow: !isOpenEdit?.isShow,
                                        id:item.id,
                                        key:'disbursement_projection_eops_target_percent'
                                    })}
                                    key={`disbursement_projection_eops_target_percent_${index}`}
                                >
                                    {item.disbursement_projection_eops_target_percent}
                                    {(isOpenEdit?.id === item.id && isOpenEdit?.key === 'disbursement_projection_eops_target_percent' && isOpenEdit?.isShow && !isReportSubmitted)  &&
                                        <ItemEdit
                                            itemRef={itemRef}
                                            itemData={item}
                                            itemKey={'disbursement_projection_eops_target_percent'}
                                            updateMethod={updatePrContractAward}
                                            applyBtnTitle={translations?.['loan_utilization.index.actions.apply']}
                                            placeHolder={translations?.['index.index.placeholder.number']}
                                        />}
                                </td>
                            )}
                            <td style={{width: tdWidths ? `${tdWidths / pContractAward?.current?.data?.length}px` : 'auto'}}>
                                {pContractAward?.current_total?.disbursement_projection_eops_target_percent}
                            </td>
                        </tr>
                        <tr>
                            <td>{translations?.['loan_utilization.project_contract_award.titles.percent_of_achievable_estimate']}</td>
                            <td>{pContractAward?.last_total?.disbursement_projection_achievable_estimate_percent}</td>

                            {pContractAward?.current?.data?.map((item, index) =>
                                <td
                                    style={{
                                        width: tdWidths ? `${tdWidths / pContractAward?.current?.data?.length}px` : 'auto'
                                    }}
                                    onClick={()=>setIsOpenEdit({
                                        isShow: !isOpenEdit?.isShow,
                                        id:item.id,
                                        key:'disbursement_projection_achievable_estimate_percent'
                                    })}
                                    key={`disbursement_projection_achievable_estimate_percent_${index}`}
                                >
                                    {item.disbursement_projection_achievable_estimate_percent}
                                    {(isOpenEdit?.id === item.id && isOpenEdit?.key === 'disbursement_projection_achievable_estimate_percent' && isOpenEdit?.isShow && !isReportSubmitted)  &&
                                        <ItemEdit
                                            itemRef={itemRef}
                                            itemData={item}
                                            itemKey={'disbursement_projection_achievable_estimate_percent'}
                                            updateMethod={updatePrContractAward}
                                            placeHolder={translations?.['index.index.placeholder.number']}
                                            applyBtnTitle={translations?.['loan_utilization.index.actions.apply']}
                                        />}
                                </td>
                            )}
                            <td style={{width: tdWidths ? `${tdWidths / pContractAward?.current?.data?.length}px` : 'auto'}}>
                                {pContractAward?.current_total?.disbursement_projection_achievable_estimate_percent}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            }
        </div>

    );
};

export default PCAandDisbursPerfomance;
