import React from 'react';
import {useSelector} from "react-redux";

function QuarterlyImplementationProgress({pageTranslations}) {
	const sections_title = useSelector(state => state?.quarterlyReducer?.sections_title) ?? null;
	const implementation_progress = useSelector(state => state?.quarterlyReducer?.online_view?.sections?.implementation_progress) ?? null;
	
	const PresentStatus = () => (
			<>
				<p>5.1 {pageTranslations?.['implementation_progress.present_status.headers.present_status']}</p>
				<div className="quarterly-online-table">
					<table style={{marginTop: 20}}>
						<thead>
						<tr className={"row_gray"}>
							<th style={{textAlign: 'left'}}>{pageTranslations?.['implementation_progress.present_status.titles.overall_physical_progress']}</th>
						</tr>
						</thead>
						<tbody>
						<tr>
							<td style={{textAlign: 'left'}} dangerouslySetInnerHTML={{__html: implementation_progress?.present_status?.overall_physical_progress}} />
						</tr>
						</tbody>
					</table>
				</div>
			</>
	)
	
	const ProgressPerOutput = () => (
			<>
				<p>5.2 {pageTranslations?.['implementation_progress.progress_per_outputs.headers.progress_per_outputs']}</p>
				<div className="quarterly-online-table">
					<table style={{marginTop: 20}}>
						<thead>
						<tr className={"row_gray"}>
							<th style={{width: '50%'}}>{pageTranslations?.['implementation_progress.progress_per_outputs.table_headers.description']}</th>
							<th style={{width: '50%'}}>{pageTranslations?.['implementation_progress.progress_per_outputs.table_headers.action_taken_proposed']}</th>
						</tr>
						</thead>
						<tbody>
						{implementation_progress?.progress_per_outputs?.map((item, index) => (
								<React.Fragment key={index}>
									<tr>
										<td colSpan={2}>{pageTranslations?.['implementation_progress.progress_per_outputs.table_headers.output_no']} {index + 1}:</td>
									</tr>
									<tr>
										<td dangerouslySetInnerHTML={{__html: item?.description}}></td>
										<td dangerouslySetInnerHTML={{__html: item?.action_taken}}></td>
									</tr>
								</React.Fragment>
						))}
						</tbody>
					</table>
				</div>
			</>
	)
	
	const ProblemWithApi = () => (
			<>
				<p>5.3 {pageTranslations?.['implementation_progress.major_problems_with_ip.headers.major_problems_with_ip']}</p>
				<div className="quarterly-online-table">
					<table style={{marginTop: 20}}>
						<thead>
						<tr className={"row_gray"}>
							<th style={{width: '50%'}}>{pageTranslations?.['implementation_progress.major_problems_with_ip.table_headers.description']}</th>
							<th style={{width: '50%'}}>{pageTranslations?.['implementation_progress.major_problems_with_ip.table_headers.action_taken_proposed']}</th>
						</tr>
						</thead>
						<tbody>
						{implementation_progress?.major_problems_with_ip?.map((item, index) => (
								<tr key={index}>
									<td style={{width: 'max-content'}} dangerouslySetInnerHTML={{__html: item?.description}}></td>
									<td style={{width: 'max-content'}} dangerouslySetInnerHTML={{__html: item?.action_taken}}></td>
								</tr>
						))}
						</tbody>
					</table>
				</div>
			</>
	)
	const NextQuarters = () => (
			<>
				<p>5.4 {pageTranslations?.['implementation_progress.future_plans_for_the_next_quarters.headers.future_plans_for_the_next_quarters']}</p>
				<div className="quarterly-online-table">
					<table style={{marginTop: 20}}>
						<thead>
						<tr className={"row_gray"}>
							<th style={{width: '50%'}}>{pageTranslations?.['implementation_progress.future_plans_for_the_next_quarters.table_headers.description']}</th>
							<th style={{width: '50%'}}>{pageTranslations?.['implementation_progress.future_plans_for_the_next_quarters.table_headers.action_taken_proposed']}</th>
						</tr>
						</thead>
						<tbody>
						{implementation_progress?.future_plans_for_the_next_quarters?.map(item => (
									<tr>
										<td dangerouslySetInnerHTML={{__html: item?.description}}></td>
										<td dangerouslySetInnerHTML={{__html: item?.action_taken}}></td>
									</tr>
						))}
						</tbody>
					</table>
				</div>
			</>
	)
	
	if (!implementation_progress) {
		return <div></div>
	}
	
	return (
			<div className="online__content-projectRow">
				<p className="online__content-boldTitle">5. {pageTranslations?.['implementation_progress.index.headers.implementation_progress']}</p>
				<PresentStatus/>
				<ProgressPerOutput/>
				<ProblemWithApi/>
				<NextQuarters/>
			</div>
	);
}

export default QuarterlyImplementationProgress;
