import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useGetAllSectionsMutation,
  useUpdateProjectMutation,
} from "../../../store/service/adbService";
import { toast } from "react-toastify";
import { setLoader } from "../../../store/slice/dashboard";
import {errorHandle} from "../../../global/errorHandle";

export const EditProjectStep4 = ({ setSteps, translations }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const allEnterDatas = JSON.parse(localStorage.getItem("all-edit-data"));
  const [getAllSections, {data: allSections}] = useGetAllSectionsMutation()
  const [sections, setSections] = useState([]);

  useEffect(() => {
    if (allSections?.data) {
      let s = [];
      delete allEnterDatas?.project_settings?.id;
      delete allEnterDatas?.project_settings?.project_id;

      const newArray = Object.keys(allSections?.data).map(key => {
        return {
          id: key,
          label: allSections?.data[key],
        }
      });
      const resultArray = newArray.map(item => {
        return {
          id: item.id,
          label: item.label,
          value: allEnterDatas?.project_settings[item.id]
        };
      });
      setSections(resultArray);
    }
  }, [allSections]);

  useEffect(() => {
    getAllSections()
  }, [])

  const params = {
    project: location?.pathname?.match(/\/\d+/g, "")[0].replace(/\//g, ""),
  };

  const [
    editProject,
    {
      data: editData,
      isSuccess: editSuccess,
      error: editError,
      isError: editIsError,
      isLoading: editLoading,
    },
  ] = useUpdateProjectMutation();


  useEffect(() => {
    editLoading ? dispatch(setLoader(true)) : dispatch(setLoader(false));
  }, [editLoading]);

  useEffect(() => {
    if (editSuccess) {
      toast.success(editData.message);
      localStorage.removeItem("all-edit-data");
      localStorage.removeItem("edit-data");
      localStorage.removeItem("create-data");
      navigate(`/project/${params?.project}`);
    }
  }, [editSuccess, editData]);

  useEffect(() => {
    if (editIsError){
      errorHandle(editError)
    }
  }, [editIsError, editError])


  const submitHandler = (e) => {
    e.preventDefault();
    let s = {};
    for (let i = 0; i < sections?.length; i++) {
      s = {
        ...s,
        [sections[i]?.id]: sections[i]
          ?.value
          ? sections[i].value
          : 0,
      };
    }
    if (sections.length !== 0) {
      editProject({
        body: {
          ...JSON.parse(localStorage.getItem("edit-data")),
          location_id: JSON.parse(localStorage.getItem("edit-data"))
            ?.location_id?.value,
          contract_type_id: JSON.parse(localStorage.getItem("edit-data"))
            ?.contract_type_id?.value,
          sector_id: JSON.parse(localStorage.getItem("edit-data"))?.sector_id
            ?.value,
          ...s,
        },
        id: params?.project,
      })
    }
  }

  return (
    <form className="create__form">
      <div className="create__form-content">
        <h6 className="create__form-subtitle">
          {translations?.['main.edit_projects_details.titles.select_required_sections_for_this_project']}
        </h6>
        <div className="s-page__details">
          <div className="contact__modal-projectBox">
            <div className="contact__modal-checkbox">
              <div className="a-checkbox">
                <input
                  id="all"
                  key={"all"}
                  type="checkbox"
                  checked={
                    sections?.filter((el) => el?.value)?.length ===
                    sections?.length
                  }
                  onChange={(e) => {
                    if (e?.target?.checked) {
                      const s = sections?.map((item) => {
                        item.value = 1;
                        return item;
                      });
                      setSections(s);
                    } else {
                      const s = sections?.map((item) => {
                        item.value = 0;
                        return item;
                      });
                      setSections(s);
                    }
                  }}
                />
                <label htmlFor={"all"}>{translations?.['main.edit_projects_details.titles.select_all']}</label>
              </div>
            </div>
          </div>
          {sections?.map((el, idx) => (
            <div key={el?.id} className="contact__modal-projectBox">
              <div className="contact__modal-checkbox">
                <div className="a-checkbox">
                  <input
                    key={el?.id}
                    id={el?.id}
                    type="checkbox"
                    checked={el?.value}
                    onChange={(e) => {
                      const s = sections?.map((item) => {
                        if (item?.id === el?.id) {
                          if (e.target.checked) {
                            item.value = 1;
                          } else {
                            item.value = 0;
                          }
                        }
                        return item;
                      });
                      setSections(s);
                    }}
                  />
                  <label htmlFor={el?.id}>{el?.label}</label>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="create__form-br"></div>
      <div className="create__form-btns">
        <div
          className="create__form-back"
          onClick={() => {
            setSteps(3);
          }}
        >
          {translations?.['main.edit_projects_details.actions.back']}
        </div>
        <button onClick={submitHandler} className="create__form-next">
          <span>{translations?.['main.edit_projects_details.actions.next']}</span>
          <span>
            <img src="/assets/icons/next-icon.svg" alt="icon" />
          </span>
        </button>
      </div>
    </form>
  );
};
