import {useDispatch} from "react-redux";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import AxelInput from "../../UI/AxelInput";
import AxelCheckbox from "../../UI/AxelCheckbox";
import {errorHandle} from "../../global/errorHandle";
import {setLoader} from "../../store/slice/dashboard";
import {useLazyGetQuarterlyReportProjectsQuery} from "../../store/service/adbService";

const CreateQReportStep2 = ({pageTranslations}) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState("");
    const [selectedProjects, setSelectedProjects] = useState([]);
    const enterData = JSON.parse(localStorage.getItem("create-report-data"));
    const reportData = JSON.parse(localStorage.getItem("edit-report-data"));

    const [projects, setProjects] = useState(enterData?.projects ?? reportData?.projects ?? null);

    const [getQuarterlyReportProjects, {
        data: projectsData,
        isLoading: projectsIsLoading,
        error: projectsError,
        isError: projectsIsError,
    }] = useLazyGetQuarterlyReportProjectsQuery();

    const filteredProjects = projects?.filter(project =>
        (project?.contract_title?.toLowerCase().includes(searchText.toLowerCase()) ||
            project?.contract_number?.toLowerCase().includes(searchText.toLowerCase()))
    );

    const handleSelectProjects = (id, checked) => {
        if (checked) {
            setSelectedProjects(oldData => {
                const projectExists = oldData.some(project => project === Number(id));
                if (projectExists) {
                    return oldData;
                } else {
                    return [...oldData, Number(id)];
                }
            });
        } else {
            setSelectedProjects(oldData =>
                oldData.filter(projectId => projectId !== Number(id))
            );
        }
    };

    const backToPrevPage = () => {
        localStorage.removeItem("create-data");
        if (location?.pathname === "/edit-quarterly-data/step-2") {
            navigate("/edit-quarterly-data/step-1");
        }
        else if (location?.pathname === "/create-quarterly-report/step-2") {
            navigate("/create-quarterly-report/step-1");
        }
    }

    useEffect(() => {
        getQuarterlyReportProjects();
    }, []);

    useEffect(() => {
        if (projectsData?.data) {
            setProjects(projectsData.data);
            const activeProjects = projectsData.data
                .filter(projectData => projects?.some(project => project.id === projectData.id))
                .map(projectData => projectData.id);
            setSelectedProjects(activeProjects);
        }
    }, [projectsData]);

    useEffect(() => {
        if (projectsIsError) {
            errorHandle(projectsError);
        }
    }, [projectsError, projectsIsError]);

    useEffect(() => {
        projectsIsLoading
            ? dispatch(setLoader(true))
            : dispatch(setLoader(false));
    }, [projectsIsLoading]);

    const submitHandler = (e) => {
        e.preventDefault();
        const formData = new FormData();
        selectedProjects.filter(projectId => {
            const project = projects.find(p => p.id === projectId);
            return project?.isActiveToCopy;
        }).forEach(filteredProject => formData.append(`projects[]`, filteredProject));
        const newData = {
            ...enterData,
            ...reportData,
            projects: selectedProjects
        };

        localStorage.setItem("create-report-data", JSON.stringify(newData));
        location.pathname === "/edit-quarterly-data/step-2"
            ? navigate("/edit-quarterly-data/step-3")
            : navigate("/create-quarterly-report/step-3");
    };

    return (
        <form className="create__form">
            <div className="create__form-content">
                <AxelInput
                    className="nSettings__copySettings__search searchProject"
                    src={'/assets/icons/search.svg'}
                    placeholder={pageTranslations?.['included_projects.actions.search']}
                    value={searchText}
                    handler={(e) => setSearchText(e.target.value)}
                />
                <div className="projectsContainer">
                    {filteredProjects?.map((project) =>
                        <div className="projectInfoWrapper" key={project.id}>
                            <AxelCheckbox
                                id={project.id}
                                checked={selectedProjects.includes(project.id) ? true : false}
                                handler={handleSelectProjects}
                            />
                            <div className="projectInfo">
                                <h3>{project.contract_title}</h3>
                                <ul>
                                    <li>{project.report_date}</li>
                                    <li>{project.contract_number}</li>
                                </ul>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="create__form-br"></div>
            <div className="create__form-btns">
                <div
                    className="create__form-back"
                    onClick={backToPrevPage}
                >
                    {pageTranslations?.['included_projects.actions.back']}
                </div>
                <button onClick={submitHandler} className="create__form-next">
                    <span>{pageTranslations?.['included_projects.actions.next']}</span>
                    <span>
                        <img src="/assets/icons/next-icon.svg" alt="icon" />
                    </span>
                </button>
            </div>
        </form>
    );
};

export default CreateQReportStep2;
