import { toast } from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import { Paginate } from "../../components/Paginate";
import {DeleteModal} from "../../components/Project/DeleteModal";
import {setLoader} from "../../store/slice/dashboard";
import {errorHandle} from "../../global/errorHandle";
import {useDeleteQuarterlyReportMutation} from "../../store/service/adbService";
import {useLocation, useNavigate} from "react-router-dom";

const Table = (
    {
        tableHeader,
        data,
        emptyDataText,
        getQuarterlyReportsList,
        pageTranslations
    }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const tableRef = useRef(null);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(null)
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage] = useState(1);
    const user = useSelector((state) => state?.adbReducer?.user);

    const [deleteQuarterlyReport, {
        data: deleteQuarterlyReportData,
        isLoading: deleteReportIsLoading,
        error: deleteReportError,
        isError: deleteReportIsError,
        isSuccess: deleteReportIsSuccess,
    }] = useDeleteQuarterlyReportMutation();


    useEffect(() => {
        deleteReportIsLoading  ?
            dispatch(setLoader(true)) :
            dispatch(setLoader(false))
    }, [deleteReportIsLoading]);

    useEffect(() => {
        if (deleteReportIsSuccess){
            toast.success(deleteQuarterlyReportData.message);
            getQuarterlyReportsList()
        }
    },[deleteReportIsSuccess ])

    useEffect(() => {
        if (deleteReportIsError){
            errorHandle(deleteReportError);
        }
    },[deleteReportError, deleteReportIsError])

    const deleteQuarterlyReportHandle = (reportId) => {
        deleteQuarterlyReport(reportId);
    }


    return (
        <>
            {
                data?.length > 0 ? <div className='quarterlyReport__content'>
                <div className='table' ref={tableRef} style={{background: "white"}}>
                <div className="table-header">
                    {tableHeader.map((title, index) => <div className="table-th px-3" key={index}>{title}</div>)}
                    <div className="table-th px-3"  style={{maxWidth:'180px'}} key='settings'></div>
                </div>

                        <div className="table-body">
                            {data?.map((item) => (
                                <div className="table-row" key={item.id}>
                                    <div className="table-td px-3 audit__tableTd">
                                        {item.report_name}
                                        {item.report_name?.length > 18  && <span className="audit__tableTd__tooltip">{item.report_name}</span>}
                                    </div>
                                    <div className="table-td px-3 audit__tableTd" >
                                        {item.country}
                                    </div>
                                    <div className="table-td px-3 audit__tableTd" >
                                        {item.loan_grant_number}
                                    </div>
                                    <div className="table-td px-3 audit__tableTd" >
                                        {item.implementing_agency}
                                        {item.implementing_agency?.length > 18  && <span className="audit__tableTd__tooltip">{item.implementing_agency}</span>}
                                    </div>
                                    <div className="table-td px-3 audit__tableTd" >
                                        {item.count_projects}
                                    </div>
                                    <div className="table-td px-3 audit__tableTd" >
                                        {item.report_number}
                                    </div>
                                    <div className="table-td px-3 audit__tableTd" >
                                        {item.report_date}
                                    </div>
                                    <div className=" px-3 quarterlyReport__tableSettings" key='settings'>
                                        {user?.permissions?.view_quarterly_report &&
                                            <button onClick={() => {
                                            navigate(`/quarterly-online-view/${item?.actions?.view_id}`, {state: {id: item?.actions?.view_id, report_id: item?.id, prevLocation: location?.pathname}})
                                             }}>
                                                <img src="/assets/icons/eye.svg" alt=""/>
                                                <span className="quarterlyReport__tableSettings__btnTooltip">{pageTranslations?.['columns.view_report.tooltip']}</span>
                                            </button>
                                        }
                                        {user?.permissions?.edit_quarterly_report &&
                                            <button onClick={() => {
                                             navigate(`/edit-quarterly-report/${item?.actions?.view_id}`, {state: {id: item?.actions?.view_id, prevLocation: location?.pathname, date: item?.report_date}})
                                            }}>
                                                <img src="/assets/icons/edit.svg" alt=""/>
                                                <span className="quarterlyReport__tableSettings__btnTooltip">{pageTranslations?.['columns.edit_report.tooltip']}</span>
                                            </button>
                                        }
                                        {user?.permissions?.delete_quarterly_report &&
                                            <button  onClick={() => {
                                                setIsOpenDeleteModal({id: item?.id, show: true});
                                            }}>
                                                <img color='red' src="/assets/icons/trash-grey-without-bg.svg" alt="Delete project"/>
                                                <span className="quarterlyReport__tableSettings__btnTooltip quarterlyReport__tableSettings_lastElTooltip ">{pageTranslations?.['columns.delete_report.tooltip']}</span>
                                            </button>
                                        }
                                    </div>
                                </div>
                            ))}
                        </div>

                </div>
            </div>
                :
                    <div style={{ height: '70vh', width: '100%', display: "flex", justifyContent: "center", alignItems: 'center', flexDirection: "column" }}>
                        <img src="/assets/images/projects.png" alt="" />
                        <span style={{ color: "#767676", fontSize: 16, fontWeight: 500 }}>{emptyDataText}</span>
                    </div>
            }
            {(data?.length > 0 && totalPage > 0) && <Paginate currentPage={currentPage} setCurrentPage={setCurrentPage} pageCount={totalPage} />}
            {isOpenDeleteModal?.show && <DeleteModal
                id={isOpenDeleteModal.id}
                action={deleteQuarterlyReportHandle}
                deleteText={pageTranslations?.['actions.delete']}
                cencelText={pageTranslations?.['actions.cancel']}
                modalTitle={pageTranslations?.['headers.confirm_your_action']}
                clarifyingText={pageTranslations?.['titles.you_are_going_to_delete_this_quarterly_report']}
                setDeleteModal={setIsOpenDeleteModal}
            />}

        </>
    );
}

export default Table;
