import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import React, {useEffect, useState} from 'react';
import {setLoader} from "../../store/slice/dashboard";
import {
    useUpdateEmailMutation,
    useConfirmEmailCodeMutation,
    useLazyGetAccountSettingsQuery, useAllowToMFAMutation
} from "../../store/service/adbService";
import {Switch} from "../../UI/Switch";
import Modal from "../../components/Modal";
import {errorHandle} from "../../global/errorHandle";
import EmailChangeContent from "../../components/Modal/EmailChangeContent"
import VerificationCode from "../../components/Modal/VerificationCode"
import './index.scss';

const AccountEmail = ({accountData, pageData}) =>{
    const dispatch = useDispatch();
    const [isEditMailModal, setIsEditMailModal] = useState(false);
    const [switchEmail, setSwitchEmail] = useState(false);
    const [isVerifyMail, setIsVerifyMail] = useState(false);
    const [newEmail, setNewEmail] = useState();
    const [inputData, setInputData] = useState({
            value: "",
            id: "email",
        },
    );

    const [getAccountData,{
        isLoading: accountDataIsLoading,
    }] = useLazyGetAccountSettingsQuery();

    const [allowToMFA,{
        data: allowToMFAData,
        isLoading: allowToMFAIsLoading,
        isSuccess: allowToMFAIsSuccess,
        isError:  allowToMFAIsError,
        error: allowToMFAError,
    }] = useAllowToMFAMutation();

    const [requestToUpdateEmail,{
        data: requestToUpdateEmailData,
        isSuccess: requestToUpdateEmailIsSuccess,
        error: requestToUpdateEmailError,
        isError: requestToUpdateEmailIsError,
        isLoading: requestToUpdateEmailIsLoading,
    }] = useUpdateEmailMutation();

    const [confirmEmailCode, {
        data: confirmEmailCodeData,
        error: confirmEmailCodeError,
        isError: confirmEmailCodeDataIsError,
        isSuccess: confirmEmailCodeIsSuccess,
        isLoading: confirmEmailCodeIsLoading,
    }] = useConfirmEmailCodeMutation();

    const submitHandler = () => {
            requestToUpdateAccountEmail(inputData.value).then(() =>{})
    }

    const requestToUpdateAccountEmail = async (email) => {
        await requestToUpdateEmail({
            email
        })
    }

    const updateAccountEmail = async (code) => {
        await confirmEmailCode({
            email: newEmail,
            code: code,
        })
    }

    const allowEmailToMFA =  () => {
        allowToMFA('email');
        localStorage.removeItem('mfa_token');
    }

    useEffect( () => {
        if (allowToMFAIsSuccess){
            getAccountData();
        }
    }, [allowToMFAData, allowToMFAIsSuccess])

    useEffect(() => {
        setSwitchEmail(!!accountData?.data.email.enabled);
    }, [accountData]);

    useEffect( () => {
        if (confirmEmailCodeIsSuccess){
            toast.success(confirmEmailCodeData.data?.message);
            getAccountData()
            setIsVerifyMail(false);
        }
    },[confirmEmailCodeIsSuccess]);

    useEffect( () => {
        if (confirmEmailCodeDataIsError){
            toast.error(confirmEmailCodeError?.data?.errors?.code);
        }
    },[confirmEmailCodeDataIsError]);

    useEffect( () => {
        if (allowToMFAIsError){
            errorHandle(allowToMFAError)
        }
    },[allowToMFAIsError,allowToMFAError]);

    useEffect( () => {
        if (requestToUpdateEmailIsError){
            errorHandle(requestToUpdateEmailError)
        }
    },[requestToUpdateEmailIsError]);

    useEffect( () => {
        if (requestToUpdateEmailIsSuccess){
            toast.success(requestToUpdateEmailData?.message);
            setNewEmail(requestToUpdateEmailData.data.email);
            setIsEditMailModal(false);
            setIsVerifyMail(true);
        }

    },[requestToUpdateEmailIsSuccess])

    useEffect(() => {
         requestToUpdateEmailIsLoading || confirmEmailCodeIsLoading || accountDataIsLoading || allowToMFAIsLoading ? dispatch(setLoader(true)) : dispatch(setLoader(false))
    }, [ requestToUpdateEmailIsLoading, confirmEmailCodeIsLoading, accountDataIsLoading, allowToMFAIsLoading ]);

    return(
        <>
            <div className="settings__content__item">
                <img className="settings__content__item__img" src="/assets/icons/mail-checking.svg" alt="password-code"/>
                <div className="settings__content__item__wrapper">
                    <h2>{pageData['email.email.headers.email']}</h2>
                    <p>
                        {pageData['email.email.titles.use_your_email']}
                    </p>
                </div>
                <div className="settings__content__item__rightWrapper">
                    <span className="settings__content__item__value">
                        {accountData?.data.email.value}
                    </span>
                    <button className="settings__content__item__editBtn" onClick={()=>setIsEditMailModal(true)}>
                        <img className="settings__content__item__img" src="/assets/icons/edit-black.svg" alt="edit-icon"/>
                    </button>
                    <Switch
                        id={'allowEmailToMfa'}
                        checked={switchEmail}
                        handler={() => {allowEmailToMFA(!switchEmail)}}
                    />
                </div>
            </div>
            { isEditMailModal &&
                <Modal
                    title={pageData["email.edit.headers.change_email"]}
                    closeModal={()=>setIsEditMailModal(false)}
                >
                    <EmailChangeContent
                        inputData={inputData}
                        setInputData={setInputData}
                        btnTitle={pageData["email.edit.actions.next"]}
                        description={pageData["email.edit.titles.send_code_to_email"]}
                        inputPlaceholder={pageData["email.edit.titles.enter_email"]}
                        submitHandler={submitHandler}
                    />
                </Modal>
            }
            { isVerifyMail &&
                <Modal
                    title={pageData["email.confirm.headers.Enter_verification_code"]}
                    closeModal={()=>setIsVerifyMail(false)}
                >
                    <VerificationCode
                        itemValue={inputData.value}
                        btnTitle={pageData["email.confirm.actions.verify"]}
                        description={pageData["email.confirm.titles.sent_code_to"]}
                        resentButtonText={pageData["email.confirm.actions.send_code_again"]}
                        resendFunc={submitHandler}
                        verifyFunc={updateAccountEmail}
                    />

                </Modal>
            }
        </>
    )
}

export default AccountEmail;