import React, { useState } from "react";
import moment from "moment";
import { useEffect } from "react";
import { useUpdateProjectApprovedChangesMutation } from "../../../store/service/adbService";
import { toast } from "react-toastify";
import { useErrorHandler } from "../../../global/ErrorsHandler";
import {useDispatch, useSelector} from "react-redux";
import {setLoader} from "../../../store/slice/dashboard";
import MoreInfoModal from "../../MoreInfoModal";

export const ApprovedContractTable = ({ glossaryData, data, id, refChangesTable, projectData, translations }) => {
    const dispatch = useDispatch()
    const user = useSelector((state) => state?.adbReducer?.user);
    const [approvedContract, setApprovedContract] = useState(true);
    const [approvedContractData, setApprovedContractData] = useState({
        edit: false,
        date: moment(),
        completion_date: projectData?.data?.contract_details?.original_contract_completion_date,
        amount: projectData?.data?.contract_details?.original_contract_amount,
    });
    const [prevData, setPrevData] = useState(null);
    const [editable, setEditable] = useState(false);
    const [historyData, setHistoryData] = useState([])
    const [tableHeaders, setTableHeaders] = useState([])
    const [infoModal, setInfoModal] = useState(false)
    const [moreInfo, setMoreInfo] = useState([])

    const [
        updateApprovedChanges,
        {
            data: approvedChangesData,
            isError: approvedChangesIsError,
            error: approvedChangesError,
            isSuccess: approvedChangesIsSuccess,
            isLoading: approvedChangesLoading,
        },
    ] = useUpdateProjectApprovedChangesMutation();


    useEffect(() => {
        if (glossaryData?.data?.length) {
            setMoreInfo(glossaryData?.data?.filter(el => el?.section_name === "Approved Contract Changes")[0])
            setTableHeaders(glossaryData?.data?.filter(el => el?.section_name === 'Approved Contract Changes')
                ?.map(item => ({
                    ...item, tooltips: item?.tooltips
                        ?.map(tooltip => ({...tooltip, showTooltip: false}))
                })))
        }
        if (data?.length) {
            setHistoryData(data)
            setApprovedContractData({
                edit: false,
                date: moment(data[0]?.change_date) ?? "",
                completion_date: moment(data[0]?.updated_completion_date) ?? "",
                amount: data[0]?.updated_contract_amount || projectData?.data?.contract_details?.original_contract_amount,
            });
            if (approvedChangesIsSuccess) {
                setPrevData({
                    edit: false,
                    date: moment(data[0]?.change_date) ? moment(data[0]?.change_date) : moment(),
                    completion_date: moment(data[0]?.updated_completion_date) ? moment(data[0]?.updated_completion_date) : projectData?.data?.contract_details?.original_contract_completion_date,
                    amount: data[0]?.updated_contract_amount ? data[0]?.updated_contract_amount : projectData?.data?.contract_details?.original_contract_amount,
                });
                setApprovedContractData({
                    edit: false,
                    date: moment(data[0]?.change_date) ? moment(data[0]?.change_date) : moment(),
                    completion_date: moment(data[0]?.updated_completion_date) ? moment(data[0]?.updated_completion_date) : projectData?.data?.contract_details?.original_contract_completion_date,
                    amount: data[0]?.updated_contract_amount ? data[0]?.updated_contract_amount : projectData?.data?.contract_details?.original_contract_amount,
                });
            }
        }
    }, [data, glossaryData, approvedChangesIsSuccess]);


    useEffect(() => {
        const d = () => {
            dispatch(setLoader(false));
        };
        setApprovedContractData((old) => ({
            ...old,
            edit: false,
        }));
        setEditable(false);

        approvedChangesLoading
            ? dispatch(setLoader(true))
            : d();
    }, [
        approvedChangesLoading,
    ]);

    const [errorHandler, setErrorHandler] = useErrorHandler([
        approvedChangesError,
    ]);

    const submitHandler = (e) => {
        e.preventDefault();

        const body = {
            change_date:
                moment(approvedContractData?.date).format("YYYY-MM-DD") ?? null,
            updated_completion_date:
                moment
                (approvedContractData?.completion_date)
                    .format("YYYY-MM-DD") ?? null,
            updated_contract_amount: approvedContractData?.amount.toString()?.split(',').join('') ?? null,
        };

        let s = [];
        for (let key in body) {
            if (!body[key] || body[key] === "Invalid date" || body[key] === "") {
                toast.error("Please enter " + key?.split('_')?.join(' '), {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
                s.push("false");
            }
        }

        if (s.length === 0) {
            updateApprovedChanges({ id, body });
        }
    };


    return (
        <div className={`project__white-box`} ref={refChangesTable}>
            {infoModal &&
                <MoreInfoModal
                    title={translations?.['approved_contract_changes.approved_contract_changes.headers.approved_contract_changes']}
                    moreInfo={translations?.['approved_contract_changes.approved_contract_changes.more_info.approved_contract_changes']}
                    close={()=> setInfoModal(false)}
                />
            }
            <div className={`project__block  ${approvedContract ? "" : "close"}`}>
                <div
                    onClick={() => {
                        setApprovedContract(!approvedContract);
                    }}
                    className="project__block-title"
                >
                    <span>{translations?.['approved_contract_changes.approved_contract_changes.headers.approved_contract_changes']}</span>
                    <span className="project__block-more" onClick={() => setInfoModal(!infoModal)}>
            <span className="img">
              <img src="/assets/icons/info.svg" alt="info" />
            </span>
            <span className="text">{translations?.['approved_contract_changes.approved_contract_changes.titles.more_info']}</span>
          </span>
                </div>
                <div className="project__block-hr"></div>
                <form
                    className={`project__block-content changes-table`}
                    onSubmit={submitHandler}
                >
                    <div className="changes-table__item">
                        <div className="label">{translations?.['approved_contract_changes.approved_contract_changes.titles.most_recent_contract_change_date']}</div>
                        <div className="value">
                            <div>{data?.most_recent_contract_change_date ? moment(data?.most_recent_contract_change_date).format('DD/MM/YYYY') : '-'}</div>
                        </div>
                    </div>
                    <div className="changes-table__item">
                        <div className="label">{translations?.['approved_contract_changes.approved_contract_changes.titles.current_contract_completion_date']}</div>
                        <div className="value">
                            <div>{data?.current_contract_completion_date ? moment(data?.current_contract_completion_date).format('DD/MM/YYYY') : '-'}</div>
                        </div>
                    </div>
                    <div className="changes-table__item">
                        <div className="label">{translations?.['approved_contract_changes.approved_contract_changes.titles.current_approved_contract_amount']}</div>
                        <div className="value">
                            USD ${String(data?.current_approved_contract_amount).replace(/(\d)(?=(\d{3})+(\D|$))/g, "$1,") ?? '-'}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};
