import React, {useEffect, useRef, useState} from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
    useLazyGetProgressPerOutputsQuery,
    useCreateProgressPerOutputsMutation,
    useUpdateProgressPerOutputsMutation,
    useDeleteProgressPerOutputsMutation,
} from '../../../store/service/adbService';
import { errorHandle } from '../../../global/errorHandle';
import { setLoader } from '../../../store/slice/dashboard';
import ItemEdit from "./ItemEdit";
import {parseHtml} from "../../../global/parseHtml";

const ProgressPerOutput = ({ reportId, translations, data, isReportSubmitted }) => {
    const dispatch = useDispatch();
    const headerRef = useRef();
    const [isOpenEdit, setIsOpenEdit] = useState({ show: false, id: null });
    const [isOpenToAdd, setIsOpenToAdd] = useState(false);
    const [progressPerOutputData, setProgressPerOutputData] = useState(data);
    const [cellWidths, setCellWidths] = useState([]);

    const [deleteProgressPerOutput, {
        data: deleteProgressPerOutputData,
        isLoading: deleteProgressPerOutputIsLoading,
        error: deleteProgressPerOutputError,
        isError: deleteProgressPerOutputIsError,
        isSuccess: deleteProgressPerOutputIsSuccess
    }] = useDeleteProgressPerOutputsMutation();

    const [getProgressPerOutputs, {
        data: newProgressPerOutputData,
        isLoading: progressPerOutputIsLoading,
        error: progressPerOutputError,
        isError: progressPerOutputIsError,
        isSuccess: progressPerOutputIsSuccess
    }] = useLazyGetProgressPerOutputsQuery();

    const updateProgressPerOutputs = () => {
        getProgressPerOutputs(reportId);
    };

    useEffect(() => {
        setProgressPerOutputData(data)
    }, [data]);

    useEffect(() => {
        if (deleteProgressPerOutputIsSuccess) {
            toast.success(deleteProgressPerOutputData.message);
            updateProgressPerOutputs();
        }
    }, [deleteProgressPerOutputIsSuccess, deleteProgressPerOutputData]);

    useEffect(() => {
        if (progressPerOutputIsSuccess) {
            setProgressPerOutputData(newProgressPerOutputData?.data);
        }
    }, [progressPerOutputIsSuccess, newProgressPerOutputData]);

    useEffect(() => {
        if (deleteProgressPerOutputIsError) {
            toast.error(deleteProgressPerOutputError?.data.message);
            errorHandle(deleteProgressPerOutputError);
        }
        if (progressPerOutputIsError) {
            toast.error(progressPerOutputError?.data.message);
            errorHandle(progressPerOutputError);
        }
    }, [
        deleteProgressPerOutputIsError, deleteProgressPerOutputError,
        progressPerOutputError, progressPerOutputIsError
    ]);

    useEffect(() => {
        progressPerOutputIsLoading ||
        deleteProgressPerOutputIsLoading ?
            dispatch(setLoader(true)) :
            dispatch(setLoader(false));
    }, [deleteProgressPerOutputIsLoading, progressPerOutputIsLoading]);


    useEffect(() => {
        if (headerRef.current) {
            const widths = Array.from(headerRef.current.querySelectorAll('th')).map(th => th?.offsetWidth);
            setCellWidths(widths);
        }
    }, [headerRef]);

    return (
        <div className='PCWADP' ref={headerRef}>
            <div className="editReport__sectionHeader">
                <h3>
                    {translations?.['implementation_progress.progress_per_outputs.headers.progress_per_outputs']}
                </h3>
                {!isReportSubmitted &&
                    <button
                        className="editReport__sectionHeader__addRowBtn"
                        onClick={() => setIsOpenToAdd(true)}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25Z" stroke="#0052CC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M11.8687 9.22595L11.9111 14.6141" stroke="#0052CC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M9.1958 11.8988L14.584 11.9412" stroke="#0052CC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        {translations?.['implementation_progress.progress_per_outputs.actions.add_row']}
                    </button>
                }
            </div>
            <div className="table-container">
                <table  className='report__table'>
                    <thead className="report__table__header">
                    <tr className="report__table__row">
                        <th style={{ width: '14%' }}>{translations?.['implementation_progress.progress_per_outputs.table_headers.output_no']}</th>
                        <th style={{ width: '20%' }}>{translations?.['implementation_progress.progress_per_outputs.table_headers.title']}</th>
                        <th style={{ width: '28%' }}>{translations?.['implementation_progress.progress_per_outputs.table_headers.description']}</th>
                        <th style={{ width: '38%' }}>{translations?.['implementation_progress.progress_per_outputs.table_headers.action_taken_proposed']}</th>
                        <th style={{padding: 0}}></th>
                    </tr>
                    </thead>
                    <tbody className="report__table__body">
                    {
                        progressPerOutputData?.map(item => {
                            return (
                                <React.Fragment key={item.id}>
                                    {!(isOpenEdit.show && isOpenEdit.id === item.id) ?
                                        <tr className="report__table__row">
                                            <td style={{ width: '14%' }}>{item.output_no}</td>
                                            <td style={{ width: '20%' }}>{item.title}</td>
                                            <td style={{ width: '28%' }}>{parseHtml(item.description)}</td>
                                            <td style={{ width: '38%' }}>{parseHtml(item.action_taken)}</td>
                                            <td style={{padding: 0}}>
                                                {!isReportSubmitted &&
                                                    <div className="editReport__table__settingsButtons">
                                                        <button className="editReport__table__deleteBtn"
                                                                onClick={() => deleteProgressPerOutput(item.id)}>
                                                            {translations?.['financing_plan.index.actions.delete']}
                                                        </button>
                                                        <button
                                                            className="editReport__table__updateBtn"
                                                            onClick={() => setIsOpenEdit({show: true, id: item.id})}>
                                                            {translations?.['financing_plan.index.actions.update']}
                                                        </button>
                                                    </div>
                                                }
                                            </td>
                                        </tr> :
                                        <ItemEdit
                                            headerRef={headerRef}
                                            itemData={progressPerOutputData?.find(item => item.id === isOpenEdit.id)}
                                            reportId={reportId}
                                            setIsOpenEdit={setIsOpenEdit}
                                            getItems={updateProgressPerOutputs}
                                            cancelTranslation={translations?.['financing_plan.index.actions.cancel']}
                                            saveTranslation={translations?.['financing_plan.index.actions.save']}
                                            useCreateItemMutation={useCreateProgressPerOutputsMutation}
                                            useUpdateItemMutation={useUpdateProgressPerOutputsMutation}
                                            fields={['output_no', 'title', 'description', 'action_taken']}
                                            cellWidths={cellWidths}
                                            htmlEditFieldKeys={['description', 'action_taken']}
                                            placeholders={[
                                                translations?.['index.index.placeholder.number'],
                                                translations?.['index.index.placeholder.text'],
                                                translations?.['index.index.placeholder.text'],
                                                translations?.['index.index.placeholder.text']
                                            ]}
                                        />
                                    }
                                </React.Fragment>
                            );
                        })
                    }
                    </tbody>
                    {isOpenToAdd && (
                        <ItemEdit
                            headerRef={headerRef}
                            itemData={{}}
                            reportId={reportId}
                            setIsOpenEdit={setIsOpenToAdd}
                            getItems={updateProgressPerOutputs}
                            cancelTranslation={translations?.['financing_plan.index.actions.cancel']}
                            saveTranslation={translations?.['financing_plan.index.actions.save']}
                            useCreateItemMutation={useCreateProgressPerOutputsMutation}
                            useUpdateItemMutation={useUpdateProgressPerOutputsMutation}
                            fields={['output_no', 'title', 'description', 'action_taken']}
                            placeholders={[
                                translations?.['index.index.placeholder.number'],
                                translations?.['index.index.placeholder.text'],
                                translations?.['index.index.placeholder.text'],
                                translations?.['index.index.placeholder.text']
                            ]}
                            cellWidths={cellWidths}
                            htmlEditFieldKeys={['description', 'action_taken']}
                        />
                    )}
                </table>
            </div>
        </div>
    );
};

export default ProgressPerOutput;
