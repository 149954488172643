import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import React, {useEffect, useRef, useState} from 'react';
import ItemEdit from "./ItemEdit";
import PCAandDisbursPerfomance from "../PCAandDisbursPerfomance";
import {setLoader} from "../../../store/slice/dashboard";
import {errorHandle} from "../../../global/errorHandle";
import {
    useLazyGetLoanUtilizationQuery,
    useUpdateLoanUtilizationMutation
} from "../../../store/service/adbService";
import './index.scss';
import MoreInfoModal from "../../../components/MoreInfoModal";


const LoanUtilization = ({reportId, translations, data, hiddenSectionHandler, isReportSubmitted }) => {
    const dispatch = useDispatch();
    const tableRef = useRef();
    const itemRef = useRef();
    const thRefs = useRef([]);
    const [tdWidths, setTdWidths] = useState([]);
    const [loanData, setLoanData ]= useState(data?.index)
    const [isOpenMoreInfo, setOpenMoreInfo] = useState(false)
    const [isOpenEdit, setIsOpenEdit ]= useState({isShow: false});

    const [getLoanUtilization, {
        data: newLoanUtilizationData,
        isLoading: loanUtilizationIsLoading,
        error: loanUtilizationError,
        isSuccess: loanUtilizationIsSuccess,
    }]= useLazyGetLoanUtilizationQuery();

    const [updateLoanUtilization, {
        data: updateLoanUtilizationData,
        isSuccess: updateLoanUtilizationIsSuccess,
        isLoading: updateLoanUtilizationIsLoading,
        error: updateLoanUtilizationError,
    }]= useUpdateLoanUtilizationMutation();


    const handleMouseLeave = (event) => {
        if (!itemRef.current || !itemRef.current.contains(event.target)) {
            setIsOpenEdit(false)
        }
    };
    useEffect(() => {
        setLoanData(data?.index)
    }, [data]);

    useEffect(() => {
        if (loanData && thRefs.current.length) {
            const widths = thRefs.current.map(th => th?.offsetWidth);
            setTdWidths(widths);
        }
    }, [loanData, thRefs.current]);

    useEffect(() => {
        if (loanUtilizationIsSuccess){
            setLoanData(newLoanUtilizationData?.data)
        }
    }, [loanUtilizationIsSuccess, newLoanUtilizationData]);

    useEffect(() => {
        if (updateLoanUtilizationIsSuccess){
            toast.success(updateLoanUtilizationData.message);
            getLoanUtilization(reportId);
            setIsOpenEdit(null)
        }
    }, [updateLoanUtilizationIsSuccess]);

    useEffect(() => {
        loanUtilizationIsLoading ||
        updateLoanUtilizationIsLoading
            ? dispatch(setLoader(true))
            : dispatch(setLoader(false));
    }, [updateLoanUtilizationIsLoading, loanUtilizationIsLoading]);

    useEffect(() => {
        if (updateLoanUtilizationError) {
            errorHandle(updateLoanUtilizationError);
        }
        if (loanUtilizationError) {
            errorHandle(loanUtilizationError);
        }
    }, [updateLoanUtilizationError, loanUtilizationError])

    useEffect(function () {
        document.addEventListener('mousedown', handleMouseLeave);
        document.addEventListener('touchstart', handleMouseLeave);

        return () => {
            document.removeEventListener('mousedown', handleMouseLeave);
            document.removeEventListener('touchstart', handleMouseLeave);

        };
    }, [tableRef]);

    return (
        <section className="erLoan" ref={tableRef} id='loan_utilization'>
            {isOpenMoreInfo &&
                <MoreInfoModal
                    title= {translations?.['loan_utilization.index.headers.loan_utilization']}
                    moreInfo={translations?.['loan_utilization.index.more_info.more_info']}
                    close={()=>setOpenMoreInfo(false)}
                />
            }
            <div className="editReport__sectionHeader">
                <span onClick={() => hiddenSectionHandler(tableRef)}/>
                <h2 onClick={() => hiddenSectionHandler(tableRef)}>
                    {translations?.['loan_utilization.index.headers.loan_utilization']}
                </h2>
                <button className="editReport__sectionHeader__moreInfo"  onClick={()=>setOpenMoreInfo(!isOpenMoreInfo)}>
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.99805 6.33325V9.43992" stroke="#767676" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M6.99805 5.05322C7.27419 5.05322 7.49805 4.82937 7.49805 4.55322C7.49805 4.27708 7.27419 4.05322 6.99805 4.05322C6.7219 4.05322 6.49805 4.27708 6.49805 4.55322C6.49805 4.82937 6.7219 5.05322 6.99805 5.05322Z" fill="#767676"/>
                        <path d="M6.9987 13.1666C10.4045 13.1666 13.1654 10.4057 13.1654 6.99992C13.1654 3.59416 10.4045 0.833252 6.9987 0.833252C3.59294 0.833252 0.832031 3.59416 0.832031 6.99992C0.832031 10.4057 3.59294 13.1666 6.9987 13.1666Z" stroke="#767676" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    {translations?.['loan_utilization.index.titles.more_info']}
                </button>
            </div>

            <div className="table-container">
                <table className="custom-table">
                    <thead className="header">
                        <tr className="header__yearsRow">
                            <th></th>
                            {
                                loanData &&  Object.keys(loanData)?.map((key, index) =>
                                    <th
                                        key={key}
                                        ref={el => thRefs.current[index] = el}
                                    >
                                    {  +String(key).replace('_', '')}
                                    </th>)
                            }
                        </tr>
                        <tr className="header__monthRow">
                        <th ></th>
                            {loanData && Object.keys(loanData)?.map((key, rowIndex) => loanData[key]?.map(item =>
                                <th
                                    key={item.id}
                                    style={{
                                        width: tdWidths[rowIndex] ? `${tdWidths[rowIndex] / loanData[key].length}px` : 'auto'
                                    }}
                                >
                                    {item?.period}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="header__yearsRow">
                            <th>{translations?.['loan_utilization.index.headers.contract_award']}</th>
                            {
                                loanData &&  Object.keys(loanData)?.map(key =><th key={key}></th>)
                            }
                        </tr>
                        <tr>
                            <td>{translations?.['loan_utilization.index.titles.projection']}</td>
                            {loanData && Object.keys(loanData)?.map((key, rowIndex) => loanData[key]?.map((item, index) =>
                                {
                                    return(
                                        <td
                                            onClick={()=>setIsOpenEdit({
                                                isShow: !isOpenEdit?.isShow,
                                                id:item.id,
                                                key:'contract_award_projection'
                                            })}
                                            style={{
                                                width: tdWidths[rowIndex] ? `${tdWidths[rowIndex] / loanData[key].length}px` : 'auto'
                                            }}
                                            key={`contract_award_projection_${index}`}
                                        >
                                            {item.contract_award_projection}
                                            {(isOpenEdit?.id === item.id && isOpenEdit?.key === 'contract_award_projection' && isOpenEdit?.isShow && !isReportSubmitted)  &&
                                                <ItemEdit
                                                    itemRef={itemRef}
                                                    itemData={item}
                                                    itemKey={'contract_award_projection'}
                                                    updateMethod={updateLoanUtilization}
                                                    applyBtnTitle={translations?.['loan_utilization.index.actions.apply']}
                                                    placeHolder={translations?.['index.index.placeholder.number']}
                                                />}
                                        </td>
                                    )
                                }
                            ))}
                        </tr>
                        <tr style={{color: '#767676', fontWeight: 500, marginBottom: '6px'}}>
                            <td>{translations?.['loan_utilization.index.titles.cumulative']}</td>
                            {loanData && Object.keys(loanData)?.map((key, rowIndex) => loanData[key]?.map((item, index) =>
                                <td
                                    key={`contract_award_projection_cumulative_${index}`}
                                    style={{
                                        width: tdWidths[rowIndex] ? `${tdWidths[rowIndex] / loanData[key].length}px` : 'auto'
                                    }}
                                >
                                    {item.contract_award_projection_cumulative}
                                </td>
                            ))}
                        </tr>
                        <tr>
                            <td>{translations?.['loan_utilization.index.titles.actual']}</td>
                            {loanData && Object.keys(loanData)?.map((key, rowIndex) => loanData[key]?.map((item, index) =>
                                <td
                                    style={{
                                        width: tdWidths[rowIndex] ? `${tdWidths[rowIndex] / loanData[key].length}px` : 'auto'
                                    }}
                                    onClick={()=>setIsOpenEdit({
                                        isShow: !isOpenEdit?.isShow,
                                        id:item.id,
                                        key:'contract_award_actual'
                                    })}
                                    key={`contract_award_actual_${index}`}
                                >
                                    {item.contract_award_actual}
                                    {(isOpenEdit?.id === item.id && isOpenEdit?.key === 'contract_award_actual' && isOpenEdit?.isShow && !isReportSubmitted)  &&
                                        <ItemEdit
                                            itemRef={itemRef}
                                            itemData={item}
                                            itemKey={'contract_award_actual'}
                                            updateMethod={updateLoanUtilization}
                                            applyBtnTitle={translations?.['loan_utilization.index.actions.apply']}
                                            placeHolder={translations?.['index.index.placeholder.number']}
                                        />}
                                </td>
                            ))}
                        </tr>
                        <tr style={{color: '#767676', fontWeight: 500, marginBottom: '6px'}}>
                            <td>{translations?.['loan_utilization.index.titles.cumulative']}</td>
                            {loanData && Object.keys(loanData)?.map((key, rowIndex) => loanData[key]?.map((item, index) =>
                                <td
                                    style={{
                                        width: tdWidths[rowIndex] ? `${tdWidths[rowIndex] / loanData[key].length}px` : 'auto'
                                    }}
                                    key={`contract_award_actual_cumulative_${index}`}
                                >
                                    {item.contract_award_actual_cumulative}
                                </td>
                            ))}
                        </tr>
                        <tr className="header__yearsRow">
                            <th>{translations?.['loan_utilization.index.headers.disbursement']}</th>
                            {
                                loanData &&  Object.keys(loanData)?.map(key =><th key={key}></th>)
                            }
                        </tr>
                        <tr>

                            <td>{translations?.['loan_utilization.index.titles.projection']}</td>
                            {loanData && Object.keys(loanData)?.map((key, rowIndex) => loanData[key]?.map((item, index) =>
                                <td
                                    style={{
                                        width: tdWidths[rowIndex] ? `${tdWidths[rowIndex] / loanData[key].length}px` : 'auto'
                                    }}
                                    onClick={()=>setIsOpenEdit({
                                        isShow: !isOpenEdit?.isShow,
                                        id:item.id,
                                        key:'disbursement_projection'
                                    })}
                                    key={`disbursement_projection_${index}`}
                                >
                                    {item.disbursement_projection}
                                    {(isOpenEdit?.id === item.id && isOpenEdit?.key === 'disbursement_projection' && isOpenEdit?.isShow && !isReportSubmitted)  &&
                                        <ItemEdit
                                            itemRef={itemRef}
                                            itemData={item}
                                            itemKey={'disbursement_projection'}
                                            updateMethod={updateLoanUtilization}
                                            applyBtnTitle={translations?.['loan_utilization.index.actions.apply']}
                                            placeHolder={translations?.['index.index.placeholder.number']}
                                        />}
                                </td>
                            ))}
                        </tr>
                        <tr style={{color: '#767676', fontWeight: 500, marginBottom: '6px'}}>
                            <td>{translations?.['loan_utilization.index.titles.cumulative']}</td>
                            {loanData && Object.keys(loanData)?.map((key, rowIndex) => loanData[key]?.map((item, index) =>
                                <td
                                    key={`disbursement_projection_cumulative_${index}`}
                                    style={{
                                        width: tdWidths[rowIndex] ? `${tdWidths[rowIndex] / loanData[key].length}px` : 'auto'
                                    }}
                                >
                                    {item.disbursement_projection_cumulative}
                                </td>
                            ))}
                        </tr>
                        <tr>
                            <td>{translations?.['loan_utilization.index.titles.actual']}</td>
                            {loanData && Object.keys(loanData)?.map((key, rowIndex) => loanData[key]?.map((item, index) =>
                                <td
                                    style={{
                                        width: tdWidths[rowIndex] ? `${tdWidths[rowIndex] / loanData[key].length}px` : 'auto'
                                    }}
                                    onClick={()=>setIsOpenEdit({
                                        isShow: !isOpenEdit?.isShow,
                                        id:item.id,
                                        key:'disbursement_actual'
                                    })}
                                    key={`disbursement_actual ${index}`}
                                >
                                    {item.disbursement_actual}
                                    {(isOpenEdit?.id === item.id && isOpenEdit?.key === 'disbursement_actual' && isOpenEdit?.isShow && !isReportSubmitted)  &&
                                        <ItemEdit
                                            itemRef={itemRef}
                                            itemData={item}
                                            itemKey={'disbursement_actual'}
                                            updateMethod={updateLoanUtilization}
                                            applyBtnTitle={translations?.['loan_utilization.index.actions.apply']}
                                            placeHolder={translations?.['index.index.placeholder.number']}
                                        />}
                                </td>
                            ))}
                        </tr>
                        <tr style={{color: '#767676', fontWeight: 500}}>
                            <td>{translations?.['loan_utilization.index.titles.cumulative']}</td>
                            {loanData && Object.keys(loanData)?.map((key, rowIndex) => loanData[key]?.map((item, index) =>
                                <td
                                    style={{
                                        width: tdWidths[rowIndex] ? `${tdWidths[rowIndex] / loanData[key].length}px` : 'auto'
                                    }}
                                    onClick={()=>setIsOpenEdit(!isOpenEdit)}
                                    key={`disbursement_actual_cumulative ${index}`}
                                >
                                    {item.disbursement_actual_cumulative}
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </div>
            <hr/>

        <PCAandDisbursPerfomance
            translations={translations}
            data={data}
            reportId={reportId}
            isReportSubmitted={isReportSubmitted}
        />
        </section>
    )
}
export default LoanUtilization;
