import React from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import './index.scss';

const DashboardDetailModal = ({data, closeModal, statisticsPage}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const viewReport = (report_id) => {
        navigate(`/online-view/${report_id}`, {
            state: {
                id: report_id,
                prevLocation: location?.pathname,
            },
        });
    };
    return(
        <div className="dashboardDetailModal">
            <div
                className="dashboardDetailModal__bg"
                onClick={() => closeModal(false)}>
            </div>
           <div className="dashboardDetailModal__content">
               <div className="statisticFilter__header">
                   <h3>
                       {statisticsPage?.['index.index.headers.projects']}
                   </h3>
                   <button
                       className="statisticFilter__header__closeIcon"
                       onClick={()=>closeModal(false)}
                   >
                       <img src="/assets/icons/close-dark.svg" alt="close" />
                   </button>
               </div>
               <div className="dashboardDetailModal__items">
                   {data?.map((item)=><div className="dashboardDetailModal__items__item" key={item.id}>
                       <h4>
                           {item?.contract_title}
                       </h4>
                       <div>
                           <span>{statisticsPage?.['index.index.titles.contract_number']}</span>
                           <span>{item?.contract_number}</span>
                       </div>
                       <div>
                           <span>{statisticsPage?.['index.index.titles.report_date']}</span>
                           <span>{item?.report_date}</span>
                       </div>
                       <button onClick={()=>viewReport(item.report_id)} > {statisticsPage?.['index.index.actions.view_report']}</button>
                   </div>)}
               </div>
           </div>
        </div>
    )
}

export default DashboardDetailModal;