import React, {useEffect, useState} from "react";
import axios from "axios";
import {useLocation} from "react-router-dom";
import moment from "moment/moment";
import AxelButton from "../../UI/AxelButton";
import useLastPathname from "../../global/useLastPathname";

export const ProgramProgressTable = ({reportId, date}) => {
    const location = useLocation();
    const lastPathname = useLastPathname();
    const [progressData, setProgressData] = useState([]);
    const [currentId, setCurrentId] = useState({id: null, type: null});
    const [plannedAmount, setPlannedAmount] = useState('');
    const [actualAmount, setActualAmount] = useState('');
    const [translations, setTranslations] = useState([]);
    const [edit, setEdit] = useState(false);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/chart/program-progress-table/${reportId ? reportId : location?.pathname?.split('/')[2]}/${date ? date : location?.pathname?.split('/')[3]}`,{
            headers: {
                "AppLocale": lastPathname
            }
        })
            .then((res) => {
                let response = res?.data?.data?.data;
                setTranslations(res?.data?.data?.translations)
                setProgressData(response?.map(el => ({
                    ...el,
                    date: el?.date,
                    planned_amount: el?.planned_amount,
                    actual_amount: el?.actual_amount,
                    cumulative_actual_amount: el?.cumulative_actual_amount,
                    cumulative_planned_amount: el?.cumulative_planned_amount,
                    edit: false
                })))
            })

    }, [reportId])

    return (
        <div className="plannedProject__table">
            <div className={`plannedProject__table-row ${edit && progressData?.length > 10 && 'active'}`}>
                <div className="plannedProject__table-months">
                    <div className="plannedProject__table-monthsBox progressChart">
                        <span>{translations?.['months']}</span>
                    </div>
                    <div className="plannedProject__table-monthsBox progressChart">
                        <div
                            className="red"></div>
                        <p>{translations?.['planned']}</p>
                    </div>
                    <div className="plannedProject__table-monthsBox progressChart">
                        <div
                            className={"lightblue"}></div>
                        <p>{translations?.['actual']}</p>
                    </div>
                    <div className="plannedProject__table-monthsBox progressChart">
                        <div
                            className={"blue"}></div>
                        <p>{translations?.['cumulative_planned']}</p>
                    </div>
                    <div className="plannedProject__table-monthsBox progressChart">
                        <div
                            className={"yellow"}></div>
                        <p>{translations?.['cumulative_actual']}</p>
                    </div>
                </div>
                {
                    progressData?.map((el, idx) => {
                        return (
                            <div
                                key={idx} className={`plannedProject__month screenshot`}>
                                <div
                                    className="plannedProject__month-box screenshot ">{el?.date}</div>
                                <div
                                    className={`plannedProject__month-box screenshot plannedProject__month-box_normal`}
                                >
                                                <span>
                                                {el?.planned_amount !== null ? el?.planned_amount?.toLocaleString() + '%' : '-'}
                                                </span>
                                    {
                                        (currentId?.type === 'planned' && currentId?.id === el?.id && el?.edit) &&
                                        <div className="plannedProject__month-box-edit ">
                                            <input
                                                className={"input-planned"}
                                                autoFocus={true}
                                                type={"number"}
                                                maxLength={3}
                                                value={plannedAmount !== null ? plannedAmount : el?.planned_amount}
                                                onChange={(e) => {
                                                    if (e?.target?.value <= 100) {
                                                        setPlannedAmount(e?.target?.value)
                                                    }
                                                }}
                                            />
                                            <div className="plannedProject__table-editBtn">
                                                <AxelButton
                                                    text={'Apply'}/>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div
                                    className={`plannedProject__month-box screenshot ${!el?.editable ? 'plannedProject__month-box_disable' : 'plannedProject__month-box_normal'}`}
                                >
                                            <span>
                                                {el?.actual_amount !== null ? el?.actual_amount?.toLocaleString() + '%' : '-'}
                                            </span>
                                    {
                                        (currentId?.type === 'actual' && currentId?.id === el?.id && el?.edit) &&
                                        <div className="plannedProject__month-box-edit ">
                                            <input
                                                className={"input-planned"}
                                                autoFocus={true}
                                                maxLength={3}
                                                type={"number"}
                                                value={actualAmount !== null ? actualAmount : el?.actual_amount}
                                                onChange={(e) => {
                                                    if (e?.target?.value <= 100) {
                                                        setActualAmount(e?.target?.value)
                                                    }
                                                }
                                                }/>
                                            <div className="plannedProject__table-editBtn">
                                                <AxelButton
                                                    text={'Apply'}/>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className={`plannedProject__month-box screenshot `}>
                                    <span>
                                        {el?.cumulative_planned_amount !== null ? el?.cumulative_planned_amount?.toLocaleString() + '%' : '-'}
                                    </span>
                                </div>
                                <div className={`plannedProject__month-box  screenshot`}>
                                    <span>
                                        {el?.cumulative_actual_amount !== null ? el?.cumulative_actual_amount?.toLocaleString() + '%' : '-'}
                                    </span>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}