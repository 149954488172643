import moment from "moment";
import isEmpty from "lodash/isEmpty";
import { toast } from "react-toastify";
import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useErrorHandler } from "../../../../global/ErrorsHandler";
import {
    useCreateSsempActivitiesMutation,
    useDeleteSsempActivitiesMutation,
    useLazyGetAllCompaniesQuery,
    useUpdateSsempActivitiesMutation,
} from "../../../../store/service/adbService";
import { setLoader } from "../../../../store/slice/dashboard";
import { Switch } from "../../../../UI/Switch";
import { DeleteModal } from "../../DeleteModal";
import MoreInfoModal from "../../../MoreInfoModal";
import CustomDatePicker from "../../../CustomDatePicker";

const customStyles = {
    menu: (provided, state = false) => ({
        ...provided,
        width: "150%",
        color: state.selectProps.menuColor,
        cursor: "pointer",
        height: 200,
        overflow: "hidden",
    }),

    control: (_, { selectProps: { width } }) => ({
        width: "100%",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        display: "flex",
        background: "#FFFFFF",
        borderRadius: 8,
    }),
};

export const SsempMonitoring = ({ id, ssempData, glossaryData, mainData, translations }) => {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [showByActive, setShowByActive] = useState(true);
    const [hasArchived, setHasArchived] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [decrExpanded, setDecrExpanded] = useState(false)
    const [newItem, setNewItem] = useState(false);
    const [options, setOptions] = useState([]);
    const [editable, setEditable] = useState(false);
    const [deleteModal, setDeleteModal] = useState(null);
    const [moreInfo, setMoreInfo] = useState(null);
    const [tableHeaders, setTableHeaders] = useState([]);
    const [infoModal, setInfoModal] = useState(false);
    const [currentRow, setCurrentRow] = useState(null);
    const scrollRef = useRef(null);
    const user = useSelector((state) => state?.adbReducer?.user);
    const expandedRef = useRef(null);
    const [expanded, setExpanded] = useState(false);
    const localeLan = localStorage.getItem("local_lan" );
    moment.locale(localeLan);

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                let array = data?.map((item) => {
                    item.edit = false;
                    return item;
                });
                setEditable(false);
                setCurrentItem(null);
                setData(array);
                if (newItem) {
                    setNewItem(false);
                    let s = data?.filter((el, i) => i !== data.length - 1);
                    setData(s);
                }
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, [data]);

    useEffect(() => {
        if (ssempData?.ssemp_activity_monitoring) {
            let data = ssempData?.ssemp_activity_monitoring?.map((item) => ({
                id: item?.id,
                actual_date: item?.actual_date,
                completed: item?.completed,
                corresponding_submittal: item?.corresponding_submittal,
                description: item?.description,
                planned_date: item?.planned_date,
                project_id: id,
                archived: item?.archived,
                submitted: item?.submitted,
                old_report: item?.old_report,
                submitted_date: item?.submitted_date,
                comment: item?.comment,
                edit: false,
            }));
            let isHaveArchived = isEmpty(data.find(item => item.archived===1));
            setHasArchived(!isHaveArchived);
            if (showByActive===true && !isHaveArchived){
                const filteredData =  data.filter(item => item.archived ===0);
                setData(filteredData);
            } else setData(data);
            setEditable(false);
        }
    }, [ssempData?.ssemp_activity_monitoring, showByActive]);

    const [
        getCompanies,
        {
            data: companies,
            error: companiesError,
            isLoading: companiesIsLoading,
            isError: companiesIsError,
            isSuccess: companiesIsSuccess,
        },
    ] = useLazyGetAllCompaniesQuery();

    useEffect(() => {
        getCompanies({
            per_page: 1000,
        });
    }, []);

    useEffect(() => {
        if (mainData?.responsible_party) {
            let data = mainData?.responsible_party?.map((item) => {
                return {
                    value: item?.id,
                    label: item?.short_name,
                };
            });
            setOptions(data);
        }
    }, [mainData]);
    const [
        createSsempActivities,
        {
            data: createSsempActivitiesData,
            isError: createSsempActivitiesIsError,
            isLoading: createSsempActivitiesIsLoading,
            isSuccess: createSsempActivitiesIsSuccess,
            error: createSsempActivitiesError,
        },
    ] = useCreateSsempActivitiesMutation();

    const [
        updateSsempActivities,
        {
            data: updateSsempActivitiesData,
            isError: updateSsempActivitiesIsError,
            isLoading: updateSsempActivitiesIsLoading,
            isSuccess: updateSsempActivitiesIsSuccess,
            error: updateSsempActivitiesError,
        },
    ] = useUpdateSsempActivitiesMutation();

    const [
        deleteSsempActivities,
        {
            data: deleteSsempActivitiesData,
            isError: deleteSsempActivitiesIsError,
            isLoading: deleteSsempActivitiesIsLoading,
            isSuccess: deleteSsempActivitiesIsSuccess,
            error: deleteSsempActivitiesError,
        },
    ] = useDeleteSsempActivitiesMutation();

    const [errorHandler, setErrorHandler] = useErrorHandler([
        createSsempActivitiesError,
        updateSsempActivitiesError,
        deleteSsempActivitiesError,
    ]);

    useEffect(() => {
        if(translations){
            const headers = [
                {
                    tooltip_name: translations?.['environment_social_health_safety_and_security_obligations.ssemp_activity_monitoring.table_headers.description_of_ssemp_activities'],
                    tooltip_text: translations?.['environment_social_health_safety_and_security_obligations.ssemp_activity_monitoring.tooltips.description_of_ssemp_activities']
                },
                {
                    tooltip_name: translations?.['environment_social_health_safety_and_security_obligations.ssemp_activity_monitoring.table_headers.corresponding_submittal'],
                    tooltip_text: translations?.['environment_social_health_safety_and_security_obligations.ssemp_activity_monitoring.tooltips.corresponding_submittal']
                },
                {
                    tooltip_name: translations?.['environment_social_health_safety_and_security_obligations.ssemp_activity_monitoring.table_headers.planned_date'],
                    tooltip_text: translations?.['environment_social_health_safety_and_security_obligations.ssemp_activity_monitoring.tooltips.planned_date']
                },
                {
                    tooltip_name: translations?.['environment_social_health_safety_and_security_obligations.ssemp_activity_monitoring.table_headers.actual_date'],
                    tooltip_text: translations?.['environment_social_health_safety_and_security_obligations.ssemp_activity_monitoring.tooltips.actual_date']
                },
                {
                    tooltip_name: translations?.['environment_social_health_safety_and_security_obligations.ssemp_activity_monitoring.table_headers.current_status_or_comments'],
                    tooltip_text: translations?.['environment_social_health_safety_and_security_obligations.ssemp_activity_monitoring.tooltips.current_status_or_comments']
                },
                {
                    tooltip_name: translations?.['environment_social_health_safety_and_security_obligations.ssemp_activity_monitoring.table_headers.completed'],
                    tooltip_text: translations?.['environment_social_health_safety_and_security_obligations.ssemp_activity_monitoring.tooltips.completed']
                },
            ]
            setTableHeaders(
                headers.map((item) => ({
                    ...item,
                    showTooltip: false,
                }))
            );
        }
    }, [translations])

    useEffect(() => {
        if (glossaryData?.data?.length) {
            setMoreInfo(
                glossaryData?.data?.filter(
                    (el) => el?.section_name === "SSEMP Activity Monitoring"
                )[0]
            );
        }
        setData((old) => {
            let data = old.map((item) => ({ ...item, edit: false }));
            return data;
        });
        setEditable(false);
    }, [
        createSsempActivitiesIsSuccess,
        updateSsempActivitiesIsSuccess,
        deleteSsempActivitiesIsSuccess,
        glossaryData,
    ]);

    useEffect(() => {
        const d = () => {
            dispatch(setLoader(false));
        };

        createSsempActivitiesIsLoading ||
        updateSsempActivitiesIsLoading ||
        deleteSsempActivitiesIsLoading
            ? dispatch(setLoader(true))
            : d();
    }, [
        createSsempActivitiesIsLoading,
        updateSsempActivitiesIsLoading,
        deleteSsempActivitiesIsLoading,
    ]);

    const setterData = (input) => {
        const body = {
            corresponding_submittal: input?.corresponding_submittal,
            description: input?.description,
            planned_date: moment(input?.planned_date).format("YYYY-MM-DD"),
            project_id: id,
        };

        let s = [];

        if (currentItem?.completed) {
            body.actual_date = moment(input?.actual_date).format("YYYY-MM-DD");
        } else {
            delete body.actual_date;
        }

        for (let key in body) {
            if (!body[key] || body[key] === "Invalid date" || body[key] === "") {
                toast.error("Please enter " + key?.split("_")?.join(" "), {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
                s.push("false");
            }
        }

        if (s.length === 0) {
            return body;
        } else {
            return null;
        }
    };

    const submitHandler = (e, item) => {
        e.preventDefault();
        if (newItem) {
            const dataCreated = setterData(currentItem);
            if (dataCreated) {
                createSsempActivities({
                    ...dataCreated,
                    completed: currentItem?.completed,
                    comment: currentItem?.comment
                });
                setCurrentItem(null);
                setNewItem(false);
                setCurrentRow(null);
            }
        } else {
            const dataUpdated = setterData(currentItem);
            if (dataUpdated) {
                updateSsempActivities({
                    id: item?.id,
                    body: {
                        ...dataUpdated,
                        completed: currentItem?.completed,
                        comment: currentItem?.comment
                    },
                });
                setCurrentItem(null);
                setNewItem(false);
                setCurrentRow(null);
            }
        }
    };

    return (
        <div className="project__ssemp">
            {infoModal && (
                <MoreInfoModal
                    title={translations?.['environment_social_health_safety_and_security_obligations.ssemp_activity_monitoring.headers.ssemp_activity_monitoring']}
                    moreInfo={translations?.['environment_social_health_safety_and_security_obligations.ssemp_activity_monitoring.more_info.ssemp_activity_monitoring']}
                    close={()=> setInfoModal(false)}
                />
            )}

            {deleteModal?.show && (
                <DeleteModal
                    id={deleteModal?.id}
                    action={deleteSsempActivities}
                    setDeleteModal={setDeleteModal}
                    cencelText={translations?.['main.main.actions.cancel']}
                    deleteText={translations?.['main.main.actions.delete']}
                    modalTitle={translations?.['main.main.headers.confirm_your_action']}
                    clarifyingText={translations?.['main.main.titles.you_are_going_to_delete_this_row']}
                />
            )}
            <div className="project__block-subtitles">
                <div className="project__block-subtitle">{translations?.['environment_social_health_safety_and_security_obligations.ssemp_activity_monitoring.headers.ssemp_activity_monitoring']}</div>
                <div className="project__block__buttonsContainer">
                    {hasArchived && <button
                        onClick={() => setShowByActive(!showByActive)}
                        className="project__block-add-row">
                        <span> <img src="/assets/icons/blue-eye.svg" alt="plus"/></span>
                        <span>{showByActive ? translations?.['environment_social_health_safety_and_security_obligations.ssemp_activity_monitoring.actions.show_all']
                            : translations?.['environment_social_health_safety_and_security_obligations.ssemp_activity_monitoring.actions.show_active']}</span>
                    </button>}
                    {user?.permissions?.edit_project && (
                        <button
                            onClick={() => {
                                let randomId = Math.floor(Math.random() * 1000);
                                setCurrentRow(randomId);
                                scrollRef !== null && scrollRef.current.scrollIntoView();
                                if (!newItem) {
                                    setNewItem(true);
                                    setEditable(true);
                                    setData((old) => [
                                        ...old,
                                        {
                                            id: randomId,
                                            actual_date: null,
                                            completed: false,
                                            corresponding_submittal: "",
                                            description: "",
                                            planned_date: null,
                                            comment: "",
                                            edit: true,
                                        },
                                    ]);
                                    setCurrentItem({
                                        id: randomId,
                                        actual_date: null,
                                        completed: false,
                                        corresponding_submittal: "",
                                        description: "",
                                        planned_date: null,
                                        comment: "",
                                        edit: true,
                                    });
                                }
                            }}
                            className="project__block-add-row"
                        >
              <span>
                <img src="/assets/icons/blue-plus.svg" alt="plus" />
              </span>
                            <span>{translations?.['environment_social_health_safety_and_security_obligations.ssemp_activity_monitoring.actions.add_row']}</span>
                        </button>
                    )}
                </div>
            </div>
            <div
                className={`project__block-content permits ${
                    editable ? "editable" : ""
                }`}
            >
                <div
                    className={`project__block-table project-table ${
                        currentItem ? "n" : ""
                    }`}
                >
                    <div className={`table__header`} ref={scrollRef} style={{gridTemplateColumns: '19% 19% 15% 15% 19% 10%'}}>
                        {tableHeaders?.map((el, idx) => {
                            return (
                                <div
                                    key={idx}
                                    onMouseEnter={(event) => {
                                        setTableHeaders(
                                            tableHeaders.map((item) => ({
                                                ...item,
                                                showTooltip: el.tooltip_name === item.tooltip_name,
                                            }))
                                        );
                                    }}
                                    onMouseLeave={() => {
                                        setTableHeaders(
                                            tableHeaders.map((item) => ({
                                                ...item,
                                                showTooltip: false,
                                            }))
                                        );
                                    }}
                                    className="project-table__th"
                                >
                                    {el?.showTooltip ? (
                                        <span
                                            className={
                                                el?.tooltip_text?.length > 20
                                                    ? "lengthy"
                                                    : "not-lengthy"
                                            }
                                        >
                      {el?.tooltip_text}
                    </span>
                                    ) : (
                                        ""
                                    )}
                                    {el?.tooltip_name}
                                </div>
                            );
                        })}
                    </div>
                    <div className="project-table__body">
                        {data?.map((item, index) => (
                            <div key={index}>
                                {index !== 0 && currentRow === item?.id && (
                                    <div className={`table__header`} key={index} style={{gridTemplateColumns: '19% 19% 15% 15% 19% 10%'}}>
                                        {tableHeaders?.map((el, idx) => {
                                            return (
                                                <div key={idx} className="project-table__th">
                                                    {el?.showTooltip ? (
                                                        <span
                                                            className={
                                                                el?.tooltip_text?.length > 20
                                                                    ? "lengthy"
                                                                    : "not-lengthy"
                                                            }
                                                        >
                              {el?.tooltip_text}
                            </span>
                                                    ) : (
                                                        ""
                                                    )}
                                                    {el?.tooltip_name}
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                                <form
                                    className={`table__row ${
                                        item?.edit ? "edit" : currentItem ? "n" : ""
                                    } ${
                                        Boolean(item.submitted) || !user?.permissions?.edit_project
                                            ? "submitted"
                                            : ""
                                    }`}
                                    style={{gridTemplateColumns: '19% 19% 15% 15% 19% 10%'}}
                                    id={item?.id}
                                    key={item?.id}
                                    onSubmit={(e) => submitHandler(e, item)}
                                >
                                    <div className="project-table__td">
                                        <span>{item?.description}</span>
                                        <div className="project__block-input project-table__field">
                      <textarea
                          className={`${
                              expanded ? "project__block-expandedInput" : ""
                          }`}
                          ref={expandedRef}
                          onFocus={() => setExpanded(true)}
                          onBlur={() => setExpanded(false)}
                          maxLength={190}
                          type="text"
                          defaultValue={item?.description}
                          onChange={(e) => {
                              setCurrentItem((old) => ({
                                  ...old,
                                  description: e.target.value,
                              }));
                          }}
                      />
                                        </div>
                                    </div>
                                    <div className="project-table__td">
                                        <span>{item?.corresponding_submittal}</span>
                                        <div className="project__block-input project-table__field">
                      <textarea
                          maxLength={190}
                          type="text"
                          defaultValue={item?.corresponding_submittal}
                          onChange={(e) => {
                              setCurrentItem((old) => ({
                                  ...old,
                                  corresponding_submittal: e.target.value,
                              }));
                          }}
                      />
                                        </div>
                                    </div>
                                    <div className="project-table__td">
                    <span>
                      {moment(item?.planned_date).format("DD/MM/YYYY") ===
                      "Invalid date" ||
                      !moment(item?.planned_date).format("DD/MM/YYYY")
                          ? ""
                          : moment(item?.planned_date).format("DD/MM/YYYY")}
                    </span>
                                        <div className="project__block-item-calendar project-table__field">
                                            <CustomDatePicker
                                                portalId="root-portal"
                                                autoComplete="off"
                                                id="approved_date"
                                                placeholderText={translations?.['main.main.placeholder.dd_mm_yyyy']}
                                                dateFormat="dd/MM/yyyy"
                                                selected={
                                                    currentItem?.planned_date
                                                        ? moment(currentItem?.planned_date).toDate()
                                                        : ""
                                                }
                                                onChange={(date) => {
                                                    setCurrentItem((old) => ({
                                                        ...old,
                                                        planned_date: date,
                                                    }));
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="project-table__td">
                    <span>
                      {moment(item?.actual_date).format("DD/MM/YYYY") ===
                      "Invalid date" ||
                      !moment(item?.actual_date).format("DD/MM/YYYY")
                          ? ""
                          : moment(item?.actual_date).format("DD/MM/YYYY")}
                    </span>
                                        <div
                                            className={`project__block-item-calendar project-table__field ${
                                                currentItem?.completed
                                                    ? ""
                                                    : "project__actualDate-disabled"
                                            }`}
                                        >
                                            <CustomDatePicker
                                                portalId="root-portal"
                                                id="approved_date"
                                                autoComplete="off"
                                                placeholderText={translations?.['main.main.placeholder.dd_mm_yyyy']}
                                                dateFormat="dd/MM/yyyy"
                                                disabled={!currentItem?.completed}
                                                selected={
                                                    currentItem?.actual_date
                                                        ? moment(currentItem?.actual_date).toDate()
                                                        : ""
                                                }
                                                onChange={(date) => {
                                                    setCurrentItem((old) => ({
                                                        ...old,
                                                        actual_date: date,
                                                    }));
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="project-table__td">
                                        <span>{item?.comment}</span>

                                        <div className="project__block-input project-table__field">
                      <textarea
                          className={`${
                              decrExpanded ? "project__block-expandedInput" : ""
                          }`}
                          maxLength={190}
                          type="text"
                          defaultValue={item?.comment}
                          onFocus={() => setDecrExpanded(true)}
                          onBlur={() => setDecrExpanded(false)}
                          onChange={(e) => {
                              setCurrentItem((old) => ({
                                  ...old,
                                  comment: e.target.value,
                              }));
                          }}
                      />
                                        </div>
                                    </div>
                                    <div className="project-table__td">
                                        <Switch
                                            value={item?.completed}
                                            id={index + 1100}
                                            disabled={item?.edit ? false : true}
                                            checked={
                                                item?.edit ? currentItem?.completed : item?.completed
                                            }
                                            handler={(value) => {
                                                setCurrentItem((old) => ({
                                                    ...old,
                                                    completed: value,
                                                    actual_date: value ? currentItem?.actual_date : null,
                                                }));
                                            }}
                                        />
                                    </div>
                                    <div className="project__block-btns">
                                        {currentItem ? (
                                            <div
                                                className="project__block-cancel"
                                                onClick={() => {
                                                    setCurrentRow(null);
                                                    let array = data?.map((item) => {
                                                        item.edit = false;
                                                        return item;
                                                    });
                                                    setCurrentItem(null);
                                                    setData(array);
                                                    if (newItem) {
                                                        setNewItem(false);
                                                        let s = data?.filter(
                                                            (el, i) => i !== data.length - 1
                                                        );
                                                        setData(s);
                                                    }
                                                }}
                                            >
                                                <img src="/assets/icons/close-mark.svg" alt="close" />
                                                {translations?.['environment_social_health_safety_and_security_obligations.ssemp_activity_monitoring.actions.cancel']}
                                            </div>
                                        ) : (
                                            <div
                                                className="project__block-delete"
                                                onClick={() => {
                                                    setCurrentRow(null);
                                                    setDeleteModal({ id: item?.id, show: true });
                                                }}
                                            >
                                                <img src="/assets/icons/trash-icon.svg" alt="arrow" />
                                                {translations?.['environment_social_health_safety_and_security_obligations.ssemp_activity_monitoring.actions.delete']}
                                            </div>
                                        )}
                                        {currentItem ? (
                                            <button className="project__block-save">
                                                <img src="/assets/icons/save-arrow.svg" alt="arrow" />
                                                {translations?.['environment_social_health_safety_and_security_obligations.ssemp_activity_monitoring.actions.save']}
                                            </button>
                                        ) : (
                                            <div
                                                onClick={() => {
                                                    setCurrentRow(item?.id);
                                                    setCurrentItem(item);
                                                    setEditable(true);
                                                    setData(() => {
                                                        let array = data?.map((elem) => {
                                                            if (elem?.id === item?.id) {
                                                                elem.edit = true;
                                                            }
                                                            return elem;
                                                        });
                                                        return array;
                                                    });
                                                }}
                                                className="project__block-update"
                                            >
                                                {translations?.['environment_social_health_safety_and_security_obligations.ssemp_activity_monitoring.actions.update']}
                                            </div>
                                        )}
                                    </div>
                                </form>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
