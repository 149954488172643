import React, {useState} from 'react';
import AxelButton from "../../UI/AxelButton";
import './index.scss';

const Authenticator = (props) => {
    const {
        description, extraInfo, btnTitle, qrData, verifyFunc
    } = props;
    const [copySuccess, setCopySuccess] = useState(false);
    const  removeBackslashes = (data) => {
        return data.replace(/\\/g, '');
    }

    const copyToClipboard = async (key) => {
        try {
            await navigator.clipboard.writeText(key);
            setCopySuccess(true);
            setTimeout(() => {
                setCopySuccess(false);
            }, 2000);
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };

    return(
        <div>
            <div className="modal-text">
                {description}
            </div>
            <div className="qrContainer">
                <div className="qrContainer__qrImg">
                    <img
                        src={`data:image/svg+xml;utf8,${encodeURIComponent(removeBackslashes(qrData?.qr_image))}`}
                        alt='qr-code'
                    />
                </div>
                <div className="qrContainer__qrKeyWrapper">
                   <span className="qrContainer__key">
                        {qrData?.secret}
                   </span>
                    <button onClick={()=>copyToClipboard(qrData?.secret)}>
                        <img
                            src="/assets/icons/copy.svg"
                            alt="copy icon"
                        />
                    </button>
                    {copySuccess && <span  className="qrContainer__copied">Copied!</span>}
                </div>
            </div>
            <div className="modal-extraInfoText">
                {extraInfo}
            </div>
            <AxelButton
                handler={verifyFunc}
                className="modal-sure"
            >
                {btnTitle}
            </AxelButton>
        </div>
    )
}

export default Authenticator;