import React, { useEffect } from 'react'
import TabComponent from './TabComponent'
import moment from 'moment';
import { useState } from 'react';

export default function SectionModalComponent({data, fullData, closeSection, translations}) {
  const [paid, setPaid] = useState([])
  const [datas, setDatas] = useState({})
  const [escrow, setEscrow] = useState([])
  const [unpaid, setUnpaid] = useState([])


  const filterTier = (type) => data?.tiers?.filter(item => item?.tier === type)

  useEffect(() => {
    const foundation = filterTier('Foundation')[0]
    const towerErection = filterTier('Tower Erection')[0]
    const lineStringing = filterTier('Line Stringing')[0]
    setDatas({...data, tiers: [foundation, towerErection, lineStringing ]})

    const paidStatuses = [foundation?.paid, towerErection?.paid,lineStringing?.paid];
    const escrowStatuses = [foundation?.escrow, towerErection?.escrow, lineStringing?.escrow];
    const unpaidStatuses = [foundation?.unpaid, towerErection?.unpaid, lineStringing?.unpaid];
    setPaid(paidStatuses)
    setEscrow(escrowStatuses)
    setUnpaid(unpaidStatuses)
  }, [data])

  return (
    <div className="larp-db__section">
      <div className="larp-db__section-content">
        <div className="larp-db__section-header">
          <div></div>
          <div className="larp-db__section-close" onClick={closeSection}>
            <img src="/assets/icons/close.svg" alt="close" />
          </div>
        </div>
        <div className="larp-db__section-hr"></div>
        <div className="larp-db__section-hero">
          <div className="larp-db__section-title">{datas?.section}</div>
        </div>
        <div className="larp-db__section-description">
          <h4>{translations?.['titles.description']}</h4>
          <div className="larp-db__section-subtitle">{datas?.description}</div>
        </div>
        <div className="larp-db__section-date">
          <div>{translations?.['titles.report_date']}</div>
          <div>{datas?.report_date}</div>
        </div>
        

        <div className="larp-db__section-bar">
          <div className="bar-content">
            <div className="bar-background">
              <div className="bar-line" >100%</div>
              <div className="bar-line" >80%</div>
              <div className="bar-line" >60%</div>
              <div className="bar-line" >40%</div>
              <div className="bar-line" >20%</div>
              <div className="bar-line" >0%</div>
            </div>
            <div className="bar-bars">
              {datas?.tiers?.map((item, index) => (
                <div className="bar-bar" key={index}>
                  {(item?.unpaid !== 0 && item?.unpaid !== '0%') && (
                    <div className="bar-bar__content unpaid" style={{height: item?.height_unpaid}}>{ item?.unpaid }</div>
                  )}
                  {(item?.escrow !== '0%' && item?.escrow !== 0) && (
                    <div className="bar-bar__content escrow" style={{height: item?.height_escrow}}>{ item?.escrow }</div>
                  )}
                  {(item?.paid !== '0%' && item?.paid !== 0) && (
                    <div className="bar-bar__content paid" style={{height: item?.height_paid}}>{ item?.paid }</div>
                  )}
                  {item?.escrow === '0%' && item?.paid === '0%' && item?.unpaid === 0 && (
                    <div className="bar-bar__content gray" style={{height: '100%'}}>0%</div>
                  )}
                </div>
              ))}
            </div>
            <div className="bar-titles">
              <div className="bar-title">{translations?.['titles.foundation']}</div>
              <div className="bar-title">{translations?.['titles.tower_erection']}</div>
              <div className="bar-title">{translations?.['titles.line_stringing']}</div>
            </div>
          </div>
        </div>

        <div className="larp-db__section-table section">
          <div className="larp-db__section-row">
            <div className="larp-db__section-cell th">{translations?.['titles.tier']}</div>
            <div className="larp-db__section-cell th">{translations?.['titles.foundation']}</div>
            <div className="larp-db__section-cell th">{translations?.['titles.tower_erection']}</div>
            <div className="larp-db__section-cell th">{translations?.['titles.line_stringing']}</div>
          </div>

          <div className="larp-db__section-row">
            <div className="larp-db__section-cell td" data-title="Tier">{translations?.['titles.paid']}</div>
            {paid?.map((item, index) => (
              <div className="larp-db__section-cell td" data-title="Line Stringing" key={index}>{ item }</div>
            ))}
          </div>
          <div className="larp-db__section-row">
            <div className="larp-db__section-cell td" data-title="Tier">{translations?.['titles.escrowed']}</div>
            {escrow?.map((item, index) => (
              <div className="larp-db__section-cell td" data-title="Line Stringing" key={index}>{ item }</div>
            ))}
          </div>
          <div className="larp-db__section-row">
            <div className="larp-db__section-cell td" data-title="Tier">{translations?.['titles.unpaid']}</div>
            {unpaid?.map((item, index) => (
              <div className="larp-db__section-cell td" data-title="Line Stringing" key={index}>{ item === 0 ? '0%' : item }</div>
            ))}
          </div>
        </div>

      </div>
      <div className="larp-db__section-b" onClick={closeSection}></div>
    </div>
  )
}
