import React, {useEffect, useState} from 'react';
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import {setLoader} from "../../store/slice/dashboard";
import './index.scss';

const GenerateQuarterlyReport = ({reportData, reportId, translations, selectedSections}) => {
    const dispatch = useDispatch();
    const [isGenerated, setIsGenerated] = useState(reportData?.document?.is_generate);
    const [generateStatus, setGenerateStatus] = useState(reportData?.document);
    const lanCode = localStorage.getItem("local_lan" )

    const generateReport = () => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ` + localStorage.getItem("user_token"),
            "AppLocale": `${lanCode ? lanCode : 'en'}`
        };

        dispatch(setLoader(true));

        fetch (
            `${process.env.REACT_APP_API_URL}/quarterly-report/generate-document/${reportId ? reportId : reportData?.id}`,
            {
                headers: headers,
                redirect: "follow",
                method: "POST",
                body: JSON.stringify(selectedSections),
            }
        )
            .then((res) => res.json())
            .then(data => {
                setIsGenerated(true)
                dispatch(setLoader(false));
                data?.errors ? toast.error(data?.message) : toast.success(data?.message);
            })
            .catch((error) => {
                toast.error(error);
                dispatch(setLoader(false));
            });
    };

    const downloadReport = () => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ` + localStorage.getItem("user_token"),
            "AppLocale": `${lanCode ? lanCode : 'en'}`
        };
        dispatch(setLoader(true));

        fetch(`${process.env.REACT_APP_API_URL}/quarterly-report/download/${reportId? reportId : reportData?.id}`, {
            headers: headers,
            redirect: "follow",
            responseType: "blob",
        })
            .then(( data ) => {
                data.blob().then((fileBlob) => {
                    const url = URL.createObjectURL(fileBlob);
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = `quarterly_report_${reportData?.report_name}.docx`;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    URL.revokeObjectURL(url);
                    dispatch(setLoader(false));
                })

            })
            .catch((e) => {
                toast.error(
                    e?.data?.message ? e?.data?.message : "Something went wrong.",
                    {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    }
                );
                dispatch(setLoader(false));
            });
    };

    const checkGenerateReportStatus = () => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ` + localStorage.getItem("user_token"),
            "AppLocale": `${lanCode ? lanCode : 'en'}`
        };
        fetch (
            `${process.env.REACT_APP_API_URL}/quarterly-report/generate-status/${reportId ? reportId : reportData?.id}`,
            {
                headers: headers,
            }
        )
            .then((res) => res.json())
            .then(data => {
                setIsGenerated(data?.data?.is_generate);
                setGenerateStatus(data?.data)
                if (!data?.is_generate) {
                    setIsGenerated(data?.data?.is_generate);
                    setGenerateStatus(data?.data)
                }
            })
            .catch((error) => {
                toast.error(error);
            });
    };

    useEffect(()=>{
        if (reportData) {
            setIsGenerated(reportData?.document?.is_generate);
            setGenerateStatus(reportData?.document)
        }
    }, [reportData]);

    useEffect(() => {
        if (generateStatus?.is_generate || isGenerated){
            const intervalId = setInterval(() => {
                checkGenerateReportStatus();
            }, 10000);
            return () => clearInterval(intervalId);
        }
    }, [generateStatus, isGenerated]);

    return (
        <div className="generateReportContainer">
            {!isGenerated &&
                <button className="generateReportContainer__info erOverview_flex"
                        onClick={generateReport}>
                    <img src="/assets/icons/clipboard.svg" alt="document icon"/>
                    <pre>{translations?.['index.index.tooltips.generate_report_to_download']}</pre>
                </button>
            }
            {(isGenerated) &&
                <span className="generateReportContainer__status erOverview_flex">
                    <img src="/assets/icons/refresh.svg" alt="refresh icon"/>
                    <pre>
                        {translations?.['index.index.titles.in_progress']}
                    </pre>
                </span>
            }
            {(generateStatus?.generate_date  && !isGenerated) &&
                <span className="generateReportContainer__status erOverview_flex">
                    {generateStatus?.generate_date}
                </span>
            }
            <button onClick={downloadReport}
                    className={`${(!generateStatus?.is_generate && generateStatus?.generate_date && !isGenerated) ? 
                        'generateReportContainer__activeBtn': 'generateReportContainer__inactiveBtn' } 
                        erOverview_flex`}
                    disabled={(!(!generateStatus?.is_generate && generateStatus?.generate_date && !isGenerated))}
            >
                {translations?.['index.index.actions.download_report']}
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14 10V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V10" stroke="#767676" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M4.66663 6.66602L7.99996 9.99935L11.3333 6.66602" stroke="#767676" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M8 10V2" stroke="#767676" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <span className="generateReportContainer__tooltip">
                    {!isGenerated && translations?.['index.index.tooltips.generate_report_to_download']}
                    {(isGenerated && !generateStatus?.generate_date) && translations?.['index.index.tooltips.report_in_process']}
                </span>
            </button>

        </div>
    )
}

export default GenerateQuarterlyReport;