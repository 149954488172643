import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const adbApi = createApi({
  reducerPath: "adbApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}`,
    keepUnusedDataFor: 0.0001,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("user_token");
      const lanCode = localStorage.getItem("local_lan" )
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
        headers.set("AppLocale", `${lanCode ? lanCode : 'en'}`)
        headers.set("time", String(Date.now()));
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({

     // Login

    login: builder.mutation({
      query: (body) => ({
        url: `login`,
        method: "POST",
        body: body,
      }),
      keepUnusedDataFor: 0.0001,
    }),
    logout: builder.query({
      query: () => ({
        url: "logout",
        method: "GET",
      }),
      invalidatesTags: ["Logout"],
    }),
    getLoginPage: builder.query({
      query: () => ({
        url: "pages/auth/login",
        method: "GET",
      }),
      invalidatesTags: ["LoginPage"],
    }),
    getNotReceiveCodeText: builder.query({
      query: ({type, mfa_token}) => ({
        url: `pages/auth/mfa-not-receive-code/${type}?mfa_token=${mfa_token}`,
        method: "GET",
      }),
      invalidatesTags: ["EnterCode"],
    }),
    getUser: builder.mutation({
      query: () => ({
        url: `account/index`,
        method: "GET",
      }),
      keepUnusedDataFor: 0.0001,
    }),
    getMFAuthPage: builder.query({
      query: (mfa_token) => ({
        url: `pages/auth/mfa-request?mfa_token=${mfa_token}`,
        method: "GET",
      }),
      invalidatesTags: ["MFAuthPage"],
    }),
    mFAuth: builder.query({
      query: ({type, mfa_token}) => ({
        url: `mfa-request?type=${type}&mfa_token=${mfa_token}`,
        method: "POST",
      }),
      invalidatesTags: ["MFAuth"],
    }),
    getMFAConfirmPage: builder.query({
      query: (mfa_token) => ({
        url: `pages/auth/mfa-confirm?mfa_token=${mfa_token}`,
        method: "GET",
      }),
      invalidatesTags: ["MFAConfirmPage"],
    }),
    getRememberDevicePage: builder.query({
      query: (mfa_token) => ({
        url: `pages/auth/mfa-remember-device?mfa_token=${mfa_token}`,
        method: "GET",
      }),
      invalidatesTags: ["RememberDevicePage"],
    }),

    //  Settings page

    getSettingsPage: builder.query({
      query: () => ({
        url: `pages/account/settings`,
        method: "GET",
      }),
      invalidatesTags: ["SettingsPage"],
    }),
    getAccountSettings: builder.query({
      query: () => ({
        url: `account/settings`,
        method: "GET",
      }),
      invalidatesTags: ["AccountSettings"],
    }),
    allowToMFA: builder.mutation({
      query: (type) => ({
        url: `account/settings/toggle-mfa/${type}`,
        method: "POST",
      }),
      keepUnusedDataFor: 0.0001,
    }),
    getAuthenticatorQr: builder.query({
      query: () => ({
        url: `account/settings/authenticator/edit`,
        method: "GET",
      }),
      invalidatesTags: ["AuthQrCode"],
    }),
    confirmAuthQrCode: builder.mutation({
      query: (body) => ({
        url: `account/settings/authenticator/confirm`,
        method: "POST",
        body: body,
      }),
      keepUnusedDataFor: 0.0001,
    }),
    deleteAuthenticator: builder.mutation({
      query: () => ({
        url: `account/settings/authenticator/delete`,
        method: "DELETE",
      }),
      invalidatesTags: ["AuthQrCode"],
    }),
    updatePhoneNumber: builder.mutation({
      query: (body) => ({
        url: `account/settings/phone/edit`,
        method: "POST",
        body: body,
      }),
      keepUnusedDataFor: 0.0001,
    }),
    confirmPhoneNumberCode: builder.mutation({
      query: (body) => ({
        url: `account/settings/phone/confirm`,
        method: "POST",
        body: body,
      }),
      keepUnusedDataFor: 0.0001,
    }),
    deletePhoneNumber: builder.mutation({
      query: (body) => ({
        url: `account/settings/phone/delete`,
        method: "DELETE",
        body: body,
      }),
      invalidatesTags: ["PhoneNumber"],
    }),
    updateEmail: builder.mutation({
      query: (body) => ({
        url: `account/settings/email/edit`,
        method: "POST",
        body: body,
      }),
      keepUnusedDataFor: 0.0001,
    }),
    confirmEmailCode: builder.mutation({
      query: (body) => ({
        url: `account/settings/email/confirm`,
        method: "POST",
        body: body,
      }),
      keepUnusedDataFor: 0.0001,
    }),
    mFConfirm: builder.query({
      query: ({code, type, mfa_token}) => ({
        url: `mfa-confirm?code=${code}&mfa_token=${mfa_token}&type=${type}`,
        method: "POST",
      }),
      invalidatesTags: ["MFConfirm"],
    }),
    getAuditLogsPage: builder.query({
      query: () => ({
        url: `pages/audit-logs`,
        method: "GET",
      }),
      invalidatesTags: ["AuditLogs"],
    }),
    getAccessActivity: builder.query({
      query: (params) => ({
          url: `audit-log/user-access-activity?
        ${params?.per_page ? `per_page=${params.per_page}` : ""}
        ${params?.page ? `&page=${params.page}` : ""}
        ${params?.search ? `&search=${params.search}` : ""}
        ${params?.date_from ? `&date_from=${params.date_from}` : ""}
        ${params?.date_to ? `&date_to=${params.date_to}` : ""}
        ${params?.event ? `&event=${params.event}` : ""}`,
          method: "GET",
        }),
      providesTags: ["AuditLogs"],
    }),
    getProjectDataInput: builder.query({
      query: (params) => ({
        url: `audit-log/report-data-change?
        ${params?.per_page ? `per_page=${params.per_page}` : ""}
        ${params?.page ? `&page=${params.page}` : ""}
        ${params?.search ? `&search=${params.search}` : ""}
        ${params?.date_from ? `&date_from=${params.date_from}` : ""}
        ${params?.date_to ? `&date_to=${params.date_to}` : ""}
        ${params?.table ? `&table=${params.table}` : ""}
        ${params?.event ? `&event=${params.event}` : ""}
        ${params?.project ? `&project=${params.project}` : ""}`,
        method: "GET",
      }),
      providesTags: ["AuditLogs"],
    }),
    getSecondaryTables: builder.query({
      query: (params) => ({
        url: `audit-log/secondary-tables?
        ${params?.per_page ? `per_page=${params.per_page}` : ""}
        ${params?.page ? `&page=${params.page}` : ""}
        ${params?.search ? `&search=${params.search}` : ""}
        ${params?.date_from ? `&date_from=${params.date_from}` : ""}
        ${params?.date_to ? `&date_to=${params.date_to}` : ""}
        ${params?.event ? `&event=${params.event}` : ""}
        ${params?.table ? `&table=${params.table}` : ""}`,
        method: "GET",
      }),
      providesTags: ["AuditLogs"],
    }),
    getReportAndCMD: builder.query({
      query: (params) => ({
        url: `audit-log/report-download?
        ${params?.per_page ? `per_page=${params.per_page}` : ""}
        ${params?.page ? `&page=${params.page}` : ""}
        ${params?.search ? `&search=${params.search}` : ""}
        ${params?.date_from ? `&date_from=${params.date_from}` : ""}
        ${params?.date_to ? `&date_to=${params.date_to}` : ""}`,
        method: "GET",
      }),
      providesTags: ["AuditLogs"],
    }),
    getSystemErrors: builder.query({
      query: (params) => ({
        url: `/audit-log/system-errors?
        ${params?.per_page ? `per_page=${params.per_page}` : ""}
        ${params?.page ? `&page=${params.page}` : ""}
        ${params?.search ? `&search=${params.search}` : ""}
        ${params?.date_from ? `&date_from=${params.date_from}` : ""}
        ${params?.date_to ? `&date_to=${params.date_to}` : ""}
        ${params?.error_code ? `&error_code=${params.error_code}` : ""}`,
        method: "GET",
      }),
      providesTags: ["AuditLogs"],
    }),
    generateError: builder.mutation({
      query: (body) => ({
        url: `audit-log/system-errors/generate`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["AuditLogs"],
    }),

    // Language page
    getLanguages: builder.query({
      query: () => ({
        url: "languages",
        method: "GET",
      }),
      providesTags: ["Languages"],
    }),
    createLanguage: builder.mutation({
      query: (body) => ({
        url: "languages",
        method: "POST",
        body
      }),
      headers: {
        "Content-Type": "multipart/form-data",
      },
      invalidatesTags: ["Languages"],
    }),
    updateLanguage: builder.mutation({
      query: ({id, body}) => ({
        url: `languages/${id}`,
        method: "POST",
        body
      }),
      invalidatesTags: ["Languages"],
    }),
    deleteLanguage: builder.mutation({
      query:  (id) => ({
        url: `languages/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Languages"],
    }),
    getChangeLocal: builder.query({
      query: (locale) => ({
        url: `languages/change-language/${locale}`,
        method: "GET",
      }),
      providesTags: ["Languages"],
    }),

    getContacts: builder.query({
      query: () => ({
        url: "contact-admins",
        method: "GET",
      }),
      providesTags: ["Contacts"],
    }),
    getProject: builder.query({
      query: (id) => ({
        url: `projects/${id}`,
        method: "GET",
      }),
      providesTags: ["Project"],
      keepUnusedDataFor: 0.0001,
    }),
    getCountries: builder.query({
      query: () => ({
        url: "countries",
        method: "GET",
      }),
    }),
    getOrganizations: builder.query({
      query: () => ({
        url: "organization-types",
        method: "GET",
      }),
    }),
    createContact: builder.mutation({
      query: (body) => ({
        url: "contact-admins",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Contacts"],
    }),
    updateContact: builder.mutation({
      query: ({ id, body }) => ({
        url: `contact-admins/${id}`,
        method: "POST",
        body: {
          country_id: body.country_id,
          organization_type_id: body.organization_type_id,
          first_name: body.first_name,
          last_name: body.last_name,
          phone: body.phone,
          email: body.email,
          company_name: body.company_name,
        },
      }),
      invalidatesTags: ["Contacts"],
    }),
    deleteContact: builder.mutation({
      query: (id) => ({
        url: `contact-admins/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Contacts"],
    }),
    getProjects: builder.query({
      query: () => ({
        url: "projects",
        method: "GET",
      }),
      providesTags: ["Projects"],
      keepUnusedDataFor: 0.001,
    }),
    createProject: builder.mutation({
      query: (body) => ({
        url: "projects",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Projects"],
    }),
    updateProject: builder.mutation({
      query: ({ body, id }) => ({
        url: `projects/${id}`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Projects"],
    }),
    deleteProject: builder.mutation({
      query: ( id ) => ({
        url: `projects/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Projects"],
    }),
    searchCompanies: builder.mutation({
      query: (text) => ({
        url: `companies/search?query=${text}`,
        method: "GET",
      })
    }),
    searchCountries: builder.mutation({
      query: (text) => ({
        url: `countries/search?query=${text}`,
        method: "GET",
      })
    }),
    searchUsers: builder.mutation({
      query: (text) => ({
        url: `users/search?query=${text}`,
        method: "GET",
      })
    }),
    // updateProject: builder.mutation({
    //   query: ({ id, body }) => ({
    //     url: `projects/${id}`,
    //     method: "POST",
    //     body: {
    //       contract_title: body?.contract_title,
    //       contract_project_summary: body?.contract_project_summary,
    //       contract_location: body?.contract_location,
    //     },
    //   }),
    //   invalidatesTags: ["Projects"],
    // }),
    forgotPassword: builder.query({
      query: (email) => ({
        url: "forgot",
        method: "POST",
        body: {
          email,
        },
      }),
    }),
    resetPassword: builder.query({
      query: (body) => ({
        url: "reset",
        method: "POST",
        body: body,
      }),
    }),
    getKeyData: builder.query({
      query: (id) => ({
        url: `key-data/${id}`,
        method: "GET",
      }),
    }),
    getAllContacts: builder.query({
      query: () => ({
        url: "contacts",
      }),
    }),
    updateProjectKeyData: builder.query({
      query: (body) => {
        return {
          url: `contracts/${body.id}`,
          method: "POST",
          body: body.formData,
        };
      },
    }),
    getAllRegions: builder.query({
      query: (params) => ({
        url: `regions?${params?.per_page ? `per_page=${params?.per_page}` : ""}${
          params.page ? `&page=${params.page}` : ""
        }`,
      }),
      providesTags: ["Regions"],
    }),
    createRegion: builder.mutation({
      query: (body) => ({
        url: "regions",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Regions"],
    }),
    updateRegion: builder.mutation({
      query: ({ id, body }) => ({
        url: `regions/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Regions"],
    }),
    deleteRegions: builder.mutation({
      query: (id) => ({
        url: `regions/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Regions"],
    }),
    getAllCountries: builder.query({
      query: (params) => ({
        url: `countries?${
          params?.per_page ? `per_page=${params?.per_page}` : ""
        }${params.page ? `&page=${params.page}` : ""}`,
      }),
      providesTags: ["Countries"],
    }),
    createCountry: builder.mutation({
      query: (body) => ({
        url: "countries",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Countries"],
    }),
    updateCountry: builder.mutation({
      query: ({ id, body }) => ({
        url: `countries/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Countries"],
    }),
    deleteCountry: builder.mutation({
      query: (id) => ({
        url: `countries/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Countries"],
    }),
    getAllOrganizationType: builder.query({
      query: (params) => ({
        url: `organization-types?${
          params?.per_page ? `per_page=${params?.per_page}` : ""
        }${params.page ? `&page=${params.page}` : ""}`,
      }),
      providesTags: ["OrganizationType"],
    }),
    createOrganizationType: builder.mutation({
      query: (body) => ({
        url: "organization-types",
        method: "POST",
        body,
      }),
      invalidatesTags: ["OrganizationType"],
    }),
    updateOrganizationType: builder.mutation({
      query: ({ id, body }) => ({
        url: `organization-types/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["OrganizationType"],
    }),
    deleteOrganizationType: builder.mutation({
      query: (id) => ({
        url: `organization-types/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["OrganizationType"],
    }),
    getAllSectors: builder.query({
      query: (params) => ({
        url: `sector?${params?.per_page ? `per_page=${params?.per_page}` : ""}${
          params?.page ? `&page=${params?.page}` : ""
        }`,
      }),
      providesTags: ["Sectors"],
    }),
    createSector: builder.mutation({
      query: (body) => ({
        url: "sector",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Sectors"],
    }),
    updateSector: builder.mutation({
      query: ({ id, body }) => ({
        url: `sector/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Sectors"],
    }),
    deleteSector: builder.mutation({
      query: (id) => ({
        url: `sector/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Sectors"],
    }),
    getAllCompanies: builder.query({
      query: (params) => ({
        url: `companies?${
          params?.per_page ? `per_page=${params?.per_page}` : ""
        }${params?.page ? `&page=${params?.page}` : ""}`,
        method: "GET",
      }),
      providesTags: ["Companies"],
    }),
    deleteCompany: builder.mutation({
      query: (id) => ({
        url: `companies/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Companies"],
    }),
    createCompany: builder.mutation({
      query: (body) => ({
        url: `companies`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Companies"],
    }),
    updateCompany: builder.mutation({
      query: ({ id, body }) => ({
        url: `companies/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Companies", "Project"],
    }),
    getAllProjectTypes: builder.query({
      query: () => ({
        url: "project-types",
        method: "GET",
      }),
      providesTags: ["ProjectTypes"],
    }),
    getStatisticsPage: builder.query({
      query: () => ({
        url: "pages/statistics",
        method: "GET",
      }),
      invalidatesTags: ["StatisticsPage"],
    }),
    getSavedFilters: builder.query({
      query: () => ({
        url: "statistics-filter",
        method: "GET",
      }),
      providesTags: ["Filters"],
    }),
    createFilter: builder.mutation({
      query: (params) => ({
        url: `statistics-filter?name=${params?.name} 
          ${params?.region_id ? `&region_id=${params?.region_id}` : ""}
          ${params?.country_id ? `&country_id=${params?.country_id}` : ""}
          ${params?.sector_id ? `&sector_id=${params?.sector_id}` : ""}
          ${params?.company_id ? `&company_id=${params?.company_id}` : ""}
          ${params?.default ? `&default=${params?.default}` : ""}`,
        method: "POST",
      }),
      providesTags: ["SaveFilter"],
    }),
    getProjectsToStatistic: builder.query({
      query: (params) => ({
          url: `statistics/filter?
          ${params?.region_id ? `region_id=${params?.region_id}` : ""}
          ${params?.country_id ? `&country_id=${params?.country_id}` : ""}
          ${params?.sector_id ? `&sector_id=${params?.sector_id}` : ""}
          ${params?.company_id ? `&company_id=${params?.company_id}` : ""}`,
          method: "GET",
        }),
      providesTags: ["StatisticsFilter"],
    }),
    getStatistics: builder.query({
      query: (params) => ({
        url: `statistics?${params?.date ? `date=${params?.date}` : ""}&${params?.projects}`,
        method: "GET",
      }),
      providesTags: ["Statistics"],
    }),
    getContactById: builder.query({
      query: (id) => ({
        url: `contact-admins/${id}`,
      }),
    }),
    getSecondaryContactById: builder.query({
      query: (id) => ({
        url: `contacts-by-type/${id}`,
      }),
    }),
    updateProjectApprovedChanges: builder.mutation({
      query: ({ id, body }) => ({
        url: `project-approved-changes/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    updateProjectExecutiveSummary: builder.mutation({
      query: ({ id, body }) => ({
        url: `project-executive-summary/${id}`,
        method: "POST",
        body,
      }),
      // invalidatesTags: ["SecondaryContacts"],
    }),
    getProjectApprovals: builder.query({
      query: (id) => ({
        url: `project-approvals/${id}`,
      }),
      providesTags: ["ProjectApprovals"],
    }),
    createProjectApprovals: builder.mutation({
      query: (body) => ({
        url: `project-approvals`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["ProjectApprovals"],
    }),
    updateProjectApprovals: builder.mutation({
      query: ({ id, body }) => ({
        url: `project-approvals/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["ProjectApprovals"],
    }),
    deleteProjectApprovals: builder.mutation({
      query: (id) => ({
        url: `project-approvals/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ProjectApprovals"],
    }),
    getProjectLarp: builder.query({
      query: (id) => ({
        url: `larp-processing/${id}`,
      }),
      providesTags: ["ProjectLarp"],
    }),
    createProjectLarp: builder.mutation({
      query: (body) => ({
        url: `larp-processing`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["ProjectLarp"],
    }),
    updateProjectLarp: builder.mutation({
      query: ({ id, body }) => ({
        url: `larp-processing/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["ProjectLarp"],
    }),
    deleteProjectLarp: builder.mutation({
      query: (id) => ({
        url: `larp-processing/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ProjectLarp"],
    }),
    getProjectReportRequirments: builder.query({
      query: (id) => ({
        url: `report-requirements/${id}`,
      }),
      providesTags: ["ProjectReportRequirments"],
    }),
    createProjectReportRequirments: builder.mutation({
      query: (body) => ({
        url: `report-requirements`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["ProjectReportRequirments"],
    }),
    updateProjectReportRequirments: builder.mutation({
      query: ({ id, body }) => ({
        url: `report-requirements/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["ProjectReportRequirments"],
    }),
    deleteProjectReportRequirments: builder.mutation({
      query: (id) => ({
        url: `report-requirements/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ProjectReportRequirments"],
    }),
    getProjectContractActivities: builder.query({
      query: (id) => ({
        url: `contract-activities/${id}`,
      }),
      providesTags: ["ProjectContractActivities"],
    }),
    createProjectContractActivities: builder.mutation({
      query: (body) => ({
        url: `contract-activities`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["ProjectContractActivities"],
    }),
    updateProjectContractActivities: builder.mutation({
      query: ({ id, body }) => ({
        url: `contract-activities/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["ProjectContractActivities"],
    }),
    deleteProjectContractActivities: builder.mutation({
      query: (id) => ({
        url: `contract-activities/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ProjectContractActivities"],
    }),
    getProjectMobilizationActivities: builder.query({
      query: (id) => ({
        url: `mobilization-activities/${id}`,
      }),
      providesTags: ["ProjectMobilizationActivities"],
    }),
    createProjectMobilizationActivities: builder.mutation({
      query: (body) => ({
        url: `mobilization-activities`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["ProjectMobilizationActivities"],
    }),
    updateProjectMobilizationActivities: builder.mutation({
      query: ({ id, body }) => ({
        url: `mobilization-activities/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["ProjectMobilizationActivities"],
    }),
    deleteProjectMobilizationActivities: builder.mutation({
      query: (id) => ({
        url: `mobilization-activities/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ProjectMobilizationActivities"],
    }),
    getProjectResidualActions: builder.query({
      query: (id) => ({
        url: `residual-actions/${id}`,
      }),
      providesTags: ["ProjectResidualActions"],
    }),
    createProjectResidualActions: builder.mutation({
      query: (body) => ({
        url: `residual-actions`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["ProjectResidualActions"],
    }),
    updateProjectResidualActions: builder.mutation({
      query: ({ id, body }) => ({
        url: `residual-actions/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["ProjectResidualActions", "Project"],
    }),
    deleteProjectResidualActions: builder.mutation({
      query: (id) => ({
        url: `residual-actions/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ProjectResidualActions"],
    }),
    updateReportCover: builder.mutation({
      query: ({ id, body }) => ({
        url: `projects/${id}/report-cover-update`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    updateDisputesOverview: builder.mutation({
      query: ({ id, body }) => ({
        url: `projects/${id}/update-basics`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    createProjectDispute: builder.mutation({
      query: (body) => ({
        url: `disputes`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    updateProjectDispute: builder.mutation({
      query: ({ id, body }) => ({
        url: `disputes/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    deleteProjectDispute: builder.mutation({
      query: (id) => ({
        url: `disputes/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Project"],
    }),
    getProjectsGoodsEquipment: builder.query({
      query: (id) => ({
        url: `goods-equipment/${id}`,
      }),
      providesTags: ["GoodsEquipment"],
    }),
    updateProjectGoodsEquipment: builder.mutation({
      query: ({ id, body }) => ({
        url: `goods-equipment/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    createProjectGoodsEquipment: builder.mutation({
      query: (body) => ({
        url: `goods-equipment`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    deleteProjectGoodsEquipment: builder.mutation({
      query: (id) => ({
        url: `goods-equipment/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Project"],
    }),
    updateActualProjectPayments: builder.mutation({
      query: ({ id, body }) => ({
        url: `actual-project-payments/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project", "PlannedProjectPayments"],
    }),
    createActualProjectPayments: builder.mutation({
      query: (body) => ({
        url: `actual-project-payments`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    deleteActualProjectPayments: builder.mutation({
      query: (id) => ({
        url: `actual-project-payments/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Project"],
    }),
    updateFinancialManagementOverview: builder.mutation({
      query: ({ id, body }) => ({
        url: `projects/${id}/update-basics`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    createSubcontractorPayments: builder.mutation({
      query: (body) => ({
        url: `subcontractor-payments`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    deleteSubcontractorPayments: builder.mutation({
      query: (id) => ({
        url: `subcontractor-payments/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Project"],
    }),
    updateSubcontractorPayments: builder.mutation({
      query: ({ id, body }) => ({
        url: `subcontractor-payments/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    updatePlannedProjectPayments: builder.mutation({
      query: ({ id, body }) => ({
        url: `planned-project-payments/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project", "PlannedProjectPayments"],
    }),

    // Glossary Pages
    getGlossary: builder.query({
      query: () => ({
        url: `glossary`,
      }),
      providesTags: ["Glossary"],
    }),
    updateGlossary: builder.mutation({
      query: (body) => ({
        url: `glossary/update`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Glossary"],
    }),


    getTooltipsBySection: builder.query({
      query: (id) => ({
        url: `glossary/all-tooltips-by-section/${id}`,
      }),
      providesTags: ["GlossaryTooltipsBySection"],
    }),

    getAllGlossary: builder.query({
      query: () => ({
        url: `glossary/all`,
      }),
      providesTags: ["AllGlossary"],
    }),

    getPlannedPayments: builder.query({
      query: (id) => ({
        url: `planned-project-payments/${id}`,
      }),
      providesTags: ["PlannedProjectPayments"],
    }),
    createRiskManagement: builder.mutation({
      query: (body) => ({
        url: `project-risks`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    deleteRiskManagement: builder.mutation({
      query: (id) => ({
        url: `project-risks/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Project"],
    }),
    updateRiskManagement: builder.mutation({
      query: ({ id, body }) => ({
        url: `project-risks/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    createEngineeringDesignDocuments: builder.mutation({
      query: (body) => ({
        url: `engineering-design-document`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    deleteEngineeringDesignDocuments: builder.mutation({
      query: (id) => ({
        url: `engineering-design-document/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Project"],
    }),
    updateEngineeringDesignDocuments: builder.mutation({
      query: ({ id, body }) => ({
        url: `engineering-design-document/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    createLevel2Program: builder.mutation({
      query: (body) => ({
        url: `project-level`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    updateLevel2Program: builder.mutation({
      query: ({ id, body }) => ({
        url: `project-level/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    deleteLevel2Program: builder.mutation({
      query: (id) => ({
        url: `project-level/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Project"],
    }),
    updateProgramProgress: builder.mutation({
      query: ({ id, body }) => ({
        url: `program-progress/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    createVariationRegister: builder.mutation({
      query: (body) => ({
        url: `variation-register`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    updateVariationRegister: builder.mutation({
      query: ({ id, body }) => ({
        url: `variation-register/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    deleteVariationRegister: builder.mutation({
      query: (id) => ({
        url: `variation-register/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Project"],
    }),
    createClaimRegister: builder.mutation({
      query: (body) => ({
        url: `claim-register`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    createExtensionOfTime: builder.mutation({
      query: (body) => ({
        url: `eot`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    updateClaimRegister: builder.mutation({
      query: ({ id, body }) => ({
        url: `claim-register/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    updateExtensionOfTime: builder.mutation({
      query: ({ id, body }) => ({
        url: `eot/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    deleteClaimRegister: builder.mutation({
      query: (id) => ({
        url: `claim-register/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Project"],
    }),
    updateProgramOverview: builder.mutation({
      query: ({ id, body }) => ({
        url: `projects/${id}/update-basics`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    getProgramOverview: builder.query({
      query: (id) => ({
        url: `project-program-overview/${id}`,
      }),
      providesTags: ["ProgramOverview"],
    }),
    updateCriticalPath: builder.mutation({
      query: ({ id, body }) => ({
        url: `projects/${id}/update-basics`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    getCriticalPath: builder.query({
      query: (id) => ({
        url: `project-critical-path-table/${id}`,
      }),
      providesTags: ["ProgramOverview"],
    }),
    getProgramProgressChart: builder.query({
      query: (id) => ({
        url: `program-progress/${id}`,
      }),
      providesTags: ["ProgramProgressChart"],
    }),
    getSubmittedReports: builder.query({
      query: (params) => ({
        url: `/report/submitted-reports?${
          params?.per_page ? `per_page=${params?.per_page}` : ""
        }${params.page ? `&page=${params.page}` : ""}${
          params.submitted_by ? `&submitted_by=${params.submitted_by}` : ""
        }${params.date_from ? `&date_from=${params.date_from}` : ""}${
          params.date_to ? `&date_to=${params.date_to}` : ""
        }`,
        // url: `/report/submitted-reports?${params.submitted_by ?
        //   `submitted_by=${params.submitted_by}` : ''}&${params.date_from && params.date_to ? `date_from=${params.date_from}&date_to=${params.date_to}` : ''}${params?.per_page ? `per_page=${params?.per_page}` : ''}${params.page ? `&page=${params.page}` : ''}`,
      }),
      providesTags: ["SubmittedReports"],
    }),
    createOneMonthAhead: builder.mutation({
      query: (body) => ({
        url: `one-month-look-ahead`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    updateOneMonthAhead: builder.mutation({
      query: ({ id, body }) => ({
        url: `one-month-look-ahead/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    deleteOneMonthAhead: builder.mutation({
      query: (id) => ({
        url: `one-month-look-ahead/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Project"],
    }),
    updateProjectStatus: builder.mutation({
      query: ({ id, body }) => ({
        url: `projects/${id}/update-basics`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    createCompletionOfWork: builder.mutation({
      query: (body) => ({
        url: `completion-of-work`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    updateCompletionOfWork: builder.mutation({
      query: ({ id, body }) => ({
        url: `completion-of-work/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    deleteCompletionOfWork: builder.mutation({
      query: (id) => ({
        url: `completion-of-work/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Project"],
    }),
    createSsempActivities: builder.mutation({
      query: (body) => ({
        url: `ssemp-activities`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    updateSsempActivities: builder.mutation({
      query: ({ id, body }) => ({
        url: `ssemp-activities/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    deleteSsempActivities: builder.mutation({
      query: (id) => ({
        url: `ssemp-activities/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Project"],
    }),
    createSocialMonitoring: builder.mutation({
      query: (body) => ({
        url: `social-safeguard`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    updateSocialMonitoring: builder.mutation({
      query: ({ id, body }) => ({
        url: `social-safeguard/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    deleteSocialMonitoring: builder.mutation({
      query: (id) => ({
        url: `social-safeguard/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Project"],
    }),
    createHealthMonitoring: builder.mutation({
      query: (body) => ({
        url: `health-safety`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    updateHealthMonitoring: builder.mutation({
      query: ({ id, body }) => ({
        url: `health-safety/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    deleteHealthMonitoring: builder.mutation({
      query: (id) => ({
        url: `health-safety/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Project"],
    }),
    createComplaints: builder.mutation({
      query: (body) => ({
        url: `complaints`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    updateComplaints: builder.mutation({
      query: ({ id, body }) => ({
        url: `complaints/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    deleteComplaints: builder.mutation({
      query: (id) => ({
        url: `complaints/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Project"],
    }),
    createSecurityMonitoring: builder.mutation({
      query: (body) => ({
        url: `security-monitoring`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    updateSecurityMonitoring: builder.mutation({
      query: ({ id, body }) => ({
        url: `security-monitoring/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    deleteSecurityMonitoring: builder.mutation({
      query: (id) => ({
        url: `security-monitoring/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Project"],
    }),
    updateApplicableSettings: builder.mutation({
      query: ({ id, body }) => ({
        url: `applicable-settings/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    updateGenderTracking: builder.mutation({
      query: ({ id, body }) => ({
        url: `projects/${id}/update-basics`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    getPermissions: builder.query({
      query: () => ({
        url: `permissions`,
      }),
      providesTags: ["Permissions"],
    }),
    getUsers: builder.query({
      query: (params) => ({
        url: `users?${params?.per_page ? `per_page=${params?.per_page}` : ""}${
          params.page ? `&page=${params.page}` : ""
        }`,
      }),
      providesTags: ["Users"],
    }),
    getUsersNotLazy: builder.query({
      query: () => ({
        url: `users`,
      }),
      providesTags: ["UsersNotLazy"],
    }),
    createUsers: builder.mutation({
      query: (body) => ({
        url: `user`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Users"],
    }),
    updateUsers: builder.mutation({
      query: ({ id, body }) => {
        return ({
          url: `user/${id}`,
          method: "POST",
          body,
        })
      },
      invalidatesTags: ["Users"],
    }),
    updateUsersMFA: builder.mutation({
      query: (body ) => ({
        url: `user/toggle-mfa`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Users"],
    }),
    deleteUsers: builder.mutation({
      query: (id) => ({
        url: `user/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Users"],
    }),
    getContractType: builder.query({
      query: (params) => ({
        url: `project-types?${
          params?.per_page ? `per_page=${params?.per_page}` : ""
        }${params.page ? `&page=${params.page}` : ""}`,
      }),
      providesTags: ["ContractTypes"],
    }),
    getSector: builder.query({
      query: () => ({
        url: `sector`,
      }),
      providesTags: ["Sector"],
    }),
    createContractTypes: builder.mutation({
      query: (body) => ({
        url: `project-types`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["ContractTypes"],
    }),
    updateContractTypes: builder.mutation({
      query: ({ id, body }) => ({
        url: `project-types/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["ContractTypes"],
    }),
    deleteContractTypes: builder.mutation({
      query: (id) => ({
        url: `project-types/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ContractTypes"],
    }),
    sendCode: builder.mutation({
      query: (body) => ({
        url: `reset-access`,
        method: "POST",
        body,
      }),
    }),
    getCreatePasswordForm: builder.query({
      query: (token) => ({
        url: `user/form-password/${token}`,
      }),
      providesTags: ["PasswordForm"],
    }),
    createPassword: builder.mutation({
      query: (body) => ({
        url: `user/create-password`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    getReportDates: builder.query({
      query: (id) => ({
        url: `report/submitted/${id}`,
      }),
      providesTags: ["ReportDates"],
    }),
    updateHealthSafetyStatistics: builder.mutation({
      query: ({ id, body }) => ({
        url: `projects/${id}/update-basics`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    deletePhoto: builder.mutation({
      query: (id) => ({
        url:  `site-photo/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Project"],
    }),
    deleteReportCoverPhoto: builder.mutation({
      query: (body) => ({
        url: `projects/${body?.id}/report-cover-delete-image/${body?.type}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Project"],
    }),
    getAllProjects: builder.query({
      query: (params) => ({
        url: `projects?${params?.search?.length > 0 ? `search=${params?.search}&` : ''}all_projects=${typeof params?.all_projects === 'number' ? params?.all_projects : 1 }&${params?.per_page ? `per_page=${params?.per_page}` : ""}${
          params.page ? `&page=${params.page}` : ""
        }`,
      }),
      providesTags: ["Projects"],
    }),
    getAllReports: builder.query({
      query: () => ({
        url: `quarterly-report`,
      }),
      providesTags: ["Reports"],
    }),
    createAdb: builder.mutation({
      query: (body) => ({
        url: `contact-details`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    updateAdb: builder.mutation({
      query: ({ id, body }) => ({
        url: `contact-details/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    deleteAdb: builder.mutation({
      query: (id) => ({
        url: `contact-details/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Project"],
    }),
    createContractor: builder.mutation({
      query: (body) => ({
        url: `contact-details`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    updateContractor: builder.mutation({
      query: ({ id, body }) => ({
        url: `contact-details/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    deleteContractor: builder.mutation({
      query: (id) => ({
        url: `contact-details/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Project"],
    }),
    createDisputeBoardMember: builder.mutation({
      query: (body) => ({
        url: `contact-details`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    updateDisputeBoardMember: builder.mutation({
      query: ({ id, body }) => ({
        url: `contact-details/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    updateLarpTopLevel: builder.mutation({
      query: ({ id, body }) => ({
        url: `larp/top-level/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    deleteDisputeBoardMember: builder.mutation({
      query: (id) => ({
        url: `contact-details/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Project"],
    }),
    createEngineer: builder.mutation({
      query: (body) => ({
        url: `contact-details`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    updateEngineer: builder.mutation({
      query: ({ id, body }) => ({
        url: `contact-details/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    deleteEngineer: builder.mutation({
      query: (id) => ({
        url: `contact-details/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Project"],
    }),
    createExecutingAgency: builder.mutation({
      query: (body) => ({
        url: `contact-details`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    updateExecutingAgency: builder.mutation({
      query: ({ id, body }) => ({
        url: `contact-details/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    deleteExecutingAgency: builder.mutation({
      query: (id) => ({
        url: `contact-details/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Project"],
    }),
    createImplementingAgency: builder.mutation({
      query: (body) => ({
        url: `contact-details`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    updateImplementingAgency: builder.mutation({
      query: ({ id, body }) => ({
        url: `contact-details/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    deleteImplementingAgency: builder.mutation({
      query: (id) => ({
        url: `contact-details/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Project"],
    }),
    createOther: builder.mutation({
      query: (body) => ({
        url: `contact-details`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    updateOther: builder.mutation({
      query: ({ id, body }) => ({
        url: `contact-details/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    updateContactName: builder.mutation({
      query: ({ id, body }) => ({
        url: `projects/${id}/update-basics`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    deleteOther: builder.mutation({
      query: (id) => ({
        url: `contact-details/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Project"],
    }),
    deleteProjectImage: builder.mutation({
      query: (id) => ({
        url: `projects/remove-preview-image/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Project"],
    }),
    larpSectionCreate: builder.mutation({
      query: (body) => ({
        url: `larp/section`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Project"],
    }),
    larpSectionUpdate: builder.mutation({
      query: (data) => ({
        url: `larp/section/${data.id}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Project"],
    }),
    larpSectionDelete: builder.mutation({
      query: (id) => ({
        url: `larp/section/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Project"],
    }),
    getDashboard: builder.query({
      query: (id) => ({
        url: `projects/dashboard/${id}`,
      }),
      providesTags: ["Dashboard"],
    }),
    getRoles: builder.query({
      query: () => ({
        url: `users/roles`,
      }),
      providesTags: ["Roles"],
    }),
    getProjectSettings: builder.query({
      query: (id) => ({
        url: `projects/settings/${id}`,
      }),
      providesTags: ["ProjectSettings"],
    }),
    setLarpSectionTier: builder.mutation({
      query: (body) => ({
        url: `larp/tier/${body?.id}`,
        body,
        method: "POST",
      }),
      // invalidatesTags: ["Project"],
    }),
    setLarpAffected: builder.mutation({
      query: (body) => ({
        url: `larp/affected-persons${!body?.tier_id ? `/${body.id}` : ""}`,
        body,
        method: "POST",
      }),
    }),
    deleteLarpAffected: builder.mutation({
      query: (id) => ({
        url: `larp/affected-persons/${id}`,
        method: "DELETE",
      }),
    }),
    duplicatePersons: builder.mutation({
      query: (body) => ({
        url: `larp/affected-persons-duplicate`,
        body,
        method: "POST",
      }),
      invalidatesTags: ["Project"],
    }),
    getAllSections: builder.mutation({
      query: () => ({
        url: `report/sections`,
        method: "GET",
      }),
    }),
    getAllTemplates: builder.mutation({
      query: () => ({
        url: `cmp-templates?per_page=1000`,
        method: "GET",
      }),
    }),
    downloadTemplate: builder.query({
      query: (id) => ({
        url: `cmp-templates/download/${id}`,
        method: "GET",
      }),
    }),
    deleteTemplate: builder.mutation({
      query: (id) => ({
        url: `cmp-templates/${id}`,
        method: "DELETE",
      }),
    }),
    createTemplate: builder.mutation({
      query: (formData) => ({
        url: `cmp-templates`,
        method: "POST",
        body: formData,
        formData: true,
      }),
    }),
    updateTemplate: builder.mutation({
      query: ({ formData, id }) => ({
        url: `cmp-templates/${id}`,
        method: "POST",
        body: formData,
        formData: true,
      }),
    }),
    getContractManagementGlossary: builder.mutation({
      query: () => ({
        url: `glossary/section/39`,
        method: "GET",
      }),
    }),
    getLarpDashboard: builder.query({
      query: (id) => ({
        url: `larp-dashboard/${id}`,
        method: "GET",
      })
    }),
    getAllProjectDocuments: builder.mutation({
      query: ({ projectId, url = '' }) => ({
        url:  url ? `${url}/${projectId}` : `project-documents/${projectId}`,
        method: 'GET',
      }),
      invalidatesTags: ['Project'],
    }),
    deleteDocument: builder.mutation({
      query: ({id, url}) => ({
        url: `${url}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Project'],
    }),
    createDocument: builder.mutation({
      query: ({ body, url = '' }) => ({
        url: url ,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Project'],
    }),
    updateDocument: builder.mutation({
      query: ({ id, name, url = '' }) => ({
        url:  `${url}/${id}`,
        method: 'POST',
        body: { name },
      }),
      invalidatesTags: ['Project'],
    }),

    // Notifications Page
    getNotificationPage: builder.query({
      query: () => ({
        url: "pages/notification-center",
        method: "GET",
      }),
      invalidatesTags: ["NotificationPage"],
    }),
    getAccountProjects: builder.query({
      query: () => ({
        url: "account/projects",
        method: "GET",
      }),
      invalidatesTags: ["AccountProjects"],
    }),
    setIsUserToggleNotify: builder.mutation({
      query: () => ({
        url: "account/toggle-notify",
        method: "POST",
      }),
      invalidatesTags: ["NotificationPage"],
    }),
    setProjectToggleNotify: builder.mutation({
      query: (projectId) => ({
        url: `notification-center/project/toggle-notify/${projectId}`,
        method: "POST",
      }),
      invalidatesTags: ["NotificationPage"],
    }),
    getAllNotifications: builder.query({
      query: () => ({
        url: `notification-center`,
        method: "GET",
      }),
      invalidatesTags: ["NotificationPage"],
    }),
    getNotifByProject: builder.query({
      query: (projectId) => ({
        url: `notification-center/project/${projectId}`,
        method: "GET",
      }),
      invalidatesTags: ["NotificationPage"],
    }),
    updateSectionNotify: builder.mutation({
    query: (sectionId) =>({
      url: `notification-center/project/section/toggle-notify/${sectionId}`,
      method: "POST",
    }),
      invalidatesTags: ["NotificationPage"],
    }),
    updateSectionItemNotify: builder.mutation({
      query: (notificationId) =>({
        url: `notification-center/project/section/notification/toggle-notify/${notificationId}`,
        method: "POST",
      }),
      invalidatesTags: ["NotificationPage"],
    }),
    updateNotificationTriggers: builder.mutation({
      query: ({notificationId, body}) =>({
        url: `notification-center/project/section/notification/triggers-update/${notificationId}`,
        method: "POST",
         body
      }),
      invalidatesTags: ["NotificationPage"],
    }),

    copyNotifications: builder.mutation({
      query: ({projectId, body}) =>({
        url: `notification-center/project/copy/${projectId}`,
        method: "POST",
        body
      })
    }),


    // Help Page
    getAllTrainingVideosPage: builder.query({
      query: () => ({
        url: "pages/help",
        method: "GET",
      }),
      invalidatesTags: ["trainingVideo"],
    }),
    getAllTrainingVideos: builder.query({
      query: () => ({
        url: "help-videos",
        method: "GET",
      }),
      invalidatesTags: ["trainingVideo"],
    }),
    createTrainingVideo: builder.mutation({
      query: (formData) => ({
          url: "help-videos",
          method: "POST",
          body: formData,
          formData: true,
        })
    }),
    updateTrainingVideo: builder.mutation({
      query: ({ formData, id }) => ({
        url: `help-videos/${id}`,
        method: "POST",
        body: formData,
        formData: true,
      }),
    }),
    deleteTrainingVideo: builder.mutation({
      query: (id) => ({
        url: `help-videos/${id}`,
        method: "DELETE",
      }),
    }),

  //   Quarterly Reports Page
    getQuarterlyReportPage: builder.query({
      query: () => ({
        url: "pages/quarterly-report/list",
        method: "GET",
      }),
      invalidatesTags: ["QuarterlyReport"],
    }),
    getQuarterlyReportEditPage: builder.query({
      query: () => ({
        url: "pages/quarterly-report/edit",
        method: "GET",
      }),
      invalidatesTags: ["QuarterlyReport"],
    }),
    getQuarterlyReportPhotographs: builder.query({
      query: () => ({
        url: "pages/quarterly-report/edit",
        method: "GET",
      }),
      invalidatesTags: ["QuarterlyReport"],
    }),

    // Site Photographs
    getQuarterlyPhotographs: builder.query({
      query: (reportId) => ({
        url: `quarterly-report/site-photographs/list/${reportId}`,
        method: "GET",
      }),
      invalidatesTags: ["QuarterlyPhotographs"],
    }),
    createPhoto: builder.mutation({
      query: (body) => ({
        url: 'quarterly-report/site-photographs/create',
        method: "POST",
        body
      }),
      invalidatesTags: ["QuarterlyPhotographs"],
    }),
    updateSitePhotograph: builder.mutation({
      query: ({id, body}) => ({
        url: `quarterly-report/site-photographs/update/${id}`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["QuarterlyPhotographs"],
    }),
    deleteSitePhotographs: builder.mutation({
      query: ( id) => ({
        url: `quarterly-report/site-photographs/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["QuarterlyPhotographs"],
    }),


    getQuarterlyReportDetailsPage: builder.query({
      query: () => ({
        url: "pages/quarterly-report/details",
        method: "GET",
      }),
      invalidatesTags: ["QuarterlyReport"],
    }),

  //   Quarterly Report Section List
    getAllQuarterlyReports: builder.query({
      query: () => ({
        url: "quarterly-report",
        method: "GET",
      }),
      invalidatesTags: ["QuarterlyReport"],
    }),
    getQuarterlyReportSections: builder.query({
      query: () => ({
        url: "quarterly-report/sections",
        method: "GET",
      }),
      invalidatesTags: ["QuarterlyReport"],
    }),
    getQuarterlyReportProjects: builder.query({
      query: () => ({
        url: "quarterly-report/projects",
        method: "GET",
      }),
      invalidatesTags: ["QuarterlyReport"],
    }),
    getQuarterlyReportInfo: builder.query({
      query: (quarterly_report_id) => ({
        url: `quarterly-report/info/${quarterly_report_id}`,
        method: "GET",
      }),
      invalidatesTags: ["QuarterlyReport"],
    }),
    createQuarterlyReport: builder.mutation({
      query: (body) => ({
        url: 'quarterly-report',
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["QuarterlyReport"],
    }),
    updateQuarterlyReport: builder.mutation({
      query: ({body, quarterlyReportId}) => ({
        url: `quarterly-report/${quarterlyReportId}`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["QuarterlyReport"],
    }),
    deleteQuarterlyReport: builder.mutation({
      query: (quarterlyReportId) => ({
        url: `quarterly-report/${quarterlyReportId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["QuarterlyReport"],
    }),
    updateQuarterlyReportCover: builder.mutation({
      query: ({body, reportId}) => ({
        url: `quarterly-report/report-cover/${reportId}`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["QuarterlyReport"],
    }),
    deleteQuarterlyReportCover: builder.mutation({
      query: ( reportId) => ({
        url: `quarterly-report/report-cover/${reportId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["QuarterlyReport"],
    }),
    getQuarterlyReportDetails: builder.query({
      query: (reportId) => ({
        url: `quarterly-report/details/${reportId}`,
        method: "GET",
      }),
      invalidatesTags: ["QuarterlyReport"],
    }),
    submitQuarterlyReport: builder.mutation({
      query: ( reportId) => ({
        url: `quarterly-report/submit/${reportId}`,
        method: "POST",
      }),
      invalidatesTags: ["QuarterlyReport"],
    }),

    duplicateQuarterlyReport: builder.mutation({
      query: ({reportId, body}) => ({
        url: `quarterly-report/duplicate/${reportId}`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["QuarterlyReport"],
    }),
    // Financing Plan
    getFinancingPlanSources: builder.query({
      query: () => ({
        url: 'quarterly-report/financing-plan/sources',
        method: "GET",
      }),
      invalidatesTags: ["FinancingPlan"],
    }),
    getFinancingPlan: builder.query({
      query: (reportId) => ({
        url: `quarterly-report/financing-plan/list/${reportId}`,
        method: "GET",
      }),
      invalidatesTags: ["FinancingPlan"],
    }),
    createFinancingPlan: builder.mutation({
      query: (body) => ({
        url: 'quarterly-report/financing-plan/create',
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["FinancingPlan"],
    }),
    updateFinancingPlan: builder.mutation({
      query: ({id, body}) => ({
        url: `quarterly-report/financing-plan/update/${id}`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["FinancingPlan"],
    }),
    deleteFinancingPlan: builder.mutation({
      query: ( id) => ({
        url: `quarterly-report/financing-plan/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["FinancingPlan"],
    }),
    // Loan Utilization
    getLoanUtilization: builder.query({
      query: (reportId) => ({
        url: `quarterly-report/loan-utilization/list/${reportId}`,
        method: "GET",
      }),
      invalidatesTags: ["LoanUtilization"],
    }),
    updateLoanUtilization: builder.mutation({
      query: ({id, body}) => ({
        url: `quarterly-report/loan-utilization/update/${id}`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["LoanUtilization"],
    }),
    // Project Contract Award
    getProjectContractAward: builder.query({
      query: (reportId) => ({
        url: `quarterly-report/project-contract-award/list/${reportId}`,
        method: "GET",
      }),
      invalidatesTags: ["ProjectContractAward"],
    }),
    updateProjectContractAward: builder.mutation({
      query: ({id, body}) => ({
        url: `quarterly-report/project-contract-award/update/${id}`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["ProjectContractAward"],
    }),
    // DevelopmentObjectives
    getDevObjectives: builder.query({
      query: (reportId) => ({
        url: `quarterly-report/development-objectives/details/${reportId}`,
        method: "GET",
      }),
      invalidatesTags: ["DevelopmentObjectives"],
    }),
    updateDevObjectives: builder.mutation({
      query: ({id, body}) => ({
        url: `quarterly-report/development-objectives/update/${id}`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["DevelopmentObjectives"],
    }),

    // Implementation Progress
    getImplementationProg: builder.query({
      query: (reportId) => ({
        url: `quarterly-report/implementation-progress/details/${reportId}`,
        method: "GET",
      }),
      invalidatesTags: ["ImplementationProgress"],
    }),
    updateImplementationProg: builder.mutation({
      query: ({id, body}) => ({
        url: `quarterly-report/implementation-progress/update/${id}`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["ImplementationProgress"],
    }),
    getProgressPerOutputs: builder.query({
      query: (reportId) => ({
        url: `quarterly-report/progress-per-outputs/list/${reportId}`,
        method: "GET",
      }),
      invalidatesTags: ["ImplementationProgress"],
    }),
    createProgressPerOutputs: builder.mutation({
      query: (body) => ({
        url: 'quarterly-report/progress-per-outputs/create',
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["ImplementationProgress"],
    }),
    updateProgressPerOutputs: builder.mutation({
      query: ({id, body}) => ({
        url: `quarterly-report/progress-per-outputs/update/${id}`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["ImplementationProgress"],
    }),
    deleteProgressPerOutputs: builder.mutation({
      query: ( id) => ({
        url: `quarterly-report/progress-per-outputs/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ImplementationProgress"],
    }),
    getMajorProblemsWithIp: builder.query({
      query: ({reportId, url}) => ({
        url: `quarterly-report/${url}/list/${reportId}`,
        method: "GET",
      }),
      invalidatesTags: ["ImplementationProgress"],
    }),
    createMajorProblemsWithIp: builder.mutation({
      query: ({body, url}) => ({
        url: `quarterly-report/${url}/create`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["ImplementationProgress"],
    }),
    updateMajorProblemsWithIp: builder.mutation({
      query: ({id, body, url}) => ({
        url: `quarterly-report/${url}/update/${id}`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["ImplementationProgress"],
    }),
    deleteMajorProblemsWithIp: builder.mutation({
      query: ({id, url}) => ({
        url: `quarterly-report/${url}/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ImplementationProgress"],
    }),
    // Covenants
    // Financial Covenants

    getFinancialCovenants: builder.query({
      query: (reportId) => ({
        url: `quarterly-report/covenants/financial-covenants/${reportId}`,
        method: "GET",
      }),
      invalidatesTags: ["FinancialCovenants"],
    }),
    updateFinancialCovenants: builder.mutation({
      query: ({id, body}) => ({
        url: `quarterly-report/covenants/financial-covenants/${id}`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["FinancialCovenants"],
    }),
    getSubmissionsAudited: builder.query({
      query: ({reportId, url}) => ({
        url: `${url}/${reportId}`,
        method: "GET",
      }),
      invalidatesTags: ["FinancialCovenants"],
    }),
    updateSubmissionsAudited: builder.mutation({
      query: ({id, body, url}) => ({
        url: `${url}/${id}`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["FinancialCovenants"],
    }),

    // Ratios
    getFinancialRatios: builder.query({
      query: (reportId) => ({
        url: `quarterly-report/covenants/financial-ratios/list/${reportId}`,
        method: "GET",
      }),
      invalidatesTags: ["FinancialCovenants"],
    }),
    createFinancialRatios: builder.mutation({
      query: (body) => ({
        url: 'quarterly-report/covenants/financial-ratios/create',
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["FinancialCovenants"],
    }),
    updateFinancialRatios: builder.mutation({
      query: ({id, body}) => ({
        url: `quarterly-report/covenants/financial-ratios/update/${id}`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["FinancialCovenants"],
    }),
    deleteFinancialRatios: builder.mutation({
      query: ( id) => ({
        url: `quarterly-report/covenants/financial-ratios/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["FinancialCovenants"],
    }),
    // Sub Ratios
    getFinancialSubRatios: builder.query({
      query: (financialReportId) => ({
        url: `quarterly-report/covenants/financial-ratios/ratios/list/${financialReportId}`,
        method: "GET",
      }),
      invalidatesTags: ["FinancialCovenants"],
    }),
    createFinancialSubRatios: builder.mutation({
      query: (body) => ({
        url: 'quarterly-report/covenants/financial-ratios/ratios/create',
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["FinancialCovenants"],
    }),
    updateFinancialSubRatios: builder.mutation({
      query: ({id, body}) => ({
        url: `quarterly-report/covenants/financial-ratios/ratios/update/${id}`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["FinancialCovenants"],
    }),
    deleteFinancialSubRatios: builder.mutation({
      query: ( id) => ({
        url: `quarterly-report/covenants/financial-ratios/ratios/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["FinancialCovenants"],
    }),

    // Covenants
    getCovenants: builder.query({
      query: ({reportId, type}) => ({
        url: `quarterly-report/covenants/covenant/list/${reportId}?type=${type}`,
        method: "GET",
      }),
      invalidatesTags: ["Covenants"],
    }),
    createCovenants: builder.mutation({
      query: (body) => ({
        url: 'quarterly-report/covenants/covenant/create',
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Covenants"],
    }),
    updateCovenants: builder.mutation({
      query: ({id, body}) => ({
        url: `quarterly-report/covenants/covenant/update/${id}`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Covenants"],
    }),
    deleteCovenants: builder.mutation({
      query: ( id) => ({
        url: `quarterly-report/covenants/covenant/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Covenants"],
    }),
    // Site Documents
    getQRDocuments: builder.query({
      query: (reportId) => ({
        url: `quarterly-report/document/list/${reportId}`,
        method: "GET",
      }),
      invalidatesTags: ["QuarterlyDocument"],
    }),
    downloadQRDocuments: builder.query({
      query: (id) => ({
        url: `/quarterly-report/document/download/${id}`,
        method: "GET",
      }),
      invalidatesTags: ["QuarterlyDocument"],
    }),


    // CMP page
    getCMPPage: builder.query({
      query: () => ({
        url: 'pages/cmp',
        method: "GET",
      }),
      invalidatesTags: ["CMP"]
    }),

    // project-list page
    getProjectListPage: builder.query({
      query: () => ({
        url: 'pages/project-list',
        method: "GET",
      }),
      invalidatesTags: ["projectList"]
    }),

    // larp-dashboard translations
    getLarpDashboardTranslations: builder.query({
      query: () => ({
        url: `pages/dashboard/larp`,
        method: "GET",
      })
    }),

    // project-dashboard translations
    getProjectDashboardTranslations: builder.query({
      query: () => ({
        url: `pages/dashboard/project`,
        method: "GET",
      })
    }),

    // dashboard index translations
    getDashboardIndexTranslations: builder.query({
      query: () => ({
        url: `pages/dashboard/index`,
        method: 'GET',
      })
    }),

    // report-details translations
    getReportsDetailsTranslations: builder.query({
      query: () => ({
        url: `pages/report-details`,
        method: "GET",
      })
    }),

    // reporting translations
    getReportingTranslations: builder.query({
      query: () => ({
        url: `pages/reporting`,
        method: "GET",
      })
    }),

    // log out translations
    getLogOutTranslations: builder.query({
      query: () => ({
        url: `pages/auth/logout`,
        method: "GET",
      })
    }),

    // sidebar translations
    getSidebarTranslations: builder.query({
      query:() => ({
        url: `pages/menu`,
        method: "GET",
      })
    })

  }),
});

export const {

  // projectListPage
  useLazyGetProjectListPageQuery,
  useLazyGetCMPPageQuery,

  useLoginMutation,
  useLazyLogoutQuery,
  useLazyGetLoginPageQuery,
  useLazyGetNotReceiveCodeTextQuery,
  useLazyGetMFAuthPageQuery,
  useLazyMFAuthQuery,
  useLazyGetMFAConfirmPageQuery,
  useLazyMFConfirmQuery,
  useLazyGetRememberDevicePageQuery,
  useLazyGetSettingsPageQuery,
  useAllowToMFAMutation,
  useLazyGetAccountSettingsQuery,
  useLazyGetAuthenticatorQrQuery,
  useConfirmAuthQrCodeMutation,
  useDeleteAuthenticatorMutation,
  useUpdatePhoneNumberMutation,
  useConfirmPhoneNumberCodeMutation,
  useUpdateEmailMutation,
  useConfirmEmailCodeMutation,
  useLazyGetAuditLogsPageQuery,
  useLazyGetAccessActivityQuery,
  useLazyGetProjectDataInputQuery,
  useLazyGetSecondaryTablesQuery,
  useLazyGetReportAndCMDQuery,
  useLazyGetSystemErrorsQuery,
  useLazyGetLanguagesQuery,
  useCreateLanguageMutation,
  useUpdateLanguageMutation,
  useDeleteLanguageMutation,
  useLazyGetChangeLocalQuery,

  //   Notification page
  useLazyGetNotificationPageQuery,
  useLazyGetAccountProjectsQuery,
  useSetIsUserToggleNotifyMutation,
  useSetProjectToggleNotifyMutation,
  useLazyGetAllNotificationsQuery,
  useLazyGetNotifByProjectQuery,
  useUpdateSectionNotifyMutation,
  useUpdateSectionItemNotifyMutation,
  useUpdateNotificationTriggersMutation,
  useCopyNotificationsMutation,

  // financial ratios
  useLazyGetFinancialRatiosQuery,
  useCreateFinancialRatiosMutation,
  useCreateFinancialSubRatiosMutation,
  useDeleteFinancialRatiosMutation,
  useDeleteFinancialSubRatiosMutation,

  //   Quarterly Reporting
  useGetQuarterlyReportPageQuery,
  useGetQuarterlyReportEditPageQuery,
  useCreatePhotoMutation,
  useDeleteSitePhotographsMutation,
  useLazyGetQuarterlyPhotographsQuery,
  useLazyGetQuarterlyReportDetailsPageQuery,
  useLazyGetQuarterlyReportSectionsQuery,
  // Create Report step 2 projects
  useLazyGetQuarterlyReportProjectsQuery,
  useLazyGetAllQuarterlyReportsQuery,
  useLazyGetQuarterlyReportInfoQuery,
  useCreateQuarterlyReportMutation,
  useUpdateQuarterlyReportMutation,
  useDeleteQuarterlyReportMutation,
  useUpdateQuarterlyReportCoverMutation,
  useDeleteQuarterlyReportCoverMutation,
  useLazyGetQuarterlyReportDetailsQuery,
  useSubmitQuarterlyReportMutation,
  useDuplicateQuarterlyReportMutation,
  //   Financing Plan
  useLazyGetFinancingPlanSourcesQuery,
  useLazyGetFinancingPlanQuery,
  useCreateFinancingPlanMutation,
  useUpdateFinancingPlanMutation,
  useDeleteFinancingPlanMutation,
  // Laon Utilization
  useLazyGetLoanUtilizationQuery,
  useUpdateLoanUtilizationMutation,
  //   ProjectContractAward
  useLazyGetProjectContractAwardQuery,
  useUpdateProjectContractAwardMutation,
  // Development Objectives
  useLazyGetDevObjectivesQuery,
  useUpdateDevObjectivesMutation,
  //   Implementation Progress
  useLazyGetImplementationProgQuery,
  useUpdateImplementationProgMutation,
  useLazyGetProgressPerOutputsQuery,
  useCreateProgressPerOutputsMutation,
  useUpdateProgressPerOutputsMutation,
  useDeleteProgressPerOutputsMutation,
  useLazyGetMajorProblemsWithIpQuery,
  useCreateMajorProblemsWithIpMutation,
  useUpdateMajorProblemsWithIpMutation,
  useDeleteMajorProblemsWithIpMutation,

  useLazyGetFinancialCovenantsQuery,
  useUpdateFinancialCovenantsMutation,
  useLazyGetSubmissionsAuditedQuery,
  useUpdateSubmissionsAuditedMutation,
  //   Other Covenants
  useLazyGetCovenantsQuery,
  useUpdateCovenantsMutation,
  useCreateCovenantsMutation,
  useDeleteCovenantsMutation,

  //   Documents
  useLazyGetQRDocumentsQuery,
  //   Progress tables




  useGenerateErrorMutation,
  useGetContactsQuery,
  useDeleteContactMutation,
  useGetCountriesQuery,
  useUpdateContactMutation,
  useCreateContactMutation,
  useGetOrganizationsQuery,
  useGetProjectsQuery,
  useCreateProjectMutation,
  useLazyForgotPasswordQuery,
  useLazyResetPasswordQuery,
  useUpdateProjectMutation,
  useLazyGetProjectQuery,
  useGetKeyDataQuery,
  useGetAllContactsQuery,
  useLazyUpdateProjectKeyDataQuery,
  useGetAllRegionsQuery,
  useGetAllCountriesQuery,
  useGetAllOrganizationTypeQuery,
  useCreateRegionMutation,
  useCreateOrganizationTypeMutation,
  useGetAllSectorsQuery,
  useCreateSectorMutation,
  useCreateCountryMutation,
  useDeleteRegionsMutation,
  useUpdateRegionMutation,
  useUpdateSectorMutation,
  useDeleteSectorMutation,
  useUpdateOrganizationTypeMutation,
  useDeleteOrganizationTypeMutation,
  useUpdateCountryMutation,
  useDeleteCountryMutation,
  useLazyGetAllCompaniesQuery,
  useCreateCompanyMutation,
  useDeleteCompanyMutation,
  useUpdateCompanyMutation,
  useCreateFilterMutation,
  useGetAllProjectTypesQuery,
  useLazyGetStatisticsPageQuery,
  useLazyGetSavedFiltersQuery,
  useLazyGetProjectsToStatisticQuery,
  useLazyGetStatisticsQuery,
  useGetContactByIdQuery,
  useGetSecondaryContactByIdQuery,
  useUpdateProjectApprovedChangesMutation,
  useUpdateProjectExecutiveSummaryMutation,
  useGetProjectApprovalsQuery,
  useCreateProjectApprovalsMutation,
  useDeleteProjectApprovalsMutation,
  useUpdateProjectApprovalsMutation,
  useGetDashboardQuery,
  useGetProjectLarpQuery,
  useCreateProjectLarpMutation,
  useDeleteProjectLarpMutation,
  useUpdateProjectLarpMutation,
  useGetProjectReportRequirmentsQuery,
  useCreateProjectReportRequirmentsMutation,
  useUpdateProjectReportRequirmentsMutation,
  useDeleteProjectReportRequirmentsMutation,
  useGetProjectContractActivitiesQuery,
  useCreateProjectContractActivitiesMutation,
  useUpdateProjectContractActivitiesMutation,
  useDeleteProjectContractActivitiesMutation,
  useGetProjectMobilizationActivitiesQuery,
  useCreateProjectMobilizationActivitiesMutation,
  useDeleteProjectMobilizationActivitiesMutation,
  useUpdateProjectMobilizationActivitiesMutation,
  useGetProjectResidualActionsQuery,
  useCreateProjectResidualActionsMutation,
  useUpdateProjectResidualActionsMutation,
  useDeleteProjectResidualActionsMutation,
  useGetUserMutation,
  useUpdateReportCoverMutation,
  useUpdateDisputesOverviewMutation,
  useCreateProjectDisputeMutation,
  useUpdateProjectDisputeMutation,
  useDeleteProjectDisputeMutation,
  useUpdateProjectGoodsEquipmentMutation,
  useCreateProjectGoodsEquipmentMutation,
  useDeleteProjectGoodsEquipmentMutation,
  useUpdateActualProjectPaymentsMutation,
  useCreateActualProjectPaymentsMutation,
  useDeleteActualProjectPaymentsMutation,
  useUpdateFinancialManagementOverviewMutation,
  useCreateSubcontractorPaymentsMutation,
  useDeleteSubcontractorPaymentsMutation,
  useUpdateSubcontractorPaymentsMutation,
  useUpdatePlannedProjectPaymentsMutation,
  //   Glossary pages
  useLazyGetGlossaryQuery,
  useUpdateGlossaryMutation,

  useGetTooltipsBySectionQuery,
  useUpdateLarpTopLevelMutation,
  useGetAllGlossaryQuery,
  useGetPlannedPaymentsQuery,
  useCreateRiskManagementMutation,
  useDeleteRiskManagementMutation,
  useUpdateRiskManagementMutation,
  useCreateEngineeringDesignDocumentsMutation,
  useDeleteEngineeringDesignDocumentsMutation,
  useUpdateEngineeringDesignDocumentsMutation,
  useCreateLevel2ProgramMutation,
  useUpdateLevel2ProgramMutation,
  useDeleteLevel2ProgramMutation,
  useUpdateProgramProgressMutation,
  useCreateVariationRegisterMutation,
  useUpdateVariationRegisterMutation,
  useDeleteVariationRegisterMutation,
  useCreateClaimRegisterMutation,
  useUpdateClaimRegisterMutation,
  useDeleteClaimRegisterMutation,
  useCreateExtensionOfTimeMutation,
  useUpdateExtensionOfTimeMutation,
  useUpdateProgramOverviewMutation,
  useGetProgramOverviewQuery,
  useUpdateCriticalPathMutation,
  useGetCriticalPathQuery,
  useGetProgramProgressChartQuery,
  useCreateOneMonthAheadMutation,
  useUpdateOneMonthAheadMutation,
  useDeleteOneMonthAheadMutation,
  useUpdateProjectStatusMutation,
  useCreateCompletionOfWorkMutation,
  useDeleteCompletionOfWorkMutation,
  useUpdateCompletionOfWorkMutation,
  useCreateSsempActivitiesMutation,
  useDeleteSsempActivitiesMutation,
  useUpdateSsempActivitiesMutation,
  useCreateSocialMonitoringMutation,
  useDeleteSocialMonitoringMutation,
  useUpdateSocialMonitoringMutation,
  useCreateHealthMonitoringMutation,
  useDeleteHealthMonitoringMutation,
  useUpdateHealthMonitoringMutation,
  useCreateComplaintsMutation,
  useDeleteComplaintsMutation,
  useUpdateComplaintsMutation,
  useCreateSecurityMonitoringMutation,
  useDeleteSecurityMonitoringMutation,
  useUpdateSecurityMonitoringMutation,
  useUpdateApplicableSettingsMutation,
  useUpdateGenderTrackingMutation,
  useLazyGetSubmittedReportsQuery,
  useGetPermissionsQuery,
  useGetUsersQuery,
  useCreateUsersMutation,
  useDeleteUsersMutation,
  useDeletePhotoMutation,
  useUpdateUsersMutation,
  useUpdateUsersMFAMutation,
  useLazyGetContractTypeQuery,
  useGetSectorQuery,
  useCreateContractTypesMutation,
  useDeleteContractTypesMutation,
  useUpdateContractTypesMutation,
  useSendCodeMutation,
  useGetCreatePasswordFormQuery,
  useCreatePasswordMutation,
  useGetRolesQuery,
  useGetAllCompaniesQuery,
  useLazyGetAllOrganizationTypeQuery,
  useLazyGetAllSectorsQuery,
  useLazyGetAllCountriesQuery,
  useLazyGetAllRegionsQuery,
  useLazyGetAllProjectsQuery,
  useLazyGetAllReportsQuery,
  useLazyGetUsersQuery,
  useGetUsersNotLazyQuery,
  useGetReportDatesQuery,
  useGetProjectSettingsQuery,
  useUpdateHealthSafetyStatisticsMutation,
  useDeleteReportCoverPhotoMutation,
  useCreateAdbMutation,
  useDeleteAdbMutation,
  useUpdateAdbMutation,
  useCreateContractorMutation,
  useDeleteContractorMutation,
  useUpdateContractorMutation,
  useCreateDisputeBoardMemberMutation,
  useDeleteDisputeBoardMemberMutation,
  useUpdateDisputeBoardMemberMutation,
  useCreateEngineerMutation,
  useDeleteEngineerMutation,
  useUpdateEngineerMutation,
  useCreateExecutingAgencyMutation,
  useDeleteExecutingAgencyMutation,
  useUpdateExecutingAgencyMutation,
  useCreateImplementingAgencyMutation,
  useDeleteImplementingAgencyMutation,
  useUpdateImplementingAgencyMutation,
  useCreateOtherMutation,
  useDeleteOtherMutation,
  useUpdateOtherMutation,
  useUpdateContactNameMutation,
  useDeleteProjectImageMutation,
  useLarpSectionCreateMutation,
  useLarpSectionUpdateMutation,
  useLarpSectionDeleteMutation,
  useSetLarpSectionTierMutation,
  useSetLarpAffectedMutation,
  useDeleteLarpAffectedMutation,
  useDuplicatePersonsMutation,
  useGetAllSectionsMutation,
  useGetAllTemplatesMutation,
  useLazyDownloadTemplateQuery,
  useDeleteTemplateMutation,
  useCreateTemplateMutation,
  useUpdateTemplateMutation,
  useGetContractManagementGlossaryMutation,
  useLazyGetLarpDashboardQuery,
  useSearchCompaniesMutation,
  useSearchCountriesMutation,
  useSearchUsersMutation,
    useGetAllProjectDocumentsMutation,
  useCreateDocumentMutation,
  useDeleteDocumentMutation,
  useUpdateDocumentMutation,
  useDeleteProjectMutation,
  useLazyGetAllTrainingVideosPageQuery,
  useLazyGetAllTrainingVideosQuery,
  useCreateTrainingVideoMutation,
  useUpdateTrainingVideoMutation,
  useDeleteTrainingVideoMutation,

  // larp-dashboard translations
  useLazyGetLarpDashboardTranslationsQuery,

  // project-dashboard translations
  useLazyGetProjectDashboardTranslationsQuery,

  // dashboard index translations
  useLazyGetDashboardIndexTranslationsQuery,

  // report-details translations
  useLazyGetReportsDetailsTranslationsQuery,

  // reporting translations
  useLazyGetReportingTranslationsQuery,

  // log out translations
  useLazyGetLogOutTranslationsQuery,

  // sidebar translations
  useLazyGetSidebarTranslationsQuery
} = adbApi;
