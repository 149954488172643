import React, {useEffect, useRef, useState} from "react";

import { useScreenshot } from "use-react-screenshot";
import {Area, Bar, CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {useLazyGetProjectQuery} from "../../../store/service/adbService";
import moment from "moment/moment";

export const ProgramProgressScreenshot = () => {
    const ref = useRef(null);
    const [width, setWidth] = useState(300);
    const [image, takeScreenShot] = useScreenshot();
    const [progressData, setProgressData] = useState([])

    const [
        trigger,
        { data, isError, error: projectError, isSuccess, isLoading },
    ] = useLazyGetProjectQuery();

    const getImage = () => takeScreenShot(ref.current);

    useEffect(() => {
        !projectError && trigger(1);

        setProgressData(data?.data?.program_progress?.data?.map(el => ({
            ...el,
            date: el?.date,
            planned_amount: +el?.planned_amount,
            actual_amount: +el?.actual_amount,
            cumulative_actual_amount: +el?.cumulative_actual_amount,
            cumulative_planned_amount: +el?.cumulative_planned_amount,
            edit: false
        })))

    }, [data])

    useEffect(() => {
        if (progressData?.length !== 0) {
            setTimeout(() => {
                getImage()
            }, 4000)
        }
    }, [progressData?.length])

    const CustomTooltip = ({active, payload, label}) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <div style={{display: "flex", flexDirection: "column"}}>
                        {payload.map((pld) => (
                            <div style={{display: "inline-block", padding: 10}}>
                                <div style={{fontSize: 12}}>{pld.value}%</div>
                                <div style={{color: pld.color, fontSize: 12}}>{pld.name}</div>
                            </div>
                        ))}
                    </div>
                </div>
            );
        }

        return null;
    };

    function formatXAxis(tickItem) {
        return moment(tickItem).format('MMM-YY')
    }

    return (
        <div style={{padding: "0 50px 0 100px"}} className="plannedProject">
            <img width={width} src={image} alt={"ScreenShot"} />
            <div ref={ref} className="plannedProject__chart">
                <h2>Monthly Progress %</h2>
                {
                    progressData?.length !== 0 ?
                        <ResponsiveContainer width="100%" aspect={3}>
                            <ComposedChart
                                width={500}
                                height={300}
                                data={progressData}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="0 0"/>
                                <XAxis tickFormatter={formatXAxis} fontSize={10} dataKey="date"/>
                                <YAxis
                                    orientation={"right"}
                                    domain={[0, 100]}
                                    type="number"
                                    tickFormatter={(tick) => {
                                        return `${tick}%`;
                                    }}
                                    yAxisId="uv"
                                    fontSize={12}/>
                                <YAxis
                                    domain={[0, dataMax => (dataMax + dataMax / 5)]}
                                    orientation={"left"}
                                    type="number"
                                    tickFormatter={(tick) => {
                                        return `${tick}%`;
                                    }}
                                    fontSize={12}
                                />
                                <Area type="monotone" dataKey="uv" yAxisId="uv" />
                                <Area type="monotone" dataKey="uv" />
                                <Tooltip content={CustomTooltip}/>
                                <Bar dataKey="planned_amount" name="Planned" fill="#E68484"
                                     radius={[10, 10, 0, 0]}/>
                                <Bar dataKey="actual_amount" name="Actual" fill="#99BAEB"
                                     radius={[10, 10, 0, 0]}/>
                                <Line dot={false}
                                      strokeWidth={4}
                                      strokeLinecap="round"
                                      type="monotone"
                                      dataKey="cumulative_planned_amount"
                                      stroke="#3B7AD9"
                                      name="Cumulative Planned"
                                />
                                <Line dot={false}
                                      strokeWidth={4}
                                      strokeLinecap="round"
                                      type="monotone"
                                      dataKey="cumulative_actual_amount"
                                      stroke="#FFD023"
                                      name="Cumulative Actual"
                                />
                            </ComposedChart>
                        </ResponsiveContainer> :
                        <div className="plannedProject__placeholder">
                            <img src="/assets/icons/planned_chart.svg" alt=""/>
                            There is no Data yet
                        </div>
                }
            </div>
        </div>
    );
};
