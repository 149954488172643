import React, {useCallback, useState} from 'react';
import AxelInput from "../../UI/AxelInput";
import AxelButton from "../../UI/AxelButton";
import './index.scss';

const VerifyAuthentication = (props) => {
    const {
        description,
        extraInfo,
        btnTitle,
        inputPlaceholder,
        verifyFunc
    } = props;
    const [inputData, setInputData] = useState({
            value: "",
            id: "email",
            error: "Please enter correct email",
            errorShow: false,
    });
    const inputHandler = useCallback((e) => {
        const inputValue = e.target.value;
        if (inputValue.length <= 6 && /^\d*$/.test(inputValue)) {
            setInputData(old => ({
                ...old,
                value: e.target.value
            }));
        }

    }, []);

    return(
        <div className="modal-mainContainer">
            <div className="modal-text">
                {description}
            </div>
            <AxelInput
                placeholder={inputPlaceholder}
                type="number"
                value={inputData.value}
                handler={inputHandler}
                id={inputData.id}
                error={inputData.error}
                errorShow={inputData.errorShow}
                maxLength={6}
            />
            <div className="modal-extraInfoText">
                {extraInfo}
            </div>
            <AxelButton
                handler={()=>verifyFunc(inputData.value)}
                classname={`
                ${inputData.value.length <6 && 'modal-btnDisable'} 
                `}
                disabled={inputData.value.length < 6}
            >
                {btnTitle}
            </AxelButton>
        </div>
    )
}

export default VerifyAuthentication;