import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {Link, useLocation, useNavigate} from "react-router-dom";

import Wrapper from "../../components/Wrapper";
import {
    useDeleteProjectMutation,
    useUpdateProjectMutation,
    useLazyGetProjectListPageQuery,
    useLazyGetAllProjectsQuery
} from "../../store/service/adbService";
import moment from "moment";
import "./index.scss";
import ProjectModal from "../../components/ProjectModal";
import { setLoader } from "../../store/slice/dashboard";
import { toast } from "react-toastify";
import {Paginate} from "../../components/Paginate";
import useDebounce from "../../global/useDebounce";
import {errorHandle} from "../../global/errorHandle";
import CustomModal from "../../components/customModal";

export default function ProjectList() {
    const dispatch = useDispatch();
    const location = useLocation();
    const user = useSelector((state) => state?.adbReducer?.user);
    const navigate = useNavigate();
    const [currentItem, setCurrentItem] = useState(null);
    const [deletingProject, setDeletingProject] = useState()
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPage, setTotalPage] = useState(0)
    const [searchTerm, setSearchTerm] = useState('');
    const [checkbox, setCheckbox] = useState(0)
    const debouncedSearchTerm = useDebounce(searchTerm, 500)
    const [translations, setTranslations] = useState(false);

    const [getProjectListPage, {
        data: projectListPage,
        isLoading: projectListPageLoading,
        isFetching: projectListPageFetching,
    }] = useLazyGetProjectListPageQuery();

    const [getProjectList, {
        data: projectList,
        isError: projectListIsError,
        isLoading: projectListLoading,
        isFetching: projectListFetching,
        isSuccess: projectListSuccess,
        error: projectListError,
    }] = useLazyGetAllProjectsQuery();


    const [
        deleteProject, {
            data: projectListDeleteData,
            isError: projectListDeleteIsError,
            isLoading: projectListDeleteLoading,
            isSuccess: projectListDeleteIsSuccess,
            error: projectListDeleteError,
        }
    ] = useDeleteProjectMutation();


    const [updateProject] = useUpdateProjectMutation()
    const [projectsArray, setProjectsArray] = useState([]);

    useEffect(() => {
        getProjectList({
            per_page: 20,
            page: currentPage,
            all_projects: checkbox,
            search: debouncedSearchTerm
        })
    }, [currentPage, checkbox, debouncedSearchTerm])

    useEffect(() => {
        if (projectList && projectListSuccess) {
            setTotalPage(projectList?.data?.last_page);
            setProjectsArray(projectList?.data?.data?.map((el) => ({ ...el, edit: false })));
        }
    }, [projectList, projectListSuccess, currentItem])

    useEffect(() => {
        if (!showModal) {
            setCurrentItem(null);
        }
    }, [showModal]);

    useEffect(() => {
        if (projectListDeleteIsSuccess){
            toast.success(projectListDeleteData?.message)
        }
    }, [projectListDeleteIsSuccess, projectListDeleteData])

    useEffect(() => {
        if (projectListDeleteIsError){
            toast.error(projectListDeleteError?.data?.message)
            errorHandle(projectListDeleteError)
        }
    }, [projectListDeleteIsError, projectListDeleteError])

    useEffect(() => {
        projectListLoading || projectListFetching || projectListPageLoading || projectListPageFetching || projectListDeleteLoading ? dispatch(setLoader(true)) : dispatch(setLoader(false));
    }, [projectListLoading,projectListDeleteLoading, projectList, projectListPageLoading,projectListPageFetching,  projectListError, projectListIsError, projectListFetching]);

    useEffect(() => {
        setTranslations(projectListPage?.data?.translations);
    }, [projectListPage]);

    useEffect(()=>{
        const token = localStorage.getItem('user_token');
        if (projectListError?.data?.message === "Your account has been deactivated.") {
            toast.error('Your account has been deactivated.', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 8000,
            });
            localStorage.removeItem('user_token')
            setTimeout(() => {
                navigate("/login");
            }, 500);
        }
        if (projectListError?.originalStatus === 401) {
            toast.error('Unauthorized.', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 8000,
            });
            dispatch(setLoader(false))
            localStorage.removeItem('user_token')
            setTimeout(() => {
                navigate("/login");
            }, 500);
        }
        getProjectListPage()
    },[])

    return (
        <Wrapper>
            {showModal && (
                <ProjectModal currentItem={currentItem} setShowModal={setShowModal} />
            )}
            <h1 className="text-dark text-[36px] dashboard__title">{translations?.['headers.project_list']}</h1>
            <div className="flex justify-end items-center">
                <div className="dashboard__checkboxs mr-15">
                    <div className="dashboard__checkbox">
                        <div className="a-checkbox">
                            <input type="radio" id="1-radio" defaultChecked={true} name="projects" onChange={() => {setCheckbox(0); setCurrentPage(1)}} />
                            <label htmlFor="1-radio">{translations?.['actions.active_projects']}</label>
                        </div>
                    </div>
                    <div className="dashboard__checkbox">
                        <div className="a-checkbox">
                            <input type="radio" id="0-radio" name="projects" onChange={() => {setCheckbox(1); setCurrentPage(1)}} />
                            <label htmlFor="0-radio">{translations?.['actions.all_projects']}</label>
                        </div>
                    </div>
                </div>
                <div className="search-block">
                    <input placeholder={translations?.['actions.search']} type="text" className="search" onChange={(e) => {
                        setSearchTerm(e.target.value)
                    }} />
                </div>
                {user?.permissions?.new_project && (
                    <button
                        onClick={() => {
                            navigate("/create-project/step-1", {
                                state: { allProjects: projectList?.data },
                            });
                        }}
                        className="dashboard__btn"
                    >
                        <img src="/assets/icons/plus.svg" alt="plus" />
                        <span>{translations?.['actions.add_project']}</span>
                    </button>
                )}
            </div>
            {projectsArray?.length > 0 ? (
                <div className="relative mt-[24px]">
                    <table className="w-full text-left dashboard__table">
                        <thead className="dashboard__thead">
                        <tr className="dashboard__tr">
                            <th scope="col" className="py-3 px-6 dashboard__th">
                                {[translations?.['columns.project_name.title']]}
                            </th>
                            <th scope="col" className="py-3 px-6 dashboard__th">
                                {[translations?.['columns.contract_number.title']]}
                            </th>
                            <th scope="col" className="py-3 px-6 dashboard__th">
                                {[translations?.['columns.budget.title']]}
                            </th>
                            <th scope="col" className="py-3 px-6 dashboard__th">
                                {[translations?.['columns.country.title']]}
                            </th>
                            <th scope="col" className="py-3 px-6 dashboard__th">
                                {[translations?.['columns.actual_progress.title']]}
                            </th>
                            <th scope="col" className="py-3 px-6 dashboard__th">
                                {[translations?.['columns.variance_days.title']]}
                            </th>
                            <th scope="col" className="py-3 px-6 dashboard__th">
                                {[translations?.['columns.completion_date.title']]}
                            </th>
                            <th scope="col" className="py-3 px-6 dashboard__th">
                                {[translations?.['columns.high_risks.title']]}
                            </th>
                            <th scope="col" className="py-3 px-6 dashboard__th">
                                {[translations?.['columns.last_updated.title']]}
                            </th>
                            <th scope="col" className="py-3 px-6 dashboard__th">
                                {[translations?.['columns.report_in_process.title']]}
                            </th>
                            <th scope="col" className="py-3 px-10 dashboard__th">
                            </th>
                        </tr>
                        </thead>
                        <tbody className="dashboard__tbody">
                        {projectsArray?.map((item, index) => (
                            <tr
                                //  didn't understand why this code is here
                                // onMouseEnter={() => {
                                //     setProjectsArray(
                                //         projectsArray.map((el) =>
                                //             el.id === item.id
                                //                 ? { ...el, edit: true }
                                //                 : { ...el, edit: false }
                                //         )
                                //     );
                                // }}
                                // onMouseLeave={() => {
                                //     setProjectsArray(
                                //         projectsArray.map((el) =>
                                //             el.id === item.id
                                //                 ? { ...el, edit: false }
                                //                 : { ...el, edit: false }
                                //         )
                                //     );
                                // }}
                                className={`dashboard__tr ${item?.edit ? "edit" : ""}`}
                                key={index}
                            >
                                <td className="py-4 px-6 dashboard__td">
                                    <div
                                        onClick={() => {
                                            localStorage.setItem("itemId", item.id);
                                        }}
                                    >
                                        {item?.project_name}
                                    </div>
                                </td>
                                <td className="py-4 px-6">{item?.contract_number ?? ""}</td>
                                <td className="py-4 px-6">
                                    {item?.budget == 'N/A' ? '' : '$'}
                                    {String(item?.budget).replace(
                                        /(\d)(?=(\d{3})+(\D|$))/g,
                                        "$1,"
                                    ) ?? "-"}
                                </td>
                                <td className="py-4 px-6">{item?.country ?? "-"}</td>
                                <td className="py-4 px-6">{item?.actual_progress !== "N/A" ? item?.actual_progress : ""}</td>
                                <td className="py-4 px-6">{item?.variance ?? "-"}</td>
                                <td className="py-4 px-6">
                                    {(item?.completion_date !== 'N/A' && item?.completion_date && moment(item?.completion_date, "DD/MM/YYYY").isValid()) ? moment(item?.completion_date).format("DD/MM/YYYY") : null}
                                </td>
                                <td className="py-4 px-6 text-center">{item?.risks ?? "-"}</td>
                                <td className="py-4 px-6">
                                    {item?.last_updated !== '' && item?.last_updated ? moment(item?.last_updated).format("DD/MM/YYYY") : ""}
                                </td>
                                <td className="py-4 px-6" style={item?.completed  ? {color: '#0caf60',} : {}}>{item?.report_in_process ? item?.report_in_process : "-"}</td>
                                <td className="py-4 dashboard__editBtns">
                                    {user?.permissions?.inactive_project ?
                                        <div className="a-checkbox">
                                            <input key={item?.id} type="checkbox" id={item?.id} defaultChecked={item?.inactive == 1 ? true : false} onChange={(e) => {
                                                dispatch(setLoader(true))
                                                updateProject({body: {inactive: e.target.checked ? 1 : 0}, id: item?.id}).unwrap().then(() => {
                                                    dispatch(setLoader(false))
                                                }).catch(() => {
                                                    dispatch(setLoader(false))
                                                })
                                            }} />
                                            <label htmlFor={item?.id}></label>
                                        </div>
                                        : null}
                                    <div
                                        state={{ project: item }}
                                        className="dashboard__editBtn"
                                        onMouseEnter={() => {
                                            localStorage.setItem("itemId", item.id);
                                            setCurrentItem(item)
                                        }}
                                        onMouseLeave={() => {
                                            setCurrentItem(null)
                                        }}
                                    >
                                        <img src="/assets/icons/dashboardview.svg" alt=""/>
                                        {`${currentItem?.id}-item` === `${item?.id}-item` && (
                                            <div className="dashboard__editBtns-links"
                                                 onMouseLeave={() => {
                                                     setCurrentItem(null)
                                                 }}>
                                                {item?.actions?.charts?.dashboard_id ? <Link tag to={`/dashboard/${item?.id}`} state={{project: item, dash_visible:item?.actions?.charts?.dashboard_id, larp_visible: item?.actions?.charts?.larp_id }} onClick={() => localStorage.setItem("itemId", item.id)}>{translations?.['actions.project_dashboard']}</Link> : null }
                                                {item?.actions?.charts?.larp_id  ? <Link to={`/larp-dashboard/${item?.id}`} state={{project: item, dash_visible:item?.actions?.charts?.dashboard_id, larp_visible: item?.actions?.charts?.larp_id}} onClick={() => localStorage.setItem("itemId", item.id)}>{translations?.['actions.view_larp_dashboard']}</Link> : null}
                                            </div>
                                        )}
                                    </div>
                                    <div
                                        className="dashboard__editBtn"
                                        onClick={() => {
                                            localStorage.setItem("itemId", item.id);
                                            navigate(`/online-view/${item?.actions?.view_id}`, {state: {id: item?.latest_report?.id, prevLocation: location?.pathname, date: item?.latest_report?.date}})
                                        }}
                                    >
                                        <img src="/assets/icons/eyelist.svg" alt=""/>
                                        <p>{translations?.['actions.view_report']}</p>
                                    </div>
                                    {
                                        (user?.permissions?.edit_project || user?.permissions?.view_project) &&
                                        <Link
                                            to={`/project/${item?.id}`}
                                            state={{ project: item }}
                                            className="dashboard__editBtn"
                                            onClick={() => {
                                                localStorage.setItem("itemId", item.id);
                                            }}
                                        >
                                            <img src="/assets/icons/edit-report.svg" alt=""/>
                                            <p>{translations?.['actions.edit_report']}</p>
                                        </Link>
                                    }
                                    { user?.permissions?.delete_project &&
                                        <div
                                            className="dashboard__editBtn"
                                            onClick={()=> {
                                                setDeletingProject(item)
                                                setShowDeleteModal(true)
                                            }}
                                        >
                                            <div className="dashboard__deleteBtn" >
                                                <img color='red' src="/assets/icons/trash-grey.svg" alt="Delete project"/>
                                            </div>
                                            <p>{translations?.['actions.delete_project']}</p>
                                        </div>
                                    }
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <div style={{height: '70vh', width: '100%', display: "flex", justifyContent:"center", alignItems: 'center', flexDirection: "column"}}>
                    <img src="/assets/images/projects.png" alt=""/>
                    <span style={{color: "#767676", fontSize: 16, fontWeight: 500}}>{translations?.['headers.no_projects']}</span>
                </div>
            )}
            {projectsArray?.length > 0 && totalPage > 0 && <Paginate key={projectsArray} currentPage={currentPage} setCurrentPage={setCurrentPage} pageCount={totalPage}/>}
            {
                showDeleteModal &&
                <CustomModal
                    acceptBtnTitle={translations?.['actions.delete']}
                    cancelBtnTitle={translations?.['actions.cancel']}
                    acceptHandler={() => {
                        setShowDeleteModal(false)
                        deleteProject(deletingProject?.id)
                    }}
                    closeHandler={() => {
                        setShowDeleteModal(false)
                    }}
                    title={translations?.['headers.delete_confirmation']}
                    hasHr
                >
                    <p>
                        {translations?.['titles.are_you_sure_you_want_to_delete'] + ' ' + deletingProject?.project_name}?
                    </p>
                </CustomModal>
            }
        </Wrapper>
    );
}
