export function formatYAxis(tickItem) {
    if (tickItem > 1000000000) {
        return (
            "$" +
            String((tickItem / 1000000000).toFixed(0)).replace(
                /(\d)(?=(\d{3})+([^\d]|$))/g,
                "$1,"
            ) +
            "B"
        );
    }
    if (tickItem > 1000000) {
        return (
            "$" +
            String((tickItem / 1000000).toFixed(0)).replace(
                /(\d)(?=(\d{3})+([^\d]|$))/g,
                "$1,"
            ) +
            "M"
        );
    }
    if (tickItem > 1000) {
        return (
            "$" +
            String((tickItem / 1000).toFixed(0)).replace(
                /(\d)(?=(\d{3})+([^\d]|$))/g,
                "$1,"
            ) +
            "K"
        );
    }
    return "$" + String(tickItem).replace(/(\d)(?=(\d{3})+([^\d]|$))/g, "$1,");
}

