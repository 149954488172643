import React from 'react';
import './index.scss'

const EmptyVideoState = () =>{
    return(
        <div className="emptyContainer">
            <img src="assets/icons/files.svg"/>
            <p>
                There are no Videos yet
            </p>
        </div>
    )
}
export default EmptyVideoState;