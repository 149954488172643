import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Switch} from "../../UI/Switch";
import Wrapper from "../../components/Wrapper";
import GeneralNotification from "./GeneralNotification";
import {errorHandle} from "../../global/errorHandle";
import {setLoader, setUserData} from "../../store/slice/dashboard";
import {
    useGenerateErrorMutation,
    useLazyGetNotificationPageQuery,
    useSetIsUserToggleNotifyMutation
} from "../../store/service/adbService";
import './index.scss';
import MoreInfoModal from "../../components/MoreInfoModal";

const NotificationCenter = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const route = useLocation().pathname;
    const dispatch = useDispatch();
    const [notificationsOn, setNotificationsOn] =useState(false);
    const [isOpenMoreInfo, setOpenMoreInfo] = useState(false)
    const [translations, setTranslations] = useState(false);

    const user = useSelector((state) => state?.adbReducer?.user);

    const [generateError] = useGenerateErrorMutation();
    const [setIsUserToggleNotify, {
        data: userToggleNotifyData,
        error: errorUserToggleNotify,
        isError: isErrorUserToggleNotify,
        isLoading: isLoadingUserToggleNotify,
        isSuccess: isSuccessUserToggleNotify,
        isFetching: isFetchingUserToggleNotify,
    }] = useSetIsUserToggleNotifyMutation();

    const [getNotificationPage, {
        data: pageData,
        error: errorPageData,
        isError: isErrorPageData,
        isLoading: isLoadingPageData,
        isFetching: isFetchingPageData,
    }] = useLazyGetNotificationPageQuery();

    const notificationStatusHandler = () => {
        setNotificationsOn(!notificationsOn);
        setIsUserToggleNotify();
    }

    useEffect(() => {
      if(user){
          setNotificationsOn(user?.notifications_on)
      }
    }, [user]);

    useEffect(()=>{
        if (isSuccessUserToggleNotify) {
            dispatch(setUserData(userToggleNotifyData?.data));
            localStorage.setItem("local_lan", userToggleNotifyData?.locale?.code )
        }

    },[isSuccessUserToggleNotify, userToggleNotifyData])

    useEffect(() => {
        if(isErrorPageData){
            generateError({
                code: errorPageData?.originalStatus,
                message: errorPageData?.status,
                referer: `${process.env.REACT_APP_API_URL}${location.pathname}`,
            })
        }
    },[isErrorPageData, errorPageData])

    useEffect(() => {
        if (isErrorUserToggleNotify) {
            errorHandle(errorUserToggleNotify)
        }

    }, [errorUserToggleNotify, isErrorUserToggleNotify ])

    useEffect(() => {
        if (localStorage.getItem("user_token")) {
            getNotificationPage();
        } else {
            return  navigate("/login");
        }
    }, [route]);

    useEffect(() => {
        setTranslations(pageData?.data?.translations);
    }, [pageData]);

    useEffect(() => {
        isLoadingPageData || isFetchingPageData || isLoadingUserToggleNotify || isFetchingUserToggleNotify ? dispatch(setLoader(true)) : dispatch(setLoader(false))
    }, [ isLoadingPageData, isFetchingPageData, isFetchingUserToggleNotify, isLoadingUserToggleNotify]);

    return (
        <Wrapper classname='notification'>
            {isOpenMoreInfo &&
                <MoreInfoModal
                    title= {translations?.['index.index.headers.notification_center']}
                    moreInfo={translations?.['index.index.more_info.notification_center']}
                    close={()=>setOpenMoreInfo(false)}
                />
            }
            <div className='notification__header'>
                <div className='notification__header__leftWrapper'>
                    <h1 className="notification__title">
                        {translations?.['index.index.headers.notification_center']}
                    </h1>
                    <button className='notification__header__moreInfo' onClick={()=>setOpenMoreInfo(!isOpenMoreInfo)} type='button'>
                        <img src='/assets/icons/info.svg' alt='more info icon' />
                        {/*{translations?.['index.index.more_info.notification_center']} */}
                    </button>
                </div>
                <div className='notification__header__rightWrapper'>
                    <h4 className='notification__title'>
                        {translations?.['index.index.actions.enable_notifications']}
                    </h4>
                    <Switch
                        id={'index + 7320'}
                        checked={notificationsOn}
                        handler={notificationStatusHandler}
                    />
                </div>
            </div>
            <div className='notification__content '>
                { !notificationsOn ? <div className='notification__content__disableContent '>
                    <img
                        src='/assets/icons/notification-empty.svg'
                        alt="notification icon"
                    />
                    <p>
                        {translations?.['index.index.titles.you_have_successfully_disabled_alerts']}
                    </p>
                </div>: <GeneralNotification  translations={translations}/>
                }
            </div>
        </Wrapper>

    )
}

export default NotificationCenter;