import isEmpty from "lodash/isEmpty";
import axios from "axios";
import Select from "react-select";
import moment from "moment/moment";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {setLoader} from "../../store/slice/dashboard";
import CustomDatePicker from "../../components/CustomDatePicker";
import GenerateQuarterlyReport from "../../components/GenerateQuarterlyReport";
import {
    useGetAllSectionsMutation,
    useLazyGetAllProjectsQuery,
    useLazyGetAllReportsQuery, useLazyGetQuarterlyReportSectionsQuery
} from "../../store/service/adbService";

const customStyles = {

    menu: (provided, state = false) => ({
        ...provided,
        width: "100%",
        color: state.selectProps.menuColor,
        cursor: "pointer",
        zIndex: 50,
        overflow: "hidden",
    }),

    control: (_, { selectProps: {  } }) => ({
        width: '100%',
        border: "1px solid rgba(0, 0, 0, 0.1)",
        display: "flex",
        background: "#FFFFFF",
        borderRadius: 8,
        padding: 8,
    }),
};

const ProjectReports = ({isAllProject, tab, translations}) => {
    const localeLan = localStorage.getItem("local_lan" );
    moment.locale(localeLan)
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [reportId, setReportId] = useState(null);
    const [details, setDetails] = useState([]);
    const [orginDetails, setOriginDetails] = useState({});
    const [selectedDate, setSelectedDate] = useState();
    const [reportDates, setReportDates] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedItem, setSelectedItem] = useState({});
    const [projectOptions, setProjectOptions] = useState([]);
    const [selectedDetails, setSelectedDetails] = useState([]);
    const [parsedDetails, setParsedDetails] = useState([]);

    const [getAllSections, {data: allProjectSections}] = useGetAllSectionsMutation()
    const [getQuarterlyReportSections, {data: allReportSections}] = useLazyGetQuarterlyReportSectionsQuery();
    const [getAllProjects, {data: allProjects,},] = useLazyGetAllProjectsQuery();
    const [getAllReports, {data: allReports,},] = useLazyGetAllReportsQuery();

    // const {data: sections} = useGetProjectSettingsQuery(selectedItem?.value);
    useEffect(() => {
        setDetails(null)
        if (tab === 1) {
            getAllProjects({
                per_page: 1000,
                page: currentPage,
                all_projects: isAllProject ? 0 : 1
            });
            getAllSections()
        } else {
            getAllReports();
            getQuarterlyReportSections()
        }

    }, [currentPage, isAllProject, tab]);

    useEffect(() => {
        let data;
        setReportId(null);
        setSelectedDate(null);
        setSelectedItem({});
        setProjectOptions({});
        setSelectedDetails([]);
        if (tab === 1) {
            if (allProjects?.data?.data) {
                data = allProjects?.data?.data?.map((item) => {
                    return {
                        value: item?.id,
                        label: `${item?.project_name} - ${item?.contract_number}`,
                    };
                });
            }
        } else {
            if (allReports?.data) {
                data = allReports?.data?.map((item) => {
                    return {
                        value: item?.id,
                        label: `${item?.report_name}`,
                    };
                });
            }
        }
        setProjectOptions(data);
    }, [allProjects, tab, allReports]);

    useEffect(()=>{
        if (tab === 1 && allProjectSections?.data ){
            setOriginDetails(allProjectSections?.data)
        }
        if (tab === 2 && allReportSections?.data ){
            setOriginDetails(allReportSections?.data)
        }

    },[allReportSections,allProjectSections])

    useEffect(() => {
        if (selectedItem?.value !== undefined) {
            dispatch(setLoader(true));
            const lanCode = localStorage.getItem("local_lan" )
            const headers = {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ` + localStorage?.getItem("user_token"),
                "AppLocale": `${lanCode ? lanCode : 'en'}`
            };
            axios
                .get(
                    `${process.env.REACT_APP_API_URL}/${tab === 1 ? 'report/submitted' : 'quarterly-report/submitted-reports'}/${selectedItem?.value}`,
                    { headers: headers }
                )
                .then((res) => {
                    dispatch(setLoader(false));
                    setReportDates(
                        res?.data?.data?.map((el) => {
                            return ({
                                value: el?.id,
                                label: moment(el?.date)?.format(
                                    "DD/MM/YYYY"
                                )
                        })
                        })
                    );
                    setDetails(null);
                    setSelectedDetails(null);
                })
                .catch(() => {
                    dispatch(setLoader(false));
                });
        }
    }, [selectedItem]);
    // }, [sections?.data, selectedItem]);

    useEffect(()=>{
        let sectionsData;
        for (let i = 0; i < details?.length; i++) {
            const foundItem = selectedDetails.find(item => item.value === details[i].value);
            sectionsData = {
                ...sectionsData,
                [details[i].value]: foundItem ? foundItem.show : 0
            }
        }
        setParsedDetails(sectionsData)

    },[details,selectedDetails])

    function array_move(arr, old_index, new_index) {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr; // for testing
    }

    useEffect(() => {
        const lanCode = localStorage.getItem("local_lan" )
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ` + localStorage.getItem("user_token"),
            "AppLocale": `${lanCode ? lanCode : 'en'}`
        };
        if (selectedDate) {
            dispatch(setLoader(true));
            const headers = {
                "Content-Type": "multipart/form-data",
                "AppLocale": `${lanCode ? lanCode : 'en'}`,
                Authorization: `Bearer ` + localStorage?.getItem("user_token")
            };
            axios
                .get(
                    `${process.env.REACT_APP_API_URL}/${tab === 1 ? 'projects/settings-by-report' : 'quarterly-report/sections-by-report'}/${reportId?.value}`,
                    { headers: headers }
                )
                .then((res) => {
                    dispatch(setLoader(false));
                    let s = [];
                    for (let key in res?.data?.data) {
                        s = [
                            ...s,
                            {
                                label: orginDetails?.[key],
                                value: key,
                                show: res?.data?.data[key] ? 1 : 0,
                            },
                        ];
                    }
                    if (tab === 1) {
                        array_move(s, 5, 26)
                    }
                    setDetails(s);
                    setSelectedDetails(s);
                })
                .catch(() => {
                    dispatch(setLoader(false));
                });
        }
    }, [selectedDate]);

    const generateReport = () => {
        const lanCode = localStorage.getItem("local_lan" )
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ` + localStorage.getItem("user_token"),
            "AppLocale": `${lanCode ? lanCode : 'en'}`
        };
        dispatch(setLoader(true));

        axios
            .post(`${process.env.REACT_APP_API_URL}${tab === 1 ? '/report/generate' : `/quarterly-report/generate-document/${reportId?.value}`}`, {
                report_id: reportId?.value,
                ...parsedDetails,
            }, {
                headers: headers,
                responseType: "blob",
            })
            .then(({ data: blob }) => {
                const link = document.createElement("a");
                const url = URL.createObjectURL(blob);
                link.href = url;
                link.download = `report_${moment(reportId?.label)?.format(
                    "dd/MM/yyyy"
                )}.docx`;
                link.click();
                dispatch(setLoader(false));
            })
            .catch((e) => {
                toast.error(
                    e?.data?.message ? e?.data?.message : "Something went wrong.",
                    {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    }
                );
                dispatch(setLoader(false));
            });
    };

    const handleChangeDate = (date) => {
        setSelectedDate(date);
        setReportId(
            reportDates?.filter(
                (el) =>
                {
                    return moment(el?.label,"DD/MM/YYYY").format("DD/MM/YYYY") ===
                        moment(date,"DD/MM/YYYY", true )?.format("DD/MM/YYYY")
                }
            )[0]
        );
    };
    const viewReport = () => {
        navigate(`/online-view/${reportId?.value}`, {
            state: {
                id: reportId?.value,
                prevLocation: location?.pathname,
                selectedDetails: selectedDetails,
                date: reportId?.label,
            },
        });
    };


    return(
        <div className="reporting__contentContainer">
            <div className="s-page__selects">
                <div className="s-page__project">
                    <p>{`${
                        tab === 1 ?
                            translations?.['project_reports.index.titles.project'] :
                            translations?.['quarterly_reports.index.titles.report']}`
                    }</p>
                    <Select
                        options={projectOptions}
                        styles={{
                            ...customStyles,
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        key={tab}
                        id={tab}
                        isSearchable={true}
                        placeholder={tab === 1 ?
                            translations?.['project_reports.index.placeholder.select_project'] :
                            translations?.['quarterly_reports.index.placeholder.select_report']
                        }
                        onChange={(selected) => {
                            setSelectedItem(selected);
                            setSelectedDate(null);
                        }}
                    />
                </div>
                <div className="s-page__project reporting-select">
                    <p>{tab ===1 ? translations?.['project_reports.index.titles.report_month'] : translations?.['quarterly_reports.index.titles.report_date']}</p>
                    {tab === 1 ?
                        <CustomDatePicker
                            selected={selectedDate && moment(selectedDate, 'DD/MM/YYYY', true).isValid()
                                ? moment(selectedDate, 'DD/MM/YYYY').toDate()
                                : null}
                            placeholderText={
                                tab === 1 ?
                                    translations?.['project_reports.index.placeholder.click_to_select_a_date'] :
                                    translations?.['quarterly_reports.index.placeholder.click_to_select_a_date']
                            }
                            onChange={(date) => handleChangeDate(date)}
                            dateFormat="dd/MM/yyyy"
                            includeDates={reportDates?.map((el) => {
                                const parsedDate = moment(el?.label, 'DD/MM/YYYY');
                                return parsedDate.isValid() ? parsedDate.toDate() : null;
                            }).filter(date => date !== null)}  // Filtering out invalid dates
                            showMonthYearPicker={tab === 1}
                        />
                        :
                        <Select
                            options={reportDates}
                            styles={{
                                ...customStyles,
                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                            key={tab}
                            id={tab}
                            isSearchable={true}
                            className='select_input'
                            placeholder={translations?.['quarterly_reports.index.placeholder.select_date']}
                            onChange={(date) => handleChangeDate(date.label)}
                        />
                    }
                </div>
            </div>
            <h5 className="s-page__details-title">{tab === 1 ?
                translations?.['project_reports.index.titles.details'] :
                translations?.['quarterly_reports.index.titles.details']
            }</h5>
            {selectedDate && details ? (
                <div className="s-page__details">
                    <div className="contact__modal-projectBox">
                        <div className="contact__modal-checkbox">
                            <div className="a-checkbox">
                                <input
                                    id="all"
                                    key="all"
                                    type="checkbox"
                                    checked={
                                        selectedDetails?.filter((el) => el?.show)?.length ===
                                        details?.filter((el) => el?.show)?.length
                                    }
                                    onChange={(e) => {
                                        if (e?.target?.checked) {
                                            setSelectedDetails(
                                                details
                                                    // ?.filter((el) => el?.show)
                                                    ?.map((el) => ({
                                                        ...el,
                                                        [el?.label?.split(" ").join("_").toLowerCase()]: 1,
                                                        show: 1,
                                                    }))
                                            );
                                        } else {
                                            setSelectedDetails(
                                                details
                                                    // ?.filter((el) => el?.show)
                                                    ?.map((el) => ({
                                                        ...el,
                                                        [el?.label?.split(" ").join("_").toLowerCase()]: 0,
                                                        show: 0,
                                                    }))
                                            );
                                        }
                                    }}
                                />
                                <label htmlFor="all">
                                    {tab === 1
                                        ? translations?.[
                                            "project_reports.index.actions.select_all_sections"
                                            ]
                                        : translations?.[
                                            "quarterly_reports.index.actions.select_all_sections"
                                            ]}
                                </label>
                            </div>

                        </div>
                    </div>
                    {details.slice(2, details?.length)?.map((el) => {
                      if (el?.value !=="documents"){
                          return (
                              <div
                                  key={el?.value}
                                  className={`${
                                      el?.show
                                          ? "contact__modal-projectBox"
                                          : "contact__modal-projectBox disabled"
                                  }`}
                              >
                                  <div className="contact__modal-checkbox">
                                      <div className="a-checkbox">
                                          {el?.show ? (
                                              <input
                                                  key={selectedDetails}
                                                  id={el?.value}
                                                  type="checkbox"
                                                  checked={
                                                      selectedDetails?.some(
                                                          (item) => item?.show === 1 && item?.label === el?.label
                                                      )
                                                  }
                                                  onChange={(e) => {
                                                      if (e?.target?.checked) {
                                                          setSelectedDetails((old) =>
                                                              old.map((item) =>
                                                                  item?.label === el?.label
                                                                      ? {
                                                                          ...item,
                                                                          [el?.label?.split(" ").join("_").toLowerCase()]: 1,
                                                                          show: 1,
                                                                          value: el?.value,
                                                                      }
                                                                      : item
                                                              )
                                                          );
                                                      } else {
                                                          setSelectedDetails((old) =>
                                                              old.map((item) =>
                                                                  item?.label === el?.label
                                                                      ? {
                                                                          ...item,
                                                                          [el?.label?.split(" ").join("_").toLowerCase()]: 0,
                                                                          show: 0,
                                                                          value: el?.value,
                                                                      }
                                                                      : item
                                                              )
                                                          );
                                                      }
                                                  }}
                                              />
                                          ) : (
                                              <img src="assets/icons/slash.svg" alt="" />
                                          )}
                                          <label
                                              style={{ textTransform: "capitalize" }}
                                              htmlFor={el?.value}
                                          >
                                              {el?.label}
                                          </label>
                                          <div className="not-show">
                                              {tab === 1
                                                  ? translations?.[
                                                      "project_reports.index.titles.this_section_is_not_included_in_this_project"
                                                      ]
                                                  : translations?.[
                                                      "quarterly_reports.index.titles.this_section_is_not_included_in_this_report"
                                                      ]}
                                          </div>
                                      </div>
                                  </div>

                              </div>
                          )
                      }

                    })}
                </div>
            ) : (
                <div
                    style={{
                        background: "white",
                        padding: "36px 0",
                        marginBottom: 20,
                        border: "1px solid rgba(0,0,0, 0.1)",
                    }}
                >
                    <div className="plannedProject__placeholder">
                        <img src="/assets/icons/chart-placeholder.png" alt="" />
                        {tab === 1 ?
                            translations?.['project_reports.index.titles.please_select_project_and_date'] :
                            translations?.['quarterly_reports.index.titles.please_select_project_and_date']
                        }
                    </div>
                </div>
            )}
            <div className="s-page__generateBtns">
                {selectedDate && tab === 1 &&  (
                    <div onClick={viewReport} className="s-page__generate">
                        <button disabled={!selectedDate} className={`s-page__viewBtn`}>
                            {tab === 1 ?
                                translations?.['project_reports.index.actions.view_report'] :
                                translations?.['quarterly_reports.index.actions.view_report']}
                        </button>
                    </div>
                )}
                {tab === 1 &&
                    <div onClick={generateReport} className="s-page__generate">
                        <button
                            disabled={!selectedDate}
                            className={`s-page__generateBtn ${
                                selectedDate ? "" : "disabled"
                            }`}
                        >
                            { translations?.['project_reports.index.actions.generate_report']}
                        </button>
                    </div>
                }
                {(tab === 2 && !isEmpty(parsedDetails) && !isEmpty(reportDates)) && (
                    <GenerateQuarterlyReport
                        reportId={reportId?.value}
                        reportData={allReports?.data?.find(item=>item?.id==selectedItem?.value)}
                        selectedSections={parsedDetails}
                        translations={{
                            'index.index.tooltips.generate_report_to_download': translations?.['columns.generate_report_to_download.tooltip'],
                            'index.index.titles.in_progress': translations?.['quarterly_reports.index.titles.in_progress'],
                            'index.index.tooltips.report_in_process': translations?.['columns.report_in_process.tooltip'],
                            'index.index.actions.download_report': translations?.['quarterly_reports.index.actions.download_report']
                        }}
                    />
                )
                }
            </div>
        </div>
    )
}

export default ProjectReports;