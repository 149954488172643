import isEmpty from "lodash/isEmpty";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import Select from "react-select";
import React, { useEffect, useRef, useState } from "react";
import AxelInput from "../../../UI/AxelInput";
import {
    useCreateFinancingPlanMutation,
    useUpdateFinancingPlanMutation,
    useLazyGetFinancingPlanSourcesQuery,
} from "../../../store/service/adbService";
import { errorHandle } from "../../../global/errorHandle";
import { setLoader } from "../../../store/slice/dashboard";

const ItemEdit = ({
                      reportId,
                      itemData,
                      setIsOpenEdit,
                      headerRef,
                      cancelTranslation,
                      saveTranslation,
                      getFinancingPlans,
                      cellWidths,
                      descriptionPlaceholder,
                      amountPlaceholder,
                  }) => {
    const dispatch = useDispatch();
    const rowRef = useRef();
    const [sources, setSources] = useState([]);

    const [state, setState] = useState({
        source_of_fund: itemData?.source_of_fund ?? null,
        description: itemData?.description ?? "",
        amount: itemData?.amount ?? null,
    });

    const customStyles = {
        menu: (provided, state = false) => ({
            ...provided,
            width: "100%",
            color: state.selectProps.menuColor,
            cursor: "pointer",
        }),
        control: (_, { selectProps: {} }) => ({
            width: cellWidths[0],
            border: "1px solid rgba(0, 0, 0, 0.1)",
            display: "flex",
            alignItems: "center",
            color: "#2A3650",
            background: "#F8F8F8",
            padding: "10px 8px 10px 8px",
            height: 42,
            borderRadius: 8,
            cursor: "pointer",
        }),
        placeholder: (style) => ({
            ...style,
            color: "#2A3650",
            marginTop: "-10px",
        }),
    };

    const [
        getFinancingPlanSources,
        { data: sourcesData, isLoading: sourcesIsLoading, error: sourcesError },
    ] = useLazyGetFinancingPlanSourcesQuery();

    const [
        createFinancingPlan,
        {
            data: createFinancingPlanData,
            isSuccess: createFinancingPlanIsSuccess,
            isLoading: createFinancingPlanIsLoading,
            error: createFinancingPlanError,
        },
    ] = useCreateFinancingPlanMutation();

    const [
        updateFinancingPlan,
        {
            data: updateFinancingPlanData,
            isSuccess: updateFinancingPlanIsSuccess,
            isLoading: updateFinancingPlanIsLoading,
            error: updateFinancingPlanError,
        },
    ] = useUpdateFinancingPlanMutation();

    const inputHandler = (e) => {
        setState((old) => ({ ...old, [e.target.id]: e.target.value }));
    };

    const amountInputHandler = (e) => {
        if (e.target.value.includes(",")) {
            return;
        }
        setState((old) => ({ ...old, [e.target.id]: e.target.value }));
    };

    const handleKeyDown = (e) => {
        if (e.key === ",") {
            e.preventDefault();
        }
    };

    const selectValues = (data, setter) => {
        if (data) {
            let selectData = Object.keys(data)?.map((key) => ({
                value: key,
                label: data[key],
            }));
            setter(selectData);
        }
    };

    const handleMouseLeave = (event) => {
        if (!rowRef.current || !rowRef.current.contains(event.target)) {
            setIsOpenEdit(false);
        }
    };

    const submitHandler = (e) => {
        e.preventDefault();

        let body = {
            source_of_fund: state?.source_of_fund?.value,
            description: state?.description,
            amount: state?.amount,
        };
        const formData = new FormData();

        let isHaveEmptyField = false;

        for (let key in body) {
            if (!body[key] || body[key] === "Invalid date" || body[key] === "") {
                toast.error("Please enter " + key?.split("_")?.join(" "), {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
                isHaveEmptyField = true;
            }
        }

        Object.keys(body)?.map((key) => formData.append(key, body[key]));

        if (!isHaveEmptyField) {
            if (isEmpty(itemData)) {
                formData.append("report_id", reportId);
                createFinancingPlan(formData);
            } else {
                updateFinancingPlan({ id: itemData?.id, body: formData });
            }
        }
    };

    useEffect(() => {
        getFinancingPlanSources();
    }, []);

    useEffect(() => {
        if (updateFinancingPlanIsSuccess) {
            toast.success(updateFinancingPlanData.message);
            getFinancingPlans();
            setIsOpenEdit(false);
        }
        if (createFinancingPlanIsSuccess) {
            toast.success(createFinancingPlanData.message);
            getFinancingPlans();
            setIsOpenEdit(false);
        }
    }, [
        createFinancingPlanIsSuccess,
        updateFinancingPlanIsSuccess,
        createFinancingPlanData,
        updateFinancingPlanData,
    ]);

    useEffect(() => {
        if (sourcesError) {
            errorHandle(sourcesError);
        }
        if (createFinancingPlanError) {
            errorHandle(createFinancingPlanError);
        }
        if (updateFinancingPlanError) {
            errorHandle(updateFinancingPlanError);
        }
    }, [sourcesError, createFinancingPlanError, updateFinancingPlanError]);

    useEffect(() => {
        selectValues(sourcesData?.data, setSources);

        if (itemData?.source_of_fund && sourcesData?.data) {
            const activeSelectValue = {
                value: Object.keys(sourcesData?.data)?.find(
                    (key) => sourcesData?.data[key] === itemData?.source_of_fund
                ),
                label: itemData?.source_of_fund,
            };
            setState((old) => ({ ...old, source_of_fund: activeSelectValue }));
        }
    }, [sourcesData]);

    useEffect(() => {
        sourcesIsLoading ||
        createFinancingPlanIsLoading ||
        updateFinancingPlanIsLoading
            ? dispatch(setLoader(true))
            : dispatch(setLoader(false));
    }, [
        sourcesIsLoading,
        createFinancingPlanIsLoading,
        updateFinancingPlanIsLoading,
    ]);

    useEffect(function () {
        document.addEventListener("mousedown", handleMouseLeave);
        document.addEventListener("touchstart", handleMouseLeave);

        return () => {
            document.removeEventListener("mousedown", handleMouseLeave);
            document.removeEventListener("touchstart", handleMouseLeave);
        };
    }, [headerRef]);

    return (
        <div
            className="editReport__table__row editReport__table__editRow editFinancing"
            ref={rowRef}
        >
            <Select
                styles={{
                    ...customStyles,
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
                options={sources}
                value={state?.source_of_fund}
                onChange={(selected) =>
                    setState((old) => ({ ...old, source_of_fund: selected }))
                }
            />
            <AxelInput
                id="description"
                name="description"
                className="editReport__input"
                placeholder={descriptionPlaceholder}
                handler={inputHandler}
                value={state?.description ?? ""}
                style={{ width: cellWidths[1] }}
            />
            <AxelInput
                id="amount"
                type="number"
                name="amount"
                placeholder={amountPlaceholder}
                value={state?.amount ?? ""}
                handler={amountInputHandler}
                style={{ width: cellWidths[2] }}
                onKeyDown={handleKeyDown}
            />
            <div className="child">--- ---</div>
            <div className="editReport__table__settingsButtons">
                <button
                    className="editReport__table__cancelBtn"
                    onClick={() => setIsOpenEdit(false)}
                >
                    {cancelTranslation}
                </button>
                <button
                    className="editReport__table__saveBtn"
                    type={"submit"}
                    onClick={(e) => submitHandler(e)}
                >
                    {saveTranslation}
                </button>
            </div>
        </div>
    );
};

export default ItemEdit;
