import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import React, {useEffect, useState} from "react";
import AddLanguage from "./AddLanguage";
import {DeleteModal} from "../Project/DeleteModal";
import {errorHandle} from "../../global/errorHandle";
import {setLoader, setUserData} from "../../store/slice/dashboard";
import {
    useLazyGetLanguagesQuery,
    useCreateLanguageMutation,
    useUpdateLanguageMutation,
    useDeleteLanguageMutation, useGetUserMutation
} from "../../store/service/adbService";
import './index.scss';

const Languages = ({    }) => {
    const dispatch = useDispatch();
    const [languages, setLanguages] = useState({});
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);

    const [getUserHandler] = useGetUserMutation();
    const [deleteLanguage, {
        data: deleteLanguageData,
        error: deleteLanguageError,
        isError: deleteLanguageIsError,
        isSuccess: deleteLanguageIsSuccess,
        isLoading: deleteLanguageIsLoading,
        isFetching: deleteLanguageIsFetching,
    }] = useDeleteLanguageMutation();

    const [createLanguage, {
        data: createLanguagesData,
        error: createLanguagesError,
        isError: createLanguagesIsError,
        isSuccess: createLanguagesIsSuccess,
        isLoading: createLanguagesIsLoading,
        isFetching: createLanguagesIsFetching,
    }] = useCreateLanguageMutation();

    const [updateLanguage, {
        data: updateLanguagesData,
        error: updateLanguagesError,
        isError: updateLanguagesIsError,
        isSuccess: updateLanguagesIsSuccess,
        isLoading: updateLanguagesIsLoading,
        isFetching: updateLanguagesIsFetching,
    }] = useUpdateLanguageMutation();

    const [getLanguages, {
        data: languagesData,
        isSuccess: languagesIsSuccess,
        isLoading: languagesIsLoading,
        isFetching: languagesIsFetching,
    }] = useLazyGetLanguagesQuery();


    const handleEditButton = (item) => {
        setCurrentItem(item);
        setIsOpenModal(true)
    }

    const handleDeleteButton = (item) => {
        setCurrentItem(item);
        setIsOpenDeleteModal(true)
    }

    const handleOpenAddModal = () => {
        setIsOpenModal(true);
        setCurrentItem(null);
    }

    useEffect(()=>{
        getLanguages(undefined, undefined);
    },[]);

    useEffect(()=>{
        if (languagesIsSuccess){
            setLanguages(languagesData?.data);
        }
    },[languagesIsSuccess, languagesData]);

    useEffect(()=>{
        if (createLanguagesIsSuccess){
            toast.success(createLanguagesData?.message);
            setIsOpenModal(false);
        }
    },[
        createLanguagesIsSuccess,
        createLanguagesData
    ]);

    useEffect(()=>{
        if (updateLanguagesIsSuccess){
            toast.success(updateLanguagesData?.message);
            setIsOpenModal(false)
        }
    },[
        updateLanguagesIsSuccess,
        updateLanguagesData,
    ]);

    useEffect(()=>{
        if (deleteLanguageIsSuccess){
            toast.success(deleteLanguageData?.message);
            setIsOpenModal(false);
            getUserHandler().then((res) => {
                localStorage.setItem("local_lan",res?.data?.data?.locale?.code )
                dispatch(setUserData(res?.data?.data));
            });
        }
    },[
        deleteLanguageData,
        deleteLanguageIsSuccess,
    ]);

    useEffect(()=>{
        if (createLanguagesIsError){
           errorHandle(createLanguagesError)
        }
        if (updateLanguagesIsError){
            errorHandle(updateLanguagesError)
        }
        if (deleteLanguageIsError){
            errorHandle(deleteLanguageError)
        }
    },[
        createLanguagesIsError,
        createLanguagesError,
        updateLanguagesIsError,
        updateLanguagesError,
        deleteLanguageError,
        deleteLanguageIsError
    ]);

    useEffect(() => {
        languagesIsLoading ||
        languagesIsFetching ||
        deleteLanguageIsLoading ||
        deleteLanguageIsFetching ||
        updateLanguagesIsLoading ||
        updateLanguagesIsFetching ||
        createLanguagesIsLoading
            ? dispatch(setLoader(true))
            : dispatch(setLoader(false));
    }, [
        languagesIsLoading,
        languagesIsFetching,
        createLanguagesIsLoading,
        updateLanguagesIsLoading,
        updateLanguagesIsFetching,
        createLanguagesIsFetching,
        deleteLanguageIsLoading,
        deleteLanguageIsFetching
    ]);

    return (
        <div className='s-pages languages'>
            <div className="languages__header">
                <h1>
                    Language
                </h1>
                <button
                    className="createButton"
                    onClick={handleOpenAddModal}
                >
                    <img src="/assets/icons/plus.svg" alt="plus" width={22} height={22}/>
                    <span>Add language</span>
                </button>
            </div>
            <div className='languages__content '>
                <div className='table'>
                    <div className="table-header">
                        <div className="table-th px-3">Name</div>
                        <div className="table-th px-3" style={{textAlign: "center"}}>Code</div>
                        <div className="table-th px-3 "></div>
                    </div>
                    {
                        languages?.length > 0 ?
                            <div className="table-body">
                                {languages?.map((item) => (
                                    <div className="table-row" key={item.language} >
                                        <div className="table-td px-3 audit__tableTd " >
                                            <img src={item.image} alt='language-logo' className="languages__table__img"/>
                                            {item.language}
                                        </div>
                                        <div className="table-td px-3 audit__tableTd font-medium" style={{justifyContent: "center"}}>
                                            {item.code}
                                        </div>
                                        <div className="table-td px-3 quarterlyReport__tableSettings">
                                            { item.edit &&
                                                <button onClick={()=> handleEditButton(item)}>
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M11.0555 4.28275L4.21368 11.5246C3.95534 11.7996 3.70534 12.3412 3.65533 12.7162L3.347 15.4163C3.23866 16.3913 3.93867 17.058 4.90536 16.8913L7.58874 16.433C7.96375 16.3663 8.48876 16.0913 8.74709 15.808L15.5889 8.56617C16.7722 7.31614 17.3056 5.89112 15.4639 4.14942C13.6305 2.42439 12.2388 3.03273 11.0555 4.28275Z" stroke="#8A909D" strokeWidth="1.25002" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M9.91309 5.49072C10.2714 7.79076 12.1381 9.54913 14.4548 9.78247" stroke="#8A909D" strokeWidth="1.25002" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>
                                                </button>
                                            }
                                            { item.delete &&
                                                <button onClick={() => handleDeleteButton(item)}>
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M7.50016 5.83333V3.33333C7.50016 3.11232 7.58796 2.90036 7.74424 2.74408C7.90052 2.5878 8.11248 2.5 8.3335 2.5H11.6668C11.8878 2.5 12.0998 2.5878 12.2561 2.74408C12.4124 2.90036 12.5002 3.11232 12.5002 3.33333V5.83333M3.3335 5.83333H16.6668H3.3335ZM8.3335 9.16667V14.1667V9.16667ZM11.6668 9.16667V14.1667V9.16667ZM4.16683 5.83333L5.00016 15.8333C5.00016 16.2754 5.17576 16.6993 5.48832 17.0118C5.80088 17.3244 6.2248 17.5 6.66683 17.5H13.3335C13.7755 17.5 14.1994 17.3244 14.512 17.0118C14.8246 16.6993 15.0002 16.2754 15.0002 15.8333L15.8335 5.83333H4.16683Z" stroke="#8A909D" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>
                                                </button>
                                            }
                                        </div>

                                    </div>
                                ))}
                            </div> :
                            <div style={{ height: '70vh', width: '100%', display: "flex", justifyContent: "center", alignItems: 'center', flexDirection: "column" }}>
                                <img src="/assets/images/projects.png" alt="" />
                                <span style={{ color: "#767676", fontSize: 16, fontWeight: 500 }}>No languages</span>
                            </div>
                    }
                </div>
            </div>
            {isOpenModal && <AddLanguage
                currentItem={currentItem}
                createLanguageRequest={createLanguage}
                updateLanguageRequest={updateLanguage}
                closeModal={() => setIsOpenModal(false)}
            />}
            {isOpenDeleteModal && <DeleteModal
                action={() => deleteLanguage(currentItem.id)}
                id={currentItem?.id}
                setDeleteModal={setIsOpenDeleteModal}
                cencelText='Cancel'
                deleteText='Delete'
                modalTitle='Delete Confirmation'
                clarifyingText={`Are you sure you want delete ${currentItem.language} ?`}
            />}
        </div>

    )
}

export default Languages;