import React from "react";

import "./index.scss";

export default function AxelCheckbox({ id, handler, label, ...inputs }) {
    const { defaultChecked, checked} = inputs
    return (
    <div className="a-checkbox">
      <input
        type="checkbox"
        id={id}
        defaultChecked={defaultChecked}
        checked={checked}
        {...inputs}
        onChange={(e) => {
          handler && handler(e.target.id, e.target.checked);
        }}
        maxLength={190}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
}
