import isEmpty from 'lodash/isEmpty';
import {toast} from "react-toastify";
import React, {useState} from 'react';
import AxelCheckbox from "../../UI/AxelCheckbox";
import './index.scss';

const SaveFilterModal = ({closeModal, statisticsPage, saveFilterRequest}) => {
    const [inputValue, setInputValue] = useState('');
    const [checkBoxIsChecked, setCheckBoxIsChecked] = useState(0);

    const selectAll = (id, checked) => {
        setCheckBoxIsChecked(+checked)
    }
    const handleSaveFilter = ()=> {
        if (isEmpty(inputValue)) {
            toast.error('The name field is required');
        } else saveFilterRequest(inputValue, checkBoxIsChecked);
    }

    return(
        <div className="saveFilterModal">
            <div className="statisticFilter__header">
                <h3>
                    {statisticsPage?.['filter.save_filter.headers.save_filter']}
                </h3>
                <button
                    className="statisticFilter__header__closeIcon"
                    onClick={()=>closeModal(false)}
                >
                    <img src="/assets/icons/close-dark.svg" alt="close" />
                </button>
            </div>
            <div className="saveFilterModal__item">
                <p>{statisticsPage?.['filter.save_filter.titles.filter_name']}</p>
                <input
                    required
                    name="name"
                    placeholder={statisticsPage?.['filter.save_filter.titles.enter_name']}
                    onChange={(e)=>setInputValue(e.target.value)}
                />
            </div>
            <AxelCheckbox
                id="SetAsDefault"
                label={statisticsPage?.['filter.save_filter.actions.set_default']}
                handler={selectAll}
            />
            <button
                className="statisticFilter__form__btnSubmit saveFilterModal__SBtn"
                onClick={handleSaveFilter}
            >
                {statisticsPage?.['filter.save_filter.actions.save']}
            </button>
        </div>
    )
}

export default SaveFilterModal;