import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import Wrapper from "../../components/Wrapper";
import AxelButton from "../../UI/AxelButton";
import EmptyVideoState from "../../components/EmptyVideoState";
import {DeleteModal} from "../../components/Project/DeleteModal";
import AddTrainingVideoModal from "../../components/AddTrainingVideoModal";
import {
    useCreateTrainingVideoMutation,
    useDeleteTrainingVideoMutation,
    useLazyGetAllTrainingVideosQuery,
    useLazyGetAllTrainingVideosPageQuery,
    useUpdateTrainingVideoMutation
} from "../../store/service/adbService";
import {setLoader} from "../../store/slice/dashboard";
import "./index.scss";

const Help = () =>{
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state?.adbReducer?.user);
    const [isModalOpen,setIsModalOpen] = useState(false);
    const [isVideoPlay,setIsVideoPlay] = useState(false);
    const [videoSrc,setVideoSrc] = useState(null);
    const [deleteModal, setDeleteModal] = useState(null);
    const [selectedItemToEdit,setSelectedItemToEdit] = useState(null);
    const [pmdPortalHelpdesk,setPmdPortalHelpdesk] = useState(null);
    const [trainingVideos,setTrainingVideos] = useState([]);
    const [addTrainingVideoModalData,setAddTrainingVideoModalData] = useState([]);
    const[
        getAllTrainingVideosPages,
        {
            data: helpPageData,
            isError: helpPageDataIsError,
            isLoading: helpPageDataLoading,
            isFetching: helpPageDataFetching,
            isSuccess: helpPageDataSuccess,
            error: helpPageDataError,
        }]
     = useLazyGetAllTrainingVideosPageQuery();

    const[
        getAllTrainingVideos,
        {
            data: helpData,
            isError: helpDataIsError,
            isLoading: helpDataLoading,
            isFetching: helpDataFetching,
            isSuccess: helpDataSuccess,
            error: helpDataError,
        }]
        = useLazyGetAllTrainingVideosQuery();

    const [
        createTrainingVideo,
        {
            data: createTrainingVideoData,
            error: createTrainingVideoError,
            isError: createTrainingVideoIsError,
            isSuccess: createTrainingVideoIsSuccess,
            isLoading: createTrainingVideoLoading,
        },
    ] = useCreateTrainingVideoMutation();

    const [
        updateTrainingVideo,
        {
            data: updateTrainingVideoData,
            error: updateTrainingVideoError ,
            isError: updateTrainingVideoIsError,
            isSuccess: updateTrainingVideoIsSuccess,
            isLoading: updateTrainingVideoLoading,
        },
    ] = useUpdateTrainingVideoMutation();

    const [
        deleteTrainingVideo,
        {
            data: deleteTrainingVideoData,
            error: deleteTrainingVideoError,
            isError: deleteTrainingVideoIsError,
            isSuccess: deleteTrainingVideoIsSuccess,
            isLoading: deleteTrainingVideoLoading,
        },
    ] = useDeleteTrainingVideoMutation();

    useEffect(() => {
        if (!localStorage.getItem("user_token")) {
            navigate("/login");
        }
    }, []);

    useEffect(() => {
        getAllTrainingVideosPages();
        getAllTrainingVideos()
    }, []);

    useEffect(() => {
        setIsModalOpen(false);
        setSelectedItemToEdit(null);
        getAllTrainingVideos()
    }, [createTrainingVideoIsSuccess, updateTrainingVideoIsSuccess,deleteTrainingVideoIsSuccess]);

    useEffect(() => {
        !isModalOpen && setSelectedItemToEdit(null);
    }, [isModalOpen]);

    useEffect(() => {
        helpPageDataLoading ||
        createTrainingVideoLoading ||
        updateTrainingVideoLoading ||
        deleteTrainingVideoLoading
        ? dispatch(setLoader(true))
        : dispatch(setLoader(false));
    }, [
        helpPageDataLoading,
        createTrainingVideoLoading,
        updateTrainingVideoLoading,
        deleteTrainingVideoLoading
    ]);

    useEffect(() =>{
        if (helpPageData){
            const pmdPortalHelpdesk =helpPageData.data.find(item =>  item.pmd_portal_helpdesk)?.pmd_portal_helpdesk.find(item => item.pmd_portal_helpdesk)?.pmd_portal_helpdesk;
            const trainingVideosData =helpPageData.data.find(item =>  item.training_videos)?.training_videos;
            setPmdPortalHelpdesk({
                    headers:pmdPortalHelpdesk?.find(item => item.headers)?.headers.find(item=>item.pmd_portal_helpdesk).pmd_portal_helpdesk,
                    description:pmdPortalHelpdesk?.find(item => item.titles)?.titles.find(item=>item.pmd_portal_helpdesk_text).pmd_portal_helpdesk_text,
                });
            setTrainingVideos({
                title:trainingVideosData?.find(item => item.training_videos)?.training_videos?.find(item => item.headers)?.headers.find(item=>item.training_videos)?.training_videos,
                addButtonText:trainingVideosData?.find(item => item.training_videos)?.training_videos?.find(item => item.actions)?.actions.find(item=>item.add_video)?.add_video,
                deleteClarifyText: trainingVideosData?.find(item => item.training_videos)?.training_videos?.find(item => item.titles)?.titles?.find(item => item.are_you_sure_you_want_to_delete_video)?.are_you_sure_you_want_to_delete_video,
                deleteBtnText: trainingVideosData?.find(item => item.training_videos)?.training_videos?.find(item => item.actions)?.actions?.find(item => item.delete)?.delete,
                cancelBtnText: trainingVideosData?.find(item => item.training_videos)?.training_videos?.find(item => item.actions)?.actions?.find(item => item.cancel)?.cancel,
            });

            setAddTrainingVideoModalData({
                addTrainingModalTitle:trainingVideosData?.find(item => item.add_training_video)?.add_training_video?.find(item => item.headers)?.headers.find(item=>item.add_training_video)?.add_training_video,
                editTrainingModalTitle:trainingVideosData?.find(item => item.add_training_video)?.add_training_video?.find(item => item.headers)?.headers.find(item=>item.edit_training_video)?.edit_training_video,
                labelData:trainingVideosData?.find(item => item.add_training_video)?.add_training_video.find(item=>item.titles)?.titles,
                formCreateBtnText:trainingVideosData?.find(item => item.add_training_video)?.add_training_video.find(item => item.actions)?.actions.find(item => item.submit).submit,
                formUpdateBtnText:trainingVideosData?.find(item => item.add_training_video)?.add_training_video.find(item => item.actions)?.actions.find(item => item.save).save,
                formPlaceholder: trainingVideosData?.find(item => item.add_training_video)?.add_training_video.find(item => item.placeholder)?.placeholder,
                formActions: trainingVideosData?.find(item => item.add_training_video)?.add_training_video.find(item => item.actions)?.actions,
            })
        }

    },[helpPageData]);

    const selectToEdit = (item) => {
        setSelectedItemToEdit(item);
        setIsModalOpen(true);
    }

    const playVideo = (address) => {
        setIsVideoPlay(true);
        setVideoSrc(address)
    }

    return(
        <Wrapper classname="helpSection">
            {deleteModal?.show && (
                <DeleteModal
                    id={deleteModal?.id}
                    action={deleteTrainingVideo}
                    clarifyingText={trainingVideos?.deleteClarifyText}
                    setDeleteModal={setDeleteModal}
                    isTraining={true}
                    cencelText={trainingVideos?.cancelBtnText}
                    deleteText={trainingVideos?.deleteBtnText}
                />
            )}
            <aside className="container helpSection__aside">
               <div>
                   <h2>
                       {pmdPortalHelpdesk?.headers}
                   </h2>
                   <p>
                       {pmdPortalHelpdesk?.description}
                       <a href="mailto:pmdportalhelpdesk@aptilla.com"> pmdportalhelpdesk@aptilla.com</a>
                   </p>
               </div>
                <img src="assets/icons/note-book.svg" alt="plus icon"/>
            </aside>
            <main className="container">
                <div className="mainHeader">
                    <h2>
                        {trainingVideos?.title}
                    </h2>
                    {user?.permissions.manage_help_video && <AxelButton handler={() => setIsModalOpen(true)}>
                        <img src="/assets/icons/plus.svg" alt="plus"/>
                        {trainingVideos?.addButtonText}
                    </AxelButton>}
                </div>
                {helpData?.data?.length < 1 ? <EmptyVideoState/> :
                    <div className="trainingVideoList">
                        {helpData?.data?.slice()
                            .sort((a, b) => a.title.localeCompare(b.title)).map((item) =>(
                            <div className="trainingVideoList__item" key={item.id}>
                                <button onClick={() => playVideo(item.path)}>
                                    <img src={"/assets/icons/video-play.svg"} alt="plus" className="trainingVideoList__item__playIcon"/>
                                </button>
                                <div className="trainingVideoList__item__textContainer">
                                    <h4>{item?.title}</h4>
                                    <p>{item?.description} </p>
                                </div>
                                {user?.permissions.manage_help_video && <div className="trainingVideoList__item__buttonsContainer">
                                    <button onClick={() => selectToEdit(item)}>
                                        <img src={"/assets/icons/edit-block-icon.svg"} alt="plus"/>
                                    </button>
                                    <button onClick={(e) => {
                                        setDeleteModal({id: item?.id, show: true});
                                    }}>
                                        <img src={"/assets/icons/delete-block-icon.svg"} alt="plus"/>
                                    </button>
                                </div>}
                            </div>
                        ))}
                    </div>
                }
            </main>
            {isModalOpen &&
                <AddTrainingVideoModal
                    playVideo={playVideo}
                    setIsModalOpen={setIsModalOpen}
                    modalData={addTrainingVideoModalData}
                    currentItem={selectedItemToEdit}
                    updateHook={updateTrainingVideo}
                    createHook={createTrainingVideo}
                />}
            {isVideoPlay ? <div className="helpSection__videoModal">
                <div className="helpSection__videoModal__container">
                      <div className="helpSection__videoModal__container__frame">
                          <video controls autoPlay  playsInline>
                              <source src={videoSrc} type="video/mp4"/>
                              <source src={videoSrc} type="video/mov"/>
                              Your browser does not support HTML video
                          </video>
                      </div>
                    <button
                        onClick={() => {
                            setVideoSrc(null);
                            setIsVideoPlay(false);
                        }}
                        className="helpSection__videoModal__closeIcon"
                    >
                        <img src="/assets/icons/close-dark.svg" alt="close" />
                    </button>
                </div>
                <div
                    className="helpSection__videoModal_bg"
                    onClick={() => {
                        setVideoSrc(null);
                        setIsVideoPlay(false);
                    }}
                >

                </div>
            </div>: null}
        </Wrapper>
    )
}


export default Help;