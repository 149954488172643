import React, { useEffect } from "react";
import CustomCheckbox from "../Checkbox";
import useGetAllSectionsHook from "../SectionsHook";

export default function SectionTabContent({downloadTitle, selectAllLabelsTranslation}) {
  const {
    checkboxs,
    setAllSection,
    setSection,
    get,
    allChecked,
    downloadCmp,
    checkeds,
  } = useGetAllSectionsHook();

  useEffect(() => {
    get();
  }, []);
  const renderCheckboxs = () => {
    return checkboxs?.map((checkbox, index) => (
      <CustomCheckbox
        id={checkbox?.id}
        label={checkbox?.label}
        handler={setSection}
        key={index}
        checked={checkbox?.active}
      />
    ));
  };
  return (
    <div className="contract__management-sections">
      <div className="contract__management-form">
        <CustomCheckbox
          id="all"
          label={selectAllLabelsTranslation}
          handler={setAllSection}
          checked={allChecked}
        />
        {renderCheckboxs()}
      </div>
      <div className="df end">
        <button
          disabled={checkeds?.length <= 0}
          onClick={downloadCmp}
          className="contract__management-button"
        >
          <span>
            <img src="/assets/icons/download-icon.svg" alt="download" />
          </span>
          <span>{downloadTitle}</span>
        </button>
      </div>
    </div>
  );
}
