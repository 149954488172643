import axios from "axios";
import {toast} from "react-toastify";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {setLoader} from "../../store/slice/dashboard";

const QuarterlyReportPreviewImg = ({ reportData, setDeleteModal, fetchReportData, translations}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state?.adbReducer?.user);
    const [imageLoader, setImageLoader] = useState(false);
    const [previewStatus, setPreviewStatus] = useState(false);
    const [currentImg, setCurrentImg] = useState("");
    const [showDropDown, setShowDropDown] = useState(false);

    const uploadImage = (e) => {
        dispatch(setLoader(true));
        const headers = {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ` + localStorage?.getItem("user_token"),
        };
        axios
            .post(
                `${process.env.REACT_APP_API_URL}/quarterly-report/report-cover/${reportData?.id}`,
                { cover: e?.target?.files[0] },
                { headers: headers }
            )
            .then(() => {
                fetchReportData(reportData.id);
                dispatch(setLoader(false));
            })
            .catch((err) => {
                dispatch(setLoader(false));
                if (err.response.status === 401) {
                    navigate("/login");
                    localStorage.removeItem("user_token");
                } else {
                    toast.error(
                        err?.response?.data?.errors?.preview_image?.[0] ||
                        err?.response?.data?.errors?.cover?.[0] ||
                        err?.response?.data?.message ||
                        err?.response?.data?.errors?.image[0],
                        {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 8000,
                        }
                    );
                }
            });
    };

    const hideLoader = () => {
        setImageLoader(false);
    };


    return (
        <div className="erOverview__imgContainer">
            {previewStatus && (
                <div className="custom-gallery">
                    <div className="custom-gallery__content">
                        <div
                            className="custom-gallery__closeBtn"
                            onClick={() => setPreviewStatus(false)}
                        >
                            <img
                                className="custom-gallery__close"
                                src="/assets/icons/close.svg"
                                alt="close"
                            />
                        </div>
                        <img
                            className="custom-gallery__img"
                            src={currentImg?.image}
                            alt="photo"
                        />
                    </div>
                    <div
                        className="custom-gallery__bg"
                        onClick={() => {
                            setPreviewStatus(false);
                        }}
                    ></div>
                </div>
            )}
            <div className="project__image">
                { reportData?.cover ? (
                    <>
                        <div
                            style={{ display: imageLoader ? "flex" : "none" }}
                            className="project__wrapper-loader"
                        >
                            <div className="project__loader"></div>
                        </div>
                        <img
                            style={{
                                width: 'auto',
                                display: imageLoader ? "none" : "block",
                                position: "relative",
                            }}
                            className="project__image-main"
                            src={reportData?.cover}
                            alt=""
                            onLoad={hideLoader}
                            onClick={() => {
                                setPreviewStatus(true);
                                setCurrentImg({ image:  reportData?.cover });
                            }}
                        />
                        {user?.permissions?.edit_project && (
                            <>
                                <label className="project__wrapper-menu project-photo">
                                    <input
                                        id="image_2"
                                        type="file"
                                        accept="image/png, image/jpeg"
                                        onChange={(e) => uploadImage(e)}
                                    />
                                    <img
                                        src="/assets/icons/upload.svg"
                                        alt="upload"
                                        onClick={() => {
                                            setCurrentImg({
                                                image:  reportData?.cover,
                                            });
                                        }}
                                    />
                                </label>
                                <img
                                    onClick={() => {
                                        setDeleteModal((old) => ({ ...old, show: true }));
                                    }}
                                    className="project__gallary-deleteSvg main-delete"
                                    src="/assets/icons/delete.svg"
                                    alt=""
                                />
                            </>
                        )}
                        {showDropDown && (
                            <div
                                onMouseLeave={() => setShowDropDown(false)}
                                className="project__wrapper-imageDropDown"
                            >
                                <div className="project__wrapper-dropDownBtn">
                                    <input
                                        type="file"
                                        accept="image/png, image/jpeg"
                                        onChange={(e) => uploadImage(e)}
                                    />
                                    Replace photo
                                </div>
                            </div>
                        )}
                    </>
                ) : (
                    <div className="project__image-upload">
                        <img src="/assets/icons/gallary-icon.svg" alt="" />
                        <p>
                            {translations?.['documents.index.actions.drop_your_image_here_or_select']} <br />
                            <span>{translations?.['documents.index.actions.click_to_browse']}</span>
                        </p>
                        <input
                            type="file"
                            accept="image/png, image/jpeg"
                            onChange={(e) => uploadImage(e)}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default QuarterlyReportPreviewImg;