import React, { useState, useEffect } from 'react';

const Timer = ({ onTimerEnd, classname="" }) => {
    const [seconds, setSeconds] = useState(120);

    useEffect(() => {
        const timer = setInterval(() => {
            setSeconds(prevSeconds => {
                if (prevSeconds === 0) {
                    clearInterval(timer);
                    onTimerEnd(); // Call the callback when the timer ends
                    return 120; // Reset the timer to 120 seconds
                } else {
                    return prevSeconds - 1;
                }
            });
        }, 1000);

        return () => clearInterval(timer);
    }, [onTimerEnd]);

    const displayTime = () => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    return <span className={classname}>{displayTime()}</span>;
};

export default Timer;
