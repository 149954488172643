import isEmpty from "lodash/isEmpty";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import {
    useCreateSubcontractorPaymentsMutation,
    useDeleteSubcontractorPaymentsMutation,
    useLazyGetAllCompaniesQuery,
    useUpdateSubcontractorPaymentsMutation,
} from "../../../../store/service/adbService";
import { DeleteModal } from "../../DeleteModal";
import { useErrorHandler } from "../../../../global/ErrorsHandler";
import { setLoader } from "../../../../store/slice/dashboard";
import { useDispatch, useSelector } from "react-redux";
import useErrorsHandler from "../../../../global/useErrorHandler";
import MoreInfoModal from "../../../MoreInfoModal";

export const SubcontractorPayments = ({ data, glossaryData, translations }) => {
    const dispatch = useDispatch();
    const [subcontractorPayments, setSubcontractorPayments] = useState([]);
    const [tableHeaders, setTableHeaders] = useState([]);
    const [infoModal, setInfoModal] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const user = useSelector((state) => state?.adbReducer?.user);
    const [newItem, setNewItem] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);
    const [show, setShow] = useState(true);
    const [showByActive, setShowByActive] = useState(true);
    const [hasArchived, setHasArchived] = useState(false);
    const [editable, setEditable] = useState(false);
    const [moreInfo, setMoreInfo] = useState(null);
    const [options, setOptions] = useState([]);
    const [currentRow, setCurrentRow] = useState(null);
    const scrollRef = useRef(null);

    const [
        getCompanies,
        {
            data: companies,
            error: companiesError,
            isLoading: companiesIsLoading,
            isError: companiesIsError,
            isSuccess: companiesIsSuccess,
        },
    ] = useLazyGetAllCompaniesQuery();

    useEffect(() => {
        getCompanies({
            per_page: 1000,
        });
    }, []);

    const [
        createSubcontractorPayments,
        {
            data: createSubcontractorPaymentsData,
            isError: createSubcontractorPaymentsIsError,
            isLoading: createSubcontractorPaymentsIsLoading,
            isSuccess: createSubcontractorPaymentsIsSuccess,
            error: createSubcontractorPaymentsError,
        },
    ] = useCreateSubcontractorPaymentsMutation();

    const [
        updateSubcontractorPayments,
        {
            data: updateSubcontractorPaymentsData,
            isError: updateSubcontractorPaymentsIsError,
            isLoading: updateSubcontractorPaymentsIsLoading,
            isSuccess: updateSubcontractorPaymentsIsSuccess,
            error: updateSubcontractorPaymentsError,
        },
    ] = useUpdateSubcontractorPaymentsMutation();

    const [
        deleteSubcontractorPayments,
        {
            data: deleteSubcontractorPaymentsData,
            isError: deleteSubcontractorPaymentsIsError,
            isLoading: deleteSubcontractorPaymentsIsLoading,
            isSuccess: deleteSubcontractorPaymentsIsSuccess,
            error: deleteSubcontractorPaymentsError,
        },
    ] = useDeleteSubcontractorPaymentsMutation();

    useEffect(() => {
        if(translations){
            const headers = [
                {
                    tooltip_name: translations?.['payments.subcontractor_payments.table_headers.subcontractor_name'],
                    tooltip_text: translations?.['payments.subcontractor_payments.tooltips.subcontractor_name']
                },
                {
                    tooltip_name: translations?.['payments.subcontractor_payments.table_headers.role'],
                    tooltip_text: translations?.['payments.subcontractor_payments.tooltips.role']
                },
                {
                    tooltip_name: translations?.['payments.subcontractor_payments.table_headers.percent_of_work_completed'],
                    tooltip_text: translations?.['payments.subcontractor_payments.tooltips.percent_of_work_completed']
                },
                {
                    tooltip_name: translations?.['payments.subcontractor_payments.table_headers.percent_of_contract_invoiced'],
                    tooltip_text: translations?.['payments.subcontractor_payments.tooltips.percent_of_contract_invoiced']
                },
                {
                    tooltip_name: translations?.['payments.subcontractor_payments.table_headers.percent_of_contract_paid'],
                    tooltip_text: translations?.['payments.subcontractor_payments.tooltips.percent_of_contract_paid']
                },
                {
                    tooltip_name: translations?.['payments.subcontractor_payments.table_headers.issues_or_disputes'],
                    tooltip_text: translations?.['payments.subcontractor_payments.tooltips.issues_or_disputes']
                },
            ]
            setTableHeaders(
                headers.map((item) => ({
                    ...item,
                    showTooltip: false,
                }))
            );
        }
    }, [translations])

    useEffect(() => {
        if (glossaryData?.data?.length) {
            setMoreInfo(
                glossaryData?.data?.filter(
                    (el) => el?.section_name === "Subcontractor Payments"
                )[0]
            );
        }
        if (data) {
            let parsedData= data?.subcontractor_payments?.map((el) => ({
                ...el,
                edit: false,
                company: { value: el?.company?.id, label: el?.company?.long_name, archived: el?.archived, },
            }));
            let isHaveArchived = isEmpty(parsedData.find(item => item.archived===1));
            setHasArchived(!isHaveArchived);
            if (showByActive===true && !isHaveArchived){
                const filteredData =  parsedData.filter(item => item.archived ===0);
                setSubcontractorPayments(filteredData);
            } else setSubcontractorPayments(parsedData);
        }
        if (data?.responsible_party) {
            let s = data?.responsible_party?.map((item) => {
                return {
                    value: item?.id,
                    label: item?.short_name,
                };
            });
            setOptions(s);
        }
    }, [
        data,
        glossaryData,
        showByActive,
        deleteSubcontractorPaymentsIsError,
        createSubcontractorPaymentsIsError,
        updateSubcontractorPaymentsIsError,
    ]);

    useEffect(() => {
        const d = () => {
            setSubcontractorPayments((old) => {
                let data = old?.map((item) => ({ ...item, edit: false }));
                return data;
            });
            dispatch(setLoader(false));
        };

        updateSubcontractorPaymentsIsLoading ||
        deleteSubcontractorPaymentsIsLoading ||
        createSubcontractorPaymentsIsLoading
            ? dispatch(setLoader(true))
            : d();
    }, [
        createSubcontractorPaymentsIsLoading,
        updateSubcontractorPaymentsIsLoading,
        deleteSubcontractorPaymentsIsLoading,
    ]);

    const [errorHandler, setErrorHandler] = useErrorHandler([
        createSubcontractorPaymentsError,
        updateSubcontractorPaymentsError,
    ]);

    const customErrorHandler = useErrorsHandler();

    useEffect(() => {
        customErrorHandler.error(deleteSubcontractorPaymentsError);
    }, [deleteSubcontractorPaymentsError]);

    const setterData = (input) => {
        const body = {
            project_id: data?.id,
            subcontractor_name: input?.subcontractor_name,
            invoiced: input?.invoiced,
            paid: input?.paid,
            role: input?.role,
            percent_work: input?.percent_work,
            issues: input?.issues,
        };

        let s = [];

        for (let key in body) {
            if (key !== "comment") {
                if (!body[key] || body[key] === "Invalid date" || body[key] === "") {
                    toast.error("Please enter " + key?.split("_")?.join(" "), {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    });
                    s.push("false");
                }
            }
        }
        if (body?.percent_work > 100) {
            toast.error("The percent work must not be greater than 100.", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
            s.push("false");
        }

        if (s.length === 0) {
            return body;
        } else {
            return null;
        }
    };
    const submitDispute = (e, item) => {
        e.preventDefault();
        if (newItem) {
            const dataCreated = setterData(currentItem);
            if (dataCreated) {
                createSubcontractorPayments(dataCreated);
                setCurrentItem(null);
                setNewItem(false);
                setCurrentRow(null);
            }
        } else {
            const dataUpdated = setterData(currentItem);
            if (dataUpdated) {
                updateSubcontractorPayments({
                    id: item?.id,
                    body: dataUpdated,
                });
                setCurrentItem(null);
                setNewItem(false);
                setCurrentRow(null);
            }
        }
    };

    return (
        <div className="project__subcontractor">
            {infoModal && (
                <MoreInfoModal
                    title={translations?.['payments.subcontractor_payments.headers.subcontractor_payments']}
                    moreInfo={translations?.['payments.subcontractor_payments.more_info.subcontractor_payments']}
                    close={()=> setInfoModal(false)}
                />
            )}
            {deleteModal?.show && (
                <DeleteModal
                    id={deleteModal?.id}
                    action={deleteSubcontractorPayments}
                    setDeleteModal={setDeleteModal}
                    cencelText={translations?.['main.main.actions.cancel']}
                    deleteText={translations?.['main.main.actions.delete']}
                    modalTitle={translations?.['main.main.headers.confirm_your_action']}
                    clarifyingText={translations?.['main.main.titles.you_are_going_to_delete_this_row']}
                />
            )}
            <div className={`project__block ${show ? "" : "close"}`}>
                <div className="project__block-subtitles">
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div className="project__block-subtitle">
                            {translations?.['payments.subcontractor_payments.headers.subcontractor_payments']}
                        </div>
                        <span
                            className="project__block-more"
                            onClick={() => setInfoModal(!infoModal)}
                        >
              <span className="img">
                <img src="/assets/icons/info.svg" alt="info" />
              </span>
              <span onClick={() => setInfoModal(true)} className="text">
                {translations?.['payments.subcontractor_payments.titles.more_info']}
              </span>
            </span>
                    </div>
                    <div className="project__block__buttonsContainer">
                        {hasArchived && <button
                            onClick={() => setShowByActive(!showByActive)}
                            className="project__block-add-row">
                            <span> <img src="/assets/icons/blue-eye.svg" alt="plus"/></span>
                            <span>{showByActive ? translations?.['environment_social_health_safety_and_security_obligations.ssemp_activity_monitoring.actions.show_all']
                                : translations?.['environment_social_health_safety_and_security_obligations.ssemp_activity_monitoring.actions.show_active']}</span>
                        </button>}
                        {user?.permissions?.edit_project && (
                            <button
                                onClick={() => {
                                    let randomId = Math.floor(Math.random() * 1000);
                                    scrollRef?.current?.scrollIntoView();
                                    setCurrentRow(randomId);
                                    if (!newItem) {
                                        setNewItem(true);
                                        setEditable(true);
                                        setSubcontractorPayments((old) => [
                                            ...old,
                                            {
                                                id: randomId,
                                                invoiced: "",
                                                paid: "",
                                                company_id: "",
                                                role: "",
                                                percent_work: "",
                                                issues: "",
                                                edit: true,
                                            },
                                        ]);
                                        setCurrentItem({
                                            id: randomId,
                                            company_id: "",
                                            role: "",
                                            percent_work: "",
                                            invoiced: "",
                                            paid: "",
                                            issues: "",
                                            edit: true,
                                        });
                                    }
                                }}
                                className="project__block-add-row"
                            >
                <span>
                  <img src="/assets/icons/blue-plus.svg" alt="plus" />
                </span>
                                <span>{translations?.['payments.subcontractor_payments.actions.add_row']}</span>
                            </button>
                        )}
                    </div>
                </div>
                <div
                    className={`project__block-content permits
                            ${editable ? "editable" : ""}
                        `}
                >
                    <div
                        className={`project__block-table project-table ${
                            currentItem ? "n" : ""
                        }`}
                    >
                        <div className={`project-table__header`} ref={scrollRef}>
                            {tableHeaders?.map((el, idx) => {
                                return (
                                    <div
                                        key={idx}
                                        onMouseEnter={(event) => {
                                            setTableHeaders(
                                                tableHeaders.map((item) => ({
                                                    ...item,
                                                    showTooltip: el.tooltip_name === item.tooltip_name
                                                })));
                                        }}
                                        onMouseLeave={() => {
                                            setTableHeaders(
                                                tableHeaders.map((item) => ({
                                                    ...item,
                                                    showTooltip: false,
                                                }))
                                            );
                                        }}
                                        className="project-table__th"
                                    >
                                        {el?.showTooltip ? (
                                            <span
                                                className={
                                                    el?.tooltip_text?.length > 20
                                                        ? "lengthy"
                                                        : "not-lengthy"
                                                }
                                            >
                        {el?.tooltip_text}
                      </span>
                                        ) : (
                                            ""
                                        )}
                                        {el?.tooltip_name}
                                    </div>
                                );
                            })}
                        </div>
                        <div className="project-table__body">
                            {subcontractorPayments?.map((item, index) => (
                                <div key={index}>
                                    {index !== 0 && currentRow === item?.id && (
                                        <div className={`project-table__header`} key={index}>
                                            {tableHeaders?.map((el, idx) => {
                                                return (
                                                    <div key={idx} className="project-table__th">
                                                        {el?.showTooltip ? (
                                                            <span
                                                                className={
                                                                    el?.tooltip_text?.length > 20
                                                                        ? "lengthy"
                                                                        : "not-lengthy"
                                                                }
                                                            >
                                {el?.tooltip_text}
                              </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {el?.tooltip_name}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                    <form
                                        className={`project-table__row ${
                                            item?.edit ? "edit" : currentItem ? "n" : ""
                                        } ${
                                            Boolean(item.submitted) ||
                                            !user?.permissions?.edit_project
                                                ? "submitted"
                                                : ""
                                        }`}
                                        id={item?.id}
                                        key={item?.id}
                                        onSubmit={(e) => submitDispute(e, item)}
                                    >
                                        <div className="project-table__td">
                                            <div className="project-table__td">
                                                <span>{item?.subcontractor_name}</span>
                                                <div className="project__block-input project-table__field">
                          <textarea
                              maxLength={190}
                              defaultValue={item?.subcontractor_name}
                              onChange={(e) => {
                                  setCurrentItem((old) => ({
                                      ...old,
                                      subcontractor_name: e.target.value,
                                  }));
                              }}
                          />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="project-table__td">
                                            <span>{item?.role}</span>
                                            <div className="project__block-input project-table__field">
                                                <input
                                                    maxLength={190}
                                                    type="text"
                                                    defaultValue={item?.role}
                                                    onChange={(e) => {
                                                        setCurrentItem((old) => ({
                                                            ...old,
                                                            role: e.target.value,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="project-table__td">
                                            <span>{item?.percent_work}%</span>
                                            <div className="project__block-input project-table__field percent">
                                                <input
                                                    maxLength={190}
                                                    type="number"
                                                    value={
                                                        currentItem?.percent_work
                                                            ? currentItem?.percent_work
                                                            : item?.percent_work
                                                    }
                                                    onChange={(e) => {
                                                        if (e?.target?.value <= 100) {
                                                            setCurrentItem((old) => ({
                                                                ...old,
                                                                percent_work: e?.target?.value,
                                                            }));
                                                        }
                                                    }}
                                                />
                                                <p>%</p>
                                            </div>
                                        </div>
                                        <div className="project-table__td">
                                            <span>{item?.invoiced}%</span>
                                            <div className="project__block-input project-table__field percent">
                                                <input
                                                    maxLength={190}
                                                    type="number"
                                                    value={
                                                        currentItem?.invoiced
                                                            ? currentItem?.invoiced
                                                            : item?.invoiced
                                                    }
                                                    onChange={(e) => {
                                                        if (e?.target?.value <= 100) {
                                                            setCurrentItem((old) => ({
                                                                ...old,
                                                                invoiced: e?.target?.value,
                                                            }));
                                                        }
                                                    }}
                                                />
                                                <p>%</p>
                                            </div>
                                        </div>
                                        <div className="project-table__td">
                                            <span>{item?.paid}%</span>
                                            <div className="project__block-input project-table__field percent">
                                                <input
                                                    maxLength={190}
                                                    type="number"
                                                    value={
                                                        currentItem?.paid ? currentItem?.paid : item?.paid
                                                    }
                                                    onChange={(e) => {
                                                        if (e?.target?.value <= 100) {
                                                            setCurrentItem((old) => ({
                                                                ...old,
                                                                paid: e?.target?.value,
                                                            }));
                                                        }
                                                    }}
                                                />
                                                <p>%</p>
                                            </div>
                                        </div>
                                        <div className="project-table__td">
                                            <span>{item?.issues}</span>
                                            <div className="project__block-input project-table__field">
                        <textarea
                            maxLength={190}
                            type="text"
                            defaultValue={item?.issues}
                            onChange={(e) => {
                                setCurrentItem((old) => ({
                                    ...old,
                                    issues: e.target.value,
                                }));
                            }}
                        />
                                            </div>
                                        </div>
                                        <div className="project__block-btns">
                                            {currentItem ? (
                                                <div
                                                    className="project__block-cancel"
                                                    onClick={() => {
                                                        setCurrentRow(null);
                                                        let array = subcontractorPayments?.map((item) => {
                                                            item.edit = false;
                                                            return item;
                                                        });
                                                        setCurrentItem(null);
                                                        setSubcontractorPayments(array);
                                                        if (newItem) {
                                                            setNewItem(false);
                                                            let s = subcontractorPayments?.filter(
                                                                (el, i) =>
                                                                    i !== subcontractorPayments.length - 1
                                                            );
                                                            setSubcontractorPayments(s);
                                                        }
                                                    }}
                                                >
                                                    <img src="/assets/icons/close-mark.svg" alt="close" />
                                                    {translations?.['payments.subcontractor_payments.actions.cancel']}
                                                </div>
                                            ) : (
                                                <div
                                                    className="project__block-delete"
                                                    onClick={() => {
                                                        setDeleteModal({ id: item?.id, show: true });
                                                    }}
                                                >
                                                    <img src="/assets/icons/trash-icon.svg" alt="arrow" />
                                                    {translations?.['payments.subcontractor_payments.actions.delete']}
                                                </div>
                                            )}
                                            {currentItem ? (
                                                <button className="project__block-save">
                                                    <img src="/assets/icons/save-arrow.svg" alt="arrow" />
                                                    {translations?.['payments.subcontractor_payments.actions.save']}
                                                </button>
                                            ) : (
                                                <div
                                                    onClick={() => {
                                                        setCurrentRow(item?.id);
                                                        setCurrentItem(item);
                                                        setEditable(true);
                                                        setSubcontractorPayments(() => {
                                                            let array = subcontractorPayments?.map((elem) => {
                                                                if (elem?.id === item?.id) {
                                                                    elem.edit = true;
                                                                }
                                                                return elem;
                                                            });
                                                            return array;
                                                        });
                                                    }}
                                                    className="project__block-update"
                                                >
                                                    {translations?.['payments.subcontractor_payments.actions.update']}
                                                </div>
                                            )}
                                        </div>
                                    </form>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
