import React, { useEffect, useState } from "react";
import { useUpdateHealthSafetyStatisticsMutation } from "../../../../store/service/adbService";
import { setLoader } from "../../../../store/slice/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useErrorHandler } from "../../../../global/ErrorsHandler";

export const HealthStatistics = ({ id, healthData, translations }) => {
  const dispatch = useDispatch();
  const [currentItem, setCurrentItem] = useState({});
  const [manpowerEdit, setManpowerEdit] = useState(false);
  const [ltiEdit, setLtiEdit] = useState(false);
  const [ltifrEdit, setLtifrEdit] = useState(false);
  const [faiEdit, setFaiEdit] = useState(false);
  const [mtiEdit, setMtiEdit] = useState(false);
  const [snmEdit, setSnmEdit] = useState(false);
  const [trifrEdit, setTrifrEdit] = useState(false);
  const user = useSelector((state) => state?.adbReducer?.user);

  const [
    updateHealthSafetyStatistics,
    {
      data: updateHealthSafetyStatisticsData,
      isError: updateHealthSafetyStatisticsIsError,
      isLoading: updateHealthSafetyStatisticsIsLoading,
      isSuccess: updateHealthSafetyStatisticsIsSuccess,
      error: updateHealthSafetyStatisticsError,
    },
  ] = useUpdateHealthSafetyStatisticsMutation();

  useEffect(() => {
    const d = () => {
      dispatch(setLoader(false));
    };
    updateHealthSafetyStatisticsIsLoading ? dispatch(setLoader(true)) : d();
  }, [updateHealthSafetyStatisticsIsLoading]);

  useEffect(() => {
    setManpowerEdit(false);
    setLtiEdit(false);
    setLtifrEdit(false);
    setFaiEdit(false);
    setMtiEdit(false);
    setSnmEdit(false);
    setTrifrEdit(false);
  }, [updateHealthSafetyStatisticsIsSuccess, healthData]);
  const submitHandler = () => {
    const dataUpdated = {
      average_daily_manpower: currentItem?.average_daily_manpower,
      lti: currentItem?.lti,
      ltifr: currentItem?.ltifr,
      fai: currentItem?.fai,
      mti: currentItem?.mti,
      snm: currentItem?.snm,
      trifr: currentItem?.trifr,
    };

    if (currentItem) {
      updateHealthSafetyStatistics({ id, body: dataUpdated });
      // updateExecutiveSummary({ id, body: { resume: text } });
    } else {
      toast.error("sad", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const [errorHandler, setErrorHandler] = useErrorHandler([
    updateHealthSafetyStatisticsError,
  ]);

  return (
      <div className="project__health">
        <div className="project__block-subtitles">
          <div className="project__block-subtitle">
            {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_statistics.headers.health_and_safety_statistics']}
          </div>
        </div>
        <div className={`project__health-box`}>
          <p>{translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_statistics.titles.average_daily_manpower']}</p>
          {manpowerEdit ? (
              <div className="project__block-input project-table__field">
                <input
                    type="number"
                    maxLength={5}
                    defaultValue={
                      currentItem?.average_daily_manpower
                          ? currentItem?.average_daily_manpower
                          : healthData?.health_safety_statistics?.average_daily_manpower
                    }
                    onChange={({target}) => {
                      const newText = target.value
                      if (newText?.length < 6) {
                        setCurrentItem((old) => ({
                          ...old,
                          average_daily_manpower: newText,
                        }));
                      }
                    }}
                />
              </div>
          ) : (
              <div className="project__health-subBox">
            <span className="project__status-text">
              {healthData?.health_safety_statistics?.average_daily_manpower
                  ? healthData?.health_safety_statistics?.average_daily_manpower
                  : "-"}
            </span>
              </div>
          )}
          {user?.permissions?.edit_project && (
              <div className="project__block-btns">
                {manpowerEdit && (
                    <div
                        className="project__block-cancel"
                        onClick={() => {
                          setManpowerEdit(false);
                        }}
                    >
                      <img src="/assets/icons/close-mark.svg" alt="close" />
                      {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_statistics.actions.cancel']}
                    </div>
                )}
                {manpowerEdit ? (
                    <button
                        onClick={submitHandler}
                        type="submit"
                        className="project__block-save"
                    >
                      <img src="/assets/icons/save-arrow.svg" alt="arrow" />
                      {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_statistics.actions.save']}
                    </button>
                ) : (
                    <div
                        onClick={() => {
                          setManpowerEdit(true);
                        }}
                        className="project__block-update"
                    >
                      {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_statistics.actions.update']}
                    </div>
                )}
              </div>
          )}
        </div>
        <div className={`project__health-box`}>
          <p>{translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_statistics.titles.lost_time_injury']}</p>
          {ltiEdit ? (
              <div className="project__block-input project-table__field">
                <input
                    type={"number"}
                    maxLength={5}
                    defaultValue={
                      currentItem?.lti
                          ? currentItem?.lti
                          : healthData?.health_safety_statistics?.lti
                    }
                    onChange={({target}) => {
                      let newText = target.value
                      if (newText?.length < 6) {
                        setCurrentItem((old) => ({
                          ...old,
                          lti: newText,
                        }));
                      }
                    }}
                />
              </div>
          ) : (
              <div className="project__health-subBox">
            <span className="project__status-text">
              {healthData?.health_safety_statistics?.lti
                  ? healthData?.health_safety_statistics?.lti
                  : "-"}
            </span>
              </div>
          )}
          {user?.permissions?.edit_project && (
              <div className="project__block-btns">
                {ltiEdit && (
                    <div
                        className="project__block-cancel"
                        onClick={() => {
                          setLtiEdit(false);
                        }}
                    >
                      <img src="/assets/icons/close-mark.svg" alt="close" />
                      {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_statistics.actions.cancel']}
                    </div>
                )}
                {ltiEdit ? (
                    <button
                        onClick={submitHandler}
                        type="submit"
                        className="project__block-save"
                    >
                      <img src="/assets/icons/save-arrow.svg" alt="arrow" />
                      {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_statistics.actions.save']}
                    </button>
                ) : (
                    <div
                        onClick={() => {
                          setLtiEdit(true);
                        }}
                        className="project__block-update"
                    >
                      {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_statistics.actions.update']}
                    </div>
                )}
              </div>
          )}
        </div>
        <div className={`project__health-box`}>
          <p>{translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_statistics.titles.lost_time_injury_frequency_rate']}</p>
          {ltifrEdit ? (
              <div className="project__block-input project-table__field">
                <input
                    type={"number"}
                    maxLength={5}
                    defaultValue={
                      currentItem?.ltifr
                          ? currentItem?.ltifr
                          : healthData?.health_safety_statistics?.ltifr
                    }
                    onChange={({target}) => {
                      let newText = target.value
                      if (newText?.length < 6) {
                        setCurrentItem((old) => ({
                          ...old,
                          ltifr: newText,
                        }));
                      }
                    }}
                />
              </div>
          ) : (
              <div className="project__health-subBox">
            <span className="project__status-text">
              {healthData?.health_safety_statistics?.ltifr
                  ? healthData?.health_safety_statistics?.ltifr
                  : "-"}
            </span>
              </div>
          )}
          {user?.permissions?.edit_project && (
              <div className="project__block-btns">
                {ltifrEdit && (
                    <div
                        className="project__block-cancel"
                        onClick={() => {
                          setLtifrEdit(false);
                        }}
                    >
                      <img src="/assets/icons/close-mark.svg" alt="close" />
                      {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_statistics.actions.cancel']}
                    </div>
                )}
                {ltifrEdit ? (
                    <button
                        onClick={submitHandler}
                        type="submit"
                        className="project__block-save"
                    >
                      <img src="/assets/icons/save-arrow.svg" alt="arrow" />
                      {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_statistics.actions.save']}
                    </button>
                ) : (
                    <div
                        onClick={() => {
                          setLtifrEdit(true);
                        }}
                        className="project__block-update"
                    >
                      {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_statistics.actions.update']}
                    </div>
                )}
              </div>
          )}
        </div>
        <div className={`project__health-box`}>
          <p>{translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_statistics.titles.first_aid_injury']}</p>
          {faiEdit ? (
              <div className="project__block-input project-table__field">
                <input
                    type={"number"}
                    maxLength={5}
                    defaultValue={
                      currentItem?.fai
                          ? currentItem?.fai
                          : healthData?.health_safety_statistics?.fai
                    }
                    onChange={(e) => {
                      let newText = e.target.value.replace(/\D/g, "");
                      if (newText?.length < 6) {
                        setCurrentItem((old) => ({
                          ...old,
                          fai: newText,
                        }));
                      }
                    }}
                />
              </div>
          ) : (
              <div className="project__health-subBox">
            <span className="project__status-text">
              {healthData?.health_safety_statistics?.fai
                  ? healthData?.health_safety_statistics?.fai
                  : "-"}
            </span>
              </div>
          )}
          {user?.permissions?.edit_project && (
              <div className="project__block-btns">
                {faiEdit && (
                    <div
                        className="project__block-cancel"
                        onClick={() => {
                          setFaiEdit(false);
                        }}
                    >
                      <img src="/assets/icons/close-mark.svg" alt="close" />
                      {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_statistics.actions.cancel']}
                    </div>
                )}
                {faiEdit ? (
                    <button
                        onClick={submitHandler}
                        type="submit"
                        className="project__block-save"
                    >
                      <img src="/assets/icons/save-arrow.svg" alt="arrow" />
                      {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_statistics.actions.save']}
                    </button>
                ) : (
                    <div
                        onClick={() => {
                          setFaiEdit(true);
                        }}
                        className="project__block-update"
                    >
                      {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_statistics.actions.update']}
                    </div>
                )}
              </div>
          )}
        </div>
        <div className={`project__health-box`}>
          <p>{translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_statistics.titles.medical_treatment_injury']}</p>
          {mtiEdit ? (
              <div className="project__block-input project-table__field">
                <input
                    type={"number"}
                    maxLength={5}
                    defaultValue={
                      currentItem?.mti
                          ? currentItem?.mti
                          : healthData?.health_safety_statistics?.mti
                    }
                    onChange={({target}) => {
                      let newText = target.value
                      if (newText?.length < 6) {
                        setCurrentItem((old) => ({
                          ...old,
                          mti: newText,
                        }));
                      }
                    }}
                />
              </div>
          ) : (
              <div className="project__health-subBox">
            <span className="project__status-text">
              {healthData?.health_safety_statistics?.mti
                  ? healthData?.health_safety_statistics?.mti
                  : "-"}
            </span>
              </div>
          )}
          {user?.permissions?.edit_project && (
              <div className="project__block-btns">
                {mtiEdit && (
                    <div
                        className="project__block-cancel"
                        onClick={() => {
                          setMtiEdit(false);
                        }}
                    >
                      <img src="/assets/icons/close-mark.svg" alt="close" />
                      {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_statistics.actions.cancel']}
                    </div>
                )}
                {mtiEdit ? (
                    <button
                        onClick={submitHandler}
                        type="submit"
                        className="project__block-save"
                    >
                      <img src="/assets/icons/save-arrow.svg" alt="arrow" />
                      {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_statistics.actions.save']}
                    </button>
                ) : (
                    <div
                        onClick={() => {
                          setMtiEdit(true);
                        }}
                        className="project__block-update"
                    >
                      {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_statistics.actions.update']}
                    </div>
                )}
              </div>
          )}
        </div>
        <div className={`project__health-box `}>
          <p>{translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_statistics.titles.significant_near_misses']}</p>
          {snmEdit ? (
              <div className="project__block-input project-table__field">
                <input
                    type={"text"}
                    maxLength={5}
                    defaultValue={
                      currentItem?.snm
                          ? currentItem?.snm
                          : healthData?.health_safety_statistics?.snm
                    }
                    onChange={({target}) => {
                      let newText = target.value;
                      if (newText?.length < 6) {
                        setCurrentItem((old) => ({
                          ...old,
                          snm: newText,
                        }));
                      }
                    }}
                />
              </div>
          ) : (
              <div className="project__health-subBox">
            <span className="project__status-text">
              {healthData?.health_safety_statistics?.snm
                  ? healthData?.health_safety_statistics?.snm
                  : "-"}
            </span>
              </div>
          )}
          {user?.permissions?.edit_project && (
              <div className="project__block-btns">
                {snmEdit && (
                    <div
                        className="project__block-cancel"
                        onClick={() => {
                          setSnmEdit(false);
                        }}
                    >
                      <img src="/assets/icons/close-mark.svg" alt="close" />
                      {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_statistics.actions.cancel']}
                    </div>
                )}
                {snmEdit ? (
                    <button
                        onClick={submitHandler}
                        type="submit"
                        className="project__block-save"
                    >
                      <img src="/assets/icons/save-arrow.svg" alt="arrow" />
                      {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_statistics.actions.save']}
                    </button>
                ) : (
                    <div
                        onClick={() => {
                          setSnmEdit(true);
                        }}
                        className="project__block-update"
                    >
                      {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_statistics.actions.update']}
                    </div>
                )}
              </div>
          )}
        </div>
        <div className={`project__health-box`}>
          <p>{translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_statistics.titles.total_recordable_injury_frequency_rate']}</p>
          {trifrEdit ? (
              <div className="project__block-input project-table__field">
                <input
                    type={"text"}
                    maxLength={5}
                    defaultValue={
                      currentItem?.trifr
                          ? currentItem?.trifr
                          : healthData?.health_safety_statistics?.trifr
                    }
                    onChange={({target}) => {
                      let newText = target.value;
                      if (newText?.length < 6) {
                        setCurrentItem((old) => ({
                          ...old,
                          trifr: newText,
                        }));
                      }
                    }}
                />
              </div>
          ) : (
              <div className="project__health-subBox">
            <span className="project__status-text">
              {healthData?.health_safety_statistics?.trifr
                  ? healthData?.health_safety_statistics?.trifr
                  : "-"}
            </span>
              </div>
          )}
          {user?.permissions?.edit_project && (
              <div className="project__block-btns">
                {trifrEdit && (
                    <div
                        className="project__block-cancel"
                        onClick={() => {
                          setTrifrEdit(false);
                        }}
                    >
                      <img src="/assets/icons/close-mark.svg" alt="close" />
                      {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_statistics.actions.cancel']}
                    </div>
                )}
                {trifrEdit ? (
                    <button
                        onClick={submitHandler}
                        type="submit"
                        className="project__block-save"
                    >
                      <img src="/assets/icons/save-arrow.svg" alt="arrow" />
                      {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_statistics.actions.save']}
                    </button>
                ) : (
                    <div
                        onClick={() => {
                          setTrifrEdit(true);
                        }}
                        className="project__block-update"
                    >
                      {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_statistics.actions.update']}
                    </div>
                )}
              </div>
          )}
        </div>
      </div>
  );
};
