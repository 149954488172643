import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import adbSlice from "./slice/dashboard";
import mfaSlice from "./slice/mfAuth";
import forgetSlice from "./slice/forgetSlice";
import statisticSlice from "./slice/statistic";
import { adbApi } from "./service/adbService";
import quarterlySlice from "./slice/quarterly.slice";

export const store = configureStore({
  reducer: {
    adbReducer: adbSlice,
    mfaReducer: mfaSlice,
    forgetReducer: forgetSlice,
    statisticReducer: statisticSlice,
    quarterlyReducer: quarterlySlice,
    [adbApi.reducerPath]: adbApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({serializableCheck: false}).concat(adbApi.middleware),
});

setupListeners(store.dispatch);
