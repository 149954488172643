import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {useGenerateErrorMutation} from "../../store/service/adbService";


export const Page404 = () => {
    const location = useLocation();
    const [generateError] = useGenerateErrorMutation();

    useEffect(() => {
        generateError({
            code: 404,
            message: "Not Found",
            referer: `${process.env.REACT_APP_API_URL}${location.pathname}`,
        })
    },[location.pathname])

  return (
    <div className="page404">
      <h1>404</h1>
      <h2>Page not found</h2>
    </div>
  );
};
