import React, {useEffect} from "react";
import { useState } from "react";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import {
  useLazyGetLogOutTranslationsQuery,
  useLazyLogoutQuery,
  useLazyGetSidebarTranslationsQuery
} from "../store/service/adbService";
import LanguageSelect from "./LanguageSelect";
import {setLoader} from "../store/slice/dashboard";
import {errorHandle} from "../global/errorHandle";

export default function Sidebar() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.adbReducer?.user);
  const [logout, {data: logOutData, error, isError, isSuccess, isLoading}] = useLazyLogoutQuery();
  const [isMenuItemLongerText, setIsMenuItemLongerText] = useState(false)

  const [modal, setModal] = useState(false);
  const [menuTranslations, setMenuTranslations] = useState({});
  const [logoutTranslations, setLogoutTranslations] = useState({})
  const navigate = useNavigate();

  const [getLogOutTranslations, {data: logOutTranslationsData}] = useLazyGetLogOutTranslationsQuery();
  const [getSidebarTranslations, {data: sidebarTranslationsData}] = useLazyGetSidebarTranslationsQuery();

  const renderUserName = () => {
    let nameArray = user?.full_name?.split(" ");
    let name = nameArray?.map((item) => {
      return item[0];
    });
    return name?.join("") ?? "";
  };

  const logOut =  () => {
    logout();
  }

  useEffect(() => {
    getSidebarTranslations();
    getLogOutTranslations()
  }, [])

  useEffect(() => {
    if(sidebarTranslationsData){
      setMenuTranslations(sidebarTranslationsData?.data?.translations);

      for (const [, value] of Object.entries(sidebarTranslationsData?.data?.translations)) {
        if (value?.length > 26) {
          setIsMenuItemLongerText(true);
        }
      }
    }
  }, [sidebarTranslationsData])

  useEffect(() => {
    if(logOutTranslationsData){
      setLogoutTranslations(logOutTranslationsData?.data?.translations)
    }
  }, [logOutTranslationsData])

  useEffect(() => {
    if (isSuccess){
      toast.success(logOutData?.message);
      let rememberDeviceData = localStorage.getItem('rememberDevice');
      let storageData = JSON.parse(rememberDeviceData);
      let isRememberDevice = storageData?.isRemember;
      if(!isRememberDevice) {
        localStorage.clear();
      } else{
        localStorage.removeItem("user_token");
      }
      navigate("/login");
    }
  }, [isSuccess, logOutData]);

  useEffect(() => {
    isLoading ?
        dispatch(setLoader(true)) :
        dispatch(setLoader(false));
  }, [isLoading]);

  useEffect(() => {
    if (isError) {
      errorHandle(error);
    }
  }, [isError, error]);


  return (
      <div className="sidebar">
        <div className="top">
          <div className="sidebar__logo">
            <NavLink to="/project-list">
              <img src="/image.png" alt="logo" />
            </NavLink>
          </div>
          <LanguageSelect activeLanguage={user?.locale ? user?.locale : 'en'}/>
          <div className={`sidebar__content ${isMenuItemLongerText ? 'sidebar__content__higherItem': ''}`}>
            <div className="sidebar__item">
              <NavLink to="/project-list">
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                >
                  <path
                      d="M18.25 2.75H5.75C4.09315 2.75 2.75 4.09315 2.75 5.75V18.25C2.75 19.9069 4.09315 21.25 5.75 21.25H18.25C19.9069 21.25 21.25 19.9069 21.25 18.25V5.75C21.25 4.09315 19.9069 2.75 18.25 2.75Z"
                      stroke="#0052CC"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                  />
                  <path
                      d="M6.77002 12H8.38002L10.27 15L13.73 9L15.62 12H17.23"
                      stroke="#0052CC"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                  />
                </svg>
                <span>{menuTranslations?.['index.index.titles.project_list']}</span>
              </NavLink>
            </div>
            {/*HIDE for PROD*/}
            <div className="sidebar__item">
              <NavLink to="/quarterly-reports">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" >
                  <path d="M10.43 13.5601H18.11C18.11 17.7801 14.64 21.2501 10.43 21.2501C6.21 21.2501 2.75 17.7801 2.75 13.5601C2.75 9.34012 6.22 5.87012 10.43 5.87012V13.5601Z" stroke="#0062FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M13.57 10.44V2.75C15.61 2.75 17.56 3.56 19 5C20.44 6.44 21.25 8.4 21.25 10.43H13.57V10.44Z" stroke="#0062FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <span>{menuTranslations?.['index.index.titles.quarterly_report_list']}</span>
              </NavLink>
            </div>
            {user?.permissions?.secondary_pages && (
                <div className="sidebar__item">
                  <NavLink to="/secondary-pages">
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                    >
                      <path
                          d="M16.5703 21.25H8.4603C6.7303 21.25 5.32031 19.84 5.32031 18.11V5.89C5.32031 4.16 6.7303 2.75 8.4603 2.75H16.5703C18.3003 2.75 19.7103 4.16 19.7103 5.89V18.11C19.7103 19.84 18.3003 21.25 16.5703 21.25Z"
                          stroke="#0052CC"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                      />
                      <path
                          d="M6.35004 5.83008H4.29004"
                          stroke="#0052CC"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                      />
                      <path
                          d="M6.35004 12H4.29004"
                          stroke="#0052CC"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                      />
                      <path
                          d="M6.35004 18.1699H4.29004"
                          stroke="#0052CC"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                      />
                      <path
                          d="M15.5098 5.83008H9.50977V9.94008H15.5098V5.83008Z"
                          stroke="#0052CC"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                      />
                      <path
                          d="M9.50977 14.0601H15.5098"
                          stroke="#0052CC"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                      />
                      <path
                          d="M9.50977 17.1401H15.5098"
                          stroke="#0052CC"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                      />
                    </svg>
                    <span>{menuTranslations?.['index.index.titles.secondary_tables']}</span>
                  </NavLink>
                </div>
            )}
            {user?.permissions?.glossary && (
                <div className="sidebar__item">
                  <NavLink to="/manage-glossary">
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                    >
                      <path
                          d="M19.1904 19.1899V21.2499"
                          stroke="#0052CC"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                      />
                      <path
                          d="M19.1904 2.75V15.08"
                          stroke="#0052CC"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                      />
                      <path
                          d="M19.1899 19.2001C20.3276 19.2001 21.2499 18.2778 21.2499 17.1401C21.2499 16.0024 20.3276 15.0801 19.1899 15.0801C18.0522 15.0801 17.1299 16.0024 17.1299 17.1401C17.1299 18.2778 18.0522 19.2001 19.1899 19.2001Z"
                          stroke="#0052CC"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                      />
                      <path
                          d="M4.80957 19.1899V21.2499"
                          stroke="#0052CC"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                      />
                      <path
                          d="M4.80957 2.75V15.08"
                          stroke="#0052CC"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                      />
                      <path
                          d="M4.81 19.2001C5.9477 19.2001 6.87 18.2778 6.87 17.1401C6.87 16.0024 5.9477 15.0801 4.81 15.0801C3.67229 15.0801 2.75 16.0024 2.75 17.1401C2.75 18.2778 3.67229 19.2001 4.81 19.2001Z"
                          stroke="#0052CC"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                      />
                      <path
                          d="M12 8.91992V21.2499"
                          stroke="#0052CC"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                      />
                      <path
                          d="M12 2.75V4.81"
                          stroke="#0052CC"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                      />
                      <path
                          d="M12.0004 8.9198C13.1381 8.9198 14.0604 7.99751 14.0604 6.8598C14.0604 5.7221 13.1381 4.7998 12.0004 4.7998C10.8627 4.7998 9.94043 5.7221 9.94043 6.8598C9.94043 7.99751 10.8627 8.9198 12.0004 8.9198Z"
                          stroke="#0052CC"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                      />
                    </svg>
                    <span>{menuTranslations?.['index.index.titles.manage_glossary']}</span>
                  </NavLink>
                </div>
            )}
            <div className="sidebar__item sf">
              <NavLink to="/contract-management">
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                >
                  <path
                      d="M18.4136 8.36364V8.31364H18.3636H16.5454C15.0668 8.31364 13.8681 7.11498 13.8681 5.63636V3.81818V3.76818H13.8181H7.45448C6.42271 3.76818 5.5863 4.6046 5.5863 5.63636V18.3636C5.5863 19.3954 6.42271 20.2318 7.45448 20.2318H16.5454C17.5772 20.2318 18.4136 19.3954 18.4136 18.3636V8.36364ZM18.2627 6.59545H18.333L18.3099 6.52903C18.2182 6.26525 18.0676 6.02283 17.8664 5.82159L16.3602 4.31536C16.1589 4.11412 15.9165 3.96354 15.6527 3.87181L15.5863 3.84871V3.91904V5.63636C15.5863 6.16605 16.0157 6.59545 16.5454 6.59545H18.2627ZM3.86812 5.63636C3.86812 3.65567 5.47379 2.05 7.45448 2.05H15.0392C15.9903 2.05 16.9025 2.42785 17.5751 3.10042L19.0813 4.60665C19.7539 5.27923 20.1318 6.19143 20.1318 7.14259V18.3636C20.1318 20.3443 18.5261 21.95 16.5454 21.95H7.45448C5.47378 21.95 3.86812 20.3443 3.86812 18.3636V5.63636Z"
                      fill="#8A909D"
                      stroke="#8A909D"
                      strokeWidth="0.1"
                  />
                  <path
                      d="M8.41353 12.9091C8.41353 12.4346 8.79815 12.05 9.27262 12.05H14.7272C15.2016 12.05 15.5863 12.4346 15.5863 12.9091C15.5863 13.3836 15.2016 13.7682 14.7272 13.7682H9.27262C8.79815 13.7682 8.41353 13.3836 8.41353 12.9091ZM8.41353 16.5455C8.41353 16.071 8.79815 15.6864 9.27262 15.6864H11.9999C12.4744 15.6864 12.859 16.071 12.859 16.5455C12.859 17.0199 12.4744 17.4045 11.9999 17.4045H9.27262C8.79815 17.4045 8.41353 17.0199 8.41353 16.5455Z"
                      fill="#8A909D"
                      stroke="#8A909D"
                      strokeWidth="0.1"
                  />
                </svg>

                <span>{menuTranslations?.['index.index.titles.contract_management_plan']}</span>
              </NavLink>
            </div>
            <div className="sidebar__item">
              <NavLink to="/reporting">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M17.1398 21.2H6.8598C5.7198 21.2 4.7998 20.28 4.7998 19.14V4.74994C4.7998 3.60994 5.7198 2.68994 6.8598 2.68994H17.1398C18.2798 2.68994 19.1998 3.60994 19.1998 4.74994V19.14C19.1898 20.28 18.2698 21.2 17.1398 21.2Z" stroke="#8A909D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M7.88965 6.85999H16.1096" stroke="#8A909D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M7.88965 12H16.1096" stroke="#8A909D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M7.88965 17.1399H16.1096" stroke="#8A909D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <span>{menuTranslations?.['index.index.titles.reporting']}</span>
              </NavLink>
            </div>
            <div className="sidebar__item">
              <NavLink to="/management-dashboard">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" >
                  <path d="M10.43 13.5601H18.11C18.11 17.7801 14.64 21.2501 10.43 21.2501C6.21 21.2501 2.75 17.7801 2.75 13.5601C2.75 9.34012 6.22 5.87012 10.43 5.87012V13.5601Z" stroke="#0062FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M13.57 10.44V2.75C15.61 2.75 17.56 3.56 19 5C20.44 6.44 21.25 8.4 21.25 10.43H13.57V10.44Z" stroke="#0062FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <span>{menuTranslations?.['index.index.titles.management_dashboard']}</span>
              </NavLink>
            </div>
            {/*HIDE Notification center*/}
            <div className="sidebar__item">
              <NavLink to="/notification-center">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" >
                  <path d="M20.22 16.62C20.22 17.47 19.53 18.16 18.68 18.16H5.32001C4.47001 18.16 3.78 17.47 3.78 16.62C3.78 15.77 4.47001 15.08 5.32001 15.08H5.82999V9.94002C5.82999 6.54002 8.59 3.77002 12 3.77002C13.7 3.77002 15.24 4.46002 16.36 5.58002C17.48 6.69002 18.17 8.23002 18.17 9.94002V15.08H18.68C19.53 15.08 20.22 15.77 20.22 16.62Z" stroke="#0052CC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M12 3.78V2.75" stroke="#0052CC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M15.08 18.1699C15.08 19.8799 13.7 21.2499 12 21.2499C10.3 21.2499 8.92001 19.8699 8.92001 18.1699H15.08Z" stroke="#0052CC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <span>{menuTranslations?.['index.index.titles.notification_center']}</span>
              </NavLink>
            </div>
          </div>
        </div>
        <div className="bottom">
          <div className="sidebar__item">
            <NavLink to="help"  className="sidebar__help">
              <div>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" >
                  <g clipPath="url(#clip0_4807_109309)">
                    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#8A909D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M9.08997 9.00008C9.32507 8.33175 9.78912 7.76819 10.3999 7.40921C11.0107 7.05024 11.7289 6.91902 12.4271 7.03879C13.1254 7.15857 13.7588 7.52161 14.215 8.06361C14.6713 8.60561 14.921 9.2916 14.92 10.0001C14.92 12.0001 11.92 13.0001 11.92 13.0001" stroke="#8A909D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M12 17H12.01" stroke="#8A909D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_4807_109309">
                      <rect width="24" height="24" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <span>
              {menuTranslations?.['index.index.titles.help']}
            </span>
            </NavLink>
          </div>
          <div className="sidebar__logout">
            <img
                onClick={() => {
                  setModal(true);
                }}
                src="/assets/icons/logout.svg"
                alt="logout"
            />
            <span
                onClick={() => {
                  setModal(true);
                }}
                className="t"
            >
            {menuTranslations?.['index.index.titles.log_out']}
          </span>
            {modal && (
                <div className="logout__modal">
                  <div className="logout__modal-content">
                    <div className="logout__modal-header">
                      <h5>{logoutTranslations?.['headers.confirm_your_action']}</h5>
                      <div
                          onClick={() => {
                            setModal(false);
                          }}
                          className="logout__modal-close"
                      >
                        <img src="/assets/icons/close-mark.svg" alt="close" />
                      </div>
                    </div>
                    <div className="logout__modal-text">
                      {logoutTranslations?.['titles.you_are_going_to_log_out']}
                    </div>
                    <div className="logout__modal-btns">
                      <div
                          onClick={() => {
                            setModal(false);
                          }}
                          className="logout__modal-cancel"
                      >
                        {logoutTranslations?.['actions.cancel']}
                      </div>
                      <div
                          onClick={() =>logOut()}
                          className="logout__modal-sure"
                      >
                        {logoutTranslations?.['actions.log_out']}
                      </div>
                    </div>
                  </div>
                  <div
                      onClick={() => {
                        setModal(false);
                      }}
                      className="logout__modal-bg"
                  ></div>
                </div>
            )}
          </div>
          <div className="sidebar__item ">
            <NavLink to="settings" className="sidebar__user">
              <div className="user__logo">{renderUserName()}</div>
              <span className="name">{user?.full_name ?? ""}</span>
            </NavLink>
          </div>
        </div>
      </div>
  );
}
