import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import Tabs from "./Tabs";
import SystemErrors from "./SystemErrors";
import ReportsAndCMD from "./ReportsAndCMD";
import SecondaryTables from "./SecondaryTables";
import UserAccessActivity from "./UserAccessActivity";
import ProjectDataInputPage from "./ProjectDataInputPage";
import {setLoader} from "../../store/slice/dashboard";
import {useLazyGetAuditLogsPageQuery} from "../../store/service/adbService";
import './index.scss';


const AuditLogs = ({    }) => {
    const dispatch = useDispatch();
    const [tab, setTab] = useState(1);
    const [translations, setTranslations] = useState({});
    const [actions, setActions] = useState({});

    const [getAuditLogs, {
        data: auditLogs,
        isSuccess: auditLogsIsSuccess,
        isLoading: auditLogsIsLoading
    }] = useLazyGetAuditLogsPageQuery();

    const handleTab = (tabIndex) => {
        setTab(tabIndex);
    };

    useEffect(()=>{
        getAuditLogs()
    },[]);

    useEffect(()=>{
        if (auditLogsIsSuccess){
            setTranslations(auditLogs?.data?.translations);
            setActions(auditLogs?.data?.data);
        }

    },[auditLogsIsSuccess]);

    useEffect(() => {
        auditLogsIsLoading
            ? dispatch(setLoader(true))
            : dispatch(setLoader(false));
    }, [auditLogsIsLoading]);

    return (
        <div className='s-pages audit'>
            <div className="audit__header">
                <h1 className="audit__title">
                    {translations['index.index.headers.audit_logs']}
                </h1>
               <Tabs tab={tab} handleTab={handleTab} tabTitles={translations} />
            </div>
            <div className='audit__content '>
                <div className='userAccessActivity'>
                    {tab === 1 && <UserAccessActivity
                        translations={translations}
                        eventOption={actions?.['user_access_activity.index.dropdown.event_type']}
                    />
                    }
                    {tab === 2 && <ProjectDataInputPage
                        translations={translations}
                        eventOption={actions?.['report_data_change.index.dropdown.event_type']}
                        searchPermitsPlaceholder={actions?.['report_data_change.index.dropdown.tables']}
                        tablesOption={actions?.['report_data_change.index.dropdown.tables']}
                    />
                    }
                    {tab === 3 && <SecondaryTables
                        eventOption={actions?.['secondary_tables.index.dropdown.event_type']}
                        tablesOption={actions?.['secondary_tables.index.dropdown.tables']}
                        translations={translations}
                    />}
                    {tab === 4 && <ReportsAndCMD
                        translations={translations}
                        data={actions?.['reports_and_cmp_downloaded.index.dropdown.event_type']}
                    />}
                    {tab === 5 && <SystemErrors translations={translations}/>}
                </div>
            </div>
        </div>

    )
}

export default AuditLogs;