import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import ContentLoader from "react-content-loader";

function QuarterlyLoadGrant(props) {
	const report_name = useSelector(state => state?.quarterlyReducer?.online_view?.report_name) ?? null;
	const loan_grant_key_data = useSelector(state => state?.quarterlyReducer?.online_view?.sections?.loan_grant_key_data) ?? null;
	const [translations, setTranslations] = React.useState([]);
	
	useEffect(() => {
		if (props?.pageTranslations) {
			setTranslations(props?.pageTranslations)
		}
	}, [props?.pageTranslations])
	
	if (!loan_grant_key_data) {
		return <div></div>
	}
	
	return (
			<div className="online__content-projectRow">
				<p className="online__content-boldTitle">1. {translations?.['loan_grant_key_data.index.headers.loan_grant_key_data']?.toUpperCase()}</p>
				<p className="online__content-projectText">
					{translations?.['loan_grant_key_data.index.titles.report_name']?.toUpperCase()}
					<span>{report_name}</span>
				</p>
				<p className="online__content-projectText">
					{translations?.['loan_grant_key_data.index.titles.loan_grant_number']?.toUpperCase()}
					<span>{loan_grant_key_data?.loan_grant_number}</span>
				</p>
				<p className="online__content-projectText">
					{translations?.['loan_grant_key_data.index.titles.approval_date']?.toUpperCase()}
					<span>{loan_grant_key_data?.approval_date}</span>
				</p>
				<p className="online__content-projectText">
					{translations?.['loan_grant_key_data.index.titles.signing_date']?.toUpperCase()}
					<span>{loan_grant_key_data?.signing_date}</span>
				</p>
				<p className="online__content-projectText">
					{translations?.['loan_grant_key_data.index.titles.commencement_effective_date']?.toUpperCase()}
					<span>{loan_grant_key_data?.commencement_effective_date}</span>
				</p>
				<p className="online__content-projectText">
					{translations?.['loan_grant_key_data.index.titles.current_closing_date']?.toUpperCase()}
					<span>{loan_grant_key_data?.closing_date}</span>
				</p>
				<p className="online__content-projectText">
					{translations?.['loan_grant_key_data.index.titles.original_closing_date']?.toUpperCase()}
					<span>{loan_grant_key_data?.original_closing_date}</span>
				</p>
				<p className="online__content-projectText">
					{translations?.['loan_grant_key_data.index.titles.physical_completion_date']?.toUpperCase()}
					<span>{loan_grant_key_data?.physical_completion_date}</span>
				</p>
				<p className="online__content-projectText">
					{translations?.['loan_grant_key_data.index.titles.elapsed_loan_period']?.toUpperCase()}
					<span>{loan_grant_key_data?.elapsed_loan_period}</span>
				</p>
				
				<p className="online__content-projectText">
					{translations?.['loan_grant_key_data.index.titles.physical_project_progress']?.toUpperCase()}
					<span>{loan_grant_key_data?.physical_project_progress}</span>
				</p>
			
			</div>
	);
}

export default QuarterlyLoadGrant;
