import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
import {toast} from "react-toastify";
import {DeleteModal} from "../DeleteModal";
import Select from "react-select";
import {setLoader} from "../../../store/slice/dashboard";
import {useErrorHandler} from "../../../global/ErrorsHandler";
import {
    useCreateAdbMutation,
    useDeleteAdbMutation,
    useUpdateAdbMutation,
    useUpdateContactNameMutation
} from "../../../store/service/adbService";


const customStyles = {
    menu: (provided, state = false) => ({
        ...provided,
        width: "150%",
        color: state.selectProps.menuColor,
        cursor: "pointer",
        overflow: "hidden",
    }),

    control: (_, { selectProps: { width } }) => ({
        width: "100%",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        display: "flex",
        background: "#FFFFFF",
        borderRadius: 8,
    }),
};

export const CompanyTables = ({
                                  id,
                                  refMobilizationActivities,
                                  adbData,
                                  companyName,
                                  hasNameUpdate,
                                  nameKey,
                                  translations
                              }) => {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [currentItem, setCurrentItem] = useState(null);
    const [newItem, setNewItem] = useState(false);
    const [options, setOptions] = useState([]);
    const [deleteModal, setDeleteModal] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);
    const [inputEdit, setInputEdit] = useState(false);
    const user = useSelector((state) => state?.adbReducer?.user);
    const [inputValue, setInputValue] = useState(adbData?.name || "");
    const [tableHeaders, setTableHeaders] = useState([]);
    const [compName, setCompName] = useState('')
    const scrollRef = useRef(null);

    useEffect(() => {
        if(companyName && translations){
            companyName === 'EXECUTING AGENCY' ? setCompName(translations?.['environment_social_health_safety_and_security_obligations.complaints_grievances.dropdown.executing_agency']) :
                companyName === 'IMPLEMENTING AGENCY' ? setCompName(translations?.['environment_social_health_safety_and_security_obligations.complaints_grievances.dropdown.implementing_agency']) :
                    companyName === 'CONTRACTOR / SUPPLIER' ? setCompName(translations?.['communications_and_reporting.contractor_supplier.headers.contractor_supplier']) :
                        companyName === 'ENGINEER / PROJECT MANAGER' ? setCompName(translations?.['communications_and_reporting.engineer_project_manager.headers.engineer_project_manager']) :
                            companyName === 'ADB' ? setCompName(translations?.['environment_social_health_safety_and_security_obligations.complaints_grievances.dropdown.adb']) :
                                companyName === 'DISPUTE BOARD MEMBERS' ? setCompName(translations?.['communications_and_reporting.dispute_board_members.headers.dispute_board_members']) :
                                    companyName === 'OTHER' ? setCompName(translations?.['communications_and_reporting.other.headers.other']) : setCompName('?')
        }
    }, [companyName, translations])

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                let array = data?.map((item) => {
                    item.edit = false;
                    return item;
                });
                setCurrentItem(null);
                setData(array);
                if (newItem) {
                    setNewItem(false);
                    let s = data?.filter((el, i) => i !== data.length - 1);
                    setData(s);
                }
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, [data]);

    useEffect(() => {
        if (translations) {
            const headers = [
                {
                    tooltip_name: translations?.['communications_and_reporting.implementing_agency.table_headers.name'],
                    tooltip_text: translations?.['communications_and_reporting.implementing_agency.tooltips.name']
                },
                {
                    tooltip_name: translations?.['communications_and_reporting.implementing_agency.table_headers.title'],
                    tooltip_text: translations?.['communications_and_reporting.implementing_agency.tooltips.title']
                },
                {
                    tooltip_name: translations?.['communications_and_reporting.implementing_agency.table_headers.job_title'],
                    tooltip_text: translations?.['communications_and_reporting.implementing_agency.tooltips.job_title']
                },
                {
                    tooltip_name: translations?.['communications_and_reporting.implementing_agency.table_headers.phone_number'],
                    tooltip_text: translations?.['communications_and_reporting.implementing_agency.tooltips.phone_number']
                },
                {
                    tooltip_name: translations?.['communications_and_reporting.implementing_agency.table_headers.email'],
                    tooltip_text: translations?.['communications_and_reporting.implementing_agency.tooltips.email']
                },
            ]

            setTableHeaders(
                headers.map((item) => ({
                    ...item,
                    showTooltip: false,
                }))
            );
        }
    }, [translations]);
    useEffect(() => {
        if (adbData?.data) {
            let s = adbData?.data?.map((item) => ({
                id: item?.id,
                edit: false,
                ref: item?.ref,
                name: item?.name,
                phone: item?.phone,
                position: item?.position,
                title: item?.title,
                email: item?.email,
            }));
            setData(s);
        }
    }, [adbData]);

    const [
        createAdb,
        {
            data: createAdbData,
            isError: createAdbIsError,
            isLoading: createAdbIsLoading,
            isSuccess: createAdbIsSuccess,
            error: createAdbError,
        },
    ] = useCreateAdbMutation();

    const [
        updateAdb,
        {
            data: updateAdbData,
            isError: updateAdbIsError,
            isLoading: updateAdbIsLoading,
            isSuccess: updateAdbIsSuccess,
            error: updateAdbError,
        },
    ] = useUpdateAdbMutation();

    const [
        deleteAdb,
        {
            data: deleteAdbData,
            isError: deleteAdbIsError,
            isLoading: deleteAdbIsLoading,
            isSuccess: deleteAdbIsSuccess,
            error: deleteAdbError,
        },
    ] = useDeleteAdbMutation();

    const [
        updateContactName,
        {
            data: updateContactNameData,
            isError: updateContactNameIsError,
            isLoading: updateContactNameIsLoading,
            isSuccess: updateContactNameIsSuccess,
            error: updateContactNameError,
        },
    ] = useUpdateContactNameMutation();


    const [errorHandler, setErrorHandler] = useErrorHandler([
        deleteAdbError,
        updateAdbError,
        createAdbError,
    ]);

    useEffect(() => {
        const d = () => {
            setData((old) => {
                let data = old?.map((item) => ({ ...item, edit: false }));
                return data;
            });
            dispatch(setLoader(false));
        };

        deleteAdbIsLoading || updateAdbIsLoading || createAdbIsLoading
            ? dispatch(setLoader(true))
            : d();
    }, [deleteAdbIsLoading, updateAdbIsLoading, createAdbIsLoading]);

    const setterData = (input) => {
        const body = {
            project_id: id,
            id: input?.id,
            name: input?.name,
            phone: input?.phone,
            position: input?.position,
            title: input?.title,
            email: input?.email,
            type: nameKey,
        };

        let s = [];

        if (currentItem.completed) {
            if (body?.actual_date === "Invalid date") {
                s.push("false");
            }
        } else {
            if (body?.actual_date === "Invalid date") {
                delete body.actual_date;
            }
        }

        for (let key in body) {
            if (!body[key] || body[key] === "Invalid date" || body[key] === "") {
                toast.error("Please enter " + key?.split("_")?.join(" "), {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
                s.push("false");
            }
        }

        if (s.length === 0) {
            return body;
        } else {
            return null;
        }
    };

    const submitHandler = (e, item) => {
        e.preventDefault();
        if (newItem) {
            const dataCreated = setterData(currentItem);
            if (!dataCreated) {
                return;
            }
            createAdb(dataCreated);
            setCurrentItem(null);
            setNewItem(false);
            setCurrentRow(null);
        } else {
            const dataUpdated = setterData(currentItem);
            if (!dataUpdated) {
                return;
            }
            updateAdb({
                id: item?.id,
                body: dataUpdated,
            });
            setCurrentItem(null);
            setNewItem(false);
            setCurrentRow(null);
        }
    };

    const submitName = async () => {
        await updateContactName({
            id,
            body: {[nameKey]: inputValue}
        }).then(()=>{
            setInputEdit(false)
        })
    }

    return (
        <div className="contact_details project__white-box">
            {deleteModal?.show && (
                <DeleteModal
                    id={deleteModal?.id}
                    action={deleteAdb}
                    isContact={true}
                    setDeleteModal={setDeleteModal}
                    cencelText={translations?.['main.main.actions.cancel']}
                    deleteText={translations?.['main.main.actions.delete']}
                    modalTitle={translations?.['main.main.headers.confirm_your_action']}
                    clarifyingText={translations?.['main.main.titles.you_are_going_to_delete_this_row']}
                />
            )}
            <div
                ref={refMobilizationActivities}
                id={"mobilization_activities"}
                className="project__block-subtitles"
            >
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <div className="mobilization-title">{compName}</div>
                    {
                        hasNameUpdate &&
                        <div className="mobilization-input">
                            <input
                                disabled={!inputEdit}
                                placeholder={translations?.['communications_and_reporting.executing_agency.titles.enter_name']}
                                value={inputValue}
                                onChange={({target}) => setInputValue(target?.value)}
                            />
                            <div className="project__block-btns">
                                {inputEdit && (
                                    <div
                                        className="project__block-cancel"
                                        onClick={() => {
                                            setInputEdit(false)
                                        }}
                                    >
                                        <img src="/assets/icons/close-mark.svg" alt="close"/>
                                        {translations?.['communications_and_reporting.other.actions.cancel']}
                                    </div>
                                )}
                                {inputEdit ? (
                                    <button onClick={submitName} type="submit" className="project__block-save">
                                        <img src="/assets/icons/save-arrow.svg" alt="arrow"/>
                                        {translations?.['communications_and_reporting.other.actions.save']}
                                    </button>
                                ) : (
                                    <div
                                        onClick={() => {
                                            setInputEdit(true)
                                        }}
                                        className="project__block-update"
                                    >
                                        {translations?.['communications_and_reporting.other.actions.update']}
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                </div>

                {user?.permissions?.edit_project && (
                    <div
                        onClick={() => {
                            let randomId = Math.floor(Math.random() * 1000);
                            scrollRef?.current?.scrollIntoView();
                            setCurrentRow(randomId);
                            if (!newItem) {
                                setNewItem(true);
                                setData((old) => [
                                    ...old,
                                    {
                                        id: randomId,
                                        ref: "",
                                        name: "",
                                        phone: "",
                                        position: "",
                                        title: "",
                                        edit: true,
                                        email: "",
                                    },
                                ]);
                                setCurrentItem({
                                    id: randomId,
                                    ref: "",
                                    name: "",
                                    phone: "",
                                    position: "",
                                    title: "",
                                    edit: true,
                                    email: "",
                                });
                            }
                        }}
                        className="project__block-add-row"
                    >
            <span>
              <img src="/assets/icons/blue-plus.svg" alt="plus" />
            </span>
                        <span>{translations?.['completion_of_work_and_contract_closure_activities_table.contract_closure.actions.add_row']}</span>
                    </div>
                )}
            </div>
            <div
                className={`project__block-table project-table`}
                style={{ paddingBottom: 50 }}
            >
                <div ref={scrollRef} className={`project-table__header`}>
                    {tableHeaders?.map((el) => {
                        return (
                            <div
                                key={el.tooltip_name }
                                onMouseEnter={(event) => {
                                    setTableHeaders(
                                        tableHeaders.map((item) => ({
                                            ...item,
                                            showTooltip: el.tooltip_name === item.tooltip_name
                                        }))
                                    );
                                }}
                                onMouseLeave={() => {
                                    setTableHeaders(
                                        tableHeaders.map((item) => ({
                                            ...item,
                                            showTooltip: false,
                                        }))
                                    );
                                }}
                                className="project-table__th"
                            >
                                {el?.showTooltip ? (
                                    <span
                                        className={
                                            el?.tooltip_text?.length > 20 ? "lengthy" : "not-lengthy"
                                        }
                                    >
                    {el?.tooltip_text}
                  </span>
                                ) : (
                                    ""
                                )}
                                {el?.tooltip_name}
                            </div>
                        );
                    })}
                </div>



                <div className="project-table__body">
                    {data?.map((item, index) => (
                        <div key={index}>
                            <form
                                className={`project-table__row ${
                                    item?.edit ? "edit" : currentItem ? "n" : ""
                                } ${
                                    Boolean(item.submitted) || !user?.permissions?.edit_project
                                        ? "submitted"
                                        : ""
                                }`}
                                id={item?.id}
                                key={item?.id}
                                onSubmit={(e) => submitHandler(e, item)}
                            >
                                <div className="project-table__td">



                                    <span>{item?.name}</span>
                                    <div className="project__block-input project-table__field">
                                        <input
                                            maxLength={190}
                                            key={item?.edit}
                                            type="text"
                                            defaultValue={item?.name}
                                            onChange={(e) => {
                                                setCurrentItem((old) => ({
                                                    ...old,
                                                    name: e.target.value,
                                                }));
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="project-table__td">
                                    <span>{item?.title}</span>
                                    <div className="project__block-input project-table__field select">
                                        <Select
                                            menuPortalTarget={document.body}
                                            key={item?.edit}
                                            styles={({...customStyles, menuPortal: base => ({...base, zIndex: 9999})})}
                                            options={[{value: 'Mr', label: 'Mr'}, {value: 'Mrs', label: 'Mrs'}, {value: 'Ms', label: 'Ms'}, {value: 'Miss', label: 'Miss'}]}
                                            defaultValue={{value: item?.title, label: item?.title}}
                                            isSearchable={false}
                                            onChange={(selected) =>
                                                setCurrentItem((old) => ({
                                                    ...old,
                                                    title: selected?.value,
                                                }))
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="project-table__td">
                                    <span>{item?.position}</span>
                                    <div className="project__block-input project-table__field">
                                        <input
                                            maxLength={190}
                                            key={item?.edit}
                                            type="text"
                                            defaultValue={item?.position}
                                            onChange={(e) => {
                                                setCurrentItem((old) => ({
                                                    ...old,
                                                    position: e.target.value,
                                                }));
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="project-table__td">
                                    <span>{item?.phone}</span>
                                    <div className="project__block-input project-table__field">
                                        <input
                                            maxLength={190}
                                            key={item?.edit}
                                            type="number"
                                            defaultValue={item?.phone}
                                            onChange={(e) => {
                                                setCurrentItem((old) => ({
                                                    ...old,
                                                    phone: e.target.value,
                                                }));
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="project-table__td">
                                    <span>{item?.email}</span>
                                    <div className="project__block-input project-table__field">
                                        <input
                                            maxLength={190}
                                            key={item?.edit}
                                            type="email"
                                            defaultValue={item?.email}
                                            onChange={(e) => {
                                                setCurrentItem((old) => ({
                                                    ...old,
                                                    email: e.target.value,
                                                }));
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="project__block-btns">
                                    {currentItem ? (
                                        <div
                                            className="project__block-cancel"
                                            onClick={() => {
                                                setCurrentRow(null);
                                                let array = data?.map((item) => {
                                                    item.edit = false;
                                                    if (currentItem.completed && !item?.completed) {
                                                        item.completed = false;
                                                        currentItem.completed = false;
                                                    }
                                                    return item;
                                                });
                                                setCurrentItem(null);
                                                setData(array);
                                                if (newItem) {
                                                    setNewItem(false);
                                                    let s = data?.filter(
                                                        (el, i) => i !== data.length - 1
                                                    );
                                                    setData(s);
                                                }
                                            }}
                                        >
                                            <img src="/assets/icons/close-mark.svg" alt="close" />
                                            {translations?.['completion_of_work_and_contract_closure_activities_table.contract_closure.actions.cancel']}
                                        </div>
                                    ) : (
                                        <div
                                            className="project__block-delete"
                                            onClick={() => {
                                                setDeleteModal({ id: item?.id, show: true });
                                            }}
                                        >
                                            <img src="/assets/icons/trash-icon.svg" alt="arrow" />
                                            {translations?.['completion_of_work_and_contract_closure_activities_table.contract_closure.actions.delete']}
                                        </div>
                                    )}
                                    {currentItem ? (
                                        <button className="project__block-save">
                                            <img src="/assets/icons/save-arrow.svg" alt="arrow" />
                                            {translations?.['completion_of_work_and_contract_closure_activities_table.contract_closure.actions.save']}
                                        </button>
                                    ) : (
                                        <div
                                            onClick={() => {
                                                setCurrentRow(item?.id);
                                                setCurrentItem(item);
                                                setData(() => {
                                                    let array = data?.map((elem) => {
                                                        if (elem?.id === item?.id) {
                                                            elem.edit = true;
                                                        }
                                                        return elem;
                                                    });
                                                    return array;
                                                });
                                            }}
                                            className="project__block-update"
                                        >
                                            {translations?.['completion_of_work_and_contract_closure_activities_table.contract_closure.actions.update']}
                                        </div>
                                    )}
                                </div>
                            </form>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
