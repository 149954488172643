import React from 'react';
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import GenerateQuarterlyReport from "../GenerateQuarterlyReport";

function QuarterlyBottomPanel({pageTranslations}) {
	const navigate = useNavigate()
	
	const data = useSelector(state => state?.quarterlyReducer?.online_view);
	
	return (<div className="online__download">
		<button onClick={() => navigate(-1)} className="online__close">
			<img src="/assets/icons/close.svg" alt=""/>
			{pageTranslations?.['index.index.actions.close']}
		</button>
		<GenerateQuarterlyReport
			translations={{
				'index.index.tooltips.generate_report_to_download': pageTranslations?.['index.index.actions.generate_report'],
				'index.index.titles.in_progress': pageTranslations?.['index.index.titles.in_progress'],
				'index.index.tooltips.report_in_process': pageTranslations?.['index.index.tooltips.report_in_process'],
				'index.index.actions.download_report': pageTranslations?.['index.index.actions.download_report']}}
			reportData={data}
		/>
	</div>);
}

export default QuarterlyBottomPanel;
