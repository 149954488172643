import React from 'react';
import './index.scss'

export default function ReportModal({reportSubmit, close, translations}) {
    return (
        <div className="reportModal">
            <div className="reportModal__content">
                <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                    <h2 className="reportModal__title">
                        {
                            translations?.['index.submit_report.headers.attention'] || translations?.['main.main.headers.attention']
                        }
                    </h2>
                    <img src="/assets/icons/close.svg" alt="exit" onClick={close}/>
                </div>
                <p className="reportModal__attention">
                    {
                        translations?.['index.submit_report.titles.if_you_submit_the_current_quarterly_report'] || translations?.['main.main.titles.if_you_submit_current_report']
                    }
                </p>
                <p className="reportModal__submitting">
                    {
                        translations?.['index.submit_report.titles.what_you_need_to_know_before_submitting'] || translations?.['main.main.titles.what_you_have_to_know_before_submitting']
                    }
                </p>
                <div className="reportModal__list">
                       <p className="reportModal__subtitle">
                           {
                               translations?.['index.submit_report.titles.you_will_be_able_to_modify_data_for_current_quarter'] || translations?.['main.main.titles.you_will_be_able_to_modify_data_for_the_month']
                           }
                       </p>
                       <p className="reportModal__subtitle">
                           {
                               translations?.['index.submit_report.titles.after_quarterly_report_has_been_submitted'] || translations?.['main.main.titles.after_the_project_monthly_report_has_been_submitted']
                           }
                       </p>
                       <p className="reportModal__subtitle">
                           {
                               translations?.['index.submit_report.titles.any_updates_to_field_will_not_change_data_from_previous_quarters'] || translations?.['main.main.titles.any_updates_to_field_will_not_change_data_from_previous_months']
                           }
                       </p>
                </div>
                <div className="reportModal__btns">
                    <button onClick={close} className="reportModal__btn cancel">
                        {
                            translations?.['index.submit_report.actions.cancel'] || translations?.['main.main.actions.cancel']
                        }
                    </button>
                    <a onClick={reportSubmit} className="reportModal__btn ">
                        {
                            translations?.['index.submit_report.actions.continue'] || translations?.['main.main.actions.continue']
                        }
                    </a>
                </div>
            </div>
            <div className="reportModal__bg" onClick={close}></div>
        </div>
    );
};