import React, {useEffect, useState} from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useLazyResetPasswordQuery } from "../../store/service/adbService";
import { useErrorHandler } from "../../global/ErrorsHandler";
import AuthWrapper from "../../components/AuthWrapper";
import AxelInput from "../../UI/AxelInput";
import AxelButton from "../../UI/AxelButton";
import ReactCodeInput from "react-verification-code-input";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {setLoader} from "../../store/slice/dashboard";

export default function ResetPassword() {
  const token = useSelector(state => state?.forgetReducer?.token)
  const [state, setState] = useState({
    password: "",
    password_confirmation: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [resetHandler, { data, isError, error, isLoading, isSuccess }] =
    useLazyResetPasswordQuery();
  const [validLength, setValidLength] = useState(false)
  const [hasNumber, setHasNumber] = useState(false)
  const [upperCase, setUpperCase] = useState(false)
  const [lowerCase, setLowerCase] = useState(false)
  const [specialChar, setSpecialChar] = useState(false)
  const [passwordMatch, setPasswordMatch] = useState(false)

  const [errorHandler, setErrorHandler] = useErrorHandler([error]);

  const submitHandler = async (e) => {
    dispatch(setLoader(true))
    e.preventDefault();
    if (lowerCase && upperCase && hasNumber && specialChar && validLength && passwordMatch) {
      const l = await resetHandler({
        token: token,
        new_password: state.password
      });
      if (l.isSuccess) {
        dispatch(setLoader(false))
        navigate("/login");
        toast.success(l.data.message)
      } else {
        dispatch(setLoader(false))
      }
    } else {
      alert("Сonfirmation password is incorrect");
      dispatch(setLoader(false))
    }
  };

  useEffect(() => {
    if (state?.password_confirmation) {
      if (state?.password === state?.password_confirmation) {
        setPasswordMatch(true)
      } else {
        setPasswordMatch(false)
      }
    }
  }, [state?.password, state?.password_confirmation])
  const inputHandler = (e) => {
    setState((old) => ({
      ...old,
      [e.target.id]: e.target.value,
    }));
  };
  const validate = (e) => {
    if (!/^(?=.*[a-z])/.test(e.target.value)) {
      setLowerCase(false)
    } else {
      setLowerCase(true)
    }
    if (!/^(?=.*[A-Z])/.test(e.target.value)) {
      setUpperCase(false)
    } else {
      setUpperCase(true)
    }
    if (!/^(?=.*[0-9])/.test(e.target.value)) {
      setHasNumber(false)
    } else {
      setHasNumber(true)
    }
    if (!/^(?=.*[!@#\$%\^&\*])/.test(e.target.value)) {
      setSpecialChar(false)
    } else {
      setSpecialChar(true)
    }
    if (e.target.value?.length >= 6) {
      setValidLength(true)
    } else {
      setValidLength(false)
    }
  }

  return (
    <>
      <AuthWrapper>
        <h1 className="auth__title verification">
          Create new password
        </h1>
        <p className="auth__descr-verification">
          Please enter a new password.
        </p>
        <form
          action="#"
          className="auth__form login__form"
          onSubmit={submitHandler}
        >
          <AxelInput
              id={"password"}
              type="password"
              value={state?.password}
              placeholder="New password"
              handler={(e) => {
                validate(e)
                inputHandler(e)
              }}
          />
          <AxelInput
              id={"password_confirmation"}
              type="password"
              value={state?.password_confirmation}
              placeholder="Confirm new password"
              handler={inputHandler}
          />
          <ul style={{marginTop: 20}}>
            <li>
              {
                lowerCase ?
                    <div className="auth__valid">
                      <img src="/assets/icons/checkpassword.svg" alt=""/>
                      One lowercase character
                    </div> :
                    <div className="auth__invalid">
                      <img src="/assets/icons/graycheck.svg" alt=""/>
                      One lowercase character
                    </div>
              }
            </li>
            <li>
              {
                upperCase ?
                    <div className="auth__valid">
                      <img src="/assets/icons/checkpassword.svg" alt=""/>
                      One uppercase character
                    </div> :
                    <div className="auth__invalid">
                      <img src="/assets/icons/graycheck.svg" alt=""/>
                      One uppercase character
                    </div>
              }
            </li>
            <li>
              {
                hasNumber ?
                    <div className="auth__valid">
                      <img src="/assets/icons/checkpassword.svg" alt=""/>
                      One number
                    </div> :
                    <div className="auth__invalid">
                      <img src="/assets/icons/graycheck.svg" alt=""/>
                      One number
                    </div>
              }
            </li>
            <li>
              {
                specialChar ?
                    <div className="auth__valid">
                      <img src="/assets/icons/checkpassword.svg" alt=""/>
                      One special character
                    </div> :
                    <div className="auth__invalid">
                      <img src="/assets/icons/graycheck.svg" alt=""/>
                      One special character
                    </div>
              }
            </li>
            <li>
              {
                validLength ?
                    <div className="auth__valid">
                      <img src="/assets/icons/checkpassword.svg" alt=""/>
                      6 characters minimum
                    </div> :
                    <div className="auth__invalid">
                      <img src="/assets/icons/graycheck.svg" alt=""/>
                      6 characters minimum
                    </div>
              }
            </li>
            <li>
              {
                passwordMatch ?
                    <div className="auth__valid">
                      <img src="/assets/icons/checkpassword.svg" alt=""/>
                      Password matches
                    </div> :
                    <div className="auth__invalid">
                      <img src="/assets/icons/graycheck.svg" alt=""/>
                      Password matches
                    </div>
              }
            </li>
          </ul>
          <AxelButton handler={submitHandler} text="Verify"/>
        </form>
      </AuthWrapper>
    </>


  );
}
