import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import React, {useEffect, useRef, useState} from 'react';
import MyEditor from "../../../components/EditorConvertToHTML";
import {
    useLazyGetDevObjectivesQuery,
    useUpdateDevObjectivesMutation,
} from "../../../store/service/adbService";
import {setLoader} from "../../../store/slice/dashboard";
import {errorHandle} from "../../../global/errorHandle";
import {parseHtml} from "../../../global/parseHtml";
import './index.scss';
import MoreInfoModal from "../../../components/MoreInfoModal";

const DevelopmentObjectives = ({ data, reportId, translations, hiddenSectionHandler, isReportSubmitted} ) => {
    const dispatch = useDispatch();
    const sectionsRef = useRef();
    const [isOpenMoreInfo, setOpenMoreInfo] = useState(false)
    const [editingSection, setEditingSection] = useState(null);
    const [editorContent, setEditorContent] = useState('');


    const [devObjectivesData, setDevObjectivesData] = useState(data || {});

    const [getDevObjectives, {
        data: newDevObjectivesData,
        isLoading: devObjectivesIsLoading,
        error: devObjectivesError,
        isSuccess: devObjectivesIsSuccess,
    }]= useLazyGetDevObjectivesQuery();

    const [updateDevObjectives, {
        data: updateDevObjectivesData,
        isSuccess: updateDevObjectivesIsSuccess,
        isLoading: updateDevObjectivesIsLoading,
        error: updateDevObjectivesError,
    }]= useUpdateDevObjectivesMutation();


    const handleUpdateClick = (section, initialValue) => {
        setEditingSection(section);
        setEditorContent(initialValue);
    };

    const handleCancel = () => {
        setEditingSection(null);
        setEditorContent('');
    };

    const handleSave = (newContent) => {
        setEditingSection(null);
        setEditorContent('');

        const formData = new FormData();
        formData.append(editingSection, newContent);

        updateDevObjectives({
            id: reportId,
            body: formData
        })
    };

    useEffect(() => {
        setDevObjectivesData(data)
    }, [data]);

    useEffect(() => {
        if (devObjectivesIsSuccess){
            setDevObjectivesData(newDevObjectivesData?.data)
        }
    }, [devObjectivesIsSuccess, newDevObjectivesData]);

    useEffect(() => {
        if (updateDevObjectivesIsSuccess){
            toast.success(updateDevObjectivesData.message);
            getDevObjectives(reportId);
            setEditingSection(null);
            setEditorContent('');
        }
    }, [updateDevObjectivesIsSuccess,updateDevObjectivesData]);


    useEffect(() => {
        devObjectivesIsLoading ||
        updateDevObjectivesIsLoading
            ? dispatch(setLoader(true))
            : dispatch(setLoader(false));
    }, [devObjectivesIsLoading, updateDevObjectivesIsLoading]);

    useEffect(() => {
        if (updateDevObjectivesError) {
            errorHandle(updateDevObjectivesError);
        }
        if (devObjectivesError) {
            errorHandle(devObjectivesError);
        }
    }, [updateDevObjectivesError, devObjectivesError])


    return (
        <section id='development_objectives' ref={sectionsRef}>
            {isOpenMoreInfo &&
                <MoreInfoModal
                    title= {translations?.['development_objectives.index.headers.development_objectives']}
                    moreInfo={translations?.['development_objectives.index.more_info.more_info']}
                    close={()=>setOpenMoreInfo(false)}
                />
            }
            <div className="editReport__sectionHeader">
                <span onClick={() => hiddenSectionHandler(sectionsRef)} />
                <h2 onClick={() => hiddenSectionHandler(sectionsRef)}>{translations?.['development_objectives.index.headers.development_objectives']}</h2>
                <button className="editReport__sectionHeader__moreInfo"  onClick={()=>setOpenMoreInfo(!isOpenMoreInfo)}>
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.99805 6.33325V9.43992" stroke="#767676" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M6.99805 5.05322C7.27419 5.05322 7.49805 4.82937 7.49805 4.55322C7.49805 4.27708 7.27419 4.05322 6.99805 4.05322C6.7219 4.05322 6.49805 4.27708 6.49805 4.55322C6.49805 4.82937 6.7219 5.05322 6.99805 5.05322Z" fill="#767676"/>
                        <path d="M6.9987 13.1666C10.4045 13.1666 13.1654 10.4057 13.1654 6.99992C13.1654 3.59416 10.4045 0.833252 6.9987 0.833252C3.59294 0.833252 0.832031 3.59416 0.832031 6.99992C0.832031 10.4057 3.59294 13.1666 6.9987 13.1666Z" stroke="#767676" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    {translations?.['development_objectives.index.titles.more_info']}
                </button>
            </div>
            <hr />
            <div className="summary-section">
                <h2>{translations?.['development_objectives.development_impact.headers.development_impact']}</h2>
                <div className="summary-item">
                    <h3>{translations?.['development_objectives.development_impact.headers.impact_of_project_as_aligned']}</h3>
                    <div className="summary-sub-item">
                        <span className="summary-sub-item_bold">{translations?.['development_objectives.development_impact.titles.current_project']}</span>
                        {editingSection === 'current_project' ? (
                            <MyEditor
                                cencelTitle={translations?.['development_objectives.development_impact.actions.cancel']}
                                saveTitle={translations?.['development_objectives.development_impact.actions.save']}
                                initialValue={editorContent}
                                onSave={handleSave}
                                onCancel={handleCancel}
                            />
                        ) : (
                            <>
                                <div className={'html-text-styled'}>{parseHtml(devObjectivesData?.development_impact?.impact_of_project_as_aligned.current_project) || ''}</div>
                                {!isReportSubmitted &&
                                    <div className="summary-sub-item__settingContainer">
                                        <button className="summary-sub-item__updateBtn"
                                            onClick={() => handleUpdateClick('current_project', devObjectivesData?.development_impact?.impact_of_project_as_aligned.current_project)}>{translations?.['development_objectives.development_impact.actions.update']}</button>
                                    </div>
                                }
                            </>
                        )}
                    </div>
                    <div className="summary-sub-item">
                        <span className="summary-sub-item_bold">{translations?.['development_objectives.development_impact.titles.overall_project']}</span>
                        {editingSection === 'overall_project' ? (
                            <MyEditor
                                cencelTitle={translations?.['development_objectives.development_impact.actions.cancel']}
                                saveTitle={translations?.['development_objectives.development_impact.actions.save']}
                                initialValue={editorContent}
                                onSave={handleSave}
                                onCancel={handleCancel}
                            />
                        ) : (
                            <>
                                <div className={'html-text-styled'}>{parseHtml(devObjectivesData?.development_impact?.impact_of_project_as_aligned.overall_project) || ''}</div>
                                {!isReportSubmitted &&
                                    <div className="summary-sub-item__settingContainer">
                                        <button className="summary-sub-item__updateBtn"
                                            onClick={() => handleUpdateClick('overall_project', devObjectivesData?.development_impact?.impact_of_project_as_aligned.overall_project)}>{translations?.['development_objectives.development_impact.actions.update']}</button>
                                    </div>
                                }
                            </>
                        )}
                    </div>
                </div>
            </div>
            <hr />
            <div className="summary-section">
                <h2>{translations?.['development_objectives.development_outcome.headers.development_outcome']}</h2>
                <div className="summary-item">
                    <h3>{translations?.['development_objectives.development_outcome.headers.outcomes']}</h3>
                    <div className="summary-sub-item">
                        <span className="summary-sub-item_bold">{translations?.['development_objectives.development_outcome.titles.description']}</span>
                        {editingSection === 'outcomes_description' ? (
                            <MyEditor
                                saveTitle={translations?.['development_objectives.development_outcome.actions.save']}
                                cencelTitle={translations?.['development_objectives.development_outcome.actions.cancel']}
                                initialValue={editorContent}
                                onSave={handleSave}
                                onCancel={handleCancel}
                            />
                        ) : (
                            <>
                                <div className={'html-text-styled'}>{parseHtml(devObjectivesData?.development_outcome?.outcomes.description) || ''}</div>
                                {!isReportSubmitted &&
                                    <div className="summary-sub-item__settingContainer">
                                        <button className="summary-sub-item__updateBtn"
                                            onClick={() => handleUpdateClick('outcomes_description', devObjectivesData?.development_outcome?.outcomes.description)}>{translations?.['development_objectives.development_impact.actions.update']}</button>
                                    </div>
                                }
                            </>
                        )}
                    </div>
                    <div className="summary-sub-item">
                        <span className="summary-sub-item_bold">{translations?.['development_objectives.development_outcome.titles.assessment_of_current_status']}</span>
                        {editingSection === 'outcomes_assessment' ? (
                            <MyEditor
                                saveTitle={translations?.['development_objectives.development_outcome.actions.save']}
                                cencelTitle={translations?.['development_objectives.development_outcome.actions.cancel']}
                                initialValue={editorContent}
                                onSave={handleSave}
                                onCancel={handleCancel}
                            />
                        ) : (
                            <>
                                <div className={'html-text-styled'}>{parseHtml(devObjectivesData?.development_outcome?.outcomes.assessment_of_current_status) || ''}</div>
                                {!isReportSubmitted &&
                                    <div className="summary-sub-item__settingContainer">
                                        <button className="summary-sub-item__updateBtn"
                                            onClick={() => handleUpdateClick('outcomes_assessment', devObjectivesData?.development_outcome?.outcomes.assessment_of_current_status)}>{translations?.['development_objectives.development_impact.actions.update']}</button>
                                    </div>
                                }
                            </>
                        )}
                    </div>
                </div>
                <div className="summary-item">
                    <h3>{translations?.['development_objectives.development_outcome.headers.performance_targets']}</h3>
                    <div className="summary-sub-item">
                        <span className="summary-sub-item_bold">{translations?.['development_objectives.development_outcome.titles.description']}</span>
                        {editingSection === 'indicators_description' ? (
                            <MyEditor
                                saveTitle={translations?.['development_objectives.development_outcome.actions.save']}
                                cencelTitle={translations?.['development_objectives.development_outcome.actions.cancel']}
                                initialValue={editorContent}
                                onSave={handleSave}
                                onCancel={handleCancel}
                            />
                        ) : (
                            <>
                                <div className={'html-text-styled'}>{parseHtml(devObjectivesData?.development_outcome?.performance_targets.description) || ''}</div>
                                {!isReportSubmitted &&
                                    <div className="summary-sub-item__settingContainer">
                                        <button className="summary-sub-item__updateBtn"
                                            onClick={() => handleUpdateClick('indicators_description', devObjectivesData?.development_outcome?.performance_targets.description)}>{translations?.['development_objectives.development_impact.actions.update']}</button>
                                    </div>
                                }
                            </>
                        )}
                    </div>
                    <div className="summary-sub-item">
                        <span className="summary-sub-item_bold">{translations?.['development_objectives.development_outcome.titles.assessment_of_current_status']}</span>
                        {editingSection === 'indicators_assessment' ? (
                            <MyEditor
                                saveTitle={translations?.['development_objectives.development_outcome.actions.save']}
                                cencelTitle={translations?.['development_objectives.development_outcome.actions.cancel']}
                                initialValue={editorContent}
                                onSave={handleSave}
                                onCancel={handleCancel}
                            />
                        ) : (
                            <>
                                <div className={'html-text-styled'}>{parseHtml(devObjectivesData?.development_outcome?.performance_targets.assessment_of_current_status) || ''}</div>
                                {! isReportSubmitted &&
                                    <div className="summary-sub-item__settingContainer">
                                        <button className="summary-sub-item__updateBtn"
                                            onClick={() => handleUpdateClick('indicators_assessment', devObjectivesData?.development_outcome?.performance_targets.assessment_of_current_status)}>{translations?.['development_objectives.development_outcome.actions.update']}</button>
                                    </div>
                                }
                            </>
                        )}
                    </div>
                </div>
                <div className="summary-item">
                    <h3>{translations?.['development_objectives.development_outcome.headers.risks']}</h3>
                    <div className="summary-sub-item">
                        <span className="summary-sub-item_bold">{translations?.['development_objectives.development_outcome.titles.description']}</span>
                        {editingSection === 'risks_description' ? (
                            <MyEditor
                                saveTitle={translations?.['development_objectives.development_outcome.actions.save']}
                                cencelTitle={translations?.['development_objectives.development_outcome.actions.cancel']}
                                initialValue={editorContent}
                                onSave={handleSave}
                                onCancel={handleCancel}
                            />
                        ) : (
                            <>
                                <div className={'html-text-styled'}>{parseHtml(devObjectivesData?.development_outcome?.risks.description) || ' '}</div>
                                {!isReportSubmitted &&
                                    <div className="summary-sub-item__settingContainer">
                                        <button className="summary-sub-item__updateBtn"
                                            onClick={() => handleUpdateClick('risks_description', devObjectivesData?.development_outcome?.risks.description)}>{translations?.['development_objectives.development_outcome.actions.update']}</button>
                                    </div>
                                }
                            </>
                        )}
                    </div>
                    <div className="summary-sub-item">
                        <span className="summary-sub-item_bold">{translations?.['development_objectives.development_outcome.titles.assessment_of_current_status']}</span>
                        {editingSection === 'risks_assessment' ? (
                            <MyEditor
                                saveTitle={translations?.['development_objectives.development_outcome.actions.save']}
                                cencelTitle={translations?.['development_objectives.development_outcome.actions.cancel']}
                                initialValue={editorContent}
                                onSave={handleSave}
                                onCancel={handleCancel}
                            />
                        ) : (
                            <>
                                <div className={'html-text-styled'}>{parseHtml(devObjectivesData?.development_outcome?.risks.assessment_of_current_status) || ' '}</div>
                                {!isReportSubmitted &&
                                    <div className="summary-sub-item__settingContainer">
                                        <button className="summary-sub-item__updateBtn" onClick={() => handleUpdateClick('risks_assessment', devObjectivesData?.development_outcome?.risks.assessment_of_current_status)}>{translations?.['development_objectives.development_outcome.actions.update']}</button>
                                    </div>
                                }
                            </>
                        )}
                    </div>
                </div>
            </div>
            <hr />
            <div className="summary-section">
                <h2>{translations?.['development_objectives.overall_rating.headers.overall_rating']}</h2>
                <div className="summary-item">
                    <div className="summary-sub-item">
                        <span className="summary-sub-item_bold">{translations?.['development_objectives.development_outcome.titles.description']}</span>
                        {editingSection === 'overall_rating_description' ? (
                            <MyEditor
                                cencelTitle={translations?.['development_objectives.overall_rating.actions.cancel']}
                                saveTitle={translations?.['development_objectives.overall_rating.actions.save']}
                                initialValue={editorContent}
                                onSave={handleSave}
                                onCancel={handleCancel}
                            />
                        ) : (
                            <>
                                <div className={'html-text-styled'}>{parseHtml(devObjectivesData?.overall_rating?.description) || ''}</div>
                                {!isReportSubmitted &&
                                    <div className="summary-sub-item__settingContainer">
                                        <button className="summary-sub-item__updateBtn" onClick={() => handleUpdateClick('overall_rating_description', devObjectivesData?.overall_rating?.description)}>{translations?.['development_objectives.overall_rating.actions.update']}</button>
                                    </div>
                                }
                            </>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default DevelopmentObjectives;
