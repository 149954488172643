import React, {useEffect, useState} from 'react';
import SubSectionTableContainer from "../SubSectionTableContainer";
import RiskLevelMonitoring from "./RiskLevelMonitoring";
import './index.scss';

const RiskRegisterOfContracts = ({sectionData, translations }) => {
    const [data, setData] = useState(sectionData);
    useEffect(() => {
        setData(sectionData)
    }, [sectionData]);

    return (
        <div className="listOfTable__subSections riskRegisterOfContracts">
            <div className="editReport__sectionHeader">
                <h3>
                    {translations?.['lists_of_tables.risk_register_of_contracts.headers.risk_register_of_contracts']}
                </h3>
            </div>
            {data?.map((section, index) => (
                <SubSectionTableContainer
                    isList={true}
                    list={[section?.contract_title, section?.contract_number]}
                    key={index}
                >
                   <div className="table-container">
                       <table className='report__table'>
                           <thead className="report__table__header">
                           <tr className="report__table__row">
                               <th>{translations?.['lists_of_tables.risk_register_of_contracts.table_headers.ref']}</th>
                               <th>{translations?.['lists_of_tables.risk_register_of_contracts.table_headers.risk_description']}</th>
                               <th>{translations?.['lists_of_tables.risk_register_of_contracts.table_headers.impact_rating']}</th>
                               <th>{translations?.['lists_of_tables.risk_register_of_contracts.table_headers.likelihood_rating']}</th>
                               <th>{translations?.['lists_of_tables.risk_register_of_contracts.table_headers.risk_level']}</th>
                               <th>{translations?.['lists_of_tables.risk_register_of_contracts.table_headers.mitigation_measure']}</th>
                               <th>{translations?.['lists_of_tables.risk_register_of_contracts.table_headers.responsible_party']}</th>
                               <th>{translations?.['lists_of_tables.risk_register_of_contracts.table_headers.current_status_or_comments']}</th>
                           </tr>
                           </thead>
                           <tbody className="report__table__body">
                           {section.data?.map((item, index) => (
                               <tr className="report__table__row" key={index}>
                                   <td >{item.ref}</td>
                                   <td >{item.description}</td>
                                   <td >{item.impact_rating}</td>
                                   <td >{item.likelihood_rating}</td>
                                   <td >
                                       <button
                                           style={{
                                               background: `${item.risk_background}`,color: `${item.risk_color}` }}
                                           className="riskRegisterOfContracts__levelBtn"
                                       >
                                            {item.risk_level}
                                       </button></td>
                                   <td >{item.mitigation_measure}</td>
                                   <td >{item.responsible_party}</td>
                                   <td >{item.current_status}</td>
                               </tr>
                           ))}
                           </tbody>
                       </table>
                   </div>
                    <RiskLevelMonitoring data={section?.chart_data} translations={translations} />
                </SubSectionTableContainer>
            ))}
        </div>
    );
};

export default RiskRegisterOfContracts;
