import React from "react";
import { useEffect } from "react";
import { NavLink, Outlet, useNavigate, useLocation } from "react-router-dom";
import Wrapper from "../Wrapper";
import { sidebarItems } from "./customData";

import "./index.scss";
import { useSelector } from "react-redux";
export const SecondaryPagesWrapper = ({}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state?.adbReducer?.user);

  useEffect(() => {
    if (!localStorage.getItem("user_token")) {
      navigate("/login");
    } else {
      if (location.pathname === "/secondary-pages") {
        navigate("/secondary-pages/company");
      }
    }
    if (!user?.permissions?.secondary_pages) {
      navigate("/project-list");
    }
  }, []);

  return (
    <Wrapper>
      <div className="s-page">
        <div className="s-page__sidebar">
          {sidebarItems?.map((item, index) => (
            <NavLink
              to={item?.path}
              className="s-page__sidebar-item"
              id={item?.id}
              key={index}
            >
              {item?.name}
            </NavLink>
          ))}
        </div>
        <Outlet />
      </div>
    </Wrapper>
  );
};
