import moment from "moment";
import isEmpty from "lodash/isEmpty";
import Select from "react-select";
import { toast } from "react-toastify";
import React, {useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useErrorHandler } from "../../../global/ErrorsHandler";
import {
    useCreateProjectApprovalsMutation,
    useDeleteProjectApprovalsMutation,
    useGetProjectApprovalsQuery,
    useLazyGetAllCompaniesQuery,
    useUpdateProjectApprovalsMutation,
} from "../../../store/service/adbService";
import { setLoader } from "../../../store/slice/dashboard";
import { Switch } from "../../../UI/Switch";
import { DeleteModal } from "../DeleteModal";
import MoreInfoModal from "../../MoreInfoModal";
import CustomDatePicker from "../../CustomDatePicker";

const customStyles = {
    menu: (provided, state = false) => ({
        ...provided,
        width: "150%",
        color: state.selectProps.menuColor,
        cursor: "pointer",
        overflow: "hidden",
    }),

    control: (_, { selectProps: { width } }) => ({
        width: "100%",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        display: "flex",
        background: "#FFFFFF",
        borderRadius: 8,
    }),
};

export const PermitsTable = ({ id, glossaryData, refPermits, permitsData, translations }) => {
    const dispatch = useDispatch();
    const localeLan = localStorage.getItem("local_lan" );
    moment.locale(localeLan)
    const [data, setData] = useState([]);
    const [show, setShow] = useState(true);
    const [showByActive, setShowByActive] = useState(true);
    const [hasArchived, setHasArchived] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [newItem, setNewItem] = useState(false);
    const [options, setOptions] = useState([]);
    const [editable, setEditable] = useState(false);
    const user = useSelector((state) => state?.adbReducer?.user);
    const [deleteModal, setDeleteModal] = useState(null);
    const [tableHeaders, setTableHeaders] = useState([]);
    const [infoModal, setInfoModal] = useState(false);
    const [moreInfo, setMoreInfo] = useState(null);
    const scrollRef = useRef(null);
    const [currentRow, setCurrentRow] = useState(null);
    const expandedRef = useRef(null);
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                let array = data?.map((item) => {
                    item.edit = false;
                    return item;
                });
                setEditable(false);
                setCurrentItem(null);
                setData(array);
                if (newItem) {
                    setNewItem(false);
                    let s = data?.filter((el, i) => i !== data.length - 1);
                    setData(s);
                }
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, [data]);

    const {
        data: projectApprovalsData,
        isError: projectApprovalsIsError,
        isLoading: projectApprovalsIsLoading,
        isSuccess: projectApprovalsIsSuccess,
        error: projectApprovalsError,
    } = useGetProjectApprovalsQuery(id);

    useEffect(() => {
        if (projectApprovalsData?.data) {
            let data = projectApprovalsData?.data?.map((item) => ({
                id: item?.id,
                application_type: item?.application_type,
                regulation_law_or_regulation: item?.regulation,
                responsible_party: {
                    value: item?.responsible_party,
                    label: item?.responsible_party,
                },
                expiry_date: item?.expiry_date,
                not_applicable: item?.not_applicable,
                approving_authority: item?.approving_authority,
                due_planned_date: item?.planned_date,
                actual_date: item?.actual_date ? item?.actual_date : null,
                current_status_For_comments: item?.comment,
                completed: item?.completed,
                edit: false,
                archived: item?.archived,
                submitted: item?.submitted,
                old_report: item?.old_report,
                submitted_date: item?.submitted_date,
            }));
            let isHaveArchived = isEmpty(data.find(item => item.archived===1));
            setHasArchived(!isHaveArchived);
            if (showByActive===true && !isHaveArchived){
                const filteredData =  data.filter(item => item.archived ===0);
                setData(filteredData);
            } else setData(data);
            setEditable(false);
        }
    }, [projectApprovalsData, showByActive]);

    const [
        getCompanies,
        {
            data: companies,
            error: companiesError,
            isLoading: companiesIsLoading,
            isError: companiesIsError,
            isSuccess: companiesIsSuccess,
        },
    ] = useLazyGetAllCompaniesQuery();

    useEffect(() => {
        getCompanies({
            per_page: 1000,
        });
    }, []);

    useEffect(() => {
        if (permitsData?.responsible_party) {
            let data = permitsData?.responsible_parties?.map((item) => {
                return {
                    value: item,
                    label: item,
                };
            });
            setOptions(data);
        }
    }, [permitsData]);
    const [
        createProjectApprovals,
        {
            data: createProjectApprovalsData,
            isError: createProjectApprovalsIsError,
            isLoading: createProjectApprovalsIsLoading,
            isSuccess: createProjectApprovalsIsSuccess,
            error: createProjectApprovalsError,
        },
    ] = useCreateProjectApprovalsMutation();

    const [
        updateProjectApprovals,
        {
            data: updateProjectApprovalsData,
            isError: updateProjectApprovalsIsError,
            isLoading: updateProjectApprovalsIsLoading,
            isSuccess: updateProjectApprovalsIsSuccess,
            error: updateProjectApprovalsError,
        },
    ] = useUpdateProjectApprovalsMutation();

    const [
        deleteProjectApprovals,
        {
            data: deleteProjectApprovalsData,
            isError: deleteProjectApprovalsIsError,
            isLoading: deleteProjectApprovalsIsLoading,
            isSuccess: deleteProjectApprovalsIsSuccess,
            error: deleteProjectApprovalsError,
        },
    ] = useDeleteProjectApprovalsMutation();

    const [errorHandler, setErrorHandler] = useErrorHandler([
        projectApprovalsError,
        createProjectApprovalsError,
        updateProjectApprovalsError,
        deleteProjectApprovalsError,
    ]);

    useEffect(() => {
        const headers = [
            {
                tooltip_name: translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.table_headers.application_type'],
                tooltip_text: translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.tooltips.application_type'],
            },
            {
                tooltip_name: translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.table_headers.relevant_law_or_regulation'],
                tooltip_text: translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.tooltips.relevant_law_or_regulation']
            },
            {
                tooltip_name: translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.table_headers.responsible_party'],
                tooltip_text: translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.tooltips.responsible_party']
            },
            {
                tooltip_name: translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.table_headers.approving_authority'],
                tooltip_text: translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.tooltips.approving_authority']
            },
            {
                tooltip_name: translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.table_headers.planned_date'],
                tooltip_text: translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.tooltips.planned_date']
            },
            {
                tooltip_name: translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.table_headers.actual_date'],
                tooltip_text: translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.tooltips.actual_date']
            },
            {
                tooltip_name: translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.table_headers.expiry_date'],
                tooltip_text: translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.tooltips.expiry_date']
            },
            {
                tooltip_name: translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.table_headers.not_applicable'],
                tooltip_text: translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.tooltips.not_applicable']
            },
            {
                tooltip_name: translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.table_headers.current_status_or_comments'],
                tooltip_text: translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.tooltips.current_status_or_comments']
            },
            {
                tooltip_name: translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.table_headers.completed'],
                tooltip_text: translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.tooltips.completed']
            },
        ]
        setTableHeaders(
            headers.map(el => ({
                ...el,
                showTooltip: false,
            }))
        )
    }, [translations])

    useEffect(() => {
        if (glossaryData?.data?.length) {
            setMoreInfo(
                glossaryData?.data?.filter(
                    (el) => el?.section_name === "Permits, Licenses and Approvals"
                )[0]
            );
        }
        setData((old) => {
            let data = old?.map((item) => ({ ...item, edit: false }));
            return data;
        });
        setEditable(false);
    }, [
        projectApprovalsIsSuccess,
        createProjectApprovalsIsSuccess,
        updateProjectApprovalsIsSuccess,
        deleteProjectApprovalsIsSuccess,
        glossaryData,
    ]);
    useEffect(() => {
        const d = () => {
            dispatch(setLoader(false));
        };

        projectApprovalsIsLoading ||
        createProjectApprovalsIsLoading ||
        updateProjectApprovalsIsLoading ||
        deleteProjectApprovalsIsLoading
            ? dispatch(setLoader(true))
            : d();
    }, [
        projectApprovalsIsLoading,
        createProjectApprovalsIsLoading,
        updateProjectApprovalsIsLoading,
        deleteProjectApprovalsIsLoading,
    ]);

    const setterData = (input) => {
        const body = {
            project_id: id,
            application_type: input?.application_type,
            regulation: input?.regulation_law_or_regulation,
            responsible_party: input?.responsible_party.label,
            approving_authority: input?.approving_authority,
            planned_date: moment(input?.due_planned_date).format("YYYY-MM-DD"),
            actual_date: moment(input?.actual_date).format("YYYY-MM-DD"),
            comment: input?.current_status_For_comments,
        };

        let s = [];

        if (currentItem?.completed) {
            if (body?.actual_date === "Invalid date") {
                s.push("false");
            }
        } else {
            if (body?.actual_date === "Invalid date") {
                delete body.actual_date;
            }
        }

        for (let key in body) {
            if (key !== "comment") {
                if (!body[key] || body[key] === "Invalid date" || body[key] === "") {
                    toast.error("Please enter " + key?.split("_")?.join(" "), {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    });
                    s.push("false");
                }
            }
        }

        if (s.length === 0) {
            return body;
        } else {
            return null;
        }
    };

    const submitHandler = (e, item) => {
        e.preventDefault();
        if (newItem) {
            const dataCreated = setterData(currentItem);
            if (dataCreated) {
                createProjectApprovals({
                    ...dataCreated,
                    completed: currentItem?.completed,
                    expiry_date: currentItem?.expiry_date ? moment(currentItem?.expiry_date).format("YYYY-MM-DD"): null,
                    not_applicable: currentItem?.not_applicable,
                });
                setCurrentItem(null);
                setNewItem(false);
            }
        } else {
            const dataUpdated = setterData(currentItem);
            if (dataUpdated) {
                updateProjectApprovals({
                    id: item?.id,
                    body: {
                        ...dataUpdated,
                        completed: currentItem?.completed,
                        expiry_date: currentItem?.expiry_date ? moment(currentItem?.expiry_date).format("YYYY-MM-DD"): null,
                        not_applicable: currentItem?.not_applicable,
                    },
                });
                setCurrentItem(null);
                setNewItem(false);
            }
        }
    };
    return (
        <>
            {infoModal && (
                <MoreInfoModal
                    title={translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.headers.permits_licenses_and_approvals']}
                    moreInfo={translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.more_info.permits_licenses_and_approvals']}
                    close={()=> setInfoModal(false)}
                />
            )}

            <div ref={refPermits} className="project__white-box">
                {deleteModal?.show && (
                    <DeleteModal
                        id={deleteModal?.id}
                        action={deleteProjectApprovals}
                        setDeleteModal={setDeleteModal}
                        cencelText={translations?.['main.main.actions.cancel']}
                        deleteText={translations?.['main.main.actions.delete']}
                        modalTitle={translations?.['main.main.headers.confirm_your_action']}
                        clarifyingText={translations?.['main.main.titles.you_are_going_to_delete_this_row']}
                    />
                )}
                <div className={`project__block ${show ? "" : "close"}`}>
                    <div className="d-f">
                        <div
                            className="project__block-title"
                            onClick={() => setShow(!show)}
                        >
                            <span>{translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.headers.permits_licenses_and_approvals']}</span>
                        </div>
                        <span
                            className="project__block-more"
                            onClick={() => setInfoModal(!infoModal)}
                        >
              <span className="img">
                <img src="/assets/icons/info.svg" alt="info" />
              </span>
              <span className="text">{translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.titles.more_info']}</span>
            </span>
                    </div>
                    <div className="project__block-hr"></div>
                    <div className="project__block-subtitles">
                        <div className="project__block__buttonsContainer">
                            {hasArchived && <button
                                onClick={() => setShowByActive(!showByActive)}
                                className="project__block-add-row">
                                <span> <img src="/assets/icons/blue-eye.svg" alt="plus"/></span>
                                <span>{showByActive ? translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.actions.show_all']
                                    : translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.actions.show_active']}</span>
                            </button>}
                            {user?.permissions?.edit_project && (
                                <button
                                    onClick={() => {
                                        let randomId = Math.floor(Math.random() * 1000);
                                        setCurrentRow(randomId);
                                        scrollRef !== null && scrollRef.current.scrollIntoView();
                                        if (!newItem) {
                                            setNewItem(true);
                                            setEditable(true);
                                            setData((old) => [
                                                ...old,
                                                {
                                                    id: randomId,
                                                    application_type: "",
                                                    regulation_law_or_regulation: "",
                                                    responsible_party: "",
                                                    approving_authority: "",
                                                    due_planned_date: null,
                                                    actual_date: null,
                                                    current_status_or_comments: "",
                                                    completed: false,
                                                    completed_date: null,
                                                    expiry_date: null,
                                                    not_applicable: false,
                                                    edit: true,
                                                },
                                            ]);
                                            setCurrentItem({
                                                id: randomId,
                                                application_type: "",
                                                regulation_law_or_regulation: "",
                                                responsible_party: "",
                                                approving_authority: "",
                                                due_planned_date: null,
                                                actual_date: null,
                                                current_status_or_comments: "",
                                                completed: false,
                                                completed_date: null,
                                                expiry_date: null,
                                                not_applicable: false,
                                                edit: true,
                                            });
                                        }
                                    }}
                                    className="project__block-add-row"
                                >
                <span>
                  <img src="/assets/icons/blue-plus.svg" alt="plus" />
                </span>
                                    <span>{translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.actions.add_row']}</span>
                                </button>
                            )}
                        </div>
                    </div>
                    <div
                        className={`project__block-content permits ${
                            editable ? "editable" : ""
                        }`}
                    >
                        <div
                            className={`project__block-table project-table ${
                                currentItem ? "n" : ""
                            }`}
                        >
                            <div
                                className={`table__header ${
                                    data?.length > 3 && editable ? "isFixed" : ""
                                }`}
                                style={{gridTemplateColumns: '11% 11% 9% 10% 9.5% 9.5% 9.5% 8% 12% 8%'}}
                                ref={scrollRef}
                            >
                                {tableHeaders?.map((el, idx) => {
                                    return (
                                        <div
                                            key={idx}
                                            onMouseOver={(event) => {
                                                setTableHeaders(
                                                    tableHeaders?.map(item => ({
                                                        ...item,
                                                        showTooltip: item.tooltip_name === el.tooltip_name
                                                    }))
                                                );
                                            }}
                                            onMouseLeave={() => {
                                                setTableHeaders(
                                                    tableHeaders?.map((item) => ({
                                                        ...item,
                                                        showTooltip: false,
                                                    }))
                                                );
                                            }}
                                            className="project-table__th"
                                        >
                                            {el?.showTooltip ? (
                                                <span
                                                    className={
                                                        el?.tooltip_name?.length > 20
                                                            ? "lengthy"
                                                            : "not-lengthy"
                                                    }
                                                >
                          {el?.tooltip_text}
                        </span>
                                            ) : (
                                                ""
                                            )}
                                            {el?.tooltip_name}
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="project-table__body">
                                {data?.map((item, index) => (
                                    <div key={index}>
                                        {index !== 0 && currentRow === item?.id && (
                                            <div className={`table__header`} key={index} style={{gridTemplateColumns: '11% 11% 9% 10% 9.5% 9.5% 9.5% 8% 12% 8%'}}>
                                                {tableHeaders?.map((el, idx) => {
                                                    return (
                                                        <div key={idx} className="project-table__th">
                                                            {el?.showTooltip ? (
                                                                <span
                                                                    className={
                                                                        el?.tooltip_name?.length > 20
                                                                            ? "lengthy"
                                                                            : "not-lengthy"
                                                                    }
                                                                >
                                  {el?.tooltip_text}
                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                            {el?.tooltip_name}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                        <form
                                            className={`table__row ${
                                                item?.edit ? "edit" : currentItem ? "n" : ""
                                            } ${
                                                Boolean(item.submitted) ||
                                                !user?.permissions?.edit_project
                                                    ? "submitted"
                                                    : ""
                                            }`}
                                            style={{gridTemplateColumns: '11% 11% 9% 10% 9.5% 9.5% 9.5% 8% 12% 8%'}}
                                            id={item?.id}
                                            key={item?.id}
                                            onSubmit={(e) => submitHandler(e, item)}
                                        >
                                            <div className="project-table__td">
                                                <span>{item?.application_type}</span>
                                                <div className="project__block-input project-table__field">
                                                    <input
                                                        maxLength={190}
                                                        type="text"
                                                        defaultValue={item?.application_type}
                                                        onChange={(e) => {
                                                            setCurrentItem((old) => ({
                                                                ...old,
                                                                application_type: e.target.value,
                                                            }));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="project-table__td">
                                                <span>{item?.regulation_law_or_regulation}</span>
                                                <div className="project__block-input project-table__field">
                                                    <input
                                                        maxLength={190}
                                                        type="text"
                                                        defaultValue={item?.regulation_law_or_regulation}
                                                        onChange={(e) => {
                                                            setCurrentItem((old) => ({
                                                                ...old,
                                                                regulation_law_or_regulation: e.target.value,
                                                            }));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="project-table__td">
                                                {
                                                    item?.edit ?
                                                        <div className="project__block-input project-table__field select">
                                                            <Select
                                                                options={options}
                                                                menuPortalTarget={document.body}
                                                                styles={{
                                                                    ...customStyles,
                                                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                                }}
                                                                isSearchable={false}
                                                                value={currentItem?.responsible_party}
                                                                placeholder={translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.placeholder.select']}
                                                                onChange={(selected) => {
                                                                    setCurrentItem((old) => ({
                                                                        ...old,
                                                                        responsible_party: selected,
                                                                    }));
                                                                }}
                                                            />
                                                        </div>
                                                        : <span>{item?.responsible_party?.label}</span>
                                                }
                                            </div>
                                            <div className="project-table__td">
                                                <span>{item?.approving_authority}</span>
                                                <div className="project__block-input project-table__field">
                                                    <input
                                                        maxLength={190}
                                                        type="text"
                                                        defaultValue={item?.approving_authority}
                                                        onChange={(e) => {
                                                            setCurrentItem((old) => ({
                                                                ...old,
                                                                approving_authority: e.target.value,
                                                            }));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="project-table__td">
                        <span>
                          {moment(item?.due_planned_date).format(
                              "DD/MM/YYYY"
                          ) === "Invalid date" ||
                          !moment(item?.due_planned_date).format("DD/MM/YYYY")
                              ? ""
                              : moment(item?.due_planned_date).format(
                                  "DD/MM/YYYY"
                              )}
                        </span>
                                                <div className="project__block-item-calendar project-table__field">
                                                    <CustomDatePicker
                                                        portalId="root-portal"
                                                        autoComplete="off"
                                                        id="approved_date"
                                                        placeholderText={translations?.['main.main.placeholder.dd_mm_yyyy']}
                                                        dateFormat="dd/MM/yyyy"
                                                        selected={
                                                            currentItem?.due_planned_date
                                                                ? moment(currentItem?.due_planned_date).toDate()
                                                                : ""
                                                        }
                                                        onChange={(date) => {
                                                            setCurrentItem((old) => ({
                                                                ...old,
                                                                due_planned_date: date,
                                                            }));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="project-table__td">
                        <span>
                          {moment(item?.actual_date).format("DD/MM/YYYY") ===
                          "Invalid date" ||
                          !moment(item?.actual_date).format("DD/MM/YYYY")
                              ? ""
                              : moment(item?.actual_date).format("DD/MM/YYYY")}
                        </span>
                                                <div
                                                    className={`project__block-item-calendar project-table__field ${
                                                        currentItem?.completed
                                                            ? ""
                                                            : "project__actualDate-disabled"
                                                    }`}
                                                >
                                                    <CustomDatePicker
                                                        portalId="root-portal"
                                                        id="approved_date"
                                                        autoComplete="off"
                                                        dateFormat="dd/MM/yyyy"
                                                        disabled={!currentItem?.completed}
                                                        placeholderText={translations?.['main.main.placeholder.dd_mm_yyyy']}
                                                        selected={
                                                            currentItem?.actual_date
                                                                ? moment(currentItem?.actual_date).toDate()
                                                                : ""
                                                        }
                                                        onChange={(date) => {
                                                            setCurrentItem((old) => ({
                                                                ...old,
                                                                actual_date: date,
                                                            }));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="project-table__td">
                        <span>
                          {moment(item?.expiry_date).format("DD/MM/YYYY") ===
                          "Invalid date" ||
                          !moment(item?.expiry_date).format("DD/MM/YYYY")
                              ? ""
                              : moment(item?.expiry_date).format("DD/MM/YYYY")}
                        </span>
                                                <div
                                                    className={`project__block-item-calendar project-table__field`}
                                                >
                                                    <CustomDatePicker
                                                        portalId="root-portal"
                                                        id="expiry_date"
                                                        autoComplete="off"
                                                        dateFormat="dd/MM/yyyy"
                                                        placeholderText={translations?.['main.main.placeholder.dd_mm_yyyy']}
                                                        selected={
                                                            currentItem?.expiry_date
                                                                ? moment(currentItem?.expiry_date).toDate()
                                                                : ""
                                                        }
                                                        onChange={(date) => {
                                                            setCurrentItem((old) => ({
                                                                ...old,
                                                                expiry_date: date,
                                                            }));
                                                        }}
                                                        disabled={!!currentItem?.not_applicable}
                                                    />
                                                </div>
                                            </div>
                                            <div className="project-table__td">
                                                <Switch
                                                    value={item?.not_applicable}
                                                    id={index + 7320}
                                                    disabled={item?.edit ? false : true}
                                                    checked={
                                                        item?.edit
                                                            ? currentItem?.not_applicable
                                                            : item?.not_applicable
                                                    }
                                                    handler={(value) => {
                                                        setCurrentItem((old) => ({
                                                            ...old,
                                                            not_applicable: value,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                            <div className="project-table__td expanded-input">
                                                <span>{item?.current_status_For_comments}</span>
                                                <div className="project__block-input  project-table__field">
                          <textarea
                              className={`${
                                  expanded?.id === item?.id
                                      ? "project__block-expandedInput"
                                      : ""
                              }`}
                              ref={expandedRef}
                              onFocus={() =>
                                  setExpanded({ show: true, id: item?.id })
                              }
                              onBlur={() => setExpanded({})}
                              maxLength={190}
                              defaultValue={item?.current_status_For_comments}
                              onChange={(e) => {
                                  setCurrentItem((old) => ({
                                      ...old,
                                      current_status_For_comments: e.target.value,
                                  }));
                              }}
                          />
                                                </div>
                                            </div>
                                            <div className="project-table__td">
                                                <Switch
                                                    value={item?.completed}
                                                    id={index + 731}
                                                    disabled={item?.edit ? false : true}
                                                    checked={
                                                        item?.edit
                                                            ? currentItem?.completed
                                                            : item?.completed
                                                    }
                                                    handler={(value) => {
                                                        setCurrentItem((old) => ({
                                                            ...old,
                                                            completed: value,
                                                            actual_date: value
                                                                ? currentItem?.actual_date
                                                                : null,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                            <div className="project__block-btns">
                                                {currentItem ? (
                                                    <div
                                                        className="project__block-cancel"
                                                        onClick={() => {
                                                            setCurrentRow(null);
                                                            let array = data?.map((item) => {
                                                                item.edit = false;
                                                                if (currentItem?.completed) {
                                                                    item.completed = false;
                                                                    setCurrentItem((old) => ({
                                                                        ...old,
                                                                        completed: false,
                                                                    }));
                                                                }
                                                                return item;
                                                            });
                                                            setCurrentItem(null);
                                                            setEditable(false);
                                                            setData(array);
                                                            if (newItem) {
                                                                setNewItem(false);
                                                                let s = data?.filter(
                                                                    (el, i) => i !== data.length - 1
                                                                );
                                                                setData(s);
                                                            }
                                                        }}
                                                    >
                                                        <img
                                                            src="/assets/icons/close-mark.svg"
                                                            alt="close"
                                                        />
                                                        {translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.actions.cancel']}
                                                    </div>
                                                ) : (
                                                    <div
                                                        className="project__block-delete"
                                                        onClick={() => {
                                                            setDeleteModal({ id: item?.id, show: true });
                                                        }}
                                                    >
                                                        <img
                                                            src="/assets/icons/trash-icon.svg"
                                                            alt="arrow"
                                                        />
                                                        {translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.actions.delete']}
                                                    </div>
                                                )}
                                                {currentItem ? (
                                                    <button className="project__block-save">
                                                        <img
                                                            src="/assets/icons/save-arrow.svg"
                                                            alt="arrow"
                                                        />
                                                        {translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.actions.save']}
                                                    </button>
                                                ) : (
                                                    <div
                                                        onClick={() => {
                                                            setCurrentRow(item?.id);
                                                            setCurrentItem(item);
                                                            setEditable(true);
                                                            setData(() => {
                                                                let array = data?.map((elem) => {
                                                                    if (elem?.id === item?.id) {
                                                                        elem.edit = true;
                                                                    }
                                                                    return elem;
                                                                });
                                                                return array;
                                                            });
                                                        }}
                                                        className="project__block-update"
                                                    >
                                                        {translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.actions.update']}
                                                    </div>
                                                )}
                                            </div>
                                        </form>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
