import React from 'react';
import {useSelector} from "react-redux";

function QuarterlyFinancialCovenants({pageTranslations}) {
	const financial_covenants = useSelector(state => state?.quarterlyReducer?.online_view?.sections?.covenants?.financial_covenants) ?? null;
	const submission_of_audited_project_accounts = useSelector(state => state?.quarterlyReducer?.online_view?.sections?.covenants?.financial_covenants?.submission_of_audited_project_accounts) ?? null;
	const submission_of_agency_financial_statements = useSelector(state => state?.quarterlyReducer?.online_view?.sections?.covenants?.financial_covenants?.submission_of_agency_financial_statements) ?? null;
	const overall_compliance_rating = useSelector(state => state?.quarterlyReducer?.online_view?.sections?.covenants?.financial_covenants?.overall_compliance_rating) ?? null;
	const financial_ratios_to_be_compiled = useSelector(state => state?.quarterlyReducer?.online_view?.sections?.covenants?.financial_covenants?.financial_ratios_to_be_compiled) ?? null;
	const findings = useSelector(state => state?.quarterlyReducer?.online_view?.sections?.covenants?.financial_covenants?.findings) ?? null;
	const sections_title = useSelector(state => state?.quarterlyReducer?.sections_title) ?? null;
	
	if (!financial_covenants) {
		return <></>
	}
	
	const FinancialCovenants = () => (
			<>
				<p style={{display: 'block'}}>6.1. {pageTranslations?.['covenants.financial_covenants.headers.financial_covenants']}</p>
				<div className={"quarterly-online-table"}>
					<table border="1">
						<thead>
						<tr className={"row_gray"}>
							<th>{pageTranslations?.['covenants.financial_covenants.table_headers.ea']}</th>
							<th>{pageTranslations?.['covenants.financial_covenants.table_headers.fy_end']}</th>
							<th>{pageTranslations?.['covenants.financial_covenants.table_headers.fy']}</th>
							<th>{pageTranslations?.['covenants.financial_covenants.table_headers.months_due_after_fy']}</th>
							<th>{pageTranslations?.['covenants.financial_covenants.table_headers.due_date']}</th>
							<th>{pageTranslations?.['covenants.financial_covenants.table_headers.received_date']}</th>
							<th>{pageTranslations?.['covenants.financial_covenants.table_headers.delays_months']}</th>
							<th>{pageTranslations?.['covenants.financial_covenants.table_headers.acceptable']}</th>
							<th>{pageTranslations?.['covenants.financial_covenants.table_headers.auditors_opinion']}</th>
							<th>{pageTranslations?.['covenants.financial_covenants.table_headers.status_of_compliance']}</th>
							<th>{pageTranslations?.['covenants.financial_covenants.table_headers.rating']}</th>
						</tr>
						</thead>
						<tbody>
						<tr>
							<td colSpan={11}>{pageTranslations?.['covenants.financial_covenants.headers.submission_of_audited_project_accounts']}</td>
						</tr>
						<tr>
							<td>{submission_of_audited_project_accounts?.ea}</td>
							<td>{submission_of_audited_project_accounts?.fy_end}</td>
							<td>{submission_of_audited_project_accounts?.fy}</td>
							<td>{submission_of_audited_project_accounts?.months_due_after_fy}</td>
							<td>{submission_of_audited_project_accounts?.due_date}</td>
							<td>{submission_of_audited_project_accounts?.received_date}</td>
							<td>{submission_of_audited_project_accounts?.delays}</td>
							<td>{submission_of_audited_project_accounts?.acceptable}</td>
							<td>{submission_of_audited_project_accounts?.auditors_opinion}</td>
							<td>{submission_of_audited_project_accounts?.status_of_compliance}</td>
							<td>{submission_of_audited_project_accounts?.rating}</td>
						</tr>
						<tr>
							<td colSpan={11}>{pageTranslations?.['covenants.financial_covenants.headers.submission_of_agency_financial_statements']}</td>
						</tr>
						<tr>
							<td>{submission_of_agency_financial_statements?.ea}</td>
							<td>{submission_of_agency_financial_statements?.fy_end}</td>
							<td>{submission_of_agency_financial_statements?.fy}</td>
							<td>{submission_of_agency_financial_statements?.months_due_after_fy}</td>
							<td>{submission_of_agency_financial_statements?.due_date}</td>
							<td>{submission_of_agency_financial_statements?.received_date}</td>
							<td>{submission_of_agency_financial_statements?.delays}</td>
							<td>{submission_of_agency_financial_statements?.acceptable}</td>
							<td>{submission_of_agency_financial_statements?.auditors_opinion}</td>
							<td>{submission_of_agency_financial_statements?.status_of_compliance}</td>
							<td>{submission_of_agency_financial_statements?.rating}</td>
						</tr>
						<tr>
							<td colSpan={11}>{pageTranslations?.['covenants.financial_covenants.titles.overall_compliance_rating']}</td>
						</tr>
						<tr>
							<td colSpan={11} dangerouslySetInnerHTML={{__html: overall_compliance_rating}}/>
						</tr>
						<tr>
							<td colSpan={11}>{pageTranslations?.['covenants.financial_covenants.titles.findings_actions_taken_or_other_remarks']}</td>
						</tr>
						<tr>
							<td colSpan={11} dangerouslySetInnerHTML={{__html: findings}}/>
						</tr>
						</tbody>
					</table>
				</div>
			</>
	)
	
	const FinancialRatios = () => (
			<>
				<p>{pageTranslations?.['covenants.financial_covenants.headers.financial_ratios_to_be_compiled']}</p>
				<div className={"quarterly-online-table"} style={{marginTop: 20}}>
					{financial_ratios_to_be_compiled?.map((item, index) => (
							<table border="1" key={index}>
								<thead>
								<tr className={"row_gray"}>
									<th colSpan={3}>{pageTranslations?.['covenants.financial_covenants.table_headers.description_of_financial_ratio']}</th>
									<th>{pageTranslations?.['covenants.financial_covenants.table_headers.agency']}</th>
									<th>{pageTranslations?.['covenants.financial_covenants.table_headers.fiscal_year']}</th>
									<th>{pageTranslations?.['covenants.financial_covenants.table_headers.unit_of_measurement']}</th>
									<th>{pageTranslations?.['covenants.financial_covenants.table_headers.frequency_of_compliance']}</th>
									<th>{pageTranslations?.['covenants.financial_covenants.table_headers.linked_to_aefs']}</th>
								</tr>
								</thead>
								<tbody>
								<tr>
									<td colSpan={3}>{item?.description}</td>
									<td>{item?.agency}</td>
									<td>{item?.fiscal_year}</td>
									<td>{item?.unit_of_measurement}</td>
									<td>{item?.frequency_of_compliance}</td>
									<td>{item?.linked_to_aefs }</td>
								</tr>
								<tr className={"row_gray"}>
									<th>{pageTranslations?.['covenants.financial_covenants.table_headers.target_date']}</th>
									<th>{pageTranslations?.['covenants.financial_covenants.table_headers.target_criteria']}</th>
									<th>{pageTranslations?.['covenants.financial_covenants.table_headers.target_value']}</th>
									<th colSpan={3} style={{maxWidth: 210}}>{pageTranslations?.['covenants.financial_covenants.table_headers.compliance_with_financial_covenants_provided_by_auditor']}</th>
									<th>{pageTranslations?.['covenants.financial_covenants.table_headers.actual_value']}</th>
									<th>{pageTranslations?.['covenants.financial_covenants.table_headers.compiled']}</th>
								</tr>
								{item?.ratios?.length ? item?.ratios?.map((el, idx) => (
												<tr key={idx}>
													<td>{el?.target_date}</td>
													<td>{el?.target_criteria}</td>
													<td>{el?.target_value}</td>
													<td colSpan={3}>{el?.compliance_with_financial_covenants_provided_by_auditor}</td>
													<td>{el?.actual_value}</td>
													<td>{el?.compiled}</td>
												</tr>
										)) :
										(
												<tr>
													<td></td>
													<td></td>
													<td></td>
													<td colSpan={3}></td>
													<td></td>
													<td></td>
												</tr>
										)
								}
								</tbody>
							</table>
					))}
				</div>
			</>
	)
	
	return (
			<div className="online__content-projectRow">
				<p className="online__content-boldTitle">6. {pageTranslations?.['covenants.financial_covenants.headers.financial_covenants']}</p>
				<FinancialCovenants/>
				<FinancialRatios/>
			</div>
	);
}

export default QuarterlyFinancialCovenants;
