import { useDispatch } from "react-redux";
import {
  useDeleteTemplateMutation,
  useDownloadTemplateMutation,
  useGetAllTemplatesMutation,
  useLazyDownloadTemplateQuery,
} from "../../../store/service/adbService";
import { setLoader } from "../../../store/slice/dashboard";
import { toast } from "react-toastify";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const useTemplatesHook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [getTemplates] = useGetAllTemplatesMutation();
  const [deleteTemp] = useDeleteTemplateMutation();
  const [templates, setTemplates] = useState(null);

  const get = () => {
    dispatch(setLoader(true));
    getTemplates()
      .unwrap()
      .then((res) => {
        dispatch(setLoader(false));
        setTemplates(res?.data?.data);
      })
      .catch((err) => {
        dispatch(setLoader(false));
        toast.error(err?.data?.message);
        if (err?.originalStatus === 401) {
          navigate("/login");
        }
      });
  };

  const downloadTemplate = (template) => {
    dispatch(setLoader(true));
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ` + localStorage.getItem("user_token"),
    };
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/cmp-templates/download/${template?.id}`,
        {
          headers: headers,
          responseType: "blob",
        }
      )
      .then(({ data: blob }) => {
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.download = `${template?.title}.docx`;
        link.click();
        dispatch(setLoader(false));
      })
      .catch((e) => {
        toast.error(
          e?.data?.message ? e?.data?.message : "Something went wrong.",
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          }
        );
        dispatch(setLoader(false));
        if (e?.originalStatus === 401) {
          navigate("/login");
        }
      });
  };

  const deleteTemplate = (template, closeHandler) => {
    dispatch(setLoader(true));
    deleteTemp(template?.id)
      .unwrap()
      .then((res) => {
        dispatch(setLoader(false));
        toast.success(res?.message);
        get();
        closeHandler();
      })
      .catch((err) => {
        toast.error(err?.data?.message);
        if (err?.originalStatus === 401) {
          navigate("/login");
        }
      });
  };

  const createTemplate = (template) => {};

  return {
    get,
    templates,
    downloadTemplate,
    deleteTemplate,
    createTemplate,
  };
};

export default useTemplatesHook;
