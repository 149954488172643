import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import Table from "./Table";
import Wrapper from "../../components/Wrapper";
import {errorHandle} from "../../global/errorHandle";
import {setLoader} from "../../store/slice/dashboard";
import {useGetQuarterlyReportPageQuery, useLazyGetAllQuarterlyReportsQuery } from "../../store/service/adbService";
import './index.scss';

const emptyDataText = "No Activities";

const QuarterlyReports = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state?.adbReducer?.user);
    const [pageTranslations, setPageTranslations] = useState({})
    const [quarterlyReportsList, setQuarterlyReportsList] = useState([])

    const { data: quarterlyReportsPage, error, isLoading: isLoadingQuarterlyReportsPage } = useGetQuarterlyReportPageQuery();
    const [getQuarterlyReportsList, { data: quarterlyReportsListData, error: errorQuarterlyReportsList, isLoading: isLoadingQuarterlyReportsList }] = useLazyGetAllQuarterlyReportsQuery();

    useEffect(() => {
        getQuarterlyReportsList()
    }, []);

    useEffect(() => {
        if (quarterlyReportsListData?.data) {
            setQuarterlyReportsList(quarterlyReportsListData?.data)
        }
    },[quarterlyReportsListData])

    useEffect(()=>{
        if (quarterlyReportsPage?.data) {
            setPageTranslations(quarterlyReportsPage?.data?.translations)
        }
    }, [quarterlyReportsPage]);

    useEffect(() => {
        isLoadingQuarterlyReportsPage ||
        isLoadingQuarterlyReportsList ?
            dispatch(setLoader(true)) :
            dispatch(setLoader(false))
    }, [isLoadingQuarterlyReportsPage, isLoadingQuarterlyReportsList]);

    useEffect(() => {
        if (errorQuarterlyReportsList){
            errorHandle(errorQuarterlyReportsList);
        }
        if (error){
            errorHandle(error);
        }
    },[errorQuarterlyReportsList, error])


    if (isLoadingQuarterlyReportsList || isLoadingQuarterlyReportsPage) {
        return (
            <div style={{ height: '70vh', width: '100%', display: "flex", justifyContent: "center", alignItems: 'center', flexDirection: "column" }}>
                <img src="/assets/images/projects.png" alt="" />
                <span style={{ color: "#767676", fontSize: 16, fontWeight: 500 }}>{ (isLoadingQuarterlyReportsList || isLoadingQuarterlyReportsPage ) ? 'Loading...' :emptyDataText}</span>
</div>
        )
    }
    return (
        <Wrapper classname='quarterlyReport'>
            <div className='quarterlyReport__headerWrapper'>
                <h1>
                    {pageTranslations?.['headers.quarterly_report_list']}
                </h1>
                {user?.permissions?.new_quarterly_report && <button
                    onClick={() => {
                        navigate("/create-quarterly-report/step-1");
                    }}
                >
                    <img src="/assets/icons/plus.svg" alt="plus" width={22} height={22}/>
                    <span>{pageTranslations?.['actions.create_report']}</span>
                </button>}
            </div>
            <Table
                data={quarterlyReportsList}
                emptyDataText={emptyDataText}
                getQuarterlyReportsList={getQuarterlyReportsList}
                tableHeader={[
                    pageTranslations?.['columns.report_name.title'],
                    pageTranslations?.['columns.country.title'],
                    pageTranslations?.['columns.loan_grant_number.title'],
                    pageTranslations?.['columns.implementing_agency.title'],
                    pageTranslations?.['columns.projects.title'],
                    pageTranslations?.['columns.report_number.title'],
                    pageTranslations?.['columns.report_date.title'],
                ]}
                pageTranslations={pageTranslations}
            />
        </Wrapper>
    )
}

export default QuarterlyReports;