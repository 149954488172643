import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useErrorHandler } from "../../../../global/ErrorsHandler";
import {
    useCreateSecurityMonitoringMutation,
    useDeleteSecurityMonitoringMutation,
    useUpdateApplicableSettingsMutation,
    useUpdateSecurityMonitoringMutation,
} from "../../../../store/service/adbService";
import { setLoader } from "../../../../store/slice/dashboard";
import { DeleteModal } from "../../DeleteModal";
import AxelCheckbox from "../../../../UI/AxelCheckbox";
import MoreInfoModal from "../../../MoreInfoModal";

export const SecurityMonitoring = ({
                                       id,
                                       show,
                                       mainData,
                                       glossaryData,
                                       data,
                                       translations
                                   }) => {
    const dispatch = useDispatch();
    const [currentItem, setCurrentItem] = useState(null);
    const [securityData, setSecurityData] = useState([]);
    const [newItem, setNewItem] = useState(false);
    const user = useSelector((state) => state?.adbReducer?.user);
    const [editable, setEditable] = useState(false);
    const [deleteModal, setDeleteModal] = useState(null);
    const [infoModal, setInfoModal] = useState(false);
    const [showLarp, setShowLarp] = useState(false);
    const [tableHeaders, setTableHeaders] = useState([]);
    const [moreInfo, setMoreInfo] = useState(null);

    const [currentRow, setCurrentRow] = useState(null);
    const scrollRef = useRef(null);

    const expandedRef = useRef(null);
    const [expanded, setExpanded] = useState(false);
    const commentsRef = useRef(null);
    const [commentsExpanded, setCommentsExpanded] = useState(false);
    const securityResourcesRef = useRef(null);
    const [securityResourcesExpanded, setSecurityResourcesExpanded] = useState(false);
    const actualRef = useRef(null);
    const [actualExpanded, setActualExpanded] = useState(false);
    const duringRef = useRef(null);
    const [duringExpanded, setDuringExpanded] = useState(false);

    const [
        createSecurityMonitoring,
        {
            data: createSecurityMonitoringData,
            isError: createSecurityMonitoringIsError,
            isLoading: createSecurityMonitoringIsLoading,
            isSuccess: createSecurityMonitoringIsSuccess,
            error: createSecurityMonitoringError,
        },
    ] = useCreateSecurityMonitoringMutation();

    const [
        updateSecurityMonitoring,
        {
            data: updateSecurityMonitoringData,
            isError: updateSecurityMonitoringIsError,
            isLoading: updateSecurityMonitoringIsLoading,
            isSuccess: updateSecurityMonitoringIsSuccess,
            error: updateSecurityMonitoringError,
        },
    ] = useUpdateSecurityMonitoringMutation();

    const [
        updateApplicableSettings,
        {
            data: updateApplicableSettingsData,
            isError: updateApplicableSettingsIsError,
            isLoading: updateApplicableSettingsIsLoading,
            isSuccess: updateApplicableSettingsIsSuccess,
            error: updateApplicableSettingsError,
        },
    ] = useUpdateApplicableSettingsMutation();

    const [
        deleteSecurityMonitoring,
        {
            data: deleteSecurityMonitoringData,
            isError: deleteSecurityMonitoringIsError,
            isLoading: deleteSecurityMonitoringIsLoading,
            isSuccess: deleteSecurityMonitoringIsSuccess,
            error: deleteSecurityMonitoringError,
        },
    ] = useDeleteSecurityMonitoringMutation();

    const [errorHandler, setErrorHandler] = useErrorHandler([
        createSecurityMonitoringError,
        updateSecurityMonitoringError,
        deleteSecurityMonitoringError,
    ]);

    useEffect(() => {
        if(translations){
            const headers = [
                {
                    tooltip_name: translations?.['environment_social_health_safety_and_security_obligations.security_monitoring.table_headers.security_situation_in_the_project_area_description'],
                    tooltip_text: translations?.['environment_social_health_safety_and_security_obligations.security_monitoring.tooltips.security_situation_in_the_project_area_description']
                },
                {
                    tooltip_name: translations?.['environment_social_health_safety_and_security_obligations.security_monitoring.table_headers.security_resources_as_planned_in_security_plan'],
                    tooltip_text: translations?.['environment_social_health_safety_and_security_obligations.security_monitoring.tooltips.security_resources_as_planned_in_security_plan']
                },
                {
                    tooltip_name: translations?.['environment_social_health_safety_and_security_obligations.security_monitoring.table_headers.security_resources_actual_on_site'],
                    tooltip_text: translations?.['environment_social_health_safety_and_security_obligations.security_monitoring.tooltips.security_resources_actual_on_site']
                },
                {
                    tooltip_name: translations?.['environment_social_health_safety_and_security_obligations.security_monitoring.table_headers.security_incidents_reported_during_previous_month'],
                    tooltip_text: translations?.['environment_social_health_safety_and_security_obligations.security_monitoring.tooltips.security_incidents_reported_during_previous_month']
                },
                {
                    tooltip_name: translations?.['environment_social_health_safety_and_security_obligations.security_monitoring.table_headers.security_comments'],
                    tooltip_text: translations?.['environment_social_health_safety_and_security_obligations.security_monitoring.tooltips.security_comments']
                },
            ]

            setTableHeaders(
                headers.map((item) => ({
                    ...item,
                    showTooltip: false,
                }))
            );
        }
    }, [translations])

    useEffect(() => {
        if (glossaryData?.data?.length) {
            setMoreInfo(
                glossaryData?.data?.filter(
                    (el) => el?.section_name === "Security Monitoring"
                )[0]
            );
        }
        if (data?.security_monitoring) {
            let s = data?.security_monitoring?.map((item) => ({
                id: item?.id,
                actual_on_site: item?.actual_on_site,
                area_description: item?.area_description,
                comments: item?.comments,
                during_previous_month: item?.during_previous_month,
                security_plan: item?.security_plan,
                edit: false,
                submitted: item?.submitted,
                old_report: item?.old_report,
                submitted_date: item?.submitted_date,
            }));
            setSecurityData(s);
        }
    }, [data?.security_monitoring, glossaryData]);

    useEffect(() => {
        const d = () => {
            setEditable(false);
            setSecurityData((old) => {
                let data = old.map((item) => ({ ...item, edit: false }));
                return data;
            });
            dispatch(setLoader(false));
        };
        updateApplicableSettingsIsLoading ||
        createSecurityMonitoringIsLoading ||
        updateSecurityMonitoringIsLoading ||
        deleteSecurityMonitoringIsLoading
            ? dispatch(setLoader(true))
            : d();
    }, [
        updateApplicableSettingsIsLoading,
        createSecurityMonitoringIsLoading,
        updateSecurityMonitoringIsLoading,
        deleteSecurityMonitoringIsLoading,
    ]);

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                let array = securityData?.map((item) => {
                    item.edit = false;
                    return item;
                });
                setCurrentItem(null);
                setSecurityData(array);
                setEditable(false);
                if (newItem) {
                    setNewItem(false);
                    let s = securityData?.filter(
                        (el, i) => i !== securityData.length - 1
                    );
                    setSecurityData(s);
                }
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, [securityData]);

    const setterData = (input) => {
        const body = {
            project_id: id,
            actual_on_site: input?.actual_on_site,
            area_description: input?.area_description,
            comments: input?.comments,
            during_previous_month: input?.during_previous_month,
            security_plan: input?.security_plan,
        };

        let s = [];

        for (let key in body) {
            if (key !== "comments") {
                if (!body[key] || body[key] === "Invalid date" || body[key] === "") {
                    toast.error("Please enter " + key?.split("_")?.join(" "), {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    });
                    s.push("false");
                }
            }
        }

        if (s.length === 0) {
            return body;
        } else {
            return null;
        }
    };

    const submitHandler = (e, item) => {
        e.preventDefault();
        if (newItem) {
            const dataCreated = setterData(currentItem);
            if (!dataCreated) {
                return;
            }
            createSecurityMonitoring({
                ...dataCreated,
                closed: currentItem?.closed,
            });
            setCurrentItem(null);
            setNewItem(false);
            setCurrentRow(null);
        } else {
            const dataUpdated = setterData(currentItem);
            if (!dataUpdated) {
                return;
            }
            updateSecurityMonitoring({
                id: item?.id,
                body: {
                    ...dataUpdated,
                    closed: currentItem?.closed,
                },
            });
            setCurrentItem(null);
            setCurrentRow(null);
            setNewItem(false);
        }
    };

    const updateApplicable = (value) => {
        updateApplicableSettings({
            id,
            body: { security_monitoring: value ? 1 : 0 },
        });
    };

    return (
        <div className="project__security">
            {infoModal && (
                <MoreInfoModal
                    title={translations?.['environment_social_health_safety_and_security_obligations.security_monitoring.headers.security_monitoring']}
                    moreInfo={translations?.['environment_social_health_safety_and_security_obligations.security_monitoring.more_info.security_monitoring']}
                    close={()=> setInfoModal(false)}
                />
            )}

            <div style={showLarp ? { border: "none" } : {}}>
                {deleteModal?.show && (
                    <DeleteModal
                        id={deleteModal?.id}
                        action={deleteSecurityMonitoring}
                        setDeleteModal={setDeleteModal}
                        cencelText={translations?.['main.main.actions.cancel']}
                        deleteText={translations?.['main.main.actions.delete']}
                        modalTitle={translations?.['main.main.headers.confirm_your_action']}
                        clarifyingText={translations?.['main.main.titles.you_are_going_to_delete_this_row']}
                    />
                )}
                <div className={`project__block`}>
                    <div className="project__block-subtitles">
                        <div className="general-title checkbox">
                            {translations?.['environment_social_health_safety_and_security_obligations.security_monitoring.headers.security_monitoring']}
                            <div className="project__block-checkbox">
                                <AxelCheckbox
                                    id="security"
                                    label={translations?.['environment_social_health_safety_and_security_obligations.security_monitoring.actions.not_applicable']}
                                    handler={() => {
                                        updateApplicable(!showLarp);
                                        setShowLarp(!showLarp);
                                        let array = securityData?.map((item) => {
                                            item.edit = false;
                                            return item;
                                        });
                                        setCurrentItem(null);
                                        setSecurityData(array);
                                        setEditable(false);
                                        if (newItem) {
                                            setNewItem(false);
                                            let s = securityData?.filter(
                                                (el, i) => i !== securityData.length - 1
                                            );
                                            setSecurityData(s);
                                        }
                                    }}
                                />
                            </div>
                        </div>

                        {user?.permissions?.edit_project && !showLarp && (
                            <div
                                onClick={() => {
                                    let randomId = Math.floor(Math.random() * 1000);
                                    setCurrentRow(randomId);
                                    scrollRef !== null && scrollRef.current.scrollIntoView();
                                    if (!newItem && !showLarp) {
                                        setNewItem(true);
                                        setEditable(true);
                                        setSecurityData((old) => [
                                            ...old,
                                            {
                                                id: Math.floor(Math.random() * 1000),
                                                description: "",
                                                achieved_date: null,
                                                closed: false,
                                                planned_date: null,
                                                edit: true,
                                            },
                                        ]);
                                        setCurrentItem({
                                            id: Math.floor(Math.random() * 1000),
                                            description: "",
                                            achieved_date: null,
                                            closed: false,
                                            planned_date: null,
                                            edit: true,
                                        });
                                    }
                                }}
                                className="project__block-add-row"
                            >
                <span>
                  <img src="/assets/icons/blue-plus.svg" alt="plus" />
                </span>
                                <span>{translations?.['environment_social_health_safety_and_security_obligations.security_monitoring.actions.add_row']}</span>
                            </div>
                        )}
                    </div>
                    <div
                        className={`project__block-content activities ${
                            editable ? "editable" : ""
                        }`}
                    >
                        {!showLarp && (
                            <div className={`project__block-table project-table`}>
                                <div
                                    className={`table__header security`}
                                    style={{gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr'}}
                                    ref={scrollRef}
                                >
                                    {tableHeaders?.map((el, idx) => {
                                        return (
                                            <div
                                                key={idx}
                                                onMouseOver={(event) => {
                                                    setTableHeaders(
                                                        tableHeaders.map((item) => ({
                                                                ...item,
                                                                showTooltip: el.tooltip_name === item.tooltip_name
                                                            }
                                                        )));
                                                }}
                                                onMouseLeave={() => {
                                                    setTableHeaders(
                                                        tableHeaders.map((item) => ({
                                                            ...item,
                                                            showTooltip: false,
                                                        }))
                                                    );
                                                }}
                                                className="project-table__th"
                                            >
                                                {el?.showTooltip ? (
                                                    <span
                                                        className={
                                                            el?.tooltip_text?.length > 20
                                                                ? "lengthy"
                                                                : "not-lengthy"
                                                        }
                                                    >
                            {el?.tooltip_text}
                          </span>
                                                ) : (
                                                    ""
                                                )}
                                                {el?.tooltip_name}
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="project-table__body security">
                                    {securityData?.map((item, index) => (
                                        <div key={index}>
                                            {index !== 0 && currentRow === item?.id && (
                                                <div
                                                    className={`table__header`}
                                                    key={index}
                                                    style={{gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr'}}
                                                >
                                                    {tableHeaders?.map((el, idx) => {
                                                        return (
                                                            <div key={idx} className="project-table__th">
                                                                {el?.showTooltip ? (
                                                                    <span
                                                                        className={
                                                                            el?.tooltip_text?.length > 20
                                                                                ? "lengthy"
                                                                                : "not-lengthy"
                                                                        }
                                                                    >
                                    {el?.tooltip_text}
                                  </span>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                {el?.tooltip_name}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            )}
                                            <form
                                                className={`table__row ${
                                                    showLarp ? "row__disabled" : ""
                                                } ${item?.edit ? "edit" : currentItem ? "n" : ""} ${
                                                    Boolean(item.submitted) ||
                                                    !user?.permissions?.edit_project
                                                        ? "submitted"
                                                        : ""
                                                }`}
                                                style={{gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr'}}
                                                id={item?.id}
                                                key={item?.id}
                                                onSubmit={(e) => submitHandler(e, item)}
                                            >
                                                <div className="project-table__td">
                                                    <span>{item?.area_description}</span>
                                                    <div className="project__block-input project-table__field">

                            <textarea
                                className={`${
                                    expanded ? "project__block-expandedInput" : ""
                                }`}
                                ref={expandedRef}
                                onFocus={() => setExpanded(true)}
                                onBlur={() => setExpanded(false)}
                                type="text"
                                defaultValue={item?.area_description}
                                onChange={(e) => {
                                    setCurrentItem((old) => ({
                                        ...old,
                                        area_description: e.target.value,
                                    }));
                                }}
                                maxLength={190}
                            />
                                                    </div>
                                                </div>
                                                <div className="project-table__td">
                                                    <span>{item?.security_plan}</span>
                                                    <div className="project__block-input project-table__field">
                            <textarea
                                className={`${
                                    securityResourcesExpanded ? "project__block-expandedInput" : ""
                                }`}
                                ref={securityResourcesRef}
                                onFocus={() => setSecurityResourcesExpanded(true)}
                                onBlur={() => setSecurityResourcesExpanded(false)}
                                type="text"
                                defaultValue={item?.security_plan}
                                onChange={(e) => {
                                    setCurrentItem((old) => ({
                                        ...old,
                                        security_plan: e.target.value,
                                    }));
                                }}
                                maxLength={190}
                            />
                                                    </div>
                                                </div>
                                                <div className="project-table__td">
                                                    <span>{item?.actual_on_site}</span>
                                                    <div className="project__block-input project-table__field">
                            <textarea
                                className={`${
                                    actualExpanded ? "project__block-expandedInput" : ""
                                }`}
                                ref={actualRef}
                                onFocus={() => setActualExpanded(true)}
                                onBlur={() => setActualExpanded(false)}
                                type="text"
                                defaultValue={item?.actual_on_site}
                                onChange={(e) => {
                                    setCurrentItem((old) => ({
                                        ...old,
                                        actual_on_site: e.target.value,
                                    }));
                                }}
                                maxLength={190}
                            />
                                                    </div>
                                                </div>
                                                <div className="project-table__td">
                                                    <span>{item?.during_previous_month}</span>
                                                    <div className="project__block-input project-table__field">
                            <textarea
                                className={`${
                                    duringExpanded ? "project__block-expandedInput" : ""
                                }`}
                                ref={duringRef}
                                onFocus={() => setDuringExpanded(true)}
                                onBlur={() => setDuringExpanded(false)}
                                type="text"
                                defaultValue={item?.during_previous_month}
                                onChange={(e) => {
                                    setCurrentItem((old) => ({
                                        ...old,
                                        during_previous_month: e.target.value,
                                    }));
                                }}
                                maxLength={190}
                            />
                                                    </div>
                                                </div>
                                                <div className="project-table__td">
                                                    <span>{item?.comments}</span>
                                                    <div className="project__block-input project-table__field">
                            <textarea
                                className={`${
                                    commentsExpanded
                                        ? "project__block-expandedInput"
                                        : ""
                                }`}
                                ref={commentsRef}
                                onFocus={() => setCommentsExpanded(true)}
                                onBlur={() => setCommentsExpanded(false)}
                                type="text"
                                defaultValue={item?.comments}
                                onChange={(e) => {
                                    setCurrentItem((old) => ({
                                        ...old,
                                        comments: e.target.value,
                                    }));
                                }}
                                maxLength={190}
                            />
                                                    </div>
                                                </div>

                                                {!showLarp && (
                                                    <div className="project__block-btns">
                                                        {currentItem ? (
                                                            <div
                                                                className="project__block-cancel"
                                                                onClick={() => {
                                                                    let array = securityData?.map((item) => {
                                                                        item.edit = false;
                                                                        return item;
                                                                    });
                                                                    setCurrentItem(null);
                                                                    setCurrentRow(null);
                                                                    setSecurityData(array);
                                                                    setEditable(false);
                                                                    if (newItem) {
                                                                        setNewItem(false);
                                                                        let s = securityData?.filter(
                                                                            (el, i) => i !== securityData.length - 1
                                                                        );
                                                                        setSecurityData(s);
                                                                    }
                                                                }}
                                                            >
                                                                <img
                                                                    src="/assets/icons/close-mark.svg"
                                                                    alt="close"
                                                                />
                                                                {translations?.['environment_social_health_safety_and_security_obligations.security_monitoring.actions.cancel']}
                                                            </div>
                                                        ) : (
                                                            <div
                                                                className="project__block-delete"
                                                                onClick={() => {
                                                                    setDeleteModal({ id: item?.id, show: true });
                                                                }}
                                                            >
                                                                <img
                                                                    src="/assets/icons/trash-icon.svg"
                                                                    alt="arrow"
                                                                />
                                                                {translations?.['environment_social_health_safety_and_security_obligations.security_monitoring.actions.delete']}
                                                            </div>
                                                        )}
                                                        {currentItem ? (
                                                            <button className="project__block-save">
                                                                <img
                                                                    src="/assets/icons/save-arrow.svg"
                                                                    alt="arrow"
                                                                />
                                                                {translations?.['environment_social_health_safety_and_security_obligations.security_monitoring.actions.save']}
                                                            </button>
                                                        ) : (
                                                            <div
                                                                onClick={() => {
                                                                    setCurrentRow(item?.id);
                                                                    setCurrentItem(item);
                                                                    setEditable(true);
                                                                    setSecurityData(() => {
                                                                        let array = securityData?.map((elem) => {
                                                                            if (elem?.id === item?.id) {
                                                                                elem.edit = true;
                                                                            }
                                                                            return elem;
                                                                        });
                                                                        return array;
                                                                    });
                                                                }}
                                                                className="project__block-update"
                                                            >
                                                                {translations?.['environment_social_health_safety_and_security_obligations.security_monitoring.actions.update']}
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </form>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
