import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useErrorHandler } from "../../../global/ErrorsHandler";
import {
    useCreateCompletionOfWorkMutation,
    useDeleteCompletionOfWorkMutation,
    useUpdateApplicableSettingsMutation,
    useUpdateCompletionOfWorkMutation,
} from "../../../store/service/adbService";
import { setLoader } from "../../../store/slice/dashboard";
import { Switch } from "../../../UI/Switch";
import { DeleteModal } from "../DeleteModal";
import AxelCheckbox from "../../../UI/AxelCheckbox";
import MoreInfoModal from "../../MoreInfoModal";
import CustomDatePicker from "../../CustomDatePicker";

export const CompletionOfWork = ({
                                     id,
                                     show,
                                     setShow,
                                     refCompletion,
                                     glossaryData,
                                     data,
                                     translations
                                 }) => {
   const localeLan = localStorage.getItem("local_lan" );
 moment.locale(localeLan);
    const dispatch = useDispatch();
    const [currentItem, setCurrentItem] = useState(null);
    const [completionData, setCompletionData] = useState([]);
    const [newItem, setNewItem] = useState(false);
    const [editable, setEditable] = useState(false);
    const [deleteModal, setDeleteModal] = useState(null);
    const user = useSelector((state) => state?.adbReducer?.user);
    const [infoModal, setInfoModal] = useState(false);
    const [showLarp, setShowLarp] = useState(false);
    const [tableHeaders, setTableHeaders] = useState([]);
    const [moreInfo, setMoreInfo] = useState(null);
    const expandedRef = useRef(null);
    const [expanded, setExpanded] = useState(false);
    const [expandedComment, setExpandedComment] = useState(false);
    const scrollRef = useRef(null);

    const [
        createCompletionOfWork,
        {
            data: createCompletionOfWorkData,
            isError: createCompletionOfWorkIsError,
            isLoading: createCompletionOfWorkIsLoading,
            isSuccess: createCompletionOfWorkIsSuccess,
            error: createCompletionOfWorkError,
        },
    ] = useCreateCompletionOfWorkMutation();

    const [
        updateCompletionOfWork,
        {
            data: updateCompletionOfWorkData,
            isError: updateCompletionOfWorkIsError,
            isLoading: updateCompletionOfWorkIsLoading,
            isSuccess: updateCompletionOfWorkIsSuccess,
            error: updateCompletionOfWorkError,
        },
    ] = useUpdateCompletionOfWorkMutation();

    const [
        deleteCompletionOfWork,
        {
            data: deleteCompletionOfWorkData,
            isError: deleteCompletionOfWorkIsError,
            isLoading: deleteCompletionOfWorkIsLoading,
            isSuccess: deleteCompletionOfWorkIsSuccess,
            error: deleteCompletionOfWorkError,
        },
    ] = useDeleteCompletionOfWorkMutation();

    const [
        updateApplicableSettings,
        {
            data: updateApplicableSettingsData,
            isError: updateApplicableSettingsIsError,
            isLoading: updateApplicableSettingsIsLoading,
            isSuccess: updateApplicableSettingsIsSuccess,
            error: updateApplicableSettingsError,
        },
    ] = useUpdateApplicableSettingsMutation();

    const [errorHandler, setErrorHandler] = useErrorHandler([
        createCompletionOfWorkError,
        updateCompletionOfWorkError,
        deleteCompletionOfWorkError,
    ]);

    useEffect(() => {
        if(translations){
            const headers = [
                {
                    tooltip_name: translations?.['completion_of_work_and_contract_closure_activities_table.contract_closure.table_headers.completion_closure_activity_description'],
                    tooltip_text: translations?.['completion_of_work_and_contract_closure_activities_table.contract_closure.tooltips.completion_closure_activity_description'],
                },
                {
                    tooltip_name: translations?.['completion_of_work_and_contract_closure_activities_table.contract_closure.table_headers.planned_date'],
                    tooltip_text: translations?.['completion_of_work_and_contract_closure_activities_table.contract_closure.tooltips.planned_date'],
                },
                {
                    tooltip_name: translations?.['completion_of_work_and_contract_closure_activities_table.contract_closure.table_headers.actual_date'],
                    tooltip_text: translations?.['completion_of_work_and_contract_closure_activities_table.contract_closure.tooltips.actual_date'],
                },
                {
                    tooltip_name: translations?.['completion_of_work_and_contract_closure_activities_table.contract_closure.table_headers.current_status_or_comments'],
                    tooltip_text: translations?.['completion_of_work_and_contract_closure_activities_table.contract_closure.tooltips.current_status_or_comments'],
                },
                {
                    tooltip_name: translations?.['completion_of_work_and_contract_closure_activities_table.contract_closure.table_headers.completed'],
                    tooltip_text: translations?.['completion_of_work_and_contract_closure_activities_table.contract_closure.tooltips.completed'],
                },
            ]

            setTableHeaders(
                headers.map((item) => ({
                    ...item,
                    showTooltip: false,
                }))
            );
        }
    }, [translations])

    useEffect(() => {
        if (glossaryData?.data?.length) {
            setMoreInfo(
                glossaryData?.data?.filter(
                    (el) =>
                        el?.section_name ===
                        "Completion of Work and Contract Closure Activities Table"
                )[0]
            );
        }
        if (data?.completion_of_work) {
            let s = data?.completion_of_work?.map((item) => ({
                id: item?.id,
                description: item?.description,
                planned_date: item?.planned_date,
                achieved_date: item?.achieved_date ? item?.achieved_date : null,
                closed: item?.closed,
                edit: false,
                submitted: item?.submitted,
                old_report: item?.old_report,
                submitted_date: item?.submitted_date,
                comment: item?.comment
            }));
            setCompletionData(s);
        }
    }, [data, glossaryData]);

    useEffect(() => {
        const d = () => {
            setEditable(false);
            setCompletionData((old) => {
                let data = old.map((item) => ({ ...item, edit: false }));
                return data;
            });
            dispatch(setLoader(false));
        };

        updateApplicableSettingsIsLoading ||
        createCompletionOfWorkIsLoading ||
        updateCompletionOfWorkIsLoading ||
        deleteCompletionOfWorkIsLoading
            ? dispatch(setLoader(true))
            : d();
    }, [
        createCompletionOfWorkIsLoading,
        updateCompletionOfWorkIsLoading,
        deleteCompletionOfWorkIsLoading,
        updateApplicableSettingsIsLoading,
    ]);

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                let array = completionData?.map((item) => {
                    item.edit = false;
                    return item;
                });
                setCurrentItem(null);
                setCompletionData(array);
                setEditable(false);
                if (newItem) {
                    setNewItem(false);
                    let s = completionData?.filter(
                        (el, i) => i !== completionData.length - 1
                    );
                    setCompletionData(s);
                }
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, [completionData]);

    const setterData = (input) => {
        const body = {
            project_id: id,
            description: input?.description,
            planned_date: moment(input?.planned_date).format("YYYY-MM-DD"),
        };

        let s = [];

        if (currentItem?.closed) {
            body.achieved_date = moment(input?.achieved_date).format("YYYY-MM-DD");
        } else {
            delete body.achieved_date;
        }

        for (let key in body) {
            if (key !== "comment") {
                if (!body[key] || body[key] === "Invalid date" || body[key] === "") {
                    toast.error("Please enter " + key?.split("_")?.join(" "), {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    });
                    s.push("false");
                }
            }
        }

        if (s.length === 0) {
            return body;
        } else {
            return null;
        }
    };

    const submitHandler = (e, item) => {
        e.preventDefault();
        if (newItem) {
            const dataCreated = setterData(currentItem);
            if (!dataCreated) {
                return;
            }
            createCompletionOfWork({
                ...dataCreated,
                closed: currentItem?.closed,
                comment: currentItem.comment
            });
            setCurrentItem(null);
            setNewItem(false);
        } else {
            const dataUpdated = setterData(currentItem);
            if (!dataUpdated) {
                return;
            }
            updateCompletionOfWork({
                id: item?.id,
                body: {
                    ...dataUpdated,
                    closed: currentItem?.closed,
                    comment: currentItem?.comment
                },
            });
            setCurrentItem(null);
            setNewItem(false);
        }
    };

    const updateApplicable = (value) => {
        updateApplicableSettings({
            id,
            body: { completion_of_work: value ? 1 : 0 },
        });
    };

    return (
        <>
            {infoModal && (
                <MoreInfoModal
                    title={translations?.['completion_of_work_and_contract_closure_activities_table.completion_of_work_and_contract_closure_activities_table.headers.completion_of_work_and_contract_closure_activities_table']}
                    moreInfo={translations?.['completion_of_work_and_contract_closure_activities_table.completion_of_work_and_contract_closure_activities_table.more_info.completion_of_work_and_contract_closure_activities_table']}
                    close={()=> setInfoModal(false)}
                />
            )}

            <div
                className="project__white-box"
                style={showLarp ? { border: "none" } : {}}
                ref={refCompletion}
            >
                {deleteModal?.show && (
                    <DeleteModal
                        id={deleteModal?.id}
                        action={deleteCompletionOfWork}
                        setDeleteModal={setDeleteModal}
                        cencelText={translations?.['main.main.actions.cancel']}
                        deleteText={translations?.['main.main.actions.delete']}
                        modalTitle={translations?.['main.main.headers.confirm_your_action']}
                        clarifyingText={translations?.['main.main.titles.you_are_going_to_delete_this_row']}
                    />
                )}
                <div className={`project__block ${show ? "" : "close"}`}>
                    <div className="d-f">
                        <div
                            className="project__block-title"
                            onClick={() => setShow(!show)}
                        >
              <span>
                {translations?.['completion_of_work_and_contract_closure_activities_table.completion_of_work_and_contract_closure_activities_table.headers.completion_of_work_and_contract_closure_activities_table']}
              </span>
                        </div>
                        <span
                            className="project__block-more"
                            onClick={() => setInfoModal(!infoModal)}
                        >
              <span className="img">
                <img src="/assets/icons/info.svg" alt="info" />
              </span>
              <span className="text">{translations?.['completion_of_work_and_contract_closure_activities_table.completion_of_work_and_contract_closure_activities_table.titles.more_info']}</span>
            </span>
                    </div>
                    <div className="project__block-hr"></div>
                    <div className="project__block-subtitles">
                        <div className="general-title checkbox">
                            {translations?.['completion_of_work_and_contract_closure_activities_table.contract_closure.headers.contract_closure']}
                            <div className="project__block-checkbox">
                                <AxelCheckbox
                                    id="completion"
                                    label={translations?.['completion_of_work_and_contract_closure_activities_table.contract_closure.actions.not_applicable']}
                                    handler={() => {
                                        updateApplicable(!showLarp);
                                        setShowLarp(!showLarp);
                                        let array = completionData?.map((item) => {
                                            item.edit = false;
                                            return item;
                                        });
                                        setCurrentItem(null);
                                        setCompletionData(array);
                                        setEditable(false);
                                        if (newItem) {
                                            setNewItem(false);
                                            let s = completionData?.filter(
                                                (el, i) => i !== completionData.length - 1
                                            );
                                            setCompletionData(s);
                                        }
                                    }}
                                />
                            </div>
                        </div>

                        {user?.permissions?.edit_project && !showLarp && (
                            <div
                                onClick={() => {
                                    if (!newItem && !showLarp) {
                                        setNewItem(true);
                                        setEditable(true);
                                        setCompletionData((old) => [
                                            ...old,
                                            {
                                                id: Math.floor(Math.random() * 1000),
                                                description: "",
                                                achieved_date: null,
                                                closed: false,
                                                planned_date: null,
                                                comment: "",
                                                edit: true,
                                            },
                                        ]);
                                        setCurrentItem({
                                            id: Math.floor(Math.random() * 1000),
                                            description: "",
                                            achieved_date: null,
                                            comment: "",
                                            closed: false,
                                            planned_date: null,
                                            edit: true,
                                        });
                                    }
                                }}
                                className="project__block-add-row"
                            >
                <span>
                  <img src="/assets/icons/blue-plus.svg" alt="plus" />
                </span>
                                <span>{translations?.['completion_of_work_and_contract_closure_activities_table.contract_closure.actions.add_row']}</span>
                            </div>
                        )}
                    </div>
                    <div
                        className={`project__block-content activities ${
                            editable ? "editable" : ""
                        }`}
                    >
                        {!showLarp && (
                            <div className={`project__block-table project-table`}>
                                <div ref={scrollRef} className={`table__header`} style={{gridTemplateColumns: '23% 24% 18% 23% 10%'}}>
                                    {tableHeaders?.map((el, idx) => {
                                        return (
                                            <div
                                                key={idx}
                                                onMouseEnter={(event) => {
                                                    setTableHeaders(
                                                        tableHeaders.map((item) => ({
                                                            ...item,
                                                            showTooltip: el.tooltip_name === item.tooltip_name
                                                        }))
                                                    );
                                                }}
                                                onMouseLeave={() => {
                                                    setTableHeaders(
                                                        tableHeaders.map((item) => ({
                                                            ...item,
                                                            showTooltip: false,
                                                        }))
                                                    );
                                                }}
                                                className={`project-table__th ${
                                                    idx === 2 ? "project-table__achieved" : ""
                                                }`}
                                            >
                                                {el?.showTooltip ? (
                                                    <span
                                                        className={
                                                            el?.tooltip_text?.length > 20
                                                                ? "lengthy"
                                                                : "not-lengthy"
                                                        }
                                                    >
                            {el?.tooltip_text}
                          </span>
                                                ) : (
                                                    ""
                                                )}
                                                {el?.tooltip_name}
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="project-table__body">
                                    {completionData?.map((item, index) => (
                                        <form
                                            className={`table__row ${
                                                showLarp ? "row__disabled" : ""
                                            } ${item?.edit ? "edit" : currentItem ? "n" : ""} ${
                                                Boolean(item.submitted) ||
                                                !user?.permissions?.edit_project
                                                    ? "submitted"
                                                    : ""
                                            }`}
                                            style={{gridTemplateColumns: '23% 24% 18% 23% 10%'}}
                                            id={item?.id}
                                            key={item?.id}
                                            onSubmit={(e) => submitHandler(e, item)}
                                        >
                                            <div className="project-table__td">
                                                <span>{item?.description}</span>
                                                <div className="project__block-input project-table__field">
                          <textarea
                              className={`${
                                  expanded ? "project__block-expandedInput" : ""
                              }`}
                              ref={expandedRef}
                              onFocus={() => setExpanded(true)}
                              onBlur={() => setExpanded(false)}
                              type="text"
                              defaultValue={item?.description}
                              onChange={(e) => {
                                  setCurrentItem((old) => ({
                                      ...old,
                                      description: e.target.value,
                                  }));
                              }}
                              maxLength={190}
                          />
                                                </div>
                                            </div>
                                            <div className="project-table__td">
                        <span>
                          {moment(item?.planned_date).format("DD/MM/YYYY") ===
                          "Invalid date" ||
                          !moment(item?.planned_date).format("DD/MM/YYYY")
                              ? ""
                              : moment(item?.planned_date).format("DD/MM/YYYY")}
                        </span>
                                                <div className="project__block-item-calendar project-table__field">
                                                    <CustomDatePicker
                                                        portalId="root-portal"
                                                        autoComplete="off"
                                                        id="approved_date"
                                                        placeholderText="dd/mm/yyyy"
                                                        dateFormat="dd/MM/yyyy"
                                                        selected={
                                                            currentItem?.planned_date
                                                                ? moment(currentItem?.planned_date).toDate()
                                                                : ""
                                                        }
                                                        onChange={(date) => {
                                                            setCurrentItem((old) => ({
                                                                ...old,
                                                                planned_date: date,
                                                            }));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="project-table__td project-table__achieved">
                        <span>
                          {moment(item?.achieved_date).format("DD/MM/YYYY") ===
                          "Invalid date" ||
                          !moment(item?.achieved_date).format("DD/MM/YYYY")
                              ? ""
                              : moment(item?.achieved_date).format("DD/MM/YYYY")}
                        </span>
                                                <div
                                                    className={`project__block-item-calendar project-table__field ${
                                                        currentItem?.closed
                                                            ? ""
                                                            : "project__actualDate-disabled"
                                                    }`}
                                                >
                                                    <CustomDatePicker
                                                        portalId="root-portal"
                                                        id="approved_date"
                                                        autoComplete="off"
                                                        placeholderText="dd/mm/yyyy"
                                                        dateFormat="dd/MM/yyyy"
                                                        disabled={!currentItem?.closed}
                                                        selected={
                                                            currentItem?.achieved_date
                                                                ? moment(currentItem?.achieved_date).toDate()
                                                                : ""
                                                        }
                                                        onChange={(date) => {
                                                            setCurrentItem((old) => ({
                                                                ...old,
                                                                achieved_date: date,
                                                            }));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="project-table__td">
                                                <span>{item?.comment}</span>
                                                <div className="project__block-input project-table__field">
                          <textarea
                              className={`${
                                  expandedComment ? "project__block-expandedInput" : ""
                              }`}
                              onFocus={() => setExpandedComment(true)}
                              onBlur={() => setExpandedComment(false)}
                              type="text"
                              defaultValue={item?.comment}
                              onChange={(e) => {
                                  setCurrentItem((old) => ({
                                      ...old,
                                      comment: e.target.value,
                                  }));
                              }}
                              maxLength={190}
                          />
                                                </div>
                                            </div>
                                            <div className="project-table__td">
                                                <Switch
                                                    value={item?.closed}
                                                    id={index + 1126}
                                                    disabled={item?.edit ? false : true}
                                                    checked={
                                                        item?.edit
                                                            ? currentItem?.achieved_date
                                                            : item?.closed
                                                    }
                                                    handler={(value) => {
                                                        setCurrentItem((old) => ({
                                                            ...old,
                                                            closed: value,
                                                            achieved_date: value
                                                                ? currentItem?.achieved_date
                                                                : null,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                            {!showLarp && (
                                                <div className="project__block-btns">
                                                    {currentItem ? (
                                                        <div
                                                            className="project__block-cancel"
                                                            onClick={() => {
                                                                let array = completionData?.map((item) => {
                                                                    item.edit = false;
                                                                    return item;
                                                                });
                                                                setCurrentItem(null);
                                                                setCompletionData(array);
                                                                setEditable(false);
                                                                if (newItem) {
                                                                    setNewItem(false);
                                                                    let s = completionData?.filter(
                                                                        (el, i) => i !== completionData.length - 1
                                                                    );
                                                                    setCompletionData(s);
                                                                }
                                                            }}
                                                        >
                                                            <img
                                                                src="/assets/icons/close-mark.svg"
                                                                alt="close"
                                                            />
                                                            {translations?.['completion_of_work_and_contract_closure_activities_table.contract_closure.actions.cancel']}
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className="project__block-delete"
                                                            onClick={() => {
                                                                // deleteCompletionOfWork(item?.id);
                                                                setDeleteModal({ id: item?.id, show: true });
                                                            }}
                                                        >
                                                            <img
                                                                src="/assets/icons/trash-icon.svg"
                                                                alt="arrow"
                                                            />
                                                            {translations?.['completion_of_work_and_contract_closure_activities_table.contract_closure.actions.delete']}
                                                        </div>
                                                    )}
                                                    {currentItem ? (
                                                        <button className="project__block-save">
                                                            <img
                                                                src="/assets/icons/save-arrow.svg"
                                                                alt="arrow"
                                                            />
                                                            {translations?.['completion_of_work_and_contract_closure_activities_table.contract_closure.actions.save']}
                                                        </button>
                                                    ) : (
                                                        <div
                                                            onClick={() => {
                                                                setCurrentItem(item);
                                                                setEditable(true);
                                                                setCompletionData(() => {
                                                                    let array = completionData?.map((elem) => {
                                                                        if (elem?.id === item?.id) {
                                                                            elem.edit = true;
                                                                        }
                                                                        return elem;
                                                                    });
                                                                    return array;
                                                                });
                                                            }}
                                                            className="project__block-update"
                                                        >
                                                            {translations?.['completion_of_work_and_contract_closure_activities_table.contract_closure.actions.update']}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </form>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
