import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DeleteModal } from "../DeleteModal";
import MoreInfoModal from "../../MoreInfoModal";
import {
    useCreatePhotoMutation,
    useDeletePhotoMutation, useDeleteSitePhotographsMutation,
    useLazyGetProjectQuery,
} from "../../../store/service/adbService";
import { setLoader } from "../../../store/slice/dashboard";
import { useErrorHandler } from "../../../global/ErrorsHandler";

export const SitePhotographs = ({
                                    id,
                                    data,
                                    isReportSubmitted,
                                    refSitePhotographs,
                                    translations,
                                    isQuarterReport,
                                    updateUrl,
                                    getSitePhotographs,
                                    reportId,
                                    className
                                }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [deleteModal, setDeleteModal] = useState(null);
    const user = useSelector((state) => state?.adbReducer?.user);
    const [currentImg, setCurrentImg] = useState({ image: "" });
    const [previewStatus, setPreviewStatus] = useState(false);
    const [sitePhotos, setSitePhotos] = useState([]);
    const [prevTitle, setPrevTitle] = useState({ title: "", id: "" });
    const [isOpenMoreInfo, setOpenMoreInfo] = useState(false);
    const [show, setShow] = useState(true);
    const [currentId, setCurrentId] = useState(null);
    const [expanded, setExpanded] = useState(false);
    const [clearModal, setClearModal] = useState(false);
    const [newData, setNewData] = useState({});
    const [isSaving, setIsSaving] = useState(false);

    const [isCreateOpen, setIsCreateOpen] = useState(false);
    const [trigger] = useLazyGetProjectQuery();

    const [
        deletePhoto,
        {
            isLoading: deletePhotoIsLoading,
            error: deletePhotoError,
        },
    ] = useDeletePhotoMutation();

    const [
        deleteSitePhoto,
        {
            isLoading: deleteSitePhotographsIsLoading,
        },
    ] = useDeleteSitePhotographsMutation();

    const [
        createPhoto, {
            data: createPhotoData,
            isSuccess: createPhotoIsSuccess,
            isLoading: createPhotoIsLoading,
            error: createPhotoError,
        }
    ] = useCreatePhotoMutation();

    useEffect(() => {
        const d = () => {
            dispatch(setLoader(false));
            setCurrentId(null);
        };

        deleteSitePhotographsIsLoading ? dispatch(setLoader(true)) : d();
        deletePhotoIsLoading || createPhotoIsLoading ? dispatch(setLoader(true))  : dispatch(setLoader(false));
    }, [deletePhotoIsLoading, deleteSitePhotographsIsLoading, createPhotoIsLoading]);

    useEffect(() => {
        if (data) {
            setSitePhotos(
                data?.map((el) => ({ ...el, edit: false }))
            );
        }
    }, [data]);

    useEffect(() => {
        if (createPhotoIsSuccess) {
            toast.success(createPhotoData?.message)
            setIsSaving(false); // Reset the saving state
        }
    }, [createPhotoIsSuccess, createPhotoData])

    const [errorHandler, setErrorHandler] = useErrorHandler([
        createPhotoError,
        deletePhotoError
    ]);

    const uploadImage = (e, photoId) => {
        dispatch(setLoader(true));
        const headers = {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ` + localStorage?.getItem("user_token"),
        };
        axios
            .post(
                `${process.env.REACT_APP_API_URL}/${updateUrl}/${photoId && photoId}`,

                prevTitle?.title
                    ? { description: prevTitle?.title }
                    : { photo: photoId ? e?.target?.files[0] : "" },
                { headers: headers }
            )
            .then(() => {
                if (isQuarterReport) {
                    getSitePhotographs()
                } else {
                    trigger(id ?? localStorage.getItem("itemId"));
                }
                setPrevTitle({ title: "", id: null });
                dispatch(setLoader(false));
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    navigate("/login");
                    localStorage.removeItem("user_token");
                } else {
                    toast.error(
                        err?.response?.data?.errors?.preview_image[0] ||
                        err?.response?.data?.errors?.image[0],
                        {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 8000,
                        }
                    );
                }
            });
    };

    const clearDescription = (descrId) => {
        dispatch(setLoader(true));
        const headers = {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ` + localStorage?.getItem("user_token"),
        };
        axios
            .post(
                `${process.env.REACT_APP_API_URL}/${updateUrl}/${descrId}`,
                { description: " " },
                { headers: headers }
            )
            .then(() => {
                if (isQuarterReport) {
                    getSitePhotographs()
                } else {
                    trigger(id ?? localStorage.getItem("itemId"));
                }
                setPrevTitle({ title: "", id: null });
                dispatch(setLoader(false));
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    navigate("/login");
                    localStorage.removeItem("user_token");
                } else {
                    toast.error(
                        err?.response?.data?.errors?.preview_image[0] ||
                        err?.response?.data?.errors?.image[0],
                        {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 8000,
                        }
                    );
                }
            });
    };

    const photosTr = [
        translations?.['site_photographs.site_photographs.titles.photo_1'],
        translations?.['site_photographs.site_photographs.titles.photo_2'],
        translations?.['site_photographs.site_photographs.titles.photo_3'],
        translations?.['site_photographs.site_photographs.titles.photo_4'],
        translations?.['site_photographs.site_photographs.titles.photo_5'],
        translations?.['site_photographs.site_photographs.titles.photo_6']
    ]

    const createNew = () => {
        if (isSaving) return; // Prevent further execution if already saving
        setIsSaving(true); // Set saving state to true

        const formData = new FormData();
        formData.append('image', newData.file);
        formData.append('report_id', reportId);
        formData.append('description', newData.desc);

        if (!newData.file) {
            toast.error('Image is required!')
            setIsSaving(false);
        } else if (!newData.desc) {
            toast.error('Description is required!')
            setIsSaving(false);
        } else {
            dispatch(setLoader(true));
            createPhoto(formData).unwrap()
                .then(() => {
                    setIsCreateOpen(false);
                    setNewData({});
                    getSitePhotographs();
                })
                .catch(e => {
                    e?.data?.errors?.description?.forEach(error => {
                        toast.error(error, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 8000,
                        });
                    })
                })
                .finally(() => {
                    dispatch(setLoader(false));
                    setIsSaving(false); // Reset the saving state once done
                })
        }
    }

    const handleDeleteSitePhoto = (id) => {
        deleteSitePhoto(id).unwrap()
            .then(() => {
                getSitePhotographs()
            })
    }

    const clearDescriptionOrDeleteRow = (id) => {
        if (isQuarterReport) {
            setCurrentId(id);
            setDeleteModal((old) => ({ ...old, show: true }));
        } else {
            setClearModal({ show: true, id: id });
        }
    }

    return (
        <section className={className}>
            <div className={`project__white-box ${show ? "" : "close"}`} style={{ padding: '25px' }}>
                <div className="project__site">
                    {isOpenMoreInfo &&
                        <MoreInfoModal
                            title={translations?.[`site_photographs.${isQuarterReport ? 'index' : 'site_photographs'}.headers.site_photographs`]}
                            moreInfo={isQuarterReport ? translations?.['site_photographs.index.more_info.more_info'] : translations?.['site_photographs.site_photographs.more_info.site_photographs']}
                            close={() => setOpenMoreInfo(false)}
                        />
                    }
                    {deleteModal?.show && (
                        <DeleteModal
                            action={isQuarterReport ? handleDeleteSitePhoto : deletePhoto}
                            id={currentId}
                            setDeleteModal={setDeleteModal}
                            isPhoto={true}
                            cencelText={translations?.['main.main.actions.cancel']}
                            deleteText={translations?.['main.main.actions.delete']}
                            modalTitle={translations?.['main.main.headers.confirm_your_action']}
                            clarifyingText={translations?.['main.main.titles.you_are_going_to_delete_this_row']}
                        />
                    )}
                    {clearModal?.show && (
                        <DeleteModal
                            action={() => clearDescription(clearModal?.id)}
                            id={clearModal?.id}
                            setDeleteModal={setClearModal}
                            cencelText={translations?.['main.main.actions.cancel']}
                            deleteText={translations?.['main.main.actions.delete']}
                            modalTitle={translations?.['main.main.headers.confirm_your_action']}
                            clarifyingText={translations?.['main.main.titles.you_are_going_to_delete_this_row']}
                        />
                    )}
                    {/*Headers*/}
                    <div
                        ref={refSitePhotographs}
                        id={refSitePhotographs}
                        className="project__block-subtitles"
                    >
                        <div className="d-f">
                            <div onClick={() => setShow(!show)} className="project__block-title data__title">
                                <span>{translations?.[`site_photographs.${isQuarterReport ? 'index' : 'site_photographs'}.headers.site_photographs`]}</span>
                            </div>
                            <span
                                onClick={() => {
                                    setOpenMoreInfo(true)
                                }}
                                className="project__block-more"
                            >
                               <span className="img">
                                    <img src="/assets/icons/info.svg" alt="info" />
                               </span>
                               <span className="text">
                                  {isQuarterReport ? translations?.['documents.index.titles.more_info'] : translations?.['site_photographs.site_photographs.titles.more_info']}
                               </span>
                          </span>
                        </div>
                        {(isQuarterReport && ! isReportSubmitted) ? (
                            <div
                                className="project__block-add-row"
                                onClick={() => setIsCreateOpen(true)}
                            >
                                <span>
                                    <img src="/assets/icons/blue-plus.svg" alt="upload"/>
                                </span>
                                <span>{translations?.['site_photographs.index.actions.add_row']}</span>
                            </div>
                            ) : null
                        }
                    </div>

                    <div className="project__site_content">
                        {sitePhotos?.map((el, idx) => {
                            return (
                                <div key={el?.id} className="project__site-photo">
                                    <div className="project__site-image">
                                        {previewStatus && (
                                            <div className="custom-gallery">
                                                <div className="custom-gallery__content">
                                                    <div
                                                        className="custom-gallery__closeBtn"
                                                        onClick={() => setPreviewStatus(false)}
                                                    >
                                                        <img
                                                            className="custom-gallery__close"
                                                            src="/assets/icons/close.svg"
                                                            alt="close"
                                                        />
                                                    </div>
                                                    <img
                                                        className="custom-gallery__img"
                                                        src={currentImg}
                                                        alt="photo"
                                                    />
                                                </div>
                                                <div
                                                    className="custom-gallery__bg"
                                                    onClick={() => {
                                                        setPreviewStatus(false);
                                                    }}
                                                ></div>
                                            </div>
                                        )}
                                        <p>{photosTr[idx]}</p>
                                        {!el?.photo ? (
                                            <div className="project__upload">
                                                {user?.permissions?.edit_project && (
                                                    <>
                                                        <img src="/assets/icons/upload.svg" alt="upload" />
                                                        <input
                                                            type="file"
                                                            accept="image/png, image/jpeg"
                                                            onChange={(e) => uploadImage(e, el?.id)}
                                                        />
                                                    </>
                                                )}
                                            </div>
                                        ) : (
                                            <div className="project__gallary-image" >
                                                <img
                                                    onClick={() => {
                                                        setPreviewStatus(true);
                                                        setCurrentImg(el?.photo);
                                                    }}
                                                    src={el?.photo}
                                                    alt=""
                                                    style={{objectFit: 'contain'}}
                                                />
                                                {(user?.permissions?.edit_project  &&  !isQuarterReport) && (
                                                    <>
                                                        <label className="project__gallary-delete">
                                                            <input
                                                                id="image_2"
                                                                type="file"
                                                                hidden
                                                                accept="image/png, image/jpeg"
                                                                onChange={(e) => uploadImage(e, el?.id)}
                                                            />
                                                            <img
                                                                src="/assets/icons/upload.svg"
                                                                alt="upload"
                                                                onClick={() => {
                                                                    setCurrentImg(el?.photo);
                                                                }}
                                                            />
                                                        </label>
                                                        <img
                                                            onClick={() => {
                                                                setDeleteModal((old) => ({ ...old, show: true }));
                                                                setCurrentId(el?.id);
                                                            }}
                                                            className="project__gallary-deleteSvg"
                                                            src="/assets/icons/delete.svg"
                                                            alt=""
                                                        />
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </div>

                                    {/* Description container*/}
                                    <div className="project__site-description">
                                        <p>{translations?.[`site_photographs.${isQuarterReport ? 'index' : 'site_photographs'}.titles.description`]}</p>
                                        <div
                                            className={`project__description-box ${
                                                el?.edit ? "edit" : ""
                                            }`}
                                        >
                                            {el?.edit ? (
                                                <textarea
                                                    onFocus={() => setExpanded(true)}
                                                    defaultValue={el?.description}
                                                    placeholder={"Enter description"}
                                                    maxLength={2000}
                                                    rows={expanded ? 7 : 4}
                                                    onChange={(e) => {
                                                        setPrevTitle({ title: e?.target?.value, id: el?.id });
                                                    }}
                                                />
                                            ) : (
                                                <div className="project__description-subtitle">
                                                  <p> {el?.description}</p>
                                                </div>
                                            )}

                                            {/*Settings buttons*/}
                                            {user?.permissions?.edit_project && (
                                                <div className="project__block-btns">
                                                    {el?.edit ? (<>
                                                        <div
                                                            className="project__block-cancel"
                                                            onClick={() => {
                                                                setSitePhotos(
                                                                    sitePhotos?.map((item) => ({
                                                                        ...item,
                                                                        edit: item?.id === el?.id && false,
                                                                    }))
                                                                );
                                                            }}
                                                        >
                                                            <img
                                                                src="/assets/icons/close-mark.svg"
                                                                alt="close"
                                                            />
                                                            {translations?.[`site_photographs.${isQuarterReport ? 'index' : 'site_photographs'}.actions.cancel`]}
                                                        </div>
                                                        <button
                                                            onClick={() => {
                                                                setExpanded(false);
                                                                setSitePhotos(
                                                                    sitePhotos?.map((item) => ({
                                                                        ...item,
                                                                        edit: false,
                                                                    }))
                                                                );
                                                                uploadImage("", el?.id);
                                                            }}
                                                            className="project__block-save"
                                                        >
                                                            <img
                                                                src="/assets/icons/save-arrow.svg"
                                                                alt="arrow"
                                                            />
                                                            {translations?.[`site_photographs.${isQuarterReport ? 'index' : 'site_photographs'}.actions.save`]}
                                                        </button>
                                                    </>
                                                   )
                                                    : ( !isReportSubmitted &&
                                                        //     Description clear and delete buttons
                                                        <>
                                                            {!(el?.description ==='' || el?.description ===null && !isQuarterReport ) && <div

                                                                onClick={() => clearDescriptionOrDeleteRow(el?.id)}
                                                                className="project__block-delete"
                                                            >
                                                                {translations?.[`site_photographs.${isQuarterReport ? 'index.actions.delete' : 'site_photographs.actions.clear'}`]}
                                                            </div>
                                                            }
                                                            <div
                                                                onClick={() => {
                                                                    setNewData();
                                                                    setPrevTitle({ title: el?.description, id: el?.id });
                                                                    setSitePhotos(
                                                                        sitePhotos?.map((item) => ({
                                                                            ...item,
                                                                            edit: item?.id === el?.id,
                                                                        }))
                                                                    );
                                                                }}
                                                                className="project__block-update"
                                                            >
                                                                {translations?.[`site_photographs.${isQuarterReport ? 'index' : 'site_photographs'}.actions.update`]}
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                </div>
                            );
                        })}
                        {isCreateOpen && show && (
                            <div className="project__site-photo">
                                <div className=" editReport__table__editRow add" style={{zIndex: 9}}>
                                    <div className="editReport__fieldWrapper add">
                                        <div className="project__upload" >
                                            {
                                                newData.file ? (
                                                    <div className="project__gallary-image">
                                                        <img
                                                            src={newData.fileUrl}
                                                            alt="img"
                                                            style={{borderRadius: '4px', objectFit: 'contain'}}
                                                        />
                                                    </div>
                                                ) : (
                                                    <>
                                                        <img src="/assets/icons/upload.svg" alt="upload" />
                                                        <input
                                                            type="file"
                                                            accept="image/png, image/jpeg"
                                                            onChange={(e) => {
                                                                setNewData((old) => ({...old, fileUrl: URL.createObjectURL(e.target.files[0]), file: e.target.files[0]}))
                                                            }}
                                                        />
                                                    </>
                                                )
                                            }
                                        </div>
                                        <textarea
                                            style={{width: '100%', borderRadius: '4px', padding: '6px'}}
                                            defaultValue={newData?.description}
                                            placeholder={translations?.['site_photographs.index.placeholder.enter_description']}
                                            maxLength={2000}
                                            rows={expanded ? 7 : 4}
                                            onChange={(e) => {
                                                setNewData((old) => ({...old, desc: e.target.value}))
                                            }}
                                        />
                                    </div>
                                    <div className="editReport__table__settingsButtons">
                                        <button
                                            className="editReport__table__cancelBtn"
                                            onClick={() => {
                                                setIsCreateOpen(false);
                                                setNewData({})
                                            }}>
                                            {translations?.['site_photographs.index.actions.cancel']}
                                        </button>
                                        <button
                                            className="editReport__table__saveBtn"
                                            type="submit"
                                            onClick={() => createNew()}
                                            disabled={isSaving}
                                        >
                                            {translations?.['site_photographs.index.actions.save']}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};
