import React from 'react';
import './index.scss'

const NoInternet = () => (
    <div className="noInternetContainer">
        <img src='/assets/icons/no-internet.svg' alt="no internet icon"/>
        <h1>No Internet Connection</h1>
        <p>Please check your internet connection and try again.</p>
    </div>
);

export default NoInternet;
