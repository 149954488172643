import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useErrorHandler } from "../../../global/ErrorsHandler";
import {
    useCreateProjectLarpMutation,
    useDeleteProjectLarpMutation,
    useGetProjectLarpQuery,
    useUpdateApplicableSettingsMutation,
    useUpdateProjectLarpMutation,
} from "../../../store/service/adbService";
import { setLoader } from "../../../store/slice/dashboard";
import { DeleteModal } from "../DeleteModal";
import { customData } from "./customData";
import AxelCheckbox from "../../../UI/AxelCheckbox";
import { Larp } from "../Larp";

export const Activities = ({
                               id,
                               show,
                               setShow,
                               refActivities,
                               glossaryData,
                               larpData,
                               mainData,
                               translations
                           }) => {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [currentItem, setCurrentItem] = useState(null);
    const [newItem, setNewItem] = useState(false);
    const [editable, setEditable] = useState(false);
    const [deleteModal, setDeleteModal] = useState(null);
    const [showLarp, setShowLarp] = useState(false);
    const [tableHeaders, setTableHeaders] = useState([]);
    const [moreInfo, setMoreInfo] = useState(null);
    const scrollRef = useRef(null);
    const user = useSelector((state) => state?.adbReducer?.user);
    const expandedRef = useRef(null);

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                let array = data?.map((item) => {
                    item.edit = false;
                    return item;
                });
                setCurrentItem(null);
                setData(array);
                setEditable(false);
                if (newItem) {
                    setNewItem(false);
                    let s = data?.filter((el, i) => i !== data.length - 1);
                    setData(s);
                }
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, [data]);

    const {
        data: projectLarpData,
        isError: projectLarpIsError,
        isLoading: projectLarpIsLoading,
        isSuccess: projectLarpIsSuccess,
        error: projectLarpError,
    } = useGetProjectLarpQuery(id);

    useEffect(() => {
        if (glossaryData?.data?.length) {
            setMoreInfo(
                glossaryData?.data?.filter(
                    (el) =>
                        el?.section_name === "Contract Start-up Activities and Submittals"
                )[0]
            );
            setTableHeaders(
                glossaryData?.data
                    ?.filter(
                        (el) =>
                            el?.section_name === "Contract Start-up Activities and Submittals"
                    )
                    ?.map((item) => ({
                        ...item,
                        tooltips: item?.tooltips?.map((tooltip) => ({
                            ...tooltip,
                            showTooltip: false,
                        })),
                    }))
            );
        }
        if (projectLarpData?.data) {
            let data = projectLarpData?.data?.map((item) => ({
                id: item?.id,
                section_definition: item?.section_definition,
                implementation_status: item?.implementation_status,
                compliance_report_due_date: item?.planned_date,
                compliance_report_complete: item?.completed,
                compliance_report_actual_date: item?.actual_date
                    ? item?.actual_date
                    : null,
                current_status_or_comments: item?.comment,
                edit: false,
                submitted: item?.submitted,
                old_report: item?.old_report,
                submitted_date: item?.submitted_date,
            }));
            setData(data);
        }
    }, [projectLarpData, glossaryData]);
    const [
        createProjectLarp,
        {
            data: createProjectLarpData,
            isError: createProjectLarpIsError,
            isLoading: createProjectLarpIsLoading,
            isSuccess: createProjectLarpIsSuccess,
            error: createProjectLarpError,
        },
    ] = useCreateProjectLarpMutation();

    const [
        updateProjectLarp,
        {
            data: updateProjectLarpData,
            isError: updateProjectLarpIsError,
            isLoading: updateProjectLarpIsLoading,
            isSuccess: updateProjectLarpIsSuccess,
            error: updateProjectLarpError,
        },
    ] = useUpdateProjectLarpMutation();

    const [
        deleteProjectLarp,
        {
            data: deleteProjectLarpData,
            isError: deleteProjectLarpIsError,
            isLoading: deleteProjectLarpIsLoading,
            isSuccess: deleteProjectLarpIsSuccess,
            error: deleteProjectLarpError,
        },
    ] = useDeleteProjectLarpMutation();

    const [
        updateApplicableSettings,
        {
            data: updateApplicableSettingsData,
            isError: updateApplicableSettingsIsError,
            isLoading: updateApplicableSettingsIsLoading,
            isSuccess: updateApplicableSettingsIsSuccess,
            error: updateApplicableSettingsError,
        },
    ] = useUpdateApplicableSettingsMutation();

    useEffect(() => {
        const d = () => {
            setEditable(false);
            setData((old) => {
                let data = old.map((item) => ({ ...item, edit: false }));
                return data;
            });
            dispatch(setLoader(false));
        };

        updateApplicableSettingsIsLoading ||
        projectLarpIsLoading ||
        createProjectLarpIsLoading ||
        updateProjectLarpIsLoading ||
        deleteProjectLarpIsLoading
            ? dispatch(setLoader(true))
            : d();
    }, [
        projectLarpIsLoading,
        createProjectLarpIsLoading,
        updateProjectLarpIsLoading,
        deleteProjectLarpIsLoading,
        updateApplicableSettingsIsLoading,
    ]);

    const setterData = (input) => {
        const body = {
            project_id: id,
            section_definition: input?.section_definition,
            implementation_status: input?.implementation_status,
            planned_date: moment(input?.compliance_report_due_date).format(
                "YYYY-MM-DD"
            ),
            comment: input?.current_status_or_comments,
            actual_date: moment(input?.compliance_report_actual_date).format(
                "YYYY-MM-DD"
            ),
        };

        let s = [];

        if (currentItem?.compliance_report_complete) {
            if (body?.actual_date === "Invalid date") {
                s.push("false");
            }
        } else {
            if (body?.actual_date === "Invalid date") {
                delete body.actual_date;
            }
        }

        for (let key in body) {
            if (key !== "comment") {
                if (!body[key] || body[key] === "Invalid date" || body[key] === "") {
                    toast.error("Please enter " + key?.split("_")?.join(" "), {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    });
                    s.push("false");
                }
            }
        }

        if (s.length === 0) {
            return body;
        } else {
            return null;
        }
    };

    const submitHandler = (e, item) => {
        e.preventDefault();
        if (newItem) {
            const dataCreated = setterData(currentItem);
            if (!dataCreated) {
                return;
            }
            createProjectLarp({
                ...dataCreated,
                completed: currentItem?.compliance_report_complete,
            });
            setCurrentItem(null);
            setNewItem(false);
        } else {
            const dataUpdated = setterData(currentItem);
            if (!dataUpdated) {
                return;
            }
            updateProjectLarp({
                id: item?.id,
                body: {
                    ...dataUpdated,
                    completed: currentItem?.compliance_report_complete,
                },
            });
            setCurrentItem(null);
            setNewItem(false);
        }
    };

    const updateApplicable = (value) => {
        updateApplicableSettings({
            id: larpData?.id,
            body: { larp_processing: value ? 1 : 0 },
        });
    };

    const [errorHandler, setErrorHandler] = useErrorHandler([
        projectLarpError,
        createProjectLarpError,
        updateProjectLarpError,
        deleteProjectLarpError,
        updateApplicableSettingsError,
    ]);

    useEffect(() => {
        if (updateApplicableSettingsIsError) {
            toast.error(updateApplicableSettingsError?.data?.errors?.errors?.error, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 8000,
            });
        }
    }, [updateApplicableSettingsIsError]);

    return (
        <>

            <div
                className="project__white-box"
                style={showLarp ? { border: "none" } : {}}
            >
                {deleteModal?.show && (
                    <DeleteModal
                        id={deleteModal?.id}
                        action={deleteProjectLarp}
                        setDeleteModal={setDeleteModal}
                        cencelText={translations?.['main.main.actions.cancel']}
                        deleteText={translations?.['main.main.actions.delete']}
                        modalTitle={translations?.['main.main.headers.confirm_your_action']}
                        clarifyingText={translations?.['main.main.titles.you_are_going_to_delete_this_row']}
                    />
                )}
                <div className={`project__block`}>
                    <div
                        className={`project__block-content activities ${
                            editable ? "editable" : ""
                        }`}
                        style={{ zIndex: 45 }}
                    >
                        {mainData?.project_settings?.larp ? (
                            <Larp glossaryData={glossaryData} larpData={mainData} translations={translations}/>
                        ) : null}
                    </div>
                </div>
            </div>
        </>
    );
};
