import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import {
    useCreateActualProjectPaymentsMutation,
    useDeleteActualProjectPaymentsMutation,
    useLazyGetProjectQuery,
    useUpdateActualProjectPaymentsMutation,
    useUpdateFinancialManagementOverviewMutation,
} from "../../../../store/service/adbService";
import { DeleteModal } from "../../DeleteModal";
import moment from "moment/moment";
import { setLoader } from "../../../../store/slice/dashboard";
import { useDispatch, useSelector } from "react-redux";
import MoreInfoModal from "../../../MoreInfoModal";
import CustomDatePicker from "../../../CustomDatePicker";

export const Overview = ({
                             data,
                             show,
                             setShow,
                             glossaryData,
                             refFinancialManagement,
                             translations
                         }) => {
    const dispatch = useDispatch();
    const [actualPayments, setActualPayments] = useState([]);
    const [text, setText] = useState(
        data?.financial_management_overview ?? "Please enter overview"
    );
    const [edit, setEdit] = useState(false);
    const [prevText, setPrevText] = useState(
        data?.financial_management_overview ?? ""
    );
    const [tableHeaders, setTableHeaders] = useState([]);
    const [infoModal, setInfoModal] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [newItem, setNewItem] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);
    const user = useSelector((state) => state?.adbReducer?.user);
    const [editable, setEditable] = useState(false);
    const [moreInfo, setMoreInfo] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);
    const scrollRef = useRef(null);
    const expandedRef = useRef(null);
    const [expanded, setExpanded] = useState(false);
    const overviewRef = useRef(null);
    const [isOverview, setIsOverview] = useState(false);
    const localeLan = localStorage.getItem("local_lan" );
    moment.locale(localeLan);

    const [
        trigger,
    ] = useLazyGetProjectQuery();

    const [
        updateFinancialManagementOverview,
        {
            isLoading: updateFinancialManagementOverviewIsLoading,
            isSuccess: updateFinancialManagementOverviewIsSuccess,
        },
    ] = useUpdateFinancialManagementOverviewMutation();

    const [
        createActualProjectPayments,
        {
            isError: createActualProjectPaymentsIsError,
            isLoading: createActualProjectPaymentsIsLoading,
            error: createActualProjectPaymentsError,
        },
    ] = useCreateActualProjectPaymentsMutation();

    const [
        updateActualProjectPayments,
        {
            isError: updateActualProjectPaymentsIsError,
            isLoading: updateActualProjectPaymentsIsLoading,
            error: updateActualProjectPaymentsError,
        },
    ] = useUpdateActualProjectPaymentsMutation();

    const [
        deleteActualProjectPayments,
        {
            isLoading: deleteActualProjectPaymentsIsLoading,
        },
    ] = useDeleteActualProjectPaymentsMutation();

    useEffect(() => {
        setEdit(false);
    }, [updateFinancialManagementOverviewIsSuccess]);

    useEffect(() => {
        if(translations){
            const total_headers = [
                {
                    tooltip_name: translations?.['payments.actual_project_payments.table_headers.payment_description'],
                    tooltip_text: translations?.['payments.actual_project_payments.tooltips.payment_description']
                },
                {
                    tooltip_name: translations?.['payments.actual_project_payments.table_headers.invoice_date'],
                    tooltip_text: translations?.['payments.actual_project_payments.tooltips.invoice_date']
                },
                {
                    tooltip_name: translations?.['payments.actual_project_payments.table_headers.invoice_amount'],
                    tooltip_text: translations?.['payments.actual_project_payments.tooltips.invoice_amount']
                },
                {
                    tooltip_name: translations?.['payments.actual_project_payments.table_headers.payment_date'],
                    tooltip_text: translations?.['payments.actual_project_payments.tooltips.payment_date']
                },
                {
                    tooltip_name: translations?.['payments.actual_project_payments.table_headers.payment_amount'],
                    tooltip_text: translations?.['payments.actual_project_payments.tooltips.payment_amount']
                },
            ]
            setTableHeaders(
                total_headers.map((item) => ({
                    ...item,
                    showTooltip: false,
                }))
            );
        }
    }, [translations])

    useEffect(() => {
        if (glossaryData?.data?.length) {
            setMoreInfo(
                glossaryData?.data?.filter(
                    (el) => el?.section_name === "Financial Management"
                )[0]
            );
        }
        if (data) {
            setActualPayments(
                data?.actual_project_payments?.payments?.map((el) => ({
                    ...el,
                    edit: false,
                }))
            );
            setText(data?.financial_management_overview ?? "Please enter overview");
            setPrevText(
                data?.financial_management_overview ?? "Please enter summary"
            );
        }
    }, [data, glossaryData]);

    useEffect(() => {
        const d = () => {
            dispatch(setLoader(false));
        };

        if (
            updateActualProjectPaymentsIsError ||
            createActualProjectPaymentsIsError
        ) {
            toast.error(
                updateActualProjectPaymentsError?.data?.errors?.errors?.date ||
                createActualProjectPaymentsError?.data?.errors?.errors?.date,
                {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                }
            );
        }

        deleteActualProjectPaymentsIsLoading ||
        updateActualProjectPaymentsIsLoading ||
        createActualProjectPaymentsIsLoading ||
        updateFinancialManagementOverviewIsLoading
            ? dispatch(setLoader(true))
            : d();
    }, [
        updateActualProjectPaymentsIsLoading,
        createActualProjectPaymentsIsLoading,
        updateFinancialManagementOverviewIsLoading,
        deleteActualProjectPaymentsIsLoading,
        updateActualProjectPaymentsIsError,
        createActualProjectPaymentsIsError,
    ]);


    const submitHandler = (e) => {
        e.preventDefault();
        if (text.length > 0) {
            updateFinancialManagementOverview({
                id: data?.id,
                body: { financial_management_overview: text },
            });
        } else {
            toast.error("Please enter summary", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }
    };

    const setterData = (input) => {
        const body = {
            project_id: data?.id,
            description: input?.description,
            invoice_date: moment(input?.invoice_date).format("YYYY-MM-DD"),
            invoice_amount: input?.invoice_amount?.toString()?.split(",").join(""),
        };

        let s = [];

        for (let key in body) {
            if (key !== "comment") {
                if (!body[key] || body[key] === "Invalid date" || body[key] === "") {
                    toast.error("Please enter " + key?.split("_")?.join(" "), {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    });
                    s.push("false");
                }
            }
        }
        const optionalData = {
            ...(currentItem?.payment_date && { payment_date: moment(currentItem?.payment_date).format("YYYY-MM-DD") }),
            ...(currentItem?.payment_amount && { payment_amount: currentItem?.payment_amount?.toString()?.split(",").join("")}),
        };

        if (s.length === 0) {
            return {
                ...body,
                ...optionalData
            };
        } else {
            return null;
        }
    };

    const submitDispute = (e, item) => {
        e.preventDefault();
        if (newItem) {
            const dataCreated = setterData(currentItem);
            if (dataCreated) {
                createActualProjectPayments(dataCreated).then((res) => {
                    if (res?.error) {
                    } else {
                        setCurrentItem(null);
                        setNewItem(false);
                        setCurrentRow(null);
                        dispatch(setLoader(true));
                        trigger(localStorage.getItem("itemId"));
                    }
                });
            }
        } else {
            const dataUpdated = setterData(currentItem);
            if (dataUpdated) {
                updateActualProjectPayments({
                    id: item?.id,
                    body: dataUpdated,
                }).then((res) => {
                    if (res?.error) {
                    } else {
                        setCurrentItem(null);
                        setNewItem(false);
                        setCurrentRow(null);
                        dispatch(setLoader(true));
                        trigger(localStorage.getItem("itemId"));
                    }
                });
            }
        }
    };

    return (
        <div className="project__disputes">
            {infoModal && (
                <MoreInfoModal
                    title={translations?.['payments.payments.headers.payments']}
                    moreInfo={translations?.['payments.payments.more_info.payments']}
                    close={()=> setInfoModal(false)}
                />
            )}

            {deleteModal?.show && (
                <DeleteModal
                    id={deleteModal?.id}
                    action={deleteActualProjectPayments}
                    setDeleteModal={setDeleteModal}
                    cencelText={translations?.['main.main.actions.cancel']}
                    deleteText={translations?.['main.main.actions.delete']}
                    modalTitle={translations?.['main.main.headers.confirm_your_action']}
                    clarifyingText={translations?.['main.main.titles.you_are_going_to_delete_this_row']}
                />
            )}
            <div
                ref={refFinancialManagement}
                className={`project__block ${show ? "" : "close"}`}
            >
                <div className="d-f">
                    <div className="project__block-title" onClick={() => setShow(!show)}>
                        <span>{translations?.['payments.payments.headers.payments']}</span>
                    </div>
                    <span
                        className="project__block-more"
                        onClick={() => setInfoModal(!infoModal)}
                    >
            <span className="img">
              <img src="/assets/icons/info.svg" alt="info" />
            </span>
            <span className="text">{translations?.['payments.payments.titles.more_info']}</span>
          </span>
                </div>
                <div
                    className={`project__block-content executive-summary ${
                        edit ? "edit" : ""
                    }`}
                >
                    <div className="gray-box">
                        <form onSubmit={(e) => submitHandler(e)} className="content">
                            <div className="text">
                                {edit ? (
                                    <textarea
                                        className={`${
                                            isOverview ? "project__block-expandedTextArea" : ""
                                        }`}
                                        ref={overviewRef}
                                        onFocus={() => setIsOverview(true)}
                                        rows={5}
                                        maxLength={2500}
                                        defaultValue={text === "Please enter overview" ? "" : text}
                                        placeholder={translations?.["executive_summary.executive_summary.placeholder.please_enter"]}
                                        onChange={(e) => {
                                            setText(e.target.value);
                                        }}
                                    />
                                ) : (
                                    <p>{text}</p>
                                )}
                            </div>
                            {user?.permissions?.edit_project && (
                                <div className="project__block-btns">
                                    {edit && (
                                        <div
                                            className="project__block-cancel"
                                            onClick={() => {
                                                setText(prevText);
                                                setEdit(false);
                                            }}
                                        >
                                            <img src="/assets/icons/close-mark.svg" alt="close" />
                                            {translations?.['payments.payments.actions.cancel']}
                                        </div>
                                    )}
                                    {edit ? (
                                        <button
                                            onClick={() => setIsOverview(false)}
                                            type="submit"
                                            className="project__block-save"
                                        >
                                            <img src="/assets/icons/save-arrow.svg" alt="arrow" />
                                            {translations?.['payments.payments.actions.save']}
                                        </button>
                                    ) : (
                                        <div
                                            onClick={() => {
                                                setEdit(true);
                                            }}
                                            className="project__block-update"
                                        >
                                            {translations?.['payments.payments.actions.edit']}
                                        </div>
                                    )}
                                </div>
                            )}
                        </form>
                    </div>
                </div>
                <div className="project__block-subtitles">
                    <div className="project__block-subtitle">{translations?.['payments.actual_project_payments.headers.actual_project_payments']}</div>
                    {user?.permissions?.edit_project && (
                        <div
                            onClick={() => {
                                let randomId = Math.floor(Math.random() * 1000);
                                scrollRef?.current?.scrollIntoView();
                                setCurrentRow(randomId);
                                if (!newItem) {
                                    setNewItem(true);
                                    setEditable(true);
                                    setActualPayments((old) => [
                                        ...old,
                                        {
                                            id: randomId,
                                            description: "",
                                            invoice_date: null,
                                            invoice_amount: "",
                                            payment_date: null,
                                            payment_amount: "",
                                            edit: true,
                                        },
                                    ]);
                                    setCurrentItem({
                                        id: randomId,
                                        description: "",
                                        invoice_date: null,
                                        invoice_amount: "",
                                        payment_date: null,
                                        payment_amount: "",
                                        edit: true,
                                    });
                                }
                            }}
                            className="project__block-add-row"
                        >
              <span>
                <img src="/assets/icons/blue-plus.svg" alt="plus" />
              </span>
                            <span>{translations?.['payments.actual_project_payments.actions.add_row']}</span>
                        </div>
                    )}
                </div>
                <div
                    className={`project__block-content permits
                            ${editable ? "editable" : ""}
                        `}
                >
                    <div
                        className={`project__block-table project-table ${
                            currentItem ? "n" : ""
                        }`}
                    >
                        <div className={`table__row grid-5`} ref={scrollRef} style={{gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr'}}>
                            {tableHeaders?.map((el, idx) => {
                                return (
                                    <div
                                        key={idx}
                                        onMouseEnter={() => {
                                            setTableHeaders(
                                                tableHeaders.map((item) => ({
                                                    ...item,
                                                    showTooltip: el.tooltip_name === item.tooltip_name
                                                }))
                                            );
                                        }}
                                        onMouseLeave={() => {
                                            setTableHeaders(
                                                tableHeaders.map((item) => ({
                                                    ...item,
                                                    showTooltip: false,
                                                }))
                                            );
                                        }}
                                        className="project-table__th header_description"
                                    >
                                        {el?.showTooltip ? (
                                            <span
                                                className={
                                                    el?.tooltip_text?.length > 20
                                                        ? "lengthy"
                                                        : "not-lengthy"
                                                }
                                            >
                        {el?.tooltip_text}
                      </span>
                                        ) : (
                                            ""
                                        )}
                                        {el?.tooltip_name}
                                    </div>
                                );
                            })}
                        </div>
                        <div className="project-table__body">
                            {actualPayments?.map((item, index) => (
                                <div key={index}>
                                    {index !== 0 && currentRow === item?.id && (
                                        <div
                                            className={`table__header`}
                                            key={index}
                                            style={{gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr'}}
                                        >
                                            {tableHeaders?.map((el, idx) => {
                                                return (
                                                    <div
                                                        key={idx}
                                                        className="project-table__th header_description"
                                                    >
                                                        {el?.showTooltip ? (
                                                            <span
                                                                className={
                                                                    el?.tooltip_text?.length > 20
                                                                        ? "lengthy"
                                                                        : "not-lengthy"
                                                                }
                                                            >
                                {el?.tooltip_text}
                              </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {el?.tooltip_name}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                    <form
                                        className={`table__row ${
                                            item?.edit ? "edit" : currentItem ? "n" : ""
                                        } ${
                                            Boolean(item.submitted) ||
                                            !user?.permissions?.edit_project
                                                ? "submitted"
                                                : ""
                                        }`}
                                        style={{gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr'}}
                                        id={item?.id}
                                        key={item?.id}
                                        onSubmit={(e) => submitDispute(e, item)}
                                    >
                                        <div className="project-table__td payment_description">
                                            <span>{item?.description}</span>
                                            <div className="project__block-input project-table__field">
                        <textarea
                            className={`${
                                expanded ? "project__block-expandedInput" : ""
                            }`}
                            ref={expandedRef}
                            onFocus={() => setExpanded(true)}
                            onBlur={() => setExpanded(false)}
                            maxLength={190}
                            value={
                                currentItem
                                    ? currentItem?.description
                                    : item?.description
                            }
                            onChange={(e) => {
                                setCurrentItem((old) => ({
                                    ...old,
                                    description: e.target.value,
                                }));
                            }}
                        />
                                            </div>
                                        </div>
                                        <div className="project-table__td">
                      <span>
                        {moment(item?.invoice_date).format("DD/MM/YYYY") ===
                        "Invalid date" ||
                        !moment(item?.invoice_date).format("DD/MM/YYYY")
                            ? ""
                            : moment(item?.invoice_date).format("DD/MM/YYYY")}
                      </span>
                                            <div className="project__block-item-calendar project-table__field">
                                                <CustomDatePicker
                                                    portalId="root-portal"
                                                    autoComplete="off"
                                                    id="invoice_date"
                                                    placeholderText={translations?.['main.main.placeholder.dd_mm_yyyy']}
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={
                                                        currentItem?.invoice_date
                                                            ? moment(currentItem?.invoice_date).toDate()
                                                            : ""
                                                    }
                                                    onChange={(date) => {
                                                        setCurrentItem((old) => ({
                                                            ...old,
                                                            invoice_date: date,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="project-table__td">
                      <span>
                        $
                          {String(item?.invoice_amount).replace(
                              /(\d)(?=(\d{3})+([^\d]|$))/g,
                              "$1,"
                          )}
                      </span>
                                            <div className="project__block-input project-table__field">
                                                <input
                                                    maxLength={190}
                                                    value={
                                                        currentItem
                                                            ? currentItem?.invoice_amount?.toLocaleString()
                                                            : item?.invoice_amount?.toLocaleString()
                                                    }
                                                    onChange={(e) => {
                                                        let newValue = e.target.value.replace(/,/g, "");
                                                        let abc = newValue.replace(
                                                            /\B(?=(\d{3})+(?!\d))/g,
                                                            ","
                                                        );
                                                        let formattedValue = abc.replace(/[^\d,]+/g, "");

                                                        setCurrentItem((old) => ({
                                                            ...old,
                                                            invoice_amount: formattedValue,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="project-table__td">
                      <span>
                        {moment(item?.payment_date).format("DD/MM/YYYY") ===
                        "Invalid date" ||
                        !moment(item?.payment_date).format("DD/MM/YYYY")
                            ? ""
                            : moment(item?.payment_date).format("DD/MM/YYYY")}
                      </span>
                                            <div className="project__block-item-calendar project-table__field">
                                                <CustomDatePicker
                                                    portalId="root-portal"
                                                    autoComplete="off"
                                                    id="payment_date"
                                                    placeholderText={translations?.['main.main.placeholder.dd_mm_yyyy']}
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={
                                                        currentItem?.payment_date
                                                            ? moment(currentItem?.payment_date).toDate()
                                                            : ""
                                                    }
                                                    onChange={(date) => {
                                                        setCurrentItem((old) => ({
                                                            ...old,
                                                            payment_date: date,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="project-table__td">
                                            {item?.payment_amount && <span>
                        $
                                                {String(item?.payment_amount).replace(
                                                    /(\d)(?=(\d{3})+([^\d]|$))/g,
                                                    "$1,"
                                                )}
                      </span>}
                                            <div className="project__block-input project-table__field">
                                                <input
                                                    maxLength={190}
                                                    value={
                                                        currentItem
                                                            ? currentItem?.payment_amount?.toLocaleString()
                                                            : item?.payment_amount?.toLocaleString()
                                                    }
                                                    onChange={(e) => {
                                                        let newValue = e.target.value.replace(/,/g, "");
                                                        let abc = newValue.replace(
                                                            /\B(?=(\d{3})+(?!\d))/g,
                                                            ","
                                                        );
                                                        let formattedValue = abc.replace(/[^\d,]+/g, "");

                                                        setCurrentItem((old) => ({
                                                            ...old,
                                                            payment_amount: formattedValue,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="project__block-btns">
                                            {currentItem ? (
                                                <div
                                                    className="project__block-cancel"
                                                    onClick={() => {
                                                        setCurrentRow(null);
                                                        let array = actualPayments?.map((item) => {
                                                            item.edit = false;
                                                            return item;
                                                        });
                                                        setCurrentItem(null);
                                                        setActualPayments(array);
                                                        if (newItem) {
                                                            setNewItem(false);
                                                            let s = actualPayments?.filter(
                                                                (el, i) => i !== actualPayments.length - 1
                                                            );
                                                            setActualPayments(s);
                                                        }
                                                    }}
                                                >
                                                    <img src="/assets/icons/close-mark.svg" alt="close" />
                                                    {translations?.['payments.actual_project_payments.actions.cancel']}
                                                </div>
                                            ) : (
                                                <div
                                                    className="project__block-delete"
                                                    onClick={() => {
                                                        setDeleteModal({ id: item?.id, show: true });
                                                    }}
                                                >
                                                    <img src="/assets/icons/trash-icon.svg" alt="arrow" />
                                                    {translations?.['payments.actual_project_payments.actions.delete']}
                                                </div>
                                            )}
                                            {currentItem ? (
                                                <button className="project__block-save">
                                                    <img src="/assets/icons/save-arrow.svg" alt="arrow" />
                                                    {translations?.['payments.actual_project_payments.actions.save']}
                                                </button>
                                            ) : (
                                                <div
                                                    onClick={() => {
                                                        setCurrentRow(item?.id);
                                                        setCurrentItem(item);
                                                        setEditable(true);
                                                        setActualPayments(() => {
                                                            let array = actualPayments?.map((elem) => {
                                                                if (elem?.id === item?.id) {
                                                                    elem.edit = true;
                                                                }
                                                                return elem;
                                                            });
                                                            return array;
                                                        });
                                                    }}
                                                    className="project__block-update"
                                                >
                                                    {translations?.['payments.actual_project_payments.actions.edit']}
                                                </div>
                                            )}
                                        </div>
                                    </form>
                                </div>
                            ))}
                            <div className="project__block-total">
                                <span>{translations?.['payments.actual_project_payments.titles.total']}:</span>
                                <span>
                  $
                                    {String(data?.actual_project_payments?.total).replace(
                                        /(\d)(?=(\d{3})+([^\d]|$))/g,
                                        "$1,"
                                    ) ?? "0"}
                </span>
                            </div>
                        </div>
                    </div>
                    {/* <div className={`project__block-load ${currentItem ? "n" : ""}`}>
            Load more
          </div> */}
                </div>
            </div>
        </div>
    );
};
