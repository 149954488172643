import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import {
    useLazyGetMajorProblemsWithIpQuery,
    useCreateMajorProblemsWithIpMutation,
    useUpdateMajorProblemsWithIpMutation,
    useDeleteMajorProblemsWithIpMutation
} from '../../../store/service/adbService';
import { errorHandle } from '../../../global/errorHandle';
import { setLoader } from '../../../store/slice/dashboard';
import ItemEdit from "./ItemEdit";
import {parseHtml} from "../../../global/parseHtml";

const MajorProblems = ({ reportId, translations, data, url, isReportSubmitted }) => {
    const dispatch = useDispatch();
    const headerRef = useRef();
    const [isOpenEdit, setIsOpenEdit] = useState({ show: false, id: null });
    const [isOpenToAdd, setIsOpenToAdd] = useState(false);
    const [majorProblemsData, setMajorProblemsData] = useState(data);
    const [cellWidths, setCellWidths] = useState([]);


    const [deleteMajorProblem, {
        data: deleteMajorProblemData,
        isLoading: deleteMajorProblemIsLoading,
        error: deleteMajorProblemError,
        isError: deleteMajorProblemIsError,
        isSuccess: deleteMajorProblemIsSuccess,
    }] = useDeleteMajorProblemsWithIpMutation();

    const [getMajorProblems, {
        data: newMajorProblemsData,
        isLoading: majorProblemsIsLoading,
        error: majorProblemsError,
        isError: majorProblemsIsError,
        isSuccess: majorProblemsIsSuccess,
    }] = useLazyGetMajorProblemsWithIpQuery();

    useEffect(() => {
        setMajorProblemsData(data)
    }, [data]);

    const updateMajorProblems = () => {
        getMajorProblems({reportId: reportId, url: url});
    };

    useEffect(() => {
        if (deleteMajorProblemIsSuccess) {
            toast.success(deleteMajorProblemData.message);
            updateMajorProblems();
        }
    }, [deleteMajorProblemIsSuccess, deleteMajorProblemData]);

    useEffect(() => {
        if (majorProblemsIsSuccess) {
            setMajorProblemsData(newMajorProblemsData?.data);
        }
    }, [majorProblemsIsSuccess, newMajorProblemsData]);

    useEffect(() => {
        if (deleteMajorProblemIsError) {
            toast.error(deleteMajorProblemError?.data.message);
            errorHandle(deleteMajorProblemError);
        }
        if (majorProblemsIsError) {
            toast.error(majorProblemsError?.data.message);
            errorHandle(majorProblemsError);
        }
    }, [
        deleteMajorProblemIsError, deleteMajorProblemError,
        majorProblemsError, majorProblemsIsError
    ]);

    useEffect(() => {
        majorProblemsIsLoading || deleteMajorProblemIsLoading
            ? dispatch(setLoader(true))
            : dispatch(setLoader(false));
    }, [deleteMajorProblemIsLoading, majorProblemsIsLoading]);

    useEffect(() => {
        if (headerRef.current) {
            const widths = Array.from(headerRef.current.querySelectorAll('th')).map(th => th?.offsetWidth - 10);
            setCellWidths(widths);
        }
    }, [headerRef]);

    return (
        <div className='majorProblems' ref={headerRef}>
            <div className="editReport__sectionHeader">
                <h3 style={{marginBottom:'12px'}}>
                    {url==='future-plans' ? translations?.['implementation_progress.future_plans_for_the_next_quarters.headers.future_plans_for_the_next_quarters']: translations?.['implementation_progress.major_problems_with_ip.headers.major_problems_with_ip']}
                </h3>
                {!isReportSubmitted &&
                    <button
                        className="editReport__sectionHeader__addRowBtn"
                        onClick={() => setIsOpenToAdd(true)}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25Z" stroke="#0052CC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M11.8687 9.22595L11.9111 14.6141" stroke="#0052CC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M9.1958 11.8988L14.584 11.9412" stroke="#0052CC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        {translations?.['implementation_progress.major_problems_with_ip.actions.add_row']}
                    </button>
                }
            </div>
            <div className="table-container">
                <table className="report__table">
                    <thead className="report__table__header">
                        <tr className="report__table__row ">
                            <th style={{ width: '50%' }}>{translations?.['implementation_progress.major_problems_with_ip.table_headers.description']}</th>
                            <th style={{ width: '50%' }}>{translations?.['implementation_progress.major_problems_with_ip.table_headers.action_taken_proposed']}</th>
                            <th style={{padding: 0}}></th>
                        </tr>
                    </thead>
                    <tbody className="report__table__body">
                    {
                        majorProblemsData?.map(item => {
                            return (
                                <React.Fragment key={item.id}>
                                    {!(isOpenEdit.show && isOpenEdit.id === item.id) ?
                                        <tr className="report__table__row">
                                            <td style={{ width: '50%' }}>{parseHtml(item.description)}</td>
                                            <td style={{ width: '50%' }}>{parseHtml(item.action_taken)}</td>
                                            <td style={{padding: 0}}>
                                                {!isReportSubmitted &&
                                                    <div className="editReport__table__settingsButtons">
                                                        <button
                                                            className="editReport__table__deleteBtn"
                                                            onClick={() => deleteMajorProblem({id: item.id, url: url})}>
                                                            {translations?.['financing_plan.index.actions.delete']}
                                                        </button>
                                                        <button
                                                            className="editReport__table__updateBtn"
                                                            onClick={() => setIsOpenEdit({show: true, id: item.id})}>
                                                            {translations?.['financing_plan.index.actions.update']}
                                                        </button>
                                                    </div>
                                                }
                                            </td>
                                        </tr> :
                                        <ItemEdit
                                            headerRef={headerRef}
                                            itemData={majorProblemsData?.find(item => item.id === isOpenEdit.id)}
                                            reportId={reportId}
                                            setIsOpenEdit={setIsOpenEdit}
                                            getItems={updateMajorProblems}
                                            cancelTranslation={translations?.['financing_plan.index.actions.cancel']}
                                            saveTranslation={translations?.['financing_plan.index.actions.save']}
                                            useCreateItemMutation={useCreateMajorProblemsWithIpMutation}
                                            useUpdateItemMutation={useUpdateMajorProblemsWithIpMutation}
                                            fields={['description', 'action_taken']}
                                            htmlEditFieldKeys={['action_taken', 'description']}
                                            cellWidths={cellWidths}
                                            requestUrl={url}
                                        />
                                    }
                                </React.Fragment>
                            );
                        })
                    }
                    </tbody>
                </table>
                {isOpenToAdd && (
                    <ItemEdit
                        headerRef={headerRef}
                        itemData={{}}
                        reportId={reportId}
                        setIsOpenEdit={setIsOpenToAdd}
                        getItems={updateMajorProblems}
                        cancelTranslation={translations?.['financing_plan.index.actions.cancel']}
                        saveTranslation={translations?.['financing_plan.index.actions.save']}
                        useCreateItemMutation={useCreateMajorProblemsWithIpMutation}
                        useUpdateItemMutation={useUpdateMajorProblemsWithIpMutation}
                        fields={['description', 'action_taken']}
                        cellWidths={cellWidths}
                        htmlEditFieldKeys={['action_taken', 'description']}
                        requestUrl={url}
                    />
                )}
            </div>
        </div>
    );
};

export default MajorProblems;

