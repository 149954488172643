import React, { useRef } from "react";
import useTemplateModalHook from "./hooks";
import { toast } from "react-toastify";

export default function TemplateModal({
  closeModal,
  get,
  translations,
  currentTemplate,
  setCurrentTemplate,
}) {
  const {
    state,
    handleChange,
    handleUpload,
    create,
    clearUploader,
    update,
    handleDragDrop,
  } = useTemplateModalHook(currentTemplate);

  const fileInputRef = useRef();

  const handleDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "copy";
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    const filteredFiles = Array.from(files).filter(
      (item) =>
        item.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        item?.type === "application/msword"
    );
    const otherTypes = Array.from(files).filter(
      (item) =>
        item.type !==
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        item?.type === "application/msword"
    );

    if (otherTypes.length > 0) {
      toast.error("Please enter only .docx, .doc type file");
    } else {
      handleDragDrop(filteredFiles[0]);
    }
    // dispatch(setFilesInfo(filesInfo));
  };

  // const handleDrop = (event) => {
  //   event.preventDefault();
  //   const files = event.dataTransfer.files;
  //   const filteredFiles = Array.from(files).filter(
  //     (item) =>
  //       item.type ===
  //         "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
  //       item?.type === "application/msword"
  //   );
  //   const otherTypes = Array.from(files).filter(
  //     (item) =>
  //       item.type ===
  //         "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
  //       item?.type === "application/msword"
  //   );
  //   handleUpload(filteredFiles[0]);

  //   if (otherTypes.length > 0) {
  //     toast.error("Please enter only excel type file");
  //   }
  // };

  return (
    <div className="template-modal">
      <div className="template-modal__content">
        <div className="template-modal__header">
          <div className="template-modal__title">
            {currentTemplate ? translations?.['cmp_templates.edit_cmp_template.headers.edit_cmp_template'] : translations?.['cmp_templates.index.actions.create_new_template']}
          </div>
          <div
            onClick={() => {
              closeModal();
              setCurrentTemplate(null);
            }}
            className="template-modal__close"
          >
            <img src="/assets/icons/close.svg" alt="svg" />
          </div>
        </div>
        <div className="template-modal__hr"></div>
        <div className="template-modal__field">
          <div className="template-modal__label">{translations?.['cmp_templates.index.titles.title']}</div>
          <input
            id="title"
            type="text"
            className="template-modal__input"
            placeholder={translations?.['cmp_templates.index.placeholder.enter_title']}
            value={state?.title}
            onChange={handleChange}
          />
        </div>
        <div className="template-modal__field">
          <div className="template-modal__label">{translations?.['cmp_templates.index.titles.description']}</div>
          <textarea
            id="description"
            type="text"
            className="template-modal__textarea"
            placeholder={translations?.['cmp_templates.index.placeholder.enter_description']}
            value={state?.description}
            onChange={handleChange}
          />
        </div>
        <div className="template-modal__uploader">
          <input
            type="file"
            id="uploader"
            onChange={handleUpload}
            accept=".docx, .doc"
            ref={fileInputRef}
          />
          <label
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            htmlFor="uploader"
          >
            <img src="/assets/icons/upload-icon.svg" alt="upload" />
            <span>{translations?.['cmp_templates.index.actions.select_a_file__to_upload']}</span>
            <span>{translations?.['cmp_templates.index.actions.or_drag_and_drop']}</span>
          </label>
        </div>
        <div className="template-modal__p">{translations?.['cmp_templates.index.titles.supported_files']} .docx</div>
        {state?.template && (
          <div className="template-modal__file">
            <div>
              <span>
                <img src="/assets/icons/word-icon.svg" alt="word" />
              </span>
              <div>
                <span>{state?.template?.name}</span>
                <span>
                  {" "}
                  {(state?.template?.size / 1024 > 1024
                    ? state?.template?.size / 1024 / 1024
                    : state?.template?.size / 1024
                  ).toFixed(2)}
                  {state?.template?.size / 1024 > 1024 ? "mb" : "kb"}
                </span>
              </div>
            </div>
            <span onClick={clearUploader}>
              <img src="/assets/icons/close.svg" alt="close" />
            </span>
          </div>
        )}
        <div className="template-modal__btns">
          <div className="template-modal__cancel" onClick={closeModal}>
            {translations?.['cmp_templates.index.actions.cancel']}
          </div>
          <div
            className="template-modal__save"
            onClick={() => {
              currentTemplate
                ? update(closeModal, get, setCurrentTemplate)
                : create(closeModal, get);
            }}
          >
            {translations?.['cmp_templates.index.actions.save']}
          </div>
        </div>
      </div>
      <div
        className="template-modal__bg"
        onClick={() => {
          closeModal();
          setCurrentTemplate(null);
        }}
      ></div>
    </div>
  );
}
