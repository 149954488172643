import moment from "moment";
import isEmpty from "lodash/isEmpty";
import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useErrorHandler } from "../../../../global/ErrorsHandler";
import {
  useCreateClaimRegisterMutation,
  useCreateExtensionOfTimeMutation,
  useDeleteClaimRegisterMutation,
  useLazyGetAllCompaniesQuery,
  useLazyGetProjectQuery,
  useUpdateClaimRegisterMutation,
  useUpdateExtensionOfTimeMutation,
} from "../../../../store/service/adbService";
import { setLoader } from "../../../../store/slice/dashboard";
import { Switch } from "../../../../UI/Switch";
import CustomDatePicker from "../../../CustomDatePicker";

export const EOTcomponent = ({
                               claimData,
                               glossaryData,
                               refGoodsEquipment,
                               translations
                             }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [dataEot, setDataEot] = useState([]);
  const [show, setShow] = useState(true);
  const [showByActive, setShowByActive] = useState(true);
  const [hasArchived, setHasArchived] = useState(false);
  const [showEot, setShowEot] = useState(true);
  const [currentItem, setCurrentItem] = useState(null);
  const [currentItemEot, setCurrentItemEot] = useState(null);
  const [newItem, setNewItem] = useState(false);
  const [newItemEot, setNewItemEot] = useState(false);
  const [options, setOptions] = useState([]);
  const [editable, setEditable] = useState(false);
  const [editableEot, setEditableEot] = useState(false);
  const [deleteModal, setDeleteModal] = useState(null);
  const [moreInfo, setMoreInfo] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const [currentRowEot, setCurrentRowEot] = useState(null);
  const user = useSelector((state) => state?.adbReducer?.user);
  const expandedRef = useRef(null);
  const expandedRefEot = useRef(null);
  const [expanded, setExpanded] = useState(false);
  const [expandedEot, setExpandedEot] = useState(false);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [tableHeadersEot, setTableHeadersEot] = useState([]);
  const [infoModal, setInfoModal] = useState(false);
  const scrollRef = useRef(null);
  const scrollRefEot = useRef(null);
  const descrRef = useRef(null);
  const descrRefEot = useRef(null);
  const [decrExpanded, setDecrExpanded] = useState(false);
  const [decrExpandedEot, setDecrExpandedEot] = useState(false);
  const localeLan = localStorage.getItem("local_lan" );
  moment.locale(localeLan);

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        let array = data?.map((item) => {
          item.edit = false;
          return item;
        });
        let arrayEot = dataEot?.map((item) => {
          item.edit = false;
          return item;
        });
        setEditableEot(false);
        setCurrentItemEot(null);
        setDataEot(arrayEot);
        if (newItem) {
          setNewItem(false);
          let s = data?.filter((el, i) => i !== data.length - 1);
          setData(s);
        }
        if (newItemEot) {
          setNewItemEot(false);
          let s = dataEot?.filter((el, i) => i !== data.length - 1);
          setDataEot(s);
        }
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, [data, dataEot]);

  const [
    createClaimRegister,
    {
      data: createClaimRegisterData,
      isError: createClaimRegisterIsError,
      isLoading: createClaimRegisterIsLoading,
      isSuccess: createClaimRegisterIsSuccess,
      error: createClaimRegisterError,
    },
  ] = useCreateClaimRegisterMutation();

  const [
    createEOT,
    {
      data: createEOTData,
      isError: createEOTIsError,
      isLoading: createEOTIsLoading,
      isSuccess: createEOTIsSuccess,
      error: createEOTError,
    },
  ] = useCreateExtensionOfTimeMutation();
  const [
    updateClaimRegister,
    {
      data: updateClaimRegisterData,
      isError: updateClaimRegisterIsError,
      isLoading: updateClaimRegisterIsLoading,
      isSuccess: updateClaimRegisterIsSuccess,
      error: updateClaimRegisterError,
    },
  ] = useUpdateClaimRegisterMutation();

  const [
    updateEOT,
    {
      data: updateEOTData,
      isError: updateEOTIsError,
      isLoading: updateEOTIsLoading,
      isSuccess: updateEOTIsSuccess,
      error: updateEOTError,
    },
  ] = useUpdateExtensionOfTimeMutation();

  const [
    deleteClaimRegister,
    {
      data: deleteClaimRegisterData,
      isError: deleteClaimRegisterIsError,
      isLoading: deleteClaimRegisterIsLoading,
      isSuccess: deleteClaimRegisterIsSuccess,
      error: deleteClaimRegisterError,
    },
  ] = useDeleteClaimRegisterMutation();

  useEffect(() => {
    if (claimData?.claim_register) {
      let data = claimData?.claim_register?.map((item) => ({
        claim_reference: item?.claim_reference,
        claim_description: item?.claim_description,
        notice_date: item?.notice_date,
        detailed_date: item?.detailed_date,
        submitted_amount: item?.submitted_amount,
        certified: item?.certified,
        certified_amount: item?.certified_amount,
        current_status: item?.current_status,
        project_id: claimData?.id,
        id: item?.id,
        edit: false,
        submitted: item?.submitted,
        old_report: item?.old_report,
        submitted_date: item?.submitted_date,
        date_claim_certified: item?.date_claim_certified
      }));
      setEditable(false);
      setData(data);
    }
    if (claimData?.extension_of_time?.data) {
      let data = claimData?.extension_of_time?.data?.map((item) => ({
        id: item?.id,
        project_id: claimData?.id,
        ref: item?.ref,
        description: item?.description,
        submission_of_notice: item?.submission_of_notice,
        number_of_days_claimed: item?.number_of_days_claimed,
        approval_date: item?.approval_date,
        number_of_approved_days: item?.number_of_approved_days,
        approved: item?.approved,
        comments: item?.comments,
        archived: item?.archived,
        submitted: item?.submitted,
        edit: false,
      }));
      let isHaveArchived = isEmpty(data.find(item => item.archived===1));
      setHasArchived(!isHaveArchived);
      if (showByActive===true && !isHaveArchived){
        const filteredData =  data.filter(item => item.archived ===0);
        setDataEot(filteredData);
      } else setDataEot(data);
      setEditableEot(false);
    }
  }, [claimData?.claim_register, claimData?.extension_of_time?.data, showByActive]);

  const [
    getCompanies,
    {
      data: companies,
      error: companiesError,
      isLoading: companiesIsLoading,
      isError: companiesIsError,
      isSuccess: companiesIsSuccess,
    },
  ] = useLazyGetAllCompaniesQuery();

  useEffect(() => {
    getCompanies({
      per_page: 1000,
    });
  }, []);
  const [
    trigger,
    { triggerData, isError, error: projectError, isSuccess, isLoading },
  ] = useLazyGetProjectQuery();

  useEffect(() => {
    if (claimData?.responsible_party) {
      let data = claimData?.responsible_party?.map((item) => {
        return {
          value: item?.id,
          label: item?.short_name,
        };
      });
      setOptions(data);
    }
  }, [claimData]);

  const [errorHandler, setErrorHandler] = useErrorHandler([
    createClaimRegisterError,
    updateClaimRegisterError,
    deleteClaimRegisterError,
    createEOTError,
    updateEOTError,
  ]);

  useEffect(() => {
    if(translations){
      const headers = [
        {
          tooltip_name: translations?.['project_monitoring.extension_of_time.table_headers.ref'],
          tooltip_text: translations?.['project_monitoring.extension_of_time.tooltips.ref']
        },
        {
          tooltip_name: translations?.['project_monitoring.extension_of_time.table_headers.eot_description'],
          tooltip_text: translations?.['project_monitoring.extension_of_time.tooltips.eot_description']
        },
        {
          tooltip_name: translations?.['project_monitoring.extension_of_time.table_headers.submission_of_notice_of_eot'],
          tooltip_text: translations?.['project_monitoring.extension_of_time.tooltips.submission_of_notice_of_eot']
        },
        {
          tooltip_name: translations?.['project_monitoring.extension_of_time.table_headers.number_of_days_claimed'],
          tooltip_text: translations?.['project_monitoring.extension_of_time.tooltips.number_of_days_claimed']
        },
        {
          tooltip_name: translations?.['project_monitoring.extension_of_time.table_headers.eot_approval_date'],
          tooltip_text: translations?.['project_monitoring.extension_of_time.tooltips.eot_approval_date']
        },
        {
          tooltip_name: translations?.['project_monitoring.extension_of_time.table_headers.number_of_approved_days'],
          tooltip_text: translations?.['project_monitoring.extension_of_time.tooltips.number_of_approved_days']
        },
        {
          tooltip_name: translations?.['project_monitoring.extension_of_time.table_headers.approved'],
          tooltip_text: translations?.['project_monitoring.extension_of_time.tooltips.approved']
        },
        {
          tooltip_name: translations?.['project_monitoring.extension_of_time.table_headers.current_status_or_comments'],
          tooltip_text: translations?.['project_monitoring.extension_of_time.tooltips.current_status_or_comments']
        },
      ]

      setTableHeaders(
          headers.map((item) => ({
            ...item,
            showTooltip: false,
          }))
      );
      setTableHeadersEot(
          headers.map((item) => ({
            ...item,
            showTooltip: false,
          }))
      );
    }
  }, [translations])

  useEffect(() => {
    if (glossaryData?.data?.length) {
      setMoreInfo(
          glossaryData?.data?.filter(
              (el) => el?.section_name === "Claim Register"
          )[0]
      );
    }
    setData((old) => {
      let data = old.map((item) => ({ ...item, edit: false }));
      return data;
    });
    setDataEot((old) => {
      let data = old.map((item) => ({ ...item, edit: false }));
      return data;
    });
    setEditable(false);
    setEditableEot(false);
  }, [
    createClaimRegisterIsSuccess,
    createEOTIsSuccess,
    updateEOTIsSuccess,
    updateClaimRegisterIsSuccess,
    deleteClaimRegisterIsSuccess,
    glossaryData,
  ]);

  useEffect(() => {
    const d = () => {
      dispatch(setLoader(false));
    };
    createClaimRegisterIsLoading ||
    updateClaimRegisterIsLoading ||
    updateEOTIsLoading ||
    createEOTIsLoading ||
    deleteClaimRegisterIsLoading
        ? dispatch(setLoader(true))
        : d();
  }, [
    createClaimRegisterIsLoading,
    createEOTIsLoading,
    updateEOTIsLoading,
    updateClaimRegisterIsLoading,
    deleteClaimRegisterIsLoading,
  ]);

  const setterData = (input) => {
    const body = {
      claim_reference: input?.claim_reference,
      claim_description: input?.claim_description,
      notice_date: moment(input?.notice_date).format("YYYY-MM-DD"),
      submitted_amount: input?.submitted_amount
          ?.toString()
          ?.split(",")
          .join(""),
      current_status: input?.current_status,
      project_id: claimData?.id,
    };

    let s = [];

    if (currentItem?.certified_amount !== null) {
      body.certified_amount = currentItem?.certified_amount
          ?.toString()
          ?.split(",")
          .join("");
    }

    if (currentItem?.certified) {
      body.date_claim_certified = moment(input?.date_claim_certified).format("YYYY-MM-DD")
    }

    for (let key in body) {
      if (!body[key] || body[key] === "Invalid date" || body[key] === "") {
        toast.error("Please enter " + key?.split("_")?.join(" "), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
        s.push("false");
      }
    }

    if (s.length === 0) {

      if (input?.detailed_date) {
        body.detailed_date = moment(input?.detailed_date).format("YYYY-MM-DD")
      }
      return body;
    } else {
      return null;
    }
  };

  const setterDataEot = (input) => {
    const body = {
      ref: input?.ref,
      description: input?.description,
      submission_of_notice: moment(input?.submission_of_notice)?.format('YYYY-MM-DD'),
      number_of_days_claimed: input?.number_of_days_claimed,
      project_id: claimData?.id,
    }

    let s = [];

    if (currentItemEot?.approved) {
      body.approval_date = moment(input?.approval_date).format("YYYY-MM-DD")
      body.number_of_approved_days = input?.number_of_approved_days
    }

    for (let key in body) {
      if (!body[key] || body[key] === "Invalid date" || body[key] === "") {
        toast.error("Please enter " + key?.split("_")?.join(" "), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
        s.push("false");
      }
    }

    if (s.length === 0) {
      body.comments = !input?.comments || typeof input?.comments === 'number' ? '' : input?.comments;
      return body;
    } else {
      return null;
    }
  };

  const submitHandler = (e, item) => {
    e.preventDefault();
    if (newItem) {
      const dataCreated = setterData(currentItem);
      if (dataCreated) {
        createClaimRegister({
          ...dataCreated,
          certified: currentItem?.certified,
        }).then((res) => {
          if (res?.error) {
          } else {
            setCurrentItem(null);
            setNewItem(false);
            setCurrentRow(null);
            dispatch(setLoader(true));
            trigger(localStorage.getItem("itemId"));
          }
        });
      }
    } else {
      const dataUpdated = setterData(currentItem);
      if (dataUpdated) {
        updateClaimRegister({
          id: item?.id,
          body: {
            ...dataUpdated,
            certified: currentItem?.certified,
          },
        }).then((res) => {
          if (res?.error) {
          } else {
            setCurrentItem(null);
            setNewItem(false);
            setCurrentRow(null);
            dispatch(setLoader(true));
            trigger(localStorage.getItem("itemId"));
          }
        });
      }
    }
  };

  const submitHandlerEot = (e, item) => {
    e.preventDefault();
    if (newItemEot) {
      const dataCreatedEot = setterDataEot(currentItemEot);
      if (dataCreatedEot) {
        createEOT({
          ...dataCreatedEot,
          approved: currentItemEot?.approved ? 1 : 0
        }).then((res) => {
          if (res?.error) {
          } else {
            setCurrentItemEot(null);
            setNewItemEot(false);
            setCurrentRowEot(null);
            dispatch(setLoader(false));
            trigger(localStorage.getItem("itemId"));
          }
        });
      }
    } else {
      const dataUpdatedEot = setterDataEot(currentItemEot);
      if (dataUpdatedEot) {
        updateEOT({
          id: item?.id,
          body: {
            ...dataUpdatedEot,
            approved: currentItemEot?.approved ? 1 : 0,
          },
        }).then((res) => {
          if (res?.error) {
          } else {
            setCurrentItemEot(null);
            setNewItemEot(false);
            setCurrentRowEot(null);
            dispatch(setLoader(false));
            trigger(localStorage.getItem("itemId"));
          }
        });
      }
    }
  };

  return (
      <div className="project__goods">
        <div className={`project__block ${showEot ? "" : "close"} mb_32`}>
          <div className="project__block-subtitles">
            <div className="project__block-subtitle">{translations?.['project_monitoring.extension_of_time.headers.extension_of_time']}</div>
            <div className="project__block__buttonsContainer">
              {hasArchived && <button
                  onClick={() => setShowByActive(!showByActive)}
                  className="project__block-add-row">
                <span> <img src="/assets/icons/blue-eye.svg" alt="plus"/></span>
                <span>{showByActive ? translations?.['project_monitoring.extension_of_time.actions.show_all']
                    : translations?.['project_monitoring.extension_of_time.actions.show_active']}</span>
              </button>}
              {user?.permissions?.edit_project && (
                  <button
                      onClick={() => {
                        let randomId = Math.floor(Math.random() * 1000);
                        setCurrentRowEot(randomId);
                        scrollRefEot !== null && scrollRefEot.current.scrollIntoView();
                        if (!newItemEot) {
                          setNewItemEot(true);
                          setEditableEot(true);
                          setDataEot((old) => [
                            ...old,
                            {
                              id: randomId,
                              ref: "",
                              description: "",
                              submission_of_notice: null,
                              number_of_days_claimed: null,
                              approval_date: null,
                              number_of_approved_days: null,
                              approved: 0,
                              comments: "",
                              edit: true,
                            },
                          ]);
                          setCurrentItemEot({
                            id: randomId,
                            ref: "",
                            description: "",
                            submission_of_notice: null,
                            number_of_days_claimed: null,
                            approval_date: null,
                            number_of_approved_days: null,
                            approved: 0,
                            comments: "",
                            edit: true,
                          });
                        }
                      }}
                      className="project__block-add-row"
                  >
                <span>
                  <img src="/assets/icons/blue-plus.svg" alt="plus" />
                </span>
                    <span>{translations?.['project_monitoring.extension_of_time.actions.add_row']}</span>
                  </button>
              )}
            </div>
          </div>
          <div
              className={`project__block-content permits ${
                  editableEot ? "editable" : ""
              }`}
          >
            <div
                className={`project__block-table project-table ${
                    currentItemEot ? "n" : ""
                }`}
            >
              <div className={`project-table__header  grid-8`} ref={scrollRefEot}>
                {tableHeadersEot?.map((el, idx) => {
                  return (
                      <div
                          key={idx}
                          onMouseEnter={(event) => {
                            setTableHeadersEot(
                                tableHeadersEot.map((item) => ({
                                  ...item,
                                  showTooltip: el.tooltip_name === item.tooltip_name,
                                }))
                            );
                          }}
                          onMouseLeave={() => {
                            setTableHeadersEot(
                                tableHeadersEot.map((item) => ({
                                  ...item,
                                  showTooltip: false,
                                }))
                            );
                          }}
                          className="project-table__th"
                      >
                        {el?.showTooltip ? (
                            <span
                                className={
                                  el?.tooltip_text?.length > 20
                                      ? "lengthy"
                                      : "not-lengthy"
                                }
                            >
                        {el?.tooltip_text}
                      </span>
                        ) : (
                            ""
                        )}
                        {el?.tooltip_name}
                      </div>
                  );
                })}
              </div>
              <div className="project-table__body">
                {dataEot?.map((item, index) => (
                    <div key={index}>
                      {index !== 0 && currentRowEot === item?.id && (
                          <div className={`project-table__header grid-8`} key={index}>
                            {tableHeadersEot?.map((el, idx) => {
                              return (
                                  <div key={idx} className="project-table__th">
                                    {el?.showTooltip ? (
                                        <span
                                            className={
                                              el?.tooltip_text?.length > 20
                                                  ? "lengthy"
                                                  : "not-lengthy"
                                            }
                                        >
                                {el?.tooltip_text}
                              </span>
                                    ) : (
                                        ""
                                    )}
                                    {el?.tooltip_name}
                                  </div>
                              );
                            })}
                          </div>
                      )}
                      <form
                          className={`project-table__row grid-8 ${
                              item?.edit ? "edit" : currentItemEot ? "n" : ""
                          } ${
                              Boolean(item.submitted) ||
                              !user?.permissions?.edit_project
                                  ? "submitted"
                                  : ""
                          }`}
                          id={item?.id}
                          key={item?.id}
                          onSubmit={(e) => submitHandlerEot(e, item)}
                      >
                        <div className="project-table__td">
                          <span>{item?.ref}</span>
                          <div className="project__block-input project-table__field">
                            <input
                                maxLength={190}
                                value={
                                  currentItemEot
                                      ? currentItemEot?.ref
                                      : item?.ref
                                }
                                onChange={(e) => {
                                  setCurrentItemEot((old) => ({
                                    ...old,
                                    ref: e?.target?.value,
                                  }));
                                }}
                            />
                          </div>
                        </div>
                        <div className="project-table__td">
                          <span>{item?.description}</span>
                          <div className="project__block-input project-table__field">
                        <textarea
                            className={`${
                                expandedEot ? "project__block-expandedInput" : ""
                            }`}
                            ref={expandedRefEot}
                            onFocus={() => setExpandedEot(true)}
                            onBlur={() => setExpandedEot(false)}
                            maxLength={190}
                            defaultValue={item?.description}
                            onChange={(e) => {
                              setCurrentItemEot((old) => ({
                                ...old,
                                description: e.target.value,
                              }));
                            }}
                        />
                          </div>
                        </div>
                        <div className="project-table__td">
                      <span>
                        {item?.submission_of_notice ? moment(item?.submission_of_notice).format("DD/MM/YYYY") ===
                        "Invalid date" ||
                        !moment(item?.submission_of_notice).format("DD/MM/YYYY")
                            ? ""
                            : moment(item?.submission_of_notice).format("DD/MM/YYYY") : ''}
                      </span>
                          <div className="project__block-item-calendar project-table__field">
                            <CustomDatePicker
                                portalId="root-portal"
                                autoComplete="off"
                                id="submission_of_notice"
                                placeholderText={translations?.['main.main.placeholder.dd_mm_yyyy']}
                                dateFormat="dd/MM/yyyy"
                                selected={
                                  currentItemEot?.submission_of_notice
                                      ? moment(currentItemEot?.submission_of_notice).toDate()
                                      : ""
                                }
                                onChange={(date) => {
                                  setCurrentItemEot((old) => ({
                                    ...old,
                                    submission_of_notice: date,
                                  }));
                                }}
                            />
                          </div>
                        </div>
                        <div className="project-table__td" style={{marginLeft: item?.number_of_days_claimed < 0 ? '-5.5px' : '0px'}}>
                      <span>
                        {item?.number_of_days_claimed}
                      </span>
                          <div className="project__block-item-calendar project-table__field">
                            <input
                                maxLength={190}
                                value={
                                  currentItemEot
                                      ? currentItemEot?.number_of_days_claimed
                                      : item?.number_of_days_claimed
                                }
                                onChange={(e) => {
                                  setCurrentItemEot((old) => ({
                                    ...old,
                                    number_of_days_claimed: e?.target?.value,
                                  }));
                                }}
                            />
                          </div>
                        </div>
                        <div className="project-table__td">
                      <span>
                        {item?.approved && item?.approval_date ? moment(item?.approval_date).format("DD/MM/YYYY") ===
                        "Invalid date" ||
                        !moment(item?.approval_date).format("DD/MM/YYYY")
                            ? ""
                            : moment(item?.approval_date).format("DD/MM/YYYY") : ''}
                      </span>
                          <div className="project__block-item-calendar project-table__field">
                            {currentItemEot?.id === item?.id ? <CustomDatePicker
                                portalId="root-portal"
                                autoComplete="off"
                                id="approval_date"
                                placeholderText={translations?.['main.main.placeholder.dd_mm_yyyy']}
                                dateFormat="dd/MM/yyyy"
                                disabled={!currentItemEot?.approved}
                                selected={
                                  currentItemEot?.approval_date
                                      ? moment(currentItemEot?.approval_date).toDate()
                                      : ""
                                }
                                onChange={(date) => {
                                  setCurrentItemEot((old) => ({
                                    ...old,
                                    approval_date: date,
                                  }));
                                }}
                            /> : null}
                          </div>
                        </div>
                        <div className="project-table__td" style={{marginLeft: item?.number_of_approved_days < 0 ? '-5.5px' : '0px'}}>
                      <span>
                        {item?.approved ? item?.number_of_approved_days : ''}
                      </span>
                          <div className="project__block-item-calendar project-table__field">
                            {currentItemEot?.id === item?.id ? (
                                <input
                                    maxLength={190}
                                    disabled={!currentItemEot?.approved}
                                    value={
                                      currentItemEot
                                          ? currentItemEot?.number_of_approved_days
                                          : item?.number_of_approved_days
                                    }
                                    onChange={(e) => {
                                      setCurrentItemEot((old) => ({
                                        ...old,
                                        number_of_approved_days: e?.target?.value,
                                      }));
                                    }}
                                />
                            ) : null}
                          </div>
                        </div>
                        <div className="project-table__td">
                          <Switch
                              value={item?.approved}
                              id={index + 939}
                              disabled={item?.edit ? false : true}
                              checked={
                                item?.edit ? currentItemEot?.approved : item?.approved
                              }
                              handler={(value) => {
                                setCurrentItemEot((old) => ({
                                  ...old,
                                  approved: value,
                                }));
                              }}
                          />
                        </div>
                        <div className="project-table__td">
                          <span>{item?.comments}</span>
                          <div className="project__block-input project-table__field">
                        <textarea
                            className={`${
                                decrExpanded ? "project__block-expandedInput" : ""
                            }`}
                            ref={descrRefEot}
                            onFocus={() => setDecrExpanded(true)}
                            onBlur={() => setDecrExpanded(false)}
                            maxLength={190}
                            defaultValue={item?.comments}
                            onChange={(e) => {
                              setCurrentItemEot((old) => ({
                                ...old,
                                comments: e.target.value,
                              }));
                            }}
                        />
                          </div>
                        </div>
                        <div className="project__block-btns">
                          {currentItemEot ? (
                              <div
                                  className="project__block-cancel"
                                  onClick={() => {
                                    setCurrentRowEot(null);
                                    let array = dataEot?.map((item) => {
                                      item.edit = false;
                                      return item;
                                    });
                                    setCurrentItemEot(null);
                                    setDataEot(array);
                                    if (newItemEot) {
                                      setNewItemEot(false);
                                      let s = dataEot?.filter(
                                          (el, i) => i !== dataEot.length - 1
                                      );
                                      setDataEot(s);
                                    }
                                  }}
                              >
                                <img src="/assets/icons/close-mark.svg" alt="close" />
                                {translations?.['project_monitoring.extension_of_time.actions.cancel']}
                              </div>
                          ) : <></>}
                          {currentItemEot ? (
                              <button className="project__block-save">
                                <img src="/assets/icons/save-arrow.svg" alt="arrow" />
                                {translations?.['project_monitoring.extension_of_time.actions.save']}
                              </button>
                          ) : (
                              <div
                                  onClick={() => {
                                    setCurrentRowEot(item?.id);
                                    setCurrentItemEot(item);
                                    setEditableEot(true);
                                    setDataEot(() => {
                                      let array = dataEot?.map((elem) => {
                                        if (elem?.id === item?.id) {
                                          elem.edit = true;
                                        }
                                        return elem;
                                      });
                                      return array;
                                    });
                                  }}
                                  className="project__block-update"
                              >
                                {translations?.['project_monitoring.extension_of_time.actions.edit']}
                              </div>
                          )}
                        </div>
                      </form>
                    </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="project__status-content">
          <div className="varandclaims__boxLong">
            <p className="project__status-subtitle">{translations?.['project_monitoring.extension_of_time.titles.total_days_claimed']}</p>
            <span className="project__status-text">
            {claimData?.extension_of_time?.total_days_claimed}
          </span>
          </div>
          <div className="varandclaims__boxLong">
            <p className="project__status-subtitle">{translations?.['project_monitoring.extension_of_time.titles.total_days_approved']}</p>
            <span className="project__status-text">
            {claimData?.extension_of_time?.total_days_approved}
          </span>
          </div>
          <div className="varandclaims__boxLong">
            <p className="project__status-subtitle">
              {translations?.['project_monitoring.extension_of_time.titles.total_days_pending_approval']}
            </p>
            <span className="project__status-text">
            {claimData?.extension_of_time?.total_days_pending_approval}
          </span>
          </div>
        </div>
      </div>
  );
};
