import {toast} from "react-toastify";
import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import ReactCodeInput from "react-verification-code-input";
import {setToken} from "../../store/slice/forgetSlice";
import {setLoader} from "../../store/slice/dashboard";
import {useLazyForgotPasswordQuery, useSendCodeMutation} from "../../store/service/adbService";
import AxelButton from "../../UI/AxelButton";
import AuthWrapper from "../../components/AuthWrapper";
import {useErrorHandler} from "../../global/ErrorsHandler";


export default function ForgotPin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const email = useSelector(state => state?.forgetReducer?.email)
  const [data, setData] = useState({
    email: email,
    code: null,
  })

  const [forgotHandler, {data: forgotEmailData, isLoading, error, isSuccess}] =
    useLazyForgotPasswordQuery();

  const [sendPin, {
    data: sendPinData,
    isError: sendPinIsError,
    isLoading: sendPinIsLoading,
    error: sendPinError,
    isSuccess: sendPinIsSuccess
  }] = useSendCodeMutation();

  const [errorHandler, setErrorHandler] = useErrorHandler([error, sendPinError]);

  useEffect(() => {
    if (isLoading) {
      dispatch(setLoader(true))
    } else {
      dispatch(setLoader(false))
    }

    if (isSuccess) {
      toast.success(forgotEmailData.message)
    }
  }, [isLoading, isSuccess])

  useEffect(() => {
    if (sendPinIsLoading) {
      dispatch(setLoader(true))
    } else {
      dispatch(setLoader(false))
    }
    if (sendPinIsSuccess) {
      toast.success(sendPinData.message)
      dispatch(setToken(sendPinData.data))
      navigate('/reset-password')
    }
  }, [sendPinIsLoading, sendPinIsSuccess, sendPinIsError])

  const codeHandler = (e) => {
    setData(old => ({...old, code: e}))
  }
  const submitCode = (e) => {
    e.preventDefault()
    if (data?.code?.length === 6) {
      sendPin(data)
    } else {
      toast.error('Enter valid code!')
    }
  }

  const sendCodeAgain = () => {
    forgotHandler(email)
  }
  return (
    <>
      <AuthWrapper>
        <h1 className="auth__title verification">
          Enter verification code
        </h1>
        <p className="auth__descr-verification">
          We have just sent a verification code to {email}
        </p>
        <form
          action="#"
          className="auth__form login__form"
          onSubmit={submitCode}
        >
          <ReactCodeInput className={'auth__form-codeField'} fieldWidth={48} onChange={codeHandler}
                          fieldHeight={48} autoFocus={false}/>

          <span className="auth__form-sendAgain" onClick={sendCodeAgain}>Send the code again</span>
          <AxelButton handler={submitCode} text="Verify"/>
        </form>
      </AuthWrapper>
    </>

  );
};

