import {
    Bar,
    CartesianGrid, ComposedChart,
    Line,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import useLastPathname from "../../global/useLastPathname";
import moment from "moment/moment";

export const VersusChart = ({ id, reportId, data, translations, isPage}) => {
    const location = useLocation();
    const lastPathname = useLastPathname();
    const [titles, setTitles] = useState({})
    const localeLan = localStorage.getItem("local_lan" );
    const [paymentsList, setPaymentsList] = useState([]);
    const [docTranslations, setDocTranslations] = useState([]);

    useEffect(() => {
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/chart/actual-planned/${reportId ? reportId : location?.pathname?.split("/")[2]}`,
                {
                    headers: {
                        "AppLocale": isPage? localeLan : lastPathname
                    }
                }
            )
            .then((res) => {
                let response = res?.data?.data?.data?.data;

                setDocTranslations(res?.data?.data?.translations)
                setPaymentsList(response?.map(item=>({
                    ...item,
                    actual_amount: item?.actual_amount,
                    cumulative_actual_amount: item?.cumulative_actual_amount,
                    cumulative_planned_amount: item?.cumulative_planned_amount,
                    planned_amount: item?.planned_amount,
                })));
            });
    }, [reportId, id, data]);


    useEffect(() => {
        if(translations || docTranslations){
            setTitles(() => ({
                planned: translations?.['titles.planned'] || translations?.['project_monitoring.program_progress.titles.planned'] || docTranslations?.['planned'],
                actual: translations?.['titles.actual'] || translations?.['project_monitoring.program_progress.titles.actual'] || docTranslations?.['actual'],
                culmunativePlanned: translations?.['titles.cumulative_planned'] || translations?.['project_monitoring.program_progress.titles.cumulative_planned'] || docTranslations?.['cumulative_planned'],
                culmunativeActual: translations?.['titles.cumulative_actual'] || translations?.['project_monitoring.program_progress.titles.cumulative_actual'] || docTranslations?.['cumulative_actual']
            }))
        }
    },[translations, docTranslations])


    function formatYAxis(tickItem) {
        if (tickItem > 1000000000) {
            return (
                "$" +
                String((tickItem / 1000000000).toFixed(0)).replace(
                    /(\d)(?=(\d{3})+([^\d]|$))/g,
                    "$1,"
                ) +
                "B"
            );
        }
        if (tickItem > 1000000) {
            return (
                "$" +
                String((tickItem / 1000000).toFixed(0)).replace(
                    /(\d)(?=(\d{3})+([^\d]|$))/g,
                    "$1,"
                ) +
                "M"
            );
        }
        if (tickItem > 1000) {
            return (
                "$" +
                String((tickItem / 1000).toFixed(0)).replace(
                    /(\d)(?=(\d{3})+([^\d]|$))/g,
                    "$1,"
                ) +
                "K"
            );
        }
        return "$" + String(tickItem).replace(/(\d)(?=(\d{3})+([^\d]|$))/g, "$1,");
    }

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        {payload.map((pld, idx) => (
                            <div key={idx} style={{ display: "inline-block", padding: 10 }}>
                                <div
                                    style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        fontSize: 12,
                                    }}
                                >
                                    $
                                    {String(pld?.value).replace(
                                        /(\d)(?=(\d{3})+([^\d]|$))/g,
                                        "$1,"
                                    )}
                                </div>
                                <div style={{ color: pld.color, fontSize: 12 }}>{pld.name}</div>
                            </div>
                        ))}
                    </div>
                </div>
            );
        }

        return null;
    };


    return (
        <div className="plannedProject__chart">
            <div className="project__dashboard-progress">
                <h3 className="project__dashboard-subtitle">
                    {
                        isPage ? translations?.['headers.actual_versus_planned_payments_usd']
                            ||  translations?.['payments.actual_versus_planned_project_payments.titles.actual_versus_planned_project_payments_usd']
                            : docTranslations?.['actual_versus_planned_project_payments_usd']

                    }
                </h3>

                <div className="project__dashboard-statuses">
                    <div className="project__dashboard-status">
                        <div className="project__dashboard-status red"></div>
                        <span>{titles?.planned}</span>
                    </div>
                    <div className="project__dashboard-status">
                        <div className="project__dashboard-status lightblue"></div>
                        <span>{titles?.actual}</span>
                    </div>
                    <div className="project__dashboard-status">
                        <div className="project__dashboard-status blue"></div>
                        <span>{titles?.culmunativePlanned}</span>
                    </div>
                    <div className="project__dashboard-status">
                        <div className="project__dashboard-status yellow"></div>
                        <span>{titles?.culmunativeActual}</span>
                    </div>
                </div>
            </div>
            {paymentsList?.length !== 0 ? (
                <ResponsiveContainer width="100%" aspect={3}>
                    <ComposedChart
                        width={500}
                        height={300}
                        data={paymentsList}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >

                        <CartesianGrid strokeDasharray="0 0"/>
                        <XAxis tickFormatter={(tickItem)=>tickItem} fontSize={12} dataKey="date"/>
                        <YAxis
                            orientation={"right"}
                            domain={[0, dataMax => (dataMax + dataMax / 5)]}
                            type="number"
                            tickFormatter={formatYAxis}
                            yAxisId="cumulative"
                            fontSize={12}/>
                        <YAxis
                            yAxisId={'amount'}
                            domain={[0, dataMax => (dataMax + dataMax / 5)]}
                            orientation={"left"}
                            type="number"
                            tickFormatter={formatYAxis}
                            fontSize={12}
                        />
                        <Tooltip content={CustomTooltip}/>
                        <Bar
                            yAxisId={'amount'}
                            domain={[0, dataMax => (dataMax + dataMax / 5)]}
                            dataKey="planned_amount"
                            name={titles?.planned}
                            fill="#E68484"
                            radius={[10, 10, 0, 0]}/>
                        <Bar
                            yAxisId={'amount'}
                            dataKey={"actual_amount"}
                            name={titles?.actual}
                            fill="#99BAEB"
                            radius={[10, 10, 0, 0]}/>
                        <Line
                            domain={[0, dataMax => (dataMax + dataMax / 5)]}
                            yAxisId={'cumulative'} dot={false}
                            strokeWidth={4}
                            strokeLinecap="round"
                            type="monotone"
                            dataKey="cumulative_planned_amount"
                            stroke="#3375d6"
                            name={titles?.culmunativePlanned}
                        />
                        <Line
                            yAxisId={'cumulative'}
                            dot={false}
                            strokeWidth={4}
                            strokeLinecap="round"
                            type="monotone"
                            dataKey="cumulative_actual_amount"
                            stroke="#ecc949"
                            name={titles?.culmunativeActual}
                        />
                    </ComposedChart>
                </ResponsiveContainer>
            ) : (
                <div className="plannedProject__placeholder">
                    <img src="/assets/icons/planned_chart.svg" alt="" />
                    There is no Data yet
                </div>
            )}
        </div>
    );
};
