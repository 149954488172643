export const bigNumberFormatter = (value) => {
  if (typeof value === "number" || typeof value === "string") {
    let formattedValue = value.toString();
    if (formattedValue.length > 3) {
      const parts = [];
      for (let i = formattedValue.length - 1; i >= 0; i -= 3) {
        const part = formattedValue.substring(Math.max(0, i - 2), i + 1);
        parts.unshift(part);
      }
      formattedValue = parts.join(",");
    }
    return formattedValue;
  }
  return value;
};

export const millionFormatter = (value) => {
  if (typeof value === "number" || typeof value === "string") {
    let formattedValue = value.toString().replace(/\s+/g, '');

    // Remove the '$' symbol if present
    let hasDollarSign = false;
    if (formattedValue.charAt(0) === '$') {
      hasDollarSign = true;
      formattedValue = formattedValue.slice(1);
    }

    const length = formattedValue.length;

    if (length > 6) {
      const millionsPart = formattedValue.slice(0, length - 6);
      const restPart = formattedValue.slice(length - 6);
      formattedValue = `${millionsPart}.${restPart}`;
    }

    return value.includes('$') ? `$${formattedValue}` : formattedValue;
  }
  return value;
};

