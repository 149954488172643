import moment from "moment";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import { useErrorHandler } from "../../../global/ErrorsHandler";
import {
    useLarpSectionCreateMutation,
    useLarpSectionDeleteMutation,
    useLarpSectionUpdateMutation,
    useUpdateLarpTopLevelMutation,
    useLazyGetAllCompaniesQuery,
    useSetLarpSectionTierMutation,
    useSetLarpAffectedMutation,
    useDeleteLarpAffectedMutation,
    useDuplicatePersonsMutation,
} from "../../../store/service/adbService";
import { setLoader } from "../../../store/slice/dashboard";
import { Switch } from "../../../UI/Switch";
import { DeleteModal } from "../DeleteModal";
import { useParams } from "react-router-dom";
import AxelCheckbox from "../../../UI/AxelCheckbox";
import DownloadCSV from "../../DownloadCSV";
import MoreInfoModal from "../../MoreInfoModal";

const customStyles = {
    menu: (provided, state = false) => ({
        ...provided,
        width: "150%",
        color: state.selectProps.menuColor,
        cursor: "pointer",
        height: 95,
        overflow: "hidden",
    }),

    control: (_, { selectProps: {  } }) => ({
        border: "1px solid rgba(0, 0, 0, 0.1)",
        display: "flex",
        background: "#FFFFFF",
        borderRadius: 8,
    }),
};

export const Larp = ({ larpData, glossaryData, translations }) => {
    const dispatch = useDispatch();
    const params = useParams();
    const [data, setData] = useState([]);
    const [larpSections, setLarpSections] = useState([]);
    const [show, setShow] = useState(true);
    const [sectionDelete, setSectionDelete] = useState(false);
    const [currentdata, setCurrentdata] = useState(null);
    const [newdata, setNewdata] = useState(false);
    const [options, setOptions] = useState([]);
    const [editable, setEditable] = useState(false);
    const [editableSection, setEditableSection] = useState(false);
    const [moreInfo, setMoreInfo] = useState(null);
    const [tableHeaders, setTableHeaders] = useState([]);
    const [sectionsHeaders, setSectionsHeaders] = useState([]);
    const [infoModal, setInfoModal] = useState(false);
    const [currentRow, setCurrentRow] = useState(null);
    const [currentRowSection, setCurrentRowSection] = useState(null);
    const scrollRef = useRef(null);
    const user = useSelector((state) => state?.adbReducer?.user);
    const expandedRefSection = useRef(null);
    const [expandedSection, setExpandedSection] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [currentItemLevel1, setCurrentItemLevel1] = useState(null);
    const [currentItemLevel2, setCurrentItemLevel2] = useState(null);
    const [currentItemLevel3, setCurrentItemLevel3] = useState(null);
    const [currentTier, setCurrentTier] = useState(null);
    const [duplicateChecked, setDuplicateChecked] = useState([]);
    const [newItemSection, setNewItemSection] = useState(false);
    const [
        setTierApi,
        {
            isLoading: tierIsLoading,
            error: tierError,
        },
    ] = useSetLarpSectionTierMutation();

    const [
        setLarpAffectedApi,
        {
            isLoading: affectedIsLoading,
            error: affectedError,
        },
    ] = useSetLarpAffectedMutation();

    const [
        deleteLarpAffectedApi,
        {
            isLoading: deletedAffectedIsLoading,
            error: deleteAffectedError,
        },
    ] = useDeleteLarpAffectedMutation();

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                setEditable(false);
                setCurrentdata(null);
                if (newdata) {
                    setNewdata(false);
                    let s = data?.filter((el, i) => i !== data.length - 1);
                    setData(s);
                }
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, [data]);

    useEffect(() => {
        if (larpData?.larp?.top_level) {
            let data = larpData?.larp?.top_level;
            setEditable(false);
            setData(data);
        }
    }, [larpData?.larp?.top_level]);

    const [
        duplicatePersons,
        {
            error: duplicatePersonsError,
            isLoading: duplicatePersonsIsLoading,
        },
    ] = useDuplicatePersonsMutation();

    useEffect(() => {
        setEditableSection(false);

        if (larpSections.length > 0) {
            let dupliacteLarpSections = [...larpSections];
            let originalLarpData = dupliacteLarpSections?.filter((item) => {
                let s = larpData?.larp?.sections?.filter((elem) => {
                    if (item?.id === elem?.id) {
                        return {
                            ...elem,
                            edit: false,
                        };
                    }
                });
                return s;
            });
            let newLarpData = larpData?.larp?.sections?.filter((item) => {
                dupliacteLarpSections?.forEach((elem) => {
                    if (item?.id !== elem?.id) {
                        return {
                            ...item,
                            edit: false,
                        };
                    }
                });
            });
            let newLarp = larpData?.larp?.sections?.map((item) => {
                let newItem = { ...item, edit: false };
                originalLarpData?.forEach((elem) => {
                    if (newItem?.id === elem?.id) {
                        newItem = { ...newItem, open: elem?.open, edit: false };
                    }

                    let updatedTiers = newItem?.tiers?.map((tier) => {
                        let newTier = { ...tier, edit: false };

                        elem?.tiers?.forEach((elemTier) => {
                            if (newTier?.id === elemTier?.id) {
                                newTier = {
                                    ...newTier,
                                    open: elemTier?.open,
                                    details: {
                                        ...newTier?.details,
                                        open: elemTier?.details?.open,
                                    },
                                };
                            }
                        });
                        return newTier;
                    });
                    newItem.tiers = updatedTiers;
                });

                return newItem;
            });

            newLarp = newLarp?.map((item) => {
                return {
                    ...item,
                    edit: false,
                };
            });
            newLarpData = newLarpData?.map((item) => {
                return {
                    ...item,
                    edit: false,
                };
            });

            setLarpSections([...newLarp, ...newLarpData]);
        } else {
            setLarpSections(
                larpData?.larp?.sections?.map((item) => ({ ...item, edit: false }))
            );
        }
    }, [larpData?.larp?.sections]);

    const [
        getCompanies,
    ] = useLazyGetAllCompaniesQuery();

    const [
        createLarpSection,
        {
            error: createLarpSectionError,
            isLoading: createLarpSectionIsLoading,
        },
    ] = useLarpSectionCreateMutation();

    const [
        updateLarpSection,
        {
            error: updateLarpSectionError,
            isLoading: updateLarpSectionIsLoading,
        },
    ] = useLarpSectionUpdateMutation();

    const [
        deleteLarpSection,
        {
            error: deleteLarpSectionError,
            isLoading: deleteLarpSectionIsLoading,
        },
    ] = useLarpSectionDeleteMutation();

    useEffect(() => {
        getCompanies({
            per_page: 1000,
        });
    }, []);
    const [
        updateVariationRegister,
        {
            isLoading: updateVariationRegisterIsLoading,
            isSuccess: updateVariationRegisterIsSuccess,
            error: updateVariationRegisterError,
        },
    ] = useUpdateLarpTopLevelMutation();

    useEffect(() => {
        getCompanies({
            per_page: 1000,
        });
    }, []);

    useEffect(() => {
        if(translations){
            const headers = [
                {
                    tooltip_name: translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.final_route_and_sections_submitted_to_employer'],
                    tooltip_text: translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.tooltips.final_route_and_sections_submitted_to_employer']
                },
                {
                    tooltip_name: translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.date_submitted'],
                    tooltip_text: translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.tooltips.date_submitted']
                },
                {
                    tooltip_name: translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.final_route_and_sections_approved'],
                    tooltip_text: translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.tooltips.final_route_and_sections_approved']
                },
                {
                    tooltip_name: translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.date_approved'],
                    tooltip_text: translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.tooltips.date_approved']
                },
            ]

            const section_headers = [
                {
                    tooltip_name: translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.section_ref'],
                    tooltip_text: translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.tooltips.section_ref']
                },
                {
                    tooltip_name: translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.section_description'],
                    tooltip_text: translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.tooltips.section_description']
                },
                {
                    tooltip_name: translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.number_of_household_affected'],
                    tooltip_text: translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.tooltips.number_of_household_affected']
                },
            ]

            setTableHeaders(
                headers.map((data) => ({
                    ...data,
                    showTooltip: false,
                }))
            );

            setOptions([
                { value: 0, label: translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.dropdown.standard'] },
                { value: 1, label: translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.dropdown.detailed']},
            ])

            setSectionsHeaders(
                section_headers.map((data) => ({
                    ...data,
                    showTooltip: false,
                }))
            );
        }
    }, [translations])

    useEffect(() => {
        if (glossaryData?.data?.length) {
            setMoreInfo(
                glossaryData?.data?.filter(
                    (el) => el?.section_name === "LARP Sections"
                )[0]
            );
        }
        setEditable(false);
    }, [updateVariationRegisterIsSuccess, glossaryData]);

    useEffect(() => {
        const d = () => {
            dispatch(setLoader(false));
        };

        updateVariationRegisterIsLoading ||
        createLarpSectionIsLoading ||
        updateLarpSectionIsLoading ||
        deleteLarpSectionIsLoading ||
        tierIsLoading ||
        affectedIsLoading ||
        deletedAffectedIsLoading ||
        duplicatePersonsIsLoading
            ? dispatch(setLoader(true))
            : d();
    }, [
        updateVariationRegisterIsLoading,
        createLarpSectionIsLoading,
        updateLarpSectionIsLoading,
        deleteLarpSectionIsLoading,
        tierIsLoading,
        affectedIsLoading,
        deletedAffectedIsLoading,
        duplicatePersonsIsLoading,
    ]);

    const [errorHandler, setErrorHandler] = useErrorHandler([
        updateVariationRegisterError,
        updateVariationRegisterError,
        tierError,
        createLarpSectionError,
        updateLarpSectionError,
        affectedError,
        deleteAffectedError,
        duplicatePersonsError,
        affectedError,
        deleteLarpSectionError,
    ]);

    const setterData = (input) => {
        const body = {
            date_submitted: moment(input?.date_submitted).format("YYYY-MM-DD"),
            date_approved: moment(input?.date_approved).format("YYYY-MM-DD"),
            project_id: larpData?.id,
        };

        let s = [];

        for (let key in body) {
            if (!body[key] || body[key] === "Invalid date" || body[key] === "") {
                toast.error("Please enter " + key?.split("_")?.join(" "), {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
                s.push("false");
            }
        }

        if (s.length === 0) {
            return body;
        } else {
            return null;
        }
    };

    const deleteSection = (id) => {
        deleteLarpSection(id);
        setCurrentItem(null);
        setEditableSection(false);
        setNewItemSection(false);
    };

    const submitHandler = (e, data) => {
        e.preventDefault();
        if (newdata) {
            const dataCreated = setterData(currentdata);
            if (dataCreated) {
                setCurrentdata(null);
                setNewdata(false);
                setCurrentRow(null);
            }
        } else {
            let data = {
                id: currentdata?.id,
                detailed: currentdata?.detailed,
            };
            if (currentdata?.sections_approved) {
                data.date_approved = currentdata?.date_approved;
            }
            if (currentdata?.sections_submitted) {
                data.date_submitted = currentdata?.date_submitted;
            }
            const dataUpdated = { ...setterData(data) };
            if (!currentdata?.sections_approved) {
                dataUpdated.date_approved = null;
            }
            if (!currentdata?.sections_submitted) {
                dataUpdated.date_submitted = null;
            }
            if (dataUpdated) {
                updateVariationRegister({
                    id: data?.id,
                    body: {
                        ...dataUpdated,
                        sections_submitted: currentdata?.sections_submitted,
                        sections_approved: currentdata?.sections_approved,
                        detailed: data?.detailed,
                    },
                });
                setCurrentdata(null);
                setNewdata(false);
                setCurrentRow(null);
            }
        }
    };

    const changeTrackingMethod = (selected) => {
        if (currentdata?.responsible_party?.length !== 0) {
            updateVariationRegister({
                id: data?.id,
                body: {
                    detailed: selected?.value,
                },
            });
        }
    };

    const submitHandlerSection = (e, item) => {
        e.preventDefault();
        if (newItemSection) {
            const body = {
                project_id: currentItem?.project_id,
                section_ref: currentItem?.section_ref,
                section_description: currentItem?.section_description,
            };
            if (
                currentItem?.number_of_households_affected &&
                currentItem?.number_of_households_affected !== 0
            ) {
                body.number_of_households_affected = String(
                    currentItem?.number_of_households_affected
                );
            }
            createLarpSection({ ...body, project_id: params?.id })
            setCurrentItem(null);
            setNewItemSection(false);
            setEditableSection(false);
        } else {
            const body = {
                project_id: currentItem?.project_id,
                section_ref: currentItem?.section_ref,
                section_description: currentItem?.section_description,
            };
            if (
                currentItem?.number_of_households_affected &&
                currentItem?.number_of_households_affected !== 0
            ) {
                body.number_of_households_affected = String(
                    currentItem?.number_of_households_affected
                );
            }
            updateLarpSection({ body: { ...body, edit: false }, id: item.id })
                .unwrap()
                .then((res) => {
                    setCurrentItem(null);
                    setNewItemSection(false);
                    setEditableSection(false);
                    setCurrentRowSection(null);
                });
        }
    };

    const duplicatePersonsFunc = () => {
        dispatch(setLoader(true));
        duplicatePersons({
            tier_id: currentTier?.tier_id,
            copy_tier_ids: duplicateChecked,
        })
            .unwrap()
            .then((res) => {
                dispatch(setLoader(false));
                if (res?.data?.length) {
                    toast.error(res?.data[0]);
                } else {
                    toast.success(res.message);
                }
            });
    };

    return (
        <div className="project__goods">
            {infoModal && (
                <MoreInfoModal
                    title={translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.headers.duplicate']}
                    moreInfo={translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.more_info.larp_land_acquisition_resettlement_plan']}
                />
            )}
            {currentTier && (
                <div className="larp__modal">
                    <div className="larp__modal-content">
                        <div className="larp__modal-header">
                            <div className="larp__modal-title">{translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.headers.duplicate']}</div>
                            <div
                                className="larp__modal-close"
                                onClick={() => {
                                    setDuplicateChecked([]);
                                    setCurrentTier(null);
                                }}
                            >
                                <img src="/assets/icons/close.svg" alt="close" />
                            </div>
                        </div>
                        <div className="larp__modal-descr">
              <span>
                <img src="/assets/icons/info.svg" alt="info" />
              </span>
                            {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.titles.if_you_happen_to_copy_a_name_that_already_exists']}
                        </div>
                        <div className="larp__modal-p">
                            {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.titles.what_tiers_do_you_want_to_duplicate']}
                        </div>
                        {currentTier?.tiers?.map((item) => {
                            if (item?.id !== currentTier?.tier_id) {
                                return (
                                    <div className="larp__modal-item">
                                        <span>
                                            {item?.id !== currentTier?.tier_id && item?.stage_ref}
                                        </span>
                                        <AxelCheckbox
                                            id={item?.id}
                                            handler={(value) => {
                                                let data = [...duplicateChecked];
                                                if (data?.filter((a) => a === value).length === 0) {
                                                    data = [...data, value];
                                                } else {
                                                    data = data?.filter((a) => a !== value);
                                                }
                                                setDuplicateChecked(data);
                                            }}
                                        />
                                    </div>
                                );
                            }
                        })}
                        <div
                            className="larp__modal-btns"
                            onClick={() => {
                                setDuplicateChecked([]);
                                setCurrentTier(null);
                            }}
                        >
                            <div className="larp__modal-btn larp__modal-btn_cancel">
                                <button>{translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_monitoring.actions.cancel']}</button>
                            </div>
                            <div
                                className="larp__modal-btn"
                                onClick={() => {
                                    duplicatePersonsFunc();
                                }}
                            >
                                <button>{translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.actions.confirm']}</button>
                            </div>
                        </div>
                    </div>
                    <div
                        onClick={() => {
                            setCurrentTier(null);
                            setDuplicateChecked([]);
                        }}
                        className="larp__modal-bg"
                    ></div>
                </div>
            )}
            {sectionDelete && (
                <DeleteModal
                    id={currentItem?.id}
                    action={deleteSection}
                    setDeleteModal={setSectionDelete}
                    cencelText={translations?.['main.main.actions.cancel']}
                    deleteText={translations?.['main.main.actions.delete']}
                    modalTitle={translations?.['main.main.headers.confirm_your_action']}
                    clarifyingText={translations?.['main.main.titles.you_are_going_to_delete_this_row']}
                />
            )}
            {larpData?.project_settings?.larp ? (
                <div className={`project__block ${show ? "" : "close"}`}>
                    <div className="project__block-subtitles online__more">
                        <div className="project__block-subtitle">
                            {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.headers.larp_land_acquisition_resettlement_plan']}
                        </div>
                        <span
                            className="project__block-more"
                            onClick={() => setInfoModal(!infoModal)}
                        >
              <span className="img">
                <img src="/assets/icons/info.svg" alt="info" />
              </span>
              <span className="text">{translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.titles.more_info']}</span>
            </span>
                    </div>
                    <div
                        className={`project__block-content permits ${
                            editable ? "editable" : ""
                        }`}
                    >
                        <div
                            className={`project__block-table project-table ${
                                currentdata ? "n" : ""
                            }`}
                        >
                            <div className={`project-table__header`} ref={scrollRef}>
                                {tableHeaders?.map((el, idx) => {
                                    return (
                                        <div
                                            key={idx}
                                            onMouseEnter={(event) => {
                                                setTableHeaders(
                                                    tableHeaders.map((data) => ({
                                                        ...data,
                                                        showTooltip: el.tooltip_name === data.tooltip_name,
                                                    }))
                                                );
                                            }}
                                            onMouseLeave={() => {
                                                setTableHeaders(
                                                    tableHeaders.map((data) => ({
                                                        ...data,
                                                        showTooltip: false,
                                                    }))
                                                );
                                            }}
                                            className="project-table__th"
                                        >
                                            {el?.showTooltip ? (
                                                <span
                                                    className={
                                                        el?.tooltip_text?.length > 20
                                                            ? "lengthy"
                                                            : "not-lengthy"
                                                    }
                                                >
                          {el?.tooltip_text}
                        </span>
                                            ) : (
                                                ""
                                            )}
                                            {el?.tooltip_name}
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="project-table__body">
                                <>
                                    <form
                                        className={`project-table__row ${
                                            editable ? "edit" : currentdata ? "n" : ""
                                        } ${
                                            Boolean(data.submitted) ||
                                            !user?.permissions?.edit_project
                                                ? "submitted"
                                                : ""
                                        }`}
                                        id={data?.id}
                                        key={data?.id}
                                        onSubmit={(e) => submitHandler(e, data)}
                                    >
                                        <div className="project-table__td">
                                            <Switch
                                                value={data?.sections_submitted}
                                                checked={
                                                    editable
                                                        ? currentdata?.sections_submitted
                                                        : data?.sections_submitted
                                                }
                                                id={6613}
                                                handler={(value) => {
                                                    setCurrentdata((old) => ({
                                                        ...old,
                                                        sections_submitted: value,
                                                    }));
                                                }}
                                            />
                                        </div>
                                        <div className="project-table__td">
                      <span>
                        {moment(data?.date_submitted).format("DD/MM/YYYY") ===
                        "Invalid date" ||
                        !moment(data?.date_submitted).format("DD/MM/YYYY")
                            ? ""
                            : moment(data?.date_submitted).format("DD/MM/YYYY")}
                      </span>
                                            <div className="project__block-item-calendar project-table__field">
                                                <DatePicker
                                                    portalId="root-portal"
                                                    autoComplete="off"
                                                    id="approved_date"
                                                    placeholderText="dd/mm/yyyy"
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={
                                                        currentdata?.date_submitted
                                                            ? moment(currentdata?.date_submitted).toDate()
                                                            : ""
                                                    }
                                                    onChange={(date) => {
                                                        setCurrentdata((old) => ({
                                                            ...old,
                                                            date_submitted: date,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="project-table__td">
                                            <Switch
                                                value={data?.sections_approved}
                                                checked={
                                                    editable
                                                        ? currentdata?.sections_approved
                                                        : data?.sections_approved
                                                }
                                                id={614}
                                                handler={(value) => {
                                                    setCurrentdata((old) => ({
                                                        ...old,
                                                        sections_approved: value,
                                                    }));
                                                }}
                                            />
                                        </div>
                                        <div className="project-table__td">
                      <span>
                        {moment(data?.date_approved).format("DD/MM/YYYY") ===
                        "Invalid date" ||
                        !moment(data?.date_approved).format("DD/MM/YYYY")
                            ? ""
                            : moment(data?.date_approved).format("DD/MM/YYYY")}
                      </span>
                                            <div className="project__block-item-calendar project-table__field">
                                                <DatePicker
                                                    portalId="root-portal"
                                                    autoComplete="off"
                                                    id="approved_date"
                                                    placeholderText="dd/mm/yyyy"
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={
                                                        currentdata?.date_approved
                                                            ? moment(currentdata?.date_approved).toDate()
                                                            : ""
                                                    }
                                                    onChange={(date) => {
                                                        setCurrentdata((old) => ({
                                                            ...old,
                                                            date_approved: date,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="project__block-btns">
                                            {currentdata ? (
                                                <div
                                                    className="project__block-cancel"
                                                    onClick={() => {
                                                        setEditable(false);
                                                        setCurrentdata(null);
                                                        if (newdata) {
                                                            let s = data?.filter(
                                                                (el, i) => i !== data.length - 1
                                                            );
                                                            setData(s);
                                                        }
                                                    }}
                                                >
                                                    <img src="/assets/icons/close-mark.svg" alt="close" />
                                                    {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.actions.cancel']}
                                                </div>
                                            ) : (
                                                <></>)}
                                            {currentdata ? (
                                                <button className="project__block-save">
                                                    <img src="/assets/icons/save-arrow.svg" alt="arrow" />
                                                    {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.actions.save']}
                                                </button>
                                            ) : (
                                                <div
                                                    onClick={() => {
                                                        setCurrentRow(data?.id);
                                                        setCurrentdata(data);
                                                        setEditable(true);
                                                    }}
                                                    className="project__block-update"
                                                >
                                                    {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.actions.update']}
                                                </div>
                                            )}
                                        </div>
                                    </form>
                                </>
                            </div>
                        </div>
                        <div className="project__block-larpSection">
                            <h2 className="project__block-sectionsTitle">{translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.headers.sections']}</h2>
                            {user?.permissions?.edit_project ? (
                                <>
                                    <div className="project__block-sectionsInput">
                                       <span> {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.titles.tracking_method']}</span>
                                        <Select
                                            options={options}
                                            menuPortalTarget={document.body}
                                            styles={{
                                                ...customStyles,
                                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            }}
                                            isSearchable={false}
                                            value={
                                                currentdata?.responsible_party
                                                    ? currentdata?.responsible_party
                                                    : {
                                                        value: data?.detailed,
                                                        label:
                                                            data?.detailed === 1 ? translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.dropdown.detailed']
                                                                : translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.dropdown.standard'],
                                                    }
                                            }
                                            onChange={(selected) => {
                                                setCurrentItem(null);
                                                const copy = [...larpSections];
                                                const updatedLarps = copy?.map((larp) => {
                                                    return {
                                                        ...larp,
                                                        open: false,
                                                        tiers: larp?.tiers?.map((a) => {
                                                            return {
                                                                ...a,
                                                                open: false,
                                                                details: {
                                                                    ...a.details,
                                                                    open: false,
                                                                },
                                                            };
                                                        }),
                                                    };
                                                });
                                                setCurrentItemLevel1(null);
                                                setCurrentItemLevel2(null);
                                                setCurrentItemLevel3(null);
                                                setLarpSections(updatedLarps);
                                                changeTrackingMethod(selected);
                                            }}
                                            defaultValue={options[0]}
                                        />
                                    </div>
                                    {data?.detailed ?
                                        <DownloadCSV btnTitle={translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.actions.download_to_csv']}
                                                     fileName="affected_persons"
                                                     url={`/larp/section/export/csv/${larpData?.latest_report?.id}`}
                                                     tooltipTitle={translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.tooltips.download_affected_households_data']} />
                                        : null
                                    }
                                </>
                            ): null}
                        </div>
                        <div className="project__block-subtitles">
                            <div className="project__block-subtitle">{translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.headers.sections']}</div>
                            {user?.permissions?.edit_project && (
                                <div
                                    onClick={() => {
                                        if (larpData?.larp?.sections?.length < 30) {
                                            let randomId = Math.floor(Math.random() * 1000);
                                            scrollRef !== null &&
                                            scrollRef?.current?.scrollIntoView();
                                            setCurrentRowSection(randomId);
                                            if (!newItemSection) {
                                                setNewItemSection(true);
                                                setEditableSection(true);
                                                setLarpSections((old) => [
                                                    ...old,
                                                    {
                                                        project_id: randomId,
                                                        section_ref: "",
                                                        section_description: "",
                                                        number_of_households_affected: null,
                                                        edit: true,
                                                    },
                                                ]);
                                                setCurrentItem({
                                                    project_id: randomId,
                                                    section_ref: "",
                                                    section_description: "",
                                                    number_of_households_affected: null,
                                                    edit: true,
                                                });
                                            }
                                        } else {
                                            toast.error("Section limit is 30");
                                        }
                                    }}
                                    className="project__block-add-row"
                                >
                  <span>
                    <img src="/assets/icons/blue-plus.svg" alt="plus" />
                  </span>
                                    <span>{translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.actions.add_row']}</span>
                                </div>
                            )}
                        </div>
                        <div className="project__block-table project-table n">
                            <div className="project-table__header project-table-larp-sections">
                                <div className="project-table__th">{translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.section_ref']}</div>
                                <div className="project-table__th">{translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.section_description']}</div>
                                <div className="project-table__th">{translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.number_of_household_affected']}</div>
                            </div>
                            <div>
                                {larpSections?.map((item, idx) => (
                                    <div ref={scrollRef} key={idx} id={item?.id}>
                                        {idx !== 0 && currentRowSection === item?.id && (
                                            <div className={`project-table__header`}>
                                                {sectionsHeaders?.map((el, idx) => {
                                                    return (
                                                        <div key={idx} className="project-table__th">
                                                            {el?.showTooltip ? (
                                                                <span
                                                                    className={
                                                                        el?.tooltip_text?.length > 20
                                                                            ? "lengthy"
                                                                            : "not-lengthy"
                                                                    }
                                                                >
                                  {el?.tooltip_text}
                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                            {el?.tooltip_name} 2
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                        <form
                                            className={`project-table__row project-table-larp-sections ${
                                                item?.edit ? "edit" : item?.open ? "n" : ""
                                            }
                                   ${
                                                Boolean(item.submitted) ||
                                                !user?.permissions?.edit_project
                                                    ? "submitted"
                                                    : ""
                                            }`}
                                            id={`level1_${item?.section_ref}`}
                                            onSubmit={(e) => submitHandlerSection(e, item)}
                                        >
                                            <div className="project-table__td">
                                                <span>{item?.section_ref}</span>
                                                <div className="project__block-input project-table__field">
                                                    <input
                                                        maxLength={190}
                                                        type="text"
                                                        defaultValue={item?.section_ref}
                                                        onChange={(e) => {
                                                            setCurrentItem((old) => ({
                                                                ...old,
                                                                section_ref: e.target.value,
                                                            }));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="project-table__td">
                                                <span>{item?.section_description}</span>
                                                <div className="project__block-input project-table__field">
                          <textarea
                              className={`${
                                  expandedSection
                                      ? "project__block-expandedInput"
                                      : ""
                              }`}
                              ref={expandedRefSection}
                              onFocus={() => setExpandedSection(true)}
                              onBlur={() => setExpandedSection(false)}
                              maxLength={190}
                              type="text"
                              defaultValue={item?.section_description}
                              onChange={(e) => {
                                  setCurrentItem((old) => ({
                                      ...old,
                                      section_description: e.target.value,
                                  }));
                              }}
                          />
                                                </div>
                                            </div>
                                            <div className="project-table__td">
                                                <p>

                                                    {
                                                        item?.edit ?
                                                            <div className="project__block-input project-table__field">
                                                                {larpData?.larp?.top_level?.detailed === 0 && (
                                                                    <input
                                                                        maxLength={190}
                                                                        type="number"
                                                                        defaultValue={
                                                                            item?.number_of_households_affected
                                                                        }
                                                                        onChange={(e) => {
                                                                            setCurrentItem((old) => ({
                                                                                ...old,
                                                                                number_of_households_affected:
                                                                                e.target.value,
                                                                            }));
                                                                        }}
                                                                    />
                                                                )}
                                                            </div>
                                                            : <span style={{display: 'block'}}>{item?.number_of_households_affected}</span>
                                                    }
                                                </p>
                                            </div>
                                            <div
                                                className={`project__block-btns ${
                                                    item?.open ? "s" : ""
                                                }`}
                                            >
                                                {item?.edit ? (
                                                    <div
                                                        className="project__block-cancel"
                                                        onClick={() => {
                                                            setCurrentRowSection(null);
                                                            let array = larpSections?.map((item) => {
                                                                item.edit = false;
                                                                return item;
                                                            });
                                                            setCurrentItem(null);
                                                            setLarpSections(array);
                                                            setEditableSection(false);
                                                            if (newItemSection) {
                                                                setNewItemSection(false);
                                                                let s = larpSections?.filter(
                                                                    (el, i) => i !== larpSections.length - 1
                                                                );
                                                                setLarpSections(s);
                                                            }
                                                        }}
                                                    >
                                                        <img
                                                            src="/assets/icons/close-mark.svg"
                                                            alt="close"
                                                        />
                                                        {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.actions.cancel']}
                                                    </div>
                                                ) : (
                                                    <div
                                                        className="project__block-delete"
                                                        onClick={() => {
                                                            setCurrentItem(item);
                                                            setSectionDelete(true);
                                                        }}
                                                    >
                                                        <img
                                                            src="/assets/icons/trash-icon.svg"
                                                            alt="arrow"
                                                        />
                                                        {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.actions.delete']}
                                                    </div>
                                                )}
                                                {item?.edit ? (
                                                    <button className="project__block-save">
                                                        <img
                                                            src="/assets/icons/save-arrow.svg"
                                                            alt="arrow"
                                                        />
                                                        {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.actions.save']}
                                                    </button>
                                                ) : (
                                                    <div
                                                        onClick={() => {
                                                            setCurrentRowSection(item?.id);
                                                            setCurrentItem(item);
                                                            setEditableSection(true);
                                                            setLarpSections(() => {
                                                                let array = larpSections?.map((elem) => {
                                                                    if (elem?.id === item?.id) {
                                                                        elem.edit = true;
                                                                    }
                                                                    return elem;
                                                                });
                                                                return array;
                                                            });
                                                        }}
                                                        className="project__block-update"
                                                    >
                                                        {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.actions.update']}
                                                    </div>
                                                )}
                                            </div>
                                            {!item.edit ? (
                                                <div className="project__larp-btns">
                                                    {!item.open ? (
                                                        <div
                                                            onClick={() => {
                                                                setCurrentItem({ ...item, level_1_s: true });
                                                                const copy = [...larpSections];
                                                                const updatedLarps = copy?.map((larp) => {
                                                                    if (larp?.id === item?.id) {
                                                                        return { ...larp, open: true };
                                                                    } else {
                                                                        return {
                                                                            ...larp,
                                                                            open: false,
                                                                        };
                                                                    }
                                                                });
                                                                setLarpSections(updatedLarps);
                                                                let el = document.getElementById(
                                                                    `level1_${item?.section_ref}`
                                                                );
                                                                setTimeout(() => {
                                                                    el?.scrollIntoView({
                                                                        block: "start",
                                                                        inline: "center",
                                                                        behavior: "smooth",
                                                                    });
                                                                }, 0);
                                                            }}
                                                            className="project__block-expand"
                                                        >
                                                            <img
                                                                src="/assets/icons/arrow-down-larp.svg"
                                                                alt="arrow"
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div
                                                            onClick={() => {
                                                                setCurrentItem(null);
                                                                const copy = [...larpSections];
                                                                const updatedLarps = copy?.map((larp) => {
                                                                    return {
                                                                        ...larp,
                                                                        open: false,
                                                                        tiers: larp?.tiers?.map((a) => {
                                                                            return {
                                                                                ...a,
                                                                                open: false,
                                                                                details: {
                                                                                    ...a.details,
                                                                                    open: false,
                                                                                },
                                                                            };
                                                                        }),
                                                                    };
                                                                });
                                                                setCurrentItemLevel1(null);
                                                                setCurrentItemLevel2(null);
                                                                setCurrentItemLevel3(null);
                                                                setLarpSections(updatedLarps);
                                                                let el = document.getElementById(
                                                                    `level1_${item?.section_ref}`
                                                                );
                                                                setTimeout(() => {
                                                                    el?.scrollIntoView({
                                                                        block: "start",
                                                                        inline: "center",
                                                                        behavior: "smooth",
                                                                    });
                                                                }, 0);
                                                            }}
                                                            className="project__block-expand"
                                                        >
                                                            <img
                                                                src="/assets/icons/arrow-up-larp.svg"
                                                                alt="arrow"
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            ) : null}
                                        </form>
                                        {item?.open && (
                                            <div className="project__expanded-space"></div>
                                        )}
                                        {item?.open && (
                                            <div className="project__expanded-tiers">
                                                <div className="mt-0 project__block-subtitles">
                                                    <div className="project__expanded-titles s">
                                                        {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.headers.tiers']}
                                                    </div>
                                                </div>
                                                <div className="project-table__header project-table-larp-sections">
                                                    <div className="project-table__th">{translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.tier_ref']}</div>
                                                    <div className="project-table__th">{translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.notice_to_proceed']}</div>
                                                    <div className="project-table__th">{translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.date_of_notice']}</div>
                                                </div>
                                                {item?.tiers?.map((tier, index) => (
                                                    <>
                                                        <div
                                                            key={index}
                                                            className={`project-table__row  ${
                                                                tier?.edit
                                                                    ? "edit"
                                                                    : currentItemLevel1?.edit
                                                                        ? "n"
                                                                        : ""
                                                            }`}
                                                        >
                                                            <div className="project-table__td">
                                                                {tier?.stage_ref}
                                                            </div>
                                                            <div className="project-table__td">
                                                                <Switch
                                                                    value={tier?.notice_to_proceed}
                                                                    id={index + 1341}
                                                                    disabled={!tier?.edit}
                                                                    checked={
                                                                        tier?.edit
                                                                            ? currentItemLevel1?.notice_to_proceed
                                                                            : tier?.notice_to_proceed
                                                                    }
                                                                    handler={(value) => {
                                                                        setCurrentItemLevel1((old) => ({
                                                                            ...old,
                                                                            notice_to_proceed: value ? 1 : 0,
                                                                        }));
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="project-table__td" style={{marginRight: '50px'}}>
                                <span>
                                  {moment(tier?.date_of_notice).format(
                                      "DD/MM/YYYY"
                                  ) === "Invalid date" ||
                                  !moment(tier?.date_of_notice).format(
                                      "DD/MM/YYYY"
                                  )
                                      ? ""
                                      : moment(tier?.date_of_notice).format(
                                          "DD/MM/YYYY"
                                      )}
                                </span>
                                                                <div className="project__block-item-calendar project-table__field">
                                                                    <DatePicker
                                                                        portalId="root-portal"
                                                                        autoComplete="off"
                                                                        id={`date_of_notice_${tier?.id}`}
                                                                        placeholderText="dd/mm/yyyy"
                                                                        dateFormat="dd/MM/yyyy"
                                                                        selected={
                                                                            currentItemLevel1?.date_of_notice
                                                                                ? moment(
                                                                                    currentItemLevel1?.date_of_notice
                                                                                ).toDate()
                                                                                : ""
                                                                        }
                                                                        onChange={(date) => {
                                                                            setCurrentItemLevel1((old) => ({
                                                                                ...old,
                                                                                date_of_notice: date,
                                                                            }));
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div
                                                                className={`project__block-btns ${
                                                                    tier?.open ? "s" : ""
                                                                }`}
                                                            >
                                                                {tier?.edit ? (
                                                                    <div
                                                                        className="project__block-cancel"
                                                                        onClick={() => {
                                                                            const copy = { ...item };
                                                                            const updatedTiers = copy?.tiers?.map(
                                                                                (currentTier) => {
                                                                                    if (currentTier?.id === tier?.id) {
                                                                                        return {
                                                                                            ...currentTier,
                                                                                            edit: false,
                                                                                        };
                                                                                    } else {
                                                                                        return {
                                                                                            ...currentTier,
                                                                                            edit: false,
                                                                                        };
                                                                                    }
                                                                                }
                                                                            );
                                                                            copy.tiers = updatedTiers;
                                                                            setCurrentItemLevel1(false);
                                                                            setLarpSections((old) => {
                                                                                return old?.map((a) => {
                                                                                    if (a.id === item.id) {
                                                                                        a.tiers = copy.tiers;
                                                                                    }
                                                                                    return a;
                                                                                });
                                                                            });
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src="/assets/icons/close-mark.svg"
                                                                            alt="close"
                                                                        />
                                                                        {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_monitoring.actions.cancel']}
                                                                    </div>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                                {tier?.edit ? (
                                                                    <button
                                                                        className="project__block-save"
                                                                        onClick={() => {
                                                                            const send = {
                                                                                notice_to_proceed:
                                                                                currentItemLevel1?.notice_to_proceed,
                                                                                date_of_notice:
                                                                                    currentItemLevel1?.date_of_notice
                                                                                        ? moment(
                                                                                            currentItemLevel1?.date_of_notice
                                                                                        ).format("YYYY-MM-DD")
                                                                                        : null,
                                                                                id: currentItemLevel1?.id,
                                                                            };

                                                                            setTierApi(send)
                                                                                .unwrap()
                                                                                .then((res) => {
                                                                                    const copy = { ...item };
                                                                                    const updatedTiers = copy?.tiers?.map(
                                                                                        (currentTier) => {
                                                                                            if (
                                                                                                currentTier?.id === tier?.id
                                                                                            ) {
                                                                                                return {
                                                                                                    ...currentTier,
                                                                                                    notice_to_proceed:
                                                                                                    currentItemLevel1?.notice_to_proceed,
                                                                                                    date_of_notice:
                                                                                                    currentItemLevel1?.date_of_notice,
                                                                                                    edit: false,
                                                                                                    open: false,
                                                                                                };
                                                                                            } else {
                                                                                                return {
                                                                                                    ...currentTier,
                                                                                                    edit: false,
                                                                                                    open: false,
                                                                                                };
                                                                                            }
                                                                                        }
                                                                                    );
                                                                                    copy.tiers = updatedTiers;
                                                                                    setCurrentItemLevel1(null);
                                                                                    setLarpSections((old) => {
                                                                                        return old?.map((a) => {
                                                                                            if (a.id === item.id) {
                                                                                                a.tiers = copy.tiers;
                                                                                            }
                                                                                            return a;
                                                                                        });
                                                                                    });

                                                                                    setCurrentItemLevel1(null);
                                                                                });
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src="/assets/icons/save-arrow.svg"
                                                                            alt="arrow"
                                                                        />
                                                                        {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.actions.save']}
                                                                    </button>
                                                                ) : (
                                                                    <div
                                                                        onClick={() => {
                                                                            const copy = { ...item };
                                                                            const updatedTiers = copy?.tiers?.map(
                                                                                (currentTier) => {
                                                                                    if (currentTier?.id === tier?.id) {
                                                                                        return {
                                                                                            ...currentTier,
                                                                                            edit: true,
                                                                                        };
                                                                                    } else {
                                                                                        return {
                                                                                            ...currentTier,
                                                                                            edit: false,
                                                                                        };
                                                                                    }
                                                                                }
                                                                            );
                                                                            copy.tiers = updatedTiers;
                                                                            setCurrentItemLevel1({
                                                                                ...tier,
                                                                                edit: true,
                                                                            });
                                                                            setLarpSections((old) => {
                                                                                return old?.map((a) => {
                                                                                    if (a.id === item.id) {
                                                                                        a.tiers = copy.tiers;
                                                                                    }
                                                                                    return a;
                                                                                });
                                                                            });
                                                                        }}
                                                                        className="project__block-update"
                                                                    >
                                                                        {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.actions.update']}
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="project__larp-btns">
                                                                {!tier?.open ? (
                                                                    <div
                                                                        onClick={() => {
                                                                            const copy = { ...item };
                                                                            const updatedTiers = copy?.tiers?.map(
                                                                                (currentTier) => {
                                                                                    if (currentTier?.id === tier?.id) {
                                                                                        return {
                                                                                            ...currentTier,
                                                                                            open: true,
                                                                                        };
                                                                                    } else {
                                                                                        return {
                                                                                            ...currentTier,
                                                                                            open: false,
                                                                                        };
                                                                                    }
                                                                                }
                                                                            );
                                                                            copy.tiers = updatedTiers;
                                                                            setCurrentItemLevel1({
                                                                                ...tier,
                                                                                level_2_s: true,
                                                                            });
                                                                            setLarpSections((old) => {
                                                                                return old?.map((a) => {
                                                                                    if (a.id === item.id) {
                                                                                        a.tiers = copy.tiers;
                                                                                    }
                                                                                    return a;
                                                                                });
                                                                            });
                                                                            let el = document.getElementById(
                                                                                `level1_${item?.section_ref}`
                                                                            );
                                                                            setTimeout(() => {
                                                                                el?.scrollIntoView({
                                                                                    block: "start",
                                                                                    inline: "center",
                                                                                    behavior: "smooth",
                                                                                });
                                                                            }, 0);
                                                                        }}
                                                                        className="project__block-expand"
                                                                    >
                                                                        <img
                                                                            src="/assets/icons/arrow-down-larp.svg"
                                                                            alt="arrow"
                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        onClick={() => {
                                                                            const copy = { ...item };
                                                                            const updatedTiers = copy?.tiers?.map(
                                                                                (currentTier) => {
                                                                                    return {
                                                                                        ...currentTier,
                                                                                        open: false,
                                                                                    };
                                                                                }
                                                                            );
                                                                            copy.tiers = updatedTiers;
                                                                            setLarpSections((old) => {
                                                                                return old?.map((a) => {
                                                                                    if (a.id === item.id) {
                                                                                        a.tiers = copy.tiers;
                                                                                    }
                                                                                    return a;
                                                                                });
                                                                            });
                                                                            let el = document.getElementById(
                                                                                `level1_${item?.section_ref}`
                                                                            );
                                                                            setTimeout(() => {
                                                                                el?.scrollIntoView({
                                                                                    block: "start",
                                                                                    inline: "center",
                                                                                    behavior: "smooth",
                                                                                });
                                                                            }, 0);
                                                                        }}
                                                                        className="project__block-expand"
                                                                    >
                                                                        <img
                                                                            src="/assets/icons/arrow-up-larp.svg"
                                                                            alt="arrow"
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        {tier?.open && (
                                                            <div className="project__expanded-space s"></div>
                                                        )}
                                                        {tier?.open && (
                                                            <div
                                                                className="project__expanded-tiers s"
                                                                id={`tier_level_${tier?.id}`}
                                                            >
                                                                <div className="project-table__header grid-s">
                                                                    <div className="project-table__th">
                                                                        {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.updated_larp_submitted_to_adb']}
                                                                    </div>
                                                                    <div className="project-table__th">
                                                                        {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.larp_date_submitted']}
                                                                    </div>
                                                                    <div className="project-table__th">
                                                                        {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.larp_approved']}
                                                                    </div>
                                                                    <div className="project-table__th">
                                                                        {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.larp_date_approved']}
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className={`project-table__row grid-s ${
                                                                        tier?.details?.edit
                                                                            ? "edit"
                                                                            : tier?.details?.open
                                                                                ? "n"
                                                                                : "a"
                                                                    }`}
                                                                    id={`tier_details_${tier?.details?.id}`}
                                                                >
                                                                    <div className="project-table__td">
                                                                        <Switch
                                                                            value={tier?.details?.larp_submitted}
                                                                            id={index + 1738}
                                                                            disabled={!tier?.details?.edit}
                                                                            checked={
                                                                                tier?.details?.edit
                                                                                    ? currentItemLevel2?.larp_submitted
                                                                                    : tier?.details?.larp_submitted
                                                                            }
                                                                            handler={(value) => {
                                                                                setCurrentItemLevel2((old) => ({
                                                                                    ...old,
                                                                                    larp_submitted: value ? 1 : 0,
                                                                                }));
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="project-table__td">
                                    <span>
                                      {moment(
                                          tier?.details?.larp_date_submitted
                                      ).format("DD/MM/YYYY") ===
                                      "Invalid date" ||
                                      !moment(
                                          tier?.details?.larp_date_submitted
                                      ).format("DD/MM/YYYY")
                                          ? ""
                                          : moment(
                                              tier?.details?.larp_date_submitted
                                          ).format("DD/MM/YYYY")}
                                    </span>
                                                                        <div className="project__block-item-calendar project-table__field">
                                                                            <DatePicker
                                                                                portalId="root-portal"
                                                                                autoComplete="off"
                                                                                id={`larp_date_submitted_${tier?.id}`}
                                                                                placeholderText="dd/mm/yyyy"
                                                                                dateFormat="dd/MM/yyyy"
                                                                                selected={
                                                                                    currentItemLevel2?.larp_date_submitted
                                                                                        ? moment(
                                                                                            currentItemLevel2?.larp_date_submitted
                                                                                        ).toDate()
                                                                                        : ""
                                                                                }
                                                                                onChange={(date) => {
                                                                                    setCurrentItemLevel2((old) => ({
                                                                                        ...old,
                                                                                        larp_date_submitted: date,
                                                                                    }));
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="project-table__td">
                                                                        <Switch
                                                                            value={tier?.details?.larp_approved}
                                                                            disabled={!tier?.details?.edit}
                                                                            id={index + 21973}
                                                                            checked={
                                                                                tier?.details?.edit
                                                                                    ? currentItemLevel2?.larp_approved
                                                                                    : tier?.details?.larp_approved
                                                                            }
                                                                            handler={(value) => {
                                                                                setCurrentItemLevel2((old) => ({
                                                                                    ...old,
                                                                                    larp_approved: value ? 1 : 0,
                                                                                }));
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="project-table__td">
                                    <span>
                                      {moment(
                                          tier?.details?.larp_date_approved
                                      ).format("DD/MM/YYYY") ===
                                      "Invalid date" ||
                                      !moment(
                                          tier?.details?.larp_date_approved
                                      ).format("DD/MM/YYYY")
                                          ? ""
                                          : moment(
                                              tier?.details?.larp_date_approved
                                          ).format("DD/MM/YYYY")}
                                    </span>
                                                                        <div className="project__block-item-calendar project-table__field">
                                                                            <DatePicker
                                                                                portalId="root-portal"
                                                                                autoComplete="off"
                                                                                id={`larp_date_approved_${tier?.id}`}
                                                                                placeholderText="dd/mm/yyyy"
                                                                                dateFormat="dd/MM/yyyy"
                                                                                selected={
                                                                                    currentItemLevel2?.larp_date_approved
                                                                                        ? moment(
                                                                                            currentItemLevel2?.larp_date_approved
                                                                                        ).toDate()
                                                                                        : ""
                                                                                }
                                                                                onChange={(date) => {
                                                                                    setCurrentItemLevel2((old) => ({
                                                                                        ...old,
                                                                                        larp_date_approved: date,
                                                                                    }));
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className={`project__block-btns ${
                                                                            tier?.details?.open ? "s" : ""
                                                                        }`}
                                                                    >
                                                                        {tier?.details?.edit ? (
                                                                            <div
                                                                                className="project__block-cancel"
                                                                                onClick={() => {
                                                                                    const copy = { ...item };
                                                                                    const updatedTiers = copy?.tiers?.map(
                                                                                        (currentTier) => {
                                                                                            if (
                                                                                                currentTier?.id === tier?.id
                                                                                            ) {
                                                                                                return {
                                                                                                    ...currentTier,
                                                                                                    details: {
                                                                                                        ...currentTier?.details,
                                                                                                        edit: false,
                                                                                                    },
                                                                                                };
                                                                                            } else {
                                                                                                return {
                                                                                                    ...currentTier,
                                                                                                    details: {
                                                                                                        ...currentTier?.details,
                                                                                                        edit: false,
                                                                                                    },
                                                                                                };
                                                                                            }
                                                                                        }
                                                                                    );
                                                                                    copy.tiers = updatedTiers;
                                                                                    setCurrentItemLevel2(null);
                                                                                    setLarpSections((old) => {
                                                                                        return old?.map((a) => {
                                                                                            if (a.id === item.id) {
                                                                                                a.tiers = copy.tiers;
                                                                                            }
                                                                                            return a;
                                                                                        });
                                                                                    });
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src="/assets/icons/close-mark.svg"
                                                                                    alt="close"
                                                                                />
                                                                                {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.actions.cancel']}
                                                                            </div>
                                                                        ) : (
                                                                            <></>
                                                                        )}
                                                                        {tier?.details?.edit ? (
                                                                            <button
                                                                                className="project__block-save"
                                                                                onClick={() => {
                                                                                    const send = {
                                                                                        larp_submitted:
                                                                                        currentItemLevel2?.larp_submitted,
                                                                                        id: tier?.id,
                                                                                        larp_approved:
                                                                                        currentItemLevel2?.larp_approved,
                                                                                        vouchers_received:
                                                                                        currentItemLevel2?.vouchers_received,
                                                                                        total_amount:
                                                                                            currentItemLevel2?.total_amount
                                                                                                ?.toString()
                                                                                                ?.split(",")
                                                                                                .join(""),
                                                                                        payments_completed:
                                                                                        currentItemLevel2?.payments_completed,
                                                                                        report_submitted:
                                                                                        currentItemLevel2?.report_submitted,
                                                                                        report_approved:
                                                                                        currentItemLevel2?.report_approved,
                                                                                    };
                                                                                    if (
                                                                                        currentItemLevel2?.larp_date_submitted !==
                                                                                        null
                                                                                    ) {
                                                                                        send.larp_date_submitted = moment(
                                                                                            currentItemLevel2?.larp_date_submitted
                                                                                        ).format("YYYY-MM-DD");
                                                                                    }
                                                                                    if (
                                                                                        currentItemLevel2?.larp_date_approved !==
                                                                                        null
                                                                                    ) {
                                                                                        send.larp_date_approved = moment(
                                                                                            currentItemLevel2?.larp_date_approved
                                                                                        ).format("YYYY-MM-DD");
                                                                                    }
                                                                                    if (
                                                                                        currentItemLevel2?.payments_date_completed !==
                                                                                        null
                                                                                    ) {
                                                                                        send.payments_date_completed =
                                                                                            moment(
                                                                                                currentItemLevel2?.payments_date_completed
                                                                                            ).format("YYYY-MM-DD");
                                                                                    }
                                                                                    if (
                                                                                        currentItemLevel2?.report_date_submitted !==
                                                                                        null
                                                                                    ) {
                                                                                        send.report_date_submitted = moment(
                                                                                            currentItemLevel2?.report_date_submitted
                                                                                        ).format("YYYY-MM-DD");
                                                                                    }
                                                                                    if (
                                                                                        currentItemLevel2?.report_date_approved !==
                                                                                        null
                                                                                    ) {
                                                                                        send.report_date_approved = moment(
                                                                                            currentItemLevel2?.report_date_approved
                                                                                        ).format("YYYY-MM-DD");
                                                                                    }

                                                                                    setTierApi(send)
                                                                                        .unwrap()
                                                                                        .then((res) => {
                                                                                            setCurrentItemLevel2(null);
                                                                                            const copy = { ...item };
                                                                                            const updatedTiers =
                                                                                                copy?.tiers?.map(
                                                                                                    (currentTier) => {
                                                                                                        if (
                                                                                                            currentTier?.id ===
                                                                                                            tier?.id
                                                                                                        ) {
                                                                                                            return {
                                                                                                                ...currentTier,
                                                                                                                details: {
                                                                                                                    ...currentTier?.details,
                                                                                                                    larp_submitted:
                                                                                                                    currentItemLevel2?.larp_submitted,
                                                                                                                    larp_date_submitted:
                                                                                                                    currentItemLevel2?.larp_date_submitted,
                                                                                                                    id: tier?.id,
                                                                                                                    larp_approved:
                                                                                                                    currentItemLevel2?.larp_approved,
                                                                                                                    larp_date_approved:
                                                                                                                    currentItemLevel2?.larp_date_approved,
                                                                                                                    vouchers_received:
                                                                                                                    currentItemLevel2?.vouchers_received,
                                                                                                                    total_amount:
                                                                                                                        currentItemLevel2?.total_amount
                                                                                                                            ?.toString()
                                                                                                                            ?.split(",")
                                                                                                                            .join(""),
                                                                                                                    payments_completed:
                                                                                                                    currentItemLevel2?.payments_completed,
                                                                                                                    payments_date_completed:
                                                                                                                    currentItemLevel2?.payments_date_completed,
                                                                                                                    report_submitted:
                                                                                                                    currentItemLevel2?.report_submitted,
                                                                                                                    report_date_submitted:
                                                                                                                    currentItemLevel2?.report_date_submitted,
                                                                                                                    report_approved:
                                                                                                                    currentItemLevel2?.report_approved,
                                                                                                                    report_date_approved:
                                                                                                                    currentItemLevel2?.report_date_approved,
                                                                                                                    edit: false,
                                                                                                                    open: false,
                                                                                                                },
                                                                                                            };
                                                                                                        } else {
                                                                                                            return {
                                                                                                                ...currentTier,
                                                                                                                edit: false,
                                                                                                                open: false,
                                                                                                            };
                                                                                                        }
                                                                                                    }
                                                                                                );
                                                                                            copy.tiers = updatedTiers;
                                                                                            setCurrentItemLevel2(null);
                                                                                            setLarpSections((old) => {
                                                                                                return old?.map((a) => {
                                                                                                    if (a.id === item.id) {
                                                                                                        a.tiers = copy.tiers;
                                                                                                    }
                                                                                                    return a;
                                                                                                });
                                                                                            });
                                                                                        })
                                                                                        .catch((err) => {
                                                                                            for (let key in err.data.errors) {
                                                                                                err.data.errors[key].forEach(
                                                                                                    (k) => {
                                                                                                        toast.error(k);
                                                                                                    }
                                                                                                );
                                                                                            }
                                                                                        });
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src="/assets/icons/save-arrow.svg"
                                                                                    alt="arrow"
                                                                                />
                                                                                {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_monitoring.actions.save']}
                                                                            </button>
                                                                        ) : (
                                                                            <div
                                                                                onClick={() => {
                                                                                    const copy = { ...item };
                                                                                    const updatedTiers = copy?.tiers?.map(
                                                                                        (currentTier) => {
                                                                                            if (
                                                                                                currentTier?.id === tier?.id
                                                                                            ) {
                                                                                                return {
                                                                                                    ...currentTier,
                                                                                                    details: {
                                                                                                        ...currentTier?.details,
                                                                                                        edit: true,
                                                                                                    },
                                                                                                };
                                                                                            } else {
                                                                                                return {
                                                                                                    ...currentTier,
                                                                                                    details: {
                                                                                                        ...currentTier?.details,
                                                                                                        edit: false,
                                                                                                    },
                                                                                                };
                                                                                            }
                                                                                        }
                                                                                    );
                                                                                    copy.tiers = updatedTiers;
                                                                                    setCurrentItemLevel2(tier?.details);
                                                                                    setLarpSections((old) => {
                                                                                        return old?.map((a) => {
                                                                                            if (a.id === item.id) {
                                                                                                a.tiers = copy.tiers;
                                                                                            }
                                                                                            return a;
                                                                                        });
                                                                                    });
                                                                                }}
                                                                                className="project__block-update"
                                                                            >
                                                                                {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_monitoring.actions.update']}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="project-table__header grid-s">
                                                                    <div className="project-table__th">
                                                                        {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.list_of_affected_households_and_compensation_vouchers_received']}
                                                                    </div>
                                                                    <div className="project-table__th">
                                                                        {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.total_amount']}
                                                                    </div>
                                                                    <div className="project-table__th">
                                                                        {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.payments_completed']}
                                                                    </div>
                                                                    <div className="project-table__th">
                                                                        {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.payments_completed_date']}
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className={`project-table__row grid-s ${
                                                                        tier?.details?.edit
                                                                            ? "edit"
                                                                            : currentItemLevel2
                                                                                ? "n"
                                                                                : "a"
                                                                    }`}
                                                                >
                                                                    <div className="project-table__td">
                                                                        <Switch
                                                                            value={tier?.details?.vouchers_received}
                                                                            // id={`vouchers_received_larp`}
                                                                            id={index + 12312}
                                                                            disabled={!tier?.details?.edit}
                                                                            checked={
                                                                                tier?.details?.edit
                                                                                    ? currentItemLevel2?.vouchers_received
                                                                                    : tier?.details?.vouchers_received
                                                                            }
                                                                            handler={(value) => {
                                                                                setCurrentItemLevel2((old) => ({
                                                                                    ...old,
                                                                                    vouchers_received: value ? 1 : 0,
                                                                                }));
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="project-table__td">
                                    <span>
                                      {tier?.details?.total_amount
                                          ? "$" +
                                          String(
                                              tier?.details?.total_amount
                                          ).replace(
                                              /(\d)(?=(\d{3})+([^\d]|$))/g,
                                              "$1,"
                                          )
                                          : ""}
                                    </span>
                                                                        <div className="project__block-input project-table__field">
                                                                            <input
                                                                                maxLength={190}
                                                                                type="number"
                                                                                defaultValue={
                                                                                    tier?.details?.total_amount
                                                                                }
                                                                                onChange={(e) => {
                                                                                    setCurrentItemLevel2((old) => ({
                                                                                        ...old,
                                                                                        total_amount: e.target.value,
                                                                                    }));
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="project-table__td">
                                                                        <Switch
                                                                            value={tier?.details.payments_completed}
                                                                            // id={`payments_completed_larp`}
                                                                            id={index + 753}
                                                                            disabled={!tier?.details?.edit}
                                                                            checked={
                                                                                tier?.details?.edit
                                                                                    ? currentItemLevel2?.payments_completed
                                                                                    : tier?.details?.payments_completed
                                                                            }
                                                                            handler={(value) => {
                                                                                setCurrentItemLevel2((old) => ({
                                                                                    ...old,
                                                                                    payments_completed: value ? 1 : 0,
                                                                                }));
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="project-table__td">
                                    <span>
                                      {moment(
                                          tier?.details?.payments_date_completed
                                      ).format("DD/MM/YYYY") ===
                                      "Invalid date" ||
                                      !moment(
                                          tier?.details?.payments_date_completed
                                      ).format("DD/MM/YYYY")
                                          ? ""
                                          : moment(
                                              tier?.details
                                                  ?.payments_date_completed
                                          ).format("DD/MM/YYYY")}
                                    </span>
                                                                        <div className="project__block-item-calendar project-table__field">
                                                                            <DatePicker
                                                                                portalId="root-portal"
                                                                                autoComplete="off"
                                                                                id={`payments_date_completed_${tier?.id}`}
                                                                                placeholderText="dd/mm/yyyy"
                                                                                dateFormat="dd/MM/yyyy"
                                                                                selected={
                                                                                    currentItemLevel2?.payments_date_completed
                                                                                        ? moment(
                                                                                            currentItemLevel2?.payments_date_completed
                                                                                        ).toDate()
                                                                                        : ""
                                                                                }
                                                                                onChange={(date) => {
                                                                                    setCurrentItemLevel2((old) => ({
                                                                                        ...old,
                                                                                        payments_date_completed: date,
                                                                                    }));
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    {larpData?.larp?.top_level?.detailed === 1 ? (
                                                                        <div className="project__larp-btns">
                                                                            {!tier?.details?.open ? (
                                                                                <div
                                                                                    onClick={() => {
                                                                                        const copy = { ...item };
                                                                                        const updatedTiers =
                                                                                            copy?.tiers?.map(
                                                                                                (currentTier) => {
                                                                                                    if (
                                                                                                        currentTier?.id === tier?.id
                                                                                                    ) {
                                                                                                        return {
                                                                                                            ...currentTier,
                                                                                                            details: {
                                                                                                                ...currentTier?.details,
                                                                                                                open: true,
                                                                                                            },
                                                                                                        };
                                                                                                    } else {
                                                                                                        return {
                                                                                                            ...currentTier,
                                                                                                            details: {
                                                                                                                ...currentTier?.details,
                                                                                                                open: false,
                                                                                                            },
                                                                                                        };
                                                                                                    }
                                                                                                }
                                                                                            );
                                                                                        copy.tiers = updatedTiers;
                                                                                        setCurrentItemLevel2(tier?.details);
                                                                                        setLarpSections((old) => {
                                                                                            return old?.map((a) => {
                                                                                                if (a.id === item.id) {
                                                                                                    a.tiers = copy.tiers;
                                                                                                }
                                                                                                return a;
                                                                                            });
                                                                                        });
                                                                                        let el = document.getElementById(
                                                                                            `level1_${item?.section_ref}`
                                                                                        );
                                                                                        setTimeout(() => {
                                                                                            el?.scrollIntoView({
                                                                                                block: "start",
                                                                                                inline: "center",
                                                                                                behavior: "smooth",
                                                                                            });
                                                                                        }, 0);
                                                                                    }}
                                                                                    className="project__block-expand"
                                                                                >
                                                                                    <img
                                                                                        src="/assets/icons/arrow-down-larp.svg"
                                                                                        alt="arrow"
                                                                                    />
                                                                                </div>
                                                                            ) : (
                                                                                <div
                                                                                    onClick={() => {
                                                                                        const copy = { ...item };
                                                                                        setCurrentItemLevel2(null);
                                                                                        const updatedTiers =
                                                                                            copy?.tiers?.map(
                                                                                                (currentTier) => {
                                                                                                    if (
                                                                                                        currentTier?.id === tier?.id
                                                                                                    ) {
                                                                                                        return {
                                                                                                            ...currentTier,
                                                                                                            details: {
                                                                                                                ...currentTier?.details,
                                                                                                                open: false,
                                                                                                            },
                                                                                                        };
                                                                                                    } else {
                                                                                                        return {
                                                                                                            ...currentTier,
                                                                                                            details: {
                                                                                                                ...currentTier?.details,
                                                                                                                open: false,
                                                                                                            },
                                                                                                        };
                                                                                                    }
                                                                                                }
                                                                                            );
                                                                                        copy.tiers = updatedTiers;
                                                                                        setLarpSections((old) => {
                                                                                            return old?.map((a) => {
                                                                                                if (a.id === item.id) {
                                                                                                    a.tiers = copy.tiers;
                                                                                                }
                                                                                                return a;
                                                                                            });
                                                                                        });
                                                                                    }}
                                                                                    className="project__block-expand"
                                                                                >
                                                                                    <img
                                                                                        src="/assets/icons/arrow-up-larp.svg"
                                                                                        alt="arrow"
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                                {tier?.details?.open && (
                                                                    <div className="project__expanded-space"></div>
                                                                )}
                                                                {tier?.details?.open && (
                                                                    <div className="project__expanded-tiers">
                                                                        <div className="mt-0 project__block-subtitles">
                                                                            <div className="project__expanded-titles d">
                                                                                <div className="left">
                                                                                    {translations?.['environment_social_health_safety_and_security_obligations.complaints_grievances.table_headers.affected_persons']}
                                                                                </div>
                                                                                <div className="right">
                                          <span
                                              onClick={() => {
                                                  setCurrentTier({
                                                      tier_id: tier?.id,
                                                      tiers: item?.tiers,
                                                      item: item,
                                                  });
                                              }}
                                          >
                                            <img
                                                src="/assets/icons/duplicate.svg"
                                                alt="duplicate"
                                            />
                                              {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.actions.duplicate_to_tiers']}
                                          </span>
                                                                                    <span
                                                                                        onClick={() => {
                                                                                            if (!currentItemLevel3) {
                                                                                                const copy = { ...item };
                                                                                                const updatedTiers =
                                                                                                    copy?.tiers?.map(
                                                                                                        (currentTier) => {
                                                                                                            return {
                                                                                                                ...currentTier,
                                                                                                                affected_persons: [
                                                                                                                    ...currentTier.affected_persons,
                                                                                                                    {
                                                                                                                        amount: "",
                                                                                                                        completed: 0,
                                                                                                                        date_verified: null,
                                                                                                                        external_consultant_verified:
                                                                                                                            null,
                                                                                                                        name: "",
                                                                                                                        paidLabel: translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.titles.unpaid'],
                                                                                                                        paid: 'unpaid',
                                                                                                                        paid_date: null,
                                                                                                                        status: "",
                                                                                                                        edit: true,
                                                                                                                        create: true,
                                                                                                                        id: currentTier
                                                                                                                            .affected_persons[
                                                                                                                        currentTier
                                                                                                                            .affected_persons
                                                                                                                            ?.length - 1
                                                                                                                            ]?.id
                                                                                                                            ? currentTier
                                                                                                                            .affected_persons[
                                                                                                                        currentTier
                                                                                                                            .affected_persons
                                                                                                                            ?.length - 1
                                                                                                                            ]?.id + 1
                                                                                                                            : 0,
                                                                                                                    },
                                                                                                                ],
                                                                                                            };
                                                                                                        }
                                                                                                    );
                                                                                                copy.tiers = updatedTiers;
                                                                                                setCurrentItemLevel3({
                                                                                                    amount: "",
                                                                                                    completed: 0,
                                                                                                    date_verified: null,
                                                                                                    external_consultant_verified: 0,
                                                                                                    name: "",
                                                                                                    paid: 'unpaid',
                                                                                                    paidLabel: translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.titles.unpaid'],
                                                                                                    paid_date: null,
                                                                                                    status: "",
                                                                                                    edit: true,
                                                                                                    create: true,
                                                                                                });
                                                                                                setLarpSections((old) => {
                                                                                                    return old?.map((a) => {
                                                                                                        if (a.id === item.id) {
                                                                                                            a.tiers = copy.tiers;
                                                                                                        }
                                                                                                        return a;
                                                                                                    });
                                                                                                });
                                                                                            }
                                                                                        }}
                                                                                    >
                                            <img
                                                src="/assets/icons/blue-plus.svg"
                                                alt="duplicate"
                                            />
                                                                                        {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.actions.add_row']}
                                          </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="project-table__header" style={{padding: '12px',}}>
                                                                            <div className="project-table__th" style={{width: 150}}>
                                                                                {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.affected_household_name']}
                                                                            </div>
                                                                            <div className="project-table__th" style={{width: 95}}>
                                                                                {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.amount']}
                                                                            </div>
                                                                            <div className="project-table__th">
                                                                                {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.payment_status']}
                                                                            </div>
                                                                            <div className="project-table__th" style={{width: '105px'}}>
                                                                                {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.paid_date']}
                                                                            </div>
                                                                            <div className="project-table__th">
                                                                                {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.external_consultant_verified']}
                                                                            </div>
                                                                            <div className="project-table__th">
                                                                                {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.data_verified']}
                                                                            </div>
                                                                            <div className="project-table__th">
                                                                                {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.completed']}
                                                                            </div>
                                                                            <div className="project-table__th">
                                                                                {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.current_status_residual_issues']}
                                                                            </div>
                                                                        </div>
                                                                        {tier?.affected_persons?.map(
                                                                            (person, personIndex) => (
                                                                                <div
                                                                                    className={`project-table__row ${
                                                                                        person?.edit
                                                                                            ? "edit"
                                                                                            : currentItemLevel3
                                                                                                ? "n"
                                                                                                : ""
                                                                                    }`}
                                                                                >
                                                                                    <div className="project-table__td" style={{width: 150}}>
                                                                                        <span>{person?.name}</span>
                                                                                        <div className="project__block-input project-table__field">
                                                                                            <input
                                                                                                maxLength={190}
                                                                                                type="text"
                                                                                                defaultValue={person?.name}
                                                                                                onChange={(e) => {
                                                                                                    setCurrentItemLevel3(
                                                                                                        (old) => ({
                                                                                                            ...old,
                                                                                                            name: e.target.value,
                                                                                                        })
                                                                                                    );
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        className="project-table__td"
                                                                                        style={{ width: "95px" }}
                                                                                    >
                                            <span>
                                              {person?.amount
                                                  ? "$" +
                                                  String(
                                                      person?.amount
                                                  ).replace(
                                                      /(\d)(?=(\d{3})+([^\d]|$))/g,
                                                      "$1,"
                                                  )
                                                  : ""}
                                            </span>
                                                                                        <div className="project__block-input project-table__field">
                                                                                            <input
                                                                                                maxLength={190}
                                                                                                type="number"
                                                                                                defaultValue={person?.amount}
                                                                                                onChange={(e) => {
                                                                                                    setCurrentItemLevel3(
                                                                                                        (old) => ({
                                                                                                            ...old,
                                                                                                            amount: e.target.value,
                                                                                                        })
                                                                                                    );
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="project-table__td Unpaid">
                                                                                        <span>{person?.paid == '0' ? 'Unpaid' : (person?.paid?.charAt(0)?.toUpperCase() + person?.paid?.slice(1)) ?? '-'}</span>
                                                                                        <div className="project-table__field">
                                                                                            <Select
                                                                                                menuPortalTarget={document.body}
                                                                                                styles={({...customStyles,menu: (provided, state = false) => ({
                                                                                                        ...provided,
                                                                                                        height: 170,
                                                                                                    }), menuPortal: base => ({...base, zIndex: 9999})})}
                                                                                                options={[
                                                                                                    {value: 'unpaid', label: translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.titles.unpaid']},
                                                                                                    {value: 'paid', label: translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.titles.paid']},
                                                                                                    {value: 'escrow', label: translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.titles.escrow']}
                                                                                                ]}
                                                                                                value={{value: currentItemLevel3?.paid == '0' ? 'unpaid' : currentItemLevel3?.paid, label: currentItemLevel3?.paid == '0' ? translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.titles.unpaid'] : currentItemLevel3?.paidLabel ? currentItemLevel3?.paidLabel : currentItemLevel3?.paid }}
                                                                                                onChange={(selected) =>
                                                                                                   {
                                                                                                       setCurrentItemLevel3((old) => ({
                                                                                                           ...old,
                                                                                                           paid: selected?.value,
                                                                                                           paidLabel: selected?.label,
                                                                                                       }))
                                                                                                   }
                                                                                                }
                                                                                            />
                                                                                        </div>


                                                                                    </div>
                                                                                    <div className="project-table__td" style={{width: '105px'}}>
                                            <span>
                                              {moment(person?.paid_date).format(
                                                  "DD/MM/YYYY"
                                              ) === "Invalid date" ||
                                              !moment(person?.paid_date).format(
                                                  "DD/MM/YYYY"
                                              )
                                                  ? ""
                                                  : moment(
                                                      person?.paid_date
                                                  ).format("DD/MM/YYYY")}
                                            </span>
                                                                                        <div className="project__block-item-calendar project-table__field">
                                                                                            <DatePicker
                                                                                                portalId="root-portal"
                                                                                                autoComplete="off"
                                                                                                id={`paid_date_${person?.id}`}
                                                                                                placeholderText="dd/mm/yyyy"
                                                                                                dateFormat="dd/MM/yyyy"
                                                                                                selected={
                                                                                                    currentItemLevel3?.paid_date
                                                                                                        ? moment(
                                                                                                            currentItemLevel3?.paid_date
                                                                                                        ).toDate()
                                                                                                        : ""
                                                                                                }
                                                                                                onChange={(date) => {
                                                                                                    setCurrentItemLevel3(
                                                                                                        (old) => ({
                                                                                                            ...old,
                                                                                                            paid_date: date,
                                                                                                        })
                                                                                                    );
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="project-table__td">
                                                                                        <Switch
                                                                                            value={
                                                                                                person?.external_consultant_verified
                                                                                            }
                                                                                            id={personIndex + 2901}
                                                                                            disabled={!person?.edit}
                                                                                            checked={
                                                                                                person?.edit
                                                                                                    ? currentItemLevel3?.external_consultant_verified
                                                                                                    : person?.external_consultant_verified
                                                                                            }
                                                                                            handler={(value) => {
                                                                                                setCurrentItemLevel3((old) => ({
                                                                                                    ...old,
                                                                                                    external_consultant_verified:
                                                                                                        value ? 1 : 0,
                                                                                                }));
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="project-table__td">
                                            <span>
                                              {moment(
                                                  person?.date_verified
                                              ).format("DD/MM/YYYY") ===
                                              "Invalid date" ||
                                              !moment(
                                                  person?.date_verified
                                              ).format("DD/MM/YYYY")
                                                  ? ""
                                                  : moment(
                                                      person?.date_verified
                                                  ).format("DD/MM/YYYY")}
                                            </span>
                                                                                        <div className="project__block-item-calendar project-table__field">
                                                                                            <DatePicker
                                                                                                portalId="root-portal"
                                                                                                autoComplete="off"
                                                                                                id={`date_verified_${person?.id}`}
                                                                                                placeholderText="dd/mm/yyyy"
                                                                                                dateFormat="dd/MM/yyyy"
                                                                                                selected={
                                                                                                    currentItemLevel3?.date_verified
                                                                                                        ? moment(
                                                                                                            currentItemLevel3?.date_verified
                                                                                                        ).toDate()
                                                                                                        : ""
                                                                                                }
                                                                                                onChange={(date) => {
                                                                                                    setCurrentItemLevel3(
                                                                                                        (old) => ({
                                                                                                            ...old,
                                                                                                            date_verified: date,
                                                                                                        })
                                                                                                    );
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="project-table__td">
                                                                                        <Switch
                                                                                            value={person?.completed}
                                                                                            id={personIndex + 5423}
                                                                                            disabled={!person?.edit}
                                                                                            checked={
                                                                                                person?.edit
                                                                                                    ? currentItemLevel3?.completed
                                                                                                    : person?.completed
                                                                                            }
                                                                                            handler={(value) => {
                                                                                                setCurrentItemLevel3((old) => ({
                                                                                                    ...old,
                                                                                                    completed: value ? 1 : 0,
                                                                                                }));
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="project-table__td">
                                                                                        <span>{person?.status}</span>
                                                                                        <div className="project__block-input project-table__field">
                                                                                            <input
                                                                                                maxLength={190}
                                                                                                type="text"
                                                                                                defaultValue={person?.status}
                                                                                                onChange={(e) => {
                                                                                                    setCurrentItemLevel3(
                                                                                                        (old) => ({
                                                                                                            ...old,
                                                                                                            status: e.target.value,
                                                                                                        })
                                                                                                    );
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="project__block-btns">
                                                                                        {currentItemLevel3 ? (
                                                                                            <div
                                                                                                className="project__block-cancel"
                                                                                                onClick={() => {
                                                                                                    const copy = { ...item };
                                                                                                    const updatedTiers =
                                                                                                        copy?.tiers?.map(
                                                                                                            (currentTier) => {
                                                                                                                return {
                                                                                                                    ...currentTier,
                                                                                                                    affected_persons:
                                                                                                                        currentTier?.affected_persons?.filter(
                                                                                                                            (a) =>
                                                                                                                                a.create ===
                                                                                                                                true
                                                                                                                        ).length > 0
                                                                                                                            ? currentTier?.affected_persons?.filter(
                                                                                                                                (a) =>
                                                                                                                                    !a.create
                                                                                                                            )
                                                                                                                            : currentTier?.affected_persons?.map(
                                                                                                                                (a) => {
                                                                                                                                    return {
                                                                                                                                        ...a,
                                                                                                                                        edit: false,
                                                                                                                                    };
                                                                                                                                }
                                                                                                                            ),
                                                                                                                };
                                                                                                            }
                                                                                                        );
                                                                                                    copy.tiers = updatedTiers;
                                                                                                    setCurrentItemLevel3(null);
                                                                                                    setLarpSections((old) => {
                                                                                                        return old?.map((a) => {
                                                                                                            if (a.id === item.id) {
                                                                                                                a.tiers = copy.tiers;
                                                                                                            }
                                                                                                            return a;
                                                                                                        });
                                                                                                    });
                                                                                                }}
                                                                                            >
                                                                                                <img
                                                                                                    src="/assets/icons/close-mark.svg"
                                                                                                    alt="close"
                                                                                                />
                                                                                                {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.actions.cancel']}
                                                                                            </div>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}
                                                                                        {currentItemLevel3 ? (
                                                                                            <button
                                                                                                className="project__block-save"
                                                                                                onClick={() => {
                                                                                                    const send = {};
                                                                                                    if (
                                                                                                        currentItemLevel3?.create
                                                                                                    ) {
                                                                                                        send.tier_id = tier?.id;
                                                                                                    } else {
                                                                                                        send.id = person?.id;
                                                                                                    }
                                                                                                    if (
                                                                                                        currentItemLevel3?.name !==
                                                                                                        person?.name
                                                                                                    ) {
                                                                                                        send.name =
                                                                                                            currentItemLevel3?.name;
                                                                                                    }
                                                                                                    if (
                                                                                                        currentItemLevel3?.amount
                                                                                                    ) {
                                                                                                        send.amount =
                                                                                                            currentItemLevel3?.amount
                                                                                                                ?.toString()
                                                                                                                ?.split(",")
                                                                                                                .join("");
                                                                                                    }
                                                                                                    if (
                                                                                                        currentItemLevel3?.paid !==
                                                                                                        null
                                                                                                    ) {
                                                                                                        send.paid =
                                                                                                            currentItemLevel3?.paid;
                                                                                                    }
                                                                                                    if (
                                                                                                        currentItemLevel3?.paid_date
                                                                                                    ) {
                                                                                                        send.paid_date = moment(
                                                                                                            currentItemLevel3?.paid_date
                                                                                                        ).format("YYYY-MM-DD");
                                                                                                    }
                                                                                                    if (
                                                                                                        currentItemLevel3?.external_consultant_verified !==
                                                                                                        null
                                                                                                    ) {
                                                                                                        send.external_consultant_verified =
                                                                                                            currentItemLevel3?.external_consultant_verified;
                                                                                                    }
                                                                                                    if (
                                                                                                        currentItemLevel3?.date_verified
                                                                                                    ) {
                                                                                                        send.date_verified = moment(
                                                                                                            currentItemLevel3?.date_verified
                                                                                                        ).format("YYYY-MM-DD");
                                                                                                    }
                                                                                                    if (
                                                                                                        currentItemLevel3?.completed !==
                                                                                                        null
                                                                                                    ) {
                                                                                                        send.completed =
                                                                                                            currentItemLevel3?.completed;
                                                                                                    }
                                                                                                    if (
                                                                                                        currentItemLevel3?.status
                                                                                                    ) {
                                                                                                        send.status =
                                                                                                            currentItemLevel3?.status;
                                                                                                    }
                                                                                                    const latestIndex =
                                                                                                        tier?.affected_persons.slice(
                                                                                                            -1
                                                                                                        );

                                                                                                    setLarpAffectedApi(send)
                                                                                                        .unwrap()
                                                                                                        .then((res) => {
                                                                                                            toast.success(res.message)
                                                                                                            setCurrentItemLevel3(
                                                                                                                null
                                                                                                            );
                                                                                                            const copy = { ...item };
                                                                                                            const updatedTiers =
                                                                                                                copy?.tiers?.map(
                                                                                                                    (currentTier) => {
                                                                                                                        if (
                                                                                                                            currentTier?.id ===
                                                                                                                            tier?.id
                                                                                                                        ) {
                                                                                                                            return {
                                                                                                                                ...currentTier,
                                                                                                                                affected_persons:
                                                                                                                                    currentTier?.affected_persons?.map(
                                                                                                                                        (a) => {
                                                                                                                                            if (
                                                                                                                                                a?.id ===
                                                                                                                                                person?.id
                                                                                                                                            ) {
                                                                                                                                                return {
                                                                                                                                                    ...a,
                                                                                                                                                    edit: false,
                                                                                                                                                    create: false,
                                                                                                                                                    ...res?.data,
                                                                                                                                                };
                                                                                                                                            } else {
                                                                                                                                                return {
                                                                                                                                                    ...a,
                                                                                                                                                    edit: false,
                                                                                                                                                    create: false,
                                                                                                                                                };
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    ),
                                                                                                                            };
                                                                                                                        } else {
                                                                                                                            return {
                                                                                                                                ...currentTier,
                                                                                                                            };
                                                                                                                        }
                                                                                                                    }
                                                                                                                );
                                                                                                            copy.tiers = updatedTiers;
                                                                                                            setLarpSections((old) => {
                                                                                                                return old?.map((a) => {
                                                                                                                    if (
                                                                                                                        a.id === item.id
                                                                                                                    ) {
                                                                                                                        a.tiers =
                                                                                                                            copy.tiers;
                                                                                                                    }
                                                                                                                    return a;
                                                                                                                });
                                                                                                            });
                                                                                                        });
                                                                                                }}
                                                                                            >
                                                                                                <img
                                                                                                    src="/assets/icons/save-arrow.svg"
                                                                                                    alt="arrow"
                                                                                                />
                                                                                                {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.actions.save']}
                                                                                            </button>
                                                                                        ) : (
                                                                                            <>
                                                                                                <div
                                                                                                    className="project__block-delete"
                                                                                                    onClick={() => {
                                                                                                        deleteLarpAffectedApi(
                                                                                                            person?.id
                                                                                                        )
                                                                                                            .unwrap()
                                                                                                            .then((res) => {
                                                                                                                const copy = {
                                                                                                                    ...item,
                                                                                                                };
                                                                                                                const updatedTiers =
                                                                                                                    copy?.tiers?.map(
                                                                                                                        (currentTier) => {
                                                                                                                            if (
                                                                                                                                currentTier?.id ===
                                                                                                                                tier?.id
                                                                                                                            ) {
                                                                                                                                return {
                                                                                                                                    ...currentTier,
                                                                                                                                    affected_persons:
                                                                                                                                        currentTier?.affected_persons?.filter(
                                                                                                                                            (a) =>
                                                                                                                                                a.id !==
                                                                                                                                                person?.id
                                                                                                                                        ),
                                                                                                                                };
                                                                                                                            } else {
                                                                                                                                return {
                                                                                                                                    ...currentTier,
                                                                                                                                    affected_persons:
                                                                                                                                        currentTier?.affected_persons?.map(
                                                                                                                                            (a) => {
                                                                                                                                                return {
                                                                                                                                                    ...a,
                                                                                                                                                    edit: false,
                                                                                                                                                };
                                                                                                                                            }
                                                                                                                                        ),
                                                                                                                                };
                                                                                                                            }
                                                                                                                        }
                                                                                                                    );
                                                                                                                copy.tiers =
                                                                                                                    updatedTiers;
                                                                                                                setCurrentItemLevel3(
                                                                                                                    null
                                                                                                                );
                                                                                                                setLarpSections(
                                                                                                                    (old) => {
                                                                                                                        return old?.map(
                                                                                                                            (a) => {
                                                                                                                                if (
                                                                                                                                    a.id ===
                                                                                                                                    item.id
                                                                                                                                ) {
                                                                                                                                    a.tiers =
                                                                                                                                        copy.tiers;
                                                                                                                                }
                                                                                                                                return a;
                                                                                                                            }
                                                                                                                        );
                                                                                                                    }
                                                                                                                );
                                                                                                            });
                                                                                                    }}
                                                                                                >
                                                                                                    <img
                                                                                                        src="/assets/icons/trash-icon.svg"
                                                                                                        alt="arrow"
                                                                                                    />
                                                                                                    {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.actions.delete']}
                                                                                                </div>
                                                                                                <div
                                                                                                    onClick={() => {
                                                                                                        const copy = { ...item };
                                                                                                        const updatedTiers =
                                                                                                            copy?.tiers?.map(
                                                                                                                (currentTier) => {
                                                                                                                    if (
                                                                                                                        currentTier?.id ===
                                                                                                                        tier?.id
                                                                                                                    ) {
                                                                                                                        return {
                                                                                                                            ...currentTier,
                                                                                                                            affected_persons:
                                                                                                                                [
                                                                                                                                    ...currentTier?.affected_persons?.map(
                                                                                                                                        (a) => {
                                                                                                                                            if (
                                                                                                                                                a.id ===
                                                                                                                                                person.id
                                                                                                                                            ) {
                                                                                                                                                return {
                                                                                                                                                    ...a,
                                                                                                                                                    edit: true,
                                                                                                                                                };
                                                                                                                                            } else {
                                                                                                                                                return {
                                                                                                                                                    ...a,
                                                                                                                                                    edit: false,
                                                                                                                                                };
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    ),
                                                                                                                                ],
                                                                                                                        };
                                                                                                                    } else {
                                                                                                                        return {
                                                                                                                            ...currentTier,
                                                                                                                            details: {
                                                                                                                                ...currentTier?.details,
                                                                                                                                edit: false,
                                                                                                                            },
                                                                                                                        };
                                                                                                                    }
                                                                                                                }
                                                                                                            );
                                                                                                        copy.tiers = updatedTiers;
                                                                                                        setCurrentItemLevel3(
                                                                                                            person
                                                                                                        );
                                                                                                        setLarpSections((old) => {
                                                                                                            return old?.map((a) => {
                                                                                                                if (a.id === item.id) {
                                                                                                                    a.tiers = copy.tiers;
                                                                                                                }
                                                                                                                return a;
                                                                                                            });
                                                                                                        });
                                                                                                    }}
                                                                                                    className="project__block-update"
                                                                                                >
                                                                                                    {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.actions.update']}
                                                                                                </div>
                                                                                            </>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        )}
                                                                    </div>
                                                                )}
                                                                <div className="project-table__header grid-s">
                                                                    <div className="project-table__th">
                                                                        {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.verification_reports_submitted_to_adb']}
                                                                    </div>
                                                                    <div className="project-table__th">
                                                                        {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.verification_report_date_submitted']}
                                                                    </div>
                                                                    <div className="project-table__th">
                                                                        {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.verification_report_approved']}
                                                                    </div>
                                                                    <div className="project-table__th">
                                                                        {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.verification_report_date_approved']}
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className={`project-table__row grid-s ${
                                                                        tier?.details?.edit
                                                                            ? "edit"
                                                                            : currentItemLevel2
                                                                                ? "n"
                                                                                : "a"
                                                                    }`}
                                                                >
                                                                    <div className="project-table__td">
                                                                        <Switch
                                                                            value={tier?.details?.report_submitted}
                                                                            id={index + 8913}
                                                                            disabled={!tier?.details?.edit}
                                                                            checked={
                                                                                tier?.details?.edit
                                                                                    ? currentItemLevel2?.report_submitted
                                                                                    : tier?.details?.report_submitted
                                                                            }
                                                                            handler={(value) => {
                                                                                setCurrentItemLevel2((old) => ({
                                                                                    ...old,
                                                                                    report_submitted: value ? 1 : 0,
                                                                                }));
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="project-table__td">
                                    <span>
                                      {moment(
                                          tier?.details.report_date_submitted
                                      ).format("DD/MM/YYYY") ===
                                      "Invalid date" ||
                                      !moment(
                                          tier?.details.report_date_submitted
                                      ).format("DD/MM/YYYY")
                                          ? ""
                                          : moment(
                                              tier?.details.report_date_submitted
                                          ).format("DD/MM/YYYY")}
                                    </span>
                                                                        <div className="project__block-item-calendar project-table__field">
                                                                            <DatePicker
                                                                                portalId="root-portal"
                                                                                autoComplete="off"
                                                                                id={`report_date_submitted_${tier?.id}`}
                                                                                placeholderText="dd/mm/yyyy"
                                                                                dateFormat="dd/MM/yyyy"
                                                                                selected={
                                                                                    currentItemLevel2?.report_date_submitted
                                                                                        ? moment(
                                                                                            currentItemLevel2?.report_date_submitted
                                                                                        ).toDate()
                                                                                        : ""
                                                                                }
                                                                                onChange={(date) => {
                                                                                    setCurrentItemLevel2((old) => ({
                                                                                        ...old,
                                                                                        report_date_submitted: date,
                                                                                    }));
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="project-table__td">
                                                                        <Switch
                                                                            value={tier?.details?.report_approved}
                                                                            id={index + 9912}
                                                                            disabled={!tier?.details?.edit}
                                                                            checked={
                                                                                tier?.details?.edit
                                                                                    ? currentItemLevel2?.report_approved
                                                                                    : tier?.details?.report_approved
                                                                            }
                                                                            handler={(value) => {
                                                                                setCurrentItemLevel2((old) => ({
                                                                                    ...old,
                                                                                    report_approved: value ? 1 : 0,
                                                                                }));
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="project-table__td">
                                    <span>
                                      {moment(
                                          tier?.details?.report_date_approved
                                      ).format("DD/MM/YYYY") ===
                                      "Invalid date" ||
                                      !moment(
                                          tier?.details?.report_date_approved
                                      ).format("DD/MM/YYYY")
                                          ? ""
                                          : moment(
                                              tier?.details?.report_date_approved
                                          ).format("DD/MM/YYYY")}
                                    </span>
                                                                        <div className="project__block-item-calendar project-table__field">
                                                                            <DatePicker
                                                                                portalId="root-portal"
                                                                                autoComplete="off"
                                                                                id={`report_date_approved_${tier?.id}`}
                                                                                placeholderText="dd/mm/yyyy"
                                                                                dateFormat="dd/MM/yyyy"
                                                                                selected={
                                                                                    currentItemLevel2?.report_date_approved
                                                                                        ? moment(
                                                                                            currentItemLevel2?.report_date_approved
                                                                                        ).toDate()
                                                                                        : ""
                                                                                }
                                                                                onChange={(date) => {
                                                                                    setCurrentItemLevel2((old) => ({
                                                                                        ...old,
                                                                                        report_date_approved: date,
                                                                                    }));
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
};
