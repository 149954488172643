import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { CountryModal } from "../../components/CountryModal";
import {
  useCreateCountryMutation,
  useDeleteCountryMutation,
  useLazyGetAllCountriesQuery,
  useSearchCountriesMutation,
  useUpdateCountryMutation,
} from "../../store/service/adbService";
import { setLoader } from "../../store/slice/dashboard";
import { DeleteModal } from "../../components/Project/DeleteModal";
import {Paginate} from "../../components/Paginate";
import useDebounce from "../../global/useDebounce";

export const CountryPage = ({}) => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [deleteModal, setDeleteModal] = useState(null);
  const [currentPage, setCurrentPage] = useState(1)
  const [data, setData] = useState(null)
  const [totalPage, setTotalPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  const [searchCountries] = useSearchCountriesMutation()

  const [getCountries, {
    data: countries,
    isLoading: countriesIsLoading,
  }] = useLazyGetAllCountriesQuery();

  useEffect(() => {
    if (debouncedSearchTerm.length > 0) {
      dispatch(setLoader(true))
      searchCountries(debouncedSearchTerm).unwrap().then(res => {
        dispatch(setLoader(false))
        setData(res?.data)
        setTotalPage(0)
      }).catch(() => {
        dispatch(setLoader(false))
      })
    } else {
      dispatch(setLoader(true))
      getCountries({
        per_page: 10,
        page: 1,
      }).unwrap().then(res => {
        setData(res?.data?.data)
        setTotalPage(res?.data?.last_page)
        dispatch(setLoader(false))
        setCurrentPage(1)
      }).catch(() => {
        dispatch(setLoader(false))
      })
    }
  }, [debouncedSearchTerm])

  const [
    createCountry,
    {
      isSuccess: createCountryIsSuccess,
      isLoading: createCountryIsLoading,
    },
  ] = useCreateCountryMutation();

  const [
    deleteCountry,
    {
      isLoading: deleteCountryIsLoading,
    },
  ] = useDeleteCountryMutation();

  const [
    updateCountry,
    {
      isSuccess: updateCountryIsSuccess,
      isLoading: updateCountryIsLoading,
    },
  ] = useUpdateCountryMutation();

  useEffect(() => {
    getCountries({
      per_page: 10,
      page: currentPage,
    })
  }, [currentPage])

  useEffect(() => {
    if (countries) {
      setData(countries?.data?.data)
      setTotalPage(countries?.data?.last_page)
    }
  }, [countries])

  useEffect(() => {
    setModal(false);
    setCurrentItem(null);
  }, [createCountryIsSuccess, updateCountryIsSuccess]);

  useEffect(() => {
    !modal && setCurrentItem(null);
  }, [modal]);

  useEffect(() => {
    countriesIsLoading ||
    createCountryIsLoading ||
    updateCountryIsLoading ||
    deleteCountryIsLoading
      ? dispatch(setLoader(true))
      : dispatch(setLoader(false));
  }, [
    countriesIsLoading,
    createCountryIsLoading,
    updateCountryIsLoading,
    deleteCountryIsLoading,
  ]);

  return (
    <div className="s-pages">
      {deleteModal?.show && (
        <DeleteModal
          id={deleteModal?.id}
          action={deleteCountry}
          setDeleteModal={setDeleteModal}
        />
      )}
      {modal && (
        <CountryModal
          title="Country"
          setModal={setModal}
          type="Country"
          createHook={createCountry}
          updateHook={updateCountry}
          currentItem={currentItem}
        />
      )}
      <div className="s-page__header">
        <div style={{display: 'flex'}}>
          <h1>Country</h1>
          <div className="search-block">
            <input placeholder="Search..." type="text" className="search" onChange={(e) => {
              setSearchTerm(e.target.value)
            }} />
          </div>
        </div>
        <button onClick={() => setModal(true)}>
          <img src="/assets/icons/plus.svg" alt="plus" />
          <span>Create Country</span>
        </button>
      </div>
      <div className="table">
        <div className="table-header">
          <div className="table-th">Country Name</div>
          <div className="table-th">Region </div>
        </div>
        {data?.length > 0 ?
            <div className="table-body">
              {data?.map((item) => (
                  <div className="table-row" key={item?.id} id={item?.id}>
                    <div className="table-td">{item?.name}</div>
                    <div className="table-td">{item?.region}</div>
                    <div className="table-actions">
                      <div
                          onClick={() => {
                            setDeleteModal({ id: item?.id, show: true });
                            // deleteCountry(item?.id)
                          }}
                          className="table-delete"
                      >
                        <img src="/assets/icons/trash-icon.svg" alt="trash" />
                        <span>Delete</span>
                      </div>
                      <div
                          className="table-edit"
                          onClick={() => {
                            setCurrentItem(item);
                            setModal(true);
                          }}
                      >
                        <img src="/assets/icons/edit-icon-2.svg" alt="edit" />
                        <span>Edit</span>
                      </div>
                    </div>
                  </div>
              ))}
            </div> :
            <div style={{height: '70vh', width: '100%', display: "flex", justifyContent:"center", alignItems: 'center', flexDirection: "column"}}>
              <img src="/assets/images/projects.png" alt=""/>
              <span style={{color: "#767676", fontSize: 16, fontWeight: 500}}>No Countries</span>
            </div>
        }
      </div>
      {(countries?.data?.data?.length > 0 && totalPage > 0) && <Paginate currentPage={currentPage} setCurrentPage={setCurrentPage} pageCount={totalPage}/>}
    </div>
  );
};
