import React from "react";

export default function CustomCheckbox({ id, label, handler, checked }) {
  return (
    <div className="contract__management-fill">
      <input type="checkbox" id={id} onChange={handler} checked={checked} />
      <label htmlFor={id}>
        <div>
          <img src="/assets/icons/checkbox-arrow.svg" alt="marker" />
        </div>
        <span>{label}</span>
      </label>
    </div>
  );
}
