import React, { useState, useEffect } from "react";
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Line,
  ComposedChart,
} from "recharts";

import moment from "moment";
import { setLoader } from "../../store/slice/dashboard";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import axios from "axios";
import useLastPathname from "../../global/useLastPathname";

export const ProgressChart = ({ data, glossaryData, id }) => {
  const lastPathname = useLastPathname();
  const dispatch = useDispatch();
  const location = useLocation();
  const [progressData, setProgressData] = useState([]);
  const [translations, setTranslations] = useState([]);
  moment.locale(lastPathname);

  useEffect(() => {
    axios
        .get(
            `${process.env.REACT_APP_API_URL}/chart/program-progress/${
                id ? id : location?.pathname?.split("/")[2]
            }`,
            {
              headers: {
                "AppLocale": lastPathname
              }
            }
        )
        .then((res) => {
          let response = res?.data?.data?.data;
          setTranslations(res?.data?.data?.translations)
          setProgressData(
              response?.data?.map((el) => ({
                ...el,
                date: el?.date,
                planned_amount:
                el?.planned_amount,
                actual_amount:
                el?.actual_amount,
                cumulative_actual_amount:
                el?.cumulative_actual_amount,
                cumulative_planned_amount:
                el?.cumulative_planned_amount,
                edit: false,
              }))
          );
        });
  }, [id]);


  useEffect(() => {
    const d = () => {
      setProgressData((old) => {
        let data = old?.map((item) => ({...item, edit: false}));
        return data;
      });
      dispatch(setLoader(false));
    };
  },[])


  function formatXAxis(tickItem) {
    return moment(tickItem).format("MMM-YY");
  }

  return (
      <div className="plannedProject">
        <div className="plannedProject__chart">
          <h2>{translations?.['monthly_progress']}</h2>
          {progressData?.length !== 0 ? (
              <ResponsiveContainer width="100%" aspect={3}>
                <ComposedChart
                    width={500}
                    height={300}
                    data={progressData}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                >
                  <CartesianGrid strokeDasharray="0 0" />
                  <XAxis tickFormatter={formatXAxis} fontSize={10} dataKey="date" />
                  <YAxis
                      yAxisId="cumulative"
                      orientation={"right"}
                      domain={[0, 100]}
                      type="number"
                      tickFormatter={(tick) => {
                        return tick ? `${Math.round(tick / 10) * 10}%` : "0%";
                      }}
                      fontSize={12}
                  />
                  <YAxis
                      yAxisId={"amount"}
                      orientation={"left"}
                      domain={[0, (dataMax) => dataMax + Math.round(dataMax / 10)]}
                      type="number"
                      tickFormatter={(tick) => {
                        return `${tick}%`;
                      }}
                      fontSize={12}
                  />
                  <Bar
                      animationDuration={0}
                      yAxisId={"amount"}
                      dataKey="planned_amount"
                      name={translations?.['project_monitoring.program_progress.titles.planned']}
                      fill="#E68484"
                      radius={[10, 10, 0, 0]}
                  />
                  <Bar
                      animationDuration={0}
                      yAxisId={"amount"}
                      dataKey="actual_amount"
                      name={translations?.['project_monitoring.program_progress.titles.actual']}
                      fill="#99BAEB"
                      radius={[10, 10, 0, 0]}
                  />
                  <Line
                      animationDuration={0}
                      yAxisId={"cumulative"}
                      dot={false}
                      strokeWidth={4}
                      strokeLinecap="round"
                      type="monotone"
                      dataKey="cumulative_planned_amount"
                      stroke="#3B7AD9"
                      name={translations?.['project_monitoring.program_progress.titles.cumulative_planned']}
                  />
                  <Line
                      animationDuration={0}
                      yAxisId={"cumulative"}
                      dot={false}
                      strokeWidth={4}
                      strokeLinecap="round"
                      type="monotone"
                      dataKey="cumulative_actual_amount"
                      stroke="#FFD023"
                      name={translations?.['project_monitoring.program_progress.titles.cumulative_actual']}
                  />
                </ComposedChart>
              </ResponsiveContainer>
          ) : (
              <div className="plannedProject__placeholder">
                <img src="/assets/icons/planned_chart.svg" alt="" />
                There is no Data yet
              </div>
          )}
        </div>
      </div>
  );
};
