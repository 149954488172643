import {toast} from "react-toastify";
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import ReportModal from "../../../components/ReportModal";
import {DeleteModal} from "../../../components/Project/DeleteModal";
import CreateReportModal from "../../../components/CreateReportModal";
import GenerateQuarterlyReport from "../../../components/GenerateQuarterlyReport";
import QuarterlyReportPreviewImg from "../../../components/QuarterlyReportPreviewImg";
import {errorHandle} from "../../../global/errorHandle";
import {setLoader} from "../../../store/slice/dashboard";
import {
    useDeleteQuarterlyReportCoverMutation,
    useSubmitQuarterlyReportMutation,
    useLazyGetQuarterlyReportInfoQuery
} from "../../../store/service/adbService";
import './index.scss';

const Overview = ({translations, reportData, fetchReportData, isReportSubmitted, setIsReportSubmitted}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const user = useSelector((state) => state?.adbReducer?.user);
    const [reportModal, setReportModal] = useState(false);
    const [isOpenCreateReportModal, setIsOpenCreateReportModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(null);

    const [deleteQuarterlyReportCover, {
        isLoading: deleteCoverIsLoading,
    }] = useDeleteQuarterlyReportCoverMutation();

    const [submitQuarterlyReport, {
        data: reportSubmitData,
        isLoading: reportSubmitIsLoading,
        error: reportSubmitError,
        isError: reportSubmitIsError,
        isSuccess: reportSubmitIsSuccess,
    }] = useSubmitQuarterlyReportMutation();

    const [getQuarterlyReportInfo, {
        isLoading: reportInfoIsLoading,
        error: reportInfoError,
        isError: reportInfoIsError,
    }] = useLazyGetQuarterlyReportInfoQuery();

    const reportSubmit = () => {
        submitQuarterlyReport(reportData.id)
    }

    const prepareToEditReport = () => {
        getQuarterlyReportInfo(reportData?.quarterly_report_id).then((quarterlyReport=> {
            if (quarterlyReport?.data?.data) {
                localStorage.removeItem("create-report-data");
                localStorage.setItem("edit-report-data", JSON.stringify(quarterlyReport?.data?.data));
                navigate(`/edit-quarterly-data/step-1`, {state: {id: reportData.id, prevLocation: location?.pathname}})
            }
        }))
    }

    const handleDeleteCover = () => {
        deleteQuarterlyReportCover(reportData?.id).unwrap()
            .then(() => {
                fetchReportData(reportData?.id)
            })
    }

    useEffect(()=>{
        if (reportSubmitIsSuccess) {
            toast.success(reportSubmitData.message)
            setIsReportSubmitted(reportSubmitData?.data?.submitted)
            setReportModal(false)
        }
    }, [reportSubmitData, reportSubmitIsSuccess]);

    useEffect(() => {
        reportSubmitIsLoading || deleteCoverIsLoading || reportInfoIsLoading?
            dispatch(setLoader(true)) :
            dispatch(setLoader(false))
    }, [reportSubmitIsLoading, deleteCoverIsLoading, reportInfoIsLoading]);

    useEffect(() => {
        if (reportSubmitIsError){
            toast.error(reportSubmitError?.data?.message)
            errorHandle(reportSubmitError);
        }
        if (reportInfoIsError){
            toast.error(reportInfoError?.data?.message)
            errorHandle(reportInfoError);
        }
    },[reportSubmitError, reportSubmitIsError, reportInfoError])

    return (
        <section className="erOverview" id="overview">
            {reportModal && (
                <ReportModal
                    translations={translations}
                    reportSubmit={reportSubmit}
                    close={() => setReportModal(!reportModal)}
                />
            )}
            {isOpenCreateReportModal && (
                <CreateReportModal
                    reportId={reportData?.id}
                    closeModal={setIsOpenCreateReportModal}
                    title="Create new report"
                    cancelButton={translations?.['index.index.actions.cancel']}
                    confirmButton={translations?.['index.index.actions.confirm']}
                    reportNumberTitle={translations?.['index.index.titles.report_number']}
                    reportDateTitle={translations?.['index.index.titles.report_date']}
                    reportNumberPlaceholder={translations?.['index.index.placeholder.text']}
                    datePickerPlaceholder={translations?.['index.index.placeholder.choose_date']}
                />
            )}
            {deleteModal?.show && (
              <DeleteModal
                action={handleDeleteCover}
                id={reportData?.id}
                setDeleteModal={setDeleteModal}
                isPhoto={true}
                cencelText={translations?.['main.main.actions.cancel'] || translations?.['index.index.actions.cancel']}
                deleteText={translations?.['main.main.actions.delete'] || translations?.['index.index.actions.delete']}
                modalTitle={translations?.['main.main.headers.confirm_your_action'] || translations?.['index.index.headers.confirm_your_action']}
                clarifyingText={translations?.['main.main.titles.you_are_going_to_delete_this_row'] || translations?.['index.index.titles.you_are_going_to_delete_this_photo']}
              />
            )}
            <QuarterlyReportPreviewImg
                reportData={reportData}
                fetchReportData={fetchReportData}
                translations={translations}
                setDeleteModal={setDeleteModal} />
            <div className="erOverview__infoContainer">
                <div className="erOverview__header">
                    <h2>
                        {reportData?.report_name}
                    </h2>
                    {!isReportSubmitted &&
                        <button
                            onClick={() => prepareToEditReport()}
                        >
                            <img src="/assets/icons/edit.svg" alt="edit icom"/>
                            <span>
                                {translations?.['index.index.actions.edit_details']}
                            </span>
                        </button>
                    }
                </div>
                <ul>
                    <li>
                        {translations?.['index.index.titles.grant_no']} {reportData?.loan_grant_number}
                    </li>
                </ul>
                <div className="erOverview__departmentInfo">
                    <img src="/assets/icons/building.svg" alt="department icon" />
                    <p>
                        {reportData?.implementing_agency}
                    </p>
                </div>
                <div className="erOverview__timeSchedular">
                    <button  className="erOverview__timeSchedular__quarter">
                        {reportData?.report_date}
                        <img
                            src="/assets/icons/calendar.svg" alt="calendar"
                        />
                    </button>
                    <button className="erOverview__timeSchedular__quarter reportNumber" >
                      <span> {reportData?.report_number ? reportData?.report_number : ''}</span>
                        <img src="/assets/icons/oclock.svg" alt="calendar"/>
                        {reportData?.report_number && reportData?.report_number?.length> 20 && <pre className="erOverview__timeSchedular__quarter__tooltip">
                            {reportData?.report_number}
                        </pre>}
                    </button>
                </div>
                <div className="erOverview__buttons">
                    <button
                        onClick={() => {
                            navigate(`/quarterly-online-view/${reportData.id}`, {state: {id: reportData.id, report_id: reportData?.quarterly_report_id, prevLocation: location?.pathname}})
                        }}
                    >
                        <img src="/assets/icons/eye.svg" alt="view icon"/>
                        {translations?.['index.index.actions.view_report']}
                    </button>
                    {!isReportSubmitted && user?.permissions?.submit_quarterly_report ?
                        <button onClick={() => setReportModal(true)}>
                            <img src="/assets/icons/submit-report.svg" alt="submit report"/>
                            {translations?.['index.index.actions.submit_report']}
                        </button> :
                        <button onClick={()=>setIsOpenCreateReportModal(true)}>
                            <img src="/assets/icons/add-list.svg" alt="create report"/>
                            {translations?.['index.index.actions.create_new_report']}
                        </button>
                    }
                </div>
               <GenerateQuarterlyReport reportData={reportData} translations={translations}/>
            </div>

        </section>
    )
}
export default Overview;
//report_user@adb.com