import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { useErrorHandler } from "../../global/ErrorsHandler";
import {useGetAllRegionsQuery, useLazyGetAllRegionsQuery} from "../../store/service/adbService";

const customStyles = {
  menu: (provided, state = false) => ({
    ...provided,
    width: "100%",
    color: state.selectProps.menuColor,
    cursor: "pointer",
  }),

  control: (_, { selectProps: { width } }) => ({
    width: "100%",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    display: "flex",
    marginTop: 8,
    background: "#FFFFFF",
    height: 58,
    borderRadius: 8,
    cursor: "pointer",
  }),
};

export const CountryModal = ({
  title,
  createHook,
  updateHook,
  setModal,
  currentItem,
}) => {
  const [state, setState] = useState(
    currentItem ?? {
      name: null,
      region_id: null,
      code: null,
    }
  );

  const [selectedValue, setSelectedValue] = useState(null);
  const [regionsOptions, setRegionsOptions] = useState(null);

  const [getRegions, {
    data: regions,
    isError: regionsIsError,
    isLoading: regionsIsLoading,
    isSuccess: regionsIsSuccess,
    error: regionsError,
  }] = useLazyGetAllRegionsQuery();


  useEffect(() => {
    getRegions({
      per_page: 1000,
    })
  }, [])


  const [errorsHandler, setErrorsHandler] = useErrorHandler([regionsError]);

  useEffect(() => {
    if (regions?.data?.data) {
      let data = regions?.data?.data?.map((item) => ({
        label: item?.name,
        value: item?.id,
      }));
      setRegionsOptions(data);
    }
  }, [regions]);

  useEffect(() => {
    if (currentItem && regions) {
      let data = regions?.data?.data?.filter((item) => {
        if (item?.name === currentItem?.region) {
          return item;
        }
      })[0];
      setSelectedValue({ label: data?.name, value: data?.id });
    }
  }, [currentItem, regions]);
  const inputHandler = (e) => {
    setState((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };
  const submitHandler = (e) => {
    e.preventDefault();
    let s = [];
    for (let key in state) {
      if (!state[key] || state[key] === "") {
        s.push("false");
        toast.error("Please enter " + key?.split('_')?.join(' '), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    }

    if (s.length === 0) {
      currentItem
        ? updateHook({
            id: currentItem?.id,
            body: {
              name: state?.name,
              region_id: state?.region_id,
              code: state?.code,
            },
          })
        : createHook(state);
    }
  };

  return (
    <div className="s-page__modal">
      <div className="s-page__modal-content">
        <div className="s-page__modal-header">
          <div className="s-page__modal-title">
            {currentItem ? "Update " + title : "Create " + title}
          </div>
          <div className="s-page__modal-close" onClick={() => setModal(false)}>
            <img src="/assets/icons/close-mark.svg" alt="close" />
          </div>
        </div>
        <form
          action="#"
          onSubmit={submitHandler}
          className="s-page__modal-form"
        >
          <div className="s-page__modal-fields">
            <div className="s-page__modal-field">
              <label htmlFor="id">{"Country Name"}</label>
              <input
                id="name"
                type="text"
                value={state?.name ?? ""}
                placeholder="Enter name"
                onChange={inputHandler}
                maxLength={190}
              />
            </div>
            <div className="s-page__modal-field">
              <label htmlFor="id">{"Country Code"}</label>
              <input
                id="code"
                type="text"
                value={state?.code ?? ""}
                placeholder="Enter code"
                maxLength={190}
                onChange={inputHandler}
              />
            </div>
            <div className="s-page__modal-field">
              <label htmlFor="region">Select Region</label>
              <div className="s-page__modal-select">
                <Select
                    menuPortalTarget={document.body}
                    styles={({...customStyles, menuPortal: base => ({...base, zIndex: 9999})})}
                  options={regionsOptions}
                  value={selectedValue}
                  onChange={(selected) => {
                    setState((prev) => ({
                      ...prev,
                      region_id: selected?.value,
                    }));
                    setSelectedValue(selected);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="s-page__modal-button">
            <button>{currentItem ? "Update" : "Create"}</button>
          </div>
        </form>
      </div>
      <div className="s-page__modal-bg" onClick={() => setModal(false)}></div>
    </div>
  );
};
