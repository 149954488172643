import React, { useEffect, useState } from 'react'

export default function TabComponent({type, count, percent, hasDollar = true, title}) {

    const [titleText, setTitleText] = useState('')

    useEffect(() => {
        if(title){
            setTitleText(title)
        }
    }, [title])

    return (
        <div className="larp-db__tab">
            <div className="larp-db__tab-left">
                <div className="larp-db__tab-title">{titleText}</div>
                <div className="larp-db__tab-value">
                    <div className="larp-db__tab-cash">{hasDollar ? '$' : ''}{count}</div>
                    {hasDollar && <div className="larp-db__tab-dot"></div>}
                    {hasDollar && <div className="larp-db__tab-percent">{ percent ? percent : 0}</div>}
                </div>
            </div>
            <div className="larp-db__tab-right">
                <img src={`/assets/icons/larp-${type}-icon.svg`} alt="icon" />
            </div>
        </div>
    )
}
