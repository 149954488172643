import axios from "axios";
import {useLocation} from "react-router-dom";
import React, {useEffect, useState} from 'react';
import LineChartWithXAxisPadding from "../../components/LineChartWithXAxisPadding";
import useLastPathname from "../../global/useLastPathname";

const QuarterPaymentsChart = ({reportId}) => {
    const location = useLocation();
    const lastPathname = useLastPathname();
    const [progressData, setProgressData] = useState([]);
    const [docTranslations, setDocTranslations] = useState([]);



    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/chart/quarter-payments/${reportId ? reportId : location?.pathname?.split('/')[2]}`, {
            headers: {
                "AppLocale": lastPathname
            }
        })
            .then((res) => {
                let response = res?.data?.data?.data;
                setDocTranslations(res?.data?.data?.translations)
                setProgressData(response)
            })

    }, [reportId])
    return(
        <div className="listOfTable__subSections">
            <div className="plannedProject__chart" style={{marginTop: 0}}>
                <LineChartWithXAxisPadding
                    charData={progressData}
                    translations={docTranslations}
                    tooltipSymbolBeforeValue={'$'}
                />
            </div>
        </div>
    )
}

export default QuarterPaymentsChart;