import React, { useEffect, useState } from 'react'

export default function LarpTableComponent({datas, openTier, openSection, translations}) {
    const [data, setData] = useState([])

    useEffect(() => {
        if (datas) {
            const newData = datas?.map(item => {
                const foundation = item?.tiers?.filter(elem => elem?.tier === translations?.['titles.foundation'])[0];
                const towerErection = item?.tiers?.filter(elem => elem?.tier === translations?.['titles.tower_erection'])[0];
                const lineStringing = item?.tiers?.filter(elem => elem?.tier === translations?.['titles.line_stringing'])[0];
                return { ...item, showTooltip: false, tiers: [foundation, towerErection, lineStringing ] };
            });

            let convertedData = convertHeight(newData)
            setData(convertedData)
        }
    }, [datas, translations]);

    const filterAffectedPersons = (elem, item, type) => {
        const s = elem?.affected_persons?.filter((person) => person?.payment_status === type)
        openTier({...elem, affected_persons: s, section: item?.section, report_date: item?.report_date})
    }

    const borderRadius = (type, elem) => {
        const {total_paid, total_unpaid, total_escrow} = elem
        if (type === 'paid') {
            if (total_escrow == 0 && total_unpaid == 0) {
                return {borderTopLeftRadius: 4, borderTopRightRadius: 4, borderBottomLeftRadius: 4, borderBottomRightRadius: 4}
            }
            if ((total_escrow == 0 && total_paid != 0 && total_unpaid != 0) || (total_escrow != 0 && total_paid != 0 && total_unpaid != 0) || (total_escrow != 0 && total_paid != 0 && total_unpaid == 0)) {
                return {borderBottomLeftRadius: 4, borderBottomRightRadius: 4}
            }
        }
        if (type === 'escrow') {
            if (total_paid == 0 && total_unpaid == 0) {
                return {borderTopLeftRadius: 4, borderTopRightRadius: 4, borderBottomLeftRadius: 4, borderBottomRightRadius: 4}
            }
            if (total_escrow !== 0 && total_paid != 0 && total_unpaid == 0) {
                return {borderTopLeftRadius: 4, borderTopRightRadius: 4}
            }
            if (total_escrow !== 0 && total_paid == 0 && total_unpaid != 0) {
                return {borderBottomLeftRadius: 4, borderBottomRightRadius: 4}
            }
        }

        if (type === 'unpaid') {
            if (total_paid == 0 && total_escrow == 0) {
                return {borderTopLeftRadius: 4, borderTopRightRadius: 4, borderBottomLeftRadius: 4, borderBottomRightRadius: 4}
            }
            if ((total_paid != 0 && total_escrow == 0) || (total_paid != 0 && total_escrow != 0) || (total_paid == 0 && total_escrow != 0)) {
                return {borderTopLeftRadius: 4, borderTopRightRadius: 4}
            }
        }
    }

    const convertHeight = (s) => {
        const a = s.map(item => {
            const b = { ...item };
            b.tiers = b.tiers.map(elem => {
                const unpaid = percentToInt(elem?.unpaid);
                const escrow = percentToInt(elem?.escrow);
                const paid = percentToInt(elem?.paid);
                const smallest = findSmallestNonZeroItem([{value: unpaid, label: 'unpaid'}, {value: escrow, label: 'escrow'}, {value: paid, label: 'paid'}], 'value')
                const maxNumber = findMaxNonZeroItem([{value: unpaid, label: 'unpaid'}, {value: escrow, label: 'escrow'}, {value: paid, label: 'paid'}], 'value');
                let height_unpaid = `${unpaid}%` ;
                let height_escrow = `${escrow}%` ;
                let height_paid = `${paid}%`;

                if (+maxNumber?.value > 80) {
                    if (maxNumber.label === 'unpaid') {
                        height_unpaid = escrow == 0 && paid == 0 ? '100%' : (escrow == 0 || paid == 0) ? '90%' : `80%`
                        height_escrow = escrow == 0 ? '0%' : `10%`
                        height_paid = paid == 0 ? '0%' : `10%`
                        return { ...elem, height_unpaid, height_escrow, height_paid};
                    } else if (maxNumber.label === 'escrow') {
                        height_unpaid = unpaid == 0 ? '0%' : `10%`
                        height_escrow = unpaid == 0 && paid == 0 ? '100%' : (unpaid == 0 || paid == 0) ? '90%' : `80%`
                        height_paid = paid == 0 ? '0%' : `10%`
                        return { ...elem, height_unpaid, height_escrow, height_paid};
                    } else {
                        height_unpaid = unpaid == 0 ? '0%' : `10%`
                        height_escrow = escrow == 0 ? '0%' : `10%`
                        height_paid = unpaid == 0 && escrow == 0 ? '100%' : (unpaid == 0 || escrow == 0) ? '90%' : `80%`;
                        return { ...elem, height_unpaid, height_escrow, height_paid};
                    }
                }

                let count = 0;

                if (smallest?.value < 11 && smallest?.value !== null) {
                    count = (smallest?.value - 11) * -1;
                    if (smallest.label === 'unpaid') {
                        height_unpaid = `10%`
                    } else if (smallest.label === 'escrow') {
                        height_escrow = `10%`
                    } else {
                        height_paid = `10%`
                    }

                    if (maxNumber.label === 'unpaid') {
                        height_unpaid = `${maxNumber.value - count}%`
                    } else if (maxNumber.label === 'escrow') {
                        height_escrow = `${maxNumber.value - count}%`
                    } else {
                        height_paid = `${maxNumber.value - count}%`
                    }
                }
                return { ...elem, height_unpaid, height_escrow, height_paid};
            });
            return b;
        });

        return a
    }

    function findMaxNonZeroItem(arr, key) {
        if (!arr.length) {
            return null;
        }

        const nonZeroItems = arr.filter(item => item[key] !== 0);

        if (!nonZeroItems.length) {
            return null;
        }

        let MaxtNonZeroItem = nonZeroItems[0];

        for (let i = 1; i < nonZeroItems.length; i++) {
            if (nonZeroItems[i][key] > MaxtNonZeroItem[key]) {
                MaxtNonZeroItem = nonZeroItems[i];
            }
        }

        return MaxtNonZeroItem;
    }

    const percentToInt = (percent) => {
        if (percent != 0 || percent != '0%') {
            return +String(percent).replace('%', '')
        }
        return 0
    }

    function findSmallestNonZeroItem(arr, key) {
        if (!arr.length) {
            return null;
        }

        // Filter out objects where the value is zero
        const nonZeroItems = arr.filter(item => item[key] !== 0);

        if (!nonZeroItems.length) {
            return null;
        }

        // Find the object with the smallest non-zero value
        let smallestNonZeroItem = nonZeroItems[0];

        for (let i = 1; i < nonZeroItems.length; i++) {
            if (nonZeroItems[i][key] < smallestNonZeroItem[key]) {
                smallestNonZeroItem = nonZeroItems[i];
            }
        }

        return smallestNonZeroItem;
    }

    const heightPaidWithEscrow = (elem1, elem2) => {
        // return ((+elem1?.replace('%', '') + +elem2?.replace('%', '')) * 197 / 100 - 22 - 7) / 2 + - 11 + 'px'
        let a = +elem1?.replace('%', '');
        let b = +elem2?.replace('%', '');
        return ((((a + b) * 197) / 100) / 2) - 18 - 10 + 'px'
    }

    const centerHeightPaidWithEscrow = (elem1, elem2) => {
        let a = +elem1?.replace('%', '');
        let b = +elem2?.replace('%', '');
        return (((a + b) * 197) / 100) + 'px'
    }

    return (
        <div className="larp-db__table">
            <div className="larp-db__row">
                <div className="larp-db__th">
                    {translations?.['titles.foundation']}
                </div>
                <div className="larp-db__th">
                    {translations?.['titles.tower_erection']}
                </div>
                <div className="larp-db__th">
                    {translations?.['titles.line_stringing']}
                </div>
                <div className="larp-db__th end"></div>
            </div>

            <div className="larp-db__table-scroll">
                {data?.map((item, index) => (
                    <div className="larp-db__row" key={index}>
                        {item?.tiers?.map((elem, i) => (
                            <div className="larp-db__td" key={i}>
                                <div className="larp-db__td-radius">
                                    {elem?.unpaid !== '0%' && (
                                        <div className={`larp-db__chart full unpaid ${elem?.unpaid == '100%' ? 'gray' : ''}`} onClick={() => {filterAffectedPersons(elem, item, translations?.['titles.unpaid'])}} style={{height: elem?.height_unpaid, ...borderRadius('unpaid', elem)}}>{elem?.unpaid}</div>
                                    )}
                                    {elem?.escrow !== '0%' && (
                                        <div className="larp-db__chart full escrowed" onClick={() => {filterAffectedPersons(elem, item, translations?.['titles.escrow'])}} style={{height: elem?.height_escrow, ...borderRadius('escrow', elem)}}>
                                            {elem?.escrow}
                                            {elem?.escrow !== '100%' && elem?.total_paid !== 0 && (
                                                <div className="larp-db__top-arrow">
                                                    <img src="/assets/icons/larp-end-arrow.svg" alt="arrow" className='end' />
                                                    <span style={{height: heightPaidWithEscrow(elem?.height_paid, elem?.height_escrow)}}></span>
                                                    <img src="/assets/icons/larp-start-arrow.svg" alt="arrow" className='start' />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {elem?.paid !== '0%' && (
                                        <div className="larp-db__chart full paid" onClick={() => {filterAffectedPersons(elem, item, translations?.['titles.paid'])}} style={{height: elem?.height_paid, ...borderRadius('paid', elem)}}>
                                            {elem?.paid}
                                            {elem?.paid !== '100%' && elem?.total_escrow != 0 && (
                                                <div className="larp-db__top-arrow">
                                                    <img src="/assets/icons/larp-start-arrow.svg" alt="arrow" className='start' />
                                                    {/* <span style={{height: `calc(((${elem?.height_paid} + ${elem?.height_escrow}) / 2) - 22px - 7px)`}}></span> */}
                                                    <span style={{height: heightPaidWithEscrow(elem?.height_paid, elem?.height_escrow)}}></span>
                                                    <img src="/assets/icons/larp-end-arrow.svg" alt="arrow" className='end' />
                                                </div>
                                            )}
                                        </div>
                                    )}


                                    {elem?.escrow === '0%' && elem?.paid === '0%' && elem?.unpaid === 0 && (
                                        <div className="larp-db__chart full gray" style={{height: '100%'}}>0%</div>
                                    )}
                                    {elem?.total_escrow != 0 && elem?.total_paid != 0 && (
                                        // <div className="larp-db__chart-percent" style={{height: `calc(((${elem?.height_paid} + ${elem?.height_escrow}))`, transform: elem?.total_percent !== '100%' ? 'translateX(60px)' : +elem?.total_percent.replace('%', '') < 10 ? 'translateX(65px)' : 'translateX(58px)'}}>{elem?.total_percent}</div>
                                        <div className="larp-db__chart-percent" style={{height: centerHeightPaidWithEscrow(elem?.height_paid, elem?.height_escrow)}}>
                                            <span>{elem?.total_percent}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                        <div className="larp-db__td end" onClick={() => {
                            openSection(item)
                        }} onMouseEnter={() => {
                            setData(old => {
                                return old?.map(el => {
                                    if (el?.section === item?.section && el?.description === item?.description) {
                                        el.showTooltip = true;
                                    } else {
                                        el.showTooltip = false
                                    }
                                    return el
                                })
                            })
                        }}
                             onMouseLeave={() => {
                                 setData(old => {
                                     return old?.map(el => {
                                         el.showTooltip = false
                                         return el
                                     })
                                 })
                             }}
                        >
                            {item?.showTooltip ?
                                <span className={item?.description?.length > 20 ? "lengthy" : "not-lengthy"}>
                {item?.description}
              </span> : null }
                            <div className="larp-db__td-name">
                                { item?.section }</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
