import React, {useEffect, useState} from 'react';
import useDebounce from "../../global/useDebounce";
import {useLazyGetProjectDataInputQuery} from "../../store/service/adbService";
import Table from "./Table";
import Toolbar from "./Toolbar";

const ProjectDataInputPage = ({translations, eventOption,tablesOption, searchPermitsPlaceholder}) => {
    const [event, setEvent] = useState(null);
    const [table, setTable] = useState(null);
    const [selectOptions, setSelectOptions] = useState(null);
    const [selectOptions2, setSelectOptions2] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchByTable, setSearchByTable] = useState('');
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    const debouncedSearchTerm = useDebounce(searchTerm, 500);
    const debouncedSearchByPermits = useDebounce(searchByTable, 500);

    useEffect(()=>{
        if (eventOption)
        {
            setSelectOptions(Object.keys(eventOption)?.map((key)=>({
                value: key,
                label: eventOption[key],
            })))
        }
        if (tablesOption)
        {
            setSelectOptions2(Object.keys(tablesOption)?.map((key)=>({
                value: key,
                label: tablesOption[key],
            })))
        }
    },[eventOption, tablesOption])

    const tableHeader = [
        translations["report_data_change.index.table_headers.first_name"],
        translations["report_data_change.index.table_headers.last_name"],
        translations["report_data_change.index.table_headers.company_name"],
        translations["report_data_change.index.table_headers.timestamp"],
        translations["report_data_change.index.table_headers.location"],
        translations["report_data_change.index.table_headers.device"],
        translations["report_data_change.index.table_headers.project_name"],
        translations["report_data_change.index.table_headers.contract_number"],
        translations["report_data_change.index.table_headers.month"],
        translations["report_data_change.index.table_headers.event_type"],
        translations["report_data_change.index.table_headers.table"],
        translations["report_data_change.index.table_headers.record"],
        translations["report_data_change.index.table_headers.field_identifier"],
        translations["report_data_change.index.table_headers.previous"],
        translations["report_data_change.index.table_headers.current"],
    ];
    const dataFetcher = useLazyGetProjectDataInputQuery;
    const exportEndpoint =`/audit-log/report-data-change/export/csv`

    return (
        <>
            <Toolbar
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                searchByTable={searchByTable}
                setSearchByTable={setSearchByTable}
                startDate={startDate}
                endDate={endDate}
                setDateRange={setDateRange}
                translations={translations}
                event={event}
                setEvent={setEvent}
                table={table}
                setTable={setTable}
                exportEndpoint={exportEndpoint}
                selectOptions={selectOptions}
                secondSelectOptions={selectOptions2}
                isSearchPermits={true}
                isHasSecondSelect={true}
                debouncedSearchTerm={debouncedSearchTerm}
                debouncedSearchByPermits={debouncedSearchByPermits}
                exportFileName='report-data-change'
                selectTitle={translations['report_data_change.index.actions.event_type']}
                secondSelectTitle={translations['report_data_change.index.actions.table']}
                searchPermitsPlaceholder={translations['report_data_change.index.actions.project_name']}
                searchPlaceholder={translations['report_data_change.index.actions.search_by_name_company']}
            />
            <Table
                event={event}
                table={table}
                dateRange={dateRange}
                tableHeader={tableHeader}
                dataFetcher={dataFetcher}
                debouncedSearchTerm={debouncedSearchTerm}
                debouncedSearchByPermits={debouncedSearchByPermits}
                emptyDataText={"No Project Data"}
            />
        </>
    );
}

export default ProjectDataInputPage;
