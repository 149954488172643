import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import isEmpty from 'lodash/isEmpty';
import AxelInput from '../../../UI/AxelInput';
import { errorHandle } from '../../../global/errorHandle';
import { setLoader } from '../../../store/slice/dashboard';
import EditorForTableField from "../../../components/EditorConvertToHTML/EditorForTableField";

const ItemEdit = ({
                      headerRef,
                      itemData,
                      reportId,
                      setIsOpenEdit,
                      getItems,
                      cancelTranslation,
                      saveTranslation,
                      useCreateItemMutation,
                      useUpdateItemMutation,
                      fields,
                      cellWidths,
                      htmlEditFieldKeys,
                      requestUrl,
                      placeholders,
                  }) => {
    const dispatch = useDispatch();
    const rowRef = useRef();
    const [state, setState] = useState(
        fields.reduce((acc, field) => {
            acc[field] = itemData?.[field] ?? '';
            return acc;
        }, {})
    );

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [createItem, {
        data: createItemData,
        isSuccess: createItemIsSuccess,
        isLoading: createItemIsLoading,
        isFetching: createItemIsFetching,
        error: createItemError
    }] = useCreateItemMutation();

    const [updateItem, {
        data: updateItemData,
        isSuccess: updateItemIsSuccess,
        isLoading: updateItemIsLoading,
        isFetching: updateItemIsFetching,
        error: updateItemError
    }] = useUpdateItemMutation();

    const inputHandler = (e) => {
        setState((old) => ({ ...old, [e.target.id]: e.target.value }));
    };

    const handleEditor = (value, fieldName) => {
        setState((old) => ({ ...old, [fieldName]: value }));
    }

    const handleMouseLeave = (event) => {
        if (!rowRef.current || !rowRef.current.contains(event.target)) {
            setIsOpenEdit(false);
        }
    };

    const submitHandler = (e) => {
        e.preventDefault();
        if (isSubmitting) return;
        setIsSubmitting(true);

        const body = fields.reduce((acc, field) => {
            acc[field] = state[field];
            return acc;
        }, {});

        const formData = new FormData();
        let isHaveEmptyField = false;

        for (let key in body) {
            if (!body[key] || body[key] === 'Invalid date' || body[key] === '') {
                toast.error(`Please enter ${key.split('_').join(' ')}`, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
                isHaveEmptyField = true;
            }
        }

        Object.keys(body).forEach((key) => formData.append(key, body[key]));

        if (!isHaveEmptyField) {
            formData.append('report_id', reportId);
            if (isEmpty(itemData)) {
                createItem(requestUrl ? { body: formData, url: requestUrl } : formData);
            } else {
                updateItem({ id: itemData.id, body: formData, url: requestUrl });
            }
        } else {
            setIsSubmitting(false);
        }
    };

    useEffect(() => {
        if (updateItemIsSuccess || createItemIsSuccess) {
            toast.success(updateItemIsSuccess ? updateItemData.message : createItemData.message);
            getItems();
            setIsOpenEdit(false);
            setIsSubmitting(false);  // Reset submit state after success
        }
    }, [createItemIsSuccess, updateItemIsSuccess, createItemData, updateItemData]);

    useEffect(() => {
        if (createItemError || updateItemError) {
            errorHandle(createItemError || updateItemError);
            setIsSubmitting(false);  // Reset submit state after error
        }
    }, [createItemError, updateItemError]);

    useEffect(() => {
         createItemIsFetching || updateItemIsFetching
            ? dispatch(setLoader(true))
            : dispatch(setLoader(false));
    }, [createItemIsLoading, updateItemIsLoading, createItemIsFetching, updateItemIsFetching]);

    useEffect(() => {
        document.addEventListener('mousedown', handleMouseLeave);
        document.addEventListener('touchstart', handleMouseLeave);

        return () => {
            document.removeEventListener('mousedown', handleMouseLeave);
            document.removeEventListener('touchstart', handleMouseLeave);
        };
    }, [headerRef]);

    return (
        <div className="editReport__table__row editReport__table__editRow editFinancing" ref={rowRef} style={{ alignItems: 'stretch' }}>
            {fields.map((field, index) => (
                (htmlEditFieldKeys && htmlEditFieldKeys.includes(field)) ? (
                    <EditorForTableField
                        key={field}
                        value={state[field] ?? ''}
                        width={(cellWidths?.[index] - 25)}
                        onChange={(el) => handleEditor(el, field)}
                    />
                ) : (
                    <AxelInput
                        key={field}
                        id={field}
                        name={field}
                        className="editReport__input"
                        placeholder={placeholders?.[index]}
                        type={field === 'output_no' ? 'number' : 'text'}
                        handler={inputHandler}
                        value={state[field] ?? ''}
                        style={{ width: (cellWidths?.[index] - 25) }}
                    />
                )
            ))}
            <div className="editReport__table__settingsButtons">
                <button
                    className="editReport__table__cancelBtn"
                    onClick={() => setIsOpenEdit(false)}
                >
                    {cancelTranslation}
                </button>
                <button
                    className="editReport__table__saveBtn"
                    type="submit"
                    onClick={(e)=>submitHandler(e)}
                    disabled={isSubmitting}
                >
                    {saveTranslation}
                </button>
            </div>
        </div>
    );
};

export default ItemEdit;
