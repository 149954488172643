import React from 'react';

function QuarterlySidebar({sections_title, refs, onLinkClick, activeSection}) {
	

	return (
			<div className="project__sidebar online__sidebar">
					{sections_title?.map(section => {
						if (section.key !== 'documents') {
							return (
								<div className={`project__sidebar-item ${activeSection === section.key ? 'active' : ''}`} data-to-scrollspy-id={section?.key} key={`link-${section.key}`} id={section.key}
									 onClick={() => onLinkClick(section.key)}>
									{section?.value}
								</div>
							)
						}
					})}
			</div>
	);
}

export default QuarterlySidebar;
