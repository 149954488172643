import React, {useEffect, useRef} from "react";
import {useState} from "react";
import {useDispatch} from "react-redux";
import {
    useUpdateUsersMutation,
    useDeleteUsersMutation,
    useCreateUsersMutation,
    useLazyGetUsersQuery,
    useLazyGetAllProjectsQuery,
    useGetRolesQuery,
    useSearchUsersMutation,
    useUpdateUsersMFAMutation,
} from "../../store/service/adbService";
import {setLoader} from "../../store/slice/dashboard";
import {DeleteModal} from "../../components/Project/DeleteModal";
import UsersModal from "../../components/UsersModal";
import {Paginate} from "../../components/Paginate";
import useDebounce from "../../global/useDebounce";
import AxelCheckbox from "../../UI/AxelCheckbox";
import {errorHandle} from "../../global/errorHandle";

export const UsersPage = () => {
    const hasBeenRendered = useRef(null)
    const dispatch = useDispatch();
    const [modalShow, setModalShow] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [checkboxStatus, setCheckboxStatus] = useState(false);
    const [deleteModal, setDeleteModal] = useState(null);
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPage, setTotalPage] = useState(0)
     const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchTerm = useDebounce(searchTerm, 500)
    const [data, setData] = useState(null)

    const [searchUsers] = useSearchUsersMutation()

    const [getUsers, {
        data: users,
        isLoading: usersIsLoading,
    }] = useLazyGetUsersQuery();

    const {
        data: roles,
    } = useGetRolesQuery();

    const [
        createUsers,
        {
            error: createUsersError,
            isError: createUsersIsError,
            isSuccess: createUsersIsSuccess,
            isLoading: createUsersIsLoading,
        },
    ] = useCreateUsersMutation();

    const [getAllProjects,
        {
            data: projects,
        }
    ] = useLazyGetAllProjectsQuery();

    const [
        updateUsers,
        {
            isSuccess: updateUsersIsSuccess,
            isLoading: updateUsersIsLoading,
        },
    ] = useUpdateUsersMutation();

    const [
        updateUsersMFA,
        {isLoading: isLoadingMFA},
    ] = useUpdateUsersMFAMutation();

    const [
        deleteUsers,
        {
            isLoading: deleteUsersIsLoading,
        },
    ] = useDeleteUsersMutation();

    const checkAllUserMfaStatus = (data) => {
        const hasMFA0 = data.some(item => item.use_mfa === false);
        setCheckboxStatus(!hasMFA0);
    }

    const updateUsersMfa = (id, checkingStatus) => {
        updateUsersMFA({
            use_mfa: checkingStatus & 1,
            users:[id],
        });
    };
    const updateAllUsersMfa = (id, checkingStatus) => {
        updateUsersMFA({
            use_mfa: checkingStatus & 1,
            users: data.map(users => users.id),
        }).then(() => {
        }).catch(error => {
            console.error("Error updating users MFA:", error);
        });
    };

    useEffect(() => {
        if (createUsersIsError){
            errorHandle(createUsersError)
        }

    }, [createUsersError,createUsersIsError]);

    useEffect(() => {
        if (debouncedSearchTerm.length > 0) {
            searchUsers(debouncedSearchTerm).unwrap().then(res => {
              setData(res?.data)
              setTotalPage(0);
              checkAllUserMfaStatus(res?.data)
          })
        } else {
          getUsers({
            per_page: 10,
            page: 1,
          }).unwrap().then(res => {
            setData(res?.data?.data);
            setTotalPage(res?.data?.last_page)
            setCurrentPage(1)
            checkAllUserMfaStatus(res?.data?.data);
          })
        }
        hasBeenRendered.current= true;
    }, [debouncedSearchTerm])

    useEffect(() => {
        getAllProjects({
            per_page: 1000,
            page: currentPage,
        })
    }, [currentPage])

    useEffect(() => {
        getUsers({
            per_page: 10,
            page: currentPage,
        })
    }, [currentPage, checkboxStatus])

    useEffect(() => {
        if (debouncedSearchTerm.length > 0) {
            searchUsers(debouncedSearchTerm).unwrap().then(res => {
                setData(res?.data);
                setTotalPage(0);
                checkAllUserMfaStatus(res?.data)
            })
        }
        else if(users) {
            setTotalPage(users?.data?.last_page);
            setData(users?.data?.data);
            checkAllUserMfaStatus(users?.data?.data)
        }
    }, [users])

    useEffect(() => {
        setModalShow(false);
        setCurrentItem(null);
    }, [createUsersIsSuccess, updateUsersIsSuccess]);

    useEffect(() => {
        !modalShow && setCurrentItem(null);
    }, [modalShow]);

    useEffect(() => {
        deleteUsersIsLoading ||
        usersIsLoading ||
        createUsersIsLoading ||
        updateUsersIsLoading ||
        isLoadingMFA
            ? dispatch(setLoader(true))
            : dispatch(setLoader(false));
    }, [
        deleteUsersIsLoading,
        usersIsLoading,
        createUsersIsLoading,
        updateUsersIsLoading,
        isLoadingMFA,
    ]);

    return (
        <div className="s-pages">
            {deleteModal?.show && (
                <DeleteModal
                    id={deleteModal?.id}
                    action={deleteUsers}
                    setDeleteModal={setDeleteModal}
                />
            )}
            {modalShow && (
                <UsersModal
                    projects={projects}
                    currentItem={currentItem}
                    setModal={setModalShow}
                    rolesArray={roles?.data}
                    createHook={createUsers}
                    updateHook={updateUsers}
                />
            )}
            <div className="s-page__header">
                <div style={{display: 'flex'}}>
                    <h1>Users</h1>
                    <div className="search-block">
                        <input
                            placeholder="Search..."
                            type="text" className="search"
                            onChange={(e) => {
                            setSearchTerm(e.target.value)
                            }}
                        />
                    </div>
                </div>
                <button
                    onClick={() => {
                        setModalShow(true);
                    }}
                >
                    <img src="/assets/icons/plus.svg" alt="plus"/>
                    <span>Create User</span>
                </button>
            </div>
            <div className="table users__table">
                <div className="table-header">
                    <div className="table-th ">First name</div>
                    <div className="table-th ">Last name</div>
                    <div className="table-th">Email</div>
                    <div className="table-th">Phone number</div>
                    <div className="table-th ">Country</div>
                    <div className="table-th ">Role</div>
                    <div className="table-th pl-6 ">Status</div>
                    <div className="py-3 px-8">
                        <AxelCheckbox
                            id="all-users-mfa"
                            checked={checkboxStatus}
                            handler={updateAllUsersMfa}
                        />
                    </div>
                </div>
                {
                    data?.length > 0 ?
                        <div className="table-body">
                            {data?.map((item, index) => (
                                <div className="table-row" key={index}>
                                    <div className="table-td ">{item?.first_name ?? "-"}</div>
                                    <div className="table-td ">
                                        {item?.last_name ?? "-"}
                                    </div>
                                    <div className="table-td">
                                        {item?.email ?? "-"}
                                    </div>
                                    <div className="table-td ">{item?.phone ?? "-"}</div>
                                    <div className="table-td">
                                        {item?.country?.name ?? "-"}
                                    </div>
                                    <div className="table-td ">{item?.role ?? "-"}</div>
                                    <div className={`table-td pl-6`}>
                                        <div className={`${item?.deactivated ? 'deactivated' : 'deactivated-true'}`}
                                             onClick={() => updateUsers({id: item?.id, body: {deactivated: !item?.deactivated, force_password_change: 0}})}>
                                            {item?.deactivated ? 'Inactive' : 'Active'}
                                        </div>
                                    </div>
                                    <div className="px-8">
                                        <AxelCheckbox
                                            checked={item.use_mfa}
                                            id={item.id}
                                            handler={updateUsersMfa}
                                        />
                                    </div>
                                    <div className="table-actions">
                                        <div
                                            onClick={() => {
                                                setDeleteModal({id: item?.id, show: true});
                                            }}
                                            className="table-delete"
                                        >
                                            <img src="/assets/icons/trash-icon.svg" alt="trash"/>
                                            <span>Delete</span>
                                        </div>
                                        <div
                                            className="table-edit"
                                            onClick={() => {
                                                setCurrentItem(item);
                                                setModalShow(true);
                                            }}
                                        >
                                            <img src="/assets/icons/edit-icon-2.svg" alt="edit"/>
                                            <span>Edit</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div> :
                        <div style={{height: '70vh', width: '100%', display: "flex", justifyContent:"center", alignItems: 'center', flexDirection: "column"}}>
                            <img src="/assets/images/projects.png" alt=""/>
                            <span style={{color: "#767676", fontSize: 16, fontWeight: 500}}>No Users</span>
                        </div>
                }
            </div>
            {(users?.data?.data?.length > 0 && totalPage > 0) && <Paginate currentPage={currentPage} setCurrentPage={setCurrentPage} pageCount={totalPage}/>}
        </div>
    );
};
