import React from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

const SubSectionTableContainer = ({ isList = false, title, list, className, children, style }) => {
    return (
        <div className={classNames('subSectionTableContainer', className)} style={style}>
            {isList && !isEmpty(list) ? (
                <ul>
                    {list.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            ) : (
                <h4>{title}</h4>
            )}
            <div className="subSectionTableContainer__tableContainer">
                {children}
            </div>
        </div>
    );
};

export default SubSectionTableContainer;
