import React, { useState } from "react";
import { useEffect } from "react";
import Select from "react-select";
import {
  useGetAllCompaniesQuery,
  useGetAllCountriesQuery,
  useGetAllOrganizationTypeQuery,
  useGetAllSectorsQuery, useLazyGetAllCountriesQuery, useLazyGetAllSectorsQuery,
} from "../../store/service/adbService";
import "./index.scss";
import { useErrorHandler } from "../../global/ErrorsHandler";
import { toast } from "react-toastify";

const customStyles = {
  menu: (provided, state = false) => ({
    ...provided,
    width: "100%",
    color: state.selectProps.menuColor,
    cursor: "pointer",
  }),

  control: (_, { selectProps: { width } }) => ({
    width: "100%",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    display: "flex",
    marginTop: 8,
    background: "#FFFFFF",
    borderRadius: 8,
    padding: 6,
    cursor: "pointer",
  }),
};

export default function SecondaryContactModal({
  currentItem,
  setModal,
  updateHook,
  createHook,
}) {
  const [state, setState] = useState({
    long_name: currentItem?.long_name ?? "",
    short_name: currentItem?.short_name ?? "",
    country: currentItem?.country
      ? { label: currentItem?.country?.name, value: currentItem?.country?.id }
      : null,
    sector: currentItem?.sector
      ? { label: currentItem?.sector?.name, value: currentItem?.sector?.id }
      : null,
  });

  const [getSectors, {
    data: sectors,
    isError: sectorsIsError,
    isLoading: sectorsIsLoading,
    isSuccess: sectorsIsSuccess,
    error: sectorsError,
  }] = useLazyGetAllSectorsQuery();

  useEffect(() => {
    getSectors({
      per_page: 1000,
    })
  }, [])

  const [getCountries, {
    data: countries,
    isError: countriesIsError,
    isLoading: countriesIsLoading,
    isSuccess: countriesIsSuccess,
    error: countriesError,
  }] = useLazyGetAllCountriesQuery();

  useEffect(() => {
    getCountries({
      per_page: 1000,
    })
  }, [])

  const [errorHandler, setErrorHandler] = useErrorHandler([
    countriesError,
    sectorsError,
  ]);

  useEffect(() => {
    if (countries?.data?.data) {
      let data = countries?.data?.data?.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));
      setCountryOptions(data);
    }
  }, [countries]);

  useEffect(() => {
    if (sectors?.data?.data) {
      let data = sectors?.data?.data?.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));
      setSectorOptions(data);
    }
  }, [sectors]);

  const [countryOptions, setCountryOptions] = useState(null);
  const [sectorOptions, setSectorOptions] = useState(null);
  const submitHandler = (e) => {
    e.preventDefault();
    let body = {
      country_id: state?.country?.value,
      sector_id: state?.sector?.value,
      long_name: state?.long_name,
      short_name: state?.short_name,
    };
    let s = [];
    for (let key in body) {
      if (!body[key] || body[key] === "Invalid date" || body[key] === "") {
        toast.error("Please enter " + key?.split('_')?.join(' '), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
        s.push("false");
      }
    }

    if (s.length === 0) {
      if (currentItem) {
        updateHook({ id: currentItem?.id, body });
      } else {
        createHook(body);
      }
    }
  };
  const inputHandler = (e) => {
    setState((old) => ({ ...old, [e.target.id]: e.target.value }));
  };
  return (
    <div className="contact__modal">
      <div className="contact__modal-content">
        <div className="contact__modal-header">
          <h4 className="contact__modal-title">
            {currentItem ? "Edit" : "Create"} Company
          </h4>
          <div onClick={() => setModal(false)} className="contact__modal-close">
            <img src="/assets/icons/close-mark.svg" alt="close" />
          </div>
        </div>
        <form
          action="#"
          className="contact__modal-form"
          onSubmit={submitHandler}
        >
          <div className="contact__modal-gray">
            <div className="contact__modal-row">
              <div className="contact__modal-field">
                <label htmlFor="long_name" className="contact__modal-label">
                  Company Full Name
                </label>
                <input
                  maxLength={190}
                  type="text"
                  id="long_name"
                  onChange={inputHandler}
                  defaultValue={state?.long_name}
                  className="contact__modal-input"
                  placeholder="Enter long name"
                />
              </div>
              <div className="contact__modal-field">
                <label htmlFor="short_name" className="contact__modal-label">
                  Company Short Name
                </label>
                <input
                  maxLength={190}
                  type="text"
                  id="short_name"
                  onChange={inputHandler}
                  defaultValue={state?.short_name}
                  className="contact__modal-input"
                  placeholder="Enter short name"
                />
              </div>
            </div>
            <div className="contact__modal-row">
              <div className="contact__modal-field">
                <label className="contact__modal-label">Select Country</label>
                <Select
                    menuPortalTarget={document.body}
                    styles={({...customStyles, menuPortal: base => ({...base, zIndex: 9999})})}
                  options={countryOptions}
                  value={state?.country}
                  onChange={(selected) =>
                    setState((old) => ({ ...old, country: selected }))
                  }
                />
              </div>
              <div className="contact__modal-field">
                <label htmlFor="email" className="contact__modal-label">
                  Select Sector
                </label>
                <Select
                    menuPortalTarget={document.body}
                    styles={({...customStyles, menuPortal: base => ({...base, zIndex: 9999})})}
                  options={sectorOptions}
                  value={state?.sector}
                  onChange={(selected) =>
                    setState((old) => ({ ...old, sector: selected }))
                  }
                />
              </div>
            </div>
          </div>
          <div className="contact__modal-btns">
            <button className="contact__modal-btn">
              {currentItem ? "Save" : "Save"}
            </button>
          </div>
        </form>
      </div>
      <div onClick={() => setModal(false)} className="contact__modal-bg"></div>
    </div>
  );
}
