import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  email: null,
  token: null,
};

export const forgetSlice = createSlice({
  name: "forgetPassword",
  initialState,
  reducers: {
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
  },
});

export const { setEmail, setToken } = forgetSlice.actions;

export default forgetSlice.reducer;
