import React, {useState} from 'react';
import './index.scss'

export default function InfoModal ({moreInfo, title, text, close}) {
  const [language, setLanguage] = useState('en')
  return (
    <div className="infoModal">
      <div className="infoModal__content">
        <div className="infoModal__content-top">
          <p className="infoModal__content-title">{moreInfo?.section_name}</p>
          <img src="/assets/icons/close.svg" alt="exit" onClick={close}/>
        </div>

        <div className="infoModal__tabs">
          <p className={`infoModal__tabs-name ${language === 'en' ? 'active' : ''}`} onClick={() => setLanguage('en')}>English</p>
          <p className={`infoModal__tabs-name ${language === 'ru' ? 'active' : ''}`} onClick={() => setLanguage('ru')}>Russian</p>
        </div>

        <p className="infoModal__content-text">
          {language === 'en' ? moreInfo?.section_text[0]["EN"] : moreInfo?.section_text[1]["RU"]}
        </p>
      </div>
      <div className="infoModal__bg" onClick={close}></div>
    </div>
  );
};