import moment from "moment";
import isEmpty from "lodash/isEmpty";
import React, { useRef, useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
    useCreateProjectGoodsEquipmentMutation,
    useDeleteProjectGoodsEquipmentMutation,
    useLazyGetAllCompaniesQuery,
    useUpdateProjectGoodsEquipmentMutation,
} from "../../../store/service/adbService";
import { setLoader } from "../../../store/slice/dashboard";
import { Switch } from "../../../UI/Switch";
import { DeleteModal } from "../DeleteModal";
import MoreInfoModal from "../../MoreInfoModal";
import CustomDatePicker from "../../CustomDatePicker";

export const GoodsEquipment = ({
                                   goodsData,
                                   glossaryData,
                                   refGoodsEquipment,
                                   translations
                               }) => {
    const dispatch = useDispatch();
    const localeLan = localStorage.getItem("local_lan" );
    moment.locale(localeLan);
    const [data, setData] = useState([]);
    const [show, setShow] = useState(true);
    const [showByActive, setShowByActive] = useState(true);
    const [hasArchived, setHasArchived] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [currentExpandedItem, setCurrentExpandedItem] = useState(null);
    const user = useSelector((state) => state?.adbReducer?.user);
    const [newItem, setNewItem] = useState(false);
    const [options, setOptions] = useState([]);
    const [editable, setEditable] = useState(false);
    const [deleteModal, setDeleteModal] = useState(null);
    const [moreInfo, setMoreInfo] = useState(null);
    const expandedRef = useRef(null);
    const expandedCommentsRef = useRef(null);
    const [expanded, setExpanded] = useState(false);
    const [expandedComments, setExpandedComments] = useState(false);
    const [expandedRow, setExpandedRow] = useState(false);
    const [tableHeaders, setTableHeaders] = useState([]);
    const [expandedHeaders, setExpandedHeaders] = useState([]);
    const [infoModal, setInfoModal] = useState(false);
    const [currentRow, setCurrentRow] = useState(null);
    const scrollRef = useRef(null);

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                let array = data?.map((item) => {
                    item.edit = false;
                    return item;
                });
                setEditable(false);
                setCurrentItem(null);
                setData(array);
                if (newItem) {
                    setNewItem(false);
                    let s = data?.filter((el, i) => i !== data.length - 1);
                    setData(s);
                }
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, [data]);


    const [
        getCompanies,
    ] = useLazyGetAllCompaniesQuery();

    useEffect(() => {
        getCompanies({
            per_page: 1000,
        });
    }, []);

    useEffect(() => {
        if (goodsData?.responsible_party) {
            let data = goodsData?.responsible_party?.map((item) => {
                return {
                    value: item?.id,
                    label: item?.short_name,
                };
            });
            setOptions(data);
        }
    }, [goodsData]);
    const [
        createProjectGoodsEquipment,
        {
            isError: createProjectGoodsEquipmentIsError,
            isLoading: createProjectGoodsEquipmentIsLoading,
            isSuccess: createProjectGoodsEquipmentIsSuccess,
        },
    ] = useCreateProjectGoodsEquipmentMutation();

    const [
        updateProjectGoodsEquipment,
        {
            isError: updateProjectGoodsEquipmentIsError,
            isLoading: updateProjectGoodsEquipmentIsLoading,
            isSuccess: updateProjectGoodsEquipmentIsSuccess,
        },
    ] = useUpdateProjectGoodsEquipmentMutation();

    const [
        deleteProjectGoodsEquipment,
        {
            isError: deleteProjectGoodsEquipmentIsError,
            isLoading: deleteProjectGoodsEquipmentIsLoading,
            isSuccess: deleteProjectGoodsEquipmentIsSuccess,
        },
    ] = useDeleteProjectGoodsEquipmentMutation();

    useEffect(() => {
        if (goodsData?.goods_equipment) {
            let data = goodsData?.goods_equipment?.map((item) => ({
                id: item?.id,
                clearance_status: item?.clearance_status,
                destination: item?.destination,
                description: item?.description,
                fat_status: item?.fat_status,
                invoiced: item?.invoiced,
                paid: item?.paid,
                project_id: goodsData?.id,
                edit: false,
                archived: item?.archived,
                submitted: item?.submitted,
                old_report: item?.old_report,
                submitted_date: item?.submitted_date,
                comments: item?.comments,
                actual_arrival_date: item?.actual_arrival_date,
                actual_delivery_date: item?.actual_delivery_date,
                actual_shipped_date: item?.actual_shipped_date,
                planned_arrival_date: item?.planned_arrival_date,
                planned_delivery_date: item?.planned_delivery_date,
                planned_shipped_date: item?.planned_shipped_date,
                warehouse_actual_date: item?.warehouse_actual_date,
                warehouse_planned_date: item?.warehouse_planned_date,
                delivery_complete: item?.delivery_complete,
            }));
            let isHaveArchived = isEmpty(data.find(item => item.archived===1));
            setHasArchived(!isHaveArchived);
            if (showByActive===true && !isHaveArchived){
                const filteredData =  data.filter(item => item.archived ===0);
                setData(filteredData);
            } else setData(data);
        }
    }, [
        goodsData?.goods_equipment,
        createProjectGoodsEquipmentIsError,
        deleteProjectGoodsEquipmentIsError,
        updateProjectGoodsEquipmentIsError,
        showByActive,
    ]);

    useEffect(() => {
        if(translations){
            const headers = [
                {
                    tooltip_name: translations?.['goods_equipment.goods_equipment_packages_status.table_headers.goods_equipment_description'],
                    tooltip_text: translations?.['goods_equipment.goods_equipment_packages_status.tooltips.goods_equipment_description'],
                },
                {
                    tooltip_name: translations?.['goods_equipment.goods_equipment_packages_status.table_headers.fat_status'],
                    tooltip_text: translations?.['goods_equipment.goods_equipment_packages_status.tooltips.fat_status'],
                },
                {
                    tooltip_name: translations?.['goods_equipment.goods_equipment_packages_status.table_headers.destinations'],
                    tooltip_text: translations?.['goods_equipment.goods_equipment_packages_status.tooltips.destinations'],
                },
                {
                    tooltip_name: translations?.['goods_equipment.goods_equipment_packages_status.table_headers.customs_clearance_status'],
                    tooltip_text: translations?.['goods_equipment.goods_equipment_packages_status.tooltips.customs_clearance_status'],
                },
                {
                    tooltip_name: translations?.['goods_equipment.goods_equipment_packages_status.table_headers.percent_invoiced'],
                    tooltip_text: translations?.['goods_equipment.goods_equipment_packages_status.tooltips.percent_invoiced'],
                },
                {
                    tooltip_name: translations?.['goods_equipment.goods_equipment_packages_status.table_headers.percent_paid'],
                    tooltip_text: translations?.['goods_equipment.goods_equipment_packages_status.tooltips.percent_paid'],
                },
                {
                    tooltip_name: translations?.['goods_equipment.goods_equipment_packages_status.table_headers.delivery_complete'],
                    tooltip_text: translations?.['goods_equipment.goods_equipment_packages_status.tooltips.delivery_complete'],
                },
                {
                    tooltip_name: translations?.['goods_equipment.goods_equipment_packages_status.table_headers.current_status_or_comments'],
                    tooltip_text: translations?.['goods_equipment.goods_equipment_packages_status.tooltips.current_status_or_comments'],
                },
            ]

            const expanded_header = [
                {
                    tooltip_name: translations?.['goods_equipment.goods_equipment_packages_status.table_headers.planned_shipment_date'],
                    tooltip_text: translations?.['goods_equipment.goods_equipment_packages_status.tooltips.planned_shipment_date'],
                },
                {
                    tooltip_name: translations?.['goods_equipment.goods_equipment_packages_status.table_headers.actual_shipment_date'],
                    tooltip_text: translations?.['goods_equipment.goods_equipment_packages_status.tooltips.actual_shipment_date'],
                },
                {
                    tooltip_name: translations?.['goods_equipment.goods_equipment_packages_status.table_headers.planned_arrival_date'],
                    tooltip_text: translations?.['goods_equipment.goods_equipment_packages_status.tooltips.planned_arrival_date'],
                },
                {
                    tooltip_name: translations?.['goods_equipment.goods_equipment_packages_status.table_headers.actual_arrival_date'],
                    tooltip_text: translations?.['goods_equipment.goods_equipment_packages_status.tooltips.actual_arrival_date'],
                },
                {
                    tooltip_name: translations?.['goods_equipment.goods_equipment_packages_status.table_headers.warehouse_planned_arrival_date'],
                    tooltip_text: translations?.['goods_equipment.goods_equipment_packages_status.tooltips.warehouse_planned_arrival_date'],
                },
                {
                    tooltip_name: translations?.['goods_equipment.goods_equipment_packages_status.table_headers.warehouse_actual_arrival_date'],
                    tooltip_text: translations?.['goods_equipment.goods_equipment_packages_status.tooltips.warehouse_actual_arrival_date'],
                },
                {
                    tooltip_name: translations?.['goods_equipment.goods_equipment_packages_status.table_headers.site_planned_delivery_date'],
                    tooltip_text: translations?.['goods_equipment.goods_equipment_packages_status.tooltips.site_planned_delivery_date'],
                },
                {
                    tooltip_name: translations?.['goods_equipment.goods_equipment_packages_status.table_headers.site_actual_delivery_date'],
                    tooltip_text: translations?.['goods_equipment.goods_equipment_packages_status.tooltips.site_actual_delivery_date'],
                },
            ]

            setTableHeaders(
                headers.map((item) => ({
                    ...item,
                    showTooltip: false,
                }))
            );
            setExpandedHeaders(
                expanded_header.map((item) => ({
                    ...item,
                    showTooltip: false,
                }))
            );
        }
    }, [translations])

    useEffect(() => {
        if (glossaryData?.data?.length) {
            setMoreInfo(
                glossaryData?.data?.filter(
                    (el) => el?.section_name === "Goods / Equipment"
                )[0]
            );
        }
        setData((old) => {
            let data = old.map((item) => ({ ...item, edit: false }));
            return data;
        });
        setEditable(false);
    }, [
        createProjectGoodsEquipmentIsSuccess,
        updateProjectGoodsEquipmentIsSuccess,
        deleteProjectGoodsEquipmentIsSuccess,
        glossaryData,
    ]);



    useEffect(() => {
        const d = () => {
            dispatch(setLoader(false));
        };

        createProjectGoodsEquipmentIsLoading ||
        updateProjectGoodsEquipmentIsLoading ||
        deleteProjectGoodsEquipmentIsLoading
            ? dispatch(setLoader(true))
            : d();
    }, [
        createProjectGoodsEquipmentIsLoading,
        updateProjectGoodsEquipmentIsLoading,
        deleteProjectGoodsEquipmentIsLoading,
    ]);

    const setterData = (input) => {
        const body = {
            destination: input?.destination,
            paid: input?.paid,
            clearance_status: input?.clearance_status,
            description: input?.description,
            fat_status: input?.fat_status,
            project_id: goodsData?.id,
            invoiced: input?.invoiced,
        };

        let s = [];
        for (let key in body) {
            if (!body[key] || body[key] === "Invalid date" || body[key] === "") {
                toast.error("Please enter " + key?.split("_")?.join(" "), {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
                s.push("false");
            }
        }

        if (s.length === 0) {
            return body;
        } else {
            return null;
        }
    };
    const submitHandler = (e, item) => {
        e.preventDefault();
        if (newItem) {
            const dataCreated = setterData(currentItem);
            const dataDated = setterExpandedData(currentExpandedItem, item, currentItem?.delivery_complete);
            if (dataCreated) {
                createProjectGoodsEquipment({
                    ...dataCreated,
                    ...dataDated,
                    delivery_complete: currentItem?.delivery_complete ? 1 : 0,
                    comments: currentItem?.comments,
                });
                setCurrentItem(null);
                setNewItem(false);
                setCurrentRow(null);
                setCurrentExpandedItem(null);
            }
        } else {
            const dataUpdated = setterData(currentItem);
            if (dataUpdated) {
                submitExpandedHandler(e,item, currentItem?.delivery_complete,  dataUpdated, currentItem?.comments)

                setCurrentItem(null);
                setNewItem(false);
                setCurrentRow(null);
                setCurrentExpandedItem(null);
            }
        }
    };
    const setterExpandedData = (input, item, complete) => {
        const expandedBody = {}

        let s = [];

        if (input?.planned_arrival_date) {
            expandedBody.planned_arrival_date = moment(input?.planned_arrival_date).format("YYYY-MM-DD")
        }
        if (input?.planned_delivery_date) {
            expandedBody.planned_delivery_date = moment(input?.planned_delivery_date).format("YYYY-MM-DD")
        }
        if (input?.planned_shipped_date) {
            expandedBody.planned_shipped_date = moment(input?.planned_shipped_date).format("YYYY-MM-DD")
        }
        if (input?.warehouse_planned_date) {
            expandedBody.warehouse_planned_date = moment(input?.warehouse_planned_date).format("YYYY-MM-DD")
        }

        if (complete) {
            expandedBody.actual_arrival_date = input?.actual_arrival_date ? moment(
                input?.actual_arrival_date
            ).format("YYYY-MM-DD") : null;
            expandedBody.actual_delivery_date = input?.actual_delivery_date ? moment(
                input?.actual_delivery_date
            ).format("YYYY-MM-DD") : null;
            expandedBody.actual_shipped_date = input?.actual_shipped_date ? moment(
                input?.actual_shipped_date
            ).format("YYYY-MM-DD") : null;
            expandedBody.warehouse_actual_date = input?.warehouse_actual_date ? moment(
                input?.warehouse_actual_date
            ).format("YYYY-MM-DD") : null;
            expandedBody.planned_arrival_date = input?.planned_arrival_date ? moment(input?.planned_arrival_date).format(
                "YYYY-MM-DD"
            ) : null
            expandedBody.planned_delivery_date = input?.planned_delivery_date ? moment(input?.planned_delivery_date).format(
                "YYYY-MM-DD"
            ) : null
            expandedBody.planned_shipped_date = input?.planned_shipped_date ? moment(input?.planned_shipped_date).format(
                "YYYY-MM-DD"
            ) : null
            expandedBody.warehouse_planned_date = input?.warehouse_planned_date ? moment(input?.warehouse_planned_date).format(
                "YYYY-MM-DD"
            ) : null
            expandedBody.project_id = goodsData?.id
        } else if (
            !complete &&
            (input?.actual_arrival_date ||
                input?.actual_delivery_date ||
                input?.actual_shipped_date ||
                input?.warehouse_actual_date)
        ) {
            toast.error("To add actual dates, delivery complete must be true");
        }

        for (let key in expandedBody) {
            if (
                !expandedBody[key] ||
                expandedBody[key] === "Invalid date" ||
                expandedBody[key] === ""
            ) {
                toast.error("Please enter " + key?.split("_")?.join(" "), {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
                s.push("false");
            }
        }
        if (s.length === 0) {
            return expandedBody;
        } else {
            setCurrentRow(null);
            let array = data?.map((item) => {
                item.edit = false;
                return item;
            });
            setCurrentItem(null);
            setData(array);
            setCurrentExpandedItem(null);
            if (newItem) {
                setNewItem(false);
                let s = data?.filter(
                    (el, i) => i !== data.length - 1
                );
                setData(s);
            }
            return null;
        }
    };
    const submitExpandedHandler = (e, item, complete, dataUpdatedS, comments) => {
        e.preventDefault();
        if (newItem) {
            const dataCreated = setterExpandedData(currentExpandedItem, item, complete);
            if (dataCreated) {
                createProjectGoodsEquipment({
                    ...dataCreated,
                    delivery_complete: currentItem?.delivery_complete ? 1 : 0,
                    comments,
                }).unwrap().then(() => {
                    dispatch(setLoader(false))
                }).catch(err => {
                    for (let key in err.data.errors) {
                        err.data.errors[key].forEach((elem) => {
                            toast.error(elem);
                        });
                    }
                    dispatch(setLoader(false))
                })
                setCurrentItem(null);
                setNewItem(false);
                setCurrentRow(null);
            }
        } else {
            const dataUpdated = setterExpandedData(currentExpandedItem ? currentExpandedItem : item, item, complete);
            if (dataUpdated) {
                updateProjectGoodsEquipment({
                    id: item?.id,
                    body: {
                        ...dataUpdatedS,
                        delivery_complete: currentItem?.delivery_complete ? 1 : 0,
                        comments,
                        ...dataUpdated,
                    },
                })
                    .unwrap()
                    .then(() => {
                        dispatch(setLoader(false))
                    })
                    .catch((err) => {
                        for (let key in err.data.errors) {
                            err.data.errors[key].forEach((elem) => {
                                toast.error(elem);
                            });
                        }
                        dispatch(setLoader(false))
                    });
                setCurrentItem(null);
                setNewItem(false);
                setCurrentRow(null);
                setCurrentExpandedItem(null);
            }
        }
    };

    return (
        <div
            ref={refGoodsEquipment}
            id={refGoodsEquipment}
            className="project__white-box"
        >
            <div className="project__goods">
                {infoModal && (
                    <MoreInfoModal
                        title={translations?.['goods_equipment.goods_equipment.headers.goods_equipment']}
                        moreInfo={translations?.['goods_equipment.goods_equipment.more_info.goods_equipment']}
                        close={()=> setInfoModal(false)}
                    />
                )}

                {deleteModal?.show && (
                    <DeleteModal
                        id={deleteModal?.id}
                        action={deleteProjectGoodsEquipment}
                        setDeleteModal={setDeleteModal}
                        cencelText={translations?.['main.main.actions.cancel']}
                        deleteText={translations?.['main.main.actions.delete']}
                        modalTitle={translations?.['main.main.headers.confirm_your_action']}
                        clarifyingText={translations?.['main.main.titles.you_are_going_to_delete_this_row']}
                    />
                )}
                <div className={`project__block ${show ? "" : "close"}`}>
                    <div className="d-f">
                        <div
                            className="project__block-title"
                            onClick={() => setShow(!show)}
                        >
                            <span>{translations?.['goods_equipment.goods_equipment.headers.goods_equipment']}</span>
                        </div>
                        <span
                            className="project__block-more"
                            onClick={() => setInfoModal(!infoModal)}
                        >
              <span className="img">
                <img src="/assets/icons/info.svg" alt="info" />
              </span>
              <span className="text">{translations?.['goods_equipment.goods_equipment.titles.more_info']}</span>
            </span>
                    </div>
                    <div className="project__block-subtitles">
                        <div className="project__block-subtitle">
                            {translations?.['goods_equipment.goods_equipment_packages_status.headers.goods_equipment_packages_status']}
                        </div>
                        <div className="project__block__buttonsContainer">
                            {hasArchived && <button
                                onClick={() => setShowByActive(!showByActive)}
                                className="project__block-add-row">
                                <span> <img src="/assets/icons/blue-eye.svg" alt="plus"/></span>
                                <span>{showByActive ? translations?.['goods_equipment.goods_equipment_packages_status.actions.show_all']
                                    : translations?.['goods_equipment.goods_equipment_packages_status.actions.show_active']}</span>
                            </button>}
                            {user?.permissions?.edit_project && (
                                <button
                                    onClick={() => {
                                        let randomId = Math.floor(Math.random() * 1000);
                                        setCurrentRow(randomId);
                                        scrollRef !== null && scrollRef?.current?.scrollIntoView();
                                        if (!newItem) {
                                            setNewItem(true);
                                            setEditable(true);
                                            setExpandedRow(true)
                                            setData((old) => [
                                                ...old,
                                                {
                                                    id: randomId,
                                                    clearance_status: "",
                                                    description: "",
                                                    destination: "",
                                                    comments: "",
                                                    paid: "",
                                                    fat_status: "",
                                                    invoiced: "",
                                                    edit: true,
                                                    actual_arrival_date: null,
                                                    actual_delivery_date: null,
                                                    actual_shipped_date: null,
                                                    planned_arrival_date: null,
                                                    planned_delivery_date: null,
                                                    planned_shipped_date: null,
                                                    warehouse_actual_date: null,
                                                    warehouse_planned_date: null,
                                                    delivery_complete: 0,
                                                },
                                            ]);
                                            setCurrentItem({
                                                id: randomId,
                                                clearance_status: "",
                                                description: "",
                                                destination: "",
                                                comments: "",
                                                paid: "",
                                                fat_status: "",
                                                invoiced: "",
                                                edit: true,
                                                actual_arrival_date: null,
                                                actual_delivery_date: null,
                                                actual_shipped_date: null,
                                                planned_arrival_date: null,
                                                planned_delivery_date: null,
                                                planned_shipped_date: null,
                                                warehouse_actual_date: null,
                                                warehouse_planned_date: null,
                                                delivery_complete: 0,
                                            });
                                        }
                                    }}
                                    className="project__block-add-row"
                                >
                  <span>
                    <img src="/assets/icons/blue-plus.svg" alt="plus" />
                  </span>
                                    <span>{translations?.['goods_equipment.goods_equipment_packages_status.actions.add_row']}</span>
                                </button>
                            )}
                        </div>
                    </div>
                    <div
                        className={`project__block-content permits ${
                            editable ? "editable" : ""
                        }`}
                    >
                        <div
                            className={`project__block-table project-table ${
                                currentItem ? "n" : ""
                            }`}
                        >
                            <div
                                ref={scrollRef}
                                className={`table__header project-table-larp-sections ${
                                    data?.length > 3 && currentItem ? "isFixed" : ""
                                }`}
                                style={{gridTemplateColumns: '1.3fr 1fr 1fr 1fr 0.7fr 0.7fr 1fr 1.3fr'}}
                            >
                                {tableHeaders?.map((el, idx) => {
                                    return (
                                        <div
                                            key={idx}
                                            onMouseEnter={() => {
                                                setTableHeaders(
                                                    tableHeaders.map((item) => ({
                                                        ...item,
                                                        showTooltip: el.tooltip_name === item.tooltip_name,
                                                    }))
                                                );
                                            }}
                                            onMouseLeave={() => {
                                                setTableHeaders(
                                                    tableHeaders.map((item) => ({
                                                        ...item,
                                                        showTooltip: false,
                                                    }))
                                                );
                                            }}
                                            className="project-table__th"
                                        >
                                            {el?.showTooltip ? (
                                                <span
                                                    className={
                                                        el?.tooltip_text?.length > 20
                                                            ? "lengthy"
                                                            : "not-lengthy"
                                                    }
                                                >
                          {el?.tooltip_text}
                        </span>
                                            ) : (
                                                ""
                                            )}
                                            {el?.tooltip_name}
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="project-table__body">
                                {data?.map((item, index) => (
                                    <>
                                        {index !== 0 && currentRow === item?.id && (
                                            <div
                                                className={`table__header project-table-larp-sections`}
                                                style={{gridTemplateColumns: '1.3fr 1fr 1fr 1fr 0.7fr 0.7fr 1fr 1.31fr'}}
                                                key={index}
                                            >
                                                {tableHeaders?.map((el, idx) => {
                                                    return (
                                                        <div key={idx} className="project-table__th">
                                                            {el?.showTooltip ? (
                                                                <span
                                                                    className={
                                                                        el?.tooltip_text?.length > 20
                                                                            ? "lengthy"
                                                                            : "not-lengthy"
                                                                    }
                                                                >
                                  {el?.tooltip_text}
                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                            {el?.tooltip_name}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                        <form
                                            style={{gridTemplateColumns: '1.3fr 1fr 1fr 1fr 0.7fr 0.7fr 1fr 1.3fr'}}
                                            className={`table__row project-table-larp-sections ${
                                                item?.edit ? "edit" : currentItem ? "n" : ""
                                            } ${
                                                Boolean(item.submitted) ||
                                                !user?.permissions?.edit_project
                                                    ? "submitted"
                                                    : ""
                                            }`}
                                            id={item?.id}
                                            key={item?.id}
                                            onSubmit={(e) => {
                                                submitHandler(e, item)
                                            }
                                            }
                                        >
                                            <div className="project-table__td">
                                                <span>{item?.description}</span>
                                                <div className="project__block-input project-table__field">
                          <textarea
                              className={`${
                                  expanded ? "project__block-expandedInput" : ""
                              }`}
                              onFocus={() => setExpanded(true)}
                              onBlur={() => setExpanded(false)}
                              ref={expandedRef}
                              maxLength={190}
                              defaultValue={item?.description}
                              onChange={(e) => {
                                  setCurrentItem((old) => ({
                                      ...old,
                                      description: e.target.value,
                                  }));
                              }}
                          />
                                                </div>
                                            </div>
                                            <div className="project-table__td">
                                                <span>{item?.fat_status}</span>
                                                <div className="project__block-input project-table__field">
                                                    <input
                                                        maxLength={190}
                                                        type="text"
                                                        defaultValue={item?.fat_status}
                                                        onChange={(e) => {
                                                            setCurrentItem((old) => ({
                                                                ...old,
                                                                fat_status: e.target.value,
                                                            }));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="project-table__td">
                                                <span>{item?.destination}</span>
                                                <div className="project__block-input project-table__field">
                                                    <input
                                                        maxLength={190}
                                                        type="text"
                                                        defaultValue={item?.destination}
                                                        onChange={(e) => {
                                                            setCurrentItem((old) => ({
                                                                ...old,
                                                                destination: e.target.value,
                                                            }));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="project-table__td">
                                                <span>{item?.clearance_status}</span>
                                                <div className="project__block-input project-table__field">
                                                    <input
                                                        maxLength={190}
                                                        type="text"
                                                        defaultValue={item?.clearance_status}
                                                        onChange={(e) => {
                                                            setCurrentItem((old) => ({
                                                                ...old,
                                                                clearance_status: e.target.value,
                                                            }));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="project-table__td">
                                                <span>{item?.invoiced}%</span>
                                                <div className="project__block-input project-table__field percent">
                                                    <input
                                                        type={"number"}
                                                        maxLength={3}
                                                        value={
                                                            currentItem
                                                                ? currentItem?.invoiced
                                                                : item?.invoiced
                                                        }
                                                        onChange={(e) => {
                                                            if (e?.target?.value <= 100) {
                                                                setCurrentItem((old) => ({
                                                                    ...old,
                                                                    invoiced: e?.target?.value,
                                                                }));
                                                            }
                                                        }}
                                                    />
                                                    <p>%</p>
                                                </div>
                                            </div>
                                            <div className="project-table__td">
                                                <span>{item?.paid}%</span>
                                                <div className="project__block-input project-table__field percent">
                                                    <input
                                                        type={"number"}
                                                        maxLength={3}
                                                        value={currentItem ? currentItem?.paid : item?.paid}
                                                        onChange={(e) => {
                                                            if (e?.target?.value <= 100) {
                                                                setCurrentItem((old) => ({
                                                                    ...old,
                                                                    paid: e?.target?.value,
                                                                }));
                                                            }
                                                        }}
                                                    />
                                                    <p>%</p>
                                                </div>
                                            </div>
                                            <div className="project-table__td">
                                                <Switch
                                                    value={item?.delivery_complete}
                                                    disabled={item?.edit ? false : true}
                                                    checked={
                                                        item?.edit
                                                            ? currentItem?.delivery_complete
                                                            : item?.delivery_complete
                                                    }
                                                    id={index + 457}
                                                    handler={(value) => {
                                                        setCurrentItem((old) => ({
                                                            ...old,
                                                            delivery_complete: value ? 1 : 0,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                            <div className="project-table__td">
                                                <span>{item?.comments}</span>
                                                <div className="project__block-input project-table__field">
                          <textarea
                              className={`${
                                  expandedComments
                                      ? "project__block-expandedInput"
                                      : ""
                              }`}
                              ref={expandedCommentsRef}
                              onFocus={() => setExpandedComments(true)}
                              onBlur={() => setExpandedComments(false)}
                              maxLength={190}
                              defaultValue={item?.comments}
                              onChange={(e) => {
                                  setCurrentItem((old) => ({
                                      ...old,
                                      comments: e.target.value,
                                  }));
                              }}
                          />
                                                </div>
                                            </div>
                                            <div className="project__block-btns">
                                                {currentItem ? (
                                                    <div
                                                        className="project__block-cancel"
                                                        onClick={() => {
                                                            setCurrentRow(null);
                                                            let array = data?.map((item) => {
                                                                item.edit = false;
                                                                return item;
                                                            });
                                                            setCurrentItem(null);
                                                            setData(array);
                                                            setCurrentExpandedItem(null);
                                                            if (newItem) {
                                                                setNewItem(false);
                                                                let s = data?.filter(
                                                                    (el, i) => i !== data.length - 1
                                                                );
                                                                setData(s);
                                                            }
                                                        }}
                                                    >
                                                        <img
                                                            src="/assets/icons/close-mark.svg"
                                                            alt="close"
                                                        />
                                                        {translations?.['goods_equipment.goods_equipment_packages_status.actions.cancel']}
                                                    </div>
                                                ) : (
                                                    <div
                                                        className="project__block-delete"
                                                        onClick={() => {
                                                            setDeleteModal({ id: item?.id, show: true });
                                                        }}
                                                    >
                                                        <img
                                                            src="/assets/icons/trash-icon.svg"
                                                            alt="arrow"
                                                        />
                                                        {translations?.['goods_equipment.goods_equipment_packages_status.actions.delete']}
                                                    </div>
                                                )}
                                                {currentItem ? (
                                                    <button className="project__block-save">
                                                        <img
                                                            src="/assets/icons/save-arrow.svg"
                                                            alt="arrow"
                                                        />
                                                        {translations?.['goods_equipment.goods_equipment_packages_status.actions.save']}
                                                    </button>
                                                ) : (
                                                    <div
                                                        onClick={() => {
                                                            setCurrentRow(item?.id);
                                                            setCurrentItem(item);
                                                            setEditable(true);
                                                            setExpandedRow(true);

                                                            setCurrentExpandedItem(item);
                                                            if (!item?.delivery_complete) {
                                                            }

                                                            setData(() => {
                                                                let array = data?.map((elem) => {
                                                                    if (elem?.id === item?.id) {
                                                                        elem.edit = true;
                                                                    }
                                                                    return elem;
                                                                });
                                                                return array;
                                                            });
                                                        }}
                                                        className="project__block-update"
                                                    >
                                                        {translations?.['goods_equipment.goods_equipment_packages_status.actions.update']}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="project__larp-btns">
                                                {currentRow === item?.id && expandedRow ? (
                                                        <button
                                                            onClick={() => {
                                                                setExpandedRow(false);
                                                                setCurrentRow(null);
                                                            }}
                                                            className="project__block-expand"
                                                        >
                                                            <img
                                                                src="/assets/icons/arrow-up-larp.svg"
                                                                alt="arrow"
                                                            />
                                                        </button>
                                                    ) :
                                                    <div
                                                        onClick={() => {
                                                            setCurrentRow(item?.id);
                                                            setExpandedRow(true);
                                                        }}
                                                        className="project__block-expand"
                                                    >
                                                        <img
                                                            src="/assets/icons/arrow-down-larp.svg"
                                                            alt="arrow"
                                                        />
                                                    </div>
                                                }
                                            </div>
                                        </form>
                                        {currentRow === item?.id && expandedRow ? (
                                            <>
                                                <div className="expandedRow__bg">
                                                    <div
                                                        ref={scrollRef}
                                                        className={`table__header project-table-larp-sections ${
                                                            data?.length > 3 && currentExpandedItem
                                                                ? "isFixed"
                                                                : ""
                                                        }`}
                                                        style={{gridTemplateColumns: '1.2fr 1fr 1fr 1fr 1fr 1fr 1fr 1.2fr'}}
                                                    >
                                                        {expandedHeaders?.map((el, idx) => {
                                                            return (
                                                                <div
                                                                    key={idx}
                                                                    onMouseEnter={(event) => {
                                                                        setExpandedHeaders(
                                                                            expandedHeaders.map((item) => ({
                                                                                ...item,
                                                                                showTooltip: el.tooltip_name === item.tooltip_name,
                                                                            }))
                                                                        );
                                                                    }}
                                                                    onMouseLeave={() => {
                                                                        setExpandedHeaders(
                                                                            expandedHeaders.map((item) => ({
                                                                                ...item,
                                                                                showTooltip: false,
                                                                            }))
                                                                        );
                                                                    }}
                                                                    className="project-table__th"
                                                                >
                                                                    {el?.showTooltip ? (
                                                                        <span
                                                                            className={
                                                                                el?.tooltip_text?.length > 20
                                                                                    ? "lengthy"
                                                                                    : "not-lengthy"
                                                                            }
                                                                        >
                                      {el?.tooltip_text}
                                    </span>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                    {el?.tooltip_name}
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                    <form
                                                        onSubmit={(e) => submitExpandedHandler(e, item)}
                                                        style={{gridTemplateColumns: '1.2fr 1fr 1fr 1fr 1fr 1fr 1fr 1.2fr'}}
                                                        className={`table__row project-table-larp-sections expandedRow ${
                                                            currentExpandedItem || (currentItem?.edit)
                                                                ? "edit"
                                                                : currentExpandedItem
                                                                    ? "n"
                                                                    : ""
                                                        } ${
                                                            Boolean(item.submitted) ||
                                                            !user?.permissions?.edit_project
                                                                ? "submitted"
                                                                : ""
                                                        }`}
                                                        id={item?.id}
                                                        key={item?.id}
                                                    >
                                                        <div className="project-table__td">
                              <span>
                                {!item?.planned_shipped_date ? 'DD/MM/YYYY' : moment(item?.planned_shipped_date).format(
                                    "DD/MM/YYYY"
                                ) === "Invalid date" ||
                                !moment(item?.planned_shipped_date).format(
                                    "DD/MM/YYYY"
                                )
                                    ? "DD/MM/YYYY"
                                    : moment(item?.planned_shipped_date).format(
                                        "DD/MM/YYYY"
                                    )}
                              </span>
                                                            <div className="project__block-item-calendar project-table__field">
                                                                <CustomDatePicker
                                                                    portalId="root-portal"
                                                                    autoComplete="off"
                                                                    id="approved_date"
                                                                    placeholderText={
                                                                        item?.planned_shipped_date ? moment(item?.planned_shipped_date).format(
                                                                            "DD/MM/YYYY"
                                                                        ) : translations?.['main.main.placeholder.dd_mm_yyyy'] }
                                                                    dateFormat="dd/MM/yyyy"
                                                                    selected={
                                                                        currentExpandedItem?.planned_shipped_date
                                                                            ? moment(
                                                                                currentExpandedItem?.planned_shipped_date
                                                                            ).toDate()
                                                                            : ""
                                                                    }
                                                                    onChange={(date) => {
                                                                        setCurrentExpandedItem((old) => ({
                                                                            ...old,
                                                                            planned_shipped_date: date,
                                                                        }));
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="project-table__td">
                              <span>
                                {item?.delivery_complete ? moment(item?.actual_shipped_date).format(
                                    "DD/MM/YYYY"
                                ) === "Invalid date" ||
                                !moment(item?.actual_shipped_date).format(
                                    "DD/MM/YYYY"
                                )
                                    ? ""
                                    : moment(item?.actual_shipped_date).format(
                                        "DD/MM/YYYY"
                                    ) : 'DD/MM/YYYY'}
                              </span>
                                                            <div className="project__block-item-calendar project-table__field">
                                                                <CustomDatePicker
                                                                    portalId="root-portal"
                                                                    autoComplete="off"
                                                                    id="approved_date"
                                                                    placeholderText={
                                                                        item?.actual_shipped_date ? moment(item?.actual_shipped_date).format(
                                                                            "DD/MM/YYYY"
                                                                        ) : translations?.['main.main.placeholder.dd_mm_yyyy'] }
                                                                    dateFormat="dd/MM/yyyy"
                                                                    selected={
                                                                        currentExpandedItem?.actual_shipped_date
                                                                            ? moment(
                                                                                currentExpandedItem?.actual_shipped_date
                                                                            ).toDate()
                                                                            : ""
                                                                    }
                                                                    onChange={(date) => {
                                                                        setCurrentExpandedItem((old) => ({
                                                                            ...old,
                                                                            actual_shipped_date: date,
                                                                        }));
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="project-table__td">
                              <span>
                                {moment(item?.planned_arrival_date).format(
                                    "DD/MM/YYYY"
                                ) === "Invalid date" ||
                                !moment(item?.planned_arrival_date).format(
                                    "DD/MM/YYYY"
                                )
                                    ? "DD/MM/YYYY"
                                    : moment(item?.planned_arrival_date).format(
                                        "DD/MM/YYYY"
                                    )}
                              </span>
                                                            <div className="project__block-item-calendar project-table__field">
                                                                <CustomDatePicker
                                                                    portalId="root-portal"
                                                                    autoComplete="off"
                                                                    id="approved_date"
                                                                    placeholderText={
                                                                        item?.planned_arrival_date ? moment(item?.planned_arrival_date).format(
                                                                            "DD/MM/YYYY"
                                                                        ) : translations?.['main.main.placeholder.dd_mm_yyyy'] }
                                                                    dateFormat="dd/MM/yyyy"
                                                                    selected={
                                                                        currentExpandedItem?.planned_arrival_date
                                                                            ? moment(
                                                                                currentExpandedItem?.planned_arrival_date
                                                                            ).toDate()
                                                                            : ""
                                                                    }
                                                                    onChange={(date) => {
                                                                        setCurrentExpandedItem((old) => ({
                                                                            ...old,
                                                                            planned_arrival_date: date,
                                                                        }));
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="project-table__td">
                              <span>
                                {item?.delivery_complete ? moment(item?.actual_arrival_date).format(
                                    "DD/MM/YYYY"
                                ) === "Invalid date" ||
                                !moment(item?.actual_arrival_date).format(
                                    "DD/MM/YYYY"
                                )
                                    ? ""
                                    : moment(item?.actual_arrival_date).format(
                                        "DD/MM/YYYY"
                                    ) : "DD/MM/YYYY"}
                              </span>
                                                            <div className="project__block-item-calendar project-table__field">
                                                                <CustomDatePicker
                                                                    portalId="root-portal"
                                                                    autoComplete="off"
                                                                    id="approved_date"
                                                                    placeholderText={
                                                                        item?.actual_arrival_date ? moment(item?.actual_arrival_date).format(
                                                                            "DD/MM/YYYY"
                                                                        ) : translations?.['main.main.placeholder.dd_mm_yyyy'] }
                                                                    dateFormat="dd/MM/yyyy"
                                                                    selected={
                                                                        currentExpandedItem?.actual_arrival_date
                                                                            ? moment(
                                                                                currentExpandedItem?.actual_arrival_date
                                                                            ).toDate()
                                                                            : ""
                                                                    }
                                                                    onChange={(date) => {
                                                                        setCurrentExpandedItem((old) => ({
                                                                            ...old,
                                                                            actual_arrival_date: date,
                                                                        }));
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="project-table__td">
                              <span>
                                {moment(item?.warehouse_planned_date).format(
                                    "DD/MM/YYYY"
                                ) === "Invalid date" ||
                                !moment(item?.warehouse_planned_date).format(
                                    "DD/MM/YYYY"
                                )
                                    ? "DD/MM/YYYY"
                                    : moment(item?.warehouse_planned_date).format(
                                        "DD/MM/YYYY"
                                    )}
                              </span>
                                                            <div className="project__block-item-calendar project-table__field">
                                                                <CustomDatePicker
                                                                    portalId="root-portal"
                                                                    autoComplete="off"
                                                                    id="approved_date"
                                                                    placeholderText={
                                                                        item?.warehouse_planned_date ? moment(item?.warehouse_planned_date).format(
                                                                            "DD/MM/YYYY"
                                                                        ) : translations?.['main.main.placeholder.dd_mm_yyyy'] }
                                                                    dateFormat="dd/MM/yyyy"
                                                                    selected={
                                                                        currentExpandedItem?.warehouse_planned_date
                                                                            ? moment(
                                                                                currentExpandedItem?.warehouse_planned_date
                                                                            ).toDate()
                                                                            : ""
                                                                    }
                                                                    onChange={(date) => {
                                                                        setCurrentExpandedItem((old) => ({
                                                                            ...old,
                                                                            warehouse_planned_date: date,
                                                                        }));
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="project-table__td">
                              <span>
                                {item?.delivery_complete ? moment(item?.warehouse_actual_date).format(
                                    "DD/MM/YYYY"
                                ) === "Invalid date" ||
                                !moment(item?.warehouse_actual_date).format(
                                    "DD/MM/YYYY"
                                )
                                    ? ""
                                    : moment(item?.warehouse_actual_date).format(
                                        "DD/MM/YYYY"
                                    ) : 'DD/MM/YYYY'}
                              </span>
                                                            <div className="project__block-item-calendar project-table__field">
                                                                <CustomDatePicker
                                                                    portalId="root-portal"
                                                                    autoComplete="off"
                                                                    id="approved_date"
                                                                    placeholderText={
                                                                        item?.warehouse_actual_date ? moment(item?.warehouse_actual_date).format(
                                                                            "DD/MM/YYYY"
                                                                        ) : translations?.['main.main.placeholder.dd_mm_yyyy'] }
                                                                    dateFormat="dd/MM/yyyy"
                                                                    selected={
                                                                        currentExpandedItem?.warehouse_actual_date
                                                                            ? moment(
                                                                                currentExpandedItem?.warehouse_actual_date
                                                                            ).toDate()
                                                                            : ""
                                                                    }
                                                                    onChange={(date) => {
                                                                        setCurrentExpandedItem((old) => ({
                                                                            ...old,
                                                                            warehouse_actual_date: date,
                                                                        }));
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="project-table__td">
                              <span>
                                {moment(item?.planned_delivery_date).format(
                                    "DD/MM/YYYY"
                                ) === "Invalid date" ||
                                !moment(item?.planned_delivery_date).format(
                                    "DD/MM/YYYY"
                                )
                                    ? "DD/MM/YYYY"
                                    : moment(item?.planned_delivery_date).format(
                                        "DD/MM/YYYY"
                                    )}
                              </span>
                                                            <div className="project__block-item-calendar project-table__field">
                                                                <CustomDatePicker
                                                                    portalId="root-portal"
                                                                    autoComplete="off"
                                                                    id="approved_date"
                                                                    placeholderText={
                                                                        item?.planned_delivery_date ? moment(item?.planned_delivery_date).format(
                                                                            "DD/MM/YYYY"
                                                                        ) : translations?.['main.main.placeholder.dd_mm_yyyy'] }
                                                                    dateFormat="dd/MM/yyyy"
                                                                    selected={
                                                                        currentExpandedItem?.planned_delivery_date
                                                                            ? moment(
                                                                                currentExpandedItem?.planned_delivery_date
                                                                            ).toDate()
                                                                            : ""
                                                                    }
                                                                    onChange={(date) => {
                                                                        setCurrentExpandedItem((old) => ({
                                                                            ...old,
                                                                            planned_delivery_date: date,
                                                                        }));
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="project-table__td">
                              <span>
                                {item?.delivery_complete ? moment(item?.actual_delivery_date).format(
                                    "DD/MM/YYYY"
                                ) === "Invalid date" ||
                                !moment(item?.actual_delivery_date).format(
                                    "DD/MM/YYYY"
                                )
                                    ? ""
                                    : moment(item?.actual_delivery_date).format(
                                        "DD/MM/YYYY"
                                    ) : "DD/MM/YYYY"}
                              </span>
                                                            <div className="project__block-item-calendar project-table__field">
                                                                <CustomDatePicker
                                                                    portalId="root-portal"
                                                                    autoComplete="off"
                                                                    id="approved_date"
                                                                    placeholderText={
                                                                        item?.actual_delivery_date ? moment(item?.actual_delivery_date).format(
                                                                            "DD/MM/YYYY"
                                                                        ) : translations?.['main.main.placeholder.dd_mm_yyyy'] }
                                                                    dateFormat="dd/MM/yyyy"
                                                                    selected={
                                                                        currentExpandedItem?.actual_delivery_date
                                                                            ? moment(
                                                                                currentExpandedItem?.actual_delivery_date
                                                                            ).toDate()
                                                                            : ""
                                                                    }
                                                                    onChange={(date) => {
                                                                        setCurrentExpandedItem((old) => ({
                                                                            ...old,
                                                                            actual_delivery_date: date,
                                                                        }));
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="project__block-btns">
                                                            {currentExpandedItem ? (
                                                                <div
                                                                    className="project__block-cancel"
                                                                    onClick={() => {
                                                                        setCurrentExpandedItem(null);
                                                                    }}
                                                                >
                                                                    <img
                                                                        src="/assets/icons/close-mark.svg"
                                                                        alt="close"
                                                                    />
                                                                    {translations?.['goods_equipment.goods_equipment_packages_status.actions.cancel']}
                                                                </div>
                                                            ) : (<></>)
                                                            }
                                                        </div>
                                                    </form>
                                                </div>
                                            </>
                                        ) : null}
                                    </>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
