import React, { useState, useEffect } from 'react';
import './index.scss';
import SubSectionTableContainer from "../SubSectionTableContainer";

const MonitoringTable = ({ data, translations, isCashflow }) => {
    const [hasLongText, setHasLongText] = useState(false);

    useEffect(() => {
        const checkLongText = () => {
            if (data?.data) {
                for (let item of data.data) {
                    if (item?.cumulative_actual?.length >6) {
                        setHasLongText(true);
                        return;
                    }
                }
            }
            setHasLongText(false);
        };

        checkLongText();
    }, [data]);

    const tdStyle = hasLongText ? { width: '150px' } : { width: '120px' };

    return (
        <SubSectionTableContainer
            isList={true}
            list={[data?.contract_title, data?.contract_number]}
            className={"progress-table"}
        >
            <table>
                <thead>
                <tr>
                    <th style={{ width: '160px' }}>{translations?.['lists_of_tables.physical_progress_of_contracts.titles.month']}</th>
                    {data?.data?.map((item, index) => (
                        <th style={{ ...tdStyle }} key={index}>{item.date}</th>
                    ))}
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="label planned" style={{ width: '160px' }}>
                        <span className="planned-value" />
                        <span>{
                        translations?.['lists_of_tables.physical_progress_of_contracts.titles.cumulative_planned'] || translations?.['lists_of_tables.cashflow_of_contracts.titles.cumulative_planned']
                        }</span>
                    </td>
                    {data?.data?.map((item, index) => (
                        <td key={index} style={{ ...tdStyle }}>{item?.cumulative_planned}</td>
                    ))}
                </tr>
                <tr>
                    <td className="label actual" style={{ width: '160px' }}>
                        <span className="actual-value" />
                        <span>{
                        translations?.['lists_of_tables.physical_progress_of_contracts.titles.cumulative_actual'] || translations?.['lists_of_tables.cashflow_of_contracts.titles.cumulative_actual']}
                        </span>
                    </td>
                    {data?.data?.map((item, index) => (
                        <td key={index} style={{ ...tdStyle }}>
                            {item?.cumulative_actual !== '%'
                                ? item?.cumulative_actual
                                : '---'}
                        </td>
                    ))}
                </tr>
                </tbody>
            </table>
            <tfoot>
            <tr>
                {/*Ernis baike poprasil bold*/}
                <td style={{fontWeight: 500}}>
                    {isCashflow ?
                        translations?.['lists_of_tables.cashflow_of_contracts.titles.total_cumulative_planned_project_payments_amount'] :
                        translations?.['lists_of_tables.physical_progress_of_contracts.titles.total_cumulative_planned']
                    }
                </td>
                <td style={{fontWeight: 500}} className="total planned-total">{data?.total_cumulative_planned}</td>
            </tr>
            <tr>
                <td style={{fontWeight: 500}}>
                    {isCashflow ?
                        translations?.['lists_of_tables.cashflow_of_contracts.titles.total_cumulative_actual_project_payments_amount']:
                        translations?.['lists_of_tables.physical_progress_of_contracts.titles.total_cumulative_actual']}
                </td>
                <td style={{fontWeight: 500}} className="total actual-total">{data?.total_cumulative_actual}</td>
            </tr>
            </tfoot>
        </SubSectionTableContainer>
    );
};

export default MonitoringTable;
