import React, {useEffect, useState} from "react";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend, ResponsiveContainer,
} from "recharts";
import {formatYAxis} from "../../global/formatYAxis";
import {bigNumberFormatter} from "../../global/bigNumberFormatter";

const LineChartWithXAxisPadding = ({charData, translations, tooltipSymbolBeforeValue='', tooltipSymbolAfterValue=''}) => {
    const [data, setData] = useState(charData);

    useEffect(() => {
        setData(charData)
    }, [charData]);

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        {payload.map((pld, index) => (
                            <div key={index} style={{ display: "inline-block", padding: 10 }}>
                                <div key={pld?.value} style={{ fontSize: 12 }}>{tooltipSymbolBeforeValue === '$' ? '$'+bigNumberFormatter(pld.value) : `${tooltipSymbolBeforeValue}${pld.value}${tooltipSymbolAfterValue}` }</div>
                                <div key={pld?.name} style={{ color: pld.color, fontSize: 12 }}>{pld.name}</div>
                            </div>
                        ))}
                    </div>
                </div>
            );
        }

        return null;
    };

    return (
        <ResponsiveContainer width="100%" aspect={3}>
        <LineChart width={500} height={300} data={data}  >
            <CartesianGrid strokeDasharray="3 0"  />
            <XAxis
                dataKey="date"
                padding={{ left: 50, right: 50 }}
                fontSize={12}
                tickMargin={10}
                allowDuplicatedCategory={false}
            />
            <YAxis
                orientation={"right"}
                type="number"
                tickFormatter={(tick) => {
                    return tooltipSymbolBeforeValue === '$' ? formatYAxis(tick) : `${tick}${tooltipSymbolAfterValue}`;
                }}
                axisLine={false}
                tickLine={false}
                fontSize={12}
                tickMargin={15}
                tickPadding={20}
                width={100}
                allowDuplicatedCategory={false}

            />
            <Tooltip key={5} formatter={bigNumberFormatter} content={CustomTooltip}/>
            <Legend
                verticalAlign="bottom"
                wrapperStyle={{
                    bottom: -60,
                    fontSize: 14
                }}
            />
            <Line
                dot={false}
                strokeWidth={4}
                strokeLinecap="round"
                stroke="#3B7AD9"
                name={translations?.['lists_of_tables.physical_progress_of_contracts.titles.cumulative_planned'] || translations?.['lists_of_tables.cashflow_of_contracts.titles.cumulative_planned'] || translations?.['cumulative_planned']}
                type="monotone"
                dataKey="planned"
                isAnimationActive={false}
                activeDot={{ r: 8 }}
            />
            <Line
                dot={false}
                strokeWidth={4}
                strokeLinecap="round"
                stroke="#FFD023"
                name={translations?.['lists_of_tables.physical_progress_of_contracts.titles.cumulative_actual'] || translations?.['lists_of_tables.cashflow_of_contracts.titles.cumulative_actual'] || translations?.['cumulative_actual']}
                type="monotone"
                dataKey="actual"
                isAnimationActive={false}
            />
        </LineChart>
        </ResponsiveContainer>
    );
}
export default LineChartWithXAxisPadding
