import { useDispatch } from "react-redux";
import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import Loan from "./Loan";
import Overview from "./Overview";
import Covenants from "./Covenants";
import ListOfTables from "./ListOfTables";
import FinancingPlan from "./FinancingPlan";
import Wrapper from "../../components/Wrapper";
import LoanUtilization from "./LoanUtilization";
import Documents from "../../components/Project/Documents";
import DevelopmentObjectives from "./DevelopmentObjectives";
import ImplementationProgress from "./ImplementationProgress";
import { SitePhotographs } from "../../components/Project/SitePhotographs";
import { errorHandle } from "../../global/errorHandle";
import { setLoader } from "../../store/slice/dashboard";
import {
    useLazyGetQRDocumentsQuery, useLazyGetQuarterlyPhotographsQuery,
    useLazyGetQuarterlyReportDetailsPageQuery,
    useLazyGetQuarterlyReportDetailsQuery,
} from "../../store/service/adbService";
import './index.scss';

const emptyDataText = "No Activities";

const EditQuarterlyReport = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const refDocuments = useRef(null);
    const [pageTranslations, setPageTranslations] = useState({});
    const [reportData, setReportData] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [reportSectionsData, setReportSectionsData] = useState([]);
    const [sections, setSections] = useState([]);
    const reportId = location?.pathname?.split("/")[2];
    const [isReportSubmitted, setIsReportSubmitted] = useState(false);

    const [activeSection, setActiveSection] = useState('overview');

    const [getQuarterlyReportDetailsPage, {
        data: pageTranslationsData,
        isLoading: pageTranslationsIsLoading,
        error: pageTranslationsError,
        isError: pageTranslationsIsError,
        isSuccess: pageTranslationsIsSuccess,
    }] = useLazyGetQuarterlyReportDetailsPageQuery();

    const [getQuarterlyReportDetails, {
        data: quarterlyReportData,
        isLoading: quarterlyReportIsLoading,
        error: quarterlyReportError,
        isError: quarterlyReportIsError,
        isSuccess: quarterlyReportIsSuccess,
    }] = useLazyGetQuarterlyReportDetailsQuery();

    const [getQRDocuments, {
        data: documentsData,
        isLoading: documentsDataIsLoading,
        error: documentsDataError,
        isSuccess: documentsDataIsSuccess,
    }] = useLazyGetQRDocumentsQuery();

    const [getQuarterlyPhotographs, {
        data: sitePhotographs,
    }] = useLazyGetQuarterlyPhotographsQuery();

    const handleScroll = () => {
        const sectionOffsets = Object.keys(sections).map(key => ({
            id: key,
            offsetTop: document.getElementById(key)?.offsetTop || 0,
            height: document.getElementById(key)?.offsetHeight || 0,
        }));

        const scrollPosition = window.scrollY;
        const viewportHeight = window.innerHeight;
        const bottomOfScreen = scrollPosition + viewportHeight;

        const currentSection = sectionOffsets.reduce((prev, curr, index, array) => {
            const nextSection = array[index + 1];

            if (index === array.length - 2 && bottomOfScreen > curr.offsetTop  && bottomOfScreen < nextSection.offsetTop) {

                return curr;
            }

            if (index === array.length - 1 && bottomOfScreen >= curr.offsetTop) {

                return curr;
            }

            return scrollPosition >= curr.offsetTop - 50 ? curr : prev;
        }, sectionOffsets[0]);

        if (currentSection) {
            setActiveSection(currentSection.id);
        }
    };

    const scrollToSection = (id) => {
        document.getElementById(id).scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
        setActiveSection(id);
    };

    const updateDocuments = () => {
        getQRDocuments(reportId);
    };

    const getSitePhotographs = () => {
        getQuarterlyPhotographs(reportId);
    };

    useEffect(() => {
        getQuarterlyReportDetailsPage();
    }, []);

    useEffect(() => {
        if (reportId) {
            localStorage.removeItem("edit-report-data");
            getQuarterlyReportDetails(reportId);
        }
    }, [reportId]);

    useEffect(() => {
        if (documentsDataIsSuccess) {
            setDocuments(documentsData?.data);
        }
    }, [documentsDataIsSuccess, documentsData]);

    useEffect(() => {
        if (pageTranslationsData?.data) {
            setPageTranslations(pageTranslationsData?.data?.translations);
        }
    }, [pageTranslationsData, pageTranslationsIsSuccess]);

    useEffect(() => {
        if (quarterlyReportIsSuccess) {
            const { sections, ...otherDetails } = quarterlyReportData?.data?.details;
            setSections({  'overview': pageTranslations?.['index.index.headers.overview'] , ...quarterlyReportData?.data?.side_bar});
            setReportSectionsData(sections);
            setReportData(otherDetails);
            setIsReportSubmitted(otherDetails?.submitted)
            setDocuments(sections?.documents);
        }
    }, [quarterlyReportData, quarterlyReportIsSuccess,pageTranslations]);

    useEffect(() => {
        pageTranslationsIsLoading ||
        documentsDataIsLoading ||
        quarterlyReportIsLoading ?
            dispatch(setLoader(true)) :
            dispatch(setLoader(false));
    }, [pageTranslationsIsLoading, quarterlyReportIsLoading, documentsDataIsLoading]);

    useEffect(() => {
        if (pageTranslationsIsError) {
            errorHandle(pageTranslationsError);
        }
        if (quarterlyReportIsError) {
            errorHandle(quarterlyReportError);
        }
        if (documentsDataError) {
            errorHandle(documentsDataError);
        }
    }, [pageTranslationsIsError, pageTranslationsError, quarterlyReportError, quarterlyReportIsError, documentsDataError]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [sections]);

    if (pageTranslationsIsLoading || quarterlyReportIsLoading) {
        return (
            <div style={{ height: '70vh', width: '100%', display: "flex", justifyContent: "center", alignItems: 'center', flexDirection: "column" }}>
                <img src="/assets/images/projects.png" alt="" />
                <span style={{ color: "#767676", fontSize: 16, fontWeight: 500 }}>{(pageTranslationsIsLoading || quarterlyReportIsLoading) ? 'Loading...' : emptyDataText}</span>
            </div>
        );
    }

    if (pageTranslationsIsError || quarterlyReportIsError) {
        return (
            <div style={{ height: '70vh', width: '100%', display: "flex", justifyContent: "center", alignItems: 'center', flexDirection: "column" }}>
                <img src="/assets/images/projects.png" alt="" />
                <span style={{ color: "#767676", fontSize: 16, fontWeight: 500 }}>Something went wrong!</span>
            </div>
        );
    }

    const hiddenSectionHandler = (sectionRef) => {
        sectionRef.current.classList.toggle('editReport__section_hidden')
    }

    return (
        <Wrapper classname="editReport">
            <aside className="s-page__sidebar">
                {sections && Object.keys(sections)?.map(key => (
                    <div
                        key={key}
                        className={`s-page__sidebar-item ${activeSection === key ? 'active' : ''}`}
                        onClick={() => scrollToSection(key)}
                    >
                        {sections?.[key]}
                    </div>
                ))}
            </aside>
            <main className={`${sections?.length> 0 ? '':'editReport__main'}`}>
                <div>
                    <header>
                        <nav className="project__nav">
                            <Link to="/quarterly-reports">{pageTranslations?.['index.index.headers.quarterly_reports']}</Link> <span>/</span>{" "}
                            {reportData?.report_name}
                        </nav>
                        <div className="editReport__lastUpdateInfo">
                            {pageTranslations?.['index.index.titles.last_update']} {reportData?.last_update}
                        </div>
                    </header>
                    <div id="overview">
                        <Overview translations={pageTranslations}
                                  reportData={reportData}
                                  fetchReportData={getQuarterlyReportDetails}
                                  setIsReportSubmitted={setIsReportSubmitted}
                                  isReportSubmitted={isReportSubmitted}
                        />
                    </div>
                    {sections.hasOwnProperty('loan_grant_key_data') &&
                        <div id="loan_grant_key_data">
                            <Loan translations={pageTranslations} data={reportSectionsData} hiddenSectionHandler={hiddenSectionHandler} />
                        </div>
                    }
                </div>
                {sections.hasOwnProperty('financing_plan') &&
                    <div id="financing_plan">
                        <FinancingPlan translations={pageTranslations}
                            data={reportSectionsData?.financing_plan}
                            reportId={reportData?.id}
                            hiddenSectionHandler={hiddenSectionHandler}
                            isReportSubmitted={isReportSubmitted}
                        />
                    </div>
                }
                {sections.hasOwnProperty('financing_plan') &&
                    <div id="loan_utilization">
                        <LoanUtilization translations={pageTranslations}
                             data={reportSectionsData?.loan_utilization}
                             reportId={reportData?.id}
                             hiddenSectionHandler={hiddenSectionHandler}
                             isReportSubmitted={isReportSubmitted}
                        />
                    </div>
                }
                {sections.hasOwnProperty('development_objectives') &&
                    <div id="development_objectives">
                        <DevelopmentObjectives
                            reportId={reportData?.id}
                            data={reportSectionsData?.development_objectives}
                            translations={pageTranslations}
                            hiddenSectionHandler={hiddenSectionHandler}
                            isReportSubmitted={isReportSubmitted}
                        />
                    </div>
                }
                {sections.hasOwnProperty('implementation_progress') &&
                    <div id="implementation_progress">
                        <ImplementationProgress
                            reportId={reportData?.id}
                            data={reportSectionsData?.implementation_progress}
                            translations={pageTranslations}
                            hiddenSectionHandler={hiddenSectionHandler}
                            isReportSubmitted={isReportSubmitted}
                        />
                    </div>
                }
                {(sections?.hasOwnProperty('financial_covenants') ||
                        sections?.hasOwnProperty('loan_covenants') ||
                        sections?.hasOwnProperty('safeguards_covenants') ||
                        sections?.hasOwnProperty('environmental_covenants')  ||
                        sections?.hasOwnProperty('gender_covenants')  ) &&
                    <Covenants
                        reportId={reportData?.id}
                        data={reportSectionsData?.covenants}
                        translations={pageTranslations}
                        hiddenSectionHandler={hiddenSectionHandler}
                        isReportSubmitted={isReportSubmitted}
                        sections={sections}
                    />
                }
                {sections.hasOwnProperty('lists_of_tables') &&
                    <div id="lists_of_tables">
                        <ListOfTables
                            reportId={reportData?.id}
                            data={reportSectionsData?.lists_of_tables}
                            translations={pageTranslations}
                            hiddenSectionHandler={hiddenSectionHandler}
                        />
                    </div>
                }
                {sections.hasOwnProperty('site_photographs') &&
                    <div id="site_photographs">
                        <SitePhotographs
                            isQuarterReport={true}
                            deleteUrl={'quarterly-report/site-photographs/delete'}
                            updateUrl={'quarterly-report/site-photographs/update'}
                            createUrl={'quarterly-report/site-photographs/create'}
                            glossaryData={pageTranslations}
                            reportId={reportId}
                            data={sitePhotographs?.data || reportSectionsData?.site_photographs}
                            getSitePhotographs={getSitePhotographs}
                            translations={pageTranslations}
                            className="sitePhtographsContainer"
                            isReportSubmitted={isReportSubmitted}
                        />
                    </div>}
                {sections.hasOwnProperty('documents') &&
                    <div id="documents">
                        <Documents
                            isQuarterReport={true}
                            translations={pageTranslations}
                            deleteUrl={'quarterly-report/document/delete'}
                            updateUrl={'quarterly-report/document/update'}
                            createUrl={'quarterly-report/document/create'}
                            refDocuments={refDocuments}
                            data={{
                                id: reportData?.id,
                                documents: documents
                            }}
                            glossaryData={{}}
                            updateDocuments={updateDocuments}
                            reportId={reportData?.id}
                            isReportSubmitted={isReportSubmitted}
                        />
                    </div>
                }
            </main>
        </Wrapper>
    )
}

export default EditQuarterlyReport;
