import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {
    useDeleteProjectImageMutation,
    useDeleteReportCoverPhotoMutation,
    useGetAllGlossaryQuery,
    useLazyGetProjectQuery,
    useUpdateProjectMutation,
    useUpdateReportCoverMutation,
    useLazyGetReportsDetailsTranslationsQuery
} from "../../store/service/adbService";
import { setLoader } from "../../store/slice/dashboard";
import Wrapper from "../../components/Wrapper";
import ReportModal from "../../components/ReportModal";
import ContractKeyData from "./components/ContractKeyData";
import RedirectModal from "../../components/RedirectModal";
import Documents from "../../components/Project/Documents";
import { useErrorHandler } from "../../global/ErrorsHandler";
import { Disputes } from "../../components/Project/Disputes";
import { LarpStart } from "../../components/Project/Activities2";
import { Environment } from "../../components/Project/Environment";
import { PermitsTable } from "../../components/Project/PermitsTable";
import { GeneralStart } from "../../components/Project/GeneralStart";
import { GoodsEquipment } from "../../components/Project/GoodsEquipment";
import { RiskManagement } from "../../components/Project/RiskManagement";
import { SitePhotographs } from "../../components/Project/SitePhotographs";
import { CompletionOfWork } from "../../components/Project/CompletionOfWork";
import { ProjectMonitoring } from "../../components/Project/ProjectMonitoring";
import { ExecutiveSummaryProject } from "../../components/Project/ExecutiveSummary";
import { FinancialManagement } from "../../components/Project/FinancialManagement";
import { ApprovedContractTable } from "../../components/Project/ApprovedContractTable";
import { MobilizationActivities } from "../../components/Project/MobilizationActivities";
import { EngineeringDesignDocuments } from "../../components/Project/EngineeringDesignDocuments";
import { CommunicationsAndReporting } from "../../components/Project/CommunicationsAndReporting";
import "./index.scss";
import MoreInfoModal from "../../components/MoreInfoModal";

export default function Project() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const user = useSelector((state) => state?.adbReducer?.user);
    const location = useLocation();
    const params = {
        project: id,
    };
    const [loader, setLocalLoader] = useState(false);
    const refContractDetails = useRef(null);
    const refChangesTable = useRef(null);
    const overview = useRef(null);
    const refPermits = useRef(null);
    const refExecutiveSummary = useRef(null);
    const refGeneralStartUp = useRef(null);
    const refActivities = useRef(null);
    const refCover = useRef(null);
    const refMobilizationActivities = useRef(null);
    const refProgramProgress = useRef(null);
    const refSitePhotographs = useRef(null);
    const refFinancialManagement = useRef(null);
    const refDisputes = useRef(null);
    const refRiskManagement = useRef(null);
    const refGoodsEquipment = useRef(null);
    const refEngineering = useRef(null);
    const refEnvironment = useRef(null);
    const refCommunications = useRef(null);
    const refCompletion = useRef(null);
    const refDocuments = useRef(null);
    const [offsetTop, setOffsetTop] = useState(null);
    const [imageLoader, setImageLoader] = useState(false);
    const [infoModal, setInfoModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(null);
    const [currentId, setCurrentId] = useState(null);
    const [previewStatus, setPreviewStatus] = useState(false);
    const [currentImg, setCurrentImg] = useState("");
    const [modal, setModal] = useState(false);
    const [showDropDown, setShowDropDown] = useState(false);
    const [show, setShow] = useState(true);
    const [editDropdown, setEditDropdown] = useState(false);
    const [reportCover, setReportCover] = useState(false);
    const [moreInfo, setMoreInfo] = useState([]);
    const [reportModal, setReportModal] = useState(false);
    const [redirectModal, setRedirectModal] = useState(false);
    const [translations, setTranslations] = useState([]);

    const [
        trigger,
        { data, isError, error: projectError, isSuccess, isLoading },
    ] = useLazyGetProjectQuery();

    const [
        getReportsDetailsTranslations,
        {
            data: reportDetailsTranslationsData,
            isError: reportDetailsTranslationsError,
            isLoading: reportDetailsTranslationsLoading
        }] = useLazyGetReportsDetailsTranslationsQuery()

    const [
        updateReportCover,
        {
            data: updateReportCoverData,
            isError: updateReportCoverIsError,
            isLoading: updateReportCoverIsLoading,
            isSuccess: updateReportCoverIsSuccess,
            error: updateReportCoverError,
        },
    ] = useUpdateReportCoverMutation();

    const [
        deleteReportCoverPhoto,
        {
            data: deleteReportCoverPhotoData,
            isError: deleteReportCoverPhotoIsError,
            isLoading: deleteReportCoverPhotoIsLoading,
            isSuccess: deleteReportCoverPhotoIsSuccess,
            error: deleteReportCoverPhotoError,
        },
    ] = useDeleteReportCoverPhotoMutation();

    const [
        deleteProjectImage,
        {
            data: deleteProjectImageData,
            isError: deleteProjectImageIsError,
            isLoading: deleteProjectImageIsLoading,
            isSuccess: deleteProjectImageIsSuccess,
            error: deleteProjectImageError,
        },
    ] = useDeleteProjectImageMutation();

    const {
        data: glossaryData,
        error: glossaryError,
        isLoading: glossaryIsLoading,
        isSuccess: glossaryIsSuccess,
        isError: glossaryIsError,
    } = useGetAllGlossaryQuery();

    const [reportCoverTitle, setReportCoverTitle] = useState({
        title: data?.data?.contract_title ?? null,
        prevTitle: data?.data?.contract_title ?? null,
        edit: false,
    });
    const [reportCoverSubtitle, setReportCoverSubtitle] = useState({
        title: "" ?? null,
        prevTitle: "" ?? null,
        edit: false,
    });

    const [descrOne, setDescrOne] = useState({
        title: "" ?? null,
        prevTitle: "" ?? null,
        edit: false,
    });
    const [descrTwo, setDescrTwo] = useState({
        title: "" ?? null,
        prevTitle: "" ?? null,
        edit: false,
    });
    const [descrThree, setDescrThree] = useState({
        title: "" ?? null,
        prevTitle: "" ?? null,
        edit: false,
    });
    const [imageOne, setImageOne] = useState({
        image: data?.data?.latest_report?.image_1 ?? "",
        type: "image_1",
        edit: false,
    });
    const [imageTwo, setImageTwo] = useState({
        image: data?.data?.latest_report?.image_2 ?? "",
        type: "image_2",
        edit: false,
    });
    const [imageThree, setImageThree] = useState({
        image: data?.data?.latest_report?.image_3 ?? "",
        type: "image_3",
        edit: false,
    });

    useEffect(() => {
        getReportsDetailsTranslations()
    }, [])

    useEffect(() => {
        setTranslations(reportDetailsTranslationsData?.data?.translations)
    }, [reportDetailsTranslationsData])

    useEffect(() => {
        setMoreInfo(
            glossaryData?.data?.filter((el) => el?.section_name === "Report cover")[0]
        );
        if (data?.data) {
            setReportCoverTitle({
                title: data?.data?.latest_report?.title ?? "",
                prevTitle: data?.data?.latest_report?.title ?? "",
                edit: false,
            });
            setReportCoverSubtitle({
                title: data?.data?.latest_report?.type ?? "",
                prevTitle: data?.data?.latest_report?.type ?? "",
                edit: false,
            });
            setDescrOne({
                title: data?.data?.latest_report?.description_1 ?? "",
                prevTitle: data?.data?.latest_report?.description_1 ?? "",
                edit: false,
            });
            setDescrTwo({
                title: data?.data?.latest_report?.description_2 ?? "",
                prevTitle: data?.data?.latest_report?.description_2 ?? "",
                edit: false,
            });
            setDescrThree({
                title: data?.data?.latest_report?.description_3 ?? "",
                prevTitle: data?.data?.latest_report?.description_3 ?? "",
                edit: false,
            });
            setImageOne({
                image: data?.data?.latest_report?.image_1 ?? "",
                type: "image_1",
                edit: false,
            });
            setImageTwo({
                image: data?.data?.latest_report?.image_2 ?? "",
                type: "image_2",
                edit: false,
            });
            setImageThree({
                image: data?.data?.latest_report?.image_3 ?? "",
                type: "image_3",
                edit: false,
            });
        }
    }, [data]);

    const [errorHandler, setErrorHandler] = useErrorHandler([
        projectError,
        updateReportCoverError,
    ]);

    useEffect(() => {
        !projectError && trigger(params?.project ?? localStorage.getItem("itemId"));
        if (projectError || updateReportCoverIsError) {
            setReportCoverTitle("");
        }
    }, [updateReportCoverIsError]);

    const [
        updateProject,
        {
            // data: updateData,
            // isSuccess: updateSuccess,
            // error: updateError,
            // isError: updateIsError,
            // isLoading: updateLoading,
        },
    ] = useUpdateProjectMutation();

    const uploadImage = (e, id, type) => {
        const lanCode = localStorage.getItem("local_lan" )
        setLocalLoader(true);
        setShowDropDown(false);
        const headers = {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ` + localStorage?.getItem("user_token"),
            "AppLocale": `${lanCode ? lanCode : 'en'}`
        };
        if (
            (id == 1 &&
                e?.target?.files[0]?.size > 0 &&
                e?.target?.files[0]?.size < 20000000) ||
            (id == 2 &&
                data?.data?.images?.length != 2 &&
                e?.target?.files[0]?.size > 0 &&
                e?.target?.files[0]?.size < 10000000)
        ) {
            axios
                .post(
                    `${
                        id === "2"
                            ? `${process.env.REACT_APP_API_URL}/projects/${data?.data?.id}/report-cover-upload-image/`
                            : `${process.env.REACT_APP_API_URL}/projects/${data?.data?.id}`
                    }`,
                    id === "2"
                        ? { image: e.target.files[0], type }
                        : { preview_image: e.target.files[0] },
                    { headers: headers }
                )
                .then((res) => {
                    id == 1 ? setImageLoader(true) : setImageLoader(false);
                    setLocalLoader(false);
                    trigger(params?.project ?? localStorage.getItem("itemId"));
                })
                .catch((err) => {
                    setImageLoader(false);
                    setLocalLoader(false);
                    if (err.response.status == 401) {
                        navigate("/login");
                        localStorage.removeItem("user_token");
                    } else {
                        toast.error(
                            err?.response?.data?.errors?.preview_image[0] ||
                            err?.response?.data?.errors?.image[0],
                            {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 8000,
                            }
                        );
                    }
                });
        } else {
            setImageLoader(false);
            setLocalLoader(false);
            toast.error(
                id == 2 && data?.data?.images?.length == 2
                    ? "Maximum images allowed is 2"
                    : `The maximum image size must be ${id == 1 ? "20MB" : "10MB"}`,
                {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 8000,
                }
            );
        }
    };

    const hideLoader = () => {
        setImageLoader(false);
    };

    const previewReport = () => {
        const lanCode = localStorage.getItem("local_lan" );
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ` + localStorage.getItem("user_token"),
            "AppLocale": `${lanCode ? lanCode : 'en'}`
        };
        dispatch(setLoader(true));
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/report/preview/${
                    params?.project ?? localStorage.getItem("itemId")
                }`,
                {
                    headers: headers,
                    responseType: "blob",
                }
            )
            .then(({ data: blob }) => {
                const link = document.createElement("a");
                const url = URL.createObjectURL(blob);
                link.href = url;
                link.download = `report_${moment()?.format("DD_MM_YYYY")}.docx`;
                link.click();
                dispatch(setLoader(false));
            })
            .catch((e) => {
                dispatch(setLoader(false));
            });
    };

    const removeImage = () => {
        setModal(false);
        setLocalLoader(true);
        setLoader(true);
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ` + localStorage.getItem("user_token"),
        };
        axios
            .delete(
                `${process.env.REACT_APP_API_URL}/projects/remove-images/${
                    params?.project ?? localStorage.getItem("itemId")
                }`,
                {
                    headers: headers,
                    data: {
                        image_id: currentImg?.id,
                    },
                }
            )
            .then((res) => {
                setLocalLoader(false);
                setLoader(false);
                trigger(params?.project ?? localStorage.getItem("itemId"));
            })
            .catch((err) => {
                setLocalLoader(false);
                setLoader(false);
                if (err.response.status == 401) {
                    navigate("/login");
                    localStorage.removeItem("user_token");
                }
            });
    };

    useEffect(() => {
        loader ||
        isLoading ||
        updateReportCoverIsLoading ||
        deleteReportCoverPhotoIsLoading ||
        deleteProjectImageIsLoading
            ? dispatch(setLoader(true))
            : dispatch(setLoader(false));
    }, [
        loader,
        isLoading,
        updateReportCoverIsLoading,
        deleteReportCoverPhotoIsLoading,
        updateReportCoverIsError,
        deleteProjectImageIsLoading,
    ]);

    useEffect(() => {
        const handleScroll = (event) => {
            setOffsetTop(window.scrollY);
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const reportSubmit = () => {
        const lanCode = localStorage.getItem("local_lan" )
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ` + localStorage.getItem("user_token"),
            "AppLocale": `${lanCode ? lanCode : 'en'}`
        };
        dispatch(setLoader(true));
        axios
            .post(
                `${process.env.REACT_APP_API_URL}/report/submit/${
                    params?.project ?? localStorage.getItem("itemId")
                }`,
                {},
                {
                    headers: headers,
                    responseType: "blob",
                }
            )
            .then(({ data: blob }) => {
                dispatch(setLoader(false));
                setRedirectModal(true);
                setReportModal(false);
            })
            .catch((e) => {
                toast.error("Report for this month already exist", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
                setReportModal(false);
                dispatch(setLoader(false));
            });
    };

    const deleteImg = () => {
        deleteProjectImage(params?.project ?? localStorage.getItem("itemId"));
    };

    useEffect(() => {
        if (data?.data) {
            let element = null;
            switch (location?.state?.tab) {
                case "cover":
                    element = refCover?.current;

                    break;
                case "contract_details":
                    element = refContractDetails?.current;

                    break;
                case "executive_summary":
                    element = refExecutiveSummary?.current;

                    break;
                case "permits":
                    element = refPermits?.current;

                    break;
                case "contract":
                    element = refActivities?.current;

                    break;
                case "mobilization_activities":
                    element = refMobilizationActivities?.current;

                    break;
                case "risk_management":
                    element = refRiskManagement?.current;

                    break;
                case "program_progress":
                    element = refProgramProgress?.current;

                    break;
                case "financial_management":
                    element = refFinancialManagement?.current;

                    break;
                case "disputes":
                    element = refDisputes?.current;

                    break;
                case "goods_equipment":
                    element = refGoodsEquipment?.current;

                    break;
                case "engineering":
                    element = refEngineering.current;

                    break;
                case "environment":
                    element = refEnvironment.current;
                    break;

                case "site_photographs":
                    element = refSitePhotographs?.current;
                    break;

                case "completion":
                    element = refCompletion?.current;
                    break;

                case "contract_details":
                    element = refCommunications?.current;
                    break;

                case "communications_reporting":
                    element = refCommunications?.current;
                    break;

                case "documents":
                    element = refDocuments?.current;
                    break;

                default:
                    break;
            }
            setTimeout(() => {
                if (element && element.scrollIntoView) {
                    element.scrollIntoView({ behavior: "smooth" });
                }
            }, 100);
        }
    }, [data?.data]);

    return (
        <Wrapper>
            {infoModal && (
                <MoreInfoModal
                    title={translations?.['report_cover.report_cover.headers.report_cover']}
                    moreInfo={translations?.['report_cover.report_cover.more_info.report_cover']}
                    close={()=> setInfoModal(false)}
                />
            )}
            {reportModal && (
                <ReportModal
                    translations={translations}
                    reportSubmit={reportSubmit}
                    close={() => setReportModal(!reportModal)}
                />
            )}
            {redirectModal && (
                <RedirectModal
                    translations={translations}
                    redirectModal={redirectModal}
                    close={() => setRedirectModal(!redirectModal)}
                />
            )}
            {data?.data?.latest_report?.date && (
                <div className="project__monthly-report">
                    {data?.data?.latest_report?.date_format.toUpperCase()}{" "}
                    {translations?.['main.main.titles.monthly_report']?.toUpperCase()}
                </div>
            )}
            <section className="project" ref={overview}>
                <div className="project__sidebar">
                    <div
                        className={`project__sidebar-item ${
                            (offsetTop >= 0 &&
                                offsetTop <= refContractDetails?.current?.offsetTop - 50) ||
                            offsetTop === null
                                ? "active"
                                : ""
                        }`}
                        onClick={() => {
                            overview.current.scrollIntoView();
                        }}
                    >
                        {translations?.['main.main.titles.overview']}
                    </div>
                    {data?.data?.project_settings?.contract_key_data ? (
                        <div
                            onClick={() => {
                                refContractDetails.current.scrollIntoView();
                            }}
                            className={`project__sidebar-item ${
                                offsetTop >= refContractDetails?.current?.offsetTop - 50 &&
                                offsetTop <= refChangesTable?.current?.offsetTop - 50
                                    ? "active"
                                    : ""
                            }`}
                            id={"contract_details"}
                        >
                            {translations?.['contract_key_data.contract_key_data.headers.contract_key_data']}
                        </div>
                    ) : null}
                    <div
                        onClick={() => {
                            refChangesTable.current.scrollIntoView();
                        }}
                        className={`project__sidebar-item ${
                            offsetTop >= refChangesTable?.current?.offsetTop - 50 &&
                            offsetTop <= refCover?.current?.offsetTop - 50
                                ? "active"
                                : ""
                        }`}
                        id={"approved_contract_changes_table"}
                    >
                        {translations?.['approved_contract_changes.approved_contract_changes.headers.approved_contract_changes']}
                    </div>
                    <div
                        onClick={() => {
                            refCover.current.scrollIntoView();
                        }}
                        className={`project__sidebar-item ${
                            offsetTop >= refCover?.current?.offsetTop - 50 &&
                            offsetTop <= refExecutiveSummary?.current?.offsetTop - 50
                                ? "active"
                                : ""
                        }`}
                        id={"cover"}
                    >
                        {translations?.['report_cover.report_cover.headers.report_cover']}
                    </div>
                    {data?.data?.project_settings?.executive_summary ? (
                        <div
                            onClick={() => {
                                refExecutiveSummary.current.scrollIntoView();
                            }}
                            className={`project__sidebar-item ${
                                offsetTop >= refExecutiveSummary?.current?.offsetTop - 50 &&
                                offsetTop <= refPermits?.current?.offsetTop - 50
                                    ? "active"
                                    : ""
                            }`}
                            id={"executive_summary"}
                        >
                            {translations?.['executive_summary.executive_summary.headers.executive_summary']}
                        </div>
                    ) : null}
                    {data?.data?.project_settings?.permits_licenses_approvals ? (
                        <div
                            onClick={() => {
                                refPermits.current.scrollIntoView();
                            }}
                            className={`project__sidebar-item ${
                                offsetTop >= refPermits?.current?.offsetTop - 50 &&
                                offsetTop <= refActivities?.current?.offsetTop - 50
                                    ? "active"
                                    : ""
                            }`}
                            id={"permits"}
                        >
                            {translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.headers.permits_licenses_and_approvals']}
                        </div>
                    ) : null}
                    {(data?.data?.project_settings?.residual_tender_stage_actionsata || data?.data?.project_settings?.larp_approval || data?.data?.project_settings?.contract_requirements || data?.data?.project_settings?.contract_activities) ? (
                        <div
                            onClick={() => {
                                refActivities.current.scrollIntoView();
                            }}
                            className={`project__sidebar-item ${
                                offsetTop >= refActivities?.current?.offsetTop - 50 &&
                                offsetTop <= refGeneralStartUp?.current?.offsetTop - 50
                                    ? "active"
                                    : ""
                            }`}
                            id={"contract"}
                        >
                            {translations?.['contract_start_up_activities_and_submittals.contract_start_up_activities_and_submittals.headers.contract_start_up_activities_and_submittals']}
                        </div>
                    ) : null}
                    {(data?.data?.project_settings?.residual_tender_stage_actionsata || data?.data?.project_settings?.contract_requirements || data?.data?.project_settings?.contract_activities) ? (
                        <div
                            onClick={() => {
                                refGeneralStartUp.current.scrollIntoView();
                            }}
                            className={`project__sidebar-item ${
                                offsetTop >= refGeneralStartUp?.current?.offsetTop - 50 &&
                                offsetTop <= refMobilizationActivities?.current?.offsetTop - 50
                                    ? "active"
                                    : ""
                            }`}
                            id={"general_startup"}
                        >
                            {translations?.['general_start_up_activities.general_start_up_activities.headers.general_start_up_activities']}
                        </div>
                    ) : null}
                    {data?.data?.project_settings?.mobilization_activities ? (
                        <div
                            onClick={() => {
                                refMobilizationActivities.current.scrollIntoView();
                            }}
                            className={`project__sidebar-item ${
                                offsetTop >=
                                refMobilizationActivities?.current?.offsetTop - 50 &&
                                offsetTop <= refRiskManagement?.current?.offsetTop - 50
                                    ? "active"
                                    : ""
                            }`}
                            id={"mobilization_activities"}
                        >
                            {translations?.['general_start_up_activities.mobilization_activities.headers.mobilization_activities']}
                        </div>
                    ) : null}
                    {data?.data?.project_settings?.risk_management ? (
                        <div
                            onClick={() => {
                                refRiskManagement.current.scrollIntoView();
                            }}
                            className={`project__sidebar-item ${
                                offsetTop >= refRiskManagement?.current?.offsetTop - 50 &&
                                offsetTop <= refProgramProgress?.current?.offsetTop - 50
                                    ? "active"
                                    : ""
                            }`}
                            id={"risk_management"}
                        >
                            {translations?.['risk_management.risk_management.headers.risk_management']}
                        </div>
                    ) : null}
                    {data?.data?.project_settings?.project_status ? (
                        <div
                            onClick={() => {
                                refProgramProgress.current.scrollIntoView();
                            }}
                            className={`project__sidebar-item ${
                                offsetTop >= refProgramProgress?.current?.offsetTop - 50 &&
                                offsetTop <= refFinancialManagement?.current?.offsetTop - 50
                                    ? "active"
                                    : ""
                            }`}
                            id={"program_progress"}
                        >
                            {translations?.['project_monitoring.project_monitoring.headers.project_monitoring']}
                        </div>
                    ) : null}
                    {data?.data?.project_settings?.payments_overview ? (
                        <div
                            onClick={() => {
                                refFinancialManagement.current.scrollIntoView();
                            }}
                            className={`project__sidebar-item ${
                                offsetTop >= refFinancialManagement?.current?.offsetTop - 50 &&
                                offsetTop <= refDisputes?.current?.offsetTop - 50
                                    ? "active"
                                    : ""
                            }`}
                            id={"financial_management"}
                        >
                            {translations?.['payments.payments.headers.payments']}
                        </div>
                    ) : null}
                    {data?.data?.project_settings?.disputes ? (
                        <div
                            onClick={() => {
                                refDisputes.current.scrollIntoView();
                            }}
                            className={`project__sidebar-item ${
                                offsetTop >= refDisputes?.current?.offsetTop - 50 &&
                                offsetTop <= refGoodsEquipment?.current?.offsetTop - 50
                                    ? "active"
                                    : ""
                            }`}
                            id={"disputes"}
                        >
                            {translations?.['disputes.disputes.headers.disputes']}
                        </div>
                    ) : null}
                    {data?.data?.project_settings?.goods_equipment ? (
                        <div
                            onClick={() => {
                                refGoodsEquipment.current.scrollIntoView();
                            }}
                            className={`project__sidebar-item ${
                                offsetTop >= refGoodsEquipment?.current?.offsetTop - 50 &&
                                offsetTop <= refEngineering?.current?.offsetTop - 50
                                    ? "active"
                                    : ""
                            }`}
                            id={"goods_equipment"}
                        >
                            {translations?.['goods_equipment.goods_equipment.headers.goods_equipment']}
                        </div>
                    ) : null}
                    {data?.data?.project_settings?.engineering ? (
                        <div
                            onClick={() => {
                                refEngineering.current.scrollIntoView();
                            }}
                            className={`project__sidebar-item ${
                                offsetTop >= refEngineering?.current?.offsetTop - 50 &&
                                offsetTop <= refEnvironment?.current?.offsetTop - 50
                                    ? "active"
                                    : ""
                            }`}
                            id={"goods_equipment"}
                        >
                            {translations?.['engineering_design_documents.engineering_design_documents.headers.engineering_design_documents']}
                        </div>
                    ) : null}
                    {data?.data?.project_settings?.social_safeguard ||
                    data?.data?.project_settings?.gender_tracking ||
                    data?.data?.project_settings?.social_safeguard ||
                    data?.data?.project_settings?.ssemp_activity ||
                    data?.data?.project_settings?.health_and_safety_monitoring ||
                    data?.data?.project_settings?.health_and_safety_statistic ||
                    data?.data?.project_settings?.complaints ||
                    data?.data?.project_settings?.security_monitoring ? (
                        <div
                            onClick={() => {
                                refEnvironment.current.scrollIntoView();
                            }}
                            className={`project__sidebar-item ${
                                offsetTop >= refEnvironment?.current?.offsetTop - 50 &&
                                offsetTop <= refSitePhotographs?.current?.offsetTop - 50
                                    ? "active"
                                    : ""
                            }`}
                            id={"goods_equipment"}
                        >
                            {translations?.['environment_social_health_safety_and_security_obligations.environment_social_health_safety_and_security_obligations.headers.environment_social_health_safety_and_security_obligations']}
                        </div>
                    ) : null}

                    {data?.data?.project_settings?.site_photographs ? (
                        <div
                            onClick={() => {
                                refSitePhotographs.current.scrollIntoView();
                            }}
                            className={`project__sidebar-item ${
                                offsetTop >= refSitePhotographs?.current?.offsetTop - 50 &&
                                offsetTop <= refCompletion?.current?.offsetTop - 50
                                    ? "active"
                                    : ""
                            }`}
                            id={"site_photographs"}
                        >
                            {translations?.['site_photographs.site_photographs.headers.site_photographs']}
                        </div>
                    ) : null}
                    {data?.data?.project_settings?.contract_closure ? (
                        <div
                            onClick={() => {
                                refCompletion.current.scrollIntoView();
                            }}
                            className={`project__sidebar-item ${
                                offsetTop >= refCompletion?.current?.offsetTop - 50 &&
                                offsetTop <= refCommunications?.current?.offsetTop - 50
                                    ? "active"
                                    : ""
                            }`}
                            id={"contract_details"}
                        >
                            {translations?.['completion_of_work_and_contract_closure_activities_table.completion_of_work_and_contract_closure_activities_table.headers.completion_of_work_and_contract_closure_activities_table']}
                        </div>
                    ) : null}
                    {data?.data?.project_settings?.contact_details ? (
                        <div
                            onClick={() => {
                                refCommunications.current.scrollIntoView();
                            }}
                            className={`project__sidebar-item ${
                                offsetTop >= refCommunications?.current?.offsetTop - 50 &&
                                offsetTop <= refDocuments?.current?.offsetTop - 50
                                    ? "active"
                                    : ""
                            }`}
                            id={"communications_reporting"}
                        >
                            {translations?.['communications_and_reporting.communications_and_reporting.headers.communications_and_reporting']}
                        </div>
                    ) : null}
                    <div
                        onClick={() => {
                            refDocuments.current.scrollIntoView();
                        }}
                        className={`project__sidebar-item ${
                            offsetTop >= refDocuments?.current?.offsetTop - 50
                                ? "active"
                                : ""
                        }`}
                        id={"documents"}
                    >
                        {translations?.['documents.documents.headers.documents']}
                    </div>
                </div>
                <div className="project__content">
                    <div className="project__header">
                        <div className="project__nav">
                            <Link to="/project-list">{translations?.['main.main.titles.projects']}</Link> <span>/</span>{" "}
                            {data?.data?.contract_title}
                        </div>
                        <div className="project__header-info">
                            <div className="project__last-update">
                                {translations?.['main.main.titles.last_update']+": "}
                                {data?.data?.last_updated_format}
                            </div>
                        </div>
                    </div>
                    <div className="project__data">
                        <div className="project__left">
                            {previewStatus && (
                                <div className="custom-gallery">
                                    <div className="custom-gallery__content">
                                        <div
                                            className="custom-gallery__closeBtn"
                                            onClick={() => setPreviewStatus(false)}
                                        >
                                            <img
                                                className="custom-gallery__close"
                                                src="/assets/icons/close.svg"
                                                alt="close"
                                            />
                                        </div>
                                        <img
                                            className="custom-gallery__img"
                                            src={currentImg?.image}
                                            alt="photo"
                                        />
                                    </div>
                                    <div
                                        className="custom-gallery__bg"
                                        onClick={() => {
                                            setPreviewStatus(false);
                                        }}
                                    ></div>
                                </div>
                            )}
                            <div className="project__image">
                                {data?.data?.preview_image ? (
                                    <>
                                        <div
                                            style={{ display: imageLoader ? "flex" : "none" }}
                                            className="project__wrapper-loader"
                                        >
                                            <div className="project__loader"></div>
                                        </div>
                                        <img
                                            style={{
                                                display: imageLoader ? "none" : "block",
                                                position: "relative",
                                            }}
                                            className="project__image-main"
                                            src={data?.data?.preview_image}
                                            alt=""
                                            onLoad={hideLoader}
                                            onClick={() => {
                                                setPreviewStatus(true);
                                                setCurrentImg({ image: data?.data?.preview_image });
                                            }}
                                        />
                                        {user?.permissions?.edit_project && (
                                            <>
                                                <label className="project__wrapper-menu project-photo">
                                                    <input
                                                        id="image_2"
                                                        type="file"
                                                        accept="image/png, image/jpeg"
                                                        onChange={(e) => uploadImage(e, "1")}
                                                    />
                                                    <img
                                                        src="/assets/icons/upload.svg"
                                                        alt="upload"
                                                        onClick={() => {
                                                            setCurrentImg({
                                                                image: data?.data?.preview_image,
                                                            });
                                                        }}
                                                    />
                                                </label>
                                                <img
                                                    onClick={() => {
                                                        deleteImg();
                                                        setDeleteModal((old) => ({ ...old, show: true }));
                                                    }}
                                                    className="project__gallary-deleteSvg main-delete"
                                                    src="/assets/icons/delete.svg"
                                                    alt=""
                                                />
                                            </>
                                        )}
                                        {showDropDown && (
                                            <div
                                                onMouseLeave={() => setShowDropDown(false)}
                                                className="project__wrapper-imageDropDown"
                                            >
                                                <div className="project__wrapper-dropDownBtn">
                                                    <input
                                                        type="file"
                                                        accept="image/png, image/jpeg"
                                                        onChange={(e) => uploadImage(e, "1")}
                                                    />
                                                    Replace photo
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <div className="project__image-upload">
                                        <img src="/assets/icons/gallary-icon.svg" alt="" />
                                        <p>
                                            {translations?.['main.main.titles.drop_your_image_here_or_select']} <br />
                                            <span>{translations?.['main.main.actions.click_to_browse']}</span>
                                        </p>
                                        <input
                                            type="file"
                                            accept="image/png, image/jpeg"
                                            onChange={(e) => uploadImage(e, "1")}
                                        />
                                    </div>
                                )}
                            </div>
                            {modal && (
                                <div className="logout__modal">
                                    <div className="logout__modal-content">
                                        <div className="logout__modal-header">
                                            <h5>Confirm your action</h5>
                                            <div
                                                onClick={() => {
                                                    setModal(false);
                                                }}
                                                className="logout__modal-close"
                                            >
                                                <img src="/assets/icons/close-mark.svg" alt="close" />
                                            </div>
                                        </div>
                                        <div className="logout__modal-text">
                                            You are going to remove the image, are you sure?
                                        </div>
                                        <div className="logout__modal-btns">
                                            <div
                                                onClick={() => {
                                                    setModal(false);
                                                }}
                                                className="logout__modal-cancel"
                                            >
                                                Cancel
                                            </div>
                                            <div
                                                onClick={() => removeImage()}
                                                className="logout__modal-sure"
                                            >
                                                Remove
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        onClick={() => {
                                            setModal(false);
                                        }}
                                        className="logout__modal-bg"
                                    ></div>
                                </div>
                            )}
                        </div>
                        <div className="project__right">
                            <div className="project__title">
                                <h1 className="project__subtitle">
                                    {data?.data?.contract_title}
                                </h1>
                                <div className="data__number">
                                    {translations?.['main.main.titles.contract_number']}
                                    <span>
                    {data?.data?.contract_number ?? ""}</span>
                                </div>
                            </div>
                            <div className="project__location">
                <span>
                  <img src="/assets/icons/location.svg" alt="location" />
                </span>
                                <span>{data?.data?.contract_location}</span>
                            </div>

                            <div className="project__summary">
                                <label htmlFor="">{translations?.['main.main.titles.project_summary']}:</label>
                                <p>{data?.data?.contract_project_summary}</p>
                            </div>
                            <div className="project__report-btns">
                                <a
                                    onClick={() => {
                                        navigate(`/online-view/${data?.data?.latest_report?.id}`, {
                                            state: {
                                                id: data?.data?.latest_report?.id,
                                                prevLocation: location?.pathname,
                                                date: data?.data?.latest_report?.date,
                                            },
                                        });
                                    }}
                                    className="project__report-preview"
                                >
                  <span>
                    <img src="/assets/icons/eye.svg" alt="preview report" />
                  </span>
                                    <span>{translations?.['main.main.actions.view_report']}</span>
                                </a>
                                <a onClick={previewReport} className="project__report-preview">
                  <span>
                    <img
                        src="/assets/icons/downloadreport.svg"
                        alt="preview report"
                    />
                  </span>
                                    <span>{translations?.['main.main.actions.download_report']}</span>
                                </a>
                                {user?.permissions?.submit_report && (
                                    <div
                                        onClick={() => setReportModal(true)}
                                        className="project__report-submit"
                                    >
                    <span>
                      <img
                          src="/assets/icons/submit-report.svg"
                          alt="submit report"
                      />
                    </span>
                                        <span>{translations?.['main.main.actions.submit_report']}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                        {user?.permissions?.edit_project && (
                            <>
                                <div className="project__dropdown">
                                    <Link
                                        tag="div"
                                        to={`/edit-project/${data?.data?.id}`}
                                        onMouseLeave={() => setEditDropdown(false)}
                                        className="project__report-submit edit"
                                    >
                  <span>
                    <img src="/assets/icons/pencil.svg" alt="" />
                  </span>
                                        <span>{translations?.['main.main.actions.edit_project_details']}</span>
                                    </Link>
                                    {user?.permissions?.inactive_project ?
                                        <div className="a-checkbox" style={{marginTop: 10}}>
                                            <input type="checkbox" key={data?.data} id="inactive-id" defaultChecked={data?.data?.inactive == 1 ? true : false} onChange={(e) => {
                                                dispatch(setLoader(true))
                                                updateProject({body: {inactive: e.target.checked ? 1 : 0}, id: data?.data?.id}).unwrap().then(res => {
                                                    dispatch(setLoader(false))
                                                }).catch(err => {
                                                    dispatch(setLoader(false))
                                                })
                                            }}  />
                                            <label htmlFor="inactive-id">{translations?.['main.main.actions.inactive']}</label>
                                        </div>
                                        : null}
                                </div>

                            </>
                        )}
                    </div>
                    {data?.data?.project_settings?.contract_key_data
                        ? <ContractKeyData currentRef={refContractDetails} contractDetails={data?.data?.contract_details} translations={translations}/>
                        : null}

                    <ApprovedContractTable
                        glossaryData={glossaryData}
                        data={data?.data?.approved_contract_changes}
                        id={params?.project ?? localStorage.getItem("itemId")}
                        refChangesTable={refChangesTable}
                        projectData={data}
                        translations={translations}
                    />
                    <div
                        className={`project__cover ${!reportCover ? "" : "close"}`}
                        id="cover"
                    >
                        <div className="d-f">
                            <h3
                                className="data__title reportCover"
                                ref={refCover}
                                onClick={() => setReportCover(!reportCover)}
                            >
                                {translations?.['report_cover.report_cover.headers.report_cover']}
                            </h3>
                            <span
                                className="project__block-more"
                                onClick={() => setInfoModal(!infoModal)}
                            >
                <span className="img">
                  <img src="/assets/icons/info.svg" alt="info" />
                </span>
                <span className="text">
                  {translations?.['report_cover.report_cover.titles.more_info']}
                </span>
              </span>
                        </div>
                        <div className="project__top">
                            <div className="project__leftInfo">
                                <div className="project__rightInfo">
                                    <div className="project__gallary">
                                        {!imageOne?.image ? (
                                            <div className="project__upload">
                                                {user?.permissions?.edit_project && (
                                                    <>
                                                        <img src="/assets/icons/upload.svg" alt="upload" />
                                                        <input
                                                            id="image_1"
                                                            type="file"
                                                            accept="image/png, image/jpeg"
                                                            onChange={(e) =>
                                                                uploadImage(e, "2", imageOne?.type)
                                                            }
                                                        />
                                                    </>
                                                )}
                                            </div>
                                        ) : (
                                            <div className="project__gallary-image">
                                                <img
                                                    onClick={() => {
                                                        setPreviewStatus(true);
                                                        setCurrentImg(imageOne);
                                                    }}
                                                    src={imageOne?.image}
                                                    alt=""
                                                />
                                                {user?.permissions?.edit_project && (
                                                    <>
                                                        <label
                                                            htmlFor="image_1"
                                                            className="project__gallary-delete"
                                                        >
                                                            <input
                                                                id="image_1"
                                                                type="file"
                                                                accept="image/png, image/jpeg"
                                                                onChange={(e) =>
                                                                    uploadImage(e, "2", imageOne?.type)
                                                                }
                                                            />
                                                            <img
                                                                src="/assets/icons/upload.svg"
                                                                alt="upload"
                                                                onClick={() => {
                                                                    setCurrentImg(imageOne);
                                                                }}
                                                            />
                                                        </label>
                                                        <img
                                                            onClick={() => {
                                                                setCurrentId(imageOne?.id);
                                                                setDeleteModal((old) => ({
                                                                    ...old,
                                                                    show: true,
                                                                }));
                                                                deleteReportCoverPhoto({
                                                                    id:
                                                                        params?.project ??
                                                                        localStorage.getItem("itemId"),
                                                                    type: "image_1",
                                                                });
                                                            }}
                                                            className="project__gallary-deleteSvg"
                                                            src="/assets/icons/delete.svg"
                                                            alt=""
                                                        />
                                                    </>
                                                )}
                                            </div>
                                        )}
                                        {!imageTwo?.image ? (
                                            <div className="project__upload">
                                                {user?.permissions?.edit_project && (
                                                    <>
                                                        <img src="/assets/icons/upload.svg" alt="upload" />
                                                        <input
                                                            type="file"
                                                            accept="image/png, image/jpeg"
                                                            onChange={(e) =>
                                                                uploadImage(e, "2", imageTwo?.type)
                                                            }
                                                        />
                                                    </>
                                                )}
                                            </div>
                                        ) : (
                                            <div className="project__gallary-image">
                                                <img
                                                    onClick={() => {
                                                        setPreviewStatus(true);
                                                        setCurrentImg(imageTwo);
                                                    }}
                                                    src={imageTwo?.image}
                                                    alt=""
                                                />
                                                {user?.permissions?.edit_project && (
                                                    <>
                                                        <label className="project__gallary-delete">
                                                            <input
                                                                id="image_2"
                                                                type="file"
                                                                accept="image/png, image/jpeg"
                                                                onChange={(e) =>
                                                                    uploadImage(e, "2", imageTwo?.type)
                                                                }
                                                            />
                                                            <img
                                                                src="/assets/icons/upload.svg"
                                                                alt="upload"
                                                                onClick={() => {
                                                                    setCurrentImg(imageTwo);
                                                                }}
                                                            />
                                                        </label>
                                                        <img
                                                            onClick={() => {
                                                                setCurrentId(imageTwo?.id);
                                                                setDeleteModal((old) => ({
                                                                    ...old,
                                                                    show: true,
                                                                }));
                                                                deleteReportCoverPhoto({
                                                                    id:
                                                                        params?.project ??
                                                                        localStorage.getItem("itemId"),
                                                                    type: "image_2",
                                                                });
                                                            }}
                                                            className="project__gallary-deleteSvg"
                                                            src="/assets/icons/delete.svg"
                                                            alt=""
                                                        />
                                                    </>
                                                )}
                                            </div>
                                        )}
                                        {!imageThree?.image ? (
                                            <div className="project__upload">
                                                {user?.permissions?.edit_project && (
                                                    <>
                                                        <img src="/assets/icons/upload.svg" alt="upload" />
                                                        <input
                                                            type="file"
                                                            accept="image/png, image/jpeg"
                                                            onChange={(e) =>
                                                                uploadImage(e, "2", imageThree?.type)
                                                            }
                                                        />
                                                    </>
                                                )}
                                            </div>
                                        ) : (
                                            <div className="project__gallary-image">
                                                <img
                                                    onClick={() => {
                                                        setPreviewStatus(true);
                                                        setCurrentImg(imageThree);
                                                    }}
                                                    src={imageThree?.image}
                                                    alt=""
                                                />
                                                {user?.permissions?.edit_project && (
                                                    <>
                                                        <label className="project__gallary-delete">
                                                            <input
                                                                id="image_3"
                                                                type="file"
                                                                accept="image/png, image/jpeg"
                                                                onChange={(e) =>
                                                                    uploadImage(e, "2", imageThree?.type)
                                                                }
                                                            />
                                                            <img
                                                                src="/assets/icons/upload.svg"
                                                                alt="upload"
                                                                onClick={() => {
                                                                    setCurrentImg(imageThree);
                                                                }}
                                                            />
                                                        </label>
                                                        <img
                                                            onClick={() => {
                                                                setCurrentId(imageThree?.id);
                                                                setDeleteModal((old) => ({
                                                                    ...old,
                                                                    show: true,
                                                                }));
                                                                deleteReportCoverPhoto({
                                                                    id:
                                                                        params?.project ??
                                                                        localStorage.getItem("itemId"),
                                                                    type: "image_3",
                                                                });
                                                            }}
                                                            className="project__gallary-deleteSvg"
                                                            src="/assets/icons/delete.svg"
                                                            alt=""
                                                        />
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="project__current-date">
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <img
                                            style={{ marginRight: "6px" }}
                                            src="/assets/icons/calendar.svg"
                                            alt=""
                                        />
                                        {translations?.['report_cover.report_cover.titles.report_month']}
                                    </div>
                                    <span style={{ color: "#2A3650", fontWeight: 500 }}>
                    {moment(data?.data?.latest_report?.date).format(
                        "MMMM YYYY"
                    )}
                  </span>
                                </div>
                            </div>
                        </div>
                        <div className="project__description">
                            <div
                                className={`project__description-box ${
                                    descrOne?.edit ? "edit" : ""
                                }`}
                            >
                                <h4 className="project__description-title">{translations?.['report_cover.report_cover.titles.description_1']}</h4>
                                {descrOne?.edit ? (
                                    <textarea
                                        style={{ minHeight: 200 }}
                                        maxLength={2000}
                                        defaultValue={descrOne.title}
                                        onChange={(e) => {
                                            setDescrOne((old) => ({ ...old, title: e.target.value }));
                                        }}
                                    />
                                ) : (
                                    <p className="project__description-subtitle">
                                        {descrOne?.title}
                                    </p>
                                )}
                                {user?.permissions?.edit_project && (
                                    <div className="project__block-btns">
                                        {descrOne?.edit && (
                                            <div
                                                className="project__block-cancel"
                                                onClick={() => {
                                                    setDescrOne((old) => ({
                                                        ...old,
                                                        edit: false,
                                                        title: old?.prevTitle,
                                                    }));
                                                }}
                                            >
                                                <img src="/assets/icons/close-mark.svg" alt="close" />
                                                {translations?.['report_cover.report_cover.actions.cancel']}
                                            </div>
                                        )}
                                        {descrOne?.edit ? (
                                            <button
                                                onClick={() => {
                                                    setDescrOne((old) => ({
                                                        ...old,
                                                        edit: false,
                                                        prevTitle: old?.title,
                                                    }));
                                                    updateReportCover({
                                                        id: data?.data?.id,
                                                        body: { description_1: descrOne?.title },
                                                    });
                                                }}
                                                className="project__block-save"
                                            >
                                                <img src="/assets/icons/save-arrow.svg" alt="arrow" />
                                                {translations?.['report_cover.report_cover.actions.save']}
                                            </button>
                                        ) : (
                                            <div
                                                onClick={() => {
                                                    setDescrOne((old) => ({
                                                        ...old,
                                                        edit: true,
                                                    }));
                                                }}
                                                className="project__block-update"
                                            >
                                                {translations?.['report_cover.report_cover.actions.update']}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div
                                className={`project__description-box ${
                                    descrTwo?.edit ? "edit" : ""
                                }`}
                            >
                                <h4 className="project__description-title">{translations?.['report_cover.report_cover.titles.description_2']}</h4>
                                {descrTwo?.edit ? (
                                    <textarea
                                        style={{ minHeight: 200 }}
                                        maxLength={2000}
                                        defaultValue={descrTwo.title}
                                        onChange={(e) => {
                                            setDescrTwo((old) => ({ ...old, title: e.target.value }));
                                        }}
                                    />
                                ) : (
                                    <p className="project__description-subtitle">
                                        {descrTwo?.title}
                                    </p>
                                )}
                                {user?.permissions?.edit_project && (
                                    <div className="project__block-btns">
                                        {descrTwo?.edit && (
                                            <div
                                                className="project__block-cancel"
                                                onClick={() => {
                                                    setDescrTwo((old) => ({
                                                        ...old,
                                                        edit: false,
                                                        title: old?.prevTitle,
                                                    }));
                                                }}
                                            >
                                                <img src="/assets/icons/close-mark.svg" alt="close" />
                                                {translations?.['report_cover.report_cover.actions.cancel']}
                                            </div>
                                        )}
                                        {descrTwo?.edit ? (
                                            <button
                                                onClick={() => {
                                                    setDescrTwo((old) => ({
                                                        ...old,
                                                        edit: false,
                                                        prevTitle: old?.title,
                                                    }));
                                                    updateReportCover({
                                                        id: data?.data?.id,
                                                        body: { description_2: descrTwo?.title },
                                                    });
                                                }}
                                                className="project__block-save"
                                            >
                                                <img src="/assets/icons/save-arrow.svg" alt="arrow" />
                                                {translations?.['report_cover.report_cover.actions.save']}
                                            </button>
                                        ) : (
                                            <div
                                                onClick={() => {
                                                    setDescrTwo((old) => ({
                                                        ...old,
                                                        edit: true,
                                                    }));
                                                }}
                                                className="project__block-update"
                                            >
                                                {translations?.['report_cover.report_cover.actions.update']}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div
                                className={`project__description-box ${
                                    descrThree?.edit ? "edit" : ""
                                }`}
                            >
                                <h4 className="project__description-title">{translations?.['report_cover.report_cover.titles.description_3']}</h4>
                                {descrThree?.edit ? (
                                    <textarea
                                        style={{ minHeight: 200 }}
                                        maxLength={2000}
                                        defaultValue={descrThree.title}
                                        onChange={(e) => {
                                            setDescrThree((old) => ({
                                                ...old,
                                                title: e.target.value,
                                            }));
                                        }}
                                    />
                                ) : (
                                    <p className="project__description-subtitle">
                                        {descrThree?.title}
                                    </p>
                                )}
                                {user?.permissions?.edit_project && (
                                    <div className="project__block-btns">
                                        {descrThree?.edit && (
                                            <div
                                                className="project__block-cancel"
                                                onClick={() => {
                                                    setDescrThree((old) => ({
                                                        ...old,
                                                        edit: false,
                                                        title: old?.prevTitle,
                                                    }));
                                                }}
                                            >
                                                <img src="/assets/icons/close-mark.svg" alt="close" />
                                                {translations?.['report_cover.report_cover.actions.cancel']}
                                            </div>
                                        )}
                                        {descrThree?.edit ? (
                                            <button
                                                onClick={() => {
                                                    setDescrThree((old) => ({
                                                        ...old,
                                                        edit: false,
                                                        prevTitle: old?.title,
                                                    }));
                                                    updateReportCover({
                                                        id: data?.data?.id,
                                                        body: { description_3: descrThree?.title },
                                                    });
                                                }}
                                                className="project__block-save"
                                            >
                                                <img src="/assets/icons/save-arrow.svg" alt="arrow" />
                                                {translations?.['report_cover.report_cover.actions.save']}
                                            </button>
                                        ) : (
                                            <div
                                                onClick={() => {
                                                    setDescrThree((old) => ({
                                                        ...old,
                                                        edit: true,
                                                    }));
                                                }}
                                                className="project__block-update"
                                            >
                                                {translations?.['report_cover.report_cover.actions.update']}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {data?.data?.project_settings?.executive_summary ? (
                        <ExecutiveSummaryProject
                            translations={translations}
                            glossaryData={glossaryData}
                            data={data?.data?.latest_report?.executive_summary}
                            id={params?.project ?? localStorage.getItem("itemId")}
                            refExecutiveSummary={refExecutiveSummary}
                        />
                    ) : null}
                    {data?.data?.project_settings?.permits_licenses_approvals ? (
                        <PermitsTable
                            translations={translations}
                            glossaryData={glossaryData}
                            id={params?.project ?? localStorage.getItem("itemId")}
                            refPermits={refPermits}
                            permitsData={data?.data}
                        />
                    ) : null}

                    <LarpStart
                        translations={translations}
                        mainData={data?.data}
                        larpData={data?.data?.applicable_settings}
                        glossaryData={glossaryData}
                        id={params?.project ?? localStorage.getItem("itemId")}
                        refActivities={refActivities}
                        show={show}
                        setShow={setShow}
                    />


                    {(data?.data?.project_settings?.residual_tender_stage_actions || data?.data?.project_settings?.contract_requirements || data?.data?.project_settings?.contract_activities) ? (
                        <GeneralStart
                            translations={translations}
                            glossaryData={glossaryData}
                            id={params?.project ?? localStorage.getItem("itemId")}
                            refGeneralStartUp={refGeneralStartUp}
                            refMobilizationActivities={refMobilizationActivities}
                            refSitePhotographs={refSitePhotographs}
                            refDisputes={refDisputes}
                            refFinancialManagement={refFinancialManagement}
                            show={show}
                            projectData={data?.data}
                        />
                    ) : null}

                    {data?.data?.project_settings?.mobilization_activities ? (
                        <MobilizationActivities
                            translations={translations}
                            glossaryData={glossaryData}
                            refMobilizationActivities={refMobilizationActivities}
                            id={params?.project ?? localStorage.getItem("itemId")}
                        />
                    ) : null}
                    {data?.data?.project_settings?.risk_management ? (
                        <RiskManagement
                            translations={translations}
                            refRiskManagement={refRiskManagement}
                            glossaryData={glossaryData}
                            data={data?.data}
                            id={params?.project ?? localStorage.getItem("itemId")}
                        />
                    ) : null}
                    <ProjectMonitoring
                        refProgramProgress={refProgramProgress}
                        glossaryData={glossaryData}
                        data={data?.data}
                        id={params?.project ?? localStorage.getItem("itemId")}
                        translations={translations}
                    />
                    <FinancialManagement
                        refFinancialManagement={refFinancialManagement}
                        glossaryData={glossaryData}
                        data={data?.data}
                        translations={translations}
                    />
                    {data?.data?.project_settings?.disputes ? (
                        <Disputes
                            refDisputes={refDisputes}
                            glossaryData={glossaryData}
                            data={data?.data}
                            translations={translations}
                        />
                    ) : null}
                    {data?.data?.project_settings?.goods_equipment ? (
                        <GoodsEquipment
                            refGoodsEquipment={refGoodsEquipment}
                            glossaryData={glossaryData}
                            goodsData={data?.data}
                            translations={translations}
                        />
                    ) : null}
                    {data?.data?.project_settings?.engineering ? (
                        <EngineeringDesignDocuments
                            refEngineering={refEngineering}
                            glossaryData={glossaryData}
                            documentsData={data?.data}
                            translations={translations}
                        />
                    ) : null}
                    <Environment
                        refEnvironment={refEnvironment}
                        glossaryData={glossaryData}
                        data={data?.data}
                        id={params?.project ?? localStorage.getItem("itemId")}
                        mainData={data?.data}
                        larpData={data?.data?.applicable_settings}
                        idLarp={params?.project ?? localStorage.getItem("itemId")}
                        showLarp={show}
                        setShowLarp={setShow}
                        translations={translations}
                    />
                    {data?.data?.project_settings?.site_photographs ? (
                        <SitePhotographs
                            glossaryData={glossaryData}
                            id={params?.project ?? localStorage.getItem("itemId")}
                            data={data?.data?.site_photographs}
                            refSitePhotographs={refSitePhotographs}
                            translations={translations}
                            deleteUrl={'quarterly-report/site-photographs/delete'}
                            updateUrl={'site-photo'}
                            createUrl={'quarterly-report/site-photographs/create'}
                        />
                    ) : null}
                    {data?.data?.project_settings?.contract_closure ? (
                        <CompletionOfWork
                            glossaryData={glossaryData}
                            id={params?.project ?? localStorage.getItem("itemId")}
                            refCompletion={refCompletion}
                            show={show}
                            setShow={setShow}
                            data={data?.data}
                            translations={translations}
                        />
                    ) : null}
                    {data?.data?.project_settings?.contact_details ? (
                        <CommunicationsAndReporting
                            refCommunications={refCommunications}
                            glossaryData={glossaryData}
                            data={data?.data}
                            id={params?.project ?? localStorage.getItem("itemId")}
                            translations={translations}
                        />
                    ) : null}
                    <Documents
                        refDocuments={refDocuments}
                        data={data?.data}
                        glossaryData={glossaryData}
                        translations={translations}
                        deleteUrl={'project-documents'}
                        updateUrl={'project-documents'}
                        createUrl={'project-documents'}
                    />
                </div>
            </section>
        </Wrapper>
    );
}
