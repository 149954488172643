import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { ContractTypeModal } from "../../components/ContractTypeSecondaryModal";
import {
    useCreateContractTypesMutation,
    useDeleteContractTypesMutation,
    useUpdateContractTypesMutation,
    useLazyGetContractTypeQuery
} from "../../store/service/adbService";
import { setLoader } from "../../store/slice/dashboard";
import { DeleteModal } from "../../components/Project/DeleteModal";
import {Paginate} from "../../components/Paginate";

export const ContractType = ({}) => {
    const dispatch = useDispatch();
    const [modal, setModal] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [deleteModal, setDeleteModal] = useState(null);
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPage, setTotalPage] = useState(0)

    const [getContractTypes, {
        data: contractTypes,
        isLoading: contractTypesIsLoading,
    }] = useLazyGetContractTypeQuery();

    const [
        createContractTypes,
        {
            isSuccess: createContractTypesIsSuccess,
            isLoading: createContractTypesIsLoading,
        },
    ] = useCreateContractTypesMutation();

    const [
        deleteContractTypes,
        {
            isSuccess: deleteContractTypesIsSuccess,
            isLoading: deleteContractTypesIsLoading,
        },
    ] = useDeleteContractTypesMutation();

    const [
        updateContractTypes,
        {
            isSuccess: updateContractTypesIsSuccess,
            isLoading: updateContractTypesIsLoading,
        },
    ] = useUpdateContractTypesMutation();

    useEffect(() => {
        getContractTypes({
            per_page: 10,
            page: currentPage,
        })
    }, [currentPage])

    useEffect(() => {
        if (contractTypes) {
            setTotalPage(contractTypes?.data?.last_page)
        }
    }, [contractTypes])

    useEffect(() => {
        setModal(false);
        setCurrentItem(null);
    }, [createContractTypesIsSuccess, updateContractTypesIsSuccess, deleteContractTypesIsSuccess]);

    useEffect(() => {
        !modal && setCurrentItem(null);
    }, [modal]);

    useEffect(() => {
        contractTypesIsLoading ||
        createContractTypesIsLoading ||
        updateContractTypesIsLoading ||
        deleteContractTypesIsLoading
            ? dispatch(setLoader(true))
            : dispatch(setLoader(false));
    }, [
        contractTypesIsLoading,
        createContractTypesIsLoading,
        updateContractTypesIsLoading,
        deleteContractTypesIsLoading,
    ]);

    return (
        <div className="s-pages">
            {deleteModal?.show && (
                <DeleteModal
                    id={deleteModal?.id}
                    action={deleteContractTypes}
                    setDeleteModal={setDeleteModal}
                />
            )}
            {modal && (
                <ContractTypeModal
                    title="Contract Type"
                    setModal={setModal}
                    type="Contract Type"
                    createHook={createContractTypes}
                    updateHook={updateContractTypes}
                    currentItem={currentItem}
                />
            )}
            <div className="s-page__header">
                <h1>Contract Types</h1>
                <button onClick={() => setModal(true)}>
                    <img src="/assets/icons/plus.svg" alt="plus" />
                    <span>Create Contract Type</span>
                </button>
            </div>
            <div className="table">
                <div className="table-header">
                    <div className="table-th">Contract Type Name</div>
                </div>
                {contractTypes?.data?.data?.length > 0 ?
                    <div className="table-body">
                        {contractTypes?.data?.data?.map((item) => (
                            <div className="table-row" key={item?.id} id={item?.id}>
                                <div className="table-td">{item?.name}</div>
                                <div className="table-actions">
                                    <div
                                        onClick={() => {
                                            setDeleteModal({ id: item?.id, show: true });
                                            // deleteContractType(item?.id)
                                        }}
                                        className="table-delete"
                                    >
                                        <img src="/assets/icons/trash-icon.svg" alt="trash" />
                                        <span>Delete</span>
                                    </div>
                                    <div
                                        className="table-edit"
                                        onClick={() => {
                                            setCurrentItem(item);
                                            setModal(true);
                                        }}
                                    >
                                        <img src="/assets/icons/edit-icon-2.svg" alt="edit" />
                                        <span>Edit</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div> :
                    <div style={{height: '70vh', width: '100%', display: "flex", justifyContent:"center", alignItems: 'center', flexDirection: "column"}}>
                        <img src="/assets/images/projects.png" alt=""/>
                        <span style={{color: "#767676", fontSize: 16, fontWeight: 500}}>No Contract Types</span>
                    </div>
                }
            </div>
            {(contractTypes?.data?.data?.length > 0 && totalPage > 0) && <Paginate currentPage={currentPage} setCurrentPage={setCurrentPage} pageCount={totalPage}/>}
        </div>
    );
};
