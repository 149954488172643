import React, {useEffect, useState} from "react";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";
import moment from "moment";
import {
    useGetDashboardQuery,
    useGetPlannedPaymentsQuery, useGetProgramProgressChartQuery,
    useLazyGetProjectQuery, useLazyGetDashboardIndexTranslationsQuery,
    useLazyGetProjectDashboardTranslationsQuery
} from "../../store/service/adbService";
import {setLoader} from "../../store/slice/dashboard";
import {useDispatch} from "react-redux";
import {
    Bar,
    CartesianGrid, Cell,
    ComposedChart,
    Line, Pie, PieChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import {useErrorHandler} from "../../global/ErrorsHandler";
import {VersusChart} from "../../components/VersusChart";
import MoreInfoModal from "../../components/MoreInfoModal";

export const Dashboard = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [paymentsData, setPaymentsData] = useState([]);
    const [progressData, setProgressData] = useState([]);
    const [pieData, setPieData] = useState([]);
    const [riskData, setRiskData] = useState([]);
    const [indexTranslations, setIndexTranslations] = useState(null);
    const [translations, setTranslations] = useState(null);
    const [isOpenMoreInfo, setOpenMoreInfo] = useState(false);
    const { id } = useParams();
    const localeLan = localStorage.getItem("local_lan" );
    moment.locale(localeLan);

    const [
        trigger,
        { data, error: projectError },
    ] = useLazyGetProjectQuery();

    const [
        getDashboardIndexTranslations, {
            data: dashboardIndexTranslations,
            error: dashboardIndexTranslationsIsError,
        }
    ] = useLazyGetDashboardIndexTranslationsQuery()

    const [getProjectDashboardTranslations, {
        data: projectDashboardTranslations,
    }] = useLazyGetProjectDashboardTranslationsQuery()

    const {
        data: plannedPayments,
        isLoading: plannedPaymentsIsLoading,
    } = useGetPlannedPaymentsQuery(id);

    const {
        data: dashboardData,
        error: dashboardError,
    } = useGetDashboardQuery(id);

    const {
        data: progressChart,
        error: progressChartError,
        isLoading: progressChartIsLoading,
        isSuccess:progressChartIsSuccess,
        isError: progressChartIsError,
    } = useGetProgramProgressChartQuery(id);

    useEffect(() => {
        getDashboardIndexTranslations();
        getProjectDashboardTranslations()
    }, [])

    useEffect(() => {
        if (translations) {
        }
    }, [translations]);

    useEffect(() => {
        if (plannedPayments?.length !== 0) {
            setPaymentsData(plannedPayments?.data?.filter(el => el?.actual_amount !== null).map(el =>  ({
                ...el,
                date: el?.date,
                planned_amount: +el?.planned_amount,
                actual_amount: +el?.actual_amount,
                cumulative_actual_amount: el?.cumulative_actual_amount !== null && el?.cumulative_actual_amount !== 0 ? +el?.cumulative_actual_amount : null,
                cumulative_planned_amount: el?.cumulative_planned_amount !== null && el?.cumulative_planned_amount !== 0 ? +el?.cumulative_planned_amount : null ,
            })))
        }
        setProgressData(dashboardData?.data?.program_progress?.data?.map(el => ({
            ...el,
            date: el?.date,
            planned_amount: el?.planned_amount,
            actual_amount: el?.actual_amount,
            cumulative_actual_amount: el?.cumulative_actual_amount,
            cumulative_planned_amount: el?.cumulative_planned_amount,
            edit: false
        })))

        setPieData([
            {name: "Overall progress", value: +dashboardData?.data?.program_pie_chart?.overall_progress},
            {name: "Time elapsed", value: +dashboardData?.data?.program_pie_chart?.time_elapsed},
            {name: "Time remaining", value: +dashboardData?.data?.program_pie_chart?.time_remaining},
        ])

        if (!dashboardData?.data?.risk_management_chart?.low && !dashboardData?.data?.risk_management_chart?.moderate && !dashboardData?.data?.risk_management_chart?.substantial && !dashboardData?.data?.risk_management_chart?.high) {
            setRiskData([
                {name: "Low", value: 1},
                {name: "Moderate", value: 1},
                {name: "Substantial", value: 1},
                {name: "High", value: 1},
            ])
            setRiskColors(["#9a9e9a", "#9a9e9a", "#9a9e9a", "#9a9e9a"])
        } else {
            setRiskData([
                {name: "Low", value: +dashboardData?.data?.risk_management_chart?.low},
                {name: "Moderate", value: +dashboardData?.data?.risk_management_chart?.moderate},
                {name: "Substantial", value: +dashboardData?.data?.risk_management_chart?.substantial},
                {name: "High", value: +dashboardData?.data?.risk_management_chart?.high},
            ]);
            setRiskColors(["#0CAF60", "#ECC949", "#E7984F", "#E68484"]);
        }

        !projectError && trigger(localStorage.getItem("itemId"));

        const d = () => {
            setPaymentsData((old) => {
                let data = old?.map((item) => ({ ...item, edit: false }));
                return data;
            });
            dispatch(setLoader(false));
        };

    }, [
        plannedPaymentsIsLoading,
        progressChartIsLoading,
        data,
        dashboardData
    ]);

    useEffect(() => {
        if(dashboardIndexTranslations){
            setIndexTranslations(dashboardIndexTranslations.data?.translations)
        }
    }, [dashboardIndexTranslations])

    useEffect(() => {
        if(projectDashboardTranslations){
            setTranslations(projectDashboardTranslations.data?.translations)
        }
    }, [projectDashboardTranslations])

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <div style={{display: "flex", flexDirection: "column"}}>
                        {payload.map((pld, idx) => (
                            <div key={idx} style={{ display: "inline-block", padding: 10 }}>
                                <div style={{fontSize: 12 }}>{pld.value}%</div>
                                <div style={{ color: pld.color, fontSize: 12 }}>{pld.name}</div>
                            </div>
                        ))}
                    </div>
                </div>
            );
        }

        return null;
    };

    function formatYAxis(tickItem) {
        if(tickItem > 1000000000){
            return '$' + String((tickItem/1000000000).toFixed(0)).replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1,')+'B'
        }
        if(tickItem > 1000000){
            return '$' + String((tickItem/1000000).toFixed(0)).replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1,')+'M'
        }
        if(tickItem > 1000){
            return '$' + String((tickItem/1000).toFixed(0)).replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1,')+'K'
        }
        return '$' + String(tickItem).replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1,')
    }

    const [errorHandler, setErrorHandler] = useErrorHandler([
        projectError,
        dashboardError,
        dashboardIndexTranslationsIsError
    ]);

    const COLORS = ["#0CAF60", "#ECC949", "#3375D6"];
    const [RISK_COLORS, setRiskColors] = useState(["#0CAF60", "#ECC949", "#E7984F", "#E68484"]);
    return (
        <section className="project__dashboard">
            {isOpenMoreInfo &&
                <MoreInfoModal
                    title={indexTranslations?.['tabs.project_dashboard']}
                    moreInfo={translations?.['more_info.more_info']}
                    close={()=>setOpenMoreInfo(false)}
                />
            }
            <div className="larp-db__header">
                <div className="larp-db__nav">
                    <Link to="/project-list">{indexTranslations?.['titles.projects']}</Link>
                    <p>/ {dashboardData?.data?.contract_title}</p>
                </div>
                <div className="larp-db__update">
                    <p>{indexTranslations?.['titles.last_update']} {dashboardData?.data?.last_updated_format}
                    </p>
                    <div onClick={()=>setOpenMoreInfo(!isOpenMoreInfo)}>
                        <img src="/assets/icons/info.svg" alt="info" />
                        <span>{indexTranslations?.['actions.more_info']}</span>
                    </div>
                </div>
            </div>
            <div className="larp-db__top" style={{marginBottom: 20}}>
                <div className="larp-db__head">
                    <div className="larp-db__title">
                        <h1>{dashboardData?.data?.contract_title}</h1>
                        {/* <p>Submitted</p> */}
                    </div>
                    <div className="larp-db__date">
                        <div className="project__dashboard-date">
                            {dashboardData?.data?.latest_report.date_format}
                            <img src="/assets/icons/calendar.svg" alt="" />
                        </div>
                    </div>
                </div>
                {(location?.state?.project?.has_larp || dashboardData?.data?.project_settings?.larp) ? <div className="larp-db__sidebar">
                    {location?.state?.dash_visible ? <NavLink to={`/dashboard/${id}`} state={{dash_visible: location?.state?.dash_visible, larp_visible: location?.state?.larp_visible}}>{indexTranslations?.['tabs.project_dashboard']}</NavLink> : null}
                    {location?.state?.larp_visible ? <NavLink to={`/larp-dashboard/${id}`} state={{dash_visible: location?.state?.dash_visible, larp_visible: location?.state?.larp_visible}}>{indexTranslations?.['tabs.larp_dashboard']}</NavLink> : null }
                    {/* <NavLink to="/other-dashboard">Other Future Dashboards</NavLink> */}
                </div> : <></>}
            </div>
            <div className="project__dashboard-content">
                <div className="project__dashboard-left">
                    <div className="project__dashboard-progress">
                        <h3 className="project__dashboard-subtitle">
                            {translations?.['headers.project_progress_bar_chart']}
                        </h3>
                        <div className="project__dashboard-statuses">
                            <div className="project__dashboard-status">
                                <div className="project__dashboard-status red"></div>
                                <span>{translations?.['titles.planned']}</span>
                            </div>
                            <div className="project__dashboard-status">
                                <div className="project__dashboard-status lightblue"></div>
                                <span>{translations?.['titles.actual']}</span>
                            </div>
                            <div className="project__dashboard-status">
                                <div className="project__dashboard-status blue"></div>
                                <span>{translations?.['titles.cumulative_planned']}</span>
                            </div>
                            <div className="project__dashboard-status">
                                <div className="project__dashboard-status yellow"></div>
                                <span>{translations?.['titles.cumulative_actual']}</span>
                            </div>
                        </div>
                    </div>
                    {
                        dashboardData?.data?.project_settings?.program_progress ?
                            <div style={{marginBottom: 18}} className="plannedProject__chart">
                                <h2>{translations?.['titles.monthly_progress_percent']}</h2>
                                {
                                    progressData?.length !== 0 ?
                                        <ResponsiveContainer width="100%" aspect={3}>
                                            <ComposedChart
                                                width={500}
                                                height={300}
                                                data={progressData}
                                                margin={{
                                                    top: 5,
                                                    right: 30,
                                                    left: 20,
                                                    bottom: 5,
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="0 0"/>
                                                <XAxis tickFormatter={(tickItem)=>moment(tickItem).format('MMM-YY')} fontSize={12} dataKey="date"/>
                                                <YAxis
                                                    yAxisId="cumulative"
                                                    orientation={"right"}
                                                    domain={[0, 100]}
                                                    type="number"
                                                    tickFormatter={(tick) => {
                                                        return `${Math.round(tick / 10) * 10}%`;
                                                    }}
                                                    fontSize={12}/>
                                                <YAxis
                                                    yAxisId={'amount'}
                                                    domain={[0, dataMax => (dataMax + Math.round(dataMax / 10))]}
                                                    orientation={"left"}
                                                    type="number"
                                                    tickFormatter={(tick) => {
                                                        return `${tick}%`;
                                                    }}
                                                    fontSize={12}
                                                />
                                                <Tooltip content={CustomTooltip}/>
                                                <Bar yAxisId={'amount'} dataKey="planned_amount" name={translations?.['titles.planned']} fill="#E68484"
                                                     radius={[10, 10, 0, 0]}/>
                                                <Bar yAxisId={'amount'} dataKey="actual_amount" name={translations?.['titles.actual']} fill="#99BAEB"
                                                     radius={[10, 10, 0, 0]}/>
                                                <Line yAxisId={'cumulative'} dot={false}
                                                      strokeWidth={4}
                                                      strokeLinecap="round"
                                                      type="monotone"
                                                      dataKey="cumulative_planned_amount"
                                                      stroke="#3B7AD9"
                                                      name={translations?.['titles.cumulative_planned']}
                                                />
                                                <Line yAxisId={'cumulative'} dot={false}
                                                      strokeWidth={4}
                                                      strokeLinecap="round"
                                                      type="monotone"
                                                      dataKey="cumulative_actual_amount"
                                                      stroke="#FFD023"
                                                      name={translations?.['titles.cumulative_actual']}

                                                />
                                            </ComposedChart>
                                        </ResponsiveContainer> :
                                        <div className="plannedProject__placeholder">
                                            <img src="/assets/icons/planned_chart.svg" alt=""/>
                                            There is no Data yet
                                        </div>
                                }
                            </div> : <div style={{background: 'white', padding: '36px 0', marginBottom: 20, border: '1px solid rgba(0,0,0, 0.1)'}}>
                                <div className="plannedProject__placeholder">
                                    <img src="/assets/icons/chart-placeholder.png" alt=""/>
                                    This section isn't included in this project
                                </div>
                            </div>
                    }
                    {
                        <div className='plannedProject__charts-wrapp'>
                            <div className="plannedProject__chart">
                                <div className='plannedProject__charts' >
                                    <p className="plannedProject__chart-title">{translations?.['headers.project_progress_pie_chart']}</p>
                                    <div className="project__dashboard-pieChart" style={{ display: 'flex', alignItems:'center', justifyContent: 'space-around' }}>
                                        <div className="project__dashboard-pieBlock">
                                            <ResponsiveContainer height={152} width={152}>
                                                <PieChart height={152} width={152}>
                                                    <Pie
                                                        data={pieData}
                                                        dataKey="value"
                                                        nameKey="name"
                                                        color="#000000"
                                                        fill="#8884d8"
                                                        cx="50%"
                                                        cy="50%"
                                                        innerRadius={35}
                                                    >
                                                        {
                                                            pieData?.map((el,idx) => (
                                                                <Cell  key={`cell-${idx}`}
                                                                       fill={COLORS[idx % COLORS.length]}
                                                                />
                                                            ))
                                                        }
                                                    </Pie>
                                                </PieChart>
                                            </ResponsiveContainer>
                                        </div>
                                        <div className="project__dashboard-stats">
                                            <div className="project__dashboard-stat">
                                                <div className="project__dashboard-statBox">
                                                    <div className="project__program-color green"></div>
                                                    <p>{translations?.['titles.overall_progress']}</p>
                                                </div>
                                                <span>{dashboardData?.data?.program_pie_chart?.overall_progress ? dashboardData?.data?.program_pie_chart?.overall_progress : '0'}%</span>
                                            </div>
                                            <div className="project__dashboard-stat">
                                                <div className="project__dashboard-statBox">
                                                    <div className="project__program-color yellow"></div>
                                                    <p>{translations?.['titles.time_elapsed']}</p>
                                                </div>
                                                <span>{dashboardData?.data?.program_pie_chart?.time_elapsed ? dashboardData?.data?.program_pie_chart?.time_elapsed : '0'}%</span>
                                            </div>
                                            <div className="project__dashboard-stat">
                                                <div className="project__dashboard-statBox">
                                                    <div className="project__program-color blue"></div>
                                                    <p>{translations?.['titles.time_remaining']}</p>
                                                </div>
                                                <span>{dashboardData?.data?.program_pie_chart?.time_remaining ? dashboardData?.data?.program_pie_chart?.time_remaining : '0'}%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="plannedProject__chart" style={{ marginLeft: '20px'}}>
                                <div className='plannedProject__charts' >
                                    <p className="plannedProject__chart-title">{translations?.['headers.risk_level']}</p>
                                    <div className="project__dashboard-pieChart" style={{ display: 'flex', alignItems:'center', justifyContent: 'space-around' }}>
                                        <div className="project__dashboard-pieBlock">
                                            <ResponsiveContainer height={152} width={152}>
                                                <PieChart height={152} width={152}>
                                                    <Pie
                                                        data={riskData}
                                                        dataKey="value"
                                                        nameKey="name"
                                                        color="#000000"
                                                        fill="#8884d8"
                                                        cx="50%"
                                                        cy="50%"
                                                        innerRadius={35}
                                                    >
                                                        {
                                                            riskData?.map((el,idx) => (
                                                                <Cell  key={`cell-${idx}`}
                                                                       fill={RISK_COLORS[idx % RISK_COLORS.length]}
                                                                />
                                                            ))
                                                        }
                                                    </Pie>
                                                </PieChart>
                                            </ResponsiveContainer>
                                        </div>
                                        <div className="project__dashboard-stats">
                                            <div className="project__dashboard-stat">
                                                <div className="project__dashboard-statBox">
                                                    <div className="project__program-color green"></div>
                                                    <p>{translations?.['titles.low']}</p>
                                                </div>
                                                <span>{dashboardData?.data?.risk_management_chart?.low || '0'}%</span>
                                            </div>
                                            <div className="project__dashboard-stat">
                                                <div className="project__dashboard-statBox">
                                                    <div className="project__program-color yellow"></div>
                                                    <p>{translations?.['titles.moderate']}</p>
                                                </div>
                                                <span>{dashboardData?.data?.risk_management_chart?.moderate || '0'}%</span>
                                            </div>
                                            <div className="project__dashboard-stat">
                                                <div className="project__dashboard-statBox">
                                                    <div className="project__program-color orange"></div>
                                                    <p>{translations?.['titles.substantial']}</p>
                                                </div>
                                                <span>{dashboardData?.data?.risk_management_chart?.substantial || '0'}%</span>
                                            </div>
                                            <div className="project__dashboard-stat">
                                                <div className="project__dashboard-statBox">
                                                    <div className="project__program-color red"></div>
                                                    <p>{translations?.['titles.high']}</p>
                                                </div>
                                                <span>{dashboardData?.data?.risk_management_chart?.high || '0'}%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        dashboardData?.data?.project_settings?.actual_planned_payments ?
                            <>
                                {
                                    paymentsData?.length !== 0 ?
                                        <VersusChart
                                            translations={translations}
                                            reportId={dashboardData?.data?.latest_report?.id}
                                            id={data?.data?.id}
                                            isPage={true}
                                        /> :
                                        <div className="plannedProject__placeholder" >
                                            <img src="/assets/icons/planned_chart.svg" alt=""/>
                                            There is no Data yet
                                        </div>
                                }
                            </>
                            : <div style={{background: 'white', padding: '36px 0', marginBottom: 20, border: '1px solid rgba(0,0,0, 0.1)'}}>
                                <div className="plannedProject__placeholder">
                                    <img src="/assets/icons/chart-placeholder.png" alt=""/>
                                    This section isn't included in this project
                                </div>
                            </div>
                    }
                </div>
                <div className="project__dashboard-right">
                    <div className="project__dashboard-info tooltipContainer" >
                        <h4 className="project__dashboard-infoTitle">{translations?.['titles.current_contract_amount']}</h4>
                        <p>${String(
                            dashboardData?.data?.project_monitoring?.budget
                        ).replace(/(\d)(?=(\d{3})+(\D|$))/g, "$1,")}</p>
                        <div className="tooltipContainer__tooltip">
                            <div className="tooltipContainer__tooltip__item">
                                <h4 className="project__dashboard-infoTitle">{translations?.['titles.original_contract_amount']}</h4>
                                <p>${String(
                                    dashboardData?.data?.contract_details?.original_contract_amount
                                ).replace(/(\d)(?=(\d{3})+(\D|$))/g, "$1,")}</p>
                            </div>
                            <div className="tooltipContainer__tooltip__item">
                                <h4 className="project__dashboard-infoTitle">{translations?.['titles.total_approved_variations']}</h4>
                                <p>${String(dashboardData?.data?.variations_and_claims?.total_approved_variations_cost).replace(/(\d)(?=(\d{3})+(\D|$))/g, "$1,")}</p>
                            </div>
                            <div className="tooltipContainer__tooltip__item">
                                <h4 className="project__dashboard-infoTitle">{translations?.['titles.total_certified_claims']}</h4>
                                <p>${String(dashboardData?.data?.variations_and_claims?.total_claims_certified_amount).replace(/(\d)(?=(\d{3})+(\D|$))/g, "$1,")}</p>
                            </div>
                            <div className="tooltipContainer__tooltip__item">
                                <h4 className="project__dashboard-infoTitle">{translations?.['titles.new_contract_amount']}</h4>
                                <p>${String(dashboardData?.data?.variations_and_claims?.approved_revised_contract_cost).replace(/(\d)(?=(\d{3})+(\D|$))/g, "$1,")}</p>
                            </div>
                        </div>
                    </div>
                    <div className="project__dashboard-info">
                        <h4 className="project__dashboard-infoTitle">{translations?.['titles.country']}</h4>
                        <p>{dashboardData?.data?.contract_details?.country?.name}</p>
                    </div>
                    <div className="project__dashboard-info">
                        <h4 className="project__dashboard-infoTitle">{translations?.['titles.variance_days']}</h4>
                        <p>{dashboardData?.data?.project_monitoring?.variance_to_completion}</p>
                    </div>
                    {
                        dashboardData?.data?.project_settings?.project_progress ?
                            <div className="project__dashboard-info">
                                <h4 className="project__dashboard-infoTitle">{translations?.['titles.overall_progress']}</h4>
                                <p>{dashboardData?.data?.program_progress?.total_actual ?? "-"}%</p>
                            </div> : null
                    }
                    {
                        dashboardData?.data?.project_settings?.program_monitoring ?
                            <div className="project__dashboard-info">
                                <h4 className="project__dashboard-infoTitle">{translations?.['titles.variance_days']}</h4>
                                <p>{dashboardData?.data?.project_monitoring?.variance_to_completion ?? "-"} </p>
                            </div> : null
                    }
                    <div className="project__dashboard-info">
                        <h4 className="project__dashboard-infoTitle">{translations?.['titles.expected_contract_completion_date']}</h4>
                        <p>
                            {dashboardData?.data?.project_monitoring?.expected_completion_date !== null
                                ? moment(dashboardData?.data?.project_monitoring?.expected_completion_date).format(
                                    "DD/MM/YYYY"
                                )
                                : ""}
                        </p>
                    </div>
                    {
                        dashboardData?.data?.project_settings?.risk_management ?
                            <div className="project__dashboard-info">
                                <h4 className="project__dashboard-infoTitle">{translations?.['titles.high_risks']}</h4>
                                <p>{dashboardData?.data?.project_monitoring?.risks}</p>
                            </div> : null
                    }
                </div>
            </div>
        </section>
    );
};
