import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import {Switch} from "../../UI/Switch";
import React, {useEffect, useState} from 'react';
import Modal from "../../components/Modal";
import Authenticator from "../../components/Modal/Authenticator";
import VerifyAuthentication from "../../components/Modal/VerifyAuthentication";
import {setLoader} from "../../store/slice/dashboard";
import {
    useLazyGetAuthenticatorQrQuery, useConfirmAuthQrCodeMutation,
    useDeleteAuthenticatorMutation,
    useLazyGetAccountSettingsQuery, useAllowToMFAMutation
} from "../../store/service/adbService";
import './index.scss';
import {errorHandle} from "../../global/errorHandle";

const AccountAuthenticator = ({accountData, pageData}) =>{
    const dispatch = useDispatch();
    const [qrData, setQrData] = useState(null)
    const [allowMFA, setAllowMFA] = useState(false);
    const [isAuthModal, setAuthModal] = useState(false);
    const [isAuthVerifyModal, setAuthVerifyModal] = useState(false);
    const [switchAuthenticator, setSwitchAuthenticator] = useState(false);

    const [allowToMFA,{
        data: allowToMFAData,
        isLoading: allowToMFAIsLoading,
        isSuccess: allowToMFAIsSuccess,
        isError:  allowToMFAIsError,
        error: allowToMFAError,
    }] = useAllowToMFAMutation();

    const [getAccountData,{
        isLoading: accountDataIsLoading,
    }] = useLazyGetAccountSettingsQuery();

    const [getQrCode,{
        error: getQrCodeError,
        isError: getQrCodeIsError,
        isLoading: getQrCodeIsLoading,
    }] = useLazyGetAuthenticatorQrQuery();

    const [confirmQrCode, {
        data: confirmQrCodeData,
        error: confirmQrCodeError,
        isError: confirmQrCodeIsError,
        isSuccess: confirmQrCodeIsSuccess,
        isLoading: confirmQrCodeIsLoading,
    }] = useConfirmAuthQrCodeMutation();

    const [deleteAuthenticator,{
        data: deleteAuthenticatorData,
        isSuccess: deleteAuthenticatorIsSuccess,
        isLoading: deleteAuthenticatorIsLoading,
    }] = useDeleteAuthenticatorMutation();

    useEffect( () => {
        if (allowToMFAIsSuccess){
            getAccountData();
        }
    }, [allowToMFAData, allowToMFAIsSuccess])

    const addNewAuthenticator = async () => {
        await getQrCode()
            .then((qrCode) =>{
                if (qrCode.status==="fulfilled"){
                    setQrData(qrCode.data);
                    toast.success(qrCode.data.message)
                    setAuthModal(true);
                }
                else toast.error(qrCode.errors.message)
            })
    }

    const verifyAuthenticator = async (code) => {
        await confirmQrCode({
            secret: qrData?.data?.secret,
            code: code,
        })
    }
    const deleteAuthenticatorQr = async () => {
        await deleteAuthenticator();
    }
    const allowAuthenticatorToMFA = async (isAllowed) => {
        if (!accountData?.data.authenticator.enabled) {
            await addNewAuthenticator()
                .then(() =>{
                    setAllowMFA(isAllowed)
                })
        } else {
            await allowToMFA('google');
            localStorage.removeItem('mfa_token');
        }
    }

    useEffect( () => {
        if (confirmQrCodeIsSuccess){
            toast.success(confirmQrCodeData.message);
            localStorage.setItem("authenticator", confirmQrCodeData.data?.authenticator)
            getAccountData();
            setAuthVerifyModal(false);
            if (allowMFA){
                setSwitchAuthenticator(allowMFA);
                allowToMFA('google');
                localStorage.removeItem('mfa_token');
            }
        }
    },[confirmQrCodeIsSuccess]);

    useEffect(() => {
        setSwitchAuthenticator(!!accountData?.data.authenticator.enabled);
    }, [accountData]);

    useEffect( () => {
        if (deleteAuthenticatorIsSuccess){
            toast.success(deleteAuthenticatorData.message);
            getAccountData();
        }
    },[deleteAuthenticatorIsSuccess]);

    useEffect( () => {
        if (confirmQrCodeIsError){
            toast.error(confirmQrCodeError?.data?.errors?.code);
        }
    },[confirmQrCodeIsError]);

    useEffect( () => {
        if (getQrCodeIsError){
            toast.error(getQrCodeError?.data.errors);
        }
    },[getQrCodeIsError]);

    useEffect( () => {
        if (allowToMFAIsError){
            errorHandle(allowToMFAError)
        }
    },[allowToMFAIsError,allowToMFAError]);

    useEffect(() => {
        getQrCodeIsLoading || confirmQrCodeIsLoading || accountDataIsLoading || deleteAuthenticatorIsLoading || allowToMFAIsLoading ? dispatch(setLoader(true)) : dispatch(setLoader(false))
    }, [ getQrCodeIsLoading, confirmQrCodeIsLoading, accountDataIsLoading, deleteAuthenticatorIsLoading, allowToMFAIsLoading]);

    return(
        <>
            <div className="settings__content__item">
                <img className="settings__content__item__img"  src="/assets/icons/passwor-code.svg"
                     alt="password-code"/>
                <div className="settings__content__item__wrapper">
                    <h2>{pageData['authenticator_app.authenticator_app.headers.authenticator_app']}</h2>
                    <p>
                        {pageData['authenticator_app.authenticator_app.titles.use_google_authenticator']}
                    </p>
                </div>
                <div className="settings__content__item__rightWrapper">
                    {switchAuthenticator &&
                        <>
                            <button
                                className="settings__content__item__editBtn"
                                onClick={addNewAuthenticator}
                            >
                                <img className="settings__content__item__img" src="/assets/icons/edit-black.svg" alt="edit-icon"/>
                            </button>
                            <button
                                className="settings__content__item__deleteBtn"
                                onClick={deleteAuthenticatorQr}
                            >
                                <img className="settings__content__item__img" src="/assets/icons/delete-black.svg" alt="delete-icon"/>

                            </button>
                        </>
                    }
                    <Switch
                        id={'allowGoogleToMfa'}
                        checked={switchAuthenticator}
                        handler={() => {allowAuthenticatorToMFA(!switchAuthenticator)}}
                    />
                </div>
            </div>
            {isAuthModal &&
                <Modal
                    title={pageData["authenticator_app.edit.headers.link_an_authenticator"]}
                    closeModal={setAuthModal}
                >
                    <Authenticator
                        qrData={qrData?.data}
                        description={pageData["authenticator_app.edit.titles.scan_qr_code"]}
                        extraInfo={pageData["authenticator_app.add.titles.if_you_are_unable_to_scan_qr_code"]}
                        btnTitle={pageData["authenticator_app.add.actions.next"]}
                        verifyFunc={() => {
                            setAuthVerifyModal(true);
                            setAuthModal(false);
                        }
                        }
                    />
                </Modal>
            }
            {isAuthVerifyModal &&
                <Modal
                    title={pageData["authenticator_app.confirm.headers.verify_authenticator"]}
                    closeModal={() => setAuthVerifyModal(false)}
                >
                    <VerifyAuthentication
                        description={pageData["authenticator_app.confirm.titles.authenticator_app_code"]}
                        extraInfo={pageData["authenticator_app.confirm.titles.enter_6_digit_code"]}
                        btnTitle={pageData["authenticator_app.confirm.actions.verify"]}
                        inputPlaceholder={pageData["authenticator_app.confirm.titles.enter_code"]}
                        verifyFunc={verifyAuthenticator}
                    />
                </Modal>}
        </>
    )
}

export default AccountAuthenticator;
