import React from 'react';
import MonitoringTable from "./MonitoringTable";
import LineChartWithXAxisPadding from "../../../components/LineChartWithXAxisPadding";
import './index.scss';

const CashflowOfContracts = ({reportId, translations, data}) => {

    return (
        <div className="listOfTable__subSections">
            <div className="editReport__sectionHeader">
                <h3>
                    {translations?.['lists_of_tables.cashflow_of_contracts.headers.cashflow_of_contracts']}
                </h3>
            </div>
            {data?.projects_data?.map(item =>
                <MonitoringTable
                    key={item.contract_title}
                    reportId={reportId}
                    data={item}
                    translations={translations}
                    isCashflow={true}
                />
            )}

            <div  className="listOfTable__monitoringContainer">
                <h2>{translations?.['lists_of_tables.cashflow_of_contracts.titles.actual_versus_planned_disbursements_across_all_contracts']}</h2>
                <div className="plannedProject__chart">
                    <LineChartWithXAxisPadding charData={data?.chart_data} translations={translations} tooltipSymbolBeforeValue={'$'} />
                </div>
            </div>
        </div>
    );
};

export default CashflowOfContracts;
