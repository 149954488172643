import React, {useCallback} from 'react';
import AxelInput from "../../UI/AxelInput";
import AxelButton from "../../UI/AxelButton";
import './index.scss';

const EmailChangeContent  =  (props) => {
    const {
        description,
        btnTitle,
        inputPlaceholder,
        inputData,
        setInputData,
        submitHandler
    } = props;

    const inputHandler = useCallback((e) => {
        setInputData(old => ({
                ...old,
             value: e.target.value}
        ));
    }, []);

    return(
        <div className="modal-mainContainer">
            <div className="modal-text">
                {description}
            </div>
            <AxelInput
                type="text"
                placeholder={inputPlaceholder}
                value={inputData.value}
                handler={inputHandler}
                id={inputData.id}
                error={inputData.error}
                errorShow={inputData.errorShow}
            />
            <AxelButton
                type="button"
                handler={submitHandler}
                className="modal-sure"
            >
                {btnTitle}
            </AxelButton>
        </div>
    )
}

export default EmailChangeContent;