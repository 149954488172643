import React, {useEffect, useState} from "react";
import axios from "axios";
import {useLocation} from "react-router-dom";
import SubSectionTableContainer from "../EditQuarterlyReport/SubSectionTableContainer";
import useLastPathname from "../../global/useLastPathname";

const QuarterProgressTable = ({reportId, date}) => {
    const location = useLocation()
    const lastPathname = useLastPathname();
    const [progressData, setProgressData] = useState([])
    const [hasLongText, setHasLongText] = useState(false);
    const [docTranslations, setDocTranslations] = useState([]);

    useEffect(() => {
        const checkLongText = () => {
            if (progressData) {
                for (let item of progressData) {
                    if (item?.cumulative_actual?.length >9) {
                        setHasLongText(true);
                        return;
                    }
                }
            }
            setHasLongText(false);
        };

        checkLongText();
    }, [progressData]);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/chart/quarter-progress-table/${reportId ? reportId : location?.pathname?.split('/')[2]}/${date ? date : location?.pathname?.split('/')[3]}`, {
            headers: {
                "AppLocale": lastPathname
            }
        })
            .then((res) => {
                let response = res?.data?.data?.data;
                setDocTranslations(res?.data?.data?.translations);
                setProgressData(response)
            })

    }, [reportId])


    const tdStyle = hasLongText ? { width: '166.8px' } : { width: '98.8px' };

    return (
      <SubSectionTableContainer style={{width: 'fit-content', padding: '12px 20px'}}>
            <div className="progress-table">
                <table>
                    <thead>
                    <tr>
                        <th style={{ width: '160px' }}>{docTranslations?.['month']}</th>
                        {progressData?.map((item, index) => (
                            <th style={{ ...tdStyle }} key={index}>{item.date}</th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className="label planned" style={{ width: '160px' }}>
                            <span className="planned-value" />
                            <span>{docTranslations?.['cumulative_planned']}</span>
                        </td>
                        {progressData?.map((item, index) => (
                            <td key={index} style={{ ...tdStyle }}>{item?.cumulative_planned_amount}</td>
                        ))}
                    </tr>
                    <tr>
                        <td className="label actual" style={{ width: '160px' }}>
                            <span className="actual-value" />
                            <span>{docTranslations?.['cumulative_actual']}</span>
                        </td>
                        {progressData?.map((item, index) => (
                            <td key={index} style={{ ...tdStyle }}>
                                {item?.cumulative_actual_amount !== '%'
                                    ? item?.cumulative_actual_amount
                                    : '---'}
                            </td>
                        ))}
                    </tr>
                    </tbody>
                </table>
            </div>
        </SubSectionTableContainer>

    )
}

export default QuarterProgressTable;