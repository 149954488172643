import React, {useRef, useState} from 'react';
import './index.scss';
import MoreInfoModal from "../../../components/MoreInfoModal";

const Loan = ({data, translations, hiddenSectionHandler}) => {
    const sectionRef = useRef(null);
    const [isOpenMoreInfo, setOpenMoreInfo] = useState(false)

    return (
       <section className="erLoan" id='loan_grant_key_data' ref={sectionRef}>
           {isOpenMoreInfo &&
               <MoreInfoModal
                   title= {translations?.['loan_grant_key_data.index.headers.loan_grant_key_data']}
                   moreInfo={translations?.['loan_grant_key_data.index.more_info.more_info']}
                   close={()=>setOpenMoreInfo(false)}
               />
           }
           <div className="editReport__sectionHeader" >
               <span onClick={() => hiddenSectionHandler(sectionRef)}/>
               <h2 onClick={() => hiddenSectionHandler(sectionRef)}>
                   {translations?.['loan_grant_key_data.index.headers.loan_grant_key_data']}
               </h2>
               <button className="editReport__sectionHeader__moreInfo" onClick={()=>setOpenMoreInfo(!isOpenMoreInfo)}>
                   <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                       <path d="M6.99805 6.33325V9.43992" stroke="#767676" strokeLinecap="round" strokeLinejoin="round"/>
                       <path d="M6.99805 5.05322C7.27419 5.05322 7.49805 4.82937 7.49805 4.55322C7.49805 4.27708 7.27419 4.05322 6.99805 4.05322C6.7219 4.05322 6.49805 4.27708 6.49805 4.55322C6.49805 4.82937 6.7219 5.05322 6.99805 5.05322Z" fill="#767676"/>
                       <path d="M6.9987 13.1666C10.4045 13.1666 13.1654 10.4057 13.1654 6.99992C13.1654 3.59416 10.4045 0.833252 6.9987 0.833252C3.59294 0.833252 0.832031 3.59416 0.832031 6.99992C0.832031 10.4057 3.59294 13.1666 6.9987 13.1666Z" stroke="#767676" strokeLinecap="round" strokeLinejoin="round"/>
                   </svg>

                   {translations?.['loan_grant_key_data.index.titles.more_info']}
               </button>
           </div>
           <div>
               <div className='table' style={{paddingBottom: 0, minWidth: 'fit-content'}} >
                   <div className="table-body">
                       <div className="table-row">
                           <div className="table-td">
                               <span>{translations?.['loan_grant_key_data.index.titles.loan_grant_number']}</span>
                               <h6>{data?.loan_grant_key_data?.loan_grant_number}</h6>
                               <div className="erLoan__divider"/>
                           </div>
                           <div className="flexBox">
                               <div className="table-td">
                                   <span>{translations?.['loan_grant_key_data.index.titles.current_closing_date']}</span>
                                   <h6>{data?.loan_grant_key_data?.closing_date}</h6>
                               </div>
                               <div className="table-td">
                                   <div className="flexBox__divider"/>
                                   <span>{translations?.['loan_grant_key_data.index.titles.original_closing_date']}</span>
                                   <h6>{data?.loan_grant_key_data?.original_closing_date}</h6>
                               </div>
                           </div>
                       </div>
                       <div className="table-row">
                           <div className="table-td">
                               <span>{translations?.['loan_grant_key_data.index.titles.approval_date']}</span>
                               <h6>{data?.loan_grant_key_data?.approval_date}</h6>
                               <div className="erLoan__divider"/>
                           </div>
                           <div className="table-td">
                               <span>{translations?.['loan_grant_key_data.index.titles.physical_completion_date']}</span>
                               <h6>{data?.loan_grant_key_data?.physical_completion_date}</h6>
                           </div>
                       </div>
                       <div className="table-row">
                           <div className="table-td">
                               <span>{translations?.['loan_grant_key_data.index.titles.signing_date']}</span>
                               <h6>{data?.loan_grant_key_data?.signing_date}</h6>
                               <div className="erLoan__divider"/>
                           </div>
                           <div className="table-td">
                               <span>{translations?.['loan_grant_key_data.index.titles.elapsed_loan_period']}</span>
                               <h6>{data?.loan_grant_key_data?.elapsed_loan_period}</h6>
                           </div>
                       </div>
                       <div className="table-row">
                           <div className="table-td">
                               <span>{translations?.['loan_grant_key_data.index.titles.commencement_effective_date']}</span>
                               <h6>{data?.loan_grant_key_data?.commencement_effective_date}</h6>
                               <div className="erLoan__divider"/>
                           </div>
                           <div className="table-td">
                               <span>{translations?.['loan_grant_key_data.index.titles.physical_project_progress']}</span>
                               <h6>{data?.loan_grant_key_data?.physical_project_progress}</h6>
                           </div>
                       </div>
                   </div>
               </div>
           </div>

           {/*Included Projects*/}
           <hr/>
           <div className="erGrantKey">
               <div className="erLoan__header">
                   <h3>
                       {translations?.['loan_grant_key_data.index.headers.included_projects']}: {data?.loan_grant_key_data?.projects.length}
                   </h3>
               </div>
               <div className="editReport__table">
                   <div className="editReport__table__row ">
                       <span className='erGrantKey__tableHeaderTitle'>{translations?.['loan_grant_key_data.index.titles.project_name']}</span>
                       <span className='erGrantKey__tableHeaderTitle'>{translations?.['loan_grant_key_data.index.titles.contract_number']}</span>
                   </div>
                   {data?.loan_grant_key_data?.projects.map(item=>
                       <div className="editReport__table__row" key={item.id}>
                           <span>{item.contract_title}</span>
                           <span>{item.contract_number}</span>
                       </div>
                   )}

               </div>
           </div>
       </section>
    )
}
export default Loan;
