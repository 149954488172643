import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {Link, useLocation, useNavigate} from "react-router-dom";

import Wrapper from "../../components/Wrapper";
import { PROJECTS } from "../../Const/projects";
import {
  useDeleteProjectMutation,
  useGetProjectsQuery,
  useLazyGetAllProjectsQuery,
  useUpdateDocumentMutation,
  useUpdateProjectMutation
} from "../../store/service/adbService";
import Loader from "../../components/Loader";
import moment from "moment";
import "./index.scss";
import ProjectModal from "../../components/ProjectModal";
import { setLoader } from "../../store/slice/dashboard";
import { useErrorHandler } from "../../global/ErrorsHandler";
import { toast } from "react-toastify";
import {Paginate} from "../../components/Paginate";
import AxelCheckbox from "../../UI/AxelCheckbox";
import useDebounce from "../../global/useDebounce";
import CustomModal from "../../components/customModal";

export default function ProjectList() {
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector((state) => state?.adbReducer?.user);
  const navigate = useNavigate();
  const [currentItem, setCurrentItem] = useState(null);
  const [deletingProject, setDeletingProject] = useState()
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [searchTerm, setSearchTerm] = useState('');
  const [checkbox, setCheckbox] = useState(0)
  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  const [getAllProjects,
    {
      data: allProjects,
      isError: projectsIsError,
      isLoading: projectsLoading,
      isFetching: projectsFetching,
      isSuccess: projectsSuccess,
      error: projectError,
    }
  ] = useLazyGetAllProjectsQuery();

  const [
    deleteProject,
    {
      data: deleteProjectData,
      isError: deleteProjectIsError,
      isLoading: deleteProjectIsLoading,
      isSuccess: deleteProjectIsSuccess,
      error: deleteProjectError,
    },
  ] = useDeleteProjectMutation();


  const [updateProject, {isLoading: projectIsLoading, isFetching: projectFetching, isSuccess: projectSuccess}] = useUpdateProjectMutation()
  const [projectsArray, setProjectsArray] = useState([]);

  useEffect(() => {
    getAllProjects({
      per_page: 20,
      page: currentPage,
      all_projects: checkbox,
      search: debouncedSearchTerm
    })
  }, [currentPage, checkbox, debouncedSearchTerm])

  useEffect(() => {
    if (allProjects) {
      setTotalPage(allProjects?.data?.last_page)
    }
  }, [allProjects, currentItem])

  useEffect(() => {
    if (!showModal) {
      setCurrentItem(null);
    }
  }, [showModal]);

  useEffect(() => {
    setProjectsArray(allProjects?.data?.data?.map((el) => ({ ...el, edit: false })));
    projectsLoading || projectsFetching ? dispatch(setLoader(true)) : dispatch(setLoader(false));
  }, [projectsLoading, allProjects, projectError, projectsIsError, projectsFetching]);
  const [errorHandler, setErrorHandler] = useErrorHandler([projectError]);

  useEffect(()=>{
    const token = localStorage.getItem('user_token');
    if (projectError?.data?.message === "Your account has been deactivated.") {
      toast.error('Your account has been deactivated.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 8000,
      });
      localStorage.removeItem('user_token')
      setTimeout(() => {
        navigate("/login");
      }, 500);
    }
    if (projectError?.originalStatus === 401) {
      toast.error('Unauthorized.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 8000,
      });
      dispatch(setLoader(false))
      localStorage.removeItem('user_token')
      setTimeout(() => {
        navigate("/login");
      }, 500);
    }
  },[])

  return (
      <Wrapper>
        {showModal && (
            <ProjectModal currentItem={currentItem} setShowModal={setShowModal} />
        )}
        <h1 className="text-dark text-[36px] dashboard__title">Project List</h1>
        <div className="flex justify-end items-center">
          <div className="dashboard__checkboxs mr-15">
            <div className="dashboard__checkbox">
              <div className="a-checkbox">
                <input type="radio" id="1-radio" defaultChecked={true} name="projects" onChange={() => {setCheckbox(0)}} />
                <label htmlFor="1-radio">Active Projects</label>
              </div>
            </div>
            <div className="dashboard__checkbox">
              <div className="a-checkbox">
                <input type="radio" id="0-radio" name="projects" onChange={() => {setCheckbox(1)}} />
                <label htmlFor="0-radio">All Projects</label>
              </div>
            </div>
          </div>
          <div className="search-block">
            <input placeholder="Search..." type="text" className="search" onChange={(e) => {
              setSearchTerm(e.target.value)
            }} />
          </div>
          {user?.permissions?.new_project && (
              <button
                  onClick={() => {
                    // setShowModal(true)
                    navigate("/create-project/step-1", {
                      state: { allProjects: allProjects?.data },
                    });
                  }}
                  className="dashboard__btn"
              >
                <img src="/assets/icons/plus.svg" alt="plus" />
                <span>Add Project</span>
              </button>
          )}
        </div>
        {projectsArray?.length > 0 ? (
            <div className="relative mt-[24px]">
              <table className="w-full text-left dashboard__table">
                <thead className="dashboard__thead">
                <tr className="dashboard__tr">
                  <th scope="col" className="py-3 px-6 dashboard__th">
                    Project name
                  </th>
                  <th scope="col" className="py-3 px-6 dashboard__th">
                    Contract number
                  </th>
                  <th scope="col" className="py-3 px-6 dashboard__th">
                    Budget
                  </th>
                  <th scope="col" className="py-3 px-6 dashboard__th">
                    Country
                  </th>
                  <th scope="col" className="py-3 px-6 dashboard__th">
                    Actual Progress
                  </th>
                  <th scope="col" className="py-3 px-6 dashboard__th">
                    Variance (Days)
                  </th>
                  <th scope="col" className="py-3 px-6 dashboard__th">
                    Completion Date
                  </th>
                  <th scope="col" className="py-3 px-6 dashboard__th">
                    #High Risks
                  </th>
                  <th scope="col" className="py-3 px-6 dashboard__th">
                    Last Updated
                  </th>
                  <th scope="col" className="py-3 px-6 dashboard__th">
                    Report in-Process
                  </th>
                  {/*<th scope="col" className="py-3 px-6 dashboard__th">*/}
                  {/*  Status*/}
                  {/*</th>*/}
                  <th scope="col" className="py-3 px-10 dashboard__th">
                  </th>
                </tr>
                </thead>
                <tbody className="dashboard__tbody">
                {projectsArray?.map((item, index) => (
                    <tr
                        onMouseEnter={() => {
                          setProjectsArray(
                              projectsArray.map((el) =>
                                  el.id === item.id
                                      ? { ...el, edit: true }
                                      : { ...el, edit: false }
                              )
                          );
                        }}
                        onMouseLeave={() => {
                          setProjectsArray(
                              projectsArray.map((el) =>
                                  el.id === item.id
                                      ? { ...el, edit: false }
                                      : { ...el, edit: false }
                              )
                          );
                        }}
                        className={`dashboard__tr ${item?.edit ? "edit" : ""}`}
                        key={index}
                    >
                      <td className="py-4 px-6 dashboard__td">
                        <div
                            onClick={() => {
                              localStorage.setItem("itemId", item.id);
                            }}
                        >
                          {item?.project_name}
                        </div>
                      </td>
                      <td className="py-4 px-6">{item?.contract_number ?? ""}</td>
                      <td className="py-4 px-6">
                        {item?.budget == 'N/A' ? '' : '$'}
                        {String(item?.budget).replace(
                            /(\d)(?=(\d{3})+(\D|$))/g,
                            "$1,"
                        ) ?? "-"}
                      </td>
                      <td className="py-4 px-6">{item?.country ?? "-"}</td>
                      <td className="py-4 px-6">{item?.actual_progress !== "N/A" ? item?.actual_progress + '%' : "N/A"}</td>
                      <td className="py-4 px-6">{item?.variance ?? "-"}</td>
                      <td className="py-4 px-6">
                        {item?.completion_date !== 'N/A' && item?.completion_date ? moment(item?.completion_date).format("DD/MM/yy") : ""}
                      </td>
                      <td className="py-4 px-6 text-center">{item?.risks ?? "-"}</td>
                      <td className="py-4 px-6">
                        {item?.last_updated !== 'N/A' && item?.last_updated ? moment(item?.last_updated).format("MM/DD/yy") : ""}
                      </td>
                      <td className="py-4 px-6" style={item?.report_in_process === 'Completed' ? {color: '#0caf60',} : {}}>{item?.report_in_process ? item?.report_in_process : "-"}</td>
                      {/*<td className="py-4 px-6">{item?.latest_report ? (item?.latest_report?.status ? item?.latest_report?.status : "-") : "-"}</td>*/}
                      <td className="py-4 dashboard__editBtns">
                        {user?.permissions?.inactive_project ?
                            <div className="a-checkbox">
                              <input key={item?.id} type="checkbox" id={item?.id} defaultChecked={item?.inactive == 1 ? true : false} onChange={(e) => {
                                dispatch(setLoader(true))
                                updateProject({body: {inactive: e.target.checked ? 1 : 0}, id: item?.id}).unwrap().then(res => {
                                  dispatch(setLoader(false))
                                }).catch(err => {
                                  dispatch(setLoader(false))
                                })
                              }} />
                              <label htmlFor={item?.id}></label>
                            </div>
                            : null}
                        <div
                            state={{ project: item }}
                            className="dashboard__editBtn"
                            onMouseEnter={() => {
                              localStorage.setItem("itemId", item.id);
                              setCurrentItem(item)
                            }}
                            onMouseLeave={() => {
                              setCurrentItem(null)
                            }}
                        >
                          <img src="/assets/icons/dashboardview.svg" alt=""/>
                          {`${currentItem?.id}-item` === `${item?.id}-item` && (
                              <div className="dashboard__editBtns-links"
                                   onMouseLeave={() => {
                                     setCurrentItem(null)
                                   }}>
                                {item?.actions?.charts?.dashboard_id ? <Link tag to={`/dashboard/${item?.id}`} state={{project: item, dash_visible:item?.actions?.charts?.dashboard_id, larp_visible: item?.actions?.charts?.larp_id }} onClick={() => localStorage.setItem("itemId", item.id)}>Project Dashboard</Link> : null }
                                {item?.actions?.charts?.larp_id  ? <Link to={`/larp-dashboard/${item?.id}`} state={{project: item, dash_visible:item?.actions?.charts?.dashboard_id, larp_visible: item?.actions?.charts?.larp_id}} onClick={() => localStorage.setItem("itemId", item.id)}>LARP Dashboard</Link> : null}
                              </div>
                          )}
                        </div>
                        <div
                            className="dashboard__editBtn"
                            onClick={() => {
                              localStorage.setItem("itemId", item.id);
                              navigate(`/online-view/${item?.actions?.view_id}`, {state: {id: item?.latest_report?.id, prevLocation: location?.pathname, date: item?.latest_report?.date}})
                            }}
                        >
                          <img src="/assets/icons/eyelist.svg" alt=""/>
                          <p>
                            View report
                          </p>
                        </div>
                        {
                            (user?.permissions?.edit_project || user?.permissions?.view_project) &&
                            <Link
                                to={`/project/${item?.id}`}
                                state={{ project: item }}
                                className="dashboard__editBtn"
                                onClick={() => {
                                  localStorage.setItem("itemId", item.id);
                                }}
                            >
                              <img src="/assets/icons/edit-report.svg" alt=""/>
                              <p>
                                Edit report
                              </p>
                            </Link>
                        }
                        { user?.permissions?.delete_project &&
                            <div
                                className="dashboard__editBtn"
                                onClick={()=> {
                                  setDeletingProject(item)
                                  setShowDeleteModal(true)
                                }}
                            >
                              <div className="dashboard__deleteBtn" >
                                <img color='red' src="/assets/icons/trash-grey.svg" alt="Delete project"/>
                              </div>
                              <p>
                                Delete project
                              </p>
                            </div>
                        }
                      </td>
                      {/*<td className="px-4 px-6">*/}
                      {/*  <img*/}
                      {/*    onClick={() => {*/}
                      {/*      setShowModal(true);*/}
                      {/*      setCurrentItem(item);*/}
                      {/*    }}*/}
                      {/*    style={{ width: 24, height: 24, cursor: "pointer" }}*/}
                      {/*    src="https://img.icons8.com/cotton/344/edit--v1.png"*/}
                      {/*    alt=""*/}
                      {/*  />*/}
                      {/*</td>*/}
                    </tr>
                ))}
                </tbody>
              </table>
            </div>
        ) : (
            <div style={{height: '70vh', width: '100%', display: "flex", justifyContent:"center", alignItems: 'center', flexDirection: "column"}}>
              <img src="/assets/images/projects.png" alt=""/>
              <span style={{color: "#767676", fontSize: 16, fontWeight: 500}}>No Projects</span>
            </div>
        )}
        {/* <div className="mt-[20px] flex items-center justify-center flex-col c-border">
        <div className="text-dark text-[20px]">Project Archive</div>
        <div className="underline text-[14px] mt-[10px]">Expand</div>
      </div> */}
        {projectsArray?.length > 0 && totalPage > 0 && <Paginate key={projectsArray} currentPage={currentPage} setCurrentPage={setCurrentPage} pageCount={totalPage}/>}
        {
            showDeleteModal &&
            <CustomModal
                acceptHandler={() => {
                  setShowDeleteModal(false)
                  deleteProject(deletingProject?.id)
                }}
                closeHandler={() => {
                  setShowDeleteModal(false)
                }}
                title={`Delete Confirmation`}
                hasHr
            >
              <p>
                Are you sure you want to delete {deletingProject?.project_name}?
              </p>
            </CustomModal>
        }
      </Wrapper>
  );
}
