import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { SecondaryPagesModal } from "../../components/SecondaryPagesModal";
import {
  useCreateRegionMutation,
  useDeleteRegionsMutation,
  useLazyGetAllRegionsQuery,
  useUpdateRegionMutation,
} from "../../store/service/adbService";
import { setLoader } from "../../store/slice/dashboard";
import { DeleteModal } from "../../components/Project/DeleteModal";
import {Paginate} from "../../components/Paginate";

export const RegionPage = ({}) => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [deleteModal, setDeleteModal] = useState(null);
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(0)

  const [getRegions, {
    data: regions,
    isLoading: regionsIsLoading,
  }] = useLazyGetAllRegionsQuery();

  const [
    createRegion,
    {
      isSuccess: createRegionIsSuccess,
      isLoading: createRegionIsLoading,
    },
  ] = useCreateRegionMutation();

  const [
    deleteRegion,
    {
      isLoading: deleteRegionIsLoading,
    },
  ] = useDeleteRegionsMutation();

  const [
    updateRegion,
    {
      isSuccess: updateRegionIsSuccess,
      isLoading: updateRegionIsLoading,
    },
  ] = useUpdateRegionMutation();


  useEffect(() => {
    getRegions({
      per_page: 10,
      page: currentPage,
    })
  }, [currentPage])

  useEffect(() => {
    if (regions) {
      setTotalPage(regions?.data?.last_page)
    }
  }, [regions])

  useEffect(() => {
    setModal(false);
    setCurrentItem(null);
  }, [createRegionIsSuccess, updateRegionIsSuccess]);

  useEffect(() => {
    !modal && setCurrentItem(null);
  }, [modal]);

  useEffect(() => {
    regionsIsLoading ||
    createRegionIsLoading ||
    deleteRegionIsLoading ||
    updateRegionIsLoading
      ? dispatch(setLoader(true))
      : dispatch(setLoader(false));
  }, [
    regionsIsLoading,
    createRegionIsLoading,
    deleteRegionIsLoading,
    updateRegionIsLoading,
  ]);

  return (
    <div className="s-pages">
      {deleteModal?.show && (
        <DeleteModal
          id={deleteModal?.id}
          action={deleteRegion}
          setDeleteModal={setDeleteModal}
        />
      )}
      {modal && (
        <SecondaryPagesModal
          title="Region"
          setModal={setModal}
          label="Region Name"
          createHook={createRegion}
          updateHook={updateRegion}
          currentItem={currentItem}
        />
      )}
      <div className="s-page__header">
        <h1>Region</h1>
        <button onClick={() => setModal(true)}>
          <img src="/assets/icons/plus.svg" alt="plus" />
          <span>Create Region</span>
        </button>
      </div>
      <div className="table">
        <div className="table-header">
          <div className="table-th">Region Name</div>
        </div>
        {
          regions?.data?.data?.length > 0 ?
            <div className="table-body">
              {regions?.data?.data?.map((item) => (
                  <div className="table-row" key={item?.id} id={item?.id}>
                    <div className="table-td">{item?.name}</div>
                    <div className="table-actions">
                      <div
                          onClick={() => {
                            setDeleteModal({ id: item?.id, show: true });
                            // deleteRegion(item?.id)
                          }}
                          className="table-delete"
                      >
                        <img src="/assets/icons/trash-icon.svg" alt="trash" />
                        <span>Delete</span>
                      </div>
                      <div
                          className="table-edit"
                          onClick={() => {
                            setCurrentItem(item);
                            setModal(true);
                          }}
                      >
                        <img src="/assets/icons/edit-icon-2.svg" alt="edit" />
                        <span>Edit</span>
                      </div>
                    </div>
                  </div>
              ))}
            </div> :
              <div style={{height: '70vh', width: '100%', display: "flex", justifyContent:"center", alignItems: 'center', flexDirection: "column"}}>
                <img src="/assets/images/projects.png" alt=""/>
                <span style={{color: "#767676", fontSize: 16, fontWeight: 500}}>No Regions</span>
              </div>
        }
      </div>
      {(regions?.data?.data?.length > 0 && totalPage > 0) && <Paginate currentPage={currentPage} setCurrentPage={setCurrentPage} pageCount={totalPage}/>}
    </div>
  );
};
