import isEmpty from "lodash/isEmpty";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch } from "../../../UI/Switch";
import { useEffect } from "react";
import Select from "react-select";
import { DeleteModal } from "../DeleteModal";
import {
    useCreateRiskManagementMutation,
    useDeleteRiskManagementMutation,
    useLazyGetAllCompaniesQuery,
    useUpdateRiskManagementMutation,
} from "../../../store/service/adbService";
import { useErrorHandler } from "../../../global/ErrorsHandler";
import { setLoader } from "../../../store/slice/dashboard";
import MoreInfoModal from "../../MoreInfoModal";
import moment from "moment/moment";

const customStyles = {
    menu: (provided, state = false) => ({
        ...provided,
        width: "150%",
        color: state.selectProps.menuColor,
        cursor: "pointer",
        overflow: "hidden",
    }),

    control: (_, { selectProps: {  } }) => ({
        width: "100%",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        display: "flex",
        background: "#FFFFFF",
        borderRadius: 8,
    }),
};

export const RiskManagement = ({
                                   glossaryData,
                                   id,
                                   data,
                                   refRiskManagement,
                                   translations
                               }) => {
    const dispatch = useDispatch();
    const [currentItem, setCurrentItem] = useState(null);
    const [newItem, setNewItem] = useState(false);
    const [options, setOptions] = useState([]);
    const [deleteModal, setDeleteModal] = useState(null);
    const [show, setShow] = useState(true);
    const [showByActive, setShowByActive] = useState(true);
    const [hasArchived, setHasArchived] = useState(false);
    const user = useSelector((state) => state?.adbReducer?.user);
    const [tableHeaders, setTableHeaders] = useState([]);
    const [riskData, setRiskData] = useState([]);
    const [moreInfo, setMoreInfo] = useState(null);
    const [infoModal, setInfoModal] = useState(false);
    const [currentRow, setCurrentRow] = useState(null);
    const [riskOptions, setRiskOptions] = useState([]);
    const [likelyOptions, setLikelyOptions] = useState([]);
    const scrollRef = useRef(null);
    const expandedRef = useRef(null);
    const [expanded, setExpanded] = useState(false);
    const descrRef = useRef(null);
    const [decrExpanded, setDecrExpanded] = useState(false);
    const localeLan = localStorage.getItem("local_lan" );
    moment.locale(localeLan);

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                let array = riskData?.map((item) => {
                    item.edit = false;
                    return item;
                });
                setCurrentItem(null);
                setRiskData(array);
                if (newItem) {
                    setNewItem(false);
                    let s = riskData?.filter((el, i) => i !== riskData.length - 1);
                    setRiskData(s);
                }
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, [data]);

    useEffect(() => {
        if(translations){
            const headers = [
                {
                    tooltip_name: translations?.['risk_management.risk_management.table_headers.ref'],
                    tooltip_text: translations?.['risk_management.risk_management.tooltips.ref'],
                },
                {
                    tooltip_name: translations?.['risk_management.risk_management.table_headers.risk_description'],
                    tooltip_text: translations?.['risk_management.risk_management.tooltips.risk_description'],
                },
                {
                    tooltip_name: translations?.['risk_management.risk_management.table_headers.impact_rating'],
                    tooltip_text: translations?.['risk_management.risk_management.tooltips.impact_rating'],
                },
                {
                    tooltip_name: translations?.['risk_management.risk_management.table_headers.likelihood_rating'],
                    tooltip_text: translations?.['risk_management.risk_management.tooltips.likelihood_rating'],
                },
                {
                    tooltip_name: translations?.['risk_management.risk_management.table_headers.risk_level'],
                    tooltip_text: translations?.['risk_management.risk_management.tooltips.risk_level'],
                },
                {
                    tooltip_name: translations?.['risk_management.risk_management.table_headers.mitigation_measure'],
                    tooltip_text: translations?.['risk_management.risk_management.tooltips.mitigation_measure'],
                },
                {
                    tooltip_name: translations?.['risk_management.risk_management.table_headers.responsible_party'],
                    tooltip_text: translations?.['risk_management.risk_management.tooltips.responsible_party'],
                },
                {
                    tooltip_name: translations?.['risk_management.risk_management.table_headers.current_status_or_comments'],
                    tooltip_text: translations?.['risk_management.risk_management.tooltips.current_status_or_comments'],
                },
                {
                    tooltip_name: translations?.['risk_management.risk_management.table_headers.closed'],
                    tooltip_text: translations?.['risk_management.risk_management.tooltips.closed'],
                },
            ];
            setRiskOptions([
                {
                    value: 1,
                    label: translations?.['risk_management.risk_management.dropdown.insignificant'],
                },
                {
                    value: 2,
                    label: translations?.['risk_management.risk_management.dropdown.minor'],
                },
                {
                    value: 3,
                    label: translations?.['risk_management.risk_management.dropdown.moderate'],
                },
                {
                    value: 4,
                    label: translations?.['risk_management.risk_management.dropdown.major'],
                },
                {
                    value: 5,
                    label: translations?.['risk_management.risk_management.dropdown.severe'],
                },
            ]);
            setLikelyOptions(
                [
                    {
                        value: 1,
                        label: translations?.['risk_management.risk_management.dropdown.rare'],
                    },
                    {
                        value: 2,
                        label: translations?.['risk_management.risk_management.dropdown.unlikely'],
                    },
                    {
                        value: 3,
                        label: translations?.['risk_management.risk_management.dropdown.possible'],
                    },
                    {
                        value: 4,
                        label: translations?.['risk_management.risk_management.dropdown.likely'],
                    },
                    {
                        value: 5,
                        label: translations?.['risk_management.risk_management.dropdown.almost_certain'],
                    },
                ]
            )

            setTableHeaders(
                headers.map((item) => ({
                    ...item,
                    showTooltip: false,
                }))
            );
        }
    }, [translations])

    useEffect(() => {
        if (data) {
            let parsedData = data?.risk_management?.map((el) => ({
                ...el,
                edit: false,
                responsible_party: { value: el?.responsible_party, label: el?.responsible_party },
                impact_rating: {
                    value: el?.impact_rating,
                    label: el?.impact_rating_text,
                },
                likelihood_rating: {
                    value: el?.likelihood_rating,
                    label: el?.likelihood_rating_text,
                },
                archived: el?.archived,
            }));
            let isHaveArchived = isEmpty(parsedData.find(item => item.archived===1));
            setHasArchived(!isHaveArchived);
            if (showByActive===true && !isHaveArchived){
                const filteredData =  parsedData.filter(item => item.archived ===0);
                setRiskData(filteredData);
            } else setRiskData(parsedData);
        }
        if (glossaryData?.data?.length) {
            setMoreInfo(
                glossaryData?.data?.filter(
                    (el) => el?.section_name === "Risk Management"
                )[0]
            );
        }
    }, [glossaryData, data, showByActive]);

    const [
        createRiskManagement,
        {
            isLoading: createRiskManagementIsLoading,
            error: createRiskManagementError,
        },
    ] = useCreateRiskManagementMutation();

    const [
        updateRiskManagement,
        {
            isLoading: updateRiskManagementIsLoading,
            error: updateRiskManagementError,
        },
    ] = useUpdateRiskManagementMutation();

    const [
        deleteRiskManagement,
        {
            isLoading: deleteRiskManagementIsLoading,
            error: deleteRiskManagementError,
        },
    ] = useDeleteRiskManagementMutation();

    const [errorHandler, setErrorHandler] = useErrorHandler([
        deleteRiskManagementError,
        updateRiskManagementError,
        createRiskManagementError,
    ]);

    const [
        getCompanies,
    ] = useLazyGetAllCompaniesQuery();

    useEffect(() => {
        getCompanies({
            per_page: 1000,
        });
    }, []);

    useEffect(() => {
        const d = () => {
            setRiskData((old) => {
                let data = old?.map((item) => ({ ...item, edit: false }));
                return data;
            });
            dispatch(setLoader(false));
        };

        deleteRiskManagementIsLoading ||
        updateRiskManagementIsLoading ||
        createRiskManagementIsLoading
            ? dispatch(setLoader(true))
            : d();
    }, [
        deleteRiskManagementIsLoading,
        updateRiskManagementIsLoading,
        createRiskManagementIsLoading,
    ]);

    const setterData = (input) => {
        const body = {
            project_id: id,
            risk_reference: input?.risk_reference,
            risk_description: input?.risk_description,
            impact_rating: input?.impact_rating?.value,
            likelihood_rating: input?.likelihood_rating?.value,
            mitigation_measure: input?.mitigation_measure,
            responsible_party: input?.responsible_party?.value,
        };

        return body;

    };

    const submitHandler = (e, item) => {
        e.preventDefault();
        if (newItem) {
            const dataCreated = setterData(currentItem);
            if (!dataCreated) {
                return;
            }
            createRiskManagement({
                ...dataCreated,
                closed: currentItem?.closed,
                current_status: currentItem?.current_status,
            });
            setCurrentItem(null);
            setNewItem(false);
            setCurrentRow(null);
        } else {
            const dataUpdated = setterData(currentItem);
            if (!dataUpdated) {
                return;
            }
            updateRiskManagement({
                id: item?.id,
                body: {
                    ...dataUpdated,
                    closed: currentItem?.closed,
                    current_status: currentItem?.current_status,
                },
            });
            setCurrentItem(null);
            setNewItem(false);
            setCurrentRow(null);
        }
    };

    useEffect(() => {
        if (data?.responsible_parties) {
            let s = data?.responsible_parties?.map((item) => {
                return {
                    value: item,
                    label: item,
                };
            });
            setOptions(s);
        }
    }, [data]);

    return (
        <div
            ref={refRiskManagement}
            className={`project__white-box ${show ? "" : "close"}`}
        >
            {deleteModal?.show && (
                <DeleteModal
                    id={deleteModal?.id}
                    action={deleteRiskManagement}
                    setDeleteModal={setDeleteModal}
                    cencelText={translations?.['main.main.actions.cancel']}
                    deleteText={translations?.['main.main.actions.delete']}
                    modalTitle={translations?.['main.main.headers.confirm_your_action']}
                    clarifyingText={translations?.['main.main.titles.you_are_going_to_delete_this_row']}
                />
            )}
            {infoModal && (
                <MoreInfoModal
                    title={translations?.['risk_management.risk_management.headers.risk_management']}
                    moreInfo={translations?.['risk_management.risk_management.more_info.risk_management']}
                    close={()=> setInfoModal(false)}
                />
            )}
            <div className="project__block-subtitles">
                <div className="d-f">
                    <div
                        onClick={() => setShow(!show)}
                        className="project__block-title data__title"
                    >
                        <span>{translations?.['risk_management.risk_management.headers.risk_management']}</span>
                    </div>
                    <span className="project__block-more">
            <span className="img">
              <img src="/assets/icons/info.svg" alt="info" />
            </span>
            <span onClick={() => setInfoModal(true)} className="text">
              {translations?.['risk_management.risk_management.titles.more_info']}
            </span>
          </span>
                </div>
                <div className="project__block__buttonsContainer">
                    {hasArchived && <button
                        onClick={() => setShowByActive(!showByActive)}
                        className="project__block-add-row">
                        <span> <img src="/assets/icons/blue-eye.svg" alt="plus"/></span>
                        <span>{showByActive ? translations?.['risk_management.risk_management.actions.show_all']
                            : translations?.['risk_management.risk_management.actions.show_active']}</span>
                    </button>}
                    {user?.permissions?.edit_project && (
                        <button
                            onClick={() => {
                                let randomId = Math.floor(Math.random() * 1000);
                                setCurrentRow(randomId);
                                scrollRef !== null && scrollRef.current.scrollIntoView();
                                if (!newItem) {
                                    setNewItem(true);
                                    setRiskData((old) => [
                                        ...old,
                                        {
                                            id: randomId,
                                            risk_reference: "",
                                            risk_description: "",
                                            impact_rating: null,
                                            likelihood_rating: null,
                                            mitigation_measure: "",
                                            risk_owner_id: null,
                                            current_status: "",
                                            closed: false,
                                            edit: true,
                                        },
                                    ]);
                                    setCurrentItem({
                                        id: randomId,
                                        risk_reference: "",
                                        risk_description: "",
                                        impact_rating: null,
                                        likelihood_rating: null,
                                        mitigation_measure: "",
                                        risk_owner_id: null,
                                        current_status: "",
                                        closed: false,
                                        edit: true,
                                    });
                                }
                            }}
                            className="project__block-add-row"
                        >
            <span>
              <img src="/assets/icons/blue-plus.svg" alt="plus" />
            </span>
                            <span>{translations?.['risk_management.risk_management.actions.add_row']}</span>
                        </button>
                    )}
                </div>
            </div>

            <div
                className={`project__block-table project-table`}
                style={{ paddingBottom: 50 }}
            >
                <div ref={scrollRef} className={`project-table__header`}>
                    {tableHeaders?.map((el, idx) => {
                        return (
                            <div
                                key={idx}
                                onMouseEnter={(event) => {
                                    setTableHeaders(
                                        tableHeaders.map((item) => ({
                                            ...item,
                                            showTooltip: el.tooltip_name === item.tooltip_name,
                                        }))
                                    );
                                }}
                                onMouseLeave={() => {
                                    setTableHeaders(
                                        tableHeaders.map((item) => ({
                                            ...item,
                                            showTooltip: false,
                                        }))
                                    );
                                }}
                                className={`project-table__th ${
                                    idx === 8 ? "risk_closed" : ""
                                } ${idx === 0 ? "risk-1" : ""} ${
                                    idx === 3 || idx === 2 ? "risk-3" : ""
                                }`}
                            >
                                {el?.showTooltip ? (
                                    <span
                                        className={
                                            el?.tooltip_text?.length > 20 ? "lengthy" : "not-lengthy"
                                        }
                                    >
                    {el?.tooltip_text}
                  </span>
                                ) : (
                                    ""
                                )}
                                {el?.tooltip_name}
                            </div>
                        );
                    })}
                </div>
                <div className="project-table__body">
                    {riskData?.map((item, index) => (
                        <div key={index}>
                            {index !== 0 && currentRow === item?.id && (
                                <div className={`project-table__header`} key={index}>
                                    {tableHeaders?.map((el, idx) => {
                                        return (
                                            <div
                                                key={idx}
                                                className={`project-table__th ${
                                                    idx === 8 ? "risk_closed" : ""
                                                } ${idx === 0 ? "risk-1" : ""} ${
                                                    idx === 3 || idx === 2 ? "risk-3" : ""
                                                }`}
                                            >
                                                {el?.showTooltip ? (
                                                    <span
                                                        className={
                                                            el?.tooltip_text?.length > 20
                                                                ? "lengthy"
                                                                : "not-lengthy"
                                                        }
                                                    >
                            {el?.tooltip_text}
                          </span>
                                                ) : (
                                                    ""
                                                )}
                                                {el?.tooltip_name}
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                            <form
                                className={`project-table__row ${
                                    item?.edit ? "edit" : currentItem ? "n" : ""
                                } ${
                                    Boolean(item.submitted) || !user?.permissions?.edit_project
                                        ? "submitted"
                                        : ""
                                }`}
                                id={item?.id}
                                key={item?.id}
                                onSubmit={(e) => submitHandler(e, item)}
                            >
                                <div className="project-table__td risk-1">
                                    <span>{item?.risk_reference}</span>
                                    <div className="project__block-input project-table__field">
                                        <input
                                            maxLength={190}
                                            defaultValue={item?.risk_reference}
                                            onChange={(e) => {
                                                setCurrentItem((old) => ({
                                                    ...old,
                                                    risk_reference: e.target.value,
                                                }));
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="project-table__td">
                                    <span>{item?.risk_description}</span>
                                    <div className="project__block-input project-table__field">
                    <textarea
                        className={`${
                            expanded ? "project__block-expandedInput" : ""
                        }`}
                        ref={expandedRef}
                        onFocus={() => setExpanded(true)}
                        onBlur={() => setExpanded(false)}
                        maxLength={190}
                        defaultValue={item?.risk_description}
                        onChange={(e) => {
                            setCurrentItem((old) => ({
                                ...old,
                                risk_description: e.target.value,
                            }));
                        }}
                    />
                                    </div>
                                </div>
                                <div className="project-table__td risk-3">
                  <span>
                    {item?.impact_rating_text ? item?.impact_rating_text : ""}
                  </span>
                                    <div className="project__block-input project-table__field select">
                                        <Select
                                            options={riskOptions}
                                            isSearchable={false}
                                            menuPortalTarget={document.body}
                                            styles={{
                                                ...customStyles,
                                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            }}
                                            value={currentItem?.impact_rating}
                                            onChange={(selected) => {
                                                setCurrentItem((old) => ({
                                                    ...old,
                                                    impact_rating: selected,
                                                }));
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="project-table__td risk-3">
                  <span>
                    {item?.likelihood_rating_text
                        ? item?.likelihood_rating_text
                        : ""}
                  </span>
                                    <div className="project__block-input project-table__field select">
                                        <Select
                                            options={likelyOptions}
                                            isSearchable={false}
                                            menuPortalTarget={document.body}
                                            styles={{
                                                ...customStyles,
                                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            }}
                                            value={currentItem?.likelihood_rating}
                                            onChange={(selected) => {
                                                setCurrentItem((old) => ({
                                                    ...old,
                                                    likelihood_rating: selected,
                                                }));
                                            }}
                                        />
                                    </div>
                                </div>
                                <div
                                    className={`project-table__td ${
                                        item?.risk_level_text === translations?.['risk_management.risk_management.dropdown.low']
                                            ? "project-table__td_green"
                                            : item?.risk_level_text === translations?.['risk_management.risk_management.dropdown.high']
                                                ? "project-table__td_red"
                                                : item?.risk_level_text === translations?.['risk_management.risk_management.dropdown.moderate']
                                                    ? "project-table__td_yellow"
                                                    : item?.risk_level_text === translations?.['risk_management.risk_management.dropdown.substantial']
                                                        ? "project-table__td_orange"
                                                        : ""
                                    }`}
                                >
                  <span>
                    {item?.risk_level_text ? item?.risk_level_text : ""}
                  </span>
                                    <div className="project__block-input project-table__field">
                                        {item?.risk_level_text ? item?.risk_level_text : ""}
                                    </div>
                                </div>
                                <div className="project-table__td">
                                    <span>{item?.mitigation_measure}</span>
                                    <div className="project__block-input project-table__field">
                    <textarea
                        maxLength={190}
                        type="text"
                        defaultValue={item?.mitigation_measure}
                        onChange={(e) => {
                            setCurrentItem((old) => ({
                                ...old,
                                mitigation_measure: e.target.value,
                            }));
                        }}
                    />
                                    </div>
                                </div>
                                <div className="project-table__td">
                  <span>
                    {item?.responsible_party?.label
                        ? item?.responsible_party?.label
                        : ""}
                  </span>
                                    <div className="project__block-input project-table__field select">
                                        <Select
                                            options={options}
                                            isSearchable={false}
                                            menuPortalTarget={document.body}
                                            styles={{
                                                ...customStyles,
                                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            }}
                                            value={currentItem?.responsible_party}
                                            onChange={(selected) => {
                                                setCurrentItem((old) => ({
                                                    ...old,
                                                    responsible_party: selected,
                                                }));
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="project-table__td">
                                    <span>{item?.current_status}</span>
                                    <div className="project__block-input project-table__field">
                    <textarea
                        className={`${
                            decrExpanded ? "project__block-expandedInput" : ""
                        }`}
                        ref={descrRef}
                        onFocus={() => setDecrExpanded(true)}
                        onBlur={() => setDecrExpanded(false)}
                        maxLength={190}
                        type="text"
                        defaultValue={item?.current_status}
                        onChange={(e) => {
                            setCurrentItem((old) => ({
                                ...old,
                                current_status: e.target.value,
                            }));
                        }}
                    />
                                    </div>
                                </div>
                                <div className="project-table__td risk_closed">
                                    <Switch
                                        value={item?.closed}
                                        id={index + 5412}
                                        disabled={item?.edit ? false : true}
                                        checked={item?.edit ? currentItem?.closed : item?.closed}
                                        handler={(value) => {
                                            setCurrentItem((old) => ({
                                                ...old,
                                                closed: value,
                                            }));
                                        }}
                                    />
                                </div>
                                <div className="project__block-btns">
                                    {currentItem ? (
                                        <div
                                            className="project__block-cancel"
                                            onClick={() => {
                                                setCurrentRow(null);
                                                let array = riskData?.map((item) => {
                                                    item.edit = false;
                                                    if (currentItem.closed && !item?.closed) {
                                                        item.closed = false;
                                                        currentItem.closed = false;
                                                    }
                                                    return item;
                                                });
                                                setCurrentItem(null);
                                                setRiskData(array);
                                                if (newItem) {
                                                    setNewItem(false);
                                                    let s = riskData?.filter(
                                                        (el, i) => i !== riskData.length - 1
                                                    );
                                                    setRiskData(s);
                                                }
                                            }}
                                        >
                                            <img src="/assets/icons/close-mark.svg" alt="close" />
                                            {translations?.['risk_management.risk_management.actions.cancel']}
                                        </div>
                                    ) : (
                                        <div
                                            className="project__block-delete"
                                            onClick={() => {
                                                // deleteRiskManagement(item?.id);
                                                setDeleteModal({ id: item?.id, show: true });
                                            }}
                                        >
                                            <img src="/assets/icons/trash-icon.svg" alt="arrow" />
                                            {translations?.['risk_management.risk_management.actions.delete']}
                                        </div>
                                    )}
                                    {currentItem ? (
                                        <button className="project__block-save">
                                            <img src="/assets/icons/save-arrow.svg" alt="arrow" />
                                            {translations?.['risk_management.risk_management.actions.save']}
                                        </button>
                                    ) : (
                                        <div
                                            onClick={() => {
                                                setCurrentRow(item?.id);
                                                setCurrentItem(item);
                                                setRiskData(() => {
                                                    let array = riskData?.map((elem) => {
                                                        if (elem?.id === item?.id) {
                                                            elem.edit = true;
                                                        }
                                                        return elem;
                                                    });
                                                    return array;
                                                });
                                            }}
                                            className="project__block-update"
                                        >
                                            {translations?.['risk_management.risk_management.actions.update']}
                                        </div>
                                    )}
                                </div>
                            </form>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
