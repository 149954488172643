import React, {useEffect, useRef, useState} from 'react';
import {useLazyGetLanguagesQuery, useLazyGetChangeLocalQuery} from "../../store/service/adbService";
import {toast} from "react-toastify";
import {errorHandle} from "../../global/errorHandle";
import {setLoader} from "../../store/slice/dashboard";
import {useDispatch} from "react-redux";

const LanguageSelect = ({activeLanguage}) => {
    const dispatch = useDispatch();
    const modalRef = useRef(null);
    const headerRef = useRef(null);
    const defaultImgAddress =  '../../assets/icons/default-img.svg'
    const [languages, setLanguages] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState(activeLanguage);
    const [isSelectLanguage, setIsSelectLanguage] = useState(false);

    const [getLanguages, {
        data: languagesData,
        isSuccess: languagesIsSuccess,
    }] = useLazyGetLanguagesQuery();

    const [getChangeLocal, {
        data: changeLocalData,
        isSuccess: changeLocalIsSuccess,
        isError: changeLocalIsError,
        error: changeLocalError,
    }] = useLazyGetChangeLocalQuery();

    useEffect(()=>{
        getLanguages(undefined, undefined);
    },[]);

    useEffect(()=>{
       if (changeLocalIsSuccess){
           dispatch(setLoader(true))
           window.location.reload();
       }
    },[changeLocalIsSuccess]);

    useEffect(()=>{
        if (changeLocalIsError){
            errorHandle(changeLocalError)
        }
    },[changeLocalIsError, changeLocalError]);

    useEffect(()=>{
        if (languagesIsSuccess){
            // setSelectedLanguage( languagesData?.data?.[0])
            setLanguages(languagesData?.data);
        }
    },[languagesIsSuccess, languagesData]);

    useEffect(()=>{
        setSelectedLanguage(activeLanguage);
    },[activeLanguage]);

    const closeModal = () => {
        setIsSelectLanguage(false);
    };

    const handleMouseLeave = (event) => {
        if (!modalRef.current || !modalRef.current.contains(event.target)) {
            closeModal()
        }
    };

    const changeLocaleLan = (language) => {
        getChangeLocal(language.code);
        localStorage.setItem("local_lan", language.code )
        setSelectedLanguage(language);
        setIsSelectLanguage(false);
    }

    useEffect(function () {
        document.addEventListener('mousedown', handleMouseLeave);
        document.addEventListener('touchstart', handleMouseLeave);

        return () => {
            document.removeEventListener('mousedown', handleMouseLeave);
            document.removeEventListener('touchstart', handleMouseLeave);

        };
    }, [headerRef]);

    return(

        <div className="sidebar__languageContainer" ref={headerRef}>
            <div className="sidebar__activeLanguageContainer">
                <img
                    src={selectedLanguage?.image ?? defaultImgAddress}
                    alt="language-logo"
                />
            </div>
            <div className='sidebar__languageSelectContainer' ref={modalRef} >
                <div className={`sidebar__languageSelectContainer__header ${isSelectLanguage && 'sidebar__languageSelectContainer__header_active'}`}
                     onClick={() => setIsSelectLanguage(!isSelectLanguage)}
                >
                    <img
                        src={selectedLanguage?.image}
                        alt="language-logo"
                    />
                    <h2>
                        {selectedLanguage?.language}
                    </h2>
                    <button
                        className={`sidebar__languageSelectContainer__selectBtn ${isSelectLanguage && 'rotate'}`}
                        onClick={() => setIsSelectLanguage(!isSelectLanguage)}
                    >
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M12.2932 5.33301C12.7124 5.33301 12.9455 5.81797 12.6836 6.14536L8.3906 11.5116C8.19043 11.7618 7.80989 11.7618 7.60973 11.5116L3.31671 6.14535C3.0548 5.81797 3.28789 5.33301 3.70714 5.33301L12.2932 5.33301Z" fill="#8A909D"/>
                        </svg>
                    </button>
                </div>
                 <div className={`sidebar__languageSelectContainer__modal fade-in ${isSelectLanguage && 'sidebar__languageSelectContainer__modal_visable'} `}>
                    <div className='sidebar__languageSelectContainer__languages'>
                        {languages.map(language =>
                            <div
                            key={language?.id}
                                className={`sidebar__languageSelectContainer__languages__item  
                               ${(language?.id === selectedLanguage?.id) && 'sidebar__languageSelectContainer__languages_bg'}`}
                                onClick={()=> changeLocaleLan(language)}
                            >
                                <img src={language?.image} alt={`${language?.language} logo`} />
                                <span>{language?.language}</span>
                            </div>
                        )}
                    </div>
                </div>

            </div>
        </div>

    )
}

export default LanguageSelect;