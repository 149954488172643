import React from "react";

export default function AuthWrapper({ children, className='' }) {
  return (
    <div className="auth__wrapper">
      <div className="auth__logo">
        <img style={{width: 100}} src="/image.png" alt="logo" />
      </div>

      <div className="auth__c">
        <div className={`auth__content fade-in ${className}`}>{children}</div>
      </div>
    </div>
  );
}
