import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
// PAGES
import Help from "./pages/Help";
import Login from "./pages/Login";
import Project from "./pages/Project";
import Settings from "./pages/Settings";
import AuditLogs from "./pages/AuditLogs";
import { UsersPage } from "./pages/Users";
import ForgotPin from "./pages/ForgotPin";
import { Page404 } from "./pages/Page404";
import { RegionPage } from "./pages/Region";
import ProjectList from "./pages/ProjectList";
import { CompanyPage } from "./pages/Company";
import { Dashboard } from "./pages/Dashboard";
import { SectorPage } from "./pages/Sector";
import { CountryPage } from "./pages/Country";
import ResetPassword from "./pages/ResetPassword";
import LarpDashboard from "./pages/LarpDashboard";
import ForgotPassword from "./pages/ForgotPassword";
import { ContractType } from "./pages/ContractType";
import Authentication from "./pages/Authentication";
import CreatePassword from "./pages/CreatePassword";
import ManageGlossary from "./pages/ManageGlossary";
import NotificationCenter from "./pages/Notification";
import Projects from "./pages/ManageGlossary/Projects";
import { EditProjectPage } from "./pages/EditProject";
import QuarterlyReports from "./pages/QuarterlyReports";
import EnterCode from "./pages/Authentication/EnterCode";
import { CreateProjectPage } from "./pages/CreateProject";
import ContractManagement from "./pages/ContractManagement";
import EditQuarterlyReport from "./pages/EditQuarterlyReport";
import ManagementDashboard from "./pages/ManagementDashboard";
import RememberDevice from "./pages/Authentication/RememberDevice";
import LarpForDoc from "./pages/LarpDashboard/PageForDoc/LarpForDoc";
import CreateQuarterlyReport from "./pages/CreateQuarterlyReport";
import CreateQReportStep1 from "./pages/CreateQuarterlyReport/CreateQReportStep1";
import CreateQReportStep2 from "./pages/CreateQuarterlyReport/CreateQReportStep2";
import CreateQReportStep3 from "./pages/CreateQuarterlyReport/CreateQReportStep3";
import QuarterProgressTable from "./pages/QuarterProgressTable";
import QuarterPaymentsTable from "./pages/QuarterPaymentsTable";
import QuarterProgressChart from "./pages/QuarterProgressTable/QuarterProgressChart";
import QuarterPaymentsChart from "./pages/QuarterPaymentsTable/QuarterPaymentsChart";
// Components
import Loader from "./components/Loader";
import Sidebar from "./components/Sidebar";
import Languages from "./components/Languages";
import NoInternet from "./components/NoInternet";
import { ChartPie } from "./components/ChartPie";
import { ChartRisk } from "./components/ChartRisk";
import { Reporting } from "./pages/Reporting";
import { OnlineView } from "./components/OnlineView";
import { VersusChart } from "./components/VersusChart";
import { CreateProjectStep1 } from "./components/CreateProject/Step1";
import { CreateProjectStep2 } from "./components/CreateProject/Step2";
import { CreateProjectStep3 } from "./components/CreateProject/Step3";
import { CreateProjectStep4 } from "./components/CreateProject/Step4";
import { EditProjectStep1 } from "./components/EditProject/Step1";
import { EditProjectStep2 } from "./components/EditProject/Step2";
import { EditProjectStep3 } from "./components/EditProject/Step3";
import { EditProjectStep4 } from "./components/EditProject/Step4";
import { ProgramProgressTable } from "./components/ProgramProgressTable";
import { ProgressChart } from "./components/ProgressChart/ProgressChart";
import { SecondaryPagesWrapper } from "./components/SecondaryPagesWrapper";
import { ProgramProgressScreenshot } from "./components/Screenshot/ProgramProgress";
// Store
import useOnlineStatus from "./Hooks/useOnlineStatus";
import { setUserData } from "./store/slice/dashboard";
import { useGetUserMutation } from "./store/service/adbService";
import Glossary from "./pages/ManageGlossary/Glossary";
import QuarterlyOnlineView from "./components/QuarterlyOnlineView/QuarterlyOnlineView";
import RouteWatcher from "./components/RouterWatcher";

const MIN_LIMIT_DAY = 24 * 3600 * 1000; // 24 hours
const MAX_LIMIT_DAYS = 24 * 3600 * 1000 * 30; // 30 days

const checkRememberDevices = () => {
  const rememberDeviceData = localStorage.getItem('rememberDevice');
  if (!rememberDeviceData) return;

  const { startedTimeStamp, isRemember } = JSON.parse(rememberDeviceData);
  const timeDifference = Date.now() - new Date(startedTimeStamp).getTime();

  if (isRemember && timeDifference > MAX_LIMIT_DAYS) {
    localStorage.clear();
  } else if (!isRemember && timeDifference > MIN_LIMIT_DAY) {
    localStorage.clear();
  }
};

function App() {
  const isOnline = useOnlineStatus();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const route = useLocation().pathname;
  const { user, loader } = useSelector(state => ({
    user: state.adbReducer.user,
    loader: state.adbReducer.loader,
  }));

  const [getUserHandler] = useGetUserMutation();

  useEffect(() => {
    if (localStorage.getItem("user_token")) {
      route === "/" && navigate("/project-list");
    } else {
      route === "/" && navigate("/login");
    }
  }, [route]);

  useEffect(() => {
    checkRememberDevices();
    localStorage.getItem("user_token") &&
      getUserHandler().then((res) => {
        localStorage.setItem("local_lan",res?.data?.data?.locale?.code )
        dispatch(setUserData(res?.data?.data));
      });
  }, []);

  return  isOnline ? (
      <>
          {loader && <Loader />}
          <ToastContainer />
          <RouteWatcher/>
          {localStorage.getItem("user_token") && <Sidebar />}
          <Routes>
            {/* Auth routes */}
            <Route path="/login" element={<Login />} />
            <Route path="/enter-code" element={<EnterCode />} />
            <Route path="/authentication" element={<Authentication />} />
            <Route path="/remember-device" element={<RememberDevice />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/forgot-pin" element={<ForgotPin />} />
            <Route path="/create-password/:id" element={<CreatePassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />

            {/* Main menu routes */}
            <Route path="/help" element={<Help />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/reporting" element={<Reporting />} />
            <Route path="/project-list" element={<ProjectList />} />
            <Route path="/manage-glossary" element={<ManageGlossary />} />
            <Route path="/quarterly-reports" element={<QuarterlyReports />} />
            <Route path="/notification-center" element={<NotificationCenter />} />
            <Route path="/contract-management" element={<ContractManagement />} />
            <Route path="/manage-glossary/:name" element={<Projects />}/>
            <Route path="/manage-glossary/:name/:subSection" element={<Glossary />}/>
            <Route path="/management-dashboard" element={<ManagementDashboard />} />

            {/* Project routes */}
            <Route path="/project/:id" element={<Project />} />
            <Route path="/dashboard/:id" element={<Dashboard />} />
            <Route path="/online-view/:id" element={<OnlineView />} />
            <Route path="/larp-dashboard/:id" element={<LarpDashboard />} />
            <Route path="/quarterly-online-view/:id" element={<QuarterlyOnlineView />} />
            <Route path="/edit-quarterly-report/:id" element={<EditQuarterlyReport />} />
            {/*<Route path="/create-report" element={<CreateReport />} />*/}
            <Route path="/pie-chart/:id/:locale" element={<ChartPie />} /> //ready
            <Route path="/risk-chart/:reportId/:local" element={<ChartRisk />} />   //ready
            <Route path="/progress-chart/:id/:locale" element={<ProgressChart />} /> //ready
            <Route path="/versus-chart/:id/:locale" element={<VersusChart />} />  //ready
            <Route path="/larp-doc/:id/:start/:end/:locale" element={<LarpForDoc />} />
            <Route path="/quarter-progress-chart/:id/:locale" element={<QuarterProgressChart />} />  // quarterly
            <Route path="/quarter-progress-table/:id/:date/:locale" element={<QuarterProgressTable />} />   // quarterly
            <Route path="/cumulative-payments-table/:id/:date/:locale" element={<QuarterPaymentsTable />} />   // quarterly
            <Route path="/quarter-payments-chart/:report_id/:locale" element={<QuarterPaymentsChart />} />   // quarterly
            <Route path="/program-progress-table/:id/:date/:locale" element={<ProgramProgressTable />}/> //ready
            <Route path="/program-progress-screenshot" element={<ProgramProgressScreenshot />}/> // probably this doesn't used'

            {/* Secondary pages */}
            <Route path="/secondary-pages" element={<SecondaryPagesWrapper />}>
              <Route path="region" element={<RegionPage />} />
              <Route path="country" element={<CountryPage />} />
              <Route path="contract-type" element={<ContractType />} />
              <Route path="sector" element={<SectorPage />} />
              <Route path="company" element={<CompanyPage />} />
              <Route path="users" element={<UsersPage />} />
              <Route path="audit-logs" element={<AuditLogs />} />
              <Route path="languages" element={<Languages />} />
            </Route>

            <Route path="/create-quarterly-report" element={<CreateQuarterlyReport />}>
              <Route path="step-1" element={<CreateQReportStep1 />}></Route>
              <Route path="step-2" element={<CreateQReportStep2 />}></Route>
              <Route path="step-3" element={<CreateQReportStep3 />}></Route>
            </Route>

            <Route path="/edit-quarterly-data/" element={<CreateQuarterlyReport />}>
              <Route path="step-1" element={<CreateQReportStep1 />}></Route>
              <Route path="step-2" element={<CreateQReportStep2 />}></Route>
              <Route path="step-3" element={<CreateQReportStep3 />}></Route>
            </Route>

            {/* Project creation and editing */}
            {user?.permissions?.new_project && (
                <Route path="/create-project" element={<CreateProjectPage />}>
                  <Route path="step-1" element={<CreateProjectStep1 />}></Route>
                  <Route path="step-2" element={<CreateProjectStep2 />}></Route>
                  <Route path="step-3" element={<CreateProjectStep3 />}></Route>
                  <Route path="step-4" element={<CreateProjectStep4 />}></Route>
                </Route>
            )}
            {user?.permissions?.edit_project ? (
                <Route path="/edit-project/:id" element={<EditProjectPage />}>
                  <Route path="step-1" element={<EditProjectStep1 />}></Route>
                  <Route path="step-2" element={<EditProjectStep2 />}></Route>
                  <Route path="step-3" element={<EditProjectStep3 />}></Route>
                  <Route path="step-4" element={<EditProjectStep4 />}></Route>
                </Route>
            ) : (
                <Route path="/edit-project/:id" element={<></>} />
            )}
            <Route path="/*" element={<Page404 />} />
            <Route path="/404" element={<Page404 />} />
            {/*<Route path="/generated-reports" element={<GeneratedReports />} />*/}
            {/* <Route path="organization-type" element={<OrganizationTypePage />} /> */}
            {/* <Route path="/contact-admin" element={<ContactAdmin />} /> */}
          </Routes>
        </>
      ) :(
          <NoInternet/>
  );
}

export default App;
