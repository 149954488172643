import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import Sections from "../Sections";
import {Switch} from "../../../UI/Switch";
import CopySettings from "./CopySettings";
import {errorHandle} from "../../../global/errorHandle";
import {setLoader} from "../../../store/slice/dashboard";
import {
    useLazyGetAllNotificationsQuery,
    useSetProjectToggleNotifyMutation
} from "../../../store/service/adbService";
import './index.scss';

const GeneralNotification = ({translations}) => {
    const dispatch = useDispatch();
    const [notificationsData, setNotificationsData] = useState([]);
    const [isOpenProjectSections, setIsOpenProjectSections] = useState({
        isOpen: false,
        key: null
    })

    const [getAllNotifications, {
        data: allNotificationsData,
        isSuccess: isSuccessAllNotificationsData,
        isLoading: isLoadingAllNotificationsData,
        isFetching: isFetchingAllNotificationsData,
    }] = useLazyGetAllNotificationsQuery();

    const [allProjectToggleNotify,{
        data : allProjectToggleNotifyData,
        isSuccess : isSuccessAllProjectToggleNotify,
        error: errorAllProjectToggleNotify,
        isError: isErrorAllProjectToggleNotify,
        isLoading: isLoadingAllProjectToggleNotify,
        isFetching: isFetchingAllProjectToggleNotify,
    } ] = useSetProjectToggleNotifyMutation();

    const allowNotifToAllSections = (projectId) => {
        allProjectToggleNotify(projectId)
    }

    const handleOpenProjectSections = (projectId) => {
        setIsOpenProjectSections((state) => {
            if (state.key === projectId) {
                return ({
                    isOpen: !state.isOpen,
                    key: projectId
                })
            } else {
                return ({
                    isOpen: true,
                    key: projectId
                })
            }
        });
        // setSectionsData(notificationsData.find(item => item.project_id === projectId));
    };
    const updateSections= () => {
        getAllNotifications();
    }

    useEffect(() => {
        getAllNotifications()
    }, []);

    useEffect(() => {
        if (isSuccessAllProjectToggleNotify){
            getAllNotifications();
        }
    }, [allProjectToggleNotifyData, isSuccessAllProjectToggleNotify]);

    useEffect(() => {
        if (allNotificationsData){
            setNotificationsData(allNotificationsData?.data)
        }
    }, [allNotificationsData, isSuccessAllNotificationsData]);

    useEffect(() => {
        if (isErrorAllProjectToggleNotify ){
           errorHandle(errorAllProjectToggleNotify)
        }
    }, [ errorAllProjectToggleNotify, isErrorAllProjectToggleNotify]);

    useEffect(() => {
        isLoadingAllNotificationsData || isFetchingAllNotificationsData
            || isLoadingAllProjectToggleNotify || isFetchingAllProjectToggleNotify
            ? dispatch(setLoader(true))
            : dispatch(setLoader(false));
    }, [isLoadingAllNotificationsData, isFetchingAllNotificationsData
    ]);


    return(
        <div className='nSettings'>
            <div className='nSettings__projects'>
                {notificationsData?.map(project =>(
                    <div className="nSettings__projects__project" key={project?.project_id}>
                        <div className={`nSettings__projects__project__header ${(isOpenProjectSections.isOpen &&  isOpenProjectSections.key === project.project_id) && 'nSettings__projects__project__header_active'}`}>
                            <div className="nSettings__projects__project__info">
                                <h3>
                                    {project.contract_title}
                                </h3>
                                <span>
                                    {project.contract_number}
                                </span>
                            </div>
                            <CopySettings
                                projects={notificationsData}
                                mainProjectId={project?.project_id}
                                updateSections={updateSections}
                                title={translations?.['project.index.actions.copy_settings_to_projects']}
                                modalTitle={translations?.['project.index.headers.copy_notification_settings']}
                                modalDescription={translations?.['project.index.titles.which_project_do_you_want_to_copy']}
                                searchPlaceholder={translations?.['project.index.actions.search']}
                                cancelButton={translations?.['project.index.actions.cancel']}
                                confirmButton={translations?.['project.index.actions.confirm']}
                                noProjectsDesc={translations?.['project.index.titles.no_projects_found_based_on_this_data']}
                            />
                            <div className='nSettings__projects__allowToNoifWrapper  nSettings__projects__allowToNoifWrapper__all'>
                                <span>
                                    {translations?.['project.index.actions.allow_notifications']}
                                </span>
                                <Switch
                                    id={project?.project_id}
                                    checked={project?.notifications_on}
                                    handler={()=>allowNotifToAllSections(project?.project_id)}
                                />
                            </div>
                            <button
                                type='button'
                                className={`nSettings__projects__selectDayBtn ${(isOpenProjectSections.isOpen &&  isOpenProjectSections.key === project.project_id) && 'nSettings__projects__selectDayBtn_rotate'}`}
                                onClick={() =>handleOpenProjectSections(project.project_id)}
                            >
                                <svg
                                    width="24" height="24" viewBox="0 0 24 24" fill="none" >
                                    <path d="M19 8.5L12 15.5L5 8.5" stroke="#2A3650" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </button>
                        </div>
                        { (isOpenProjectSections.isOpen &&  isOpenProjectSections.key === project.project_id) && <Sections projectId={project.project_id}  /> }
                    </div>
                ))}

            </div>

        </div>
    )
}

export default GeneralNotification;