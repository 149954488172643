import React, {useEffect, useState} from "react";
import ReactPaginate from "react-paginate";

import "./index.scss";

export const Paginate = ({ currentPage, setCurrentPage, pageCount }) => {
  const handlePageClick = (item) => {
    setCurrentPage(item?.selected + 1);
  };
  return (
    <>
    <div className="paginate">
        <ReactPaginate
            breakLabel="..."
            nextLabel={<img src="/assets/icons/paginate-arrow.svg" alt="next" />}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            currentPage={currentPage}
            forcePage={currentPage === pageCount ? pageCount-1 :  currentPage-1}
            selectedPageRel={null}
            previousLabel={<img src="/assets/icons/paginate-arrow.svg" alt="previous" />}
            containerClassName="paginate__container"
            renderOnZeroPageCount={null}
        />
      </div>
    </>
  );
};
