import isEmpty from "lodash/isEmpty";
import axios from "axios";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import React, {useCallback, useEffect, useState} from "react";
import AxelInput from "../../UI/AxelInput";
import AxelButton from "../../UI/AxelButton";
import AxelCheckbox from "../../UI/AxelCheckbox";
import {validationEmail} from "../../global/validate";
import AuthWrapper from "../../components/AuthWrapper";
import {setLoader, setUserData} from "../../store/slice/dashboard";
import {errorHandle} from "../../global/errorHandle";
import {setMfaToken} from "../../store/slice/mfAuth";
import {useLoginMutation, useLazyGetLoginPageQuery} from "../../store/service/adbService";
import "./index.scss";

export default function Login() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [pageData, setPageData] = useState({})
    const [state, setState] = useState({
        email: {
            value: "",
            id: "email",
            error: "Please enter correct email",
            errorShow: false,
        },
        password: {
            value: "",
            id: "password",
            error: "Please enter correct password",
            errorShow: false,
        },
    });
    const [getLoginPageData, { data: pageDate, isSuccess: pageDateIsSuccess, isLoginPageLoading}] =
        useLazyGetLoginPageQuery();
    const [, {isLoading}] =
        useLoginMutation();

    const inputHandler = useCallback((e) => {
        setState((old) => ({
            ...old,
            [e.target.id]: {...old[e.target.id], value: e.target.value},
        }));
    }, [])

    const submitHandler = (e) => {
        const mfaToken = localStorage.getItem("mfa_token")
        e.preventDefault();
        if (validationEmail(state.email.value) && state.password.value.length >= 4) {
            const headers = {
                "Content-Type": "application/json",
                Authorization: `Bearer ` + localStorage.getItem("user_token"),
            };
            dispatch(setLoader(true))
            axios.post(`${process.env.REACT_APP_API_URL}/login`, {
                email: state.email.value,
                password: state.password.value,
                mfa_token: mfaToken
            }, {headers})
                .then((res) => {
                    setTimeout(() => {
                        dispatch(setLoader(false))
                        dispatch(setUserData(res?.data?.data));
                        localStorage.setItem("local_lan",res?.data?.data?.locale?.code )
                    }, 500);
                    if (res.data.data?.redirect_mfa_settings){
                        localStorage.setItem("user_token", res?.data?.data?.token);
                        navigate("/settings");
                    }
                    else if(mfaToken == res.data.data?.use_mfa){
                        localStorage.setItem("user_token", res?.data?.data?.token);
                        navigate("/project-list");
                    }
                    else if  (res.data.data?.mfa_token){
                        localStorage.setItem("mfa_token", res?.data?.data?.mfa_token);
                        dispatch(setMfaToken({
                            mfa_token: res?.data?.data?.mfa_token,
                            mfa_method:true,
                        }));
                        navigate("/authentication");
                    }
                    else {
                        localStorage.setItem("user_token", res?.data?.data?.token);
                        navigate("/project-list");
                    }

                }).catch(e => {
                dispatch(setLoader(false))
                if (isEmpty(e?.response?.data.errors)){
                    toast.error(e?.response?.data?.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 8000,
                    });
                }
                else errorHandle(e?.response)

            });
        } else {
            setState((old) => ({
                ...old,
                email: {...old.email, errorShow: true},
                password: {...old.password, errorShow: true},
            }));
        }
    };

    useEffect(() => {
        if (localStorage.getItem("user_token")) {
            navigate("/project-list");
        }
        else {
            getLoginPageData();
        }

    }, []);

    useEffect(() => {
        if (pageDateIsSuccess){
            setPageData(pageDate?.data?.translations)
        }
    }, [pageDateIsSuccess, pageDate])

    useEffect(() => {
        isLoading || isLoginPageLoading ? dispatch(setLoader(true)) : dispatch(setLoader(false));
    }, [isLoading, isLoginPageLoading]);

    return (
        <>
            { pageData && Object.keys(pageData).length > 0 && (
                <AuthWrapper className="fade-in">
                    <h1 className="auth__title">{pageData['headers.login_header']} </h1>
                    <p className="auth__descr">
                        {pageData['titles.login_title']}
                    </p>
                    <form
                        action="#"
                        className="auth__form login__form"
                        onSubmit={submitHandler}
                    >
                        <AxelInput
                            placeholder= {pageData['titles.username_or_email']}
                            type="text"
                            value={state.email.value}
                            handler={inputHandler}
                            id={state.email.id}
                            error={state.email.error}
                            errorShow={state.email.errorShow}
                        />
                        <AxelInput
                            placeholder={pageData['titles.password']}
                            type="password"
                            value={state.password.value}
                            handler={inputHandler}
                            id={state.password.id}
                            error={state.password.error}
                            errorShow={state.password.errorShow}
                        />
                        <div className="login__center">
                            <div className="login__checkbox">
                                <AxelCheckbox label={pageData['actions.remember_me']} id="check"/>
                            </div>
                            <div className="login__link">
                                <Link to="/forgot-password" className="text-sm">
                                    {pageData['actions.forgot_password']}
                                </Link>
                            </div>
                        </div>
                        <AxelButton text={pageData['actions.login']} />
                    </form>
                    <Link className="privacy" to="/assets/files/privacy-policy.pdf" target="_blank">Privacy Policy</Link>
                </AuthWrapper>
            )
            }
        </>
    );
}
