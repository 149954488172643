import moment from "moment";
import isEmpty from "lodash/isEmpty";
import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Switch } from "../../../UI/Switch";
import { DeleteModal } from "../DeleteModal";
import {
    useCreateProjectMobilizationActivitiesMutation,
    useDeleteProjectMobilizationActivitiesMutation,
    useGetProjectMobilizationActivitiesQuery,
    useUpdateProjectMobilizationActivitiesMutation,
} from "../../../store/service/adbService";
import { setLoader } from "../../../store/slice/dashboard";
import CustomDatePicker from "../../CustomDatePicker";

export const MobilizationActivities = ({
                                           id,
                                           refMobilizationActivities,
                                           glossaryData,
                                           translations
                                       }) => {
    const dispatch = useDispatch();
    const localeLan = localStorage.getItem("local_lan" );
    moment.locale(localeLan);
    const [data, setData] = useState([]);
    const [showByActive, setShowByActive] = useState(true);
    const [hasArchived, setHasArchived] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const user = useSelector((state) => state?.adbReducer?.user);
    const [newItem, setNewItem] = useState(false);
    const [deleteModal, setDeleteModal] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);
    const [tableHeaders, setTableHeaders] = useState([]);
    const scrollRef = useRef(null);
    const expandedRef = useRef(null);
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                let array = data?.map((item) => {
                    item.edit = false;
                    return item;
                });
                setCurrentItem(null);
                setData(array);
                if (newItem) {
                    setNewItem(false);
                    let s = data?.filter((el, i) => i !== data.length - 1);
                    setData(s);
                }
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, [data]);

    const {
        data: projectReportRequirmentsData,
        isLoading: projectReportRequirmentsIsLoading,
    } = useGetProjectMobilizationActivitiesQuery(id);

    useEffect(() => {
        if (translations) {
            const headers = [
                {
                    tooltip_name: translations?.['general_start_up_activities.mobilization_activities.table_headers.contract_clause'],
                    tooltip_text: translations?.['general_start_up_activities.mobilization_activities.tooltips.contract_clause']
                },
                {
                    tooltip_name: translations?.['general_start_up_activities.mobilization_activities.table_headers.action_description'],
                    tooltip_text: translations?.['general_start_up_activities.mobilization_activities.tooltips.action_description']
                },
                {
                    tooltip_name: translations?.['general_start_up_activities.mobilization_activities.table_headers.reference'],
                    tooltip_text: translations?.['general_start_up_activities.mobilization_activities.tooltips.reference']
                },
                {
                    tooltip_name: translations?.['general_start_up_activities.mobilization_activities.table_headers.planned_date'],
                    tooltip_text: translations?.['general_start_up_activities.mobilization_activities.tooltips.planned_date']
                },
                {
                    tooltip_name: translations?.['general_start_up_activities.mobilization_activities.table_headers.actual_date'],
                    tooltip_text: translations?.['general_start_up_activities.mobilization_activities.tooltips.actual_date']
                },
                {
                    tooltip_name: translations?.['general_start_up_activities.mobilization_activities.table_headers.variance_days'],
                    tooltip_text: translations?.['general_start_up_activities.mobilization_activities.tooltips.variance_days']
                },
                {
                    tooltip_name: translations?.['general_start_up_activities.mobilization_activities.table_headers.completed'],
                    tooltip_text: translations?.['general_start_up_activities.mobilization_activities.tooltips.completed']
                },
            ]

            setTableHeaders(
                headers.map((item) => ({
                    ...item,
                    showTooltip: false,
                }))
            );
        }

    }, [translations]);
    useEffect(() => {
        if (projectReportRequirmentsData?.data) {
            let data = projectReportRequirmentsData?.data?.map((item) => ({
                id: item?.id,
                contract_clause: item?.contract_clause,
                action_description: item?.action_description,
                planned_date: item?.planned_date,
                actual_date: item?.actual_date ? item?.actual_date : null,
                completed: item?.completed,
                edit: false,
                variance: item?.variance,
                archived: item?.archived,
                submitted: item?.submitted,
                old_report: item?.old_report,
                submitted_date: item?.submitted_date,
            }));
            let isHaveArchived = isEmpty(data.find(item => item.archived===1));
            setHasArchived(!isHaveArchived);
            if (showByActive===true && !isHaveArchived){
                const filteredData =  data.filter(item => item.archived ===0);
                setData(filteredData);
            } else setData(data);
        }
    }, [projectReportRequirmentsData]);

    const [
        createReportRequirments,
        {
            isLoading: createReportRequirmentsIsLoading,
        },
    ] = useCreateProjectMobilizationActivitiesMutation();

    const [
        updateReportRequirments,
        {
            isLoading: updateReportRequirmentsIsLoading,
        },
    ] = useUpdateProjectMobilizationActivitiesMutation();

    const [
        deleteReportRequirments,
        {
            isLoading: deleteReportRequirmentsIsLoading,
        },
    ] = useDeleteProjectMobilizationActivitiesMutation();

    useEffect(() => {
        const d = () => {
            setData((old) => {
                let data = old?.map((item) => ({ ...item, edit: false }));
                return data;
            });
            dispatch(setLoader(false));
        };

        deleteReportRequirmentsIsLoading ||
        updateReportRequirmentsIsLoading ||
        createReportRequirmentsIsLoading ||
        projectReportRequirmentsIsLoading
            ? dispatch(setLoader(true))
            : d();
    }, [
        deleteReportRequirmentsIsLoading,
        updateReportRequirmentsIsLoading,
        createReportRequirmentsIsLoading,
        projectReportRequirmentsIsLoading,
    ]);

    const setterData = (input) => {
        const body = {
            project_id: id,
            contract_clause: input?.contract_clause,
            action_description: input?.action_description,
            planned_date: moment(input?.planned_date).format("YYYY-MM-DD"),
            actual_date: moment(input?.actual_date).format("YYYY-MM-DD"),
        };

        let s = [];

        if (currentItem.completed) {
            if (body?.actual_date === "Invalid date") {
                s.push("false");
            }
        } else {
            if (body?.actual_date === "Invalid date") {
                delete body.actual_date;
            }
        }

        for (let key in body) {
            if (!body[key] || body[key] === "Invalid date" || body[key] === "") {
                toast.error("Please enter " + key?.split("_")?.join(" "), {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
                s.push("false");
            }
        }

        if (s.length === 0) {
            return body;
        } else {
            return null;
        }
    };

    const submitHandler = (e, item) => {
        e.preventDefault();
        if (newItem) {
            const dataCreated = setterData(currentItem);
            if (!dataCreated) {
                return;
            }
            createReportRequirments({
                ...dataCreated,
                completed: currentItem?.completed,
            });
            setCurrentItem(null);
            setNewItem(false);
            setCurrentRow(null);
        } else {
            const dataUpdated = setterData(currentItem);
            if (!dataUpdated) {
                return;
            }
            updateReportRequirments({
                id: item?.id,
                body: {
                    ...dataUpdated,
                    completed: currentItem?.completed,
                },
            });
            setCurrentItem(null);
            setNewItem(false);
            setCurrentRow(null);
        }
    };

    return (
        <div className="project__white-box">
            {deleteModal?.show && (
                <DeleteModal
                    id={deleteModal?.id}
                    action={deleteReportRequirments}
                    setDeleteModal={setDeleteModal}
                    cencelText={translations?.['main.main.actions.cancel']}
                    deleteText={translations?.['main.main.actions.delete']}
                    modalTitle={translations?.['main.main.headers.confirm_your_action']}
                    clarifyingText={translations?.['main.main.titles.you_are_going_to_delete_this_row']}
                />
            )}
            <div
                ref={refMobilizationActivities}
                id={"mobilization_activities"}
                className="project__block-subtitles"
            >
                <div className="mobilization-title">{translations?.['general_start_up_activities.mobilization_activities.headers.mobilization_activities']}</div>
                <div className="project__block__buttonsContainer">
                    {hasArchived && <button
                        onClick={() => setShowByActive(!showByActive)}
                        className="project__block-add-row">
                        <span> <img src="/assets/icons/blue-eye.svg" alt="plus"/></span>
                        <span>{showByActive ? translations?.['general_start_up_activities.mobilization_activities.actions.show_all']
                            : translations?.['general_start_up_activities.mobilization_activities.actions.show_active']}</span>
                    </button>}
                    {user?.permissions?.edit_project && (
                        <button
                            onClick={() => {
                                let randomId = Math.floor(Math.random() * 1000);
                                scrollRef?.current?.scrollIntoView();
                                setCurrentRow(randomId);
                                if (!newItem) {
                                    setNewItem(true);
                                    setData((old) => [
                                        ...old,
                                        {
                                            id: randomId,
                                            contract_clause: "",
                                            action_description: "",
                                            reference: "",
                                            planned_date: false,
                                            actual_date: null,
                                            completed: false,
                                            edit: true,
                                        },
                                    ]);
                                    setCurrentItem({
                                        id: randomId,
                                        contract_clause: "",
                                        action_description: "",
                                        reference: "",
                                        planned_date: false,
                                        actual_date: null,
                                        completed: false,
                                        edit: true,
                                    });
                                }
                            }}
                            className="project__block-add-row"
                        >
              <span>
                <img src="/assets/icons/blue-plus.svg" alt="plus" />
              </span>
                            <span>{translations?.['general_start_up_activities.mobilization_activities.actions.add_row']}</span>
                        </button>
                    )}
                </div>
            </div>

            <div
                className={`project__block-table project-table`}
                style={{ paddingBottom: 50 }}
            >
                <div ref={scrollRef} className={`project-table__header`}>
                    {tableHeaders?.map((el, idx) => {
                        return (
                            <div
                                key={idx}
                                onMouseEnter={() => {
                                    setTableHeaders(
                                        tableHeaders.map((item) => ({
                                            ...item,
                                            showTooltip: el.tooltip_name === item.tooltip_name
                                        }))
                                    );
                                }}
                                onMouseLeave={() => {
                                    setTableHeaders(
                                        tableHeaders.map((item) => ({
                                            ...item,
                                            showTooltip: false,
                                        }))
                                    );
                                }}
                                className="project-table__th"
                            >
                                {el?.showTooltip ? (
                                    <span
                                        className={
                                            el?.tooltip_text?.length > 20 ? "lengthy" : "not-lengthy"
                                        }
                                    >
                    {el?.tooltip_text}
                  </span>
                                ) : (
                                    ""
                                )}
                                {el?.tooltip_name}
                            </div>
                        );
                    })}
                </div>
                <div className="project-table__body">
                    {data?.map((item, index) => (
                        <div key={index}>
                            {index !== 0 && currentRow === item?.id && (
                                <div className={`project-table__header`} key={index}>
                                    {tableHeaders?.map((el, idx) => {
                                        return (
                                            <div key={idx} className="project-table__th">
                                                {el?.showTooltip ? (
                                                    <span
                                                        className={
                                                            el?.tooltip_text?.length > 20
                                                                ? "lengthy"
                                                                : "not-lengthy"
                                                        }
                                                    >
                            {el?.tooltip_text}
                          </span>
                                                ) : (
                                                    ""
                                                )}
                                                {el?.tooltip_name}
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                            <form
                                className={`project-table__row ${
                                    item?.edit ? "edit" : currentItem ? "n" : ""
                                } ${
                                    Boolean(item.submitted) || !user?.permissions?.edit_project
                                        ? "submitted"
                                        : ""
                                }`}
                                id={item?.id}
                                key={item?.id}
                                onSubmit={(e) => submitHandler(e, item)}
                            >
                                <div className="project-table__td">
                                    <span>{item?.contract_clause}</span>
                                    <div className="project__block-input project-table__field">
                                        <input
                                            maxLength={190}
                                            type="text"
                                            defaultValue={item?.contract_clause}
                                            onChange={(e) => {
                                                setCurrentItem((old) => ({
                                                    ...old,
                                                    contract_clause: e.target.value,
                                                }));
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="project-table__td">
                                    <span>{item?.action_description}</span>
                                    <div className="project__block-input project-table__field">
                    <textarea
                        className={`${
                            expanded ? "project__block-expandedInput" : ""
                        }`}
                        ref={expandedRef}
                        onFocus={() => setExpanded(true)}
                        onBlur={() => setExpanded(false)}
                        maxLength={190}
                        defaultValue={item?.action_description}
                        onChange={(e) => {
                            setCurrentItem((old) => ({
                                ...old,
                                action_description: e.target.value,
                            }));
                        }}
                    />
                                    </div>
                                </div>
                                <div className="project-table__td">
                                    <span>{index + 1}</span>
                                    <div className="project__block-input project-table__field">
                    <textarea
                        maxLength={190}
                        disabled
                        value={index + 1}
                        onChange={(e) => {
                            setCurrentItem((old) => ({
                                ...old,
                                reference: e.target.value,
                            }));
                        }}
                    />
                                    </div>
                                </div>
                                <div className="project-table__td">
                  <span>
                    {moment(item?.planned_date).format("DD/MM/YYYY") ===
                    "Invalid date" ||
                    !moment(item?.planned_date).format("DD/MM/YYYY")
                        ? ""
                        : moment(item?.planned_date).format("DD/MM/YYYY")}
                  </span>
                                    <div className="project__block-item-calendar project-table__field">
                                        <CustomDatePicker
                                            portalId="root-portal"
                                            autoComplete="off"
                                            id="approved_date"
                                            placeholderText={translations?.['main.main.placeholder.dd_mm_yyyy']}
                                            dateFormat="dd/MM/yyyy"
                                            selected={
                                                currentItem?.planned_date
                                                    ? moment(currentItem?.planned_date).toDate()
                                                    : ""
                                            }
                                            onChange={(date) => {
                                                setCurrentItem((old) => ({
                                                    ...old,
                                                    planned_date: date,
                                                }));
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="project-table__td">
                  <span>
                    {moment(item?.actual_date).format("DD/MM/YYYY") ===
                    "Invalid date" ||
                    !moment(item?.actual_date).format("DD/MM/YYYY")
                        ? ""
                        : moment(item?.actual_date).format("DD/MM/YYYY")}
                  </span>
                                    <div
                                        className={`project__block-item-calendar project-table__field ${
                                            currentItem?.completed
                                                ? ""
                                                : "project__actualDate-disabled"
                                        }`}
                                    >
                                        <CustomDatePicker
                                            portalId="root-portal"
                                            autoComplete="off"
                                            id="approved_date"
                                            placeholderText={translations?.['main.main.placeholder.dd_mm_yyyy']}
                                            dateFormat="dd/MM/yyyy"
                                            disabled={!currentItem?.completed}
                                            selected={
                                                currentItem?.actual_date
                                                    ? moment(currentItem?.actual_date).toDate()
                                                    : ""
                                            }
                                            onChange={(date) => {
                                                setCurrentItem((old) => ({
                                                    ...old,
                                                    actual_date: date,
                                                }));
                                            }}
                                        />
                                    </div>
                                </div>
                                <div
                                    className={`project-table__td ${item?.completed && item?.variance < 0 && "project-table__td_red"} ${item?.completed && item?.variance >= 0 && "project-table__td_green"}`}
                                >
                                    <span>{item?.variance}</span>
                                </div>
                                <div className="project-table__td">
                                    <Switch
                                        value={item?.completed}
                                        id={index + 600}
                                        disabled={item?.edit ? false : true}
                                        checked={
                                            item?.edit ? currentItem?.completed : item?.completed
                                        }
                                        handler={(value) => {
                                            setCurrentItem((old) => ({
                                                ...old,
                                                completed: value,
                                                actual_date: value ? currentItem?.actual_date : null,
                                            }));
                                        }}
                                    />
                                </div>
                                <div className="project__block-btns">
                                    {currentItem ? (
                                        <div
                                            className="project__block-cancel"
                                            onClick={() => {
                                                setCurrentRow(null);
                                                let array = data?.map((item) => {
                                                    item.edit = false;
                                                    if (currentItem.completed && !item?.completed) {
                                                        item.completed = false;
                                                        currentItem.completed = false;
                                                    }
                                                    return item;
                                                });
                                                setCurrentItem(null);
                                                setData(array);
                                                if (newItem) {
                                                    setNewItem(false);
                                                    let s = data?.filter(
                                                        (el, i) => i !== data.length - 1
                                                    );
                                                    setData(s);
                                                }
                                            }}
                                        >
                                            <img src="/assets/icons/close-mark.svg" alt="close" />
                                            {translations?.['general_start_up_activities.mobilization_activities.actions.cancel']}
                                        </div>
                                    ) : (
                                        <div
                                            className="project__block-delete"
                                            onClick={() => {
                                                setDeleteModal({ id: item?.id, show: true });
                                            }}
                                        >
                                            <img src="/assets/icons/trash-icon.svg" alt="arrow" />
                                            {translations?.['general_start_up_activities.mobilization_activities.actions.delete']}
                                        </div>
                                    )}
                                    {currentItem ? (
                                        <button className="project__block-save">
                                            <img src="/assets/icons/save-arrow.svg" alt="arrow" />
                                            {translations?.['general_start_up_activities.mobilization_activities.actions.save']}
                                        </button>
                                    ) : (
                                        <div
                                            onClick={() => {
                                                setCurrentRow(item?.id);
                                                setCurrentItem(item);
                                                setData(() => {
                                                    let array = data?.map((elem) => {
                                                        if (elem?.id === item?.id) {
                                                            elem.edit = true;
                                                        }
                                                        return elem;
                                                    });
                                                    return array;
                                                });
                                            }}
                                            className="project__block-update"
                                        >
                                            {translations?.['general_start_up_activities.mobilization_activities.actions.update']}
                                        </div>
                                    )}
                                </div>
                            </form>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
