import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetAllSectionsMutation } from "../../../store/service/adbService";
import { setLoader } from "../../../store/slice/dashboard";

const useGetAllSectionsHook = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState(null);
  const [allChecked, setAllChecked] = useState(false);
  const [checkeds, setCheckeds] = useState(null);
  const [getSections] = useGetAllSectionsMutation();
  const navigate = useNavigate();

  const objectToArray = (res) => {
    const data = res;
    let formatted = [];
    for (let key in data) {
      formatted = [
        ...formatted,
        {
          id: key,
          label: data[key],
          active: false,
        },
      ];
    }
    setState(formatted);
  };

  const get = () => {
    dispatch(setLoader(true));
    getSections()
      .unwrap()
      .then((res) => {
        dispatch(setLoader(false));
        objectToArray(res?.data);
      })
      .catch((err) => {
        dispatch(setLoader(false));
        // toast.error(err?.data?.message);
        errorsShow(err?.data?.errors);
        if (err?.originalStatus === 401) {
          navigate("/login");
        }
      });
  };

  const setSection = (event) => {
    let data = state;
    data = data?.map((item) => {
      if (item?.id === event?.target?.id) {
        item.active = !item.active;
      }
      return item;
    });
    setState(data);
  };

  const setAllSection = (event) => {
    let data = state;
    data = data?.map((item) => {
      item.active = event?.target?.checked ? true : false;
      return item;
    });
    setState(data);
    setAllChecked(event.target.checked ? true : false);
  };

  const checkAllChecked = () => {
    const data = state?.filter((item) => !item.active);
    return data?.length > 0 ? false : true;
  };

  const allCheckeds = () => {
    const data = state?.filter((item) => item?.active);
    return data;
  };

  const errorsShow = (errors) => {
    for (let key in errors) {
      errors[key]?.forEach((item) => {
        toast.error(item);
      });
    }
  };

  const downloadCmp = () => {
    dispatch(setLoader(true));

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ` + localStorage.getItem("user_token"),
    };

    const stringParams = state.reduce((acc, item, index) => {
      const param = `${item.id}=${item.active ? '1' : '0'}`;
      return acc + (index === 0 ? param : `&${param}`);
    }, '?');

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/cmp-templates/generate${stringParams}`,
        {
          headers: headers,
          responseType: "blob",
        }
      )
      .then(({ data: blob }) => {
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.download = `CMP.docx`;
        link.click();
        dispatch(setLoader(false));
      })
      .catch((e) => {
        errorsShow(e?.data?.errors);
        dispatch(setLoader(false));
      });
  };

  useEffect(() => {
    if (Array.isArray(state)) {
      const status = checkAllChecked();
      setAllChecked(status);
      setCheckeds(allCheckeds());
    }
  }, [state]);

  return {
    get,
    setSection,
    setAllSection,
    checkboxs: state,
    allChecked,
    checkeds,
    downloadCmp,
  };
};

export default useGetAllSectionsHook;
