import {useEffect} from 'react';
import axios from 'axios';
import {useDispatch} from 'react-redux';
import {setLoader} from '../../store/slice/dashboard';
import {toast} from 'react-toastify';
import {setOnlineView, setSectionsTitle, setSidebar, setSubmittedReports} from "../../store/slice/quarterly.slice";
import moment from "moment";
import {useNavigate} from "react-router-dom";

const localeLan = localStorage.getItem("local_lan" );


const headers = {
	'Content-Type': 'multipart/form-data', Authorization: `Bearer ` + localStorage?.getItem('user_token'),
	"AppLocale": `${localeLan ? localeLan : 'en'}`
};

const fetchSubmittedReports = async (dispatch, report_id) => {
	dispatch(setLoader(true));
	try {
		const res = await axios.get(`${process.env.REACT_APP_API_URL}/quarterly-report/submitted-reports/${report_id}`, {headers});
		let data = res.data.data?.map((el) => ({
			value: el?.id,
			label: moment(el?.date)?.format(
				"DD/MM/yyyy"
			)
		}))
		dispatch(setSubmittedReports(data))
	} catch (err) {
		toast.error(err?.response?.data?.message, {
			position: toast.POSITION.TOP_RIGHT, autoClose: 3000,
		});
		
	} finally {
		dispatch(setLoader(false));
	}
};

export const fetchQuarterlyDetails = async (dispatch, id, navigate, headers) => {
	dispatch(setLoader(true));
	try {
		const res = await axios.get(`${process.env.REACT_APP_API_URL}/quarterly-report/details/${id}`, {headers});
		dispatch(setOnlineView(res.data.data.details));
		const data_side_bar = res.data.data.side_bar;
		let array = [
			{
				key: 'overview',
				value: localStorage.getItem('local_lan') === 'ru' ? 'Обзор' : 'Overview'
			}
		]
		for (let key in data_side_bar) {
			array = [...array, {key, value: data_side_bar[key]}]
		}
		dispatch(setSidebar(array));
		fetchSubmittedReports(dispatch, res?.data?.data?.details?.quarterly_report_id);
	} catch (err) {
		navigate('/quarterly-reports')
		toast.error(err?.response?.data?.message, {
			position: toast.POSITION.TOP_RIGHT,
			autoClose: 3000,
		});
		throw err;
	} finally {
		dispatch(setLoader(false));
	}
};

export const fetchQuarterlyDetailsWithDate = async (dispatch, id, date, navigate, headers) => {
	dispatch(setLoader(true));
	try {
		const res = await axios.get(`${process.env.REACT_APP_API_URL}/quarterly-report/details/${id}?date=${date}`, {headers});
		dispatch(setOnlineView(res.data.data.details));
		const data_side_bar = res.data.data.side_bar;
		let array = [
			{
				key: 'overview',
				value: localStorage.getItem('local_lan') === 'ru' ? 'Обзор' : 'Overview'
			}
		]
		for (let key in data_side_bar) {
			array = [...array, {key, value: data_side_bar[key]}]
		}
		dispatch(setSidebar(array));
		fetchSubmittedReports(dispatch, res?.data?.data?.details?.quarterly_report_id);
	} catch (err) {
		navigate('/quarterly-reports')
		toast.error(err?.response?.data?.message, {
			position: toast.POSITION.TOP_RIGHT,
			autoClose: 3000,
		});
		throw err;
	} finally {
		dispatch(setLoader(false));
	}
};

export const useGetQuarterlyDetails = (id, date) => {
	// moment(date, "DD MMM, YYYY").format('YYYY-MM-DD')
	const dispatch = useDispatch();
	const navigate = useNavigate()
	const headers = {
		'Content-Type': 'multipart/form-data', Authorization: `Bearer ` + localStorage?.getItem('user_token'),
		"AppLocale": `${localeLan ? localeLan : 'en'}`
	};
	useEffect(() => {
		const fetchDetails = async () => {
			try {
				if (date) {
					await fetchQuarterlyDetailsWithDate(dispatch, id, date, navigate, headers);
				} else {
					await fetchQuarterlyDetails(dispatch, id, navigate, headers);
				}
			} catch (err) {
				// Ошибка обрабатывается в функции API
			}
		};
		
		if (id) {
			fetchDetails();
		}
	}, [dispatch, id, date]);
};

export const useGetQuarterlySectionsTitle = () => {
	const dispatch = useDispatch();
	
	useEffect(() => {
		const fetchDetails = async () => {
			dispatch(setLoader(true));
			try {
				const res = await axios.get(`${process.env.REACT_APP_API_URL}/quarterly-report/sections`, {headers});
				let data = res.data.data;
				let array = []
				for (let key in data) {
					array = [...array, {key, value: data[key]}]
				}
				dispatch(setSectionsTitle(array))
			} catch (err) {
				toast.error(err?.response?.data?.message, {
					position: toast.POSITION.TOP_RIGHT, autoClose: 3000,
				});
				
			} finally {
				dispatch(setLoader(false));
			}
		};
		
		fetchDetails();
	}, [dispatch]);
};

// TODO: доделать generate document
export const useGenerateReport = (report_id, data) => {
	const dispatch = useDispatch();
	const fetchDetails = async () => {
		dispatch(setLoader(true))
		axios
				.post(`${process.env.REACT_APP_API_URL}/quarterly-report/generate-document/${report_id}`, null, {
					headers: headers, responseType: "blob",
				})
				.then(({data: blob}) => {
					const link = document.createElement("a");
					const url = URL.createObjectURL(blob);
					link.href = url;
					link.download = `report_${moment(data?.report_date)?.format("DD-MM-YYYY")}.docx`;
					link.click();
					dispatch(setLoader(false));
				})
				.catch((e) => {
					toast.error(e?.data?.message ? e?.data?.message : "Something went wrong.", {
						position: toast.POSITION.TOP_RIGHT, autoClose: 3000,
					});
					dispatch(setLoader(false));
				});
	};
	
	return fetchDetails;
};
