import moment from "moment/moment";
import React from 'react';
import Collapse from "../../../components/Collapse";

export const getContractDetail = (detail, key, unit)=>{
    const localeLan = localStorage.getItem("local_lan" );
    moment.locale(localeLan);
    const dayKeys = ['contracts_defects_liability_period', 'original_contract_time_for_completion', 'contract_time_for_completion']
    const value = detail[key]

    if (dayKeys.some(dayKey=> dayKey === key)){
        return `${value} ${unit ? unit : ''}`
    }

    if (typeof value === "number"){
        return `$ ${String(value).replace(/(\d)(?=(\d{3})+(\D|$))/g, "$1,")}`
    }

    if (!!value?.name){
        return value?.name
    }

    if (!!value?.long_name){
        return value?.long_name
    }

    if (value && key === "contract_scope"){
        return value
    }

    if (`${value}`?.split('').some((string)=>string === '-')){
        return moment(value).format("MMMM DD, YYYY")
    }

    if (value.id === null){
        return `
            `
    }

    return `${!!value ? value : '-'}`
}

const ContractKeyData = ({currentRef, contractDetails, translations}) => {

    const customTitles = {
        contract_type: translations?.['contract_key_data.contract_key_data.titles.contract_type'],
        sector: translations?.['contract_key_data.contract_key_data.titles.sector'],
        contract_scope: translations?.['contract_key_data.contract_key_data.titles.contract_scope'],
        country: translations?.['contract_key_data.contract_key_data.titles.country'],
        original_contract_amount: translations?.['contract_key_data.contract_key_data.titles.original_contract_amount'],
        contract_date_issuance_letter_acceptance: translations?.['contract_key_data.contract_key_data.titles.contract_date_of_issuance_of_letter_of_acceptance'],
        contract_signed_date: translations?.['contract_key_data.contract_key_data.titles.contract_signing_date'],
        contract_commencement_effective_date: translations?.['contract_key_data.contract_key_data.titles.contract_commencement_effective_date'],
        contract_time_for_completion: translations?.['contract_key_data.contract_key_data.titles.contract_time_for_completion'],
        original_contract_completion_date: translations?.['contract_key_data.contract_key_data.titles.original_contract_completion_date'],
        current_contract_completion_date: translations?.['project_monitoring.project_monitoring.titles.current_contract_completion_date'],
        adb: translations?.['contract_key_data.contract_key_data.titles.adb'],
        executing_agency: translations?.['contract_key_data.contract_key_data.titles.executing_agency'],
        implementing_agency: translations?.['contract_key_data.contract_key_data.titles.implementing_agency'],
        contractor: translations?.['contract_key_data.contract_key_data.titles.contractor'],
        supplier: translations?.['contract_key_data.contract_key_data.titles.supplier'],
        design_consultant: translations?.['contract_key_data.contract_key_data.titles.design_consultant'],
        project_manager: translations?.['contract_key_data.contract_key_data.titles.project_manager'],
        engineer: translations?.['contract_key_data.contract_key_data.titles.engineer'],
        consultant: translations?.['contract_key_data.contract_key_data.titles.consultant_other'],
        contracts_defects_liability_period: translations?.['contract_key_data.contract_key_data.titles.contracts_defects_liability_period'],
        contract_loan_effective_date: translations?.['contract_key_data.contract_key_data.titles.contract_adb_loan_effective_date'],
        contract_loan_closing_date: translations?.['contract_key_data.contract_key_data.titles.contract_adb_loan_closing_date']
    }

    const array = Object.keys(contractDetails || {});
    const midpoint = Math.ceil(array.length / 2);
    const firstHalf = array.slice(0, midpoint - 1);
    const secondHalf = array.slice(-midpoint);

    return(
        <Collapse
            title={translations?.['contract_key_data.contract_key_data.headers.contract_key_data']}
            currentRef={currentRef}
            hasMoreInfo={false}
        >
            <div className="project__contract-tables">
                <div className="project__contract-table">
                    {
                        firstHalf.map((key, index)=> {
                            if ( contractDetails[key]?.not_applicable === 1 ){
                                return(< span key={index}></span>)
                            }

                            if (contractDetails[key]?.not_applicable === 0){
                                return (<div className="project__contract-table-row" key={index}>
                                    <label htmlFor="">{customTitles[key] || ''}</label>
                                    <p>{getContractDetail(contractDetails, key, translations?.['contract_key_data.contract_key_data.titles.day_s'])}</p>
                                </div>)
                            }

                            if (contractDetails[key]?.not_applicable === undefined){
                                return (<div className="project__contract-table-row" key={index}>
                                    <label htmlFor="">{customTitles[key] || ''}</label>
                                    <p>{getContractDetail(contractDetails, key, translations?.['contract_key_data.contract_key_data.titles.day_s'])}</p>
                                </div>)
                            }
                            return <></>
                        })
                    }
                </div>
                <div className="project__contract-table">
                    {
                        secondHalf.map((key, index)=> {
                            if ( contractDetails[key]?.not_applicable === 1 ){
                                return(<span key={index}></span>)
                            }

                            if (contractDetails[key]?.not_applicable === 0){
                                return (<div className="project__contract-table-row" key={index}>
                                    <label htmlFor="">{customTitles[key] || ''}</label>
                                    <p>{getContractDetail(contractDetails, key, translations?.['contract_key_data.contract_key_data.titles.day_s'])}</p>
                                </div>)
                            }

                            if (contractDetails[key]?.not_applicable === undefined){
                                return (<div className="project__contract-table-row" key={index}>
                                    <label htmlFor="">{customTitles[key] || ''}</label>
                                    <p>{getContractDetail(contractDetails, key, translations?.['contract_key_data.contract_key_data.titles.day_s'])}</p>
                                </div>)
                            }
                            return <></>
                        })
                    }

                </div>
            </div>
        </Collapse>)
};

export default ContractKeyData;