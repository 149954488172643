import React from "react";
import "./index.scss";

export const Switch = ({ id, disabled = false, handler, value, checked }) => {
  return (
    <div className="switch">
      <input
        type="checkbox"
        id={`${id}-switch`}
        onChange={(e) => handler(e.target.checked)}
        defaultChecked={value}
        checked={checked}
        disabled={disabled}
      />
      <label htmlFor={`${id}-switch`}>
        <div></div>
      </label>
    </div>
  );
};
