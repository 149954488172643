import React, {useState} from 'react';

export default function MoreInfoModal ({moreInfo, title, text, close}) {
    const [language, setLanguage] = useState('English')
    return (
        <div className="infoModal">
            <div className="infoModal__content">
                <div className="infoModal__content-top">
                    <p className="infoModal__content-title">{title}</p>
                    <img src="/assets/icons/close.svg" alt="exit" onClick={close}/>
                </div>

                <div className="infoModal__tabs">
                    { moreInfo && Object.keys(moreInfo)?.map(key =>
                        <p key={key} className={`infoModal__tabs-name ${language === key ? 'active' : ''}`} onClick={() => setLanguage(key)}>{key}</p>
                    )}
                </div>

                <p className="infoModal__content-text">
                    {moreInfo?.[language]}
                </p>
            </div>
            <div className="infoModal__bg" onClick={close}></div>
        </div>
    );
};