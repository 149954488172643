import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    mfaData: null,
    mfa_token: null,
    mfa_method: false,
    mfa_method_type: null,
    mfa_confirm: false,
    remember_device:false
};

const mfaSlice = createSlice({
    name: 'mf-auth',
    initialState,
    reducers: {
        setMfaToken(state, action) {
            state.mfa_token = action.payload.mfa_token
            state.mfa_method = action.payload.mfa_method
        },
        setMfaMethodType(state, action) {
            state.mfa_method_type = action.payload.mfa_method_type
            state.mfa_confirm = action.payload.mfa_confirm
        },
        updateMfaData(state, action) {
            state.mfaData = action.payload.data
            state.mfa_method = action.payload.mfa_method
            state.mfa_confirm = action.payload.mfa_confirm
            state.remember_device = action.payload.remember_device
        },
        rememberDevice(state, action) {
            state.remember_device = action.payload
        }
    }
})

export const {
    setMfaToken,
    setMfaMethodType,
    updateMfaData,
    rememberDevice,
} = mfaSlice.actions

export default mfaSlice.reducer