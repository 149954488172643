import React from 'react';
import DatePicker, {registerLocale} from "react-datepicker";
import { enUS, ru, fr, de, es } from 'date-fns/locale';

const CustomDatePicker = ({
                              id,
                              value,
                              selected,
                              portalId,
                              autoComplete,
                              dateFormat,
                              onChange,
                              className,
                              style,
                              disabled,
                              endDate,
                              startDate,
                              onKeyDown,
                              includeDates,
                              showMonthYearPicker,
                              placeholderText,
                              selectsRange,
                              isClearable,
                              ...otherprops
}) => {
    const localeLan = localStorage.getItem("local_lan" );
    registerLocale('en-US', enUS);
    registerLocale('ru', ru);
    registerLocale('fr', fr);
    registerLocale('de', de);
    registerLocale('es', es);

    return (
        <DatePicker
            value={value}
            id={id}
            portalId={portalId}
            style={style}
            locale={localeLan}
            endDate={endDate}
            startDate={startDate}
            selected={selected}
            onChange={onChange}
            isClearable={isClearable}
            disabled={disabled}
            className={className}
            onKeyDown={onKeyDown}
            dateFormat={dateFormat}
            includeDates={includeDates}
            selectsRange={selectsRange}
            autoComplete={autoComplete}
            placeholderText={placeholderText}
            showMonthYearPicker={showMonthYearPicker}
            {...otherprops}
        />
    )
}

export default CustomDatePicker;