import React, { useState } from 'react';
import CustomModal from "../../components/customModal";

function getFileType(url) {
    const lastDotIndex = url.lastIndexOf('.');
    if (lastDotIndex !== -1) {
        return url.substring(lastDotIndex + 1);
    } else {
        return url;
    }
}

const Index = ({
    doc,
    role,
   deleteDocument,
   updateDocument,
   translations,
})=> {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [inputValue, setInputValue] = useState(doc?.name || '')

    const handleDownloadClick = (fileUrl) => {
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = 'test_file.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const getDocImage = ()=>{
        if (getFileType(doc?.path) === 'xlsx' || getFileType(doc?.path) === 'xls'){
            return "/assets/icons/exel-icon.svg"
        }

        if (getFileType(doc?.path) === 'pdf'){
            return "/assets/icons/pdf-icon.svg"
        }

        return "/assets/icons/word-icon.svg"
    }

    return (
        <div className="contract__management-template" id={doc?.id}>
            <div className="left">
                <div className="template__image">
                    <img src={getDocImage()} alt=""/>
                </div>
                <div className="template__info">
                    <div className="template__title">{doc?.name || ''}</div>
                </div>
            </div>
            <div className="right">
                <img
                    onClick={() => handleDownloadClick(doc?.path)}
                    src="/assets/icons/download-block-icon.svg"
                    alt="download"
                />
                {role === "Super Admin(Aptilla) Role" && (
                    <img
                        onClick={() => setShowUpdateModal(true)}
                        src="/assets/icons/edit-block-icon.svg"
                        alt="edit"
                    />
                )}
                {role === "Super Admin(Aptilla) Role" && (
                    <img
                        onClick={() => setShowDeleteModal(true)}
                        src="/assets/icons/delete-block-icon.svg"
                        alt="delete"
                    />
                )}
            </div>
            {
                showDeleteModal &&
                <CustomModal
                    acceptHandler={() => {
                        setShowDeleteModal(false)
                        deleteDocument({id: doc.id, url: 'project-documents'})
                    }}
                    closeHandler={() => {
                        setShowDeleteModal(false)
                    }}
                    title={translations?.['report_documents.delete_document.headers.confirm_your_action']}
                    cancelBtnTitle={translations?.['report_documents.delete_document.actions.cancel']}
                    acceptBtnTitle={translations?.['report_documents.delete_document.actions.delete']}
                    hasHr
                >
                    <p>
                        {translations?.['report_documents.delete_document.titles.you_are_going_to_delete_this_file']}
                    </p>
                </CustomModal>
            }
            {
                showUpdateModal &&
                <CustomModal
                    acceptHandler={() => {
                        setShowUpdateModal(false)
                        updateDocument({id: doc.id, name: inputValue, url: 'project-documents'})
                    }}
                    closeHandler={() => {
                        setShowUpdateModal(false)
                    }}
                    title={translations?.['report_documents.update_document.headers.update_document']}
                    hasCancelBtn={false}
                    acceptBtnTitle={translations?.['report_documents.update_document.actions.save']}
                    acceptBtnDisabled={inputValue === doc?.name}
                >
                    <div className='update_modal'>
                        <div className='update_modal__content'>
                            <div className='imageWrap'>
                                <img src={getDocImage()} alt="word"/>
                            </div>
                        </div>
                        <div className="">
                            <div className="update_modal__input_label">{translations?.['report_documents.update_document.titles.document_name']}</div>
                            <input
                                id="title"
                                type="text"
                                className="update_modal__input"
                                placeholder={translations?.['report_documents.update_document.placeholder.enter_title']}
                                value={inputValue}
                                onChange={({target}) => setInputValue(target.value)}
                            />
                        </div>
                    </div>
                </CustomModal>
            }
        </div>
    );
}

export default Index;
