import moment from "moment";
import React, { useRef } from "react";
import { useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useErrorHandler } from "../../../../global/ErrorsHandler";
import {
    useCreateVariationRegisterMutation,
    useDeleteVariationRegisterMutation,
    useLazyGetAllCompaniesQuery,
    useUpdateVariationRegisterMutation,
} from "../../../../store/service/adbService";
import { setLoader } from "../../../../store/slice/dashboard";
import { Switch } from "../../../../UI/Switch";
import { DeleteModal } from "../../DeleteModal";
import CustomDatePicker from "../../../CustomDatePicker";

export const VariationsRegister = ({
                                       variationsData,
                                       glossaryData,
                                       refGoodsEquipment,
                                       translations
                                   }) => {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [show, setShow] = useState(true);
    const [showByActive, setShowByActive] = useState(true);
    const [hasArchived, setHasArchived] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [newItem, setNewItem] = useState(false);
    const [options, setOptions] = useState([]);
    const [editable, setEditable] = useState(false);
    const [deleteModal, setDeleteModal] = useState(null);
    const [moreInfo, setMoreInfo] = useState(null);
    const [tableHeaders, setTableHeaders] = useState([]);
    const [currentRow, setCurrentRow] = useState(null);
    const scrollRef = useRef(null);
    const user = useSelector((state) => state?.adbReducer?.user);
    const expandedRef = useRef(null);
    const [expanded, setExpanded] = useState(false);
    const descrRef = useRef(null);
    const [decrExpanded, setDecrExpanded] = useState(false);
    const localeLan = localStorage.getItem("local_lan" );
    moment.locale(localeLan);

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                let array = data?.map((item) => {
                    item.edit = false;
                    return item;
                });
                setEditable(false);
                setCurrentItem(null);
                setData(array);
                if (newItem) {
                    setNewItem(false);
                    let s = data?.filter((el, i) => i !== data.length - 1);
                    setData(s);
                }
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, [data]);

    useEffect(() => {
        if (variationsData?.variation_register) {
            let data = variationsData?.variation_register?.map((item) => ({
                variation_reference: item?.variation_reference,
                variation_description: item?.variation_description,
                date_issued: item?.date_issued,
                estimated_variance_cost: item?.estimated_variance_cost,
                variation_approved: item?.variation_approved,
                approved_variance_cost: item?.approved_variance_cost,
                current_status: item?.current_status,
                project_id: variationsData?.id,
                id: item?.id,
                edit: false,
                archived: item?.archived,
                submitted: item?.submitted,
                old_report: item?.old_report,
                date_variation_approved: item?.date_variation_approved,
                submitted_date: item?.submitted_date,
            }));
            let isHasArchived = isEmpty(data.find(item => item.archived===1));
            setHasArchived(!isHasArchived);
            if (showByActive===true && !isHasArchived){
                const filteredData =  data.filter(item => item.archived ===0);
                setData(filteredData);
            } else setData(data);
            setEditable(false);
        }
    }, [variationsData?.variation_register, showByActive]);

    const [
        getCompanies,
        {
            data: companies,
            error: companiesError,
            isLoading: companiesIsLoading,
            isError: companiesIsError,
            isSuccess: companiesIsSuccess,
        },
    ] = useLazyGetAllCompaniesQuery();

    useEffect(() => {
        getCompanies({
            per_page: 1000,
        });
    }, []);
    const [
        createVariationRegister,
        {
            data: createVariationRegisterData,
            isError: createVariationRegisterIsError,
            isLoading: createVariationRegisterIsLoading,
            isSuccess: createVariationRegisterIsSuccess,
            error: createVariationRegisterError,
        },
    ] = useCreateVariationRegisterMutation();

    const [
        updateVariationRegister,
        {
            data: updateVariationRegisterData,
            isError: updateVariationRegisterIsError,
            isLoading: updateVariationRegisterIsLoading,
            isSuccess: updateVariationRegisterIsSuccess,
            error: updateVariationRegisterError,
        },
    ] = useUpdateVariationRegisterMutation();

    const [
        deleteVariationRegister,
        {
            data: deleteVariationRegisterData,
            isError: deleteVariationRegisterIsError,
            isLoading: deleteVariationRegisterIsLoading,
            isSuccess: deleteVariationRegisterIsSuccess,
            error: deleteVariationRegisterError,
        },
    ] = useDeleteVariationRegisterMutation();

    useEffect(() => {
        if (variationsData?.responsible_party) {
            let data = variationsData?.responsible_party?.map((item) => {
                return {
                    value: item?.id,
                    label: item?.short_name,
                };
            });
            setOptions(data);
        }
    }, [variationsData]);

    const [errorHandler, setErrorHandler] = useErrorHandler([
        createVariationRegisterError,
        updateVariationRegisterError,
        deleteVariationRegisterError,
    ]);

    useEffect(() => {
        if(translations){
            const headers = [
                {
                    tooltip_name: translations?.['project_monitoring.variation_register.table_headers.variation_ref'],
                    tooltip_text: translations?.['project_monitoring.variation_register.tooltips.variation_ref'],
                },
                {
                    tooltip_name: translations?.['project_monitoring.variation_register.table_headers.variation_description'],
                    tooltip_text: translations?.['project_monitoring.variation_register.tooltips.variation_description'],
                },
                {
                    tooltip_name: translations?.['project_monitoring.variation_register.table_headers.date_instruction_issued'],
                    tooltip_text: translations?.['project_monitoring.variation_register.tooltips.date_instruction_issued'],
                },
                {
                    tooltip_name: translations?.['project_monitoring.variation_register.table_headers.estimated_variation_cost'],
                    tooltip_text: translations?.['project_monitoring.variation_register.tooltips.estimated_variation_cost'],
                },
                {
                    tooltip_name: translations?.['project_monitoring.variation_register.table_headers.cost_approved'],
                    tooltip_text: translations?.['project_monitoring.variation_register.tooltips.cost_approved'],
                },
                {
                    tooltip_name: translations?.['project_monitoring.variation_register.table_headers.date_variation_approved'],
                    tooltip_text: translations?.['project_monitoring.variation_register.tooltips.date_variation_approved'],
                },
                {
                    tooltip_name: translations?.['project_monitoring.variation_register.table_headers.approved_variation_cost'],
                    tooltip_text: translations?.['project_monitoring.variation_register.tooltips.approved_variation_cost'],
                },
                {
                    tooltip_name: translations?.['project_monitoring.variation_register.table_headers.current_status'],
                    tooltip_text: translations?.['project_monitoring.variation_register.tooltips.current_status'],
                },
            ]

            setTableHeaders(
                headers.map((item) => ({
                    ...item,
                    showTooltip: false,
                }))
            );
        }
    }, [translations])

    useEffect(() => {
        if (glossaryData?.data?.length) {
            setMoreInfo(
                glossaryData?.data?.filter(
                    (el) => el?.section_name === "Variation Register"
                )[0]
            );
        }
        setData((old) => {
            let data = old.map((item) => ({ ...item, edit: false }));
            return data;
        });
        setEditable(false);
    }, [
        createVariationRegisterIsSuccess,
        updateVariationRegisterIsSuccess,
        deleteVariationRegisterIsSuccess,
        glossaryData,
    ]);

    useEffect(() => {
        const d = () => {
            dispatch(setLoader(false));
        };

        createVariationRegisterIsLoading ||
        updateVariationRegisterIsLoading ||
        deleteVariationRegisterIsLoading
            ? dispatch(setLoader(true))
            : d();
    }, [
        createVariationRegisterIsLoading,
        updateVariationRegisterIsLoading,
        deleteVariationRegisterIsLoading,
    ]);
    const setterData = (input) => {
        const body = {
            variation_reference: input?.variation_reference,
            variation_description: input?.variation_description,
            date_issued: moment(input?.date_issued).format("YYYY-MM-DD"),
            estimated_variance_cost: input?.estimated_variance_cost
                ?.toString()
                ?.split(",")
                .join(""),
            project_id: variationsData?.id,
        };

        let s = [];
        if (currentItem?.approved_variance_cost !== null) {
            body.approved_variance_cost = currentItem?.approved_variance_cost
                ?.toString()
                ?.split(",")
                .join("");
        } else {
            delete body.approved_variance_cost;
        }

        if (currentItem?.variation_approved) {
            body.date_variation_approved = moment(input?.date_variation_approved).format("YYYY-MM-DD")
        }

        for (let key in body) {
            if (!body[key] || body[key] === "Invalid date" || body[key] === "") {
                toast.error("Please enter " + key?.split("_")?.join(" "), {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
                s.push("false");
            }
        }

        if (s.length === 0) {
            return body;
        } else {
            return null;
        }
    };

    const submitHandler = (e, item) => {
        e.preventDefault();
        if (newItem) {
            const dataCreated = setterData(currentItem);
            if (dataCreated) {
                createVariationRegister({
                    ...dataCreated,
                    variation_approved: currentItem?.variation_approved,
                    current_status: currentItem?.current_status,
                });
                setCurrentItem(null);
                setNewItem(false);
                setCurrentRow(null);
            }
        } else {
            const dataUpdated = setterData(currentItem);
            if (dataUpdated) {
                updateVariationRegister({
                    id: item?.id,
                    body: {
                        ...dataUpdated,
                        variation_approved: currentItem?.variation_approved,
                        current_status: currentItem?.current_status,
                    },
                });
                setCurrentItem(null);
                setNewItem(false);
                setCurrentRow(null);
            }
        }
    };

    return (
        <div className="project__goods">
            {deleteModal?.show && (
                <DeleteModal
                    id={deleteModal?.id}
                    action={deleteVariationRegister}
                    setDeleteModal={setDeleteModal}
                    cencelText={translations?.['main.main.actions.cancel']}
                    deleteText={translations?.['main.main.actions.delete']}
                    modalTitle={translations?.['main.main.headers.confirm_your_action']}
                    clarifyingText={translations?.['main.main.titles.you_are_going_to_delete_this_row']}
                />
            )}
            <div className={`project__block ${show ? "" : "close"}`}>
                <div className="project__block-subtitles">
                    <div className="project__block-subtitle">{translations?.['project_monitoring.variation_register.headers.variation_register']}</div>
                    <div className="project__block__buttonsContainer">
                        {hasArchived && <button
                            onClick={() => setShowByActive(!showByActive)}
                            className="project__block-add-row">
                            <span> <img src="/assets/icons/blue-eye.svg" alt="plus"/></span>
                            <span>{showByActive ? translations?.['project_monitoring.variation_register.actions.show_all']
                                : translations?.['project_monitoring.variation_register.actions.show_active']}</span>
                        </button>}
                        {user?.permissions?.edit_project && (
                            <button
                                onClick={() => {
                                    let randomId = Math.floor(Math.random() * 1000);
                                    setCurrentRow(randomId);
                                    scrollRef !== null && scrollRef.current.scrollIntoView();
                                    if (!newItem) {
                                        setNewItem(true);
                                        setEditable(true);
                                        setData((old) => [
                                            ...old,
                                            {
                                                id: randomId,
                                                variation_reference: "",
                                                variation_description: "",
                                                date_issued: null,
                                                estimated_variance_cost: null,
                                                variation_approved: 0,
                                                approved_variance_cost: null,
                                                current_status: "",
                                                date_variation_approved: null,
                                                edit: true,
                                            },
                                        ]);
                                        setCurrentItem({
                                            id: randomId,
                                            variation_reference: "",
                                            variation_description: "",
                                            date_issued: null,
                                            estimated_variance_cost: null,
                                            variation_approved: 0,
                                            approved_variance_cost: null,
                                            current_status: "",
                                            date_variation_approved: null,
                                            edit: true,
                                        });
                                    }
                                }}
                                className="project__block-add-row"
                            >
                <span>
                  <img src="/assets/icons/blue-plus.svg" alt="plus" />
                </span>
                                <span>{translations?.['project_monitoring.variation_register.actions.add_row']}</span>
                            </button>
                        )}
                    </div>
                </div>
                <div
                    className={`project__block-content permits ${
                        editable ? "editable" : ""
                    }`}
                >
                    <div
                        className={`project__block-table project-table ${
                            currentItem ? "n" : ""
                        }`}
                    >
                        <div className={`project-table__header grid-8`} ref={scrollRef}>
                            {tableHeaders?.map((el, idx) => {
                                return (
                                    <div
                                        key={idx}
                                        onMouseEnter={(event) => {
                                            setTableHeaders(
                                                tableHeaders.map((item) => ({
                                                    ...item,
                                                    showTooltip: el.tooltip_name === item.tooltip_name,
                                                }))
                                            );
                                        }}
                                        onMouseLeave={() => {
                                            setTableHeaders(
                                                tableHeaders.map((item) => ({
                                                    ...item,
                                                    showTooltip: false,
                                                }))
                                            );
                                        }}
                                        className="project-table__th"
                                    >
                                        {el?.showTooltip ? (
                                            <span
                                                className={
                                                    el?.tooltip_text?.length > 20
                                                        ? "lengthy"
                                                        : "not-lengthy"
                                                }
                                            >
                        {el?.tooltip_text}
                      </span>
                                        ) : (
                                            ""
                                        )}
                                        {el?.tooltip_name}
                                    </div>
                                );
                            })}
                        </div>
                        <div className="project-table__body">
                            {data?.map((item, index) => (
                                <div key={index}>
                                    {index !== 0 && currentRow === item?.id && (
                                        <div className={`project-table__header grid-8`} key={index}>
                                            {tableHeaders?.map((el, idx) => {
                                                return (
                                                    <div key={idx} className="project-table__th">
                                                        {el?.showTooltip ? (
                                                            <span
                                                                className={
                                                                    el?.tooltip_text?.length > 20
                                                                        ? "lengthy"
                                                                        : "not-lengthy"
                                                                }
                                                            >
                                {el?.tooltip_text}
                              </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {el?.tooltip_name}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                    <form
                                        className={`project-table__row grid-8 ${
                                            item?.edit ? "edit" : currentItem ? "n" : ""
                                        } ${
                                            Boolean(item.submitted) ||
                                            !user?.permissions?.edit_project
                                                ? "submitted"
                                                : ""
                                        }`}
                                        id={item?.id}
                                        key={item?.id}
                                        onSubmit={(e) => submitHandler(e, item)}
                                    >
                                        <div className="project-table__td">
                                            <span>{item?.variation_reference}</span>
                                            <div className="project__block-input project-table__field">
                        <textarea
                            maxLength={190}
                            defaultValue={item?.variation_reference}
                            onChange={(e) => {
                                setCurrentItem((old) => ({
                                    ...old,
                                    variation_reference: e.target.value,
                                }));
                            }}
                        />
                                            </div>
                                        </div>
                                        <div className="project-table__td">
                                            <span>{item?.variation_description}</span>
                                            <div className="project__block-input project-table__field">
                        <textarea
                            className={`${
                                expanded ? "project__block-expandedInput" : ""
                            }`}
                            ref={expandedRef}
                            onFocus={() => setExpanded(true)}
                            onBlur={() => setExpanded(false)}
                            maxLength={190}
                            defaultValue={item?.variation_description}
                            onChange={(e) => {
                                setCurrentItem((old) => ({
                                    ...old,
                                    variation_description: e.target.value,
                                }));
                            }}
                        />
                                            </div>
                                        </div>
                                        <div className="project-table__td">
                      <span>
                        {moment(item?.date_issued).format("DD/MM/YYYY") ===
                        "Invalid date" ||
                        !moment(item?.date_issued).format("DD/MM/YYYY")
                            ? ""
                            : moment(item?.date_issued).format("DD/MM/YYYY")}
                      </span>
                                            <div className="project__block-item-calendar project-table__field">
                                                <CustomDatePicker
                                                    portalId="root-portal"
                                                    autoComplete="off"
                                                    id="approved_date"
                                                    placeholderText={translations?.['main.main.placeholder.dd_mm_yyyy']}
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={
                                                        currentItem?.date_issued
                                                            ? moment(currentItem?.date_issued).toDate()
                                                            : ""
                                                    }
                                                    onChange={(date) => {
                                                        setCurrentItem((old) => ({
                                                            ...old,
                                                            date_issued: date,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="project-table__td">
                      <span>
                        $
                          {String(item?.estimated_variance_cost).replace(
                              /(\d)(?=(\d{3})+([^\d]|$))/g,
                              "$1,"
                          )}
                      </span>
                                            <div className="project__block-input project-table__field">
                                                <input
                                                    maxLength={190}
                                                    value={
                                                        currentItem
                                                            ? currentItem?.estimated_variance_cost?.toLocaleString()
                                                            : item?.estimated_variance_cost?.toLocaleString()
                                                    }
                                                    onChange={(e) => {
                                                        let newValue = e.target.value.replace(/,/g, "");
                                                        let abc = newValue
                                                            .replace(/[^0-9.-]/g, "")
                                                            .replace(/(?!^-)-/g, "");
                                                        let formattedValue = abc.replace(
                                                            /\B(?=(\d{3})+(?!\d))/g,
                                                            ","
                                                        );
                                                        setCurrentItem((old) => ({
                                                            ...old,
                                                            estimated_variance_cost: formattedValue,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="project-table__td">
                                            <Switch
                                                value={
                                                    item?.edit
                                                        ? currentItem?.variation_approved
                                                            ? true
                                                            : false
                                                        : item?.variation_approved
                                                            ? true
                                                            : false
                                                }
                                                checked={
                                                    item?.edit
                                                        ? currentItem?.variation_approved
                                                            ? true
                                                            : false
                                                        : item?.variation_approved
                                                            ? true
                                                            : false
                                                }
                                                disabled={item?.edit ? false : true}
                                                id={index + 661}
                                                handler={(value) => {
                                                    setCurrentItem((old) => ({
                                                        ...old,
                                                        variation_approved: value,
                                                    }));
                                                }}
                                            />
                                        </div>
                                        <div className="project-table__td">
                      <span>
                        {item?.variation_approved ? item?.date_variation_approved ? moment(item?.date_variation_approved).format("DD/MM/YYYY") ===
                        "Invalid date" ||
                        !moment(item?.date_variation_approved).format("DD/MM/YYYY")
                            ? ""
                            : moment(item?.date_variation_approved).format("DD/MM/YYYY") : "" : ''}
                      </span>
                                            <div className="project__block-item-calendar project-table__field">
                                                <CustomDatePicker
                                                    portalId="root-portal"
                                                    autoComplete="off"
                                                    id="approved_date"
                                                    placeholderText={translations?.['main.main.placeholder.dd_mm_yyyy']}
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={
                                                        currentItem?.date_variation_approved
                                                            ? moment(currentItem?.date_variation_approved).toDate()
                                                            : ""
                                                    }
                                                    onChange={(date) => {
                                                        setCurrentItem((old) => ({
                                                            ...old,
                                                            date_variation_approved: date,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="project-table__td">
                      <span>
                        {item?.variation_approved == true
                            ? item?.approved_variance_cost === null
                                ? ""
                                : "$" +
                                String(item?.approved_variance_cost).replace(
                                    /(\d)(?=(\d{3})+([^\d]|$))/g,
                                    "$1,"
                                )
                            : ""}
                      </span>
                                            <div className="project__block-input project-table__field">
                                                <input
                                                    disabled={!currentItem?.variation_approved}
                                                    maxLength={190}
                                                    value={
                                                        currentItem
                                                            ? currentItem?.approved_variance_cost?.toLocaleString()
                                                            : item?.approved_variance_cost?.toLocaleString()
                                                    }
                                                    onChange={(e) => {
                                                        let newValue = e.target.value.replace(/,/g, "");
                                                        let abc = newValue
                                                            .replace(/[^0-9.-]/g, "")
                                                            .replace(/(?!^-)-/g, "");
                                                        let formattedValue = abc.replace(
                                                            /\B(?=(\d{3})+(?!\d))/g,
                                                            ","
                                                        );
                                                        setCurrentItem((old) => ({
                                                            ...old,
                                                            approved_variance_cost: formattedValue,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="project-table__td">
                                            <span>{item?.current_status}</span>
                                            <div className="project__block-input project-table__field">
                        <textarea
                            className={`${
                                decrExpanded ? "project__block-expandedInput" : ""
                            }`}
                            ref={descrRef}
                            onFocus={() => setDecrExpanded(true)}
                            onBlur={() => setDecrExpanded(false)}
                            maxLength={190}
                            defaultValue={item?.current_status}
                            onChange={(e) => {
                                setCurrentItem((old) => ({
                                    ...old,
                                    current_status: e.target.value,
                                }));
                            }}
                        />
                                            </div>
                                        </div>
                                        <div className="project__block-btns">
                                            {currentItem ? (
                                                <div
                                                    className="project__block-cancel"
                                                    onClick={() => {
                                                        setCurrentRow(null);
                                                        let array = data?.map((item) => {
                                                            item.edit = false;
                                                            // if (currentItem?.completed) {
                                                            //     item.completed = false;
                                                            //     setCurrentItem((old) => ({
                                                            //         ...old,
                                                            //         completed: false,
                                                            //     }));
                                                            // }
                                                            return item;
                                                        });
                                                        setCurrentItem(null);
                                                        setData(array);
                                                        if (newItem) {
                                                            setNewItem(false);
                                                            let s = data?.filter(
                                                                (el, i) => i !== data.length - 1
                                                            );
                                                            setData(s);
                                                        }
                                                    }}
                                                >
                                                    <img src="/assets/icons/close-mark.svg" alt="close" />
                                                    {translations?.['project_monitoring.variation_register.actions.cancel']}
                                                </div>
                                            ) : (
                                                <div
                                                    className="project__block-delete"
                                                    onClick={() => {
                                                        // deleteVariationRegister(item?.id);
                                                        setDeleteModal({ id: item?.id, show: true });
                                                    }}
                                                >
                                                    <img src="/assets/icons/trash-icon.svg" alt="arrow" />
                                                    {translations?.['project_monitoring.variation_register.actions.delete']}
                                                </div>
                                            )}
                                            {currentItem ? (
                                                <button className="project__block-save">
                                                    <img src="/assets/icons/save-arrow.svg" alt="arrow" />
                                                    {translations?.['project_monitoring.variation_register.actions.save']}
                                                </button>
                                            ) : (
                                                <div
                                                    onClick={() => {
                                                        setCurrentRow(item?.id);
                                                        setCurrentItem(item);
                                                        setEditable(true);
                                                        setData(() => {
                                                            let array = data?.map((elem) => {
                                                                if (elem?.id === item?.id) {
                                                                    elem.edit = true;
                                                                }
                                                                return elem;
                                                            });
                                                            return array;
                                                        });
                                                    }}
                                                    className="project__block-update"
                                                >
                                                    {translations?.['project_monitoring.variation_register.actions.edit']}
                                                </div>
                                            )}
                                        </div>
                                    </form>
                                </div>
                            ))}
                        </div>
                    </div>
                    {/* <div className={`project__block-load ${currentItem ? "n" : ""}`}>
            Load more
          </div> */}
                </div>
                <div style={{ marginTop: 30 }} className="project__status-content">
                    <div className="varandclaims__boxLong">
                        <p className="project__status-subtitle">{translations?.['project_monitoring.variation_register.titles.total_variations']}</p>
                        <span className="project__status-text">
              ${variationsData?.variations_info?.total_variations}
            </span>
                    </div>
                    <div className="varandclaims__boxLong">
                        <p className="project__status-subtitle">
                            {translations?.['project_monitoring.variation_register.titles.total_variations_approved']}
                        </p>
                        <span className="project__status-text">
              ${variationsData?.variations_info?.total_variations_approved}
            </span>
                    </div>
                    <div className="varandclaims__boxLong">
                        <p className="project__status-subtitle">
                            {translations?.['project_monitoring.variation_register.titles.total_variation_pending_approval']}
                        </p>
                        <span className="project__status-text">
              $
                            {
                                variationsData?.variations_info
                                    ?.total_variation_pending_approval
                            }
            </span>
                    </div>
                </div>
            </div>
        </div>
    );
};
