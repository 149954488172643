import React from "react";

export const VariationsAndClaims = ({variationsData, glossaryData, translations}) => {
    return (
        <>
            <div className="project__status varandclaims">
                <div className="varandclaims__content">
                    <div className="project__block-subtitle">{translations?.['project_monitoring.variations_and_claims.headers.variations_and_claims']}</div>
                </div>
                <div className="varandclaims__block grid-2">
                    <div className="varandclaims__box">
                        <p className="project__status-subtitle">{translations?.['project_monitoring.variations_and_claims.titles.prior_contract_amount']}</p>
                        <span className="project__status-text">${variationsData?.variations_and_claims?.prior_contract_price}</span>
                    </div>
                    <div className="varandclaims__box">
                        <p className="project__status-subtitle">{translations?.['project_monitoring.variations_and_claims.titles.revised_contract_amount']}</p>
                        <span className="project__status-text">${variationsData?.variations_and_claims?.approved_revised_contract_cost}</span>
                    </div>
                </div>
                <div className="varandclaims__block grid-3">
                    <div className="varandclaims__th">{translations?.['project_monitoring.variations_and_claims.headers.variations']}</div>
                    <div className="varandclaims__th">{translations?.['project_monitoring.variations_and_claims.headers.claims']}</div>
                    <div className="varandclaims__th">{translations?.['project_monitoring.variations_and_claims.headers.total_changes']}</div>
                </div>
                <div className="varandclaims__block grid-3">
                    <div className="varandclaims__box">
                        <p className="project__status-subtitle">{translations?.['project_monitoring.variations_and_claims.titles.total_approved_variations']}</p>
                        <span className="project__status-text">${variationsData?.variations_and_claims?.total_approved_variations_cost}</span>
                    </div>
                    <div className="varandclaims__box">
                        <p className="project__status-subtitle">{translations?.['project_monitoring.variations_and_claims.titles.total_certified_claims']}</p>
                        <span className="project__status-text">${variationsData?.variations_and_claims?.total_claims_certified_amount}</span>
                    </div>
                    <div className="varandclaims__box">
                        <p className="project__status-subtitle">
                            {translations?.['project_monitoring.variations_and_claims.titles.total_approved_contract_changes']}
                        </p>
                        <span className="project__status-text">${variationsData?.variations_and_claims?.total_approved_contract_cost_changes}</span>
                    </div>
                </div>

                <div className="varandclaims__block grid-3">
                    <div className="varandclaims__box">
                        <p className="project__status-subtitle">{translations?.['project_monitoring.variations_and_claims.titles.total_variations_pending']}</p>
                        <span className="project__status-text">${variationsData?.variations_and_claims?.total_variations_pending_approval_cost}</span>
                    </div>
                    <div className="varandclaims__box">
                        <p className="project__status-subtitle">{translations?.['project_monitoring.variations_and_claims.titles.total_claims_pending']}</p>
                        <span className="project__status-text">${variationsData?.variations_and_claims?.total_claims_pending_certification_amount}</span>
                    </div>
                    <div className="varandclaims__box">
                        <p className="project__status-subtitle">{translations?.['project_monitoring.variations_and_claims.titles.total_pending_amounts']}</p>
                        <span className="project__status-text">${variationsData?.variations_and_claims?.total_pending_contract_cost_changes }</span>
                    </div>
                </div>
            </div>
            <div className="project__status-show">
                <div></div>
            </div>
        </>


    )
}