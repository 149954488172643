import React from "react";

export const HealthTable = ({healthData, translations}) => {
    return (
        <div className="project__health-table">
            <div className="project__block-subtitles">
                <div className="project__block-subtitle">{translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_monitoring_statistics_definitions.headers.health_and_safety_monitoring_statistics_definitions']}</div>
            </div>
            <div className="project__health-box">
                <p>{translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_monitoring_statistics_definitions.titles.lti']}</p>
            </div>
            <div className="project__health-box">
                <p>{translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_monitoring_statistics_definitions.titles.ltifr']}</p>
            </div>
            <div className="project__health-box">
                <p>{translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_monitoring_statistics_definitions.titles.fai']}</p>
            </div>
            <div className="project__health-box">
                <p>{translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_monitoring_statistics_definitions.titles.mti']}</p>
            </div>
            <div className="project__health-box">
                <p>{translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_monitoring_statistics_definitions.titles.snm']}</p>
            </div>
            <div className="project__health-box">
                <p>{translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_monitoring_statistics_definitions.titles.trifr']}</p>
            </div>
        </div>
    )
}