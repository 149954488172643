
const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber || typeof phoneNumber !== "string" || phoneNumber.length < 7) {
        return phoneNumber;
    }
    const lastFourDigits = phoneNumber.slice(-4);
    const hiddenDigits = "*".repeat(phoneNumber.length - 4);

    return `(***) ${hiddenDigits} ${lastFourDigits}`;
};

const formatEmail = (email) => {
    if (!email || typeof email !== "string" || email.indexOf("@") === -1) {
        return email;
    }
    const [prefix, domain] = email.split("@");
    const hiddenPart = "*".repeat(prefix.length - 2) + prefix.slice(-2);
    return `${hiddenPart}@${domain}`;
};


export {
    formatPhoneNumber,
    formatEmail
}