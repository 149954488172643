import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import React, {useEffect, useRef, useState} from 'react';
import ItemEdit from "./ItemEdit";
import MoreInfoModal from "../../../components/MoreInfoModal";
import {errorHandle} from "../../../global/errorHandle";
import {setLoader} from "../../../store/slice/dashboard";
import {useDeleteFinancingPlanMutation, useLazyGetFinancingPlanQuery} from "../../../store/service/adbService";

const FinancingPlan = ({translations, data, reportId, hiddenSectionHandler, isReportSubmitted}) => {
    const dispatch = useDispatch();
    const headerRef = useRef();
    const thead = useRef();
    const [isOpenMoreInfo, setOpenMoreInfo] = useState(false)
    const [isOpenEdit, setIsOpenEdit ]= useState({show: true, id: null});
    const [isOpenToAdd, setIsOpenToAdd ]= useState(false);
    const [cellWidths, setCellWidths] = useState([]);

    const [financingPlanData, setFinancingPlanData ]= useState(data)

    const [deleteFinancingPlan, {
        data: deleteFinancingPlanData,
        isLoading: deleteFinancingPlanIsLoading,
        error: deleteFinancingPlanError,
        isError: deleteFinancingPlanIsError,
        isSuccess: deleteFinancingPlanIsSuccess,
    }]= useDeleteFinancingPlanMutation();

    const [getFinancingPlan, {
        data: newFinancingPlanData,
        isLoading: financingPlanIsLoading,
        error: financingPlanError,
        isError: financingPlanIsError,
        isSuccess: financingPlanIsSuccess,
    }]= useLazyGetFinancingPlanQuery();

    const updateFinancingPlan = () => {
        getFinancingPlan(reportId);
    }

    useEffect(() => {
        setFinancingPlanData(data)
    }, [data]);

    useEffect(() => {
        if (deleteFinancingPlanIsSuccess){
            toast.success(deleteFinancingPlanData.message);
            updateFinancingPlan();
        }
    }, [deleteFinancingPlanIsSuccess, deleteFinancingPlanData]);

    useEffect(() => {
        if (financingPlanIsSuccess){
            setFinancingPlanData(newFinancingPlanData?.data)
        }
    }, [financingPlanIsSuccess, newFinancingPlanData]);

    useEffect(() => {
        if (deleteFinancingPlanIsError){
            toast.error(deleteFinancingPlanError?.data.message)
            errorHandle(deleteFinancingPlanError);
        }
        if (financingPlanIsError){
            toast.error(financingPlanError?.data.message)
            errorHandle(financingPlanError);
        }
    }, [
        deleteFinancingPlanIsError, deleteFinancingPlanError,
        financingPlanError, financingPlanIsError
    ])

    useEffect(() => {
        financingPlanIsLoading ||
        deleteFinancingPlanIsLoading ?
            dispatch(setLoader(true))
            : dispatch(setLoader(false));
    }, [deleteFinancingPlanIsLoading, financingPlanIsLoading]);

    useEffect(() => {
        if (thead.current) {
            const widths = Array.from(thead.current.querySelectorAll('span')).map(thead => thead?.offsetWidth);
            setCellWidths(widths);
        }
    }, [thead]);


    return (
        <section className="erLoan" ref={headerRef} id="financing_plan">
            {isOpenMoreInfo &&
                <MoreInfoModal
                    title= {translations?.['financing_plan.index.headers.financing_plan']}
                    moreInfo={translations?.['financing_plan.index.more_info.more_info']}
                    close={()=>setOpenMoreInfo(false)}
                />
            }
            <div className="editReport__sectionHeader">
                <div style={{width: '20px', height: '20px'}}>
                    <span onClick={() => hiddenSectionHandler(headerRef)}/>
                </div>
                <h2 onClick={() => hiddenSectionHandler(headerRef)}>
                    {translations?.['financing_plan.index.headers.financing_plan']}
                </h2>
                <button className="editReport__sectionHeader__moreInfo" onClick={()=>setOpenMoreInfo(!isOpenMoreInfo)} >
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.99805 6.33325V9.43992" stroke="#767676" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M6.99805 5.05322C7.27419 5.05322 7.49805 4.82937 7.49805 4.55322C7.49805 4.27708 7.27419 4.05322 6.99805 4.05322C6.7219 4.05322 6.49805 4.27708 6.49805 4.55322C6.49805 4.82937 6.7219 5.05322 6.99805 5.05322Z" fill="#767676"/>
                        <path d="M6.9987 13.1666C10.4045 13.1666 13.1654 10.4057 13.1654 6.99992C13.1654 3.59416 10.4045 0.833252 6.9987 0.833252C3.59294 0.833252 0.832031 3.59416 0.832031 6.99992C0.832031 10.4057 3.59294 13.1666 6.9987 13.1666Z" stroke="#767676" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    {translations?.['financing_plan.index.titles.more_info']}
                </button>
                {!isReportSubmitted &&
                    <button
                        className="editReport__sectionHeader__addRowBtn"
                        onClick={() => setIsOpenToAdd(true)}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25Z" stroke="#0052CC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M11.8687 9.22595L11.9111 14.6141" stroke="#0052CC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M9.1958 11.8988L14.584 11.9412" stroke="#0052CC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        {translations?.['financing_plan.index.actions.add_row']}
                    </button>
                }
            </div>
            <div className="editReport__table">
                <div className="editReport__table__row editReport__table__header" ref={thead}>
                    <span> {translations?.['financing_plan.index.table_headers.source_of_fund']} </span>
                    <span> {translations?.['financing_plan.index.table_headers.description']} </span>
                    <span> {translations?.['financing_plan.index.table_headers.amount_millions']} </span>
                    <span> {translations?.['financing_plan.index.table_headers.share_percent']} </span>
                </div>
                {
                    financingPlanData?.data && financingPlanData?.data?.map(item =>{
                        return (
                            <React.Fragment key={item.id}>
                                {!(isOpenEdit.show && isOpenEdit.id === item.id) ?
                                    <div className="editReport__table__row" >
                                        <span>{item.source_of_fund}</span>
                                        <span>{item.description}</span>
                                        <span>{item.amount}</span>
                                        <span>{item.share}</span>
                                        {!isReportSubmitted && <div className="editReport__table__settingsButtons">
                                            <button className="editReport__table__deleteBtn"
                                                    onClick={() => deleteFinancingPlan(item.id)}
                                            >
                                                {translations?.['financing_plan.index.actions.delete']}
                                            </button>
                                            <button
                                                className="editReport__table__updateBtn"
                                                onClick={() => setIsOpenEdit({show: true, id: item.id})}>
                                                {translations?.['financing_plan.index.actions.update']}
                                            </button>
                                        </div>}
                                    </div> :
                                    <ItemEdit
                                        reportId={reportId}
                                        descriptionPlaceholder={translations?.['index.index.placeholder.text']}
                                        amountPlaceholder={translations?.['index.index.placeholder.number']}
                                        itemData={item}
                                        setIsOpenEdit={setIsOpenEdit}
                                        cancelTranslation={translations?.['financing_plan.index.actions.cancel']}
                                        saveTranslation={translations?.['financing_plan.index.actions.save']}
                                        getFinancingPlans={updateFinancingPlan}
                                        cellWidths={cellWidths} // Pass the cell widths
                                    />
                            }
                            </React.Fragment>
                        )
                    })
                }
                {(isOpenToAdd && !isReportSubmitted) && <ItemEdit
                    getFinancingPlans={updateFinancingPlan}
                    reportId={reportId}
                    descriptionPlaceholder={translations?.['index.index.placeholder.text']}
                    amountPlaceholder={translations?.['index.index.placeholder.number']}
                    setIsOpenEdit={setIsOpenToAdd}
                    cancelTranslation={translations?.['financing_plan.index.actions.cancel']}
                    saveTranslation={translations?.['financing_plan.index.actions.save']}
                    cellWidths={cellWidths} // Pass the cell widths
                />}
                <div className="editReport__table__row editReport__table__row__total">
                    <span>{translations?.['financing_plan.index.titles.total_project_cost']}:</span>
                    <span></span>
                    <span>${financingPlanData?.total_amount}</span>
                    <span>{financingPlanData?.total_percent}</span>
                </div>
            </div>
        </section>
    )
}
export default FinancingPlan;
