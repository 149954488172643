import {useDispatch} from "react-redux";
import React, {useEffect, useRef, useState} from 'react';
import {setLoader} from "../../store/slice/dashboard";
import {useLazyGetNotifByProjectQuery, useUpdateSectionNotifyMutation} from "../../store/service/adbService";
import {Switch} from "../../UI/Switch";
import NotificationItems from "./NotificationItems";

const Sections = ({ projectId})=>{
    const dispatch = useDispatch();
    const lastCollapseRef = useRef(null);
    const [colNumber, setColNumber] = useState('');
    const [isOpenCollapse, setIsOpenCollapse] = useState(false);
    const [sectionData, setSectionData] = useState([]);

    const [updateSectionNotify, {
        data: updatedSectionNotifyData,
        isLoading: updateSectionNotifyIsLoading,
        isFetching: updateSectionNotifyIsFetching,
    }] = useUpdateSectionNotifyMutation();

    const [getSectionsByProject, {
        data: sectionsByProjectData,
        isLoading: isLoadingSectionsByProject,
        isSuccess: isSuccessSectionsByProject,
        isFetching: isFetchingSectionsByProject,
    }] = useLazyGetNotifByProjectQuery();

    const updateSections = () => {
        getSectionsByProject(projectId);
    }

    const updateSectionToNotifications = (sectionId) => {
        updateSectionNotify(sectionId)
    }

    const handleOpenTimeSchedulerCollapse = (key,index) =>{
        if (colNumber[0] === key[0]) {
            setIsOpenCollapse(!isOpenCollapse);
            setColNumber(key );
        }
        else {
            setColNumber(key);
            setIsOpenCollapse(true);
        }
        if (index === sectionData.length - 1) {
            setTimeout(() => {
                lastCollapseRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
            }, 0);
        }

    }

    const updateSectionData = (newData) => {
        setSectionData(prevState =>
            prevState.map(item => {
                const key = Object.keys(item)[0];
                const id = item[key].id;
                if (newData[Object.keys(newData)].id === id) {
                    return {
                        ...item,
                        [key]: {
                            ...item[key],
                            notifications_on: newData[Object.keys(newData)].notifications_on
                        }
                    };
                }
                return item;
            })
        );
    }

    useEffect(() => {
        getSectionsByProject(projectId)
    }, []);

    useEffect(() => {
        if (isSuccessSectionsByProject){
            setSectionData(sectionsByProjectData?.data?.sections)
        }
    }, [sectionsByProjectData, isSuccessSectionsByProject]);

    useEffect(() => {
        if (updatedSectionNotifyData?.data){
            updateSectionData(updatedSectionNotifyData.data);
        }
    }, [updatedSectionNotifyData]);

    // useEffect(() => {
    //     setSectionData(allSectionsData?.sections)
    // }, [allSectionsData]);

    useEffect(() => {
        updateSectionNotifyIsLoading || updateSectionNotifyIsFetching
        || isLoadingSectionsByProject || isFetchingSectionsByProject ?
            dispatch(setLoader(true)) :
            dispatch(setLoader(false))
    },[updateSectionNotifyIsLoading, updateSectionNotifyIsFetching, isLoadingSectionsByProject, isFetchingSectionsByProject])

    return (
        <div className='nSettings__projects' style={{overflow: 'auto' }}>
            { sectionData?.map((item, index) =>{
                const sectionKey = Object.keys(item);
                return ( <div className='nSettings__projects__project nSettings__projects__project_bg'
                              key={index} ref={index === sectionData.length - 1 ? lastCollapseRef : null}
                              // style={{overflow: 'auto', background: '#F9F9FB !important', border: 'none' }}
                >
                    <div className="nSettings__projects__project__header">
                        <h5>{item[sectionKey].title}</h5>
                        <div className='nSettings__projects__allowToNoifWrapper'>
                            <Switch
                                id={item[sectionKey].id}
                                checked={item[sectionKey].notifications_on}
                                handler={()=>updateSectionToNotifications(item[sectionKey].id)}
                            />
                        </div>
                        <button
                            type='button'
                            className={`nSettings__projects__selectDayBtn ${isOpenCollapse && colNumber[0] == sectionKey && 'nSettings__projects__selectDayBtn_rotate'}`}
                            style={{background: "#FFF"}}
                            onClick={() =>handleOpenTimeSchedulerCollapse(sectionKey, index)}
                        >
                            <svg
                                width="24" height="24" viewBox="0 0 24 24" fill="none" >
                                <path d="M19 8.5L12 15.5L5 8.5" stroke="#2A3650" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </button>
                    </div>
                    { isOpenCollapse && colNumber[0] == sectionKey && (
                        item[sectionKey]?.notifications?.map((notification)=>{
                            let data= notification[Object.keys(notification)]
                            return <NotificationItems itemData={data} key={data.id} updateSections={updateSections}/>
                        })
                    )}
                </div>)
            })}
        </div>
    )
}

export default Sections;