import moment from "moment";
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import {
    useLazyGetCovenantsQuery,
    useDeleteCovenantsMutation,
    useCreateCovenantsMutation,
    useUpdateCovenantsMutation,
} from '../../../store/service/adbService';
import { errorHandle } from '../../../global/errorHandle';
import { setLoader } from '../../../store/slice/dashboard';
import ItemEdit from "./ItemEdit";
import {parseHtml} from "../../../global/parseHtml";

const OtherCovenants = ({ title, reportId, translations, data, covenantsType, sectionId, isReportSubmitted, sections }) => {
    const dispatch = useDispatch();
    const headerRef = useRef();
    const [isOpenEdit, setIsOpenEdit] = useState({ show: false, id: null });
    const [isOpenToAdd, setIsOpenToAdd] = useState(false);
    const [covenantsData, setCovenantsData] = useState(data);
    const [cellWidths, setCellWidths] = useState([]);


    const [deleteCovenants, {
        data: deleteCovenantsData,
        isLoading: deleteCovenantsIsLoading,
        error: deleteCovenantsError,
        isError: deleteCovenantsIsError,
        isSuccess: deleteCovenantsIsSuccess
    }] = useDeleteCovenantsMutation();


    const [getCovenants, {
        data: newCovenantsData,
        isLoading: covenantsIsLoading,
        error: covenantsError,
        isError: covenantsIsError,
        isSuccess: covenantsIsSuccess
    }] = useLazyGetCovenantsQuery();

    const updateCovenants = () => {
        getCovenants({reportId:reportId, type: covenantsType});
    };

    useEffect(() => {
        setCovenantsData(data)
    }, [data]);

    useEffect(() => {
        if (deleteCovenantsIsSuccess) {
            toast.success(deleteCovenantsData.message);
            updateCovenants();
        }
    }, [deleteCovenantsIsSuccess, deleteCovenantsData]);

    useEffect(() => {
        if (covenantsIsSuccess) {
            setCovenantsData(newCovenantsData?.data);
        }
    }, [covenantsIsSuccess, newCovenantsData]);

    useEffect(() => {
        if (deleteCovenantsIsError) {
            toast.error(deleteCovenantsError?.message);
            errorHandle(deleteCovenantsError);
        }
        if (covenantsIsError) {
            toast.error(covenantsError?.data.message);
            errorHandle(covenantsError);
        }
    }, [
        deleteCovenantsIsError, deleteCovenantsError,
        covenantsError, covenantsIsError
    ]);

    useEffect(() => {
        covenantsIsLoading || deleteCovenantsIsLoading
            ? dispatch(setLoader(true))
            : dispatch(setLoader(false));
    }, [deleteCovenantsIsLoading, covenantsIsLoading]);

    useEffect(() => {
        if (headerRef.current) {
            const widths = Array.from(headerRef.current.querySelectorAll('th')).map(th => th.offsetWidth - 20);
            setCellWidths(widths);
        }
    }, [headerRef]);


    return (
        <div className='otherCovenants ' ref={headerRef} id={sectionId}>
            {sections?.hasOwnProperty(sectionId) && <>
                <div className="editReport__sectionHeader">
                    <h3>{title}</h3>
                    {!isReportSubmitted &&
                        <button
                            className="editReport__sectionHeader__addRowBtn"
                            onClick={() => setIsOpenToAdd(true)}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25Z" stroke="#0052CC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M11.8687 9.22595L11.9111 14.6141" stroke="#0052CC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M9.1958 11.8988L14.584 11.9412" stroke="#0052CC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            {translations?.['covenants.loan_covenants.actions.add_row']}
                        </button>
                    }
                </div>
                <div className="table-container">
                    <table className='report__table'>
                        <thead className="report__table__header">
                        <tr className="report__table__row">
                            <th>{translations?.['covenants.loan_covenants.table_headers.schedule']}</th>
                            <th>{translations?.['covenants.loan_covenants.table_headers.para_no']}</th>
                            <th>{translations?.['covenants.loan_covenants.table_headers.description']}</th>
                            <th>{translations?.['covenants.loan_covenants.table_headers.due_date']}</th>
                            <th>{translations?.['covenants.loan_covenants.table_headers.compiled_date']}</th>
                            <th>{translations?.['covenants.loan_covenants.tooltips.delays_months']}</th>
                            <th>{translations?.['covenants.loan_covenants.table_headers.compiled']}</th>
                            <th>{translations?.['covenants.loan_covenants.table_headers.status_as_of']}</th>
                            <th style={{padding: 0, width: 0}}></th>
                        </tr>
                        </thead>
                        <tbody className="report__table__body">
                        {covenantsData?.map(item => (
                            <React.Fragment key={item.id}>
                                {!(isOpenEdit.show && isOpenEdit.id === item.id) ?
                                    <tr className="report__table__row">
                                        <td>{item.schedule}</td>
                                        <td>{item.para_no}</td>
                                        <td>{parseHtml(item.description)}</td>
                                        <td>{item.due_date ? moment(item.due_date, "YYYY-MM-DD").format("DD/MM/YYYY"): ''}</td>
                                        <td>{item.compiled_date ? moment(item.compiled_date, "YYYY-MM-DD").format("DD/MM/YYYY") : ''}</td>
                                        <td>{item.delays}</td>
                                        <td>{item.compiled}</td>
                                        <td>{item.status_as_of}</td>
                                        {!isReportSubmitted &&
                                            <td style={{padding: 0, width: 0}}>
                                                <div className="editReport__table__settingsButtons">
                                                    <button className="editReport__table__deleteBtn"
                                                            onClick={() => deleteCovenants(item.id)}>
                                                        {translations?.['financing_plan.index.actions.delete']}
                                                    </button>
                                                    <button
                                                        className="editReport__table__updateBtn"
                                                        onClick={() => setIsOpenEdit({show: true, id: item.id})}>
                                                        {translations?.['financing_plan.index.actions.update']}
                                                    </button>
                                                </div>
                                            </td>
                                        }
                                    </tr> :
                                    <ItemEdit
                                        type={covenantsType}
                                        headerRef={headerRef}
                                        itemData={covenantsData?.find(covenant => covenant.id === isOpenEdit.id)}
                                        reportId={reportId}
                                        setIsOpenEdit={setIsOpenEdit}
                                        getItems={updateCovenants}
                                        cancelTranslation={translations?.['financing_plan.index.actions.cancel']}
                                        saveTranslation={translations?.['financing_plan.index.actions.save']}
                                        useCreateItemMutation={useCreateCovenantsMutation}
                                        useUpdateItemMutation={useUpdateCovenantsMutation}
                                        updateById
                                        fields={['schedule', 'para_no', 'description', 'due_date', 'compiled_date', 'delays', 'compiled', 'status_as_of']}
                                        cellWidths={cellWidths}
                                        isShowLabel={false}
                                        htmlEditFieldKeys={['description']}
                                        placeHolders={[
                                            translations?.['index.index.placeholder.text'],
                                            translations?.['index.index.placeholder.number'],
                                            translations?.['index.index.placeholder.text'],
                                            translations?.['index.index.placeholder.choose_date'],
                                            translations?.['index.index.placeholder.choose_date'],
                                            translations?.['index.index.placeholder.number'],
                                            translations?.['index.index.placeholder.number'],
                                            translations?.['index.index.placeholder.text'],
                                            translations?.['index.index.placeholder.text'],
                                        ]}
                                    />
                                }
                            </React.Fragment>
                        ))}
                        </tbody>
                        {isOpenToAdd && (
                            <ItemEdit
                                type={covenantsType}
                                headerRef={headerRef}
                                reportId={reportId}
                                setIsOpenEdit={setIsOpenToAdd}
                                getItems={updateCovenants}
                                cancelTranslation={translations?.['financing_plan.index.actions.cancel']}
                                saveTranslation={translations?.['financing_plan.index.actions.save']}
                                useCreateItemMutation={useCreateCovenantsMutation}
                                useUpdateItemMutation={useUpdateCovenantsMutation}
                                updateById
                                fields={['schedule', 'para_no', 'description', 'due_date', 'compiled_date', 'delays', 'compiled', 'status_as_of']}
                                cellWidths={cellWidths}
                                isShowLabel={false}
                                htmlEditFieldKeys={['description']}
                                placeHolders={[
                                    translations?.['index.index.placeholder.text'],
                                    translations?.['index.index.placeholder.number'],
                                    translations?.['index.index.placeholder.text'],
                                    translations?.['index.index.placeholder.choose_date'],
                                    translations?.['index.index.placeholder.choose_date'],
                                    translations?.['index.index.placeholder.number'],
                                    translations?.['index.index.placeholder.text'],
                                    translations?.['index.index.placeholder.text'],
                                ]}
                            />
                        )}
                    </table>
                </div>
            </>}
        </div>
    );
};

export default OtherCovenants;
