import React from "react";
import Select from "react-select";
import moment from "moment/moment";
import 'moment/locale/ru'  // without this line it didn't work
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {errorHandle} from "../../global/errorHandle";
import { setLoader } from "../../store/slice/dashboard";
import {
    useLazyGetAllCompaniesQuery,
    useLazyGetAllCountriesQuery
} from "../../store/service/adbService";
import CustomDatePicker from "../../components/CustomDatePicker";

const customStyles = {
    menu: (provided, state = false) => ({
        ...provided,
        width: "100%",
        color: state.selectProps.menuColor,
        cursor: "pointer",
    }),
    placeholder: (defaultStyles) => ({
        ...defaultStyles,
        color: "#8A909D",
    }),
    control: (_, { selectProps: {  } }) => ({
        width: "100%",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        display: "flex",
        marginTop: 8,
        background: "#F9F9FB",
        borderRadius: 8,
        padding: 2,
        cursor: "pointer",
    }),
};


const CreateQReportStep1 = ({pageTranslations}) => {
    const localeLan = localStorage.getItem("local_lan" );
    moment.locale(localeLan)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const enterData = JSON.parse(localStorage.getItem("create-report-data"));
    const  reportData = JSON.parse(localStorage.getItem("edit-report-data"));
    const [state, setState] = useState({
        report_name: enterData?.report_name ?? reportData?.report_details?.report_name ?? "",
        country: enterData?.country?.value ?? reportData?.report_details?.country_id ?? null,
        loan_grant_number: enterData?.loan_grant_number ?? reportData?.report_details?.loan_grant_number ?? null,
        implementing_agency: enterData?.implementing_agency ?? reportData?.report_details?.implementing_agency_id ?? "",
        report_number: enterData?.report_number ?? reportData?.report_details?.report_number ?? null,
    });
    const [countries, setCountries] = useState([]);
    const [companies, setCompanies] = useState([]);

    const [reportDate, setReportDate] = useState(
        enterData?.report_date ?? reportData?.report_details?.report_date ?? null
    );
    const [approvalDate, setApprovalDate] = useState(
        enterData?.approval_date ?? reportData?.report_details?.approval_date ?? null
    );
    const [signingDate, setSigningDate] = useState(
        enterData?.signing_date ?? reportData?.report_details?.signing_date ?? null
    );
    const [commencementDate, setCommencementDate] = useState(
        enterData?.commencement_effective_date ?? reportData?.report_details?.commencement_effective_date ?? null
    );
    const [closingDate, setClosingDate] = useState(
        enterData?.closing_date ?? reportData?.report_details?.closing_date ?? null
    );
    const [physicalCompletionDate, setPhysicalCompletionDate] = useState(
        enterData?.physical_completion_date ?? reportData?.report_details?.physical_completion_date ?? null
    );


    const [getCountries, {
        data: allCountries,
        isLoading: countriesIsLoading,
        error: countriesError,
        isError: countriesIsError,
    }] = useLazyGetAllCountriesQuery();

    const [getAllCompanies, {
        data: allCompanies,
        isLoading: companiesIsLoading,
        error: companiesError,
        isError: companiesIsError,
    }]= useLazyGetAllCompaniesQuery();

    const selectValues = (data, setter) => {
        if (data) {
            let selectData = data.map((item) => ({
                value: item.id,
                label: item.name,
            }));
            setter(selectData);
        }
    };

    const inputHandler = (e) => {
        setState((old) => ({ ...old, [e.target.id]: e.target.value }));
    };

    useEffect(() => {
        getCountries({
            per_page: 10,
        })
        getAllCompanies({
            per_page: 10,
        })
    }, [])

    useEffect(() => {
        if (countriesIsError){
            errorHandle(countriesError);
        }
        if (companiesIsError){
            errorHandle(companiesError)
        }
    }, [countriesError, countriesIsError, companiesIsError])

    useEffect(() => {
        selectValues(allCountries?.data?.data, setCountries);
    }, [allCountries]);

    useEffect(() => {
        let selectData = allCompanies?.data?.data.map((item) => ({
            value: item.id,
            label: item.long_name,
        }));
        setCompanies(selectData);
    }, [allCompanies]);

    useEffect(() => {
        countriesIsLoading || companiesIsLoading
            ? dispatch(setLoader(true))
            : dispatch(setLoader(false));
    }, [countriesIsLoading, companiesIsLoading]);


    const backToPrevPage = () => {
        if (location?.pathname === "/edit-quarterly-data/step-1") {
            navigate(`/edit-quarterly-report/${reportData?.current_report_id}`);
            localStorage.removeItem("edit-report-data");
        } else {
            localStorage.removeItem("create-report-data");
            navigate("/quarterly-reports");
        }
    }

    const submitHandler = (e) => {
        e.preventDefault();
        let body = {
            report_name: state?.report_name,
            country_id: state?.country,
            loan_grant_number: state?.loan_grant_number,
            implementing_agency_id: state?.implementing_agency,
            report_number: state?.report_number,
            report_date: moment(reportDate, "DD MMM, YYYY").format('YYYY-MM-DD'),
            approval_date: moment(approvalDate, "DD MMM, YYYY").format('YYYY-MM-DD'),
            signing_date: moment(signingDate, "DD MMM, YYYY").format('YYYY-MM-DD'),
            commencement_effective_date: moment(commencementDate, "DD MMM, YYYY").format('YYYY-MM-DD'),
            closing_date: moment(closingDate, "DD MMM, YYYY").format('YYYY-MM-DD'),
            physical_completion_date: moment(physicalCompletionDate, "DD MMM, YYYY").format('YYYY-MM-DD'),
        };

        localStorage.setItem("create-report-data", JSON.stringify(body));
        location.pathname === "/edit-quarterly-data/step-1" ?
            navigate("/edit-quarterly-data/step-2") :
            navigate("/create-quarterly-report/step-2");

    };

    return (
        <form className="create__form">
            <div className="create__form-content">
                <div className="create__form-row">
                    <div className="create__form-label">{pageTranslations?.['report_details.titles.report_name']}</div>
                    <div className="create__form-input">
                        <input
                            type="text"
                            placeholder={pageTranslations?.['index.placeholder.text']}
                            id="report_name"
                            defaultValue={state?.report_name}
                            onChange={inputHandler}
                            maxLength={190}
                        />
                    </div>
                </div>
                <div className="create__form-row">
                    <div className="create__form-label">{pageTranslations?.['report_details.titles.country']}</div>
                    <div className="create__form-input">
                        <Select
                            placeholder={pageTranslations?.['index.placeholder.select']}
                            styles={({...customStyles})}
                            options={countries}
                            key={countries[0]?.id}
                            value={countries?.filter(option => option.value === state?.country)[0]}
                            onChange={(selected) =>
                                setState((old) => ({ ...old, country: selected.value }))
                            }
                        />
                    </div>
                </div>
                <div className="create__form-row">
                    <div className="create__form-label">{pageTranslations?.['report_details.titles.loan_grant_number']}</div>
                    <div className="create__form-input">
                        <input
                            placeholder={pageTranslations?.['index.placeholder.number']}
                            type="text"
                            id="loan_grant_number"
                            defaultValue={state?.loan_grant_number ? state?.loan_grant_number : ''}
                            onChange={inputHandler}
                            maxLength={190}
                        />
                    </div>
                </div>
                <div className="create__form-row">
                    <div className="create__form-label">{pageTranslations?.['report_details.titles.implementing_agency']}</div>
                    <div className="create__form-input">
                        <Select
                            placeholder={pageTranslations?.['index.placeholder.select']}
                            styles={({...customStyles})}
                            options={companies}
                            value={companies?.filter(option => option.value === state?.implementing_agency)[0]}
                            onChange={(selected) =>
                                setState((old) => ({ ...old, implementing_agency: selected.value }))
                            }
                        />
                    </div>
                </div>
                <div className="create__form-row">
                    <div className="create__form-label">{pageTranslations?.['report_details.titles.report_number']}</div>
                    <div className="create__form-input">
                        <input
                            placeholder={pageTranslations?.['index.placeholder.number']}
                            id="report_number"
                            defaultValue={state?.report_number ? state?.report_number  : ''}
                            onChange={inputHandler}
                            maxLength={190}
                        />
                    </div>
                </div>
                <div className="create__form-row">
                    <div className="create__form-label">{pageTranslations?.['report_details.titles.report_date']}</div>
                    <div className="create__form-input calendar">
                        <CustomDatePicker
                            autoComplete="off"
                            id="report_date"
                            dateFormat="dd MMM, yyyy"
                            selected={
                                reportDate && moment(reportDate, "DD MMM, YYYY").isValid()
                                    ? moment(reportDate, "DD MMM, YYYY").toDate()
                                    : null
                            }
                            onChange={(date) => setReportDate(moment(date).format("DD MMM, YYYY"))}
                        />
                        <label htmlFor="report_date">
                            {reportDate && moment(reportDate, "DD MMM, YYYY").isValid()
                                ? moment(reportDate, "DD MMM, YYYY").format("DD MMM, YYYY")
                                : <span>{pageTranslations?.['index.placeholder.choose_date']}</span>}
                        </label>
                        <label htmlFor="report_date" className={'calendar-icon'}>
                            <img src="/assets/icons/calendar.svg" alt="calendar"/>
                        </label>
                    </div>
                </div>
                <div className="create__form-row">
                    <div className="create__form-label">{pageTranslations?.['report_details.titles.approval_date']}</div>
                    <div className="create__form-input calendar">
                        <CustomDatePicker
                            autoComplete="off"
                            id="approval_date"
                            dateFormat="dd MMM, yyyy"
                            selected={
                                approvalDate && moment(approvalDate, "dd MMM, yyyy", true).isValid()
                                    ? new Date(approvalDate)
                                    : null
                            }
                            onChange={(date) => setApprovalDate(date)}
                        />
                        <label htmlFor="approval_date">
                            {approvalDate && moment(approvalDate, "DD MMM, YYYY").isValid()
                                ? moment(approvalDate, "DD MMM, YYYY").format("DD MMM, YYYY")
                                : <span>{pageTranslations?.['index.placeholder.choose_date']}</span>}
                        </label>
                        <label htmlFor="approval_date" className={'calendar-icon'}>
                            <img src="/assets/icons/calendar.svg" alt="calendar"/>
                        </label>
                    </div>
                </div>
                <div className="create__form-row">
                    <div className="create__form-label">{pageTranslations?.['report_details.titles.signing_date']}</div>
                    <div className="create__form-input calendar">
                        <CustomDatePicker
                            autoComplete="off"
                            id="signing_date"
                            dateFormat="dd MMM, yyyy"
                            selected={
                                signingDate && moment(signingDate, "dd MMM, yyyy", true).isValid()
                                    ? new Date(signingDate)
                                    : null
                            }
                            onChange={(date) => setSigningDate(date)}
                        />
                        <label htmlFor="signing_date">
                            {signingDate && moment(signingDate, "DD MMM, YYYY").isValid()
                                ? moment(signingDate, "DD MMM, YYYY").format("DD MMM, YYYY")
                                : <span>{pageTranslations?.['index.placeholder.choose_date']}</span>}
                        </label>
                        <label htmlFor="signing_date" className={'calendar-icon'}>
                            <img src="/assets/icons/calendar.svg" alt="calendar" />
                        </label>
                    </div>
                </div>
                <div className="create__form-row">
                    <div className="create__form-label">{pageTranslations?.['report_details.titles.commencement_effective_date']}</div>
                    <div className="create__form-input calendar">
                        <CustomDatePicker
                            autoComplete="off"
                            id="commencement_date"
                            dateFormat="dd MMM, yyyy"
                            selected={
                                commencementDate && moment(commencementDate, "dd MMM, yyyy", true).isValid()
                                    ? new Date(commencementDate)
                                    : null
                            }
                            onChange={(date) => setCommencementDate(date)}
                        />
                        <label htmlFor="commencement_date">
                            {commencementDate && moment(commencementDate, "DD MMM, YYYY").isValid()
                                ? moment(commencementDate, "DD MMM, YYYY").format("DD MMM, YYYY")
                                : <span>{pageTranslations?.['index.placeholder.choose_date']}</span>}
                        </label>
                        <label htmlFor="commencement_date" className="calendar-icon">
                            <img src="/assets/icons/calendar.svg" alt="calendar"/>
                        </label>
                    </div>
                </div>
                <div className="create__form-row">
                    <div className="create__form-label">{pageTranslations?.['report_details.titles.current_closing_date']}</div>
                    <div className="create__form-input calendar">
                        <CustomDatePicker
                            portalId="root-portal"
                            autoComplete="off"
                            id="closing_date"
                            dateFormat="dd MMM, yyyy"
                            selected={
                                closingDate && moment(closingDate, "dd MMM, yyyy", true).isValid()
                                    ? new Date(closingDate)
                                    : null
                            }
                            onChange={(date) => setClosingDate(date)}
                            />
                        <label htmlFor="closing_date">
                            {closingDate && moment(closingDate, "DD MMM, YYYY").isValid()
                                ? moment(closingDate, "DD MMM, YYYY").format("DD MMM, YYYY")
                                : <span>{pageTranslations?.['index.placeholder.choose_date']}</span>}
                        </label>
                        <label htmlFor="closing_date" className={'calendar-icon'}>
                            <img src="/assets/icons/calendar.svg" alt="calendar" />
                        </label>
                    </div>
                </div>
                <div className="create__form-row">
                    <div className="create__form-label">{pageTranslations?.['report_details.titles.physical_completion_date']}</div>
                    <div className="create__form-input calendar">
                        <CustomDatePicker
                            portalId="root-portal"
                            autoComplete="off"
                            id="physical_completion_date"
                            dateFormat="dd MMM, yyyy"
                            selected={
                                physicalCompletionDate && moment(physicalCompletionDate, "dd MMM, yyyy", true).isValid()
                                    ? new Date(physicalCompletionDate)
                                    : null
                            }
                            onChange={(date) => setPhysicalCompletionDate(date)}
                        />
                        <label htmlFor="physical_completion_date">
                            {physicalCompletionDate && moment(physicalCompletionDate, "DD MMM, YYYY").isValid()
                                ? moment(physicalCompletionDate, "DD MMM, YYYY").format("DD MMM, YYYY")
                                : <span>{pageTranslations?.['index.placeholder.choose_date']}</span>}
                        </label>
                        <label htmlFor="physical_completion_date" className="calendar-icon">
                            <img src="/assets/icons/calendar.svg" alt="calendar" />
                        </label>
                    </div>
                </div>
            </div>
            <div className="create__form-br"></div>
            <div className="create__form-btns">
                <div
                    className="create__form-back"
                    onClick={backToPrevPage}
                >
                    {pageTranslations?.['report_details.actions.cancel']}
                </div>
                <button onClick={submitHandler} className="create__form-next">
                    <span>{pageTranslations?.['included_projects.actions.next']}</span>
                    <span>
                        <img src="/assets/icons/next-icon.svg" alt="icon" />
                    </span>
                </button>
            </div>
        </form>
    );
};


export default CreateQReportStep1;
