import React from 'react';
import {useSelector} from "react-redux";

function QuarterlyDevelopmentObjectives({pageTranslations}) {
	const sections_title = useSelector(state => state?.quarterlyReducer?.sections_title) ?? null;
	const development_objectives = useSelector(state => state?.quarterlyReducer?.online_view?.sections?.development_objectives) ?? null;
	
	if (!development_objectives) {
		return <div></div>
	}
	
	return (
			<div className="online__content-projectRow">
				<p className="online__content-boldTitle">4. {pageTranslations?.['development_objectives.index.headers.development_objectives']}</p>
				<p>4.1 {pageTranslations?.['development_objectives.development_impact.headers.development_impact']}</p>
				<div className="quarterly-online-table">
					<table>
						<thead>
						<tr>
							<th colSpan={2} style={{textAlign: 'left'}}>{
								pageTranslations?.['development_objectives.development_impact.headers.impact_of_project_as_aligned']
							}</th>
						</tr>
						</thead>
						<tbody>
						<tr className={"row_gray"}>
							<td style={{width: '50%'}}>{pageTranslations?.['development_objectives.development_impact.titles.current_project']}</td>
							<td style={{width: '50%'}}>{pageTranslations?.['development_objectives.development_impact.titles.overall_project']}</td>
						</tr>
						<tr>
							<td style={{width: 'max-content'}}
							    dangerouslySetInnerHTML={{__html: development_objectives?.development_impact?.impact_of_project_as_aligned?.current_project}}/>
							<td style={{width: 'max-content',}}
							    dangerouslySetInnerHTML={{__html: development_objectives?.development_impact?.impact_of_project_as_aligned?.overall_project}}/>
						</tr>
						</tbody>
					</table>
				</div>
				<p>4.2 {pageTranslations?.['development_objectives.development_outcome.headers.development_outcome']}</p>
				<div className="quarterly-online-table">
					<table>
						<thead>
						<tr className={"row_gray"}>
							<th style={{
								textAlign: 'left',
								width: '50%'
							}}>{pageTranslations?.['development_objectives.development_outcome.titles.description']}</th>
							<th style={{
								textAlign: 'left',
								width: '50%'
							}}>{pageTranslations?.['development_objectives.development_outcome.titles.assessment_of_current_status']}</th>
						</tr>
						</thead>
						<tbody>
						<tr>
							<td style={{textAlign: ' left'}}
							    colSpan={2}>{pageTranslations?.['development_objectives.development_outcome.headers.outcomes']}</td>
						</tr>
						<tr>
							<td style={{textAlign: ' left'}}
							    dangerouslySetInnerHTML={{__html: development_objectives?.development_outcome?.outcomes?.description}}/>
							<td style={{textAlign: ' left'}}
							    dangerouslySetInnerHTML={{__html: development_objectives?.development_outcome?.outcomes?.assessment_of_current_status}}/>
						</tr>
						<tr>
							<td style={{textAlign: ' left'}}
							    colSpan={2}>{pageTranslations?.['development_objectives.development_outcome.headers.performance_targets']}</td>
						</tr>
						<tr>
							<td style={{textAlign: ' left'}}
							    dangerouslySetInnerHTML={{__html: development_objectives?.development_outcome?.performance_targets?.description}}/>
							<td style={{textAlign: ' left'}}
							    dangerouslySetInnerHTML={{__html: development_objectives?.development_outcome?.performance_targets?.assessment_of_current_status}}/>
						</tr>
						<tr>
							<td style={{textAlign: ' left'}}
							    colSpan={2}>{pageTranslations?.['development_objectives.development_outcome.headers.risks']}</td>
						</tr>
						<tr>
							<td style={{textAlign: ' left'}}
							    dangerouslySetInnerHTML={{__html: development_objectives?.development_outcome?.risks?.description}}/>
							<td style={{textAlign: ' left'}}
							    dangerouslySetInnerHTML={{__html: development_objectives?.development_outcome?.risks?.assessment_of_current_status}}/>
						</tr>
						</tbody>
					</table>
				</div>
				<p>4.3 {pageTranslations?.['development_objectives.overall_rating.headers.overall_rating']}</p>
				<div className="quarterly-online-table">
					<table style={{marginTop: 20}}>
						<thead>
						<tr className={"row_gray"}>
							<th style={{textAlign: 'left'}}>{pageTranslations?.['development_objectives.development_outcome.titles.description']}</th>
						</tr>
						</thead>
						<tbody>
						<tr>
							<td style={{textAlign: 'left'}} dangerouslySetInnerHTML={{__html: development_objectives?.overall_rating?.description}} />
						</tr>
						</tbody>
					</table>
				</div>
			</div>
	);
}

export default QuarterlyDevelopmentObjectives;
