import React, {useMemo} from 'react';
import {Link, useLocation} from "react-router-dom";
import './index.scss'
import {formatString} from "../../global/formatString";

const Breadcrumbs = (urlState) => {
    const location = useLocation();

    const pathNames = location.pathname.split('/').filter(x => x);
    const lastPathname = useMemo(() => decodeURIComponent(location.pathname.split('/').pop()), [location.pathname]);


    return (
        <div className="breadcrumbs">
            <nav>
                <div className="breadcrumbs__items">
                    {pathNames.map((name, index) => {
                        const routeTo = `/${pathNames.slice(0, index + 1).join('/')}`;
                        return (
                            <div key={name} className={`breadcrumbs__items__item ${lastPathname === decodeURIComponent(name) ? 'breadcrumbs__items__active' : ''}`}>
                                <Link to={routeTo} state={urlState}>
                                    {formatString(decodeURIComponent(name), '-')}
                                </Link>
                                {index < pathNames.length - 1 &&
                                    (<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g>
                                            <path d="M8.5 5.7998L15.5 12.7998L8.5 19.7998" stroke="#767676" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </g>
                                    </svg>)
                                }
                            </div>
                        );
                    })}
                </div>
            </nav>

        </div>
    );
};

export default Breadcrumbs;
