import moment from "moment";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
    useCreateEngineeringDesignDocumentsMutation,
    useDeleteEngineeringDesignDocumentsMutation,
    useLazyGetAllCompaniesQuery,
    useUpdateEngineeringDesignDocumentsMutation,
} from "../../../store/service/adbService";
import { setLoader } from "../../../store/slice/dashboard";
import { Switch } from "../../../UI/Switch";
import { DeleteModal } from "../DeleteModal";
import MoreInfoModal from "../../MoreInfoModal";
import CustomDatePicker from "../../CustomDatePicker";
export const EngineeringDesignDocuments = ({
                                               documentsData,
                                               glossaryData,
                                               refEngineering,
                                               translations
                                           }) => {
    const dispatch = useDispatch();
    const localeLan = localStorage.getItem("local_lan" );
    moment.locale(localeLan);
    const [data, setData] = useState([]);
    const [show, setShow] = useState(true);
    const [currentItem, setCurrentItem] = useState(null);
    const [newItem, setNewItem] = useState(false);
    const [editable, setEditable] = useState(false);
    const [deleteModal, setDeleteModal] = useState(null);
    const user = useSelector((state) => state?.adbReducer?.user);
    const [moreInfo, setMoreInfo] = useState(null);
    const [tableHeaders, setTableHeaders] = useState([]);
    const [infoModal, setInfoModal] = useState(false);
    const [currentRow, setCurrentRow] = useState(null);
    const scrollRef = useRef(null);
    const expandedRef = useRef(null);
    const [expandedDesign, setExpandedDesign] = useState(false);
    const [expandedApprove, setExpandedApprove] = useState(false);
    const [expandedApproveStage, setExpandedApproveStage] = useState(false);
    const [expandedCommend, setExpandedCommend] = useState(false);
    const expandedCommentRef = useRef(null);

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                let array = data?.map((item) => {
                    item.edit = false;
                    return item;
                });
                setEditable(false);
                setCurrentItem(null);
                setData(array);
                if (newItem) {
                    setNewItem(false);
                    let s = data?.filter((el, i) => i !== data.length - 1);
                    setData(s);
                }
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, [data]);

    const [
        createEngineeringDesignDocuments,
        {
            isError: createEngineeringDesignDocumentsIsError,
            isLoading: createEngineeringDesignDocumentsIsLoading,
            isSuccess: createEngineeringDesignDocumentsIsSuccess,
        },
    ] = useCreateEngineeringDesignDocumentsMutation();

    const [
        updateEngineeringDesignDocuments,
        {
            isError: updateEngineeringDesignDocumentsIsError,
            isLoading: updateEngineeringDesignDocumentsIsLoading,
            isSuccess: updateEngineeringDesignDocumentsIsSuccess,
        },
    ] = useUpdateEngineeringDesignDocumentsMutation();

    const [
        deleteEngineeringDesignDocuments,
        {
            isError: deleteEngineeringDesignDocumentsIsError,
            isLoading: deleteEngineeringDesignDocumentsIsLoading,
            isSuccess: deleteEngineeringDesignDocumentsIsSuccess,
        },
    ] = useDeleteEngineeringDesignDocumentsMutation();

    useEffect(() => {
        if (documentsData?.engineering_design_documents) {
            let data = documentsData?.engineering_design_documents?.map((item) => ({
                id: item?.id,
                design_deliverable: item?.design_deliverable,
                planned_approval_date: item?.planned_approval_date,
                actual_approval_date: item?.actual_approval_date,
                approval_entity: item?.approval_entity,
                approval_stage: item?.approval_stage,
                comment: item?.comment,
                completed: item?.completed,

                name_approver: item?.name_approver,
                description: item?.description,
                invoiced: item?.invoiced,
                percent_complete: item?.percent_complete,
                project_id: documentsData?.id,
                edit: false,
                submitted: item?.submitted,
                old_report: item?.old_report,
                submitted_date: item?.submitted_date,
            }));
            setEditable(false);
            setData(data);
        }
    }, [
        documentsData?.engineering_design_documents,
        createEngineeringDesignDocumentsIsError,
        updateEngineeringDesignDocumentsIsError,
        deleteEngineeringDesignDocumentsIsError,
    ]);


    const [
        getCompanies,
    ] = useLazyGetAllCompaniesQuery();

    useEffect(() => {
        getCompanies({
            per_page: 1000,
        });
    }, []);

    useEffect(() => {
        if (documentsData?.responsible_party) {
            let data = documentsData?.responsible_party?.map((item) => {
                return {
                    value: item?.id,
                    label: item?.short_name,
                };
            });
        }
    }, [documentsData]);

    useEffect(() => {
        if(translations){
            const headers = [
                {
                    tooltip_name: translations?.['engineering_design_documents.engineering.table_headers.design_deliverable'],
                    tooltip_text: translations?.['engineering_design_documents.engineering.tooltips.design_deliverable'],
                },
                {
                    tooltip_name: translations?.['engineering_design_documents.engineering.table_headers.planned_approval_date'],
                    tooltip_text: translations?.['engineering_design_documents.engineering.tooltips.planned_approval_date'],
                },
                {
                    tooltip_name: translations?.['engineering_design_documents.engineering.table_headers.actual_approval_date'],
                    tooltip_text: translations?.['engineering_design_documents.engineering.tooltips.actual_approval_date'],
                },
                {
                    tooltip_name: translations?.['engineering_design_documents.engineering.table_headers.approval_entity'],
                    tooltip_text: translations?.['engineering_design_documents.engineering.tooltips.approval_entity'],
                },
                {
                    tooltip_name: translations?.['engineering_design_documents.engineering.table_headers.approval_stage'],
                    tooltip_text: translations?.['engineering_design_documents.engineering.tooltips.approval_stage'],
                },
                {
                    tooltip_name: translations?.['engineering_design_documents.engineering.table_headers.current_status_or_comments'],
                    tooltip_text: translations?.['engineering_design_documents.engineering.tooltips.current_status_or_comments'],
                },
                {
                    tooltip_name: translations?.['engineering_design_documents.engineering.table_headers.completed'],
                    tooltip_text: translations?.['engineering_design_documents.engineering.tooltips.completed'],
                },
            ]

            setTableHeaders(
                headers.map((item) => ({
                    ...item,
                    showTooltip: false,
                }))
            );
        }
    }, [translations])

    useEffect(() => {
        if (glossaryData?.data?.length) {
            setMoreInfo(
                glossaryData?.data?.filter(
                    (el) => el?.section_name === "Engineering / Design Documents"
                )[0]
            );
        }
        setData((old) => {
            let data = old.map((item) => ({ ...item, edit: false }));
            return data;
        });
        setEditable(false);
    }, [
        createEngineeringDesignDocumentsIsSuccess,
        updateEngineeringDesignDocumentsIsSuccess,
        deleteEngineeringDesignDocumentsIsSuccess,
        glossaryData,
    ]);

    useEffect(() => {
        const d = () => {
            dispatch(setLoader(false));
        };

        if (
            createEngineeringDesignDocumentsIsError ||
            updateEngineeringDesignDocumentsIsError ||
            deleteEngineeringDesignDocumentsIsError
        ) {
            setCurrentItem(null);
            setEditable(null);
        }

        createEngineeringDesignDocumentsIsLoading ||
        updateEngineeringDesignDocumentsIsLoading ||
        deleteEngineeringDesignDocumentsIsLoading
            ? dispatch(setLoader(true))
            : d();
    }, [
        createEngineeringDesignDocumentsIsLoading,
        updateEngineeringDesignDocumentsIsLoading,
        deleteEngineeringDesignDocumentsIsLoading,
        createEngineeringDesignDocumentsIsError,
        updateEngineeringDesignDocumentsIsError,
        deleteEngineeringDesignDocumentsIsError,
    ]);

    const setterData = (input) => {
        const body = {
            design_deliverable: input?.design_deliverable,
            planned_approval_date: input?.planned_approval_date ? moment(input?.planned_approval_date).format('YYYY-MM-DD') : '',
            actual_approval_date: input?.actual_approval_date ? moment(input?.actual_approval_date).format('YYYY-MM-DD') : '',
            approval_entity: input?.approval_entity,
            approval_stage: input?.approval_stage,
            project_id: documentsData?.id,
        };

        let s = [];

        if (!currentItem?.completed && body?.actual_approval_date === null) {
            body.actual_approval_date = '  ';
        }

        for (let key in body) {
            if (!body[key] || body[key] === "Invalid date" || body[key] === "") {
                toast.error("Please enter " + key?.split("_")?.join(" "), {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
                s.push("false");
            }
        }

        if (s.length === 0) {
            return body;
        } else {
            return null;
        }
    };

    const submitHandler = (e, item) => {
        e.preventDefault();
        if (newItem) {
            const dataCreated = setterData(currentItem);
            if (dataCreated) {
                createEngineeringDesignDocuments({
                    ...dataCreated,
                    comment: currentItem?.comment,
                    completed: currentItem?.completed,
                });
                setCurrentItem(null);
                setNewItem(false);
                setCurrentRow(null);
            }
        } else {
            const dataUpdated = setterData(currentItem);
            if (dataUpdated) {
                updateEngineeringDesignDocuments({
                    id: item?.id,
                    body: {
                        ...dataUpdated,
                        comment: currentItem?.comment,
                        completed: currentItem?.completed,
                    },
                });
                setCurrentItem(null);
                setNewItem(false);
                setCurrentRow(null);
            }
        }
    };

    return (
        <div ref={refEngineering} className="project__white-box">
            <div className="project__goods">
                {infoModal && (
                    <MoreInfoModal
                        title={translations?.['engineering_design_documents.engineering_design_documents.headers.engineering_design_documents']}
                        moreInfo={translations?.['engineering_design_documents.engineering_design_documents.more_info.engineering_design_documents']}
                        close={()=> setInfoModal(false)}
                    />
                )}

                {deleteModal?.show && (
                    <DeleteModal
                        id={deleteModal?.id}
                        action={deleteEngineeringDesignDocuments}
                        setDeleteModal={setDeleteModal}
                        cencelText={translations?.['main.main.actions.cancel']}
                        deleteText={translations?.['main.main.actions.delete']}
                        modalTitle={translations?.['main.main.headers.confirm_your_action']}
                        clarifyingText={translations?.['main.main.titles.you_are_going_to_delete_this_row']}
                    />
                )}
                <div className={`project__block ${show ? "" : "close"}`}>
                    <div className="d-f">
                        <div
                            className="project__block-title"
                            onClick={() => setShow(!show)}
                        >
                            <span>{translations?.['engineering_design_documents.engineering_design_documents.headers.engineering_design_documents']}</span>
                        </div>
                        <span
                            className="project__block-more"
                            onClick={() => setInfoModal(!infoModal)}
                        >
                            <span className="img">
                                <img src="/assets/icons/info.svg" alt="info" />
                            </span>
                        <span className="text">{translations?.['engineering_design_documents.engineering_design_documents.titles.more_info']}</span>
            </span>
                    </div>
                    <div className="project__block-subtitles">
                        <div className="project__block-subtitle">{translations?.['engineering_design_documents.engineering.headers.engineering']}</div>
                        {user?.permissions?.edit_project && (
                            <div
                                onClick={() => {
                                    let randomId = Math.floor(Math.random() * 1000);
                                    setCurrentRow(randomId);
                                    scrollRef !== null && scrollRef.current.scrollIntoView();
                                    if (!newItem) {
                                        setNewItem(true);
                                        setEditable(true);
                                        setData((old) => [
                                            ...old,
                                            {
                                                id: randomId,
                                                design_deliverable: '',
                                                planned_approval_date: null,
                                                actual_approval_date: null,
                                                approval_entity: '',
                                                approval_stage: '',
                                                comment: '',
                                                completed: false,

                                                description: "",
                                                name_approver: "",
                                                invoiced: 0,
                                                percent_complete: null,
                                                edit: true,
                                            },
                                        ]);
                                        setCurrentItem({
                                            id: randomId,
                                            design_deliverable: '',
                                            planned_approval_date: null,
                                            actual_approval_date: null,
                                            approval_entity: '',
                                            approval_stage: '',
                                            comment: '',
                                            completed: false,

                                            description: "",
                                            name_approver: "",
                                            invoiced: 0,
                                            percent_complete: null,
                                            edit: true,
                                        });
                                    }
                                }}
                                className="project__block-add-row"
                            >
                <span>
                  <img src="/assets/icons/blue-plus.svg" alt="plus" />
                </span>
                                <span>{translations?.['engineering_design_documents.engineering.actions.add_row']}</span>
                            </div>
                        )}
                    </div>
                    <div
                        className={`project__block-content permits ${
                            editable ? "editable" : ""
                        }`}
                    >
                        <div
                            className={`project__block-table project-table ${
                                currentItem ? "n" : ""
                            }`}
                        >
                            <div className={`table__header`} ref={scrollRef} style={{gridTemplateColumns: '15% 13% 13% 15% 15% 15% 14%'}}>
                                {tableHeaders?.map((el, idx) => {
                                    return (
                                        <div
                                            key={idx}
                                            onMouseEnter={(event) => {
                                                setTableHeaders(
                                                    tableHeaders.map((item) => ({
                                                        ...item,
                                                        showTooltip: el.tooltip_name === item.tooltip_name,
                                                    }))
                                                );
                                            }}
                                            onMouseLeave={() => {
                                                setTableHeaders(
                                                    tableHeaders.map((item) => ({
                                                        ...item,
                                                        showTooltip: false,
                                                    }))
                                                );
                                            }}
                                            className="project-table__th"
                                        >
                                            {el?.showTooltip ? (
                                                <span
                                                    className={
                                                        el?.tooltip_text?.length > 20
                                                            ? "lengthy"
                                                            : "not-lengthy"
                                                    }
                                                >
                          {el?.tooltip_text}
                        </span>
                                            ) : (
                                                ""
                                            )}
                                            {el?.tooltip_name}
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="project-table__body">
                                {data?.map((item, index) => (
                                    <div key={index}>
                                        {index !== 0 && currentRow === item?.id && (
                                            <div className={`table__header`} key={index} style={{gridTemplateColumns: '15% 13% 13% 15% 15% 15% 14%'}}>
                                                {tableHeaders?.map((el, idx) => {
                                                    return (
                                                        <div key={idx} className="project-table__th">
                                                            {el?.showTooltip ? (
                                                                <span
                                                                    className={
                                                                        el?.tooltip_text?.length > 20
                                                                            ? "lengthy"
                                                                            : "not-lengthy"
                                                                    }
                                                                >
                                  {el?.tooltip_text}
                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                            {el?.tooltip_name}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                        <form
                                            className={`table__row ${
                                                item?.edit ? "edit" : currentItem ? "n" : ""
                                            } ${
                                                Boolean(item.submitted) ||
                                                !user?.permissions?.edit_project
                                                    ? "submitted"
                                                    : ""
                                            }`}
                                            style={{gridTemplateColumns: '15% 13% 13% 15% 15% 15% 14%'}}
                                            id={item?.id}
                                            key={item?.id}
                                            onSubmit={(e) => submitHandler(e, item)}
                                        >
                                            <div className="project-table__td">
                                                <span>{item?.design_deliverable}</span>
                                                <div className="project__block-input project-table__field">
                                                   <textarea
                                                      style={{maxWidth: '250px'}}
                                                      className={`${
                                                          expandedDesign ? "project__block-expandedInput" : ""
                                                      }`}
                                                      onFocus={() => setExpandedDesign(true)}
                                                      onBlur={() => setExpandedDesign(false)}
                                                      ref={expandedRef}
                                                      maxLength={190}
                                                      defaultValue={item?.design_deliverable}
                                                      onChange={(e) => {
                                                          setCurrentItem((old) => ({
                                                              ...old,
                                                              design_deliverable: e.target.value,
                                                          }));
                                                      }}
                                                   />
                                                </div>
                                            </div>
                                            <div className="project-table__td">
                                                <span>
                                                  {moment(item?.planned_approval_date).format("DD/MM/YYYY") ===
                                                  "Invalid date" ||
                                                  !moment(item?.planned_approval_date).format("DD/MM/YYYY")
                                                      ? ""
                                                      : moment(item?.planned_approval_date).format("DD/MM/YYYY")}
                                                </span>
                                                <div className="project__block-item-calendar project-table__field">
                                                    <CustomDatePicker
                                                        portalId="root-portal"
                                                        autoComplete="off"
                                                        id="planned_approval_date"
                                                        placeholderText={translations?.['main.main.placeholder.dd_mm_yyyy']}
                                                        dateFormat="dd/MM/yyyy"
                                                        selected={
                                                            currentItem?.planned_approval_date
                                                                ? moment(currentItem?.planned_approval_date).toDate()
                                                                : ""
                                                        }
                                                        onChange={(date) => {
                                                            setCurrentItem((old) => ({
                                                                ...old,
                                                                planned_approval_date: date ? moment(date).format('YYYY-MM-DD') : '',
                                                            }));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="project-table__td">
                        <span>
                          {moment(item?.actual_approval_date).format("DD/MM/YYYY") ===
                          "Invalid date" ||
                          !moment(item?.actual_approval_date).format("DD/MM/YYYY")
                              ? ""
                              : moment(item?.actual_approval_date).format("DD/MM/YYYY")}
                        </span>
                                                <div className="project__block-item-calendar project-table__field">
                                                    <CustomDatePicker
                                                        portalId="root-portal"
                                                        autoComplete="off"
                                                        id="actual_approval_date"
                                                        placeholderText={translations?.['main.main.placeholder.dd_mm_yyyy']}
                                                        dateFormat="dd/MM/yyyy"
                                                        selected={
                                                            currentItem?.actual_approval_date
                                                                ? moment(currentItem?.actual_approval_date).toDate()
                                                                : ""
                                                        }
                                                        onChange={(date) => {
                                                            setCurrentItem((old) => ({
                                                                ...old,
                                                                actual_approval_date: date ? moment(date).format('YYYY-MM-DD') : '',
                                                            }));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="project-table__td">
                                                <span>{item?.approval_entity}</span>
                                                <div className="project__block-input project-table__field">
                          <textarea
                              style={{maxWidth: '250px'}}
                              className={`${
                                  expandedApprove ? "project__block-expandedInput" : ""
                              }`}
                              onFocus={() => setExpandedApprove(true)}
                              onBlur={() => setExpandedApprove(false)}
                              maxLength={190}
                              defaultValue={item?.approval_entity}
                              onChange={(e) => {
                                  setCurrentItem((old) => ({
                                      ...old,
                                      approval_entity: e.target.value,
                                  }));
                              }}
                          />
                                                </div>
                                            </div>
                                            <div className="project-table__td">
                                                <span>{item?.approval_stage}</span>
                                                <div className="project__block-input project-table__field">
                          <textarea
                              style={{maxWidth: '250px'}}
                              className={`${
                                  expandedApproveStage ? "project__block-expandedInput" : ""
                              }`}
                              onFocus={() => setExpandedApproveStage(true)}
                              onBlur={() => setExpandedApproveStage(false)}
                              maxLength={190}
                              defaultValue={item?.approval_stage}
                              onChange={(e) => {
                                  setCurrentItem((old) => ({
                                      ...old,
                                      approval_stage: e.target.value,
                                  }));
                              }}
                          />
                                                </div>
                                            </div>
                                            <div className="project-table__td">
                                                <span>{item?.comment}</span>
                                                <div className="project__block-input project-table__field">
                          <textarea
                              style={{maxWidth: '250px'}}
                              className={`${
                                  expandedCommend ? "project__block-expandedInput" : ""
                              }`}
                              ref={expandedCommentRef}
                              onFocus={() => setExpandedCommend(true)}
                              onBlur={() => setExpandedCommend(false)}
                              maxLength={190}
                              defaultValue={item?.comment}
                              onChange={(e) => {
                                  setCurrentItem((old) => ({
                                      ...old,
                                      comment: e.target.value,
                                  }));
                              }}
                          />
                                                </div>
                                            </div>
                                            <div className="project-table__td">
                                                <Switch
                                                    value={item?.completed}
                                                    id={index + 3445}
                                                    disabled={item?.edit ? false : true}
                                                    checked={
                                                        item?.edit ? currentItem?.completed : item?.completed
                                                    }
                                                    handler={(value) => {
                                                        setCurrentItem((old) => ({
                                                            ...old,
                                                            completed: value,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                            <div className="project__block-btns">
                                                {currentItem ? (
                                                    <div
                                                        className="project__block-cancel"
                                                        onClick={() => {
                                                            setCurrentRow(null);
                                                            let array = data?.map((item) => {
                                                                item.edit = false;
                                                                return item;
                                                            });
                                                            setCurrentItem(null);
                                                            setData(array);
                                                            if (newItem) {
                                                                setNewItem(false);
                                                                let s = data?.filter(
                                                                    (el, i) => i !== data.length - 1
                                                                );
                                                                setData(s);
                                                            }
                                                        }}
                                                    >
                                                        <img
                                                            src="/assets/icons/close-mark.svg"
                                                            alt="close"
                                                        />
                                                        {translations?.['engineering_design_documents.engineering.actions.cancel']}
                                                    </div>
                                                ) : (
                                                    <div
                                                        className="project__block-delete"
                                                        onClick={() => {
                                                            setDeleteModal({ id: item?.id, show: true });
                                                        }}
                                                    >
                                                        <img
                                                            src="/assets/icons/trash-icon.svg"
                                                            alt="arrow"
                                                        />
                                                        {translations?.['engineering_design_documents.engineering.actions.delete']}
                                                    </div>
                                                )}
                                                {currentItem ? (
                                                    <button className="project__block-save">
                                                        <img
                                                            src="/assets/icons/save-arrow.svg"
                                                            alt="arrow"
                                                        />
                                                        {translations?.['engineering_design_documents.engineering.actions.save']}
                                                    </button>
                                                ) : (
                                                    <div
                                                        onClick={() => {
                                                            setCurrentRow(item?.id);
                                                            setCurrentItem(item);
                                                            setEditable(true);
                                                            setData(() => {
                                                                let array = data?.map((elem) => {
                                                                    if (elem?.id === item?.id) {
                                                                        elem.edit = true;
                                                                    }
                                                                    return elem;
                                                                });
                                                                return array;
                                                            });
                                                        }}
                                                        className="project__block-update"
                                                    >
                                                        {translations?.['engineering_design_documents.engineering.actions.update']}
                                                    </div>
                                                )}
                                            </div>
                                        </form>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
