import React from "react";
import { useState } from "react";
import { toast } from "react-toastify";

export const SecondaryPagesModal = ({
  title,
  label,
  createHook,
  updateHook,
  setModal,
  currentItem,
}) => {
  const [value, setValue] = useState(currentItem?.name ?? "");
  const inputHandler = (e) => {
    setValue(e.target.value);
  };
  const submitHandler = (e) => {
    e.preventDefault();

    if (value.length !== 0) {
      currentItem
        ? updateHook({ id: currentItem?.id, body: { name: value } })
        : createHook({ name: value });
    } else {
      toast.error("Please enter name ", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  return (
    <div className="s-page__modal">
      <div className="s-page__modal-content">
        <div className="s-page__modal-header">
          <div className="s-page__modal-title">
            {currentItem ? "Update " + title : "Create " + title}
          </div>
          <div className="s-page__modal-close" onClick={() => setModal(false)}>
            <img src="/assets/icons/close-mark.svg" alt="close" />
          </div>
        </div>
        <form
          action="#"
          onSubmit={submitHandler}
          className="s-page__modal-form"
        >
          <div className="s-page__modal-fields">
            <div className="s-page__modal-field">
              <label htmlFor="id">{label}</label>
              <input
                maxLength={190}
                type="text"
                value={value}
                placeholder="Enter name"
                onChange={inputHandler}
              />
            </div>
          </div>
          <div className="s-page__modal-button">
            <button>{currentItem ? "Update" : "Create"}</button>
          </div>
        </form>
      </div>
      <div className="s-page__modal-bg" onClick={() => setModal(false)}></div>
    </div>
  );
};
