import React, { useState, useEffect } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  useCreateProjectMutation,
  useGetAllCompaniesQuery,
  useGetContactByIdQuery,
  useGetSecondaryContactByIdQuery, useLazyGetAllCompaniesQuery, useLazyGetProjectQuery,
} from "../../../store/service/adbService";
import { useErrorHandler } from "../../../global/ErrorsHandler";
import { toast } from "react-toastify";
import { setLoader } from "../../../store/slice/dashboard";
import AxelCheckbox from "../../../UI/AxelCheckbox";

const customStyles = {
  menu: (provided, state = false) => ({
    ...provided,
    width: "100%",
    color: state.selectProps.menuColor,
    cursor: "pointer",
  }),

  control: (_, { selectProps: { width } }) => ({
    width: "100%",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    display: "flex",
    marginTop: 8,
    background: "#F9F9FB",
    borderRadius: 8,
    padding: 2,
    cursor: "pointer",
  }),
};

export const CreateProjectStep3 = ({translations}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const enterDatas = JSON.parse(localStorage.getItem("create-data"));

  const [options, setOptions] = useState([]);

  const [state, setState] = useState({
    adb: enterDatas?.adb_id ?? null,
    executing: enterDatas?.executing_agency_id ?? null,
    contractor: enterDatas?.contractor_id ?? null,
    designConsultant: enterDatas?.design_consultant_id ?? null,
    projectManager: enterDatas?.project_manager_id ?? null,
    implementing_agency: enterDatas?.implementing_agency_id ?? null,
    supplier: enterDatas?.supplier_id ?? null,
    engineer: enterDatas?.engineer_id ?? null,
    consultant: enterDatas?.consultant_id ?? null,
    not_applicable_adb: false,
    not_applicable_executing_agency: false,
    not_applicable_contractor: false,
    not_applicable_design_consultant: false,
    not_applicable_project_manager: false,
    not_applicable_implementing_agency: false,
    not_applicable_supplier: false,
    not_applicable_engineer: false,
    not_applicable_consultant: false,
  });

  const [
    createProject,
    {
      data: createData,
      isSuccess: createSuccess,
      error: createError,
      isError: createIsError,
      isLoading: createLoading,
    },
  ] = useCreateProjectMutation();

  const [getCompanies, {
    data: companies,
    error: companiesError,
    isLoading: companiesIsLoading,
    isError: companiesIsError,
    isSuccess: companiesIsSuccess,
  }] = useLazyGetAllCompaniesQuery();

  useEffect(() => {
    getCompanies({
      per_page: 1000,
    })
  }, [])

  useEffect(() => {
    if (companies?.data?.data) {
      let data = companies?.data?.data?.map((item) => ({
        value: item?.id,
        label: item?.long_name,
      }));
      setOptions(data);
    }
  }, [companies]);

  const [errorHandler, setErrorHandler] = useErrorHandler([
    // projectManagerError,
    // designConsultantError,
    // contractorError,
    // executingError,
    createError,
    companiesError,
  ]);

  useEffect(() => {
    // executingIsLoading ||
    // contractorIsLoading ||
    // designConsultantIsLoading ||
    // projectManagerIsLoading ||
    companiesIsLoading || createLoading
      ? dispatch(setLoader(true))
      : dispatch(setLoader(false));
  }, [
    // executingIsLoading,
    // contractorIsLoading,
    // designConsultantIsLoading,
    // projectManagerIsLoading,
    companiesIsLoading,
    createLoading,
  ]);
  useEffect(() => {
    if (createSuccess) {
      toast.success("Project created", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      localStorage.removeItem("create-data");
      navigate("/project-list");
    }
  }, [createSuccess]);
  const submitHandler = (e) => {
    e.preventDefault();
    let body = {

    };

    let s = [];
    for (let key in body) {
      if (!body[key] || body[key] === "Invalid date" || body[key] === "") {
        toast.error("Please enter " + key?.split('_')?.join(' '), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
        s.push("false");
      }
    }
    if (s.length === 0) {
      localStorage.setItem(
          "create-data",
          JSON.stringify({
            ...JSON.parse(localStorage.getItem("create-data")),
            ...body,
            adb_id: state?.adb ?? null,
            executing_agency_id: state?.executing ?? null,
            implementing_agency_id: state?.implementing_agency ?? null,
            contractor_id: state?.contractor ?? null,
            supplier_id: state?.supplier ?? null,
            design_consultant_id: state?.designConsultant ?? null,
            project_manager_id: state?.projectManager ?? null,
            engineer_id: state?.engineer ?? null,
            consultant_id: state?.consultant ?? null,
            not_applicable_adb: state?.not_applicable_adb ?? null,
            not_applicable_executing_agency: state?.not_applicable_executing_agency,
            not_applicable_implementing_agency: state?.not_applicable_implementing_agency,
            not_applicable_contractor: state?.not_applicable_contractor,
            not_applicable_supplier: state?.not_applicable_supplier,
            not_applicable_design_consultant: state?.not_applicable_design_consultant,
            not_applicable_project_manager: state?.not_applicable_project_manager,
            not_applicable_engineer: state?.not_applicable_engineer,
            not_applicable_consultant: state?.not_applicable_consultant,
          })
      );
      navigate("/create-project/step-4");
    }

  };

  return (
    <form className="create__form">
      <div className="create__form-content">
        <div className='create__subTitle'>{translations?.['main.edit_projects_details.titles.by_selecting_checkbox_active_parties']}</div>
        <div className="create__form-row">
          <AxelCheckbox id="not_applicable_adb" label={translations?.['main.edit_projects_details.titles.adb']} defaultChecked={state?.not_applicable_adb} handler={(id, status) => {
            setState((old) => ({...old, not_applicable_adb: status}))
          }} />
          <div className="create__form-input">
            <Select
                menuPortalTarget={document.body}
                styles={({...customStyles, menuPortal: base => ({...base, zIndex: 9999})})}
                options={options}
                value={state?.adb}
                placeholder={translations?.['main.edit_projects_details.placeholder.select']}
                onChange={(selected) =>
                    setState((old) => ({ ...old, adb: selected }))
                }
            />
          </div>
        </div>
        <div className="create__form-row">
          <AxelCheckbox id="not_applicable_executing_agency" defaultChecked={state?.not_applicable_executing_agency} label={translations?.['main.edit_projects_details.titles.executing_agency']} handler={(id, status) => {
            setState((old) => ({...old, [id]: status}))
          }} />
          <div className="create__form-input">
            <Select
              menuPortalTarget={document.body}
              styles={({...customStyles, menuPortal: base => ({...base, zIndex: 9999})})}
              options={options}
              placeholder={translations?.['main.edit_projects_details.placeholder.select']}
              value={state?.executing}
              onChange={(selected) =>
                setState((old) => ({ ...old, executing: selected }))
              }
            />
          </div>
        </div>
        <div className="create__form-row">
          <AxelCheckbox id="not_applicable_implementing_agency" defaultChecked={state?.not_applicable_implementing_agency} label={translations?.['main.edit_projects_details.titles.implementing_agency']} handler={(id, status) => {
            setState((old) => ({...old, [id]: status}))
          }} />
          <div className="create__form-input">
            <Select
                menuPortalTarget={document.body}
                placeholder={translations?.['main.edit_projects_details.placeholder.select']}
                styles={({...customStyles, menuPortal: base => ({...base, zIndex: 9999})})}
                options={options}
                value={state?.implementing_agency}
                onChange={(selected) =>
                    setState((old) => ({ ...old, implementing_agency: selected }))
                }
            />
          </div>
        </div>
        <div className="create__form-row">
          <AxelCheckbox id="not_applicable_contractor" defaultChecked={state?.not_applicable_contractor} label={translations?.['main.edit_projects_details.titles.contractor']} handler={(id, status) => {
            setState((old) => ({...old, [id]: status}))
          }} />
          <div className="create__form-input">
            <Select
              menuPortalTarget={document.body}
              placeholder={translations?.['main.edit_projects_details.placeholder.select']}
              styles={({...customStyles, menuPortal: base => ({...base, zIndex: 9999})})}
              options={options}
              value={state?.contractor}
              onChange={(selected) =>
                setState((old) => ({ ...old, contractor: selected }))
              }
            />
          </div>
        </div>
        <div className="create__form-row">
          <AxelCheckbox id="not_applicable_supplier" defaultChecked={state?.not_applicable_supplier} label={translations?.['main.edit_projects_details.titles.supplier']} handler={(id, status) => {
            setState((old) => ({...old, [id]: status}))
          }} />
          <div className="create__form-input">
            <Select
              menuPortalTarget={document.body}
              placeholder={translations?.['main.edit_projects_details.placeholder.select']}
              styles={({...customStyles, menuPortal: base => ({...base, zIndex: 9999})})}
              options={options}
              value={state?.supplier}
              onChange={(selected) =>
                setState((old) => ({ ...old, supplier: selected }))
              }
            />
          </div>
        </div>
        <div className="create__form-row">
          <AxelCheckbox id="not_applicable_design_consultant" defaultChecked={state?.not_applicable_design_consultant} label={translations?.['main.edit_projects_details.titles.design_consultant']} handler={(id, status) => {
            setState((old) => ({...old, [id]: status}))
          }} />
          <div className="create__form-input">
            <Select
              menuPortalTarget={document.body}
              placeholder={translations?.['main.edit_projects_details.placeholder.select']}
              styles={({...customStyles, menuPortal: base => ({...base, zIndex: 9999})})}
              options={options}
              value={state?.designConsultant}
              onChange={(selected) =>
                setState((old) => ({ ...old, designConsultant: selected }))
              }
            />
          </div>
        </div>
        <div className="create__form-row">
          <AxelCheckbox id="not_applicable_project_manager" defaultChecked={state?.not_applicable_project_manager} label={translations?.['main.edit_projects_details.titles.project_manager']} handler={(id, status) => {
            setState((old) => ({...old, [id]: status}))
          }} />
          <div className="create__form-input">
            <Select
              menuPortalTarget={document.body}
              placeholder={translations?.['main.edit_projects_details.placeholder.select']}
              styles={({...customStyles, menuPortal: base => ({...base, zIndex: 9999})})}
              options={options}
              value={state.projectManager}
              onChange={(selected) =>
                setState((old) => ({ ...old, projectManager: selected }))
              }
            />
          </div>
        </div>
        <div className="create__form-row">
          <AxelCheckbox id="not_applicable_engineer" defaultChecked={state?.not_applicable_engineer} label={translations?.['main.edit_projects_details.titles.engineer']} handler={(id, status) => {
            setState((old) => ({...old, [id]: status}))
          }} />
          <div className="create__form-input">
            <Select
              menuPortalTarget={document.body}
              placeholder={translations?.['main.edit_projects_details.placeholder.select']}
              styles={({...customStyles, menuPortal: base => ({...base, zIndex: 9999})})}
              options={options}
              value={state.engineer}
              onChange={(selected) =>
                setState((old) => ({ ...old, engineer: selected }))
              }
            />
          </div>
        </div>
        <div className="create__form-row">
          <AxelCheckbox id="not_applicable_consultant" defaultChecked={state?.not_applicable_consultant} label={translations?.['main.edit_projects_details.titles.consultant_other']} handler={(id, status) => {
            setState((old) => ({...old, [id]: status}))
          }} />
          <div className="create__form-input">
            <Select
              menuPortalTarget={document.body}
              placeholder={translations?.['main.edit_projects_details.placeholder.select']}
              styles={({...customStyles, menuPortal: base => ({...base, zIndex: 9999})})}
              options={options}
              value={state.consultant}
              onChange={(selected) =>
                setState((old) => ({ ...old, consultant: selected }))
              }
            />
          </div>
        </div>



      </div>
      <div className="create__form-br"></div>
      <div className="create__form-btns">
        <div
          className="create__form-back"
          onClick={() => {
            navigate("/create-project/step-2");
          }}
        >
          {translations?.['main.edit_projects_details.actions.back']}
        </div>
        <button onClick={submitHandler} className="create__form-next">
          <span>{translations?.['main.edit_projects_details.actions.next']}</span>
          <span>
            <img src="/assets/icons/next-icon.svg" alt="icon" />
          </span>
        </button>
      </div>
    </form>
  );
};
