import React from 'react';
import {CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import moment from "moment/moment";
import {formatYAxis} from "../../global/formatYAxis";

const CustomTooltip = ({active, payload}) => {
	if (active && payload && payload.length) {
		return (
				<div className="custom-tooltip">
					<div style={{display: "flex", flexDirection: "column"}}>
						{payload.map((pld, idx) => (
								<div key={idx} style={{display: "inline-block", padding: 10}}>
									<div style={{fontSize: 12}}>{pld.value ? pld.value : 0}%</div>
									<div style={{color: pld.color, fontSize: 12}}>{pld.name}</div>
								</div>
						))}
					</div>
				</div>
		);
	}
	
	return null;
};

const CustomTooltipL = ({ active, payload }) => {
	if (active && payload && payload.length) {
		return (
				<div className="custom-tooltip">
					<div style={{ display: "flex", flexDirection: "column" }}>
						{payload.map((pld, idx) => (
								<div key={idx} style={{ display: "inline-block", padding: 10 }}>
									<div
											style={{
												overflow: "hidden",
												textOverflow: "ellipsis",
												fontSize: 12,
											}}
									>
										$
										{String(pld?.value).replace(
												/(\d)(?=(\d{3})+([^\d]|$))/g,
												"$1,"
										)}
									</div>
									<div style={{ color: pld.color, fontSize: 12 }}>{pld.name}</div>
								</div>
						))}
					</div>
				</div>
		);
	}
	
	return null;
};

function QuarterlyProgressChart({data, type = true, subtitle, cumulativePlanned, cumulativeActual, emptyDataText}) {

	function formatXAxis(tickItem) {
		return moment(tickItem).format("MMM-YY");
	}

	
	return (
			<div className="plannedProject">
				<div className="plannedProject__chart">
					{type ? '' : (
							<div className="project__dashboard-progress">
								<h3 className="project__dashboard-subtitle">{subtitle || 'ACTUAL VERSUS PLANNED PAYMENTS (USD)'}</h3>
								
								<div className="project__dashboard-statuses">
									<div className="project__dashboard-status">
										<div className="project__dashboard-status blue"></div>
										<span>{cumulativePlanned || 'Cumulative Planned'}</span>
									</div>
									<div className="project__dashboard-status">
										<div className="project__dashboard-status yellow"></div>
										<span>{cumulativeActual || 'Cumulative Actual'}</span>
									</div>
								</div>
							</div>
					)}
					{data?.length !== 0 ? (
							<ResponsiveContainer width="100%" aspect={3}>
								<ComposedChart
										width={500}
										height={300}
										data={data}
										margin={{
											top: 5,
											right: 30,
											left: 50,
											bottom: 5,
										}}
								>
									<CartesianGrid strokeDasharray="0 0"/>
									<XAxis  fontSize={10} dataKey="date"/>
									<YAxis
											orientation={"right"}
											type="number"
											domain={type ? [0, 100] : [0, dataMax => (dataMax + dataMax / 5)]}
											tickFormatter={(tick) => {
												if (type) {
													return tick ? `${Math.round(tick / 10) * 10}%` : "0%";
												} else {
													return formatYAxis(tick)
												}
											}}
											fontSize={12}
									/>
									{type ? <Tooltip content={CustomTooltip}/> : <Tooltip content={CustomTooltipL}/>}
									<Line
											dot={false}
											strokeWidth={4}
											strokeLinecap="round"
											type="monotone"
											dataKey="planned"
											animationDuration={0}
											stroke="#3B7AD9"
											name={cumulativePlanned || "Cumulative Planned"}
									/>
									<Line
											dot={false}
											strokeWidth={4}
											strokeLinecap="round"
											type="monotone"
											dataKey="actual"
											animationDuration={0}
											stroke="#FFD023"
											name={cumulativeActual || "Cumulative Actual"}
									/>
								</ComposedChart>
							</ResponsiveContainer>
					
					) : (
							<div className="plannedProject__placeholder">
								<img src="/assets/icons/planned_chart.svg" alt=""/>
								{emptyDataText || 'There is no data yet'}
							</div>
					)}
				</div>
			</div>
	);
}

export default QuarterlyProgressChart;
