import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import classNames from "classnames";
import charCounter from "../../global/charCounter";
import AxelButton from "../../UI/AxelButton";
import AxelInput from "../../UI/AxelInput";

import "./index.scss";

const AddTrainingVideoModal = ({
                                   playVideo,
                                   setIsModalOpen,
                                   currentItem,
                                   modalData,
                                   updateHook,
                                   createHook,
}) =>{
    const [file, setFile] = useState();
    const [charCount, setCharCount] = useState(0);
    const [formHasChanges, setFormHasChanges] = useState(false);
    const [state, setState] = useState({
        id: currentItem?.id ?? null,
        title: currentItem?.title ?? "",
        description: currentItem?.description ?? "",
        file: currentItem?.path ?? "",
    });

    const mp4 = 'video/mp4';
    const mov = 'video/quicktime';

    useEffect(() => {
        charCounter(state?.description, setCharCount)
    }, [state]);

    const getFileSize = (file) =>{
        return  (file?.size / 1024 / 1024).toFixed(2);
    }

    const inputHandler = (e) => {
        setFormHasChanges(true);
        charCounter(e.target.value, setCharCount)
        setState((old) => ({ ...old, [e.target.name]: e.target.value }));
    };

    const handleUpload = (event) => {
        const file = event?.target.files[0]
        const getFileMb = (file?.size / 1024 / 1024).toFixed(2)

        if (getFileMb > 100){
            toast.error('The file must not be greater than 100mb.')
            return
        }
        setFile(file);
        setState((old) => ({ ...old, [event.target.name]: file }));
    };

    const handleDrop = (ev) => {
        ev.preventDefault();
        let newFile

        if (ev.dataTransfer.items) {
            [...ev.dataTransfer.items].forEach((item) => {
                if (item.kind === "file") {
                    newFile = item.getAsFile();
                }
            });
        } else {
            [...ev.dataTransfer.files].forEach((file) => {
                newFile = file
            });
        }

        const getFileMb = (newFile?.size / 1024 / 1024).toFixed(2)

        if (getFileMb > 100){
            toast.error('The file must not be greater than 100mb.')
            return
        }

        if (newFile.type !== mp4 && newFile.type !== mov){
            toast.error('Please upload only .mp4, .mov type file')
            return
        }
        setFile(newFile);
        setState((old) => ({ ...old, file: newFile }));

    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = "copy";
    };

    const submitHandler = (e) => {
        e.preventDefault();
        let body = {
            title: state?.title,
            file: state?.file,
        };
        let s = [];
        for (let key in body) {
            if (!body[key] || body[key] === "Invalid date" || body[key] === "") {
                toast.error("Please upload " + key?.split('_')?.join(' '), {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
                s.push("false");
            }
        }

        if (s.length === 0) {
            const formData = new FormData();
            formData.append("title", state?.title);
            formData.append("description", state?.description);
            if (state?.file && !currentItem) {
                formData.append("file", state?.file);
            }
            if (currentItem) {
                updateHook({ id: currentItem?.id, formData });
            } else {
                createHook(formData);
            }
        }
    };


    return(
        <div className="modal">
            <div className="modal__container">
                <div className="modal__header">
                    <h5>{currentItem ? modalData.editTrainingModalTitle: modalData?.addTrainingModalTitle}</h5>
                    <button
                        onClick={() => {
                            setIsModalOpen(false);
                        }}
                        className="modal__closeIcon"
                    >
                        <img src="/assets/icons/close-mark.svg" alt="close" />
                    </button>
                </div>
                <div className="modal_content">
                    <form
                        className="modal__form"
                        onSubmit={submitHandler}
                    >
                       <div>
                           <label htmlFor="title">{modalData?.labelData[0]?.title} <span>*</span></label>
                           <AxelInput
                               type="text"
                               name="title"
                               placeholder={modalData?.formPlaceholder?.find(i => i.enter_title)?.enter_title}
                               value={state?.title}
                               handler={inputHandler}
                           />
                       </div>
                        <div>
                            <label htmlFor="title">{modalData?.labelData[1].description}</label>
                            <textarea
                                name="description"
                                onChange={inputHandler}
                                placeholder={modalData?.formPlaceholder?.find(i => i.description)?.description}
                                maxLength="2500"
                                defaultValue={state?.description}
                            />
                            <span className="charCount">{charCount}/2500</span>
                        </div>
                        {!currentItem && <div>
                            <div className="modal__form__uploader">
                                <AxelInput
                                    type="file"
                                    name="file"
                                    id="uploader"
                                    filename={state?.file}
                                    handler={handleUpload}
                                    accept=".mp4, .mov"
                                />
                                <label
                                    onDragOver={handleDragOver}
                                    onDrop={handleDrop}
                                    htmlFor="uploader"
                                >
                                    <img src="/assets/icons/upload-icon.svg" alt="upload"/>
                                    <span>{modalData?.formActions?.find(item => item.select_a_file__to_upload)?.select_a_file__to_upload}</span>
                                    <span>{modalData?.formActions?.find(item => item.or_drag_and_drop)?.or_drag_and_drop}</span>
                                </label>
                            </div>
                            <p>{modalData?.labelData[2].supported_files} .mp4, .mov</p>
                        </div>}
                        {
                            (file || currentItem) &&
                            <div className="modal__form__file">
                                <button type="button"  onClick={()=>playVideo(state.file)}>
                                    <img src="/assets/icons/video-play.svg" alt="word" />
                                </button>
                                <div className="modal__form__file_name">
                                    <span>{file?.name || state.file || '' }</span>
                                    {file?.size && <span>{getFileSize(file)}mb</span>}
                                </div>
                                {!currentItem &&<button onClick={() => setFile(null)} type="button" className="modal__form__file__closeBtn">
                                    <img src="/assets/icons/close.svg" alt="close"/>
                                </button>}
                            </div>
                        }
                        <AxelButton
                            type="submit"
                            disabled={!formHasChanges}
                            classname={classNames({['modal__form__btnDisabled']: !formHasChanges})}
                        >
                            {currentItem ? modalData.formUpdateBtnText : modalData?.formCreateBtnText}
                        </AxelButton>
                    </form>
                </div>
            </div>
            <div
                onClick={() => {
                    setIsModalOpen(false);
                }}
                className="modal-bg"
            ></div>
        </div>
    )
}


export default AddTrainingVideoModal;