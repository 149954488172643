import isEmpty from "lodash/isEmpty";
import React, { useState } from "react";
import { useEffect } from "react";
import Select from "react-select";
import {
  useGetAllSectorsQuery,
  useLazyGetAllCompaniesQuery,
  useLazyGetAllCountriesQuery,
  useLazyGetAllProjectsQuery,
} from "../../store/service/adbService";
import "../SecondaryContactModal/index.scss";
import { useErrorHandler } from "../../global/ErrorsHandler";
import { toast } from "react-toastify";

const customStyles = {
  menu: (provided, state = false) => ({
    ...provided,
    width: "100%",
    color: state.selectProps.menuColor,
    cursor: "pointer",
  }),

  control: (_, { selectProps: {  } }) => ({
    width: "100%",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    display: "flex",
    marginTop: 8,
    background: "#FFFFFF",
    borderRadius: 8,
    padding: 6,
    cursor: "pointer",
  }),
};

export default function UsersModal({
  currentItem,
  setModal,
  updateHook,
    rolesArray,
  createHook,
}) {
  const [state, setState] = useState({
    first_name: currentItem?.first_name ?? "",
    last_name: currentItem?.last_name ?? "",
    email: currentItem?.email ?? "",
    phone: currentItem?.phone ?? "",
    country: currentItem?.country
      ? { label: currentItem?.country?.name, value: currentItem?.country?.id }
      : null,
    company: currentItem?.company
      ? { label: currentItem?.company?.short_name, value: currentItem?.company?.id }
      : null,
    role: currentItem?.role
      ? { label: currentItem?.role, value: currentItem?.role }
      : null,
    projects: currentItem?.projects ? currentItem?.projects : [],
    password: currentItem?.password ? currentItem?.password : '',
    force_password_change: false,
    deactivated: currentItem?.deactivated,
  });

  const [roles,setRoles] = useState([])
  const [
    getAllProjects,
    {
      data: projects,
    },
  ] = useLazyGetAllProjectsQuery();

  useEffect(() => {
    getAllProjects({
      per_page: 1000,
      page: 1
    })
  }, [])

  const [allArr, setAllArr] = useState([]);

  const {
    data: sectors,
    error: sectorsError,
  } = useGetAllSectorsQuery();

  const [
    getCountries,
    {
      data: countries,
      error: countriesError,
    },
  ] = useLazyGetAllCountriesQuery();


  const [getCompanies, {
    data: companies,
    error: companiesError,
  }] = useLazyGetAllCompaniesQuery()

  useEffect(() => {
    getCountries({
      per_page: 1000,
    });
    getCompanies({per_page: 1000})
  }, []);

  useEffect(() => {
    if (projects) {
      setUsersData(projects?.data?.data)
    }
    setRoles(rolesArray?.map(el => ({value: el?.value, label: el?.name})))
  }, [projects])

  const [errorHandler, setErrorHandler] = useErrorHandler([
    countriesError,
    sectorsError,
    companiesError
  ]);
  useEffect(() => {
    if (countries?.data?.data) {
      let data = countries?.data?.data?.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));
      setCountryOptions(data);
    }
  }, [countries]);

  useEffect(() => {
    if (companies?.data?.data) {
      let data = companies?.data?.data?.map((item) => ({
        value: item?.id,
        label: item?.long_name,
      }));
      setCompaniesOptions(data);
    }
  }, [companies]);


  useEffect(() => {
    if (sectors?.data?.data) {
      let data = sectors?.data?.data?.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));
      setSectorOptions(data);
    }
  }, [sectors]);

  useEffect(() => {
    if (state?.projects?.length !==
        projects?.data?.data?.length) {
      setAllSelected(false)
    }
  }, [state?.projects])

  const [countryOptions, setCountryOptions] = useState(null);
  const [companiesOptions, setCompaniesOptions] = useState(null);

  const [sectorOptions, setSectorOptions] = useState(null);
  const [usersData, setUsersData] = useState(projects?.data?.data);
  const [allSelected, setAllSelected] = useState(false);
  const submitHandler = (e) => {
    e.preventDefault();
    let body = {
      first_name: state?.first_name,
      last_name: state?.last_name,
      email: state?.email,
      phone: state?.phone,
      country_id: state?.country?.value,
      company_id: state?.company?.value,
      role: state?.role?.value,
      projects: state?.projects,
      password: state?.password,
    };
    let s = [];
    for (let key in body) {
      if (!body[key] || body[key] === "Invalid date" || (body[key])==='') {
        //   password field is checked only when currentItem does not have a value
        if (!(currentItem && key === 'password')) {
          toast.error("Please enter " + key?.split("_")?.join(" "), {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          s.push("false");
        }
      }
    }

    // password field is checked only when currentItem does not have a value
    if (!currentItem && !isEmpty(state?.password) && state?.password?.length >= 8) {
      body.password = state?.password;
    } else if (state?.password?.length > 0 && state?.password?.length < 8) {
      toast.error("The password must be at least 8 characters.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      s.push("false");
    }

    if (s.length === 0) {
      if (currentItem) {
        let newBody = {};
        for (let key in currentItem) {
          if (currentItem[key] !== body[key]) {
            if (body[key]) {
              newBody = { ...newBody, [key]: body[key] };
            }
          }
        }
        if (state?.password !== "" && state?.password?.length >= 8) {
          newBody.password = state?.password;
        } else if (state?.password?.length > 0 && state?.password?.length < 8) {
          toast.error("The password must be at least 8 characters.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          s.push("false");
        }
        updateHook({
          id: currentItem?.id,
          body: {
            ...newBody,
            force_password_change: state?.force_password_change ? 1 : 0,
            deactivated: state?.deactivated ? 1 : 0,
            country_id: state?.country?.value,
            company_id: state?.company?.value,
          },
        }).then((res) => {
          if (res?.error?.data?.message === '' || !res?.error?.data?.message) {
            toast.success("User updated", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }
        })
      } else {
        createHook({
          ...body,
          force_password_change: state?.force_password_change ? 1 : 0,
          deactivated: state?.deactivated ? 1 : 0,
        }).then((res) => {
          toast.success("User created", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          setModal(false)
        });
      }
    }
  };
  const inputHandler = (e) => {
    setState((old) => ({ ...old, [e.target.id]: e.target.value }));
  };
  return (
    <div className="contact__modal">
      <div className="contact__modal-content users">
        <div className="contact__modal-header">
          <h4 className="contact__modal-title">
            {currentItem ? "Edit" : "Create"} User
          </h4>
          <div onClick={() => setModal(false)} className="contact__modal-close">
            <img src="/assets/icons/close-mark.svg" alt="close" />
          </div>
        </div>
        <form
          action="#"
          className="contact__modal-form"
          onSubmit={submitHandler}
        >
          <div className="contact__modal-gray">
            <div className="contact__modal-row">
              <div className="contact__modal-field">
                <label htmlFor="long_name" className="contact__modal-label">
                  First Name
                </label>
                <input
                  maxLength={190}
                  type="text"
                  id="first_name"
                  onChange={inputHandler}
                  defaultValue={state?.first_name}
                  className="contact__modal-input"
                  placeholder="Enter first name"
                />
              </div>
              <div className="contact__modal-field">
                <label htmlFor="short_name" className="contact__modal-label">
                  Last Name
                </label>
                <input
                  maxLength={190}
                  type="text"
                  id="last_name"
                  onChange={inputHandler}
                  defaultValue={state?.last_name}
                  className="contact__modal-input"
                  placeholder="Enter last name"
                />
              </div>
            </div>
            <div className="contact__modal-row">
              <div className="contact__modal-field">
                <label htmlFor="long_name" className="contact__modal-label">
                  Email
                </label>
                <input
                  maxLength={190}
                  type="email"
                  id="email"
                  onChange={inputHandler}
                  defaultValue={state?.email}
                  className="contact__modal-input"
                  placeholder="Enter email"
                />
              </div>
              <div className="contact__modal-field">
                <label htmlFor="short_name" className="contact__modal-label">
                  Phone Number
                </label>
                <input
                  maxLength={190}
                  type="text"
                  id="phone"
                  onChange={inputHandler}
                  defaultValue={state?.phone}
                  className="contact__modal-input"
                  placeholder="Enter phone"
                />
              </div>
            </div>
            <div className="contact__modal-row">
              <div className="contact__modal-field">
                <label className="contact__modal-label">Select Country</label>
                <Select
                  menuPortalTarget={document.body}
                  styles={{
                    ...customStyles,
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  options={countryOptions}
                  value={state?.country}
                  onChange={(selected) =>
                    setState((old) => ({ ...old, country: selected }))
                  }
                />
              </div>
              <div className="contact__modal-field">
                <label htmlFor="email" className="contact__modal-label">
                  Select User Type
                </label>
                <Select
                  menuPortalTarget={document.body}
                  styles={{
                    ...customStyles,
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  options={roles}
                  value={state?.role}
                  onChange={(selected) =>
                    setState((old) => ({ ...old, role: selected }))
                  }
                />
              </div>
            </div>
            <div className="contact__modal-row">
              <div className="contact__modal-field">
                <label className="contact__modal-label">Select Company</label>
                <Select
                  menuPortalTarget={document.body}
                  styles={{
                    ...customStyles,
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  options={companiesOptions}
                  value={state?.company}
                  onChange={(selected) =>
                    setState((old) => ({ ...old, company: selected }))
                  }
                />
              </div>
              <div className="contact__modal-field">
                <label htmlFor="long_name" className="contact__modal-label">
                  Set Password
                </label>
                <input
                  maxLength={190}
                  type="text"
                  id="password"
                  disabled={state?.force_password_change}
                  onChange={inputHandler}
                  value={state?.password}
                  style={{height: 50, marginTop: 5}}
                  className={`${
                    state?.force_password_change
                      ? "contact__modal-input disabled"
                      : "contact__modal-input"
                  }`}
                  placeholder="Enter password"
                />
              </div>
            </div>

            <div
                style={{ marginTop: 30, display: "flex", alignItems: "center" }}
              >
                <div className="contact__mod    al-checkbox">
                  <div className="a-checkbox">
                    <input
                      key={state?.deactivated}
                      id={"deactivated"}
                      type="checkbox"
                      checked={state?.deactivated}
                      onChange={(e) => {
                        if (e?.target?.checked) {
                          setState((old) => ({ ...old, deactivated: true }));
                        } else {
                          setState((old) => ({ ...old, deactivated: false }));
                        }
                      }}
                    />
                    <label htmlFor={"deactivated"} style={{ fontSize: 14 }}>
                      Deactivate User
                    </label>
                  </div>
                </div>
                <div className="contact__modal-checkbox">
                  <div className="a-checkbox">
                    <input
                      key={state?.force_password_change}
                      id={"force_password_change"}
                      type="checkbox"
                      checked={state?.force_password_change}
                      onChange={(e) => {
                        if (e?.target?.checked) {
                          setState((old) => ({
                            ...old,
                            force_password_change: true,
                            password: "",
                          }));
                        } else {
                          setState((old) => ({
                            ...old,
                            force_password_change: false,
                          }));
                        }
                      }}
                    />
                    <label
                      htmlFor={"force_password_change"}
                      style={{ fontSize: 14 }}
                    >
                      Force Password Change
                    </label>
                  </div>
                </div>
              </div>
            <div className="contact__modal-assign">
              <p className="contact__modal-label">Assign Project</p>
              <div className="contact__modal-assignContent">
                <div className="contact__modal-projects">
                  <div className="contact__modal-search">
                    <input
                      onChange={(e) => {
                        setUsersData(
                          projects?.data?.data?.filter((el) => {
                            if (e?.target?.value === "") {
                              return el;
                            } else {
                              return el?.project_name
                                ?.toLowerCase()
                                ?.includes(e?.target?.value.toLowerCase());
                            }
                          })
                        );
                      }}
                      placeholder={"Search..."}
                    />
                  </div>
                  <div className="contact__modal-projectBox">
                    <div className="contact__modal-checkbox">
                      <div className="a-checkbox">
                        <input
                          id="all"
                          type="checkbox"
                          checked={
                            allSelected
                          }
                          onChange={(e) => {
                            if (e?.target?.checked) {
                              setAllSelected(true);
                              setState((old) => ({
                                ...old,
                                projects: projects?.data?.data?.map(
                                  (el) => el?.id
                                ),
                              }));
                            } else {
                              setAllSelected(false)
                              setAllArr(false);
                              setState((old) => ({ ...old, projects: [] }));
                            }
                          }}
                        />
                        <label htmlFor={"all"}>Select all projects</label>
                      </div>
                    </div>
                  </div>
                  {usersData?.map((el) => (
                    <div key={el?.id} className="contact__modal-projectBox">
                      <div className="contact__modal-checkbox">
                        <div className="a-checkbox">
                          <input
                            key={state?.projects}
                            id={el?.id}
                            type="checkbox"
                            checked={
                              state?.projects?.filter(
                                (item) => item === el?.id
                              )[0]
                            }
                            onChange={(e) => {
                              if (e?.target?.checked) {
                                setState((old) => ({
                                  ...old,
                                  projects: [...old.projects, el?.id],
                                }));
                              } else {
                                setState((old) => ({
                                  ...old,
                                  projects: old?.projects?.filter(
                                    (item) => item !== el?.id
                                  ),
                                }));
                              }
                            }}
                          />
                          <label htmlFor={el?.id}>{el?.project_name} - {el?.contract_number}</label>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="contact__modal-btns">
            <button className="contact__modal-btn">
              {currentItem ? "Save" : "Save"}
            </button>
          </div>
        </form>
      </div>
      <div onClick={() => setModal(false)} className="contact__modal-bg"></div>
    </div>
  );
}
