import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  useCreateTemplateMutation,
  useUpdateTemplateMutation,
} from "../../../store/service/adbService";
import { toast } from "react-toastify";
import { setLoader } from "../../../store/slice/dashboard";

const useTemplateModalHook = (initialValue) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [createTemplate] = useCreateTemplateMutation();
  const [updateTemplate] = useUpdateTemplateMutation();
  const [state, setState] = useState({
    title: initialValue?.title ?? "",
    description: initialValue?.description ?? "",
    template: initialValue?.template ?? "",
    edit: initialValue ?? false,
    id: initialValue?.id ?? null,
  });
  const [modal, setModal] = useState(false);

  const handleChange = (event) => {
    setState((old) => ({ ...old, [event?.target?.id]: event?.target.value }));
  };

  const handleUpload = (event) => {
    setState((old) => ({ ...old, template: event?.target.files[0] }));
  };

  const errorsShow = (errors) => {
    for (let key in errors) {
      errors[key]?.forEach((item) => {
        toast.error(item);
      });
    }
  };

  const handleDragDrop = (file) => {
    setState((old) => ({ ...old, template: file }));
  };

  const create = (closeModal, get) => {
    dispatch(setLoader(true));
    const formData = new FormData();
    formData.append("title", state?.title);
    formData.append("description", state?.description);
    if (state?.template) {
      formData.append("template", state?.template);
    }
    createTemplate(formData)
      .unwrap()
      .then((res) => {
        toast.success(res?.message ?? "Success");
        closeModal();
        dispatch(setLoader(false));
        get();
      })
      .catch((err) => {
        dispatch(setLoader(false));
        errorsShow(err?.data?.errors);
        if (err?.originalStatus === 401) {
          navigate("/login");
        }
      });
  };

  const update = (closeModal, get, setCurrentTemplate) => {
    dispatch(setLoader(true));
    const formData = new FormData();
    formData.append("title", state?.title);
    formData.append("description", state?.description);
    if (state?.template) {
      formData.append("template", state?.template);
    }
    updateTemplate({ formData: formData, id: state?.id })
      .unwrap()
      .then((res) => {
        toast.success(res?.message ?? "Success");
        closeModal();
        dispatch(setLoader(false));
        setCurrentTemplate(null);
        get();
      })
      .catch((err) => {
        dispatch(setLoader(false));
        errorsShow(err?.data?.errors);
        if (err?.originalStatus === 401) {
          navigate("/login");
        }
      });
  };

  const setInitialState = (state) => {

  };

  const clearUploader = () => {
    setState((old) => ({ ...old, template: null }));
  };

  const closeModal = () => {
    setModal(false);
  };

  const openModal = () => {
    setModal(true);
  };

  return {
    state,
    handleChange,
    handleUpload,
    modal,
    closeModal,
    openModal,
    create,
    clearUploader,
    setInitialState,
    update,
    handleDragDrop,
  };
};

export default useTemplateModalHook;
