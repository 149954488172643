import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import React, {useEffect, useRef, useState} from 'react';
import ProgressPerOutput from "./ProgressPerOutput";
import MajorProblems from "./MajorProblems";
import MyEditor from "../../../components/EditorConvertToHTML";
import {parseHtml} from "../../../global/parseHtml";
import {useLazyGetImplementationProgQuery, useUpdateImplementationProgMutation} from "../../../store/service/adbService";
import {setLoader} from "../../../store/slice/dashboard";
import {errorHandle} from "../../../global/errorHandle";
import MoreInfoModal from "../../../components/MoreInfoModal";

const ImplementationProgress = ({reportId, translations, data, hiddenSectionHandler, isReportSubmitted}) => {
    const dispatch = useDispatch();
    const sectionsRef = useRef();
    const [editingSection, setEditingSection] = useState(null);
    const [editorContent, setEditorContent] = useState('');
    const [isOpenMoreInfo, setOpenMoreInfo] = useState(false)



    const [implementProgressData, setImplementProgressData] = useState(data || {});

    const [getImplementationProgress, {
        data: newImplementProgressData,
        isLoading: implementProgressIsLoading,
        error: implementProgressError,
        isSuccess: implementProgressIsSuccess,
    }]= useLazyGetImplementationProgQuery();

    const [updateImplementationProgress, {
        data: updateImplementationProgressData,
        isSuccess: updateImplementProgressIsSuccess,
        isLoading: updateImplementProgressIsLoading,
        error: updateImplementProgressError,
    }]= useUpdateImplementationProgMutation();


    const handleUpdateClick = (section, initialValue) => {
        setEditingSection(section);
        setEditorContent(initialValue);
    };

    const handleCancel = () => {
        setEditingSection(null);
        setEditorContent('');
    };

    const handleSave = (newContent) => {
        setEditingSection(null);
        setEditorContent('');

        const formData = new FormData();
        formData.append(editingSection, newContent);

        updateImplementationProgress({
            id: reportId,
            body: formData
        })
    };

    useEffect(() => {
        setImplementProgressData(data)
    }, [data]);

    useEffect(() => {
        if (implementProgressIsSuccess){
            setImplementProgressData(newImplementProgressData?.data)
        }
    }, [implementProgressIsSuccess, newImplementProgressData]);

    useEffect(() => {
        if (updateImplementProgressIsSuccess){
            toast.success(updateImplementationProgressData.message);
            getImplementationProgress(reportId);
            setEditingSection(null);
            setEditorContent('');
        }
    }, [updateImplementProgressIsSuccess,updateImplementationProgressData]);


    useEffect(() => {
        implementProgressIsLoading ||
        updateImplementProgressIsLoading
            ? dispatch(setLoader(true))
            : dispatch(setLoader(false));
    }, [implementProgressIsLoading, updateImplementProgressIsLoading]);

    useEffect(() => {
        if (updateImplementProgressError) {
            errorHandle(updateImplementProgressError);
        }
        if (implementProgressError) {
            errorHandle(implementProgressError);
        }
    }, [updateImplementProgressError, implementProgressError])

    return (
        <section id='implementation_progress' ref={sectionsRef}>
            {isOpenMoreInfo &&
                <MoreInfoModal
                    title={translations?.['implementation_progress.index.headers.implementation_progress']}
                    moreInfo={translations?.['implementation_progress.index.more_info.more_info']}
                    close={()=>setOpenMoreInfo(false)}
                />
            }
            <div className="editReport__sectionHeader">
                <span onClick={() => hiddenSectionHandler(sectionsRef)}/>
                <h2 onClick={() => hiddenSectionHandler(sectionsRef)}>
                    {translations?.['implementation_progress.index.headers.implementation_progress']}
                </h2>
                <button className="editReport__sectionHeader__moreInfo" onClick={()=>setOpenMoreInfo(!isOpenMoreInfo)}>
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.99805 6.33325V9.43992" stroke="#767676" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M6.99805 5.05322C7.27419 5.05322 7.49805 4.82937 7.49805 4.55322C7.49805 4.27708 7.27419 4.05322 6.99805 4.05322C6.7219 4.05322 6.49805 4.27708 6.49805 4.55322C6.49805 4.82937 6.7219 5.05322 6.99805 5.05322Z" fill="#767676"/>
                        <path d="M6.9987 13.1666C10.4045 13.1666 13.1654 10.4057 13.1654 6.99992C13.1654 3.59416 10.4045 0.833252 6.9987 0.833252C3.59294 0.833252 0.832031 3.59416 0.832031 6.99992C0.832031 10.4057 3.59294 13.1666 6.9987 13.1666Z" stroke="#767676" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    {translations?.['implementation_progress.index.titles.more_info']}
                </button>
            </div>
            <hr />
            <div className="summary-section">
                <h2>{translations?.['implementation_progress.present_status.headers.present_status']}</h2>
                <div className="summary-item">
                    <div className="summary-sub-item">
                        <strong className="summary-sub-item__fieldTitle">{translations?.['development_objectives.development_impact.titles.current_project']}</strong>
                        {editingSection === 'overall_physical_progress' ? (
                            <MyEditor
                                cencelTitle={translations?.['implementation_progress.present_status.actions.cancel']}
                                saveTitle={translations?.['implementation_progress.present_status.actions.save']}
                                initialValue={editorContent}
                                onSave={handleSave}
                                onCancel={handleCancel}
                            />
                        ) : (
                            <>
                                <div className={'html-text-styled'}>{parseHtml(implementProgressData?.present_status?.overall_physical_progress) ||' '}</div>
                                {!isReportSubmitted &&
                                    <div className="summary-sub-item__settingContainer">
                                        <button className="summary-sub-item__updateBtn"
                                                onClick={() => handleUpdateClick('overall_physical_progress', implementProgressData?.present_status?.overall_physical_progress)}>
                                            {translations?.['implementation_progress.present_status.actions.update']}
                                        </button>
                                    </div>
                                }
                            </>
                        )}
                    </div>
                </div>
            </div>
            <hr/>
            <ProgressPerOutput
                reportId={reportId}
                data={data?.progress_per_outputs}
                translations={translations}
                isReportSubmitted={isReportSubmitted}
            />
            <hr/>
            <MajorProblems
                reportId={reportId}
                data={data?.major_problems_with_ip}
                translations={translations}
                isReportSubmitted={isReportSubmitted}
                url='major-problems-with-ip'
            />
            <hr/>
            <MajorProblems
                reportId={reportId}
                data={data?.future_plans_for_the_next_quarters}
                translations={translations}
                isReportSubmitted={isReportSubmitted}
                url='future-plans'
            />
            <hr/>
        </section>
    );
};

export default ImplementationProgress;
