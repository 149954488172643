import React, {useEffect, useState} from 'react';
import useDebounce from "../../global/useDebounce";
import {useLazyGetSecondaryTablesQuery} from "../../store/service/adbService";
import Table from "./Table";
import Toolbar from "./Toolbar";

const SecondaryTables = ({ translations, eventOption, tablesOption }) => {
    const [event, setEvent] = useState(null);
    const [table, setTable] = useState(null);
    const [selectOptions, setSelectOptions] = useState(null);
    const [selectOptions2, setSelectOptions2] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    useEffect(()=>{
        if (eventOption)
        {
            setSelectOptions(Object.keys(eventOption)?.map((key)=>({
                value: key,
                label: eventOption[key],
            })))
        }
        if (tablesOption)
        {
            setSelectOptions2(Object.keys(tablesOption)?.map((key)=>({
                value: key,
                label: tablesOption[key],
            })))
        }
    },[eventOption, tablesOption])

    const tableHeader = [
        translations["secondary_tables.index.table_headers.first_name"],
        translations["secondary_tables.index.table_headers.last_name"],
        translations["secondary_tables.index.table_headers.company_name"],
        translations["secondary_tables.index.table_headers.timestamp"],
        translations["secondary_tables.index.table_headers.location"],
        translations["secondary_tables.index.table_headers.device"],
        translations["secondary_tables.index.table_headers.event_type"],
        translations["secondary_tables.index.table_headers.table"],
        translations["secondary_tables.index.table_headers.record"],
        translations["secondary_tables.index.table_headers.field_identifier"],
        translations["secondary_tables.index.table_headers.previous"],
        translations["secondary_tables.index.table_headers.current"],
    ]

    const dataFetcher = useLazyGetSecondaryTablesQuery;

    const exportEndpoint =`/audit-log/secondary-tables/export/csv`

    return (
        <>
            <Toolbar
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                startDate={startDate}
                endDate={endDate}
                setDateRange={setDateRange}
                translations={translations}
                setEvent={setEvent}
                setTable={setTable}
                event={event}
                table={table}
                exportEndpoint={exportEndpoint}
                actionSelectTitle={translations['user_access_activity.index.actions.event_type']}
                secondSelectTitle={'Table'}
                exportFileName='secondary-table'
                isHasSecondSelect={true}
                selectOptions={selectOptions}
                secondSelectOptions={selectOptions2}
                debouncedSearchTerm={debouncedSearchTerm}
                selectTitle={translations['secondary_tables.index.actions.event_type']}
                searchPlaceholder={translations['secondary_tables.index.actions.search_by_name_company']}

            />
            <Table
                event={event}
                table={table}
                dateRange={dateRange}
                tableHeader={tableHeader}
                dataFetcher={dataFetcher}
                debouncedSearchTerm={debouncedSearchTerm}
                emptyDataText={"No Data"}
            />
        </>
    );
}

export default SecondaryTables;