import React from "react";

import "./index.scss";

export default function CustomModal({
    title = 'Confirm your action',
    acceptHandler,
    closeHandler,
    template,
    children,
    hasHr,
    cancelBtnTitle = 'Cancel',
    acceptBtnTitle = 'Ok',
    acceptBtnColor,
    acceptBtnBg,
    acceptBtnDisabled,
    hasCancelBtn = true
}) {
    return (
        <div className="custom-modal">
            <div className="custom-modal__content">
                <div className="custom-modal__header">
                    <div className="custom-modal__title">{title}</div>
                    <div className="custom-modal__close" onClick={closeHandler}>
                        <img src="/assets/icons/close.svg" alt="close"/>
                    </div>
                </div>
                {hasHr && <div className="custom-modal__hr"/>}
                {children}
                <div className="custom-modal__btns">
                    {
                        hasCancelBtn &&
                        <div className="custom-modal__btn close" onClick={closeHandler}>
                            {cancelBtnTitle}
                        </div>
                    }
                    <div
                        className={`custom-modal__btn delete ${ !hasCancelBtn && 'fullWidth'} ${!!acceptBtnDisabled && 'disabled'}`}
                        onClick={() => !acceptBtnDisabled && acceptHandler(template, closeHandler)}
                        style={{background: acceptBtnBg, color: acceptBtnColor}}
                    >
                        {acceptBtnTitle}
                    </div>
                </div>
            </div>
            <div className="custom-modal__bg" onClick={closeHandler}></div>
        </div>
    );
}
