import moment from "moment";
import isEmpty from "lodash/isEmpty";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useErrorHandler } from "../../../../global/ErrorsHandler";
import {
    useCreateLevel2ProgramMutation,
    useDeleteLevel2ProgramMutation,
    useLazyGetAllCompaniesQuery,
    useUpdateLevel2ProgramMutation,
} from "../../../../store/service/adbService";
import { setLoader } from "../../../../store/slice/dashboard";
import { DeleteModal } from "../../DeleteModal";
import CustomDatePicker from "../../../CustomDatePicker";

export const Level2Program = ({
                                  levelData,
                                  glossaryData,
                                  refGoodsEquipment,
                                  translations
                              }) => {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [show, setShow] = useState(true);
    const [showByActive, setShowByActive] = useState(true);
    const [hasArchived, setHasArchived] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [newItem, setNewItem] = useState(false);
    const [options, setOptions] = useState([]);
    const [editable, setEditable] = useState(false);
    const [deleteModal, setDeleteModal] = useState(null);
    const [moreInfo, setMoreInfo] = useState(null);
    const user = useSelector((state) => state?.adbReducer?.user);
    const [currentRow, setCurrentRow] = useState(null);
    const [tableHeaders, setTableHeaders] = useState([]);
    const [infoModal, setInfoModal] = useState(false);
    const scrollRef = useRef(null);
    const expandedRef = useRef(null);
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                let array = data?.map((item) => {
                    item.edit = false;
                    return item;
                });
                setEditable(false);
                setCurrentItem(null);
                setData(array);
                if (newItem) {
                    setNewItem(false);
                    let s = data?.filter((el, i) => i !== data.length - 1);
                    setData(s);
                }
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, [data]);

    useEffect(() => {
        if (levelData?.level_2_program) {
            let data = levelData?.level_2_program?.map((item) => ({
                program_description: item?.program_description,
                original_completion_date: item?.original_completion_date,
                updated_completion_date: item?.updated_completion_date,
                program_reference: item?.program_reference,
                program_variance: item?.program_variance,
                project_id: levelData?.id,
                id: item?.id,
                edit: false,
                archived: item?.archived,
                submitted: item?.submitted,
                old_report: item?.old_report,
                submitted_date: item?.submitted_date,
            }));
            let isHasArchived = isEmpty(data.find(item => item.archived===1));
            setHasArchived(!isHasArchived);
            if (showByActive===true && !isHasArchived){
                const filteredData =  data.filter(item => item.archived ===0);
                setData(filteredData);
            } else setData(data);
            setEditable(false);
        }
    }, [levelData?.level_2_program, showByActive]);

    const [
        getCompanies,
        {
            data: companies,
            error: companiesError,
            isLoading: companiesIsLoading,
            isError: companiesIsError,
            isSuccess: companiesIsSuccess,
        },
    ] = useLazyGetAllCompaniesQuery();

    useEffect(() => {
        getCompanies({
            per_page: 1000,
        });
    }, []);

    useEffect(() => {
        if (levelData?.responsible_party) {
            let data = levelData?.responsible_party?.map((item) => {
                return {
                    value: item?.id,
                    label: item?.short_name,
                };
            });
            setOptions(data);
        }
    }, [levelData]);
    const [
        createLevel2Program,
        {
            data: createLevel2ProgramData,
            isError: createLevel2ProgramIsError,
            isLoading: createLevel2ProgramIsLoading,
            isSuccess: createLevel2ProgramIsSuccess,
            error: createLevel2ProgramError,
        },
    ] = useCreateLevel2ProgramMutation();

    const [
        updateLevel2Program,
        {
            data: updateLevel2ProgramData,
            isError: updateLevel2ProgramIsError,
            isLoading: updateLevel2ProgramIsLoading,
            isSuccess: updateLevel2ProgramIsSuccess,
            error: updateLevel2ProgramError,
        },
    ] = useUpdateLevel2ProgramMutation();

    const [
        deleteLevel2Program,
        {
            data: deleteLevel2ProgramData,
            isError: deleteLevel2ProgramIsError,
            isLoading: deleteLevel2ProgramIsLoading,
            isSuccess: deleteLevel2ProgramIsSuccess,
            error: deleteLevel2ProgramError,
        },
    ] = useDeleteLevel2ProgramMutation();

    const [errorHandler, setErrorHandler] = useErrorHandler([
        createLevel2ProgramError,
        updateLevel2ProgramError,
        deleteLevel2ProgramError,
    ]);

    useEffect(() => {
        if(translations){
            const headers = [
                {
                    tooltip_name: translations?.['project_monitoring.major_project_milestones.table_headers.ref'],
                    tooltip_text: translations?.['project_monitoring.major_project_milestones.tooltips.ref']
                },
                {
                    tooltip_name: translations?.['project_monitoring.major_project_milestones.table_headers.description'],
                    tooltip_text: translations?.['project_monitoring.major_project_milestones.tooltips.description']
                },
                {
                    tooltip_name: translations?.['project_monitoring.major_project_milestones.table_headers.original_program_milestone_completion_date'],
                    tooltip_text: translations?.['project_monitoring.major_project_milestones.tooltips.original_program_milestone_completion_date']
                },
                {
                    tooltip_name: translations?.['project_monitoring.major_project_milestones.table_headers.updated_completion_date'],
                    tooltip_text: translations?.['project_monitoring.major_project_milestones.tooltips.updated_completion_date']
                },
                {
                    tooltip_name: translations?.['project_monitoring.major_project_milestones.table_headers.variance_days'],
                    tooltip_text: translations?.['project_monitoring.major_project_milestones.tooltips.variance_days']
                },
            ];
            setTableHeaders(
                headers.map((item) => ({
                    ...item,
                    showTooltip: false,
                }))
            );
        }
    }, [translations])

    useEffect(() => {
        if (glossaryData?.data?.length) {
            setMoreInfo(
                glossaryData?.data?.filter(
                    (el) => el?.section_name === "Project Monitoring"
                )[0]
            );
        }
        setData((old) => {
            let data = old.map((item) => ({ ...item, edit: false }));
            return data;
        });
        setEditable(false);
    }, [
        createLevel2ProgramIsSuccess,
        updateLevel2ProgramIsSuccess,
        deleteLevel2ProgramIsSuccess,
        glossaryData,
    ]);

    useEffect(() => {
        const d = () => {
            dispatch(setLoader(false));
        };

        createLevel2ProgramIsLoading ||
        updateLevel2ProgramIsLoading ||
        deleteLevel2ProgramIsLoading
            ? dispatch(setLoader(true))
            : d();
    }, [
        createLevel2ProgramIsLoading,
        updateLevel2ProgramIsLoading,
        deleteLevel2ProgramIsLoading,
    ]);
    const setterData = (input) => {
        const body = {
            program_description: input?.program_description,
            original_completion_date: moment(input?.original_completion_date).format(
                "YYYY-MM-DD"
            ),
            updated_completion_date: moment(input?.updated_completion_date).format(
                "YYYY-MM-DD"
            ),
            project_id: levelData?.id,
        };

        let s = [];

        for (let key in body) {
            if (!body[key] || body[key] === "Invalid date" || body[key] === "") {
                toast.error("Please enter " + key?.split("_")?.join(" "), {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
                s.push("false");
            }
        }

        if (s.length === 0) {
            return body;
        } else {
            return null;
        }
    };

    const submitHandler = (e, item) => {
        e.preventDefault();
        if (newItem) {
            const dataCreated = setterData(currentItem);
            if (dataCreated) {
                createLevel2Program(dataCreated);
                setCurrentItem(null);
                setNewItem(false);
                setCurrentRow(null);
            }
        } else {
            const dataUpdated = setterData(currentItem);
            if (dataUpdated) {
                updateLevel2Program({
                    id: item?.id,
                    body: dataUpdated,
                });
                setCurrentItem(null);
                setNewItem(false);
                setCurrentRow(null);
            }
        }
    };

    return (
        <div className="project__goods">
            <div className="project__block-hr"></div>
            {deleteModal?.show && (
                <DeleteModal
                    id={deleteModal?.id}
                    action={deleteLevel2Program}
                    setDeleteModal={setDeleteModal}
                    cencelText={translations?.['main.main.actions.cancel']}
                    deleteText={translations?.['main.main.actions.delete']}
                    modalTitle={translations?.['main.main.headers.confirm_your_action']}
                    clarifyingText={translations?.['main.main.titles.you_are_going_to_delete_this_row']}
                />
            )}
            <div className={`project__block ${show ? "" : "close"}`}>
                <div className="project__block-subtitles">
                    <div className="project__block-subtitle">
                        {translations?.['project_monitoring.major_project_milestones.headers.major_project_milestones']}
                    </div>
                    <div className="project__block__buttonsContainer">
                        {hasArchived && <button
                            onClick={() => setShowByActive(!showByActive)}
                            className="project__block-add-row">
                            <span> <img src="/assets/icons/blue-eye.svg" alt="plus"/></span>
                            <span>{showByActive ? translations?.['project_monitoring.major_project_milestones.actions.show_all']
                                : translations?.['project_monitoring.major_project_milestones.actions.show_active']}</span>
                        </button>}
                        {user?.permissions?.edit_project && (
                            <button
                                onClick={() => {
                                    let randomId = Math.floor(Math.random() * 1000);
                                    setCurrentRow(randomId);
                                    scrollRef !== null && scrollRef.current.scrollIntoView();
                                    if (!newItem) {
                                        setNewItem(true);
                                        setEditable(true);
                                        setData((old) => [
                                            ...old,
                                            {
                                                id: randomId,
                                                program_description: "",
                                                original_completion_date: null,
                                                updated_completion_date: null,
                                                edit: true,
                                            },
                                        ]);
                                        setCurrentItem({
                                            id: randomId,
                                            program_description: "",
                                            original_completion_date: null,
                                            updated_completion_date: null,
                                            edit: true,
                                        });
                                    }
                                }}
                                className="project__block-add-row"
                            >
                <span>
                  <img src="/assets/icons/blue-plus.svg" alt="plus" />
                </span>
                                <span>{translations?.['project_monitoring.major_project_milestones.actions.add_row']}</span>
                            </button>
                        )}
                    </div>
                </div>
                <div
                    className={`project__block-content permits ${
                        editable ? "editable" : ""
                    }`}
                >
                    <div
                        className={`project__block-table project-table ${
                            currentItem ? "n" : ""
                        }`}
                    >
                        <div className={`project-table__header`} ref={scrollRef}>
                            {tableHeaders?.map((el, idx) => {
                                return (
                                    <div
                                        key={idx}
                                        onMouseEnter={(event) => {
                                            setTableHeaders(
                                                tableHeaders.map((item) => ({
                                                    ...item,
                                                    showTooltip: el.tooltip_name === item.tooltip_name,
                                                }))
                                            );
                                        }}
                                        onMouseLeave={() => {
                                            setTableHeaders(
                                                tableHeaders.map((item) => ({
                                                    ...item,
                                                    showTooltip: false,
                                                }))
                                            );
                                        }}
                                        className="project-table__th"
                                    >
                                        {el?.showTooltip ? (
                                            <span
                                                className={
                                                    el?.tooltip_text?.length > 20
                                                        ? "lengthy"
                                                        : "not-lengthy"
                                                }
                                            >
                        {el?.tooltip_text}
                      </span>
                                        ) : (
                                            ""
                                        )}
                                        {el?.tooltip_name}
                                    </div>
                                );
                            })}
                        </div>
                        <div className="project-table__body">
                            {data?.map((item, index) => (
                                <div key={index}>
                                    {index !== 0 && currentRow === item?.id && (
                                        <div className={`project-table__header`} key={index}>
                                            {tableHeaders?.map((el, idx) => {
                                                return (
                                                    <div key={idx} className="project-table__th">
                                                        {el?.showTooltip ? (
                                                            <span
                                                                className={
                                                                    el?.tooltip_text?.length > 20
                                                                        ? "lengthy"
                                                                        : "not-lengthy"
                                                                }
                                                            >
                                {el?.tooltip_text}
                              </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {el?.tooltip_name}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                    <form
                                        className={`project-table__row ${
                                            item?.edit ? "edit" : currentItem ? "n" : ""
                                        } ${
                                            Boolean(item.submitted) ||
                                            !user?.permissions?.edit_project
                                                ? "submitted"
                                                : ""
                                        }`}
                                        id={item?.id}
                                        key={item?.id}
                                        onSubmit={(e) => submitHandler(e, item)}
                                    >
                                        {
                                            !item.edit ?
                                                <div className="project-table__td">
                                                    <span>{item?.program_reference}</span>
                                                    <div className="project__block-input project-table__field">
                                                        {item?.program_reference ? item?.program_reference : ""}
                                                    </div>
                                                </div>
                                                : <div className="project-table__td"/>
                                        }
                                        <div className="project-table__td">
                                            <span>{item?.program_description}</span>
                                            <div className="project__block-input project-table__field">
                        <textarea
                            className={`${
                                expanded ? "project__block-expandedInput" : ""
                            }`}
                            ref={expandedRef}
                            onFocus={() => setExpanded(true)}
                            onBlur={() => setExpanded(false)}
                            maxLength={190}
                            defaultValue={item?.program_description}
                            onChange={(e) => {
                                setCurrentItem((old) => ({
                                    ...old,
                                    program_description: e.target.value,
                                }));
                            }}
                        />
                                            </div>
                                        </div>
                                        <div className="project-table__td">
                      <span>
                        {moment(item?.original_completion_date).format(
                            "DD/MM/YYYY"
                        ) === "Invalid date" ||
                        !moment(item?.original_completion_date).format(
                            "DD/MM/YYYY"
                        )
                            ? ""
                            : moment(item?.original_completion_date).format(
                                "DD/MM/YYYY"
                            )}
                      </span>
                                            <div className="project__block-item-calendar project-table__field">
                                                <CustomDatePicker
                                                    portalId="root-portal"
                                                    autoComplete="off"
                                                    id="approved_date"
                                                    placeholderText={translations?.['main.main.placeholder.dd_mm_yyyy']}
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={
                                                        currentItem?.original_completion_date
                                                            ? moment(
                                                                currentItem?.original_completion_date
                                                            ).toDate()
                                                            : ""
                                                    }
                                                    onChange={(date) => {
                                                        setCurrentItem((old) => ({
                                                            ...old,
                                                            original_completion_date: date,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="project-table__td">
                      <span>
                        {moment(item?.updated_completion_date).format(
                            "DD/MM/YYYY"
                        ) === "Invalid date" ||
                        !moment(item?.updated_completion_date).format(
                            "DD/MM/YYYY"
                        )
                            ? ""
                            : moment(item?.updated_completion_date).format(
                                "DD/MM/YYYY"
                            )}
                      </span>
                                            <div className="project__block-item-calendar project-table__field">
                                                <CustomDatePicker
                                                    portalId="root-portal"
                                                    autoComplete="off"
                                                    id="approved_date"
                                                    placeholderText={translations?.['main.main.placeholder.dd_mm_yyyy']}
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={
                                                        currentItem?.updated_completion_date
                                                            ? moment(
                                                                currentItem?.updated_completion_date
                                                            ).toDate()
                                                            : ""
                                                    }
                                                    onChange={(date) => {
                                                        setCurrentItem((old) => ({
                                                            ...old,
                                                            updated_completion_date: date,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {
                                            !item.edit ?
                                                <div
                                                    className={`project-table__td ${
                                                        item?.program_variance < 0
                                                            ? "project-table__td_red"
                                                            : item?.program_variance > 0
                                                                ? "project-table__td_green"
                                                                : ""
                                                    }`}
                                                >
                      <span>
                        {item?.program_variance ? item?.program_variance : ""}
                      </span>
                                                </div>
                                                : <div className="project-table__td"/>
                                        }
                                        <div className="project__block-btns">
                                            {currentItem ? (
                                                <div
                                                    className="project__block-cancel"
                                                    onClick={() => {
                                                        setCurrentRow(null);
                                                        let array = data?.map((item) => {
                                                            item.edit = false;
                                                            return item;
                                                        });
                                                        setCurrentItem(null);
                                                        setData(array);
                                                        if (newItem) {
                                                            setNewItem(false);
                                                            let s = data?.filter(
                                                                (el, i) => i !== data.length - 1
                                                            );
                                                            setData(s);
                                                        }
                                                    }}
                                                >
                                                    <img src="/assets/icons/close-mark.svg" alt="close" />
                                                    {translations?.['project_monitoring.major_project_milestones.actions.cancel']}
                                                </div>
                                            ) : (
                                                <div
                                                    className="project__block-delete"
                                                    onClick={() => {
                                                        setDeleteModal({ id: item?.id, show: true });
                                                    }}
                                                >
                                                    <img src="/assets/icons/trash-icon.svg" alt="arrow" />
                                                    {translations?.['project_monitoring.major_project_milestones.actions.delete']}
                                                </div>
                                            )}
                                            {currentItem ? (
                                                <button className="project__block-save">
                                                    <img src="/assets/icons/save-arrow.svg" alt="arrow" />
                                                    {translations?.['project_monitoring.major_project_milestones.actions.save']}
                                                </button>
                                            ) : (
                                                <div
                                                    onClick={() => {
                                                        setCurrentRow(item?.id);
                                                        setCurrentItem(item);
                                                        setEditable(true);
                                                        setData(() => {
                                                            let array = data?.map((elem) => {
                                                                if (elem?.id === item?.id) {
                                                                    elem.edit = true;
                                                                }
                                                                return elem;
                                                            });
                                                            return array;
                                                        });
                                                    }}
                                                    className="project__block-update"
                                                >
                                                    {translations?.['project_monitoring.major_project_milestones.actions.update']}
                                                </div>
                                            )}
                                        </div>
                                    </form>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
