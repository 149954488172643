import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useRef, useState} from 'react';
import Collapse from "../../Collapse";
import CustomModal from "../../customModal";
import {
    useCreateDocumentMutation,
    useDeleteDocumentMutation,
    useUpdateDocumentMutation
} from "../../../store/service/adbService";
import {setLoader} from "../../../store/slice/dashboard";
import {errorHandle} from "../../../global/errorHandle";
import {useErrorHandler} from "../../../global/ErrorsHandler";
import "./index.scss";

function getFileType(url) {
    const lastDotIndex = url.lastIndexOf('.');
    if (lastDotIndex !== -1) {
        return url.substring(lastDotIndex + 1);
    } else {
        return url;
    }
}

const Document = ({doc, deleteUrl, updateUrl, isQuarterReport, updateDocuments, translations }) => {
    const user = useSelector((state) => state?.adbReducer?.user);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [inputValue, setInputValue] = useState(doc.name || '')
    const [hovered, setHovered] = useState(false);
    const dispatch = useDispatch();

    const [
        deleteDocument,
        {
            data: deleteDocumentData,
            isError: deleteDocumentIsError,
            isLoading: deleteDocumentIsLoading,
            isSuccess: deleteDocumentIsSuccess,
            error: deleteDocumentError,
        },
    ] = useDeleteDocumentMutation();

    const [
        updateDocument,
        {
            data: updateDocumentData,
            isError: updateDocumentIsError,
            isLoading: updateDocumentIsLoading,
            isSuccess: updateDocumentIsSuccess,
            error: updateDocumentError,
        },
    ] = useUpdateDocumentMutation();

    const getDocImage = ()=>{
        if (getFileType(doc.path) === 'xlsx' || getFileType(doc.path) === 'xls'){
            return "/assets/icons/exel-icon.svg"
        }

        if (getFileType(doc.path) === 'pdf'){
            return "/assets/icons/pdf-icon.svg"
        }

        return "/assets/icons/word2-icon.svg"
    }

    const handleDownloadClick = (fileUrl) => {
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = 'test_file.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        if (deleteDocumentIsSuccess) {
            toast.success(deleteDocumentData?.message);
            if (isQuarterReport) {
                updateDocuments()
            }
        }
        if (updateDocumentIsSuccess) {
            toast.success(updateDocumentData?.message);
            if (isQuarterReport) {
                updateDocuments()
            }
        }
    }, [deleteDocumentIsSuccess, updateDocumentIsSuccess, updateDocumentData, deleteDocumentData]);

    useEffect(() => {
        deleteDocumentIsLoading ||
        updateDocumentIsLoading ?
            dispatch(setLoader(true)) :
            dispatch(setLoader(false))
    }, [deleteDocumentIsLoading, updateDocumentIsLoading]);

    useEffect(() => {
        if (updateDocumentIsError){
            errorHandle(updateDocumentError);
        }
        if (deleteDocumentIsError){
            errorHandle(deleteDocumentError);
        }
    },[updateDocumentIsError, updateDocumentError, deleteDocumentIsError, deleteDocumentError])

    const [docTranslation, setDocTranslation] = useState({})

    useEffect(() => {
        if(translations){
            setDocTranslation((prev) => ({
                ...prev,
                title: translations?.['documents.documents.titles.update_document'] || translations?.['documents.index.headers.update_document'],
                documentNameLabel: translations?.['documents.documents.titles.document_name'] || translations?.['documents.index.titles.document_name'],
                documentNamePlaceholder: translations?.['documents.documents.placeholder.enter_title'] || translations?.['documents.index.placeholder.enter_title'],
                actions: {
                    update: translations?.['documents.documents.actions.update'] || translations?.['documents.index.actions.update'],
                    delete: translations?.['documents.documents.actions.delete'] || translations?.['documents.index.actions.delete'],
                    acceptBtnTitle: translations?.['documents.documents.actions.save'] || translations?.['documents.index.actions.save'],
                }
            }))
        }
    }, [translations])


    return (
        <div
            className={`document_wrapper-item ${hovered && 'hovered'}`}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}>
            <div className='document_wrapper-item__content'>
                <div className='document_wrapper-item__imageWrap'>
                    <img src={getDocImage()} alt="word"/>
                </div>
                <div className='document_wrapper-item__uploadBtn'>
                    <img src="/assets/icons/download-block-icon.svg" alt="Download" onClick={()=>handleDownloadClick(doc.path)}/>
                </div>
            </div>
            <div className='document_wrapper-item__title'>
                {doc.name}
            </div>
            {
                hovered && user?.permissions?.edit_project &&
                <div className='document_wrapper-item__buttons_wrap'>
                    <div
                        onClick={() => setShowDeleteModal(true)}
                        className='delete'
                    >
                        <img src="/assets/icons/trash-icon.svg" alt="close"/>
                        {docTranslation?.actions?.delete}
                    </div>
                    <div
                        onClick={() => setShowUpdateModal(true)}
                        className='update'
                    >
                        <img src="/assets/icons/blue-pencil.svg" alt="close"/>
                        {docTranslation?.actions?.update}
                    </div>
                </div>
            }
            {
                showDeleteModal &&
                <CustomModal
                    title={translations?.['main.main.headers.confirm_your_action'] || translations?.['documents.delete.headers.confirm_your_action']}
                    acceptHandler={() => {
                        setShowDeleteModal(false)
                        deleteDocument({id: doc.id, url: deleteUrl})
                    }}
                    closeHandler={() => {
                        setShowDeleteModal(false)
                        setHovered(false)
                    }}
                    cancelBtnTitle={translations?.['main.main.actions.cancel'] || translations?.['documents.delete.actions.cancel']}
                    acceptBtnTitle={translations?.['main.main.actions.delete'] || translations?.['documents.delete.actions.delete']}
                    hasHr
                >
                    <p>
                        {translations?.['main.main.titles.you_are_going_to_delete_this_row'] || translations?.['documents.delete.titles.you_are_going_to_delete_this_document']}
                    </p>
                </CustomModal>
            }
            {
                showUpdateModal &&
                <CustomModal
                    acceptHandler={() => {
                        setShowUpdateModal(false)
                        updateDocument({id: doc.id, name: inputValue, url: updateUrl})
                    }}
                    closeHandler={() => {
                        setShowUpdateModal(false)
                        setHovered(false)
                    }}
                    title={docTranslation?.title}
                    hasCancelBtn={false}
                    acceptBtnTitle={docTranslation?.actions?.acceptBtnTitle}
                    acceptBtnDisabled={inputValue === doc.name}
                >
                    <div className='update_modal'>
                        <div className='update_modal__content'>
                            <div className='imageWrap'>
                                <img src={getDocImage()} alt="word"/>
                            </div>
                        </div>
                        <div className="">
                            <div className="update_modal__input_label">{docTranslation?.documentNameLabel}</div>
                            <input
                                id="title"
                                type="text"
                                className="update_modal__input"
                                placeholder={docTranslation?.documentNamePlaceholder}
                                value={inputValue}
                                onChange={({target}) => setInputValue(target.value)}
                            />
                        </div>
                    </div>
                </CustomModal>
            }
        </div>
    )
}

const Index = ({
                   deleteUrl,
                   updateUrl,
                   createUrl,
                   glossaryData,
                   refDocuments,
                   data,
                   isQuarterReport,
                   updateDocuments,
                   translations,
                   isReportSubmitted,
               }) => {
    const [showUpLoadModal, setShowUploadModal] = useState(false);
    const [inputValue, setInputValue] = useState('')
    const [file, setFile] = useState(null)
    const fileInputRef = useRef();
    const dispatch = useDispatch();
    const exel = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    const pdf = "application/pdf"
    const word = "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    const [
        createDocument,
        {
            data: createDocumentData,
            isError: createDocumentIsError,
            isLoading: createDocumentIsLoading,
            isSuccess: createDocumentIsSuccess,
            error: createDocumentError,
        },
    ] = useCreateDocumentMutation();

    useEffect(() => {
        createDocumentIsLoading ?
            dispatch(setLoader(true)) :
            dispatch(setLoader(false))
    }, [createDocumentIsLoading]);

    const [errorHandler, setErrorHandler] = useErrorHandler([
        createDocumentError
    ]);
    useEffect(() => {
        if (createDocumentIsSuccess) {
            toast.success(createDocumentData?.message);
            if (isQuarterReport) {
                updateDocuments()
            }
        }
    }, [createDocumentIsSuccess, createDocumentData]);


    const handleUpload = (event) => {
        const file = event?.target.files[0]
        const getFileMb = (file?.size / 1024 / 1024).toFixed(2)

        if (getFileMb > 100){
            toast.error('The file must not be greater than 100mb.')
            return
        }

        setFile(file)
    };

    const handleDrop = (ev) => {
        ev.preventDefault();
        let newFile

        if (ev.dataTransfer.items) {
            [...ev.dataTransfer.items].forEach((item) => {
                if (item.kind === "file") {
                    newFile = item.getAsFile();
                }
            });
        } else {
            [...ev.dataTransfer.files].forEach((file) => {
                newFile = file
            });
        }

        const getFileMb = (newFile?.size / 1024 / 1024).toFixed(2)

        if (getFileMb > 100){
            toast.error('The file must not be greater than 100mb.')
            return
        }

        if (newFile.type !== word && newFile.type !== exel && newFile.type !== pdf){
            toast.error('Please enter only .docx, .doc, .xlsx, .xls, .pdf type file')
            return
        }

        setFile(newFile)
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = "copy";
    };

    const getImage = ()=>{
        if (file.type === exel){
            return "/assets/icons/exel-icon.svg"
        }

        if (file.type === pdf){
            return "/assets/icons/pdf-icon.svg"
        }

        return "/assets/icons/word-icon.svg"
    }

    const onCreateDocument = ()=>{
        setShowUploadModal(false)
        const formData = new FormData();
        formData.append("name", inputValue);
        isQuarterReport ? formData.append("report_id", data.id) : formData.append("project_id", data.id);
        formData.append("file", file);
        createDocument({body: formData, url: createUrl});
        setInputValue('')
        setFile(null)
    }

    const [iDocTranslation, setIDocTranslation] = useState({})

    useEffect(() => {
        if(translations){
            setIDocTranslation((prev) => ({
                ...prev,
                moreInfoTitle: translations?.['documents.documents.titles.more_info'] || translations?.['documents.index.titles.more_info'],
                moreInfoData: translations?.['documents.documents.more_info.documents'] || translations?.['documents.index.more_info.more_info'],
                moreInfoHeader:translations?.['documents.documents.headers.documents'] || translations?.['documents.index.headers.documents'],
                onClickShowUploadBtnTitle: translations?.['documents.documents.actions.upload_file'] || translations?.['documents.index.actions.upload_file'],
                title: translations?.['documents.documents.titles.documents'] || translations?.['documents.index.headers.documents'],
                acceptBtnTitle: translations?.['documents.documents.actions.submit'] || translations?.['documents.index.actions.submit'],
                labels: {
                    text: translations?.['documents.documents.actions.click_to_upload'] || translations?.['documents.index.actions.click_to_browse'],
                    link: translations?.['documents.documents.actions.or_drag_and_drop'] || translations?.['documents.index.actions.drop_your_image_here_or_select']
                },
                documentName: translations?.['documents.documents.titles.document_name'] || translations?.['documents.index.titles.document_name'],
                placeholder: translations?.['documents.documents.placeholder.enter_title'] || translations?.['documents.index.placeholder.enter_title']
            }))
        }
    }, [translations])

    return (
        <section id='documents' style={{padding: `${isQuarterReport ? '0 32px' : 0 }`}}>
            <Collapse
                moreInfoTitle={iDocTranslation?.moreInfoTitle}
                moreInfoData={iDocTranslation?.moreInfoData}
                currentRef={refDocuments}
                title={iDocTranslation?.moreInfoHeader}
                glossaryData={glossaryData}
                data={data}
                currentSectionName="Project Document"
                onClickShowUploadBtn={() => setShowUploadModal(true)}
                showUploadBtn
                onClickShowUploadBtnTitle={iDocTranslation?.onClickShowUploadBtnTitle}
                isQuarterReport={isQuarterReport}
                isReportSubmitted={isReportSubmitted}
            >
                <div className='document_wrapper'>{data?.documents?.map((doc, index) => <Document
                    isQuarterReport={isQuarterReport}
                    updateDocuments={updateDocuments}
                    deleteUrl={deleteUrl}
                    doc={doc}
                    updateUrl={updateUrl}
                    translations={translations}
                    key={index}
                />)}</div>
                {showUpLoadModal &&
                    <CustomModal
                        acceptHandler={onCreateDocument}
                        closeHandler={() => {
                            setShowUploadModal(false)
                        }}
                        title={iDocTranslation?.title}
                        acceptBtnTitle={iDocTranslation?.acceptBtnTitle}
                        hasCancelBtn={false}
                        acceptBtnDisabled={inputValue.length === 0 || !file}
                    >
                        <div className='update_modal'>
                            <div className='update_modal__content'>
                                <input
                                    type="file"
                                    id="uploader"
                                    onChange={handleUpload}
                                    accept=".docx, .doc, .xlsx, .xls, .pdf"
                                    ref={fileInputRef}
                                />
                                <label
                                    onDragOver={handleDragOver}
                                    onDrop={handleDrop}
                                    htmlFor="uploader"
                                >
                                    <img src="/assets/icons/upload.svg" alt="upload"/>
                                    <span>{iDocTranslation?.labels?.text}</span>
                                    <span>{iDocTranslation?.labels?.link}</span>
                                </label>
                            </div>
                            <div className="">
                                {
                                    file &&
                                    <div className="update_modal__file">
                                        <div>
                                      <span>
                                        <img src={getImage()} alt="word"/>
                                      </span>
                                            <div className="update_modal__file_name">
                                                <span>{file?.name || ''}</span>
                                            </div>
                                            <span onClick={() => setFile()}>
                                      <img src="/assets/icons/close.svg" alt="close"/>
                                    </span>
                                        </div>
                                    </div>
                                }

                                <div className="update_modal__input_label">{iDocTranslation?.documentName}</div>
                                <input
                                    id="title"
                                    type="text"
                                    className="update_modal__input"
                                    placeholder={iDocTranslation?.placeholder}
                                    value={inputValue}
                                    onChange={({target}) => setInputValue(target.value)}
                                />
                            </div>
                        </div>
                    </CustomModal>
                }
            </Collapse>
        </section>
    );
};

export default Index;