import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import  './index.scss'

const EditorComponent = ({ initialValue, onSave, onCancel, cencelTitle, saveTitle }) => {
    const [text, setText] = useState(initialValue);

    const handleSave = () => {
        onSave(text);
    };

    return (
        <div className="editor-container">
            <ReactQuill value={text} onChange={setText} />
            <div className="editor-container__settingsButtons ">
                <button onClick={onCancel} className="cancel-button">{cencelTitle || 'Cancel'}</button>
                <button onClick={handleSave} className="save-button">{saveTitle || 'Save'}</button>
            </div>
        </div>
    );
};

export default EditorComponent;