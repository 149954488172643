import React, {useState} from "react";
import {useEffect} from "react";
import {useDispatch} from "react-redux";
import SecondaryContactModal from "../../components/SecondaryContactModal";
import {
	useCreateCompanyMutation,
	useDeleteCompanyMutation,
	useLazyGetAllCompaniesQuery,
	useSearchCompaniesMutation,
	useUpdateCompanyMutation,
} from "../../store/service/adbService";
import {setLoader} from "../../store/slice/dashboard";
import {DeleteModal} from "../../components/Project/DeleteModal";
import {Paginate} from "../../components/Paginate";
import useDebounce from "../../global/useDebounce";
import {useErrorHandler} from "../../global/ErrorsHandler";

export const CompanyPage = ({}) => {
	const dispatch = useDispatch();
	const [showModal, setShowModal] = useState(false);
	const [currentItem, setCurrentItem] = useState(null);
	const [currentPage, setCurrentPage] = useState(1)
	const [totalPage, setTotalPage] = useState(0)
	const [data, setData] = useState(null)
	const [deleteModal, setDeleteModal] = useState(null);
	const [searchTerm, setSearchTerm] = useState('');
	const debouncedSearchTerm = useDebounce(searchTerm, 500)
	const [getCompanies, {
		data: companies,
		isLoading: companiesIsLoading,
		error: companiesError
	}] = useLazyGetAllCompaniesQuery();
	
	const [searchCompanies] = useSearchCompaniesMutation()
	useEffect(() => {
		if (debouncedSearchTerm.length > 0) {
			dispatch(setLoader(true))
			searchCompanies(debouncedSearchTerm).unwrap().then(res => {
				dispatch(setLoader(false))
				setData(res?.data.data)
				setTotalPage(0)
			}).catch(() => {
				dispatch(setLoader(false))
			})
		} else {
			dispatch(setLoader(true))
			getCompanies({
				per_page: 10,
				page: 1,
			}).unwrap().then(res => {
				setData(res?.data?.data)
				setTotalPage(res?.data?.last_page)
				dispatch(setLoader(false))
				setCurrentPage(1)
			}).catch(() => {
				dispatch(setLoader(false))
			})
		}
	}, [debouncedSearchTerm])
	const [
		createCompany,
		{
			isSuccess: createCompanyIsSuccess,
			isLoading: createCompanyIsLoading,
			error: createCompanyError
		},
	] = useCreateCompanyMutation();
	
	const [
		deleteCompany,
		{
			isLoading: deleteCompanyIsLoading,
			error: deleteCompanyError
		},
	] = useDeleteCompanyMutation();
	
	const [
		updateCompany,
		{
			isSuccess: updateCompanyIsSuccess,
			isLoading: updateCompanyIsLoading,
			error: updateCompanyError
		},
	] = useUpdateCompanyMutation();
	
	const [errorHandler, setErrorHandler] = useErrorHandler([
		deleteCompanyError,
		updateCompanyError,
		companiesError,
		createCompanyError
	]);
	
	
	useEffect(() => {
		getCompanies({
			per_page: 10,
			page: currentPage,
		})
	}, [currentPage])
	
	useEffect(() => {
		if (companies) {
			setTotalPage(companies?.data?.last_page)
			setData(companies?.data?.data)
		}
	}, [companies])
	
	useEffect(() => {
		setShowModal(false);
		setCurrentItem(null);
	}, [createCompanyIsSuccess, updateCompanyIsSuccess]);
	
	useEffect(() => {
		!showModal && setCurrentItem(null);
	}, [showModal]);
	
	useEffect(() => {
		companiesIsLoading ||
		createCompanyIsLoading ||
		deleteCompanyIsLoading ||
		updateCompanyIsLoading
				? dispatch(setLoader(true))
				: dispatch(setLoader(false));
	}, [
		companiesIsLoading,
		createCompanyIsLoading,
		deleteCompanyIsLoading,
		updateCompanyIsLoading,
	]);
	
	
	return (
			<div className="s-pages">
				{deleteModal?.show && (
						<DeleteModal
								id={deleteModal?.id}
								action={deleteCompany}
								setDeleteModal={setDeleteModal}
						/>
				)}
				{showModal && (
						<SecondaryContactModal
								setModal={setShowModal}
								currentItem={currentItem}
								createHook={createCompany}
								updateHook={updateCompany}
						/>
				)}
				<div className="s-page__header">
					<div style={{display: 'flex'}}>
						<h1>Company</h1>
						<div className="search-block">
							<input placeholder="Search..." type="text" className="search" onChange={(e) => {
								setSearchTerm(e.target.value)
							}}/>
						</div>
					</div>
					<button
							onClick={() => {
								setShowModal(true);
							}}
					>
						<img src="/assets/icons/plus.svg" alt="plus"/>
						<span>Create Company</span>
					</button>
				</div>
				
				<div className="table">
					<div className="table-header">
						<div className="table-th">Company - Full Name</div>
						<div className="table-th">Company - Short Name</div>
						<div className="table-th">Country</div>
						<div className="table-th">Sector</div>
					</div>
					{
						data?.length > 0 ?
								<div className="table-body">
									{data?.map((item, index) => (
											<div className="table-row" key={index}>
												<div className="table-td">{item?.long_name}</div>
												<div className="table-td">{item?.short_name}</div>
												<div className="table-td">{item?.country?.name}</div>
												<div className="table-td">{item?.sector?.name}</div>
												<div className="table-actions">
													<div
															onClick={() => {
																setDeleteModal({id: item?.id, show: true});
																// deleteCompany(item?.id)
															}}
															className="table-delete"
													>
														<img src="/assets/icons/trash-icon.svg" alt="trash"/>
														<span>Delete</span>
													</div>
													<div
															className="table-edit"
															onClick={() => {
																setCurrentItem(item);
																setShowModal(true);
															}}
													>
														<img src="/assets/icons/edit-icon-2.svg" alt="edit"/>
														<span>Edit</span>
													</div>
												</div>
											</div>
									))}
								</div> :
								<div style={{
									height: '70vh',
									width: '100%',
									display: "flex",
									justifyContent: "center",
									alignItems: 'center',
									flexDirection: "column"
								}}>
									<img src="/assets/images/projects.png" alt=""/>
									<span style={{color: "#767676", fontSize: 16, fontWeight: 500}}>No Companies</span>
								</div>
					}
				</div>
				{(data?.length > 0 && totalPage > 0) &&
						<Paginate currentPage={currentPage} setCurrentPage={setCurrentPage} pageCount={totalPage}/>}
			</div>
	);
};
