import moment from 'moment';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';
import React, { useEffect, useRef, useState } from 'react';
import AxelInput from '../../../UI/AxelInput';
import { errorHandle } from '../../../global/errorHandle';
import { setLoader } from '../../../store/slice/dashboard';
import CustomDatePicker from "../../../components/CustomDatePicker";
import EditorForTableField from "../../../components/EditorConvertToHTML/EditorForTableField";

const ItemEdit = ({
                      url,
                      type,
                      itemData,
                      reportId,
                      setIsOpenEdit,
                      getItems,
                      cancelTranslation,
                      saveTranslation,
                      useUpdateItemMutation,
                      useCreateItemMutation,
                      fields,
                      feldLabels,
                      updateById,
                      cellWidths,
                      isShowLabel,
                      htmlEditFieldKeys,
                      placeHolders
                  }) => {
   const localeLan = localStorage.getItem("local_lan" );
 moment.locale(localeLan)
    const dispatch = useDispatch();
    const rowRef = useRef();
    const [state, setState] = useState(
        fields.reduce((acc, field) => {
            acc[field] = itemData?.[field] ?? '';
            return acc;
        }, {})
    );

    const customFieldLabels = feldLabels || [
        'Schedule',
        'Para No',
        'Description',
        'Due Date',
        'Compiled Date',
        'Delays',
        'Compiled',
        'Status as of',
    ];

    const [createItem, {
        isSuccess: createItemIsSuccess,
        isLoading: createItemIsLoading,
        error: createItemError,
        data: createItemData
    }] = (useCreateItemMutation || (() => [null, {}]))();

    const [updateItem, {
        data: updateItemData,
        isSuccess: updateItemIsSuccess,
        isLoading: updateItemIsLoading,
        error: updateItemError
    }] = useUpdateItemMutation();

    const inputHandler = (e) => {
        setState((old) => ({ ...old, [e.target.id]: e.target.value }));
    };

    const handleEditor = (value, fieldName) => {
        setState((old) => ({ ...old, [fieldName]: value }));
    }

    const dateHandler = (date, id) => {
        setState((old) => ({ ...old, [id]: date ? moment(date).format('YYYY-MM-DD') : '' }));
    };

    const submitHandler = (e) => {
        e.preventDefault();
        const body = fields.reduce((acc, field) => {
            acc[field] = state[field];
            return acc;
        }, {});

        const formData = new FormData();
        let isHaveEmptyField = false;

        for (let key in body) {
            if (!body[key] || body[key] === 'Invalid date' || body[key] === '') {
                toast.error(`Please enter ${key.split('_').join(' ')}`, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
                isHaveEmptyField = true;
            }
        }

        if (isHaveEmptyField) return;

        Object.keys(body).forEach((key) => formData.append(key, body[key]));
        if (!itemData && !itemData?.id) {
            formData.append('report_id', reportId);
            formData.append('type', type);
            createItem(formData);
        } else {
            updateItem({
                id: updateById ? itemData.id : reportId,
                body: formData,
                url: url
            });
        }
    };

    useEffect(() => {
        if (updateItemIsSuccess) {
            toast.success(updateItemData.message);
            getItems();
            setIsOpenEdit(false);
        }
        if (createItemIsSuccess) {
            toast.success(createItemData.message);
            getItems();
            setIsOpenEdit(false);
        }
    }, [createItemIsSuccess, updateItemIsSuccess, createItemData, updateItemData]);

    useEffect(() => {
        if (createItemError) {
            errorHandle(createItemError);
        }
        if (updateItemError) {
            errorHandle(updateItemError);
        }
    }, [createItemError, updateItemError]);

    useEffect(() => {
        createItemIsLoading || updateItemIsLoading
            ? dispatch(setLoader(true))
            : dispatch(setLoader(false));
    }, [createItemIsLoading, updateItemIsLoading]);

    return (
        <div className="editReport__table__row editReport__table__editRow editFinancing" ref={rowRef}>
            <div className="editReport__fieldWrapper">
                {fields.map((field, index) => (
                    (htmlEditFieldKeys && htmlEditFieldKeys?.find((el) => field === el)) ?
                        <EditorForTableField
                            key={field}
                            value={state[field] ?? ''}
                            width={cellWidths?.[index] - 25}
                            onChange={(el) => handleEditor(el, field)}
                        /> :
                        <div key={field} className="editReport__inputWrapper" style={{ width: cellWidths?.[index] }}>
                            {isShowLabel && <label htmlFor={field} className="editReport__label">{customFieldLabels[index]}</label>}
                            {field.includes('date') ? (
                                <div style={{ width: '100%', height: '100%' }}>
                                    <CustomDatePicker
                                        portalId="root-portal"
                                        autoComplete="off"
                                        id={field}
                                        placeholderText={placeHolders?.[index]}
                                        dateFormat="dd/MM/yyyy"
                                        selected={
                                            state?.[field] && moment(state?.[field], "DD/MM/YYYY", true).isValid()
                                                ? moment(state?.[field], "DD/MM/YYYY").toDate()
                                                :  (state?.[field] ? moment(state[field]).toDate() : null)
                                        }
                                        onChange={(date) => dateHandler(date, field)}
                                        className="editReport__input"
                                        style={{ width: '100%' }}
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                    />
                                </div>
                            ) : (
                                <AxelInput
                                    id={field}
                                    name={field}
                                    className="editReport__input"
                                    placeholder={placeHolders?.[index]}
                                    type="text"
                                    handler={inputHandler}
                                    value={state[field] ?? ''}
                                    style={{ width: '100%' }}
                                />
                            )}
                        </div>
                ))}
            </div>
            <div className="editReport__table__settingsButtons">
                <button className="editReport__table__cancelBtn" onClick={() => setIsOpenEdit(false)}>
                    {cancelTranslation}
                </button>
                <button className="editReport__table__saveBtn" type="submit" onClick={(e) => submitHandler(e)}>
                    {saveTranslation}
                </button>
            </div>
        </div>
    );
};

export default ItemEdit;
