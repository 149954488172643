import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setLoader} from "../../store/slice/dashboard";
import {
    useLazyGetAccountSettingsQuery,
    useLazyGetSettingsPageQuery
} from "../../store/service/adbService";
import AccountEmail from "./AcountEmail";
import Wrapper from "../../components/Wrapper";
import AccountPhoneNumber from "./AccountPhoneNumber";
import AccountAuthenticator from "./AccountAuthenticator";
import './index.scss';

const Settings = () =>{
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [pageData, setPageData] = useState({});
    const hasPageBeenRendered = useRef(false);

    const [getPageData,{
        data: settingsData,
        isLoading: settingsDataIsLoading,
    }] = useLazyGetSettingsPageQuery();

    const [getAccountData,{
        data: accountData,
        isLoading: accountDataIsLoading,
    }] = useLazyGetAccountSettingsQuery();

    const checkAccountMfAStatus = (data) => {
        const enabledValues = Object.values(data).map(item => item.enabled);
        return enabledValues.some(enabled => enabled);
    }

    useEffect( () => {
        if (!localStorage.getItem("user_token")) {
            return  navigate("/login");
        }
         if (localStorage.getItem("user_token")){
             getPageData();
             getAccountData();
         }
    }, []);

    useEffect(() => {
        if (hasPageBeenRendered.current && settingsData){
            setPageData(settingsData?.data?.translations);
        }
        hasPageBeenRendered.current=true;
    }, [settingsData]);

    useEffect(() => {
        settingsDataIsLoading || accountDataIsLoading  ? dispatch(setLoader(true)) : dispatch(setLoader(false))
    }, [settingsDataIsLoading, accountDataIsLoading ]);

    return(
        <>
            {Object.keys(pageData).length > 0 && (
                <Wrapper classname="settings">
                    <div className="settings__header">
                        <h1>
                            Settings
                        </h1>
                    </div>
                    <div className="settings__content">
                        {accountData?.data && !checkAccountMfAStatus(accountData?.data) && <div className="settings__content__info">
                            <div>
                                <svg width="24" height="24" viewBox="0 0 20 20" fill="none">
                                    <path d="M10 0C4.49 0 0 4.49 0 10C0 15.51 4.49 20 10 20C15.51 20 20 15.51 20 10C20 4.49 15.51 0 10 0ZM10 7.08C9.59 7.08 9.25 6.75 9.25 6.33C9.25 5.92 9.59 5.58 10 5.58C10.42 5.58 10.75 5.92 10.75 6.33C10.75 6.75 10.42 7.08 10 7.08ZM10.75 13.58C10.75 14 10.41 14.33 10 14.33C9.59 14.33 9.25 14 9.25 13.58V8.92C9.25 8.5 9.59 8.17 10 8.17C10.41 8.17 10.75 8.5 10.75 8.92V13.58Z" fill="#0052CC"/>
                                </svg>
                            </div>
                            <p>{pageData?.['alerts.info.titles.enabling_mfa']}</p>
                        </div>
                        }
                        <AccountEmail accountData={accountData} pageData={pageData} />
                        <AccountPhoneNumber accountData={accountData} pageData={pageData} />
                        <AccountAuthenticator accountData={accountData} pageData={pageData} />
                    </div>
                </Wrapper>
            )
            }
        </>
    )
}

export default Settings;