import React from 'react';
import {getContractDetail} from "../../../pages/Project/components/ContractKeyData";
import { useState } from 'react';
import { useEffect } from 'react';

const ContractDetails = ({ data, translations }) => {

    const [tableHeaders, setTableHeaders] = useState([]);

    useEffect(() => {
        if(translations){

            const headers = {
                contract_type: translations?.['main.edit_projects_details.titles.contract_type'],
                sector: translations?.['main.edit_projects_details.titles.sector'],
                contract_scope: translations?.['main.edit_projects_details.titles.contract_scope'],
                country: translations?.['main.edit_projects_details.titles.country'],
                original_contract_amount: translations?.['main.edit_projects_details.titles.original_contract_amount'],
                contract_date_issuance_letter_acceptance: translations?.['main.edit_projects_details.titles.contract_date_of_issuance_of_letter_of_acceptance'],
                contract_signed_date: translations?.['main.edit_projects_details.titles.contract_signing_date'],
                contract_commencement_effective_date: translations?.['main.edit_projects_details.titles.contract_commencement_effective_date'],
                contract_time_for_completion: translations?.['main.edit_projects_details.titles.contract_time_for_completion'],
                original_contract_completion_date: translations?.['main.edit_projects_details.titles.original_contract_completion_date'],
                current_contract_completion_date: translations?.['contract_key_data.contract_key_data.titles.current_contract_completion_date'],
                adb: translations?.['main.edit_projects_details.titles.adb'],
                executing_agency: translations?.['main.edit_projects_details.titles.executing_agency'],
                implementing_agency: translations?.['main.edit_projects_details.titles.implementing_agency'],
                contractor: translations?.['main.edit_projects_details.titles.contractor'],
                supplier: translations?.['main.edit_projects_details.titles.supplier'],
                design_consultant: translations?.['main.edit_projects_details.titles.design_consultant'],
                project_manager: translations?.['main.edit_projects_details.titles.project_manager'],
                engineer: translations?.['main.edit_projects_details.titles.engineer'],
                consultant: translations?.['main.edit_projects_details.titles.consultant_other'],
                contracts_defects_liability_period: translations?.['main.edit_projects_details.titles.contracts_defects_liability_period'],
                contract_loan_effective_date: translations?.['main.edit_projects_details.titles.contract_adb_loan_effective_date'],
                contract_loan_closing_date: translations?.['main.edit_projects_details.titles.contract_adb_loan_closing_date']
            }

            setTableHeaders(headers)

        }
    }, [translations])

    return (
        <div>
            <div className="online__content-projectStatusRow">
               <p> {translations?.['main.edit_projects_details.titles.contract_title']}</p>
                <span>{data?.contract_title}</span>
            </div>
            <div className="online__content-projectStatusRow">
               <p>
                   {translations?.['main.edit_projects_details.titles.contract_number']}
               </p>
                <span>{data?.contract_number}</span>
            </div>
            {
                Object.keys(data?.contract_details || {})?.map((key, index)=> {
                    if (data?.contract_details[key]?.not_applicable === 1){
                        return <></>
                    }
                    if (data?.contract_details[key] !== null && data?.contract_details[key] !== undefined) {
                        return (
                            <div className="online__content-projectStatusRow" key={index}>
                                <p>{tableHeaders[key]}</p>
                                <span>
                                  {getContractDetail(data?.contract_details, key, translations?.['contract_key_data.contract_key_data.titles.day_s'])}
                                </span>
                            </div>)
                    }
                })
            }
        </div>
    );
};

export default ContractDetails;