import React from "react";

export default function TemplateComponent({
  template,
  download,
  openDeleteModal,
  role,
  setTemplate,
}) {
  return (
    <div className="contract__management-template" id={template?.id}>
      <div className="left">
        <div className="template__image">
          <img src="/assets/icons/word-icon.svg" alt="" />
        </div>
        <div className="template__info">
          <div className="template__title">{template?.title}</div>
          <div className="template__descr">{template?.description}</div>
        </div>
      </div>
      <div className="right">
        <img
          onClick={() => download(template)}
          src="/assets/icons/download-block-icon.svg"
          alt="download"
        />
        {role === "Super Admin(Aptilla) Role" && (
          <img
            onClick={() => setTemplate(template)}
            src="/assets/icons/edit-block-icon.svg"
            alt="edit"
          />
        )}
        {role === "Super Admin(Aptilla) Role" && (
          <img
            onClick={() => openDeleteModal(template)}
            src="/assets/icons/delete-block-icon.svg"
            alt="delete"
          />
        )}
      </div>
    </div>
  );
}
