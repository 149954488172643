import React, { useState, useEffect } from "react";
import {
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Line,
    ComposedChart,
} from "recharts";

import AxelButton from "../../../../UI/AxelButton";
import moment from "moment";
import {
    useLazyGetProjectQuery,
    useUpdateProgramProgressMutation,
} from "../../../../store/service/adbService";
import { setLoader } from "../../../../store/slice/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useErrorHandler } from "../../../../global/ErrorsHandler";
import MoreInfoModal from "../../../MoreInfoModal";

export const ProgramProgress = ({ data, glossaryData, translations }) => {
    const dispatch = useDispatch();
    const params = useParams();
    const [progressData, setProgressData] = useState([]);
    const [currentId, setCurrentId] = useState({ id: null, type: null });
    const [plannedAmount, setPlannedAmount] = useState("");
    const [actualAmount, setActualAmount] = useState("");
    const [edit, setEdit] = useState(false);
    const [showSave, setShowSave] = useState(false);
    const [infoModal, setInfoModal] = useState(false);
    const [moreInfo, setMoreInfo] = useState([]);
    const user = useSelector((state) => state?.adbReducer?.user);
    const [tableHeaders, setTableHeaders] = useState([]);

    const [
        updateProgramProgress,
        {
            data: updateProgramProgressData,
            isError: updateProgramProgressIsError,
            isLoading: updateProgramProgressIsLoading,
            isSuccess: updateProgramProgressIsSuccess,
            error: updateProgramProgressError,
        },
    ] = useUpdateProgramProgressMutation();

    const [
        getProject,
        {
            data: projects,
            isError: projectsIsError,
            isFetching: projectsIsLoading,
            isSuccess: projectsIsSuccess,
            error: projectsError,
        },
    ] = useLazyGetProjectQuery();

    useEffect(() => {
        const d = () => {
            setProgressData((old) => {
                let data = old?.map((item) => ({ ...item, edit: false }));
                return data;
            });
            dispatch(setLoader(false));
        };

        if (updateProgramProgressIsError) {
            toast.error(
                updateProgramProgressError?.data?.errors?.errors?.planned_amount ||
                updateProgramProgressError?.data?.errors?.errors?.actual_amount,
                {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 8000,
                }
            );
        }

        updateProgramProgressIsLoading ? dispatch(setLoader(true)) : d();
    }, [updateProgramProgressIsLoading, updateProgramProgressIsError]);

    useEffect(() => {
        return window.addEventListener("click", (e) => {
            if (
                progressData?.length !== 0 &&
                e.target.closest(".plannedProject__month-box") === null &&
                e.target.closest(".project__block-update") === null
            ) {
                setProgressData((old) => [
                    ...old?.map((el) => ({ ...el, edit: false })),
                ]);
                setEdit(false);
            }
        });
    }, [data]);

    useEffect(() => {
        if(translations){
            const headers = [
                {
                    tooltip_name: translations?.['project_monitoring.program_progress.titles.planned'],
                    tooltip_text: translations?.['project_monitoring.program_progress.titles.planned'],
                },
                {
                    tooltip_name: translations?.['project_monitoring.program_progress.titles.actual'],
                    tooltip_text: translations?.['project_monitoring.program_progress.titles.actual']
                },
                {
                    tooltip_name: translations?.['project_monitoring.program_progress.titles.cumulative_planned'],
                    tooltip_text: translations?.['project_monitoring.program_progress.titles.cumulative_planned']
                },
                {
                    tooltip_name: translations?.['project_monitoring.program_progress.titles.cumulative_actual'],
                    tooltip_text: translations?.['project_monitoring.program_progress.titles.cumulative_actual']
                },
            ];
            setTableHeaders(
                headers.map((item) => ({
                    ...item,
                    showTooltip: false,
                }))
            );
        }
    }, [translations])

    useEffect(() => {
        if (glossaryData?.data?.length) {
            setMoreInfo(
                glossaryData?.data?.filter(
                    (el) => el?.section_name === "Program Progress"
                )[0]
            );
        }

        setProgressData(
            data?.program_progress?.data?.map((el) => ({
                ...el,
                date: el?.date,
                planned_amount: el?.planned_amount,
                actual_amount: el?.actual_amount,
                cumulative_actual_amount: el?.cumulative_actual_amount,
                cumulative_planned_amount: el?.cumulative_planned_amount,
                edit: false,
            }))
        );
    }, [data, glossaryData]);

    useEffect(() => {
        if (projectsIsLoading) {
            dispatch(setLoader(true));
        } else {
            dispatch(setLoader(false));
        }
    }, [projectsIsLoading]);

    const updateProjectPayments = (el) => {
        const body = !actualAmount
            ? { planned_amount: plannedAmount ? plannedAmount : el?.planned_amount }
            : !plannedAmount
                ? { actual_amount: actualAmount ? actualAmount : el?.actual_amount }
                : {
                    planned_amount: plannedAmount ? plannedAmount : el?.planned_amount,
                    actual_amount: actualAmount ? actualAmount : el?.actual_amount,
                };
        if (plannedAmount ? plannedAmount <= 100 : el?.planned_amount <= 100) {
            updateProgramProgress({
                id: el?.id,
                body,
            });
            setProgressData(progressData?.map((el) => ({ ...el, edit: false })));
        } else if (actualAmount ? actualAmount <= 100 : el?.planned_amount <= 100) {
            updateProgramProgress({
                id: el?.id,
                body,
            });
            setProgressData(progressData?.map((el) => ({ ...el, edit: false })));
        } else {
            setPlannedAmount(null);
            setActualAmount(null);
            toast.error("The amount must not be greater than 100", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 8000,
            });
        }
        setActualAmount(null);
        setPlannedAmount(null);
        setEdit(false);
        setCurrentId(null);
        setProgressData(progressData?.map((item) => ({ ...item, edit: false })));
        setShowSave(false);
    };
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        {payload.map((pld, idx) => (
                            <div key={idx} style={{ display: "inline-block", padding: 10 }}>
                                <div style={{ fontSize: 12 }}>{pld.value}%</div>
                                <div style={{ color: pld.color, fontSize: 12 }}>{pld.name}</div>
                            </div>
                        ))}
                    </div>
                </div>
            );
        }

        return null;
    };

    function formatXAxis(tickItem) {
        return moment(tickItem).format("MMM-YY");
    }

    const updateChart = () => {
        getProject(params.id);
    };

    const [errorHandler, setErrorHandler] = useErrorHandler([
        updateProgramProgressError,
    ]);

    return (
        <div className="plannedProject">
            {infoModal && (
                <MoreInfoModal
                    title={translations?.['project_monitoring.program_progress.headers.program_progress']}
                    moreInfo={translations?.['project_monitoring.program_progress.more_info.program_progress']}
                    close={()=> setInfoModal(false)}
                />
            )}
            <div className="project__block-subtitles">
                <div className="project__program-subtitle">
                    {translations?.['project_monitoring.program_progress.headers.program_progress']}
                    <span
                        onClick={() => setInfoModal(true)}
                        className="project__block-more"
                    >
            <span className="img">
              <img src="/assets/icons/info.svg" alt="info" />
            </span>
            <span className="project__program-text">{translations?.['project_monitoring.project_monitoring.titles.more_info']}</span>
          </span>
                </div>
            </div>

            <div className="plannedProject__table">
                <div
                    className={`plannedProject__table-row ${
                        edit && progressData?.length > 10 && "active"
                    }`}
                >
                    <div className="plannedProject__table-months">
                        <div className="plannedProject__table-monthsBox progressChart">
                            <span>{translations?.['project_monitoring.program_progress.titles.months']}</span>
                        </div>
                        {tableHeaders?.map((el, i) => {
                            return (
                                <div
                                    key={i}
                                    className="plannedProject__table-monthsBox progressChart"
                                >
                                    <div
                                        className={
                                            el?.tooltip_name === translations?.['project_monitoring.program_progress.titles.planned']
                                                ? "red"
                                                : el?.tooltip_name === translations?.['project_monitoring.program_progress.titles.actual']
                                                    ? "lightblue"
                                                    : el?.tooltip_name === translations?.['project_monitoring.program_progress.titles.cumulative_planned']
                                                        ? "blue"
                                                        : "yellow"
                                        }
                                    ></div>
                                    <p
                                        onMouseEnter={(event) => {
                                            setTableHeaders(
                                                tableHeaders.map((item) => ({
                                                    ...item,
                                                    showTooltip: el.tooltip_name === item.tooltip_name,
                                                }))
                                            );
                                        }}
                                        onMouseLeave={() => {
                                            setTableHeaders(
                                                tableHeaders.map((item) => ({
                                                    ...item,
                                                    showTooltip: false,
                                                }))
                                            );
                                        }}
                                    >
                                        {el?.tooltip_name}
                                    </p>
                                    {el?.showTooltip ? (
                                        <span
                                            className={
                                                el?.tooltip_text?.length > 20
                                                    ? "lengthy"
                                                    : "not-lengthy"
                                            }
                                        >
                      {el?.tooltip_text}
                    </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            );
                        })}
                    </div>
                    {progressData?.map((el, idx) => {
                        return (
                            <div key={idx} className={`plannedProject__month`}>
                                <div className="plannedProject__month-box">
                                    {moment(el?.date).format("MMM YYYY")}
                                </div>
                                <div
                                    onClick={() => {
                                        if (user?.permissions?.edit_project) {
                                            setProgressData(
                                                progressData?.map((item) => ({
                                                    ...item,
                                                    edit: el?.id === item?.id ? true : false,
                                                }))
                                            );
                                            setCurrentId({ id: el?.id, type: "planned" });
                                            setEdit(true);
                                        }
                                    }}
                                    className={`plannedProject__month-box plannedProject__month-box_normal`}
                                >
                  <span>
                    {el?.planned_amount !== null
                        ? el?.planned_amount?.toLocaleString() + "%"
                        : "-"}
                  </span>
                                    {currentId?.type === "planned" &&
                                        currentId?.id === el?.id &&
                                        el?.edit && (
                                            <div className="plannedProject__month-box-edit ">
                                                <input
                                                    className={"input-planned"}
                                                    autoFocus={true}
                                                    type={"number"}
                                                    maxLength={3}
                                                    value={
                                                        plannedAmount !== null
                                                            ? plannedAmount
                                                            : el?.planned_amount
                                                    }
                                                    onChange={(e) => {
                                                        if (e?.target?.value <= 100) {
                                                            setPlannedAmount(e?.target?.value);
                                                        }
                                                    }}
                                                />
                                                <div className="plannedProject__table-editBtn">
                                                    <AxelButton
                                                        handler={() => updateProjectPayments(el)}
                                                        text={"Apply"}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                </div>
                                <div
                                    onClick={() => {
                                        if (user?.permissions?.edit_project) {
                                            if (!el?.editable) {
                                                return null;
                                            }
                                            setProgressData(
                                                progressData?.map((item) => ({
                                                    ...item,
                                                    edit: el?.id === item?.id ? true : false,
                                                }))
                                            );
                                            setCurrentId({ id: el?.id, type: "actual" });
                                            setEdit(true);
                                        }
                                    }}
                                    className={`plannedProject__month-box ${
                                        !el?.editable
                                            ? "plannedProject__month-box_disable"
                                            : "plannedProject__month-box_normal"
                                    }`}
                                >
                  <span>
                    {el?.actual_amount !== null
                        ? el?.actual_amount?.toLocaleString() + "%"
                        : "-"}
                  </span>
                                    {currentId?.type === "actual" &&
                                        currentId?.id === el?.id &&
                                        el?.edit && (
                                            <div className="plannedProject__month-box-edit ">
                                                <input
                                                    className={"input-planned"}
                                                    autoFocus={true}
                                                    maxLength={3}
                                                    type={"number"}
                                                    value={
                                                        actualAmount !== null
                                                            ? actualAmount
                                                            : el?.actual_amount
                                                    }
                                                    onChange={(e) => {
                                                        if (e?.target?.value <= 100) {
                                                            setActualAmount(e?.target?.value);
                                                        }
                                                    }}
                                                />
                                                <div className="plannedProject__table-editBtn">
                                                    <AxelButton
                                                        handler={() => updateProjectPayments(el)}
                                                        text={"Apply"}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                </div>
                                <div
                                    className={`plannedProject__month-box `}
                                >
                  <span>
                    {el?.cumulative_planned_amount !== null
                        ? el?.cumulative_planned_amount?.toLocaleString() + "%"
                        : "-"}
                  </span>
                                </div>
                                <div
                                    className={`plannedProject__month-box `}
                                >
                  <span>
                    {el?.cumulative_actual_amount !== null
                        ? el?.cumulative_actual_amount?.toLocaleString() + "%"
                        : "-"}
                  </span>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="plannedProject__total">
                <div className="plannedProject__total-block">
                    <p className="plannedProject__total-text">{translations?.['project_monitoring.program_progress.titles.cumulative_planned']}</p>
                    <p className="plannedProject__total-text">
                        {String(data?.program_progress?.total_planned).replace(
                            /(\d)(?=(\d{3})+([^\d]|$))/g,
                            "$1,"
                        )}
                        %
                    </p>
                </div>
                <div className="plannedProject__total-block">
                    <p className="plannedProject__total-text">{translations?.['project_monitoring.program_progress.titles.cumulative_actual']}</p>
                    <p className="plannedProject__total-text">
                        {String(data?.program_progress?.total_actual).replace(
                            /(\d)(?=(\d{3})+([^\d]|$))/g,
                            "$1,"
                        )}
                        %
                    </p>
                </div>
            </div>
            <div className="project__program">
                <div className="project__block-subtitles">
                    <div className="project__program-subtitle">
                        {translations?.['project_monitoring.program_progress_bar_chart.headers.program_progress_bar_chart']}
                    </div>
                    <span className="project__block-add-row" onClick={updateChart}>
            <img src="/assets/icons/refresh-cw.svg" alt="" />
                        {translations?.['project_monitoring.program_progress_bar_chart.actions.update_chart']}
          </span>
                </div>
            </div>
            <div className="plannedProject__chart">
                <h2>{translations?.['project_monitoring.program_progress_bar_chart.titles.monthly_progress']} %</h2>
                {progressData?.length !== 0 ? (
                    <ResponsiveContainer width="100%" aspect={3}>
                        <ComposedChart
                            width={500}
                            height={300}
                            data={progressData}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="0 0" />
                            <XAxis tickFormatter={formatXAxis} fontSize={12} dataKey="date" />
                            <YAxis
                                orientation={"right"}
                                domain={[0, 100]}
                                type="number"
                                tickFormatter={(tick) => {
                                    return `${Math.round(tick / 10) * 10}%`;
                                }}
                                yAxisId="cumulative"
                                fontSize={12}
                            />
                            <YAxis
                                yAxisId={"amount"}
                                domain={[0, dataMax => (dataMax + Math.round(dataMax / 10))]}
                                orientation={"left"}
                                type="number"
                                tickFormatter={(tick) => {
                                    return `${tick}%`;
                                }}
                                fontSize={12}
                            />
                            <Tooltip key={4} content={CustomTooltip} />
                            <Bar
                                yAxisId={"amount"}
                                dataKey="planned_amount"
                                name={translations?.['project_monitoring.program_progress.titles.planned']}
                                fill="#E68484"
                                radius={[10, 10, 0, 0]}
                            />
                            <Bar
                                yAxisId={"amount"}
                                dataKey="actual_amount"
                                name={translations?.['project_monitoring.program_progress.titles.actual']}
                                fill="#99BAEB"
                                radius={[10, 10, 0, 0]}
                            />
                            <Line
                                yAxisId={"cumulative"}
                                dot={false}
                                strokeWidth={4}
                                strokeLinecap="round"
                                type="monotone"
                                dataKey="cumulative_planned_amount"
                                stroke="#3B7AD9"
                                name={translations?.['project_monitoring.program_progress.titles.cumulative_planned']}
                            />
                            <Line
                                yAxisId={"cumulative"}
                                dot={false}
                                strokeWidth={4}
                                strokeLinecap="round"
                                type="monotone"
                                dataKey="cumulative_actual_amount"
                                stroke="#FFD023"
                                name={translations?.['project_monitoring.program_progress.titles.cumulative_actual']}
                            />
                        </ComposedChart>
                    </ResponsiveContainer>
                ) : (
                    <div className="plannedProject__placeholder">
                        <img src="/assets/icons/planned_chart.svg" alt="" />
                        There is no Data yet
                    </div>
                )}
            </div>
        </div>
    );
};
