import React from 'react';
import {useSelector} from "react-redux";
import './table.scss'

function QuarterlyLoanUtilization({pageTranslations}) {
	
	const loan_utilization = useSelector(state => state?.quarterlyReducer?.online_view?.sections?.loan_utilization) ?? null;
	const sections_title = useSelector(state => state?.quarterlyReducer?.sections_title) ?? null;
	
	const renderHeader = () => {
		const years = Object.keys(loan_utilization?.index || {});
		return (
				<tr className={'row_gray'}>
					<th style={{width: '250px'}}></th>
					{years?.map((year) => (
							<React.Fragment key={year}>
								<th colSpan={Number(loan_utilization?.index[year]?.length)}>{year.replace('_', '')}</th>
							</React.Fragment>
					))}
				</tr>
		);
	};
	
	const renderHeaderCurrent = () => {
		const colSpan = Number(loan_utilization?.project_contract_award?.current?.data?.length)
		return (
				<tr className={'row_gray'}>
					<th style={{width: '250px'}}></th>
					<th style={{width: '200px'}}></th>
					<th colSpan={Number(colSpan + 1)}>{loan_utilization?.project_contract_award?.current?.year}</th>
				</tr>
		);
	};
	const renderQuarters = () => {
		const years = Object.keys(loan_utilization?.index || {});
		return (
				<tr>
					<th></th>
					{years.map((year) =>
							loan_utilization?.index[year]?.map((item) => (
									<th style={{minWidth: '150px'}} key={item.id}>{item.quarter} {item.period}</th>
							))
					)}
				</tr>
		);
	};
	
	const renderQuartersCurrent = () => {
		return (
				<tr>
					<th></th>
					<th style={{minWidth: '200px'}}>{pageTranslations?.['loan_utilization.project_contract_award.titles.cumulative_as_of']}</th>
					{loan_utilization?.project_contract_award?.current?.data?.map((item) =>
							<th style={{minWidth: '150px'}} key={item.id}>{item.quarter} {item.period}</th>
					)}
					<th style={{minWidth: '150px'}}>{pageTranslations?.['loan_utilization.project_contract_award.titles.total']}</th>
				</tr>
		);
	};
	
	const renderEmpty = (text) => {
		const years = Object.keys(loan_utilization?.index || {});
		return (
				<tr className={"row_gray"}>
					<th>{text}</th>
					{years.map((year) =>
							loan_utilization?.index[year]?.map((item) => (
									<td key={`projection-${item.id}`}></td>
							))
					)}
				</tr>
		);
	};
	
	const renderEmptyCurrent = (text) => {
		return (
				<tr className={"row_gray"}>
					<th>{text}</th>
					<td></td>
					{loan_utilization?.project_contract_award?.current?.data?.map((item) =>
							<td key={`projection-${item.id}`}></td>
					)}
					<td></td>
				</tr>
		);
	};
	
	const renderRows = (title, key) => {
		const years = Object.keys(loan_utilization?.index || {});
		return (
				<tr>
					<th>{title}</th>
					{years.map((year) =>
							loan_utilization?.index[year]?.map((item) => (
									<td key={`td-${item.id}`}>{item[key]}</td>
							))
					)}
				</tr>
		);
	};

    const renderRowsCurrent = (title, key) => {
        return (
            <tr>
                <th>{title}</th>
                <td>{loan_utilization?.project_contract_award?.last_total[key]}</td>
                {loan_utilization?.project_contract_award?.current?.data?.map((item) =>
                    <td key={`td-${item.id}`}>{item[key]?.includes('%') ? item[key] : item[key]}</td>
                )}
                <td>
                    {
                        typeof loan_utilization?.project_contract_award?.current_total[key] === 'string' && loan_utilization?.project_contract_award?.current_total[key]?.includes('%')
                            ?
                            loan_utilization?.project_contract_award?.current_total[key]
                            :
							loan_utilization?.project_contract_award?.current_total[key]
                    }
                </td>
            </tr>
        );
    };
	
	if (!loan_utilization) {
		return <div></div>
	}
	
	
	return (
			<div className="online__content-table">
				<p className="online__content-boldTitle">
					3. {pageTranslations?.['loan_utilization.index.headers.loan_utilization']}
				</p>
				<div className={"quarterly-online-table"}>
					<table border="1">
						<thead>
						{renderHeader()}
						{renderQuarters()}
						</thead>
						<tbody>
						{renderEmpty(pageTranslations?.['loan_utilization.index.headers.contract_award'])}
						{renderRows(pageTranslations?.['loan_utilization.index.titles.projection'], 'contract_award_projection')}
						{renderRows(pageTranslations?.['loan_utilization.index.titles.cumulative'], 'contract_award_projection_cumulative')}
						{renderRows(pageTranslations?.['loan_utilization.index.titles.actual'], 'contract_award_actual')}
						{renderRows(pageTranslations?.['loan_utilization.index.titles.cumulative'], 'contract_award_actual_cumulative')}
						{renderEmpty(pageTranslations?.['loan_utilization.index.headers.disbursement'])}
						{renderRows(pageTranslations?.['loan_utilization.index.titles.projection'], 'disbursement_projection')}
						{renderRows(pageTranslations?.['loan_utilization.index.titles.cumulative'], 'disbursement_projection_cumulative')}
						{renderRows(pageTranslations?.['loan_utilization.index.titles.actual'], 'disbursement_actual')}
						{renderRows(pageTranslations?.['loan_utilization.index.titles.cumulative'], 'disbursement_actual_cumulative')}
						</tbody>
					</table>
				</div>
				<p style={{margin: '20px 0'}}>
					3.1 {pageTranslations?.['loan_utilization.project_contract_award.headers.project_contract_award']}
				</p>
				<div className={"quarterly-online-table"}>
					<table border="1">
						<thead>
						{renderHeaderCurrent()}
						{renderQuartersCurrent()}
						</thead>
						<tbody>
						{renderEmptyCurrent(pageTranslations?.['loan_utilization.project_contract_award.headers.contract_award'])}
						{renderRowsCurrent(pageTranslations?.['loan_utilization.project_contract_award.titles.eops_target'], 'contract_award_eops_target')}
						{renderRowsCurrent(pageTranslations?.['loan_utilization.project_contract_award.titles.achievable_estimate'], 'contract_award_achievable_estimate')}
						{renderRowsCurrent(pageTranslations?.['loan_utilization.project_contract_award.titles.percent_of_eops_target'], 'contract_award_eops_target_percent')}
						{renderRowsCurrent(pageTranslations?.['loan_utilization.project_contract_award.titles.percent_of_achievable_estimate'], 'contract_award_achievable_estimate_percent')}
						{renderEmptyCurrent(pageTranslations?.['loan_utilization.project_contract_award.headers.disbursement'])}
						{renderRowsCurrent(pageTranslations?.['loan_utilization.project_contract_award.titles.eops_target'], 'disbursement_projection_eops_target')}
						{renderRowsCurrent(pageTranslations?.['loan_utilization.project_contract_award.titles.achievable_estimate'], 'disbursement_projection_achievable_estimate')}
						{renderRowsCurrent(pageTranslations?.['loan_utilization.project_contract_award.titles.percent_of_eops_target'], 'disbursement_projection_eops_target_percent')}
						{renderRowsCurrent(pageTranslations?.['loan_utilization.project_contract_award.titles.percent_of_achievable_estimate'], 'disbursement_projection_achievable_estimate_percent')}
						</tbody>
					</table>
				</div>
			</div>
	);
}

export default QuarterlyLoanUtilization;
