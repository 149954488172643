export const sidebarItems = [
  {
    id: "company",
    name: "Company",
    path: "/secondary-pages/company",
  },
  {
    id: "contract_type",
    name: "Contract type",
    path: "/secondary-pages/contract-type",
  },
  {
    id: "sector",
    name: "Sector",
    path: "/secondary-pages/sector",
  },
  {
    id: "country",
    name: "Country",
    path: "/secondary-pages/country",
  },
  {
    id: "region",
    name: "Region",
    path: "/secondary-pages/region",
  },
  {
    id: "users",
    name: "Users",
    path: "/secondary-pages/users",
  },
  {
    id: "audit-logs",
    name: "Audit logs",
    path: "/secondary-pages/audit-logs",
  },
  {
    id: "audit-logs",
    name: "Languages",
    path: "/secondary-pages/languages",
  }
];
