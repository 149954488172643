import React, { useEffect, useState } from "react";
import TemplateComponent from "../TemplateComponent";
import useTemplatesHook from "../TemplatesHook";
import TemplateModal from "../TemplateModal";
import useTemplateModalHook from "../TemplateModal/hooks";
import { useSelector } from "react-redux";
import CustomModal from "../../../components/customModal";

export default function TemplatesTabContent({translations}) {
  const user = useSelector((state) => state?.adbReducer?.user);
  const { templates, downloadTemplate, get, deleteTemplate } =
    useTemplatesHook();
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);

  const { modal, openModal, closeModal } = useTemplateModalHook();
  useEffect(() => {
    get();
  }, []);

  const setTemplate = (template) => {
    setCurrentTemplate(template);
    openModal();
  };

  const openDeleteModal = (template) => {
    setCurrentTemplate(template);
    setDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setDeleteModal(false);
    setCurrentTemplate(null);
  };
  return (
    <div className="contract__management-templates">
      {deleteModal && (
        <CustomModal
          template={currentTemplate}
          closeHandler={closeDeleteModal}
          acceptHandler={deleteTemplate}
          title={translations?.['cmp_templates.delete_cmp_template.headers.confirm_your_action']}
          acceptBtnTitle={translations?.['cmp_templates.delete_cmp_template.actions.delete']}
          cancelBtnTitle={translations?.['cmp_templates.delete_cmp_template.actions.cancel']}
          hasHr
        >
          <p className="custom-modal__p">
            {translations?.['cmp_templates.delete_cmp_template.titles.you_are_going_to_delete_this_template']}
          </p>
        </CustomModal>
      )}
      {modal && (
        <TemplateModal
          closeModal={closeModal}
          setCurrentTemplate={setCurrentTemplate}
          get={get}
          currentTemplate={currentTemplate}
          translations={translations}
        />
      )}
      <div className="contract-management__form">
        {user?.role === "Super Admin(Aptilla) Role" && (
          <div
            onClick={openModal}
            className="contract__management-template-create"
          >
            <div className="template__create-icon">
              <img src="/assets/icons/plus-button.svg" alt="plus" />
            </div>
            <div className="template__create-text">{translations?.['cmp_templates.index.actions.create_new_template']}</div>
          </div>
        )}
        {templates?.length > 0 &&
          templates?.map((item, index) => (
            <TemplateComponent
              template={item}
              key={index}
              download={downloadTemplate}
              openDeleteModal={openDeleteModal}
              role={user?.role}
              setTemplate={setTemplate}
              translations={translations}
            />
          ))}
      </div>
    </div>
  );
}
