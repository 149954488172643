import {useMemo} from "react";
import {useLocation} from "react-router-dom";

const useLastPathname = () => {
    const location = useLocation()

    return useMemo(
        () => decodeURIComponent(location.pathname.split('/').pop().replace(/-/g, '/')),
        [location.pathname]
    )
}

export default useLastPathname