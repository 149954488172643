import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import {Switch} from "../../UI/Switch";
import React, {useEffect, useState} from 'react';
import {
    useUpdatePhoneNumberMutation,
    useConfirmPhoneNumberCodeMutation, useLazyGetAccountSettingsQuery, useAllowToMFAMutation,
} from "../../store/service/adbService";
import {setLoader} from "../../store/slice/dashboard";
import {errorHandle} from "../../global/errorHandle";
import Modal from "../../components/Modal";
import EmailChangeContent from "../../components/Modal/EmailChangeContent"
import VerificationCode from "../../components/Modal/VerificationCode"
import './index.scss';

const AccountPhoneNumber = ({accountData, pageData}) =>{
    const dispatch = useDispatch();
    const [newPhoneNumber, setNewPhoneNumber] = useState();
    const [allowMFA, setAllowMFA] = useState(false);
    const [isEditPNumberModal, setIsEditPNumberModal] = useState(false);
    const [isVerifyPhoneNumber, setIsVerifyPhoneNumber] = useState(false);
    const [switchPhone, setSwitchPhone] = useState(false);
    const [inputData, setInputData] = useState({
            value: "",
            id: "phone-number",
            error: "Please enter correct number",
            errorShow: false,
        },
    );

    const [getAccountData,{
        isLoading: accountDataIsLoading,
    }] = useLazyGetAccountSettingsQuery();

    const [allowToMFA,{
        data: allowToMFAData,
        isLoading: allowToMFAIsLoading,
        isSuccess: allowToMFAIsSuccess,
        isError:  allowToMFAIsError,
        error: allowToMFAError,
    }] = useAllowToMFAMutation();

    const [requestToUpdatePhone,{
        data: requestToUpdatePhoneData,
        isSuccess: requestToUpdatePhoneIsSuccess,
        error: requestToUpdatePhoneError,
        isError: requestToUpdatePhoneIsError,
        isLoading: requestToUpdatePhoneIsLoading,
    }] = useUpdatePhoneNumberMutation();

    const [confirmPhoneCode, {
        data: confirmPhoneCodeData,
        error: confirmPhoneCodeError,
        isError: confirmPhoneCodeDataIsError,
        isSuccess: confirmPhoneCodeIsSuccess,
        isLoading: confirmPhoneCodeIsLoading,
    }] = useConfirmPhoneNumberCodeMutation();

    const submitHandler = () => {
        requestToUpdatePhoneNumber(inputData.value)
    }

    const requestToUpdatePhoneNumber = async (phone) => {
        await requestToUpdatePhone({
            phone:phone
        })
    }

    const updatePhoneNumber = async (code) => {
        await confirmPhoneCode({
            phone: newPhoneNumber,
            code: code,
        }).then(() => {
            if (allowMFA){
                setSwitchPhone(allowMFA);
                allowToMFA('phone');
                localStorage.removeItem('mfa_token');
            }
        })
    }
    const allowPhoneToMFA = (isAllowed) => {
        if(!accountData?.data.phone.value) {
            setIsEditPNumberModal(true);
            setAllowMFA(isAllowed);
        }
        else {
            allowToMFA('phone');
            localStorage.removeItem('mfa_token');
        }
    }

    useEffect( () => {
        if (allowToMFAIsSuccess){
            getAccountData();
        }
    }, [allowToMFAData, allowToMFAIsSuccess])

    useEffect(() => {
        setSwitchPhone(!!accountData?.data.phone.enabled);
    }, [accountData]);

    useEffect( () => {
        if (confirmPhoneCodeIsSuccess){
            toast.success(confirmPhoneCodeData.message);
            getAccountData();
            setIsVerifyPhoneNumber(false);
        }
    },[confirmPhoneCodeIsSuccess]);

    useEffect( () => {
        if (allowToMFAIsError){
            errorHandle(allowToMFAError)
        }
    },[allowToMFAIsError,allowToMFAError]);

    useEffect( () => {
        if (confirmPhoneCodeDataIsError){
            errorHandle(confirmPhoneCodeError)
        }
    },[confirmPhoneCodeDataIsError]);

    useEffect( () => {
        if (requestToUpdatePhoneIsError){
           errorHandle(requestToUpdatePhoneError)
        }
    },[requestToUpdatePhoneIsError]);

    useEffect( () => {
        if (requestToUpdatePhoneIsSuccess){
            toast.success(requestToUpdatePhoneData?.message);
            setNewPhoneNumber(requestToUpdatePhoneData.data.phone);
            setIsEditPNumberModal(false);
            setIsVerifyPhoneNumber(true);

        }

    },[requestToUpdatePhoneIsSuccess])

    useEffect(() => {
        requestToUpdatePhoneIsLoading || confirmPhoneCodeIsLoading || accountDataIsLoading || allowToMFAIsLoading ? dispatch(setLoader(true)) : dispatch(setLoader(false))
    }, [ requestToUpdatePhoneIsLoading, confirmPhoneCodeIsLoading, accountDataIsLoading, allowToMFAIsLoading ]);


    return(
        <>
            <div className="settings__content__item">
                <img className="settings__content__item__img"   src="/assets/icons/phone-message-checking.svg" alt="password-code"/>
                <div className="settings__content__item__wrapper">
                    <h2>{pageData['phone_number.phone_number.headers.phone_number']}</h2>
                    <p>
                        {pageData['phone_number.phone_number.titles.use_your_phone_number']}
                    </p>
                </div>
                <div className="settings__content__item__rightWrapper">
                    <span className="settings__content__item__value">
                      {accountData?.data.phone.value}
                    </span>
                    {accountData?.data.phone.value &&
                        <button
                            className="settings__content__item__editBtn"
                            onClick={()=>setIsEditPNumberModal(true)}
                        >
                            <img className="settings__content__item__img" src="/assets/icons/edit-black.svg" alt="edit-icon"/>
                        </button>
                    }
                    <Switch
                        id={'allowPhoneToMfa'}
                        checked={switchPhone}
                        handler={() => {allowPhoneToMFA(!switchPhone)}}
                    />
                </div>
            </div>
            { isEditPNumberModal &&
                <Modal
                    title={
                        !accountData?.data.phone.value ? pageData["phone_number.add.headers.add_phone_number"] :
                            pageData["phone_number.edit.headers.change_phone_number"]
                    }
                    closeModal={()=>setIsEditPNumberModal(false)}
                >
                    <EmailChangeContent
                        inputData={inputData}
                        setInputData={setInputData}
                        btnTitle={pageData["phone_number.edit.actions.next"]}
                        description={pageData["phone_number.edit.titles.send_code_to_phone_number"]}
                        inputPlaceholder={pageData["phone_number.add.titles.phone_number_placeholder"]}
                        submitHandler={submitHandler}
                    />
                </Modal>
            }
            { isVerifyPhoneNumber &&
                <Modal
                    title={pageData["phone_number.confirm.headers.Enter_verification_code"]}
                    closeModal={()=>setIsVerifyPhoneNumber(false)}
                >
                    <VerificationCode
                        itemValue={inputData.value}
                        btnTitle={pageData["phone_number.confirm.actions.verify"]}
                        description={pageData["phone_number.confirm.titles.sent_code_to"]}
                        resentButtonText={pageData["phone_number.confirm.actions.send_code_again"]}
                        resendFunc={submitHandler}
                        verifyFunc={updatePhoneNumber}
                    />

                </Modal>
            }
        </>
    )
}

export default AccountPhoneNumber;