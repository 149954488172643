import React, {useEffect, useState} from 'react';
import useDebounce from "../../global/useDebounce";
import {useLazyGetReportAndCMDQuery} from "../../store/service/adbService";
import Table from "./Table";
import Toolbar from "./Toolbar";

const ReportsAndCMD = ({translations, data}) => {
    const [eventType, setEventType] = useState(null);
    const [selectOptions, setSelectOptions] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    useEffect(()=>{
        if (data)
        {
            setSelectOptions(Object.keys(data)?.map((key)=>({
                value: key,
                label: data[key],
            })))
        }
    },[data])

    const columData = [
        translations["reports_and_cmp_downloaded.index.table_headers.first_name"],
        translations["reports_and_cmp_downloaded.index.table_headers.last_name"],
        translations["reports_and_cmp_downloaded.index.table_headers.company_name"],
        translations["reports_and_cmp_downloaded.index.table_headers.timestamp"],
        translations["reports_and_cmp_downloaded.index.table_headers.location"],
        translations["reports_and_cmp_downloaded.index.table_headers.device"],
        translations["reports_and_cmp_downloaded.index.table_headers.event_type"],
    ];
    const dataFetcher = useLazyGetReportAndCMDQuery;

    const exportEndpoint =`/audit-log/report-download/export/csv?`;

    return (
        <>
            <Toolbar
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                startDate={startDate}
                endDate={endDate}
                setDateRange={setDateRange}
                translations={translations}
                setEvent={setEventType}
                event={eventType}
                exportEndpoint={exportEndpoint}
                selectOptions={selectOptions}
                exportFileName='report-and-cmd'
                debouncedSearchTerm={debouncedSearchTerm}
                selectTitle={translations['reports_and_cmp_downloaded.index.table_headers.event_type']}
                searchPlaceholder={translations['reports_and_cmp_downloaded.index.actions.search_by_name_company']}

            />
            <Table
                dateRange={dateRange}
                tableHeader={columData}
                dataFetcher={dataFetcher}
                debouncedSearchTerm={debouncedSearchTerm}
                emptyDataText={"No Activities"}
            />
        </>
    );
}

export default ReportsAndCMD;
