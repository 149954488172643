import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import CustomDatePicker from "../../CustomDatePicker";

export const CreateProjectStep2 = ({translations}) => {
 const localeLan = localStorage.getItem("local_lan" );
 moment.locale(localeLan);
  const navigate = useNavigate();
  const enterDatas = JSON.parse(localStorage.getItem("create-data"));

  const [effectiveDate, setEffectiveDate] = useState(
    enterDatas?.contract_effective_date ?? null
  );
  const [contractDateAcceptance, setContractDateAcceptance] = useState(
    enterDatas?.contract_date_issuance_letter_acceptance ?? null
  );
  const [contractSignDate, setContractSignDate] = useState(
    enterDatas?.contract_signed_date ?? null
  );

  const [period, setPeriod] = useState(enterDatas?.contracts_defects_liability_period ?? '')
  const [contractEffectiveDate, setConctractEffectiveDate] = useState(enterDatas?.contract_loan_effective_date ?? null);
  const [contractClosingDate, setContractClosingDate] = useState(enterDatas?.contract_loan_closing_date ?? null);

  const [projectStartDate, setProjectStartDate] = useState(
    enterDatas?.contract_start_date
      ? {
          value: enterDatas?.contract_start_date,
          label: moment(enterDatas?.contract_start_date).format("LL"),
        }
      : null
  );
  const [contractTime, setContractTime] = useState(
    enterDatas?.contract_time_for_completion ?? null
  );
  const [originalCompletionDate, setOriginalCompletionDate] = useState(null);

  const submitHandler = (e) => {
    e.preventDefault();
    let body = {
      contract_date_issuance_letter_acceptance: moment(
        contractDateAcceptance
      ).format("YYYY-MM-DD"),
      contract_signed_date: moment(contractSignDate).format("YYYY-MM-DD"),
      contract_commencement_effective_date: moment(effectiveDate).format("YYYY-MM-DD"),
      contract_time_for_completion: contractTime,
      contracts_defects_liability_period: period,
      contract_loan_effective_date: moment(contractEffectiveDate).format("YYYY-MM-DD"),
      contract_loan_closing_date: moment(contractClosingDate).format("YYYY-MM-DD"),
    };

    let s = [];
    for (let key in body) {
      if (!body[key] || body[key] === "Invalid date" || body[key] === "") {
        toast.error("Please enter " + key?.split('_')?.join(' '), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
        s.push("false");
      }
    }
    if (s.length === 0) {
      localStorage.setItem(
        "create-data",
        JSON.stringify({
          ...JSON.parse(localStorage.getItem("create-data")),
          ...body,
        })
      );
      navigate("/create-project/step-3");
    }
  };

  useEffect(() => {
    let d = new Date(effectiveDate);
    d.setDate(d.getDate() + +contractTime);
    setOriginalCompletionDate(d);
  }, [contractTime, effectiveDate]);

  return (
    <form className="create__form">
      <div className="create__form-content">
        <div className="create__form-row">
          <div className="create__form-label">
            {translations?.['main.edit_projects_details.titles.contract_date_of_issuance_of_letter_of_acceptance']}
          </div>
          <div className="create__form-input calendar">
            <CustomDatePicker
              portalId="root-portal"
              autoComplete="off"
              id="contract_date_issuance_letter_acceptance"
              selected={
                contractDateAcceptance ? new Date(contractDateAcceptance) : null
              }
              onChange={(date) => setContractDateAcceptance(date)}
            />
            <label htmlFor="contract_date_issuance_letter_acceptance">
              {contractDateAcceptance
                ? moment(contractDateAcceptance).format("LL")
                : translations?.['main.edit_projects_details.placeholder.choose_date']}
            </label>
            <img src="/assets/icons/calendar.svg" alt="calendar" />
          </div>
        </div>
        <div className="create__form-row">
          <div className="create__form-label">{translations?.['main.edit_projects_details.titles.contract_signing_date']}</div>
          <div className="create__form-input calendar">
            <CustomDatePicker
              selected={
                contractSignDate ? new Date(contractSignDate) : null
              }
              portalId="root-portal"
              autoComplete="off"
              id="contract_signed_date"
              onChange={(date) => setContractSignDate(date)}
            />
            <label htmlFor="contract_signed_date">
              {contractSignDate
                ? moment(contractSignDate).format("LL")
                : translations?.['main.edit_projects_details.placeholder.choose_date']}
            </label>
            <img src="/assets/icons/calendar.svg" alt="calendar" />
          </div>
        </div>
        <div className="create__form-row">
          <div className="create__form-label">{translations?.['main.edit_projects_details.titles.contract_commencement_effective_date']}</div>
          <div className="create__form-input calendar">
            <CustomDatePicker
              portalId="root-portal"
              autoComplete="off"
              id="contract_effective_date"
              selected={
                effectiveDate ? new Date(effectiveDate) : null
              }
              onChange={(date) => setEffectiveDate(date)}
            />
            <label htmlFor="contract_effective_date">
              {effectiveDate
                ? moment(effectiveDate).format("LL")
                : translations?.['main.edit_projects_details.placeholder.choose_date']}
            </label>
            <img src="/assets/icons/calendar.svg" alt="calendar" />
          </div>
        </div>
        <div className="create__form-row">
          <div className="create__form-label">{translations?.['main.edit_projects_details.titles.contract_time_for_completion']}</div>
          <div className="create__form-input">
            <input
              type="number"
              placeholder={translations?.['main.edit_projects_details.placeholder.enter_number_of_days']}
              id="contract_time_for_completion"
              defaultValue={contractTime}
              onChange={(e) => {
                  setContractTime(e.target.value)
              }}
              maxLength={190}
            />
          </div>
        </div>
        <div className="create__form-row">
          <div className="create__form-label">
            {translations?.['main.edit_projects_details.titles.original_contract_completion_date']}
          </div>
          <div className="create__form-input calendar">
            <CustomDatePicker
              portalId="root-portal"
              autoComplete="off"
              id="contract_time_for_completion"
              selected={
                originalCompletionDate ? new Date(originalCompletionDate) : null
              }
              onChange={(date) => setOriginalCompletionDate(date)}
              disabled
            />
            <label>
              {contractTime && effectiveDate && originalCompletionDate
                ? moment(originalCompletionDate).format("LL") == "Invalid date"
                  ? "Calculate"
                  : moment(originalCompletionDate).format("LL")
                : translations?.['main.edit_projects_details.placeholder.choose_date']}
            </label>
            <img src="/assets/icons/calendar.svg" alt="calendar" />
          </div>
        </div>
        <div className="create__form-row">
          <div className="create__form-label">
            {translations?.['main.edit_projects_details.titles.contracts_defects_liability_period']}
          </div>
          <div className="create__form-input">
            <input
              type="number"
              value={period}
              onChange={(e) =>
                setPeriod(e.target.value)
              }
              placeholder={translations?.['main.edit_projects_details.placeholder.enter_number_of_days']}
              maxLength={190}
            />
          </div>
        </div>
        <div className="create__form-row">
          <div className="create__form-label">
            {translations?.['main.edit_projects_details.titles.contract_adb_loan_effective_date']}
          </div>
          <div className="create__form-input calendar">
            <CustomDatePicker
                portalId="root-portal"
              autoComplete="off"
              id="contract_loan_effective_date"
              selected={
                contractEffectiveDate ? new Date(contractEffectiveDate) : null
              }
              onChange={(date) => setConctractEffectiveDate(date)}
            />
            <label htmlFor="contract_loan_effective_date">
              {contractEffectiveDate
                ? moment(contractEffectiveDate).format("LL")
                : translations?.['main.edit_projects_details.placeholder.choose_date']}
            </label>
            <img src="/assets/icons/calendar.svg" alt="calendar" />
          </div>
        </div>
        <div className="create__form-row">
          <div className="create__form-label">
            {translations?.['main.edit_projects_details.titles.contract_adb_loan_closing_date']}
          </div>
          <div className="create__form-input calendar">
            <CustomDatePicker
                portalId="root-portal"
              autoComplete="off"
              id="contract_loan_closing_date"
              selected={
                contractClosingDate ? new Date(contractClosingDate) : null
              }
              onChange={(date) => setContractClosingDate(date)}
            />
            <label htmlFor="contract_loan_closing_date">
              {contractClosingDate
                ? moment(contractClosingDate).format("LL")
                : translations?.['main.edit_projects_details.placeholder.choose_date']}
            </label>
            <img src="/assets/icons/calendar.svg" alt="calendar" />
          </div>
        </div>
      </div>
      <div className="create__form-br"></div>
      <div className="create__form-btns">
        <div
          className="create__form-back"
          onClick={() => {
            navigate("/create-project/step-1");
          }}
        >
          {translations?.['main.edit_projects_details.actions.back']}
        </div>
        <button onClick={submitHandler} className="create__form-next">
          <span>{translations?.['main.edit_projects_details.actions.next']}</span>
          <span>
            <img src="/assets/icons/next-icon.svg" alt="icon" />
          </span>
        </button>
      </div>
    </form>
  );
};
