import React, {useState} from "react";
import AxelInput from "../../../UI/AxelInput";

const ItemEdit = ({itemData, itemKey, itemRef, updateMethod, applyBtnTitle, placeHolder}) => {
    const [state, setState] = useState(itemData?.[itemKey]);
    const maxNumber = 999999;

    const inputHandler = (e) => {
        const value = e.target.value;
        if (value > maxNumber) {
            setState(old => old)
        }
        else setState(value);
    };

    const submitHandler = (e) => {
        e.preventDefault();

        const formData = new FormData();

        formData.append(itemKey, state)

        updateMethod({id: itemData.id, body: formData});
    };

    return (
        <div className="editItemContainer" ref={itemRef} onClick={(e)=>e.stopPropagation()}>
            <AxelInput
                type="number"
                id={itemKey}
                name={itemKey}
                className='editReport__input'
                placeholder={placeHolder}
                handler={inputHandler}
                value={state}
            />

            <button  className="editReport__table__applyBtn" onClick={submitHandler}>
                {applyBtnTitle}
            </button>
        </div>
    )
}

export default ItemEdit;