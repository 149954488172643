import React, {useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {
    useCreatePasswordMutation,
    useForgotSendEmailMutation, useGetCreatePasswordFormQuery,
    useGetPlannedPaymentsQuery,
    useLazyForgotPasswordQuery, useUpdateUsersMutation
} from "../../store/service/adbService";
import AuthWrapper from "../../components/AuthWrapper";
import AxelInput from "../../UI/AxelInput";
import AxelButton from "../../UI/AxelButton";
import {validationEmail} from "../../global/validate";
import Loader from "../../components/Loader";
import {useDispatch} from "react-redux";
import {setLoader} from "../../store/slice/dashboard";
import {useErrorHandler} from "../../global/ErrorsHandler";
import {setEmail} from "../../store/slice/forgetSlice";
import {toast} from "react-toastify";
import {logDOM} from "@testing-library/react";

export default function CreatePassword() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [state, setState] = useState({
        email: {
            value: "",
            id: "email",
            error: "Please enter correct email",
            errorShow: false,
        },
        password: {
            value: "",
            id: "password",
            error: "Minimum 6 characters",
            errorShow: false,
        },
        confirm_password: {
            value: "",
            id: "confirm_password",
            error: "Passwords do not match",
            errorShow: false,
        },
    });
    const [validLength, setValidLength] = useState(false)
    const [hasNumber, setHasNumber] = useState(false)
    const [upperCase, setUpperCase] = useState(false)
    const [lowerCase, setLowerCase] = useState(false)
    const [specialChar, setSpecialChar] = useState(false)
    const [passwordMatch, setPasswordMatch] = useState(false)

    const [forgotHandler, {data, isError, isLoading, error}] =
        useLazyForgotPasswordQuery();

    const {
        data: formData,
        error: formDataError,
        isLoading: formDataIsLoading,
        isSuccess: formDataIsSuccess,
        isError: formDataIsError,
    } = useGetCreatePasswordFormQuery(location?.pathname?.split('/')[2]);

    const [
        createPassword,
        {
            data: createPasswordData,
            isError: createPasswordisError,
            error: createPasswordError,
            isSuccess: createPasswordisSuccess,
            isLoading: createPasswordisLoading,
        },
    ] = useCreatePasswordMutation();

    useEffect(() => {
        createPasswordisLoading || createPasswordisSuccess || formDataIsLoading
            ? dispatch(setLoader(true))
            : dispatch(setLoader(false));
    }, [
        createPasswordisSuccess,
        createPasswordisLoading,
        formDataIsLoading,
        isError,
        formDataIsError,
        createPasswordisError
    ]);

    const [errorHandler, setErrorHandler] = useErrorHandler([error, formDataError, createPasswordError]);


    const inputHandler = (e) => {
        setState((old) => ({
            ...old,
            [e.target.id]: {...old[e.target.id], value: e.target.value},
        }));
    };
    useEffect(() => {
        if (state?.confirm_password?.value) {
            if (state?.password?.value == state?.confirm_password?.value) {
                setPasswordMatch(true)
            } else {
                setPasswordMatch(false)
            }
        }
    }, [state?.password?.value, state?.confirm_password?.value])

    const submitHandler = async (e) => {
        e.preventDefault();
        if (lowerCase && upperCase && hasNumber && specialChar && validLength && passwordMatch) {
            dispatch(setLoader(true))
            if (state.password?.value === state.confirm_password?.value) {
                const l = await createPassword({
                    email: formData?.data?.email,
                    register_token: location?.pathname?.split('/')[2],
                    password: state.password?.value,
                    confirm_password: state?.confirm_password?.value
                });
                if (l.data.message) {
                    dispatch(setLoader(false))
                    navigate("/login");
                    toast.success(l.data.message)
                } else {
                    dispatch(setLoader(false))
                }
            } else {
                toast.error("Confirmation password is incorrect");
                dispatch(setLoader(false))
            }
        }
    };

    useEffect(() => {
        isLoading ? dispatch(setLoader(true)) : dispatch(setLoader(false));
    }, [isLoading]);

    const validate = (e) => {
        if (!/^(?=.*[a-z])/.test(e.target.value)) {
            setLowerCase(false)
        } else {
            setLowerCase(true)
        }
        if (!/^(?=.*[A-Z])/.test(e.target.value)) {
            setUpperCase(false)
        } else {
            setUpperCase(true)
        }
        if (!/^(?=.*[0-9])/.test(e.target.value)) {
            setHasNumber(false)
        } else {
            setHasNumber(true)
        }
        if (!/^(?=.*[!@#\$%\^&\*])/.test(e.target.value)) {
            setSpecialChar(false)
        } else {
            setSpecialChar(true)
        }
        if (e.target.value?.length >= 6) {
            setValidLength(true)
        } else {
            setValidLength(false)
        }
    }
    return (
        <>
            <AuthWrapper>
                <h1 className="auth__title">Create password</h1>
                <p className="auth__descr">
                    Please enter a new password.
                </p>
                <form
                    action="#"
                    className="auth__form login__form"
                    onSubmit={submitHandler}
                >
                    <AxelInput
                        placeholder="Email"
                        type="email"
                        value={formData?.data?.email}
                        disabled
                        handler={inputHandler}
                        id={state.email.id}
                        error={state.email.error}
                        errorShow={state.email.errorShow}
                    />
                    <AxelInput
                        placeholder="Create password"
                        type="password"
                        value={state.password?.value}
                        handler={(e) => {
                            validate(e)
                            inputHandler(e)
                        }}
                        id={state.password.id}
                        error={state.password.error}
                        errorShow={state.password.errorShow}
                    />
                    <AxelInput
                        placeholder="Confirm password"
                        type="password"
                        value={state.confirm_password.value}
                        handler={inputHandler}
                        id={state.confirm_password.id}
                        error={state.confirm_password.error}
                        errorShow={state.confirm_password.errorShow}
                    />
                    <ul style={{marginTop: 20}}>
                        <li>
                            {
                                lowerCase ?
                                    <div className="auth__valid">
                                        <img src="/assets/icons/checkpassword.svg" alt=""/>
                                        One lowercase character
                                    </div> :
                                    <div className="auth__invalid">
                                        <img src="/assets/icons/graycheck.svg" alt=""/>
                                        One lowercase character
                                    </div>
                            }
                        </li>
                        <li>
                            {
                                upperCase ?
                                    <div className="auth__valid">
                                        <img src="/assets/icons/checkpassword.svg" alt=""/>
                                        One uppercase character
                                    </div> :
                                    <div className="auth__invalid">
                                        <img src="/assets/icons/graycheck.svg" alt=""/>
                                        One uppercase character
                                    </div>
                            }
                        </li>
                        <li>
                            {
                                hasNumber ?
                                    <div className="auth__valid">
                                        <img src="/assets/icons/checkpassword.svg" alt=""/>
                                        One number
                                    </div> :
                                    <div className="auth__invalid">
                                        <img src="/assets/icons/graycheck.svg" alt=""/>
                                        One number
                                    </div>
                            }
                        </li>
                        <li>
                            {
                                specialChar ?
                                    <div className="auth__valid">
                                        <img src="/assets/icons/checkpassword.svg" alt=""/>
                                        One special character
                                    </div> :
                                    <div className="auth__invalid">
                                        <img src="/assets/icons/graycheck.svg" alt=""/>
                                        One special character
                                    </div>
                            }
                        </li>
                        <li>
                            {
                                validLength ?
                                    <div className="auth__valid">
                                        <img src="/assets/icons/checkpassword.svg" alt=""/>
                                        6 characters minimum
                                    </div> :
                                    <div className="auth__invalid">
                                        <img src="/assets/icons/graycheck.svg" alt=""/>
                                        6 characters minimum
                                    </div>
                            }
                        </li>
                        <li>
                            {
                                passwordMatch ?
                                    <div className="auth__valid">
                                        <img src="/assets/icons/checkpassword.svg" alt=""/>
                                        Password matches
                                    </div> :
                                    <div className="auth__invalid">
                                        <img src="/assets/icons/graycheck.svg" alt=""/>
                                        Password matches
                                    </div>
                            }
                        </li>
                    </ul>
                    <AxelButton text="Confirm password"/>
                </form>
            </AuthWrapper>
        </>
    );
}
