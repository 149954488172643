import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import  './index.scss'

const EditorForTableField = ({ value, onChange, width}) => {

    return (
        <div className="editor-container" style={{marginBottom: 0, width: width}}>
            <ReactQuill value={value} onChange={onChange} />
        </div>
    );
};

export default EditorForTableField;