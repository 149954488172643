import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {useUpdateGenderTrackingMutation} from "../../../../store/service/adbService";
import {setLoader} from "../../../../store/slice/dashboard";
import {useDispatch, useSelector} from "react-redux";
import {useErrorHandler} from "../../../../global/ErrorsHandler";
import MoreInfoModal from "../../../MoreInfoModal";

export const GenderTracking = ({id, genderData, variationsData, glossaryData, translations}) => {
    const dispatch = useDispatch()
    const [femalesEdit, setFemalesEdit] = useState(false)
    const [staffEdit, setStaffEdit] = useState(false)
    const [infoModal, setInfoModal] = useState(false)
    const [moreInfo, setMoreInfo] = useState(null)
    const user = useSelector((state) => state?.adbReducer?.user);
    const [currentItem, setCurrentItem] = useState({})

    const [
        updateGenderTracking,
        {
            data: updateGenderTrackingData,
            isError: updateGenderTrackingIsError,
            isLoading: updateGenderTrackingIsLoading,
            isSuccess: updateGenderTrackingIsSuccess,
            error: updateGenderTrackingError,
        },
    ] = useUpdateGenderTrackingMutation();

    useEffect(() => {
        const d = () => {
            dispatch(setLoader(false));
        };
        updateGenderTrackingIsLoading
            ? dispatch(setLoader(true))
            : d();
    }, [
        updateGenderTrackingIsLoading,
    ]);

    useEffect(() => {
        setFemalesEdit(false)
        setStaffEdit(false)
        setCurrentItem(null)
    }, [
        updateGenderTrackingIsSuccess,
        updateGenderTrackingIsError,
        genderData
    ]);


    useEffect(() => {
        setMoreInfo(glossaryData?.data?.filter(el => el?.section_name === "Gender Tracking")[0])
    }, [glossaryData])

    const [errorHandler, setErrorHandler] = useErrorHandler([
        updateGenderTrackingError
    ]);

    const submitHandler = () => {
        const dataUpdated = {
            number_females: currentItem?.number_females,
            total_number: currentItem?.total_number
        }

        if (currentItem) {
            updateGenderTracking({id, body: dataUpdated})
        } else {
            toast.error("Please enter summary", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }
    };

    return (
        <>
            <div className="project__gender">
                {infoModal && (
                    <MoreInfoModal
                        title={translations?.['environment_social_health_safety_and_security_obligations.gender_tracking.headers.gender_tracking']}
                        moreInfo={translations?.['environment_social_health_safety_and_security_obligations.gender_tracking.more_info.gender_tracking']}
                        close={()=> setInfoModal(false)}
                    />
                )}
                <div className="project__gender-content">
                    <div style={{display: "flex", alignItems: "center"}}>
                        <div className="project__block-subtitle">{translations?.['environment_social_health_safety_and_security_obligations.gender_tracking.headers.gender_tracking']}</div>
                        <span
                            className="project__block-more"
                            onClick={() => setInfoModal(!infoModal)}
                        >
                        <span className="img">
                            <img src="/assets/icons/info.svg" alt="info"/>
                        </span>
                        <span className="text">{translations?.['environment_social_health_safety_and_security_obligations.gender_tracking.titles.more_info']}</span>
                    </span>
                    </div>
                </div>
                <div className="project__gender-boxes">
                    <div className={`project__gender-box ${femalesEdit ? 'edit' : ''}`}>
                        <p className="project__gender-subtitle">
                            {translations?.['environment_social_health_safety_and_security_obligations.gender_tracking.titles.number_of_females_employed_on_the_project']}
                        </p>
                        {
                            femalesEdit ?
                                <div className="project__block-input project-table__field">
                                    <input
                                        type={"text"}
                                        maxLength={5}
                                        value={currentItem ? currentItem?.number_females : genderData?.gender_tracking_monitoring?.number_females}
                                        onChange={(e) => {
                                            let newText = e.target.value.replace(/\D/g, "")
                                            if (newText?.length < 6) {
                                                setCurrentItem((old) => ({
                                                    ...old,
                                                    number_females: newText,
                                                }));
                                            }
                                        }}
                                    />
                                </div> :
                                <span className="project__status-text">
                                    {genderData?.gender_tracking_monitoring?.number_females}
                                </span>
                        }
                        {
                            user?.permissions?.edit_project &&
                            <div className="project__block-btns">
                                {femalesEdit && (
                                    <div
                                        className="project__block-cancel"
                                        onClick={() => {
                                            setFemalesEdit(false)
                                            setCurrentItem((old) => ({...old, number_females: genderData?.gender_tracking_monitoring?.number_females}))
                                        }}
                                    >
                                        <img src="/assets/icons/close-mark.svg" alt="close"/>
                                        {translations?.['environment_social_health_safety_and_security_obligations.gender_tracking.actions.cancel']}
                                    </div>
                                )}
                                {femalesEdit ? (
                                    <button onClick={submitHandler} type="submit" className="project__block-save">
                                        <img src="/assets/icons/save-arrow.svg" alt="arrow"/>
                                        {translations?.['environment_social_health_safety_and_security_obligations.gender_tracking.actions.save']}
                                    </button>
                                ) : (
                                    <div
                                        onClick={() => {
                                            setFemalesEdit(true)
                                        }}
                                        className="project__block-update"
                                    >
                                        {translations?.['environment_social_health_safety_and_security_obligations.gender_tracking.actions.update']}
                                    </div>
                                )}
                            </div>
                        }
                    </div>
                    <div className={`project__gender-box ${staffEdit ? 'edit' : ''}`}>
                        <p className="project__gender-subtitle">
                            {translations?.['environment_social_health_safety_and_security_obligations.gender_tracking.titles.total_number_of_staff_employed_on_the_project']}
                        </p>
                        {
                            staffEdit ?
                                <div className="project__block-input project-table__field">
                                    <input
                                        type={"text"}
                                        maxLength={5}
                                        value={currentItem ? currentItem?.total_number : genderData?.gender_tracking_monitoring?.total_number}
                                        onChange={(e) => {
                                            let newText = e.target.value.replace(/\D/g, "")
                                            if (newText?.length < 6) {
                                                setCurrentItem((old) => ({
                                                    ...old,
                                                    total_number: newText,
                                                }));
                                            }
                                        }}
                                    />
                                </div> :
                                <span className="project__status-text">
                                    {genderData?.gender_tracking_monitoring?.total_number}
                                </span>
                        }
                        {
                            user?.permissions?.edit_project &&
                            <div className="project__block-btns">
                                {staffEdit && (
                                    <div
                                        className="project__block-cancel"
                                        onClick={() => {
                                            setCurrentItem((old) => ({...old, total_number: genderData?.gender_tracking_monitoring?.total_number}))
                                            setStaffEdit(false)
                                        }}
                                    >
                                        <img src="/assets/icons/close-mark.svg" alt="close"/>
                                        {translations?.['environment_social_health_safety_and_security_obligations.gender_tracking.actions.cancel']}
                                    </div>
                                )}
                                {staffEdit ? (
                                    <button onClick={submitHandler} type="submit" className="project__block-save">
                                        <img src="/assets/icons/save-arrow.svg" alt="arrow"/>
                                        {translations?.['environment_social_health_safety_and_security_obligations.gender_tracking.actions.save']}
                                    </button>
                                ) : (
                                    <div
                                        onClick={() => {
                                            setStaffEdit(true)
                                        }}
                                        className="project__block-update"
                                    >
                                        {translations?.['environment_social_health_safety_and_security_obligations.gender_tracking.actions.update']}
                                    </div>
                                )}
                            </div>
                        }
                    </div>

                </div>
            </div>
        </>
    )
}