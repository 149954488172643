import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import AuthWrapper from "../../components/AuthWrapper";
import {errorHandle} from "../../global/errorHandle";
import {setLoader} from "../../store/slice/dashboard";
import {setMfaMethodType} from "../../store/slice/mfAuth";
import {useLazyGetMFAuthPageQuery, useLazyMFAuthQuery} from "../../store/service/adbService";
import {formatEmail, formatPhoneNumber} from "../../global/utils";
import './index.scss';

const Authentication = ({    }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const route = useLocation().pathname;
    const [pageData, setPageData] = useState({});
    const mfaMethod = useSelector((state) => state.mfaReducer.mfa_method);
    const mfaToken = useSelector((state) => state.mfaReducer.mfa_token);

    const [getAuthPageData, {error: pageDataError}] = useLazyGetMFAuthPageQuery();
    const [mfAuthRequest, { isLoading,  isError: isErrorMfAuth, error: errorMfAuth}] = useLazyMFAuthQuery();

    const handleSubmit = async (type) => {
        await mfAuthRequest({
            type,
            mfa_token:mfaToken
        })
            .then(() => {
                dispatch(setMfaMethodType({
                    mfa_method_type:type,
                    mfa_confirm:true
                }));
                navigate(`/enter-code`);
            })
    }

    useEffect(() => {
        if (mfaToken && mfaMethod){
            getAuthPageData(mfaToken)
                .unwrap()
                .then(fulfilled => {
                    setPageData(fulfilled?.data);

                })
                .catch(rejected => console.error(rejected))
        }
        else if (localStorage.getItem("user_token")) {
            route === "/authentication" && navigate("/project-list");
        } else {
            route === "/authentication" && navigate("/login");
        }
    }, []);


    useEffect(() => {
        if (isErrorMfAuth){
            errorHandle(errorMfAuth)
        }
        if (pageDataError){
            errorHandle(pageDataError)
        }
    }, [isErrorMfAuth, errorMfAuth, pageDataError]);

    useEffect(() => {
        isLoading ? dispatch(setLoader(true)) : dispatch(setLoader(false));
    }, [ isLoading]);

    return(
        <>
            {Object.keys(pageData).length > 0 && (
              <AuthWrapper className='mf-auth'>
                <h1 className="auth__title mf-auth__title"> { pageData?.translations['headers.multi_factor_authentication']} </h1>
                <p className="auth__descr mf-auth__description">
                    { pageData?.translations['titles.receive_verification_code']}
                </p>
                  {pageData?.data.google && <button className="mf-auth__item" onClick={() => handleSubmit('google')}
                           disabled={!pageData?.data.google.available}>
                      <img className="mf-auth__item__img" src="/assets/icons/passwor-code.svg" alt="password-code"/>
                      <div className="mf-auth__item__content">
                          <h5>{pageData?.translations['headers.google_method']}</h5>
                          <p>
                              {pageData?.data.google.available ? pageData?.translations['titles.google_method_text'] : pageData?.data.google.message}
                          </p>
                      </div>
                      <img className="mf-auth__item__arrowIcon" src="/assets/icons/arrow-right.svg"
                           alt="arrow right icon"/>
                  </button>}
                  {pageData?.data.email && <button className="mf-auth__item" onClick={() => handleSubmit('email')}
                           disabled={!pageData?.data.email.available}>
                      <img className="mf-auth__item__img" src="/assets/icons/mail-checking.svg" alt="password-code"/>
                      <div className="mf-auth__item__content">
                          <h5>{formatEmail(pageData?.data.email.email)}</h5>
                          <p>
                              {pageData?.data.email.available ? pageData?.translations['titles.email_method_text'] : pageData?.data.email.message}
                          </p>
                      </div>
                      <img className="mf-auth__item__arrowIcon" src="/assets/icons/arrow-right.svg"
                           alt="arrow right icon"/>
                  </button>}
                  {pageData?.data.phone && <button className="mf-auth__item" onClick={() => handleSubmit('phone')}
                           disabled={!pageData?.data.phone.available}>
                      <img className="mf-auth__item__img" src="/assets/icons/phone-message-checking.svg"
                           alt="password-code"/>
                      <div className="mf-auth__item__content">
                          <h5>{formatPhoneNumber(pageData?.data.phone.phone)}</h5>
                          <p>
                              {pageData?.data.phone.available ? pageData?.translations['titles.phone_method_text'] : pageData?.data.phone.message}
                          </p>
                      </div>
                      <img className="mf-auth__item__arrowIcon" src="/assets/icons/arrow-right.svg"
                           alt="arrow right icon"/>
                  </button>}
            </AuthWrapper>
            )
            }
        </>

    )
}

export default Authentication;