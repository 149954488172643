import React, { useState, useEffect } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  useCreateProjectMutation,
  useGetAllSectionsMutation,
  useLazyGetAllCompaniesQuery,
} from "../../../store/service/adbService";
import { useErrorHandler } from "../../../global/ErrorsHandler";
import { toast } from "react-toastify";
import { setLoader } from "../../../store/slice/dashboard";

const customStyles = {
  menu: (provided, state = false) => ({
    ...provided,
    width: "100%",
    color: state.selectProps.menuColor,
    cursor: "pointer",
  }),

  control: (_, { selectProps: { width } }) => ({
    width: "100%",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    display: "flex",
    marginTop: 8,
    background: "#F9F9FB",
    borderRadius: 8,
    padding: 2,
    cursor: "pointer",
  }),
};

export const CreateProjectStep4 = ({translations}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [getAllSections, {data: allSections}] = useGetAllSectionsMutation()
  const [sections, setSections] = useState([
    {
      value: 1,
      label: translations?.['contract_key_data.contract_key_data.headers.contract_key_data'],
    },
    {
      value: 2,
      label: translations?.['executive_summary.executive_summary.headers.executive_summary'],
    },
    {
      value: 3,
      label: translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.headers.permits_licenses_and_approvals'],
    },
    {
      value: 4,
      label: translations?.['contract_start_up_activities_and_submittals.larp_approval.headers.larp_approval'],
    },
    {
      value: 5,
      label: translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.headers.larp_land_acquisition_resettlement_plan'],
    },
    {
      value: 6,
      label: translations?.['general_start_up_activities.general_start_up_activities.headers.general_start_up_activities'],
    },
    {
      value: 7,
      label: translations?.['general_start_up_activities.mobilization_activities.headers.mobilization_activities'],
    },
    {
      value: 8,
      label: translations?.['risk_management.risk_management.headers.risk_management'],
    },
    {
      value: 9,
      label: translations?.['project_monitoring.project_monitoring.headers.project_monitoring'],
    },
    {
      value: 10,
      label: translations?.['project_monitoring.major_project_milestones.headers.major_project_milestones'],
    },
    {
      value: 11,
      label: translations?.['project_monitoring.program_overview.headers.program_overview'],
    },
    {
      value: 12,
      label: translations?.['project_monitoring.critical_path.headers.critical_path'],
    },
    {
      value: 13,
      label: translations?.['project_monitoring.one_month_look_ahead.headers.one_month_look_ahead'],
    },
    {
      value: 14,
      label: translations?.['project_monitoring.variations_and_claims.headers.variations_and_claims'],
    },
    {
      value: 15,
      label: translations?.['project_monitoring.variation_register.headers.variation_register'],
    },
    {
      value: 16,
      label: translations?.['project_monitoring.claim_register.headers.claim_register'],
    },
    {
      value: 17,
      label: "Financial Management",
    },
    {
      value: 18,
      label: translations?.['payments.subcontractor_payments.headers.subcontractor_payments'],
    },
    {
      value: 19,
      label: translations?.['disputes.disputes.headers.disputes'],
    },
    {
      value: 20,
      label: translations?.['goods_equipment.goods_equipment.headers.goods_equipment'],
    },
    {
      value: 21,
      label: translations?.['engineering_design_documents.engineering_design_documents.headers.engineering_design_documents'],
    },
    {
      value: 22,
      label: translations?.['environment_social_health_safety_and_security_obligations.ssemp_activity_monitoring.headers.ssemp_activity_monitoring'],
    },
    {
      value: 23,
      label: translations?.['environment_social_health_safety_and_security_obligations.social_safeguard_monitoring.headers.social_safeguard_monitoring'],
    },
    {
      value: 24,
      label: translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_monitoring.headers.health_and_safety_monitoring'],
    },
    {
      value: 25,
      label: translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_statistics.headers.health_and_safety_statistics'],
    },
    {
      value: 26,
      label: translations?.['environment_social_health_safety_and_security_obligations.security_monitoring.headers.security_monitoring'],
    },
    {
      value: 27,
      label: translations?.['site_photographs.site_photographs.headers.site_photographs'],
    },
    {
      value: 28,
      label: translations?.['completion_of_work_and_contract_closure_activities_table.completion_of_work_and_contract_closure_activities_table.headers.completion_of_work_and_contract_closure_activities_table'],
    },
    {
      value: 29,
      label: translations?.['communications_and_reporting.communications_and_reporting.headers.communications_and_reporting'],
    },
    {
      value: 30,
      label: translations?.['project_monitoring.program_progress.headers.program_progress'],
    },
    {
      value: 31,
      label: translations?.['environment_social_health_safety_and_security_obligations.gender_tracking.headers.gender_tracking'],
    },
    {
      value: 32,
      label: translations?.['environment_social_health_safety_and_security_obligations.complaints_grievances.headers.complaints_grievances'],
    },
  ]);
  const [selectedDetails, setSelectedDetails] = useState([]);

  const [options, setOptions] = useState([]);

  const [state, setState] = useState({
    adb: null,
    executing: null,
    contractor: null,
    designConsultant: null,
    projectManager: null,
    period: "",
  });
  useEffect(() => {
    getAllSections()
  }, [])

  useEffect(() => {
    if (allSections?.data) {
      const newArray = Object.keys(allSections?.data).map(key => ({
        id: key,
        label: allSections?.data[key]
      }));
      setSections(newArray)
    }
  }, [allSections])
  const [
    createProject,
    {
      data: createData,
      isSuccess: createSuccess,
      error: createError,
      isError: createIsError,
      isLoading: createLoading,
    },
  ] = useCreateProjectMutation();

  const [
    getCompanies,
    {
      data: companies,
      error: companiesError,
      isLoading: companiesIsLoading,
      isError: companiesIsError,
      isSuccess: companiesIsSuccess,
    },
  ] = useLazyGetAllCompaniesQuery();

  useEffect(() => {
    getCompanies({
      per_page: 1000,
    });
  }, []);

  useEffect(() => {
    if (companies?.data?.data) {
      let data = companies?.data?.data?.map((item) => ({
        value: item?.id,
        label: item?.long_name,
      }));
      setOptions(data);
    }
  }, [companies]);
  useEffect(() => {
    companiesIsLoading || createLoading
      ? dispatch(setLoader(true))
      : dispatch(setLoader(false));
  }, [
    companiesIsLoading,
    createLoading,
  ]);
  const [errorHandler, setErrorHandler] = useErrorHandler([createError]);
  useEffect(() => {
    if (createSuccess) {
      toast.success("Project created", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      localStorage.removeItem("create-data");
      navigate("/project-list");
    }
  }, [createSuccess]);
  const submitHandler = (e) => {
    e.preventDefault();
    let s = {};
    for (let i = 0; i < selectedDetails?.length; i++) {
      s = {...s, [selectedDetails[i]?.id]: selectedDetails[i]?.value}
    }
    if (selectedDetails.length !== 0) {
      createProject({
        ...JSON.parse(localStorage.getItem("create-data")),
        location_id: JSON.parse(localStorage.getItem("create-data"))
          ?.location_id?.value,
        adb_id: JSON.parse(localStorage.getItem("create-data"))?.adb_id?.value,
        consultant_id: JSON.parse(localStorage.getItem("create-data"))?.consultant_id?.value,
        engineer_id: JSON.parse(localStorage.getItem("create-data"))?.engineer_id?.value,
        implementing_agency_id: JSON.parse(localStorage.getItem("create-data"))?.implementing_agency_id?.value,
        supplier_id: JSON.parse(localStorage.getItem("create-data"))?.supplier_id?.value,
        contractor_id: JSON.parse(localStorage.getItem("create-data"))
          ?.contractor_id?.value,
        executing_agency_id: JSON.parse(localStorage.getItem("create-data"))
          ?.executing_agency_id?.value,
        design_consultant_id: JSON.parse(localStorage.getItem("create-data"))
          ?.design_consultant_id?.value,
        project_manager_id: JSON.parse(localStorage.getItem("create-data"))
          ?.project_manager_id?.value,
        contract_type_id: JSON.parse(localStorage.getItem("create-data"))
          ?.contract_type_id?.value,
        ...s,
        sector_id: JSON.parse(localStorage.getItem("create-data"))?.sector_id
          ?.value,
        ...s,
      });
    } else {
      toast.error("Select at least 1 section", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };
  return (
    <form className="create__form">
      <div className="create__form-content">
        <h6 className="create__form-subtitle">
          {translations?.['main.edit_projects_details.titles.select_required_sections_for_this_project']}
        </h6>
        <div className="s-page__details">
          <div className="contact__modal-projectBox">
            <div className="contact__modal-checkbox">
              <div className="a-checkbox">
                <input
                  id="all"
                  key={selectedDetails}
                  type="checkbox"
                  checked={selectedDetails?.length === sections?.length}
                  onChange={(e) => {
                    if (e?.target?.checked) {
                      const newArray = sections?.map(el => {
                        return {
                          [el?.id]: 1,
                          value: 1,
                          label: el?.label,
                          id: el?.id
                        }
                      })
                      setSelectedDetails(newArray)
                    } else {
                      setSelectedDetails([]);
                    }
                  }}
                />
                <label htmlFor={"all"}>{translations?.['main.edit_projects_details.titles.select_all']}</label>
              </div>
            </div>
          </div>
          {sections?.map((el) => {
            return (
              <div key={el?.id} className="contact__modal-projectBox">
                <div className="contact__modal-checkbox">
                  <div className="a-checkbox">
                    <input
                      key={selectedDetails}
                      id={el?.id}
                      type="checkbox"
                      checked={
                        selectedDetails?.filter(
                          (item) => item?.label === el?.label
                        )[0]
                      }
                      onChange={(e) => {
                        if (e?.target?.checked) {
                          setSelectedDetails((old) => [
                            ...old,
                            {
                              [el?.id]: 1,
                              value: 1,
                              label: el?.label,
                              id: el?.id
                            },
                          ]);
                        } else {
                          setSelectedDetails(
                            selectedDetails?.filter(
                              (item) => item?.id !== el?.id
                            )
                          );
                        }
                      }}
                    />
                    <label htmlFor={el?.id}>{el?.label}</label>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="create__form-br"></div>
      <div className="create__form-btns">
        <div
          className="create__form-back"
          onClick={() => {
            navigate("/create-project/step-3");
          }}
        >
          {translations?.['main.edit_projects_details.actions.back']}
        </div>
        <button onClick={submitHandler} className="create__form-next">
          <span>{translations?.['main.edit_projects_details.actions.next']}</span>
          <span>
            <img src="/assets/icons/next-icon.svg" alt="icon" />
          </span>
        </button>
      </div>
    </form>
  );
};
