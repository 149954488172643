import React from "react";
import Select from "react-select";
import moment from "moment/moment";
import {useDispatch} from "react-redux";
import DatePicker from "react-datepicker";
import AxelInput from "../../UI/AxelInput";
import exportSectionAsCSV from "../../global/exportSectionAsCSV";
import CustomDatePicker from "../../components/CustomDatePicker";

const customStyles = {
    menu: (provided) => ({
        ...provided,
        width: "252px",
        cursor: "pointer",
        zIndex: 50,
        overflow: "hidden",
    }),

    control: () => ({
        width: "252px",
        border: "1px solid #0000001A",
        display: "flex",
        background: "#FFFFFF",
        borderRadius: 8,
        padding: 6,
    }),
};

const Toolbar = (
    {
        searchTerm,
        setSearchTerm,
        searchByTable,
        setSearchByTable,
        searchErrorCode,
        setSearchErrorCode,
        startDate,
        endDate,
        event,
        setDateRange,
        translations,
        setEvent,
        setTable,
        table,
        exportFileName,
        exportEndpoint,
        debouncedSearchTerm,
        debouncedSearchErrorCode,
        debouncedSearchByPermits,
        isHasSelect=true,
        isSearchPermits=false,
        isHasSecondSelect=false,
        isSearchErrorCode=false,
        selectTitle,
        secondSelectTitle,
        selectOptions,
        secondSelectOptions,
        searchPlaceholder,
        searchErrorCPlaceholder,
        searchPermitsPlaceholder,
    }) =>{
   const localeLan = localStorage.getItem("local_lan" );
 moment.locale(localeLan);
    const dispatch = useDispatch();
    const exportData = () => {
        const downloadCSVUrl =`${exportEndpoint}?
        ${debouncedSearchTerm ? `search=${debouncedSearchTerm}` : ""}
        ${debouncedSearchByPermits ? `&project=${debouncedSearchByPermits}` : ""}
        ${startDate !== null ? `&date_from=${moment(startDate).format('YYYY-MM-DD')}` : ""}
        ${endDate !== null ? `&date_to=${ moment(endDate).format('YYYY-MM-DD')}` : ""}
        ${event ? `&event=${event.value}` : ""}
        ${debouncedSearchErrorCode ? `&error_code=${debouncedSearchErrorCode}` : ""}
        ${table ? `&table=${table.value}` : ""}`
        exportSectionAsCSV(downloadCSVUrl, exportFileName, dispatch);
    }

    return (
        <div className='userAccessActivity__nav' >
            <AxelInput
                className="userAccessActivity__search"
                src={'/assets/icons/search.svg'}
                placeholder={searchPlaceholder}
                value={searchTerm}
                handler={(e) => {
                    setSearchTerm(e.target.value)
                }}
            />
            <div  className='userAccessActivity__nav__wrapper' >
                <div className='userAccessActivity__calendar reporting-select'>
                    <img
                        className="online__calendar-svg"
                        src="/assets/icons/calendar.svg"
                        alt="calendar"
                    />
                    <CustomDatePicker
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        placeholderText={translations['user_access_activity.index.actions.date_range']}
                        onChange={(date) => {
                            setDateRange(date);
                        }}
                        isClearable={true}
                    />
                </div>
                {isSearchPermits && <AxelInput
                    className="userAccessActivity__search"
                    src={'/assets/icons/search.svg'}
                    placeholder={searchPermitsPlaceholder}
                    value={searchByTable}
                    handler={(e) => {
                        setSearchByTable(e.target.value)
                    }}
                />}
                {isSearchErrorCode && <AxelInput
                    className="userAccessActivity__search userAccessActivity__search_width"
                    src={'/assets/icons/search.svg'}
                    placeholder={searchErrorCPlaceholder}
                    value={searchErrorCode}
                    handler={(e) => {
                        setSearchErrorCode(e.target.value)
                    }}
                />}
                {isHasSecondSelect && <Select
                    styles={{
                        ...customStyles,
                        menuPortal: (base) => ({...base, zIndex: 9999}),
                    }}
                    onChange={(value) => setTable(value)}
                    isSearchable={true}
                    placeholder={secondSelectTitle}
                    options={secondSelectOptions}
                    isClearable={true}
                />}
                {isHasSelect && <Select
                    styles={{
                        ...customStyles,
                        menuPortal: (base) => ({...base, zIndex: 9999}),
                    }}
                    options={selectOptions}
                    onChange={(value) => setEvent(value)}
                    isSearchable={true}
                    isClearable={true}
                    placeholder={selectTitle}
                />}
                <button className='userAccessActivity__btnExport' onClick={exportData}>
                    <img src="/assets/icons/export.svg" alt="Export icon" />
                    {translations['user_access_activity.index.actions.export']}
                </button>
            </div>
        </div>
    )
}

export default  Toolbar;