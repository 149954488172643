import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { useErrorHandler } from "../../global/ErrorsHandler";
import {
    useGetAllRegionsQuery,
    useLazyGetAllRegionsQuery,
    useLazyGetContractTypeQuery
} from "../../store/service/adbService";

const customStyles = {
    menu: (provided, state = false) => ({
        ...provided,
        width: "100%",
        color: state.selectProps.menuColor,
        cursor: "pointer",
    }),

    control: (_, { selectProps: { width } }) => ({
        width: "100%",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        display: "flex",
        marginTop: 8,
        background: "#FFFFFF",
        height: 58,
        borderRadius: 8,
        cursor: "pointer",
    }),
};

export const ContractTypeModal = ({
                                 title,
                                 createHook,
                                 updateHook,
                                 setModal,
                                 currentItem,
                             }) => {
    const [state, setState] = useState(
        currentItem ?? {
            name: null,
        }
    );
    const [currentPage, setCurrentPage] = useState(1)

    const [getContractTypes, {
        data: contractTypes,
        isError: contractTypesIsError,
        isLoading: contractTypesIsLoading,
        isSuccess: contractTypesIsSuccess,
        error: contractTypesError,
    }] = useLazyGetContractTypeQuery();

    useEffect(() => {
        getContractTypes({
            per_page: 10,
            page: currentPage,
        })
    }, [currentPage])

    const [errorsHandler, setErrorsHandler] = useErrorHandler([contractTypesError]);
    const inputHandler = (e) => {
        setState((prev) => ({ ...prev, [e.target.id]: e.target.value }));
    };
    const submitHandler = (e) => {
        e.preventDefault();
        let s = [];
        for (let key in state) {
            if (!state[key] || state[key] === "") {
                s.push("false");
                toast.error("Please enter " + key?.split('_')?.join(' '), {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            }
        }

        if (s.length === 0) {
            currentItem
                ? updateHook({
                    id: currentItem?.id,
                    body: {
                        name: state?.name,
                    },
                })
                : createHook(state);
        }
    };

    return (
        <div className="s-page__modal">
            <div className="s-page__modal-content">
                <div className="s-page__modal-header">
                    <div className="s-page__modal-title">
                        {currentItem ? "Update " + title : "Create " + title}
                    </div>
                    <div className="s-page__modal-close" onClick={() => setModal(false)}>
                        <img src="/assets/icons/close-mark.svg" alt="close" />
                    </div>
                </div>
                <form
                    action="#"
                    onSubmit={submitHandler}
                    className="s-page__modal-form"
                >
                    <div className="s-page__modal-fields">
                        <div className="s-page__modal-field">
                            <label htmlFor="id">{"Contract Type Name"}</label>
                            <input
                                id="name"
                                type="text"
                                value={state?.name ?? ""}
                                placeholder="Enter name"
                                onChange={inputHandler}
                                maxLength={190}
                            />
                        </div>
                    </div>
                    <div className="s-page__modal-button">
                        <button>{currentItem ? "Update" : "Create"}</button>
                    </div>
                </form>
            </div>
            <div className="s-page__modal-bg" onClick={() => setModal(false)}></div>
        </div>
    );
};
