import React from "react";
import classNames from "classnames";

export default function Wrapper({ children, classname, pb = "pb-[300px]" }) {
  return (
    <main className="main">
      <div
          className={classNames('wrapper pl-[100px] pt-[35px] pr-[25px] min-h-[100vh]', classname, pb)}
      >
        {children}
      </div>
    </main>
  );
}
