import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { useErrorHandler } from "../../../global/ErrorsHandler";
import {
    useUpdateProjectExecutiveSummaryMutation,
    useUpdateReportCoverMutation,
} from "../../../store/service/adbService";
import { setLoader } from "../../../store/slice/dashboard";
import { useDispatch, useSelector } from "react-redux";
import MoreInfoModal from "../../MoreInfoModal";

export const ExecutiveSummaryProject = ({
                                            glossaryData,
                                            data,
                                            id,
                                            refExecutiveSummary,
                                            translations
                                        }) => {
    const dispatch = useDispatch();
    const [executiveSummary, setExecutiveSummary] = useState(true);
    const [text, setText] = useState(data ?? "");
    const [edit, setEdit] = useState(false);
    const [moreInfo, setMoreInfo] = useState(null);
    const user = useSelector((state) => state?.adbReducer?.user);
    const [prevText, setPrevText] = useState(data ?? "");
    const [infoModal, setInfoModal] = useState(false);
    const expandedRef = useRef(null);
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        setMoreInfo(
            glossaryData?.data?.filter(
                (el) => el?.section_name === "Executive Summary"
            )[0]
        );
        if (data) {
            setText(data ?? "");
            setPrevText(data ?? "");
        }
    }, [data]);

    const [
        updateExecutiveSummary,
        {
            data: executiveSummaryData,
            isError: executiveSummaryIsError,
            error: executiveSummaryError,
            isSuccess: executiveSummaryIsSuccess,
            isLoading: executiveSummaryLoading,
        },
    ] = useUpdateProjectExecutiveSummaryMutation();

    const [
        updateReportCover,
        {
            data: updateReportCoverData,
            isError: updateReportCoverIsError,
            isLoading: updateReportCoverIsLoading,
            isSuccess: updateReportCoverIsSuccess,
            error: updateReportCoverError,
        },
    ] = useUpdateReportCoverMutation();

    useEffect(() => {
        const d = () => {
            dispatch(setLoader(false));
            setEdit(false);
        };

        updateReportCoverIsLoading ? dispatch(setLoader(true)) : d();
    }, [updateReportCoverIsLoading]);

    const [errorHandler, setErrorHandler] = useErrorHandler([
        executiveSummaryError,
        updateReportCoverError,
    ]);

    const submitHandler = (e) => {
        e.preventDefault();

        if (text.length > 0) {
            updateReportCover({ id: id, body: { executive_summary: text } });
        } else {
            toast.error("Please enter summary", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }
    };

    return (
        <>
            {infoModal && (
                <MoreInfoModal
                    title={translations?.['executive_summary.executive_summary.headers.executive_summary']}
                    moreInfo={translations?.['executive_summary.executive_summary.more_info.executive_summary']}
                    close={()=> setInfoModal(false)}
                />
            )}

            <div
                className="project__white-box"
                ref={refExecutiveSummary}
                id="executive_summary"
            >
                <div className={`project__block ${executiveSummary ? "" : "close"}`}>
                    <div className="d-f">
                        <div
                            className="project__block-title"
                            onClick={() => setExecutiveSummary(!executiveSummary)}
                        >
                            <span>{translations?.['executive_summary.executive_summary.headers.executive_summary']}</span>
                        </div>
                        <span
                            className="project__block-more"
                            onClick={() => setInfoModal(!infoModal)}
                        >
              <span className="img">
                <img src="/assets/icons/info.svg" alt="info" />
              </span>
              <span className="text">{translations?.['executive_summary.executive_summary.titles.more_info']}</span>
            </span>
                    </div>

                    <div className="project__block-hr"></div>
                    <div
                        className={`project__block-content executive-summary ${
                            edit ? "edit" : ""
                        }`}
                    >
                        <div className="gray-box">
                            <form onSubmit={submitHandler} className="content">
                                <div className="text">
                                    {edit ? (
                                        <textarea
                                            className={`${
                                                expanded ? "project__block-expandedTextArea" : ""
                                            }`}
                                            ref={expandedRef}
                                            onFocus={() => setExpanded(true)}
                                            rows={5}
                                            defaultValue={text ? text: ''}
                                            placeholder={translations?.['executive_summary.executive_summary.placeholder.please_enter']}
                                            maxLength={2000}
                                            onChange={(e) => {
                                                setText(e.target.value);
                                            }}
                                        />
                                    ) : (
                                        <span>{text}</span>
                                    )}
                                </div>
                                {user?.permissions?.edit_project && (
                                    <div className="project__block-btns">
                                        {edit && (
                                            <div
                                                className="project__block-cancel"
                                                onClick={() => {
                                                    setText(prevText);
                                                    setEdit(false);
                                                }}
                                            >
                                                <img src="/assets/icons/close-mark.svg" alt="close" />
                                                {translations?.['executive_summary.executive_summary.actions.cancel']}
                                            </div>
                                        )}
                                        {edit ? (
                                            <button
                                                onClick={() => setExpanded(false)}
                                                type="submit"
                                                className="project__block-save"
                                            >
                                                <img src="/assets/icons/save-arrow.svg" alt="arrow" />
                                                {translations?.['executive_summary.executive_summary.actions.save']}
                                            </button>
                                        ) : (
                                            <div
                                                onClick={() => {
                                                    setEdit(true);
                                                }}
                                                className="project__block-update"
                                            >
                                                {translations?.['executive_summary.executive_summary.actions.update']}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
