import {useDispatch} from "react-redux";
import React, {useEffect, useState} from "react";
import SectionTabContent from "./SectionTabContent";
import TemplatesTabContent from "./TemplatesTabContent";
import DocumentsTabContent from "./DocumentsTabContent";
import MoreInfoModal from "../../components/MoreInfoModal";
import {useLazyGetCMPPageQuery} from "../../store/service/adbService";
import {setLoader} from "../../store/slice/dashboard";
import {errorHandle} from "../../global/errorHandle";
import "./index.scss";

export default function ContractManagement() {
  const dispatch = useDispatch();
  const [tab, setTab] = useState(1);
  const [moreModal, setMoreModal] = useState(false);
  const [translations, setTranslations] = useState(false);

  const [getCMPpage, {
    data: CMPpageData,
    isLoading,
    isSuccess,
    isError,
    error
  }] = useLazyGetCMPPageQuery()

  useEffect(()=>{
    getCMPpage();
  },[])

  useEffect(()=>{
    if (isSuccess) {
      setTranslations(CMPpageData?.data?.translations)
    }

  },[isSuccess,CMPpageData])

  useEffect(() => {
        if (isError) {
          errorHandle(error);
        }
      }, [isError, error])

  useEffect(() => {
    isLoading ?
      dispatch(setLoader(true)) :
      dispatch(setLoader(false));
  }, [isLoading]);


  const handleTab = (tabIndex) => {
    setTab(tabIndex);
  };

  return (
    <div className="wrapper pl-[100px] pb-[150px] pt-[35px] pr-[25px] min-h-[100vh]">
      {moreModal && <MoreInfoModal
          title={translations?.['index.index.headers.contract_management_plan']}
          moreInfo={translations?.['index.index.more_info.more_info']}
          close={()=>setMoreModal(false)}
      />}
      <div className="contract__management">
        <div className="contract__management-title">
          {translations?.['index.index.headers.contract_management_plan']}
          <span
            onClick={() => {
              setMoreModal(true);
            }}
            className="project__block-more"
          >
            <span className="img">
              <img src="/assets/icons/info.svg" alt="info" />
            </span>
            <span className="text">{translations?.['index.index.titles.more_info']}</span>
          </span>
        </div>
        <div className="contract__management-tabs">
          <div
            className={`contract__management-tab ${tab === 1 ? "active" : ""}`}
            data-tab={true}
            onClick={()=>handleTab(1)}
          >
            {translations?.['index.index.titles.custom_cmp']}
          </div>
          <div
            className={`contract__management-tab ${tab === 2 ? "active" : ""}`}
            data-tab={false}
            onClick={()=>handleTab(2)}
          >
            {translations?.['index.index.titles.cmp_templates']}
          </div>
          <div
              className={`contract__management-tab ${tab === 3 ? "active" : ""}`}
              data-tab={false}
              onClick={()=>handleTab(3)}
          >
            {translations?.['index.index.titles.report_documents']}
          </div>
        </div>
        {tab === 1 && <SectionTabContent
            downloadTitle={translations?.['custom_cmp.index.actions.download_cmp']}
            selectAllLabelsTranslation={translations?.['custom_cmp.index.actions.select_all']}
        />}
        {tab === 2 && <TemplatesTabContent translations={translations} />}
        {tab === 3 && <DocumentsTabContent translations={translations} />}
      </div>
    </div>
  );
}
