import React, {useEffect, useState} from 'react';
import InfoModal from "../InfoModal";
import {useSelector} from "react-redux";
import MoreInfoModal from "../MoreInfoModal";

const Collapse = ({
  currentRef,
  title,
  subTitle,
  moreInfoTitle = 'More Info',
  glossaryData,
  data,
  currentSectionName,
  isReportsShown,
  showUploadBtn,
  onClickShowUploadBtn,
  onClickShowUploadBtnTitle,
  hasMoreInfo = true,
  children, isReportSubmitted,
  isQuarterReport,
    moreInfoData
}) => {
    const user = useSelector((state) => state?.adbReducer?.user);
    const [infoModal, setInfoModal] = useState(false);
    const [show, setShow] = useState(true);
    const [moreInfo, setMoreInfo] = useState(null);

    useEffect(() => {
        setMoreInfo(glossaryData?.data?.filter((el) => el?.section_name === currentSectionName)[0]);
    }, [data]);

    return (<div className={`project__white-box ${show ? "" : "close"}`} style={{padding: '0 30px'}}>
        <div className="project__site">
            {infoModal && (
                moreInfoData ?
                        <MoreInfoModal
                            title={title}
                            moreInfo={moreInfoData}
                            close={() => setInfoModal(!infoModal)}
                        /> :
                        <InfoModal moreInfo={moreInfo} close={() => setInfoModal(!infoModal)}/>
            )}
            <div
                ref={currentRef}
                id={currentRef}
                className="project__block-subtitles"
            >
                <div className="d-f">
                    <div
                        onClick={() => setShow(!show)}
                        className="project__block-title data__title"
                    >
                        <span>{title}</span>
                    </div>
                    {
                        hasMoreInfo &&
                        <span
                            onClick={() => setInfoModal(true)}
                            className="project__block-more"
                        >
                          <span className="img">
                            <img src="/assets/icons/info.svg" alt="info"/>
                          </span>
                          <span className="text">{moreInfoTitle}</span>
                        </span>
                    }
                </div>
                {
                    isReportsShown &&
                    <div className="project__program-reports">
                        <img src="/assets/icons/address-book.svg" alt=""/>
                        <span>Show previous reports</span>
                    </div>
                }
                {
                    user?.permissions?.edit_project && showUploadBtn && !isReportSubmitted &&
                    <div
                        onClick={onClickShowUploadBtn}
                        className="project__block-add-row"
                    >
                        <span>
                          <img src="/assets/icons/blue-upload.svg" alt="upload"/>
                        </span>
                        <span>{onClickShowUploadBtnTitle || 'Upload file'} </span>
                    </div>
                }
            </div>
            <div className="project__site_content">
                {subTitle &&  <p className="project__site-subtitle">{subTitle}</p>}
                {children}
            </div>
        </div>
    </div>);
};

export default Collapse;