import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import CustomDatePicker from "../../CustomDatePicker";
import {isValidValue} from "../../../global/isValidValue";

export const EditProjectStep2 = ({setSteps, translations}) => {
    const localeLan = localStorage.getItem("local_lan" );
    moment.locale(localeLan);
    const allEnterDatas = JSON.parse(localStorage.getItem("all-edit-data"));
    const enterDatas = JSON.parse(localStorage.getItem("edit-data"));
    const [dataTr, setDataTr] = useState([])

    const [effectiveDate, setEffectiveDate] = useState(
        enterDatas?.contract_commencement_effective_date ?? allEnterDatas?.contract_details?.contract_commencement_effective_date ?? null
    );
    const [contractDateAcceptance, setContractDateAcceptance] = useState(
        enterDatas?.contract_date_issuance_letter_acceptance?? allEnterDatas?.contract_details?.contract_date_issuance_letter_acceptance ?? null
    );
    const [contractSignDate, setContractSignDate] = useState(
        enterDatas?.contract_signed_date ?? allEnterDatas?.contract_details?.contract_signed_date ?? null
    );

    const [period, setPeriod] = useState(enterDatas?.contracts_defects_liability_period ?? allEnterDatas?.contract_details?.contracts_defects_liability_period ?? "")
    const [contractEffectiveDate, setConctractEffectiveDate] = useState(enterDatas?.contract_effective_date ?? allEnterDatas?.contract_details?.contract_loan_effective_date ?? null);
    const [contractClosingDate, setContractClosingDate] = useState(enterDatas?.contract_loan_closing_date ?? allEnterDatas?.contract_details?.contract_loan_closing_date ?? null);

    const [contractTime, setContractTime] = useState(
        enterDatas?.contract_time_for_completion?? allEnterDatas?.contract_details?.contract_time_for_completion ?? null
    );
    const [originalCompletionDate, setOriginalCompletionDate] = useState(enterDatas?.original_contract_completion_date?? allEnterDatas?.contract_details?.original_contract_completion_date ?? null);

    const submitHandler = (e) => {
        e.preventDefault();
        let body = {
            contract_date_issuance_letter_acceptance: moment(
                contractDateAcceptance
            ).format("YYYY-MM-DD"),
            contract_signed_date: moment(contractSignDate).format("YYYY-MM-DD"),
            contract_commencement_effective_date: moment(effectiveDate).format("YYYY-MM-DD"),
            contract_time_for_completion: contractTime,
            contracts_defects_liability_period: period,
            contract_loan_effective_date: moment(contractEffectiveDate).format("YYYY-MM-DD"),
            contract_loan_closing_date: moment(contractClosingDate).format("YYYY-MM-DD"),
        };

        let s = [];
        for (let key in body) {
            if (!isValidValue(body[key]) || body[key] === "Invalid date") {
                toast.error("Please enter " + key?.split('_')?.join(' '), {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
                s.push("false");
            }
            if (body[key] < 0) {
                toast.error("Please enter a valid value at " + key?.split('_')?.join(' ') + ". Negative numbers or digits are not allowed", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
                s.push("false");
            }
        }
        if (s.length === 0) {
            localStorage.setItem(
                "edit-data",
                JSON.stringify({
                    ...JSON.parse(localStorage.getItem("edit-data")),
                    ...body,
                })
            );
            setSteps(3)
        }
    };

    useEffect(() => {
        if(translations){
            setDataTr(translations);
        }
    }, [translations])

    useEffect(() => {
        let d = effectiveDate ? new Date(effectiveDate) : null;
        d?.setDate(d.getDate() + (contractTime - 1 ));
        setOriginalCompletionDate(d);
    }, [contractTime]);


    return (
        <form className="create__form">
            <div className="create__form-content">
                <div className="create__form-row">
                    <div className="create__form-label">
                        {dataTr?.['main.edit_projects_details.titles.contract_date_of_issuance_of_letter_of_acceptance']}
                    </div>
                    <div className="create__form-input calendar">
                        <CustomDatePicker
                            portalId="root-portal"
                            autoComplete="off"
                            id="contract_date_issuance_letter_acceptance"
                            onChange={(date) => setContractDateAcceptance(date)}
                            selected={
                                contractDateAcceptance ? new Date(contractDateAcceptance) : null
                            }
                        />
                        <label htmlFor="contract_date_issuance_letter_acceptance">
                            {contractDateAcceptance && moment(contractDateAcceptance, "LL").isValid()
                                ? moment(contractDateAcceptance).format("LL")
                                : "Choose date"}
                        </label>
                        <img src="/assets/icons/calendar.svg" alt="calendar" />
                    </div>
                </div>
                <div className="create__form-row">
                    <div className="create__form-label">{dataTr?.['main.edit_projects_details.titles.contract_signing_date']}</div>
                    <div className="create__form-input calendar">
                        <CustomDatePicker
                            portalId="root-portal"
                            autoComplete="off"
                            id="contract_signed_date"
                            selected={
                                contractSignDate ? new Date(contractSignDate) : null
                            }
                            onChange={(date) => setContractSignDate(date)}
                        />
                        <label htmlFor="contract_signed_date">
                            {contractSignDate && moment(contractSignDate, "LL").isValid()
                                ? moment(contractSignDate).format("LL")
                                : "Choose date"}
                        </label>
                        <img src="/assets/icons/calendar.svg" alt="calendar" />
                    </div>
                </div>
                <div className="create__form-row">
                    <div className="create__form-label">{dataTr?.['main.edit_projects_details.titles.contract_commencement_effective_date']}</div>
                    <div className="create__form-input calendar">
                        <CustomDatePicker
                            portalId="root-portal"
                            autoComplete="off"
                            id="contract_effective_date"
                            selected={
                                effectiveDate ? new Date(effectiveDate) : null
                            }
                            onChange={(date) => setEffectiveDate(date)}
                        />
                        <label htmlFor="contract_effective_date">
                            {effectiveDate &&  moment(effectiveDate, "LL").isValid()
                                ? moment(effectiveDate).format("LL")
                                : "Choose date"}
                        </label>
                        <img src="/assets/icons/calendar.svg" alt="calendar" />
                    </div>
                </div>
                <div className="create__form-row">
                    <div className="create__form-label">{dataTr?.['main.edit_projects_details.titles.contract_time_for_completion']}</div>
                    <div className="create__form-input">
                        <input
                            type="number"
                            placeholder="Enter number of days"
                            id="contract_time_for_completion"
                            defaultValue={contractTime}
                            onChange={(e) => setContractTime(e.target.value)}
                            maxLength={190}
                        />
                    </div>
                </div>
                <div className="create__form-row">
                    <div className="create__form-label">
                        {dataTr?.['main.edit_projects_details.titles.original_contract_completion_date']}
                    </div>
                    <div className="create__form-input calendar">
                        <CustomDatePicker
                            portalId="root-portal"
                            autoComplete="off"
                            selected={
                                (originalCompletionDate) ? new Date(originalCompletionDate): null
                            }
                            onChange={(date) => setOriginalCompletionDate(date)}
                            disabled
                        />
                        <label>
                            {originalCompletionDate
                                && moment(originalCompletionDate, 'LL').isValid() ?
                                    moment(originalCompletionDate).format("LL")
                                    : "Calculate"}
                        </label>
                    </div>
                </div>
                <div className="create__form-row">
                    <div className="create__form-label">
                        {dataTr?.['main.edit_projects_details.titles.contracts_defects_liability_period']}
                    </div>
                    <div className="create__form-input">
                        <input
                            type="number"
                            value={period}
                            onChange={(e) =>
                                setPeriod(e.target.value)
                            }
                            placeholder="Enter number of days"
                            maxLength={190}
                        />
                    </div>
                </div>
                <div className="create__form-row">
                    <div className="create__form-label">
                            {dataTr?.['main.edit_projects_details.titles.contract_adb_loan_effective_date']}
                    </div>
                    <div className="create__form-input calendar">
                        <CustomDatePicker
                            portalId="root-portal"
                            autoComplete="off"
                            id="contract_loan_effective_date"
                            selected={
                                contractEffectiveDate ? new Date(contractEffectiveDate) : null
                            }
                            onChange={(date) => setConctractEffectiveDate(date)}
                        />
                        <label htmlFor="contract_loan_effective_date">
                            {contractEffectiveDate &&  moment(contractEffectiveDate).format("LL")
                                ? moment(contractEffectiveDate).format("LL")
                                : "Choose date"}
                        </label>
                        <img src="/assets/icons/calendar.svg" alt="calendar" />
                    </div>
                </div>
                <div className="create__form-row">
                    <div className="create__form-label">
                        {dataTr?.['main.edit_projects_details.titles.contract_adb_loan_closing_date']}
                    </div>
                    <div className="create__form-input calendar">
                        <CustomDatePicker
                            portalId="root-portal"
                            autoComplete="off"
                            id="contract_loan_closing_date"
                            selected={
                                contractClosingDate ? new Date(contractClosingDate) : null
                            }
                            onChange={(date) => setContractClosingDate(date)}
                        />
                        <label htmlFor="contract_loan_closing_date">
                            {contractClosingDate &&  moment(contractClosingDate, 'LL').isValid()
                                ? moment(contractClosingDate).format("LL")
                                : "Choose date"}
                        </label>
                        {/*<img src="/assets/icons/calendar.svg" alt="calendar" />*/}
                    </div>
                </div>
            </div>
            <div className="create__form-br"></div>
            <div className="create__form-btns">
                <div
                    className="create__form-back"
                    onClick={() => {
                        setSteps(1);
                    }}
                >
                    {dataTr?.['main.edit_projects_details.actions.back']}
                </div>
                <button onClick={submitHandler} className="create__form-next">
                    <span>{dataTr?.['main.edit_projects_details.actions.next']}</span>
                    <span>
            <img src="/assets/icons/next-icon.svg" alt="icon" />
          </span>
                </button>
            </div>
        </form>
    );
};
