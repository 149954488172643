import {toast} from "react-toastify";
import {isNaN, parseInt} from "lodash";
import {useDispatch} from "react-redux";
import React, {useEffect, useRef, useState} from 'react';
import {setLoader} from "../../store/slice/dashboard";
import {
    useUpdateNotificationTriggersMutation,
    useUpdateSectionItemNotifyMutation
} from "../../store/service/adbService";
import Modal from "../../components/Modal";
import AxelInput from "../../UI/AxelInput";
import AxelButton from "../../UI/AxelButton";
import AxelCheckbox from "../../UI/AxelCheckbox";

const NotificationItems = ({itemData, updateSections}) => {
    const dispatch = useDispatch();
    const modalRef = useRef(null);
    const headerRef = useRef(null);
    const [days, setDays] = useState(1);
    const [data, setData] = useState({});
    const [triggers, setTriggers] = useState([]);
    const [tempTriggers, setTempTriggers] = useState([]);
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [isOpenTimeModal, setIsOpenTimeModal] = useState(false);
    const [notificationsId, setNotificationsId] = useState(null);

    const maxDays = 999999;

    const [updateSectionItemNotify, {
        data: itemNotifyData,
        isSuccess: itemNotifyIsSuccess,
        isLoading: itemNotifyIsLoading,
        isFetching: itemNotifyIsFetching,
    }] = useUpdateSectionItemNotifyMutation();

    const [updateNotificationTriggers, {
        data: triggersData,
        isSuccess: triggersIsSuccess,
        isLoading: triggersIsLoading,
        isFetching: triggersIsFetching,
    }] = useUpdateNotificationTriggersMutation();

    const updateSectionItem = (sectionId) => {
        updateSectionItemNotify(sectionId);
    }
    const saveTriggers = async (notifId) => {
        const formData = new FormData();
        const triggers = tempTriggers;

        Object.keys(triggers).forEach(key => {
            const triggerKey = parseInt(key, 10);
            if (triggers[key] || data.triggers_default.includes(triggerKey)) {
                formData.append(`triggers[${key}]`, triggers[key] ? 1 : 0);
            }
        });

       await updateNotificationTriggers({
            notificationId:notifId,
            body: formData
        });
    }

    const updateTriggers = (id, value) => {
        setTempTriggers(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const openTimeModal = (id) => {
        setNotificationsId(id);
        setTempTriggers(data.triggers);
        setIsOpenTimeModal(!isOpenTimeModal);
    }

    const customDay = (e) => {
        const value = parseInt(e.target.value);

        if (!isNaN(value) && value > 0 && value <= maxDays && value ) {
            setDays(value);
        }
        else if (value < 0) {
            setDays(1)
        }
        else if (value > maxDays) {
            setDays(old => old)
        }
        else setDays(value)
    }

    const incrementDays = () => {
        setDays(prevDays => (prevDays < maxDays ? prevDays + 1 : maxDays));
    };

    const decrementDays = () => {
        setDays(prevDays => (prevDays > 1 ? prevDays - 1 : 1));
    };

    const addCustomDay = (day) => {
        setTempTriggers(prevState => ({
            ...prevState,
            [day]: false
        }));
        setTriggers(prevTriggers => [
            ...prevTriggers,
            {
                key: day,
                value: false,
                label: `${day} ${data?.unit || ''}`,
                default: false
            }
        ]);
        setIsOpenModal(false);
    };
    const closeModal = () => {
        setIsOpenTimeModal(false);
        setTempTriggers(data.triggers);
    };

    const handleMouseLeave = (event) => {
        if (!modalRef.current || !modalRef.current.contains(event.target)) {
            closeModal()
        }
    };

    useEffect(() => {
        setData(itemData);
    }, [itemData]);

    useEffect(() => {
        const triggers = [];
        if (data.triggers) {
            for (const [key, value] of Object.entries(data?.triggers)) {
                const triggerKey = parseInt(key, 10);
                const trigger = {
                    key: triggerKey,
                    value: value,
                    label: data?.triggers_labels[key] || (`${triggerKey}  ${data?.unit}`),
                    default: data?.triggers_default.includes(triggerKey)
                };
                triggers.push(trigger);
            }
            setTriggers(triggers)
        }
    }, [data]);

    useEffect(() => {
        if (triggersIsSuccess && triggersData?.data){
            updateSections();
            setData(triggersData.data[Object.keys(triggersData.data)]);
            setIsOpenTimeModal(false);
        }
    },[triggersIsSuccess, triggersData])


    useEffect(() => {
        if (itemNotifyIsSuccess){
            updateSections();
            setData(itemNotifyData.data[Object.keys(itemNotifyData.data)]);
        }

    },[itemNotifyIsSuccess, itemNotifyData])

    useEffect(() => {
        itemNotifyIsLoading || itemNotifyIsFetching
        || triggersIsLoading || triggersIsFetching ?
            dispatch(setLoader(true)) :
            dispatch(setLoader(false))
    },[itemNotifyIsLoading, itemNotifyIsFetching, triggersIsLoading, triggersIsFetching])


    useEffect(function () {
        document.addEventListener('mousedown', handleMouseLeave);
        document.addEventListener('touchstart', handleMouseLeave);

        return () => {
            document.removeEventListener('mousedown', handleMouseLeave);
            document.removeEventListener('touchstart', handleMouseLeave);
        };
    }, [headerRef]);

    return(
    data ?  (
        <div ref={modalRef}>
            <div className="nSettings__projects__project__timeScheduleWrapper"   >
                <AxelCheckbox
                    id={data.id}
                    checked={data?.notifications_on}
                    handler={()=>updateSectionItem(data.id)}
                />
                <pre>{data.title}</pre>
                {data?.triggers &&
                    <div className={`nSettings__timeSelect ${isOpenTimeModal && 'nSettings__timeSelect_active'}`}
                         ref={headerRef}
                    >
                        {data.triggers_title}
                        <button  type='button' className={isOpenTimeModal ? 'rotate-180' : ''}
                                 onClick={(e) => {
                                    openTimeModal(data.id);
                                 }}
                        >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M19 8.5L12 15.5L5 8.5" stroke="#2A3650" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </button>
                        {isOpenTimeModal && notificationsId === data.id  &&
                            <div className='nSettings__timeSelect__optionContainer'  >
                                <div className='nSettings__timeSelect__option' >
                                    {triggers.map(trigger => {
                                        return (<div key={trigger.key} className='nSettings__timeSelect__option__item'>
                                            <AxelCheckbox
                                                id={trigger.key}
                                                checked={tempTriggers[trigger.key]}
                                                label={trigger.label}
                                                handler={(id, value) => updateTriggers(id, value)}
                                            />
                                        </div>)
                                        }
                                    )}
                                    <button className='nSettings__timeSelect__addBtn'
                                            type='button'
                                            onClick={() => setIsOpenModal(true)}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path
                                                d="M12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25Z"
                                                stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                                strokeLinejoin="round"/>
                                            <path d="M11.8687 9.22595L11.9111 14.6141" stroke="white"
                                                  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M9.1958 11.8988L14.584 11.9412" stroke="white"
                                                  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                        <span>{data?.add_action_title}</span>
                                    </button>

                                </div>
                                <AxelButton
                                    classname='nSettings__timeSelect__saveBtn'
                                    handler={() => saveTriggers(data.id)}>
                                    {data?.save_button}
                                </AxelButton>
                            </div>
                        }
                    </div>
                }
            </div >
            {isOpenModal &&
                <Modal closeModal={setIsOpenModal} title={data?.add_header}>
                    <div className="nSettings__customDay fade-in">
                        <div className="nSettings__customDay__inputContainer">
                            <AxelInput
                                type="number"
                                value={days}
                                handler={(e)=>customDay(e)}
                                placeholder='Enter day'
                            />
                            <button className="nSettings__customDay__inputContainer__incBtn" onClick={incrementDays} type='button'>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" >
                                    <path d="M19 8.5L12 15.5L5 8.5" stroke="#2A3650" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </button>
                            <button className="nSettings__customDay__inputContainer__dicBtn" onClick={decrementDays} type='button'>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" >
                                    <path d="M19 8.5L12 15.5L5 8.5" stroke="#2A3650" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </button>
                        </div>
                        <AxelButton handler={()=>addCustomDay(days)}>{data?.save_button}</AxelButton>
                    </div>
                </Modal>
            }
        </div>
        ) :  null
    )

}


export default NotificationItems;