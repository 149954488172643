import moment from "moment";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
    useCreateOneMonthAheadMutation,
    useDeleteOneMonthAheadMutation,
    useLazyGetAllCompaniesQuery,
    useUpdateOneMonthAheadMutation,
} from "../../../../store/service/adbService";
import { setLoader } from "../../../../store/slice/dashboard";
import { Switch } from "../../../../UI/Switch";
import { DeleteModal } from "../../DeleteModal";
import MoreInfoModal from "../../../MoreInfoModal";
import CustomDatePicker from "../../../CustomDatePicker";

export const OneMonthAhead = ({
                                  monthData,
                                  glossaryData,
                                  translations
                              }) => {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [show, setShow] = useState(true);
    const user = useSelector((state) => state?.adbReducer?.user);
    const [currentItem, setCurrentItem] = useState(null);
    const [newItem, setNewItem] = useState(false);
    const [options, setOptions] = useState([]);
    const [editable, setEditable] = useState(false);
    const [deleteModal, setDeleteModal] = useState(null);
    const [moreInfo, setMoreInfo] = useState(null);
    const [tableHeaders, setTableHeaders] = useState([]);
    const [infoModal, setInfoModal] = useState(false);
    const [currentRow, setCurrentRow] = useState(null);
    const scrollRef = useRef(null);
    const expandedRef = useRef(null);
    const [expanded, setExpanded] = useState(false);
    const localeLan = localStorage.getItem("local_lan" );
    moment.locale(localeLan);

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                let array = data?.map((item) => {
                    item.edit = false;
                    return item;
                });
                setEditable(false);
                setCurrentItem(null);
                setData(array);
                if (newItem) {
                    setNewItem(false);
                    let s = data?.filter((el, i) => i !== data.length - 1);
                    setData(s);
                }
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, [data]);

    useEffect(() => {
        if (monthData?.one_month_look_ahead) {
            let data = monthData?.one_month_look_ahead?.map((item) => ({
                description: item?.description,
                target_date: item?.target_date,
                completed_date: item?.completed_date,
                completed: item?.completed,
                project_id: monthData?.id,
                id: item?.id,
                edit: false,
                submitted: item?.submitted,
                old_report: item?.old_report,
                submitted_date: item?.submitted_date,
            }));
            setEditable(false);
            setData(data);
        }
    }, [monthData?.one_month_look_ahead]);

    const [
        getCompanies,
    ] = useLazyGetAllCompaniesQuery();

    useEffect(() => {
        getCompanies({
            per_page: 1000,
        });
    }, []);

    useEffect(() => {
        if (monthData?.responsible_party) {
            let data = monthData?.responsible_party?.map((item) => {
                return {
                    value: item?.id,
                    label: item?.short_name,
                };
            });
            setOptions(data);
        }
    }, [monthData]);
    const [
        createOneMonthAhead,
        {
            isLoading: createOneMonthAheadIsLoading,
            isSuccess: createOneMonthAheadIsSuccess,
        },
    ] = useCreateOneMonthAheadMutation();

    const [
        updateOneMonthAhead,
        {
            isLoading: updateOneMonthAheadIsLoading,
            isSuccess: updateOneMonthAheadIsSuccess,
        },
    ] = useUpdateOneMonthAheadMutation();

    const [
        deleteOneMonthAhead,
        {
            isLoading: deleteOneMonthAheadIsLoading,
            isSuccess: deleteOneMonthAheadIsSuccess,
        },
    ] = useDeleteOneMonthAheadMutation();

    useEffect(() => {
        if(translations){
            const headers = [
                {
                    tooltip_name: translations?.['project_monitoring.one_month_look_ahead.table_headers.target_description'],
                    tooltip_text: translations?.['project_monitoring.one_month_look_ahead.tooltips.target_description']
                },
                {
                    tooltip_name: translations?.['project_monitoring.one_month_look_ahead.table_headers.planned_date'],
                    tooltip_text: translations?.['project_monitoring.one_month_look_ahead.tooltips.planned_date']
                },
                {
                    tooltip_name: translations?.['project_monitoring.one_month_look_ahead.table_headers.completion_date'],
                    tooltip_text: translations?.['project_monitoring.one_month_look_ahead.tooltips.completion_date']
                },
                {
                    tooltip_name: translations?.['project_monitoring.one_month_look_ahead.table_headers.completed'],
                    tooltip_text: translations?.['project_monitoring.one_month_look_ahead.tooltips.completed']
                },
            ]

            setTableHeaders(
                headers.map((item) => ({
                    ...item,
                    showTooltip: false,
                }))
            );
        }
    }, [translations])

    useEffect(() => {
        if (glossaryData?.data?.length) {
            setMoreInfo(
                glossaryData?.data?.filter(
                    (el) => el?.section_name === "One Month Look Ahead"
                )[0]
            );
        }
        setData((old) => {
            let data = old.map((item) => ({ ...item, edit: false }));
            return data;
        });
        setEditable(false);
    }, [
        createOneMonthAheadIsSuccess,
        updateOneMonthAheadIsSuccess,
        deleteOneMonthAheadIsSuccess,
        glossaryData,
    ]);

    useEffect(() => {
        const d = () => {
            dispatch(setLoader(false));
        };

        createOneMonthAheadIsLoading ||
        updateOneMonthAheadIsLoading ||
        deleteOneMonthAheadIsLoading
            ? dispatch(setLoader(true))
            : d();
    }, [
        createOneMonthAheadIsLoading,
        updateOneMonthAheadIsLoading,
        deleteOneMonthAheadIsLoading,
    ]);
    const setterData = (input) => {
        const body = {
            description: input?.description,
            target_date: moment(input?.target_date).format("YYYY-MM-DD"),
            project_id: monthData?.id,
        };

        let s = [];

        if (currentItem?.completed) {
            body.completed_date = moment(input?.completed_date).format("YYYY-MM-DD");
        } else {
            delete body.completed_date;
        }

        for (let key in body) {
            if (!body[key] || body[key] === "Invalid date" || body[key] === "") {
                toast.error("Please enter " + key?.split("_")?.join(" "), {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
                s.push("false");
            }
        }

        if (s.length === 0) {
            return body;
        } else {
            return null;
        }
    };

    const submitHandler = (e, item) => {
        e.preventDefault();
        if (newItem) {
            const dataCreated = setterData(currentItem);
            if (dataCreated) {
                createOneMonthAhead({
                    ...dataCreated,
                    completed: currentItem?.completed,
                });
                setCurrentItem(null);
                setNewItem(false);
                setCurrentRow(null);
            }
        } else {
            const dataUpdated = setterData(currentItem);
            if (dataUpdated) {
                updateOneMonthAhead({
                    id: item?.id,
                    body: {
                        ...dataUpdated,
                        completed: currentItem?.completed,
                    },
                });
                setCurrentItem(null);
                setCurrentRow(null);
                setNewItem(false);
            }
        }
    };

    return (
        <div className="project__goods">
            {infoModal && (
                <MoreInfoModal
                    title={translations?.['project_monitoring.one_month_look_ahead.headers.one_month_look_ahead']}
                    moreInfo={translations?.['project_monitoring.one_month_look_ahead.more_info.one_month_look_ahead']}
                    close={()=> setInfoModal(false)}
                />
            )}
            {deleteModal?.show && (
                <DeleteModal
                    id={deleteModal?.id}
                    action={deleteOneMonthAhead}
                    setDeleteModal={setDeleteModal}
                    cencelText={translations?.['main.main.actions.cancel']}
                    deleteText={translations?.['main.main.actions.delete']}
                    modalTitle={translations?.['main.main.headers.confirm_your_action']}
                    clarifyingText={translations?.['main.main.titles.you_are_going_to_delete_this_row']}
                />
            )}
            <div className={`project__block ${show ? "" : "close"}`}>
                <div className="project__block-subtitles">
                    <div className="project__program-subtitle">
                        {translations?.['project_monitoring.one_month_look_ahead.headers.one_month_look_ahead']}
                        <span
                            onClick={() => setInfoModal(!infoModal)}
                            className="project__block-more"
                        >
              <span className="img">
                <img src="/assets/icons/info.svg" alt="info" />
              </span>
              <span className="project__program-text">{translations?.['project_monitoring.one_month_look_ahead.titles.more_info']}</span>
            </span>
                    </div>
                    {user?.permissions?.edit_project && (
                        <div
                            onClick={() => {
                                let randomId = Math.floor(Math.random() * 1000);
                                setCurrentRow(randomId);
                                scrollRef !== null && scrollRef.current.scrollIntoView();
                                if (!newItem) {
                                    setNewItem(true);
                                    setEditable(true);
                                    setData((old) => [
                                        ...old,
                                        {
                                            id: randomId,
                                            description: "",
                                            target_date: null,
                                            completed_date: null,
                                            completed: false,
                                            edit: true,
                                        },
                                    ]);
                                    setCurrentItem({
                                        id: randomId,
                                        description: "",
                                        target_date: null,
                                        completed_date: null,
                                        completed: false,
                                        edit: true,
                                    });
                                }
                            }}
                            className="project__block-add-row"
                        >
              <span>
                <img src="/assets/icons/blue-plus.svg" alt="plus" />
              </span>
                            <span>{translations?.['project_monitoring.one_month_look_ahead.actions.add_row']}</span>
                        </div>
                    )}
                </div>
                <div
                    className={`project__block-content permits ${
                        editable ? "editable" : ""
                    }`}
                >
                    <div
                        className={`project__block-table project-table ${
                            currentItem ? "n" : ""
                        }`}
                    >
                        <div
                            ref={scrollRef}
                            className={`project-table__header ${
                                data?.length > 3 && currentItem ? "isFixed" : ""
                            }`}
                        >
                            {tableHeaders?.map((el, idx) => {
                                return (
                                    <div
                                        key={idx}
                                        onMouseEnter={(event) => {
                                            setTableHeaders(
                                                tableHeaders.map((item) => ({
                                                    ...item,
                                                    showTooltip: el.tooltip_name === item.tooltip_name,
                                                }))
                                            );
                                        }}
                                        onMouseLeave={() => {
                                            setTableHeaders(
                                                tableHeaders.map((item) => ({
                                                    ...item,
                                                    showTooltip: false,
                                                }))
                                            );
                                        }}
                                        className="project-table__th"
                                    >
                                        {el?.showTooltip ? (
                                            <span
                                                className={
                                                    el?.tooltip_text?.length > 20
                                                        ? "lengthy"
                                                        : "not-lengthy"
                                                }
                                            >
                        {el?.tooltip_text}
                      </span>
                                        ) : (
                                            ""
                                        )}
                                        {el?.tooltip_name}
                                    </div>
                                );
                            })}
                        </div>
                        <div className="project-table__body">
                            {data?.map((item, index) => (
                                <div key={index}>
                                    {index !== 0 && currentRow === item?.id && (
                                        <div className={`project-table__header`} key={index}>
                                            {tableHeaders?.map((el, idx) => {
                                                return (
                                                    <div key={idx} className="project-table__th">
                                                        {el?.showTooltip ? (
                                                            <span
                                                                className={
                                                                    el?.tooltip_text?.length > 20
                                                                        ? "lengthy"
                                                                        : "not-lengthy"
                                                                }
                                                            >
                                {el?.tooltip_text}
                              </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {el?.tooltip_name}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                    <form
                                        className={`project-table__row ${
                                            item?.edit ? "edit" : currentItem ? "n" : ""
                                        } ${
                                            Boolean(item.submitted) ||
                                            !user?.permissions?.edit_project
                                                ? "submitted"
                                                : ""
                                        }`}
                                        id={item?.id}
                                        key={item?.id}
                                        onSubmit={(e) => submitHandler(e, item)}
                                    >
                                        <div className="project-table__td">
                                            <span>{item?.description}</span>
                                            <div className="project__block-input project-table__field">
                        <textarea
                            className={`${
                                expanded ? "project__block-expandedInput" : ""
                            }`}
                            ref={expandedRef}
                            onFocus={() => setExpanded(true)}
                            onBlur={() => setExpanded(false)}
                            maxLength={190}
                            defaultValue={item?.description}
                            onChange={(e) => {
                                setCurrentItem((old) => ({
                                    ...old,
                                    description: e.target.value,
                                }));
                            }}
                        />
                                            </div>
                                        </div>
                                        <div className="project-table__td">
                      <span>
                        {moment(item?.target_date).format("DD/MM/YYYY") ===
                        "Invalid date" ||
                        !moment(item?.target_date).format("DD/MM/YYYY")
                            ? ""
                            : moment(item?.target_date).format("DD/MM/YYYY")}
                      </span>
                                            <div className="project__block-item-calendar project-table__field">
                                                <CustomDatePicker
                                                    portalId="root-portal"
                                                    autoComplete="off"
                                                    id="approved_date"
                                                    placeholderText={translations?.['main.main.placeholder.dd_mm_yyyy']}
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={
                                                        currentItem?.target_date
                                                            ? moment(currentItem?.target_date).toDate()
                                                            : ""
                                                    }
                                                    onChange={(date) => {
                                                        setCurrentItem((old) => ({
                                                            ...old,
                                                            target_date: date,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="project-table__td">
                      <span>
                        {moment(item?.completed_date).format("DD/MM/YYYY") ===
                        "Invalid date" ||
                        !moment(item?.completed_date).format("DD/MM/YYYY")
                            ? ""
                            : moment(item?.completed_date).format("DD/MM/YYYY")}
                      </span>
                                            <div className="project__block-item-calendar project-table__field">
                                                <CustomDatePicker
                                                    portalId="root-portal"
                                                    autoComplete="off"
                                                    id="approved_date"
                                                    disabled={!currentItem?.completed}
                                                    placeholderText={translations?.['main.main.placeholder.dd_mm_yyyy']}
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={
                                                        currentItem?.completed_date
                                                            ? moment(currentItem?.completed_date).toDate()
                                                            : ""
                                                    }
                                                    onChange={(date) => {
                                                        setCurrentItem((old) => ({
                                                            ...old,
                                                            completed_date: date,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="project-table__td">
                                            <Switch
                                                value={item?.completed}
                                                id={index + 775}
                                                disabled={item?.edit ? false : true}
                                                checked={
                                                    item?.edit ? currentItem?.completed : item?.completed
                                                }
                                                handler={(value) => {
                                                    setCurrentItem((old) => ({
                                                        ...old,
                                                        completed: value,
                                                        completed_date: value
                                                            ? currentItem?.completed_date
                                                            : null,
                                                    }));
                                                }}
                                            />
                                        </div>
                                        <div className="project__block-btns">
                                            {currentItem ? (
                                                <div
                                                    className="project__block-cancel"
                                                    onClick={() => {
                                                        let array = data?.map((item) => {
                                                            item.edit = false;
                                                            if (currentItem?.completed) {
                                                                item.completed = false;
                                                                setCurrentItem((old) => ({
                                                                    ...old,
                                                                    completed: false,
                                                                }));
                                                            }
                                                            return item;
                                                        });
                                                        setCurrentRow(null);
                                                        setCurrentItem(null);
                                                        setData(array);
                                                        if (newItem) {
                                                            setNewItem(false);
                                                            let s = data?.filter(
                                                                (el, i) => i !== data.length - 1
                                                            );
                                                            setData(s);
                                                        }
                                                    }}
                                                >
                                                    <img src="/assets/icons/close-mark.svg" alt="close" />
                                                    {translations?.['project_monitoring.one_month_look_ahead.actions.cancel']}
                                                </div>
                                            ) : (
                                                <div
                                                    className="project__block-delete"
                                                    onClick={() => {
                                                        setDeleteModal({ id: item?.id, show: true });
                                                    }}
                                                >
                                                    <img src="/assets/icons/trash-icon.svg" alt="arrow" />
                                                    {translations?.['project_monitoring.one_month_look_ahead.actions.delete']}
                                                </div>
                                            )}
                                            {currentItem ? (
                                                <button className="project__block-save">
                                                    <img src="/assets/icons/save-arrow.svg" alt="arrow" />
                                                    {translations?.['project_monitoring.one_month_look_ahead.actions.save']}
                                                </button>
                                            ) : (
                                                <div
                                                    onClick={() => {
                                                        setCurrentRow(item?.id);
                                                        setCurrentItem(item);
                                                        setEditable(true);
                                                        setData(() => {
                                                            let array = data?.map((elem) => {
                                                                if (elem?.id === item?.id) {
                                                                    elem.edit = true;
                                                                }
                                                                return elem;
                                                            });
                                                            return array;
                                                        });
                                                    }}
                                                    className="project__block-update"
                                                >
                                                    {translations?.['project_monitoring.one_month_look_ahead.actions.edit']}
                                                </div>
                                            )}
                                        </div>
                                    </form>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
