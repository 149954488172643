import classNames from "classnames";
import {toast} from "react-toastify";
import React, {useState} from 'react';
import Modal from "../../components/Modal";
import AxelInput from "../../UI/AxelInput";
import AxelButton from "../../UI/AxelButton";
import './index.scss';

const AddLanguage = ({
                         closeModal,
                         currentItem,
                         updateLanguageRequest,
                         createLanguageRequest,
                     }) => {
    const defaultImgAddress =  '../../assets/icons/default-img.svg'
    const [languageImg, setLanguageImg] = useState(
        currentItem?.image ?? defaultImgAddress
    )
    const [formHasChanges, setFormHasChanges] = useState(false);
    const [state, setState] = useState({
        id: currentItem?.id ?? null,
        language: currentItem?.language ?? "",
        code: currentItem?.code ?? "",
        image: currentItem?.image ?? "",
    });

    const imageUpload = (e) => {
        const uploadedFile = e.target.files[0];
        if (uploadedFile && uploadedFile?.size > 0 && uploadedFile?.size < 20000000){
            setLanguageImg(URL.createObjectURL(uploadedFile))
            setState((old) => ({ ...old, image: uploadedFile }));
            setFormHasChanges(true);
        }
        else toast.error('The file must not be greater than 100mb.')
    }

    const inputHandler = (e) => {
        setFormHasChanges(true);
        setState((old) => ({ ...old, [e.target.name]: e.target.value }));
    };

    const submitHandler = (e) => {
        e.preventDefault();
        let body = {
            language: state?.language,
            code: state?.code,
            image: state?.image,
        };
        let data= [];
        for (let key in body) {
            if (!body[key] || body[key] === "Invalid date" || body[key] === "") {
                toast.error("Please enter " + key?.split('_')?.join(' '), {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
                data.push("false");
            }
        }

        if (data.length === 0) {
            const formData = new FormData();
            formData.append("language", body?.language);
            if (state?.image && !currentItem) {
                formData.append("image", body?.image, `${body?.image.name}` );
                formData.append("code", body?.code);
            }
            else if (currentItem.image !== body.image)  {
                formData.append("image", body?.image )
            }

            if (currentItem) {
                updateLanguageRequest( {id: currentItem?.id, body: formData });
            } else {
                createLanguageRequest(formData);
            }
        }
    };

    return (
        <Modal
            title={currentItem ? 'Edit language' : 'Add language'}
            closeModal={closeModal}
        >
            <form
                className="modal__form"
                onSubmit={submitHandler}
                >
                <div className="languages__uploadImg">
                    <div
                        className="languages__uploadImg__imgContainer"
                    >
                        <img
                            src={languageImg}
                            alt='language logo'
                            className={classNames( {['languages__uploadImg__imgContainer_cover']: state.image})}
                        />
                    </div>

                    <input
                        id='file'
                        type='file'
                        name="image"
                        accept="image/jpg, image/png, image/jpeg, image/gif, image/svg, image/webp"
                        onChange={(e)=>imageUpload(e)}
                    />
                    {currentItem? 'Change image' : 'Upload image'}
                </div>
                <div>
                    <label htmlFor="long_name" className="contact__modal-label">
                       Language <span > *</span>
                    </label>
                    <AxelInput
                        maxLength={190}
                        type="text"
                        name='language'
                        handler={inputHandler}
                        value={state.language}
                        placeholder="Enter language"
                    />
                </div>
                {! currentItem && <div>
                    <label htmlFor="long_name" className="contact__modal-label">
                        Language code <span> *</span>
                    </label>
                    <AxelInput
                        maxLength={190}
                        type="text"
                        name='code'
                        handler={inputHandler}
                        value={state.code}
                        placeholder="Enter language code"
                    />
                </div>}
                <AxelButton
                    type="submit"
                    disabled={!formHasChanges}
                    classname={classNames({['modal__form__btnDisabled']: !formHasChanges})}
                >
                    Save
                </AxelButton>
            </form>
        </Modal>
    )
}

export default AddLanguage;