import React, {useEffect, useRef, useState} from "react";
import moment from "moment";
import {toast} from "react-toastify";
import { useDispatch } from "react-redux";
import { Paginate } from "../../components/Paginate";
import { setLoader } from "../../store/slice/dashboard";

const ChildComponent2 = (
    {
        tableHeader,
        dataFetcher,
        dateRange,
        event,
        table,
        debouncedSearchTerm,
        emptyDataText,
        debouncedSearchByPermits,
        debouncedSearchErrorCode
    }) => {
    const dispatch = useDispatch();
    const tableRef = useRef(null);
    const hasPageBeenRendered = useRef(false)
    const [data, setData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);

    const [getData,
        {   isLoading: dataIsLoading,
            isError,
        }
    ] = dataFetcher();

    useEffect(() => {
        if(isError){
            toast.error("Unexpected error", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }

    }, [isError]);

    useEffect(() => {
        if (hasPageBeenRendered.current) {
            const requestData = {
                per_page: 10,
                page: currentPage,
            };

            if (debouncedSearchTerm) {
                requestData.search = debouncedSearchTerm;
            }

            if (debouncedSearchByPermits) {
                requestData.project = debouncedSearchByPermits;
            }
            if (debouncedSearchErrorCode) {
                requestData.error_code = debouncedSearchErrorCode;
            }

            if (dateRange[0]) {
                requestData.date_from = moment(dateRange[0]).format('YYYY-MM-DD');
            }

            if (dateRange[1]) {
                requestData.date_to = moment(dateRange[1]).format('YYYY-MM-DD');
            }

            if (event?.value !== undefined) {
                requestData.event = event.value;
            }
            if (table?.value !== undefined) {
                requestData.table = table.value;
            }

            dispatch(setLoader(true));
            getData(requestData).unwrap().then(res => {
                dispatch(setLoader(false));
                setData(res?.data.data);
                setTotalPage(res?.data?.meta?.last_page);
            }).catch(() => {
                dispatch(setLoader(false));
            });

        }
        hasPageBeenRendered.current=true
    }, [debouncedSearchTerm, debouncedSearchByPermits, debouncedSearchErrorCode, dateRange, currentPage,event, table]);

    useEffect(() => {
            dispatch(setLoader(true));
            getData().unwrap().then(res => {
                setData(res?.data?.data);
                setTotalPage(res?.data?.meta?.last_page);
                dispatch(setLoader(false));
                setCurrentPage(res?.data?.meta?.current_page);
            }).catch(() => {
                dispatch(setLoader(false));
            });

    }, []);

    useEffect(() => {
        let mainContainer = document.querySelector('.main');
        let parentContainer = document.querySelector('.audit');
        if (tableRef.current) {
            const tableWidth = tableRef?.current?.offsetWidth;
            const parentWidth = mainContainer?.offsetWidth;
            if (tableWidth > (parentWidth - 243)) {
                parentContainer.style.paddingRight ='32px'
            } else {
                parentContainer.style.paddingRight ='0'
            }
        }
    },[data]);

    useEffect(() => {
        dataIsLoading
            ? dispatch(setLoader(true))
            : dispatch(setLoader(false));
    }, [dataIsLoading]);

    return (
        <>
            <div className='table' ref={tableRef}>
                <div className="table-header">
                    {tableHeader.map((title, index) => <div className="table-th px-3" key={index}>{title}</div>)}
                </div>
                {
                    data?.length > 0 ?
                        <div className="table-body">
                            {data?.map((item, index) => (
                                <div className="table-row" key={index}>
                                    {Object.entries(item).map(([key, value]) => (
                                        key !== "id" && (
                                            <div className="table-td px-3 audit__tableTd" key={key}>
                                                {value}
                                                {value?.length > 18  && <span className="audit__tableTd__tooltip">{value}</span>}
                                            </div>
                                        )
                                    ))}
                                </div>
                            ))}
                        </div> :
                        <div style={{ height: '70vh', width: '100%', display: "flex", justifyContent: "center", alignItems: 'center', flexDirection: "column" }}>
                            <img src="/assets/images/projects.png" alt="" />
                            <span style={{ color: "#767676", fontSize: 16, fontWeight: 500 }}>{emptyDataText}</span>
                        </div>
                }
            </div>
            {(data?.length > 0 && totalPage > 0) && <Paginate currentPage={currentPage} setCurrentPage={setCurrentPage} pageCount={totalPage} />}
        </>
    );
}

export default ChildComponent2;
