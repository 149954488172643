import React from 'react';
import {useSelector} from "react-redux";

function QuarterlySidePhotographs({pageTranslations}) {
	const site_photographs = useSelector(state => state?.quarterlyReducer?.online_view?.sections?.site_photographs) ?? null;
	const sections_title = useSelector(state => state?.quarterlyReducer?.side_bar) ?? null;
	
	if (!site_photographs) {
		return <div></div>
	}
	
	return (
			<div className="online__content-projectRow">
				<p className="online__content-boldTitle">8. {pageTranslations?.['site_photographs.index.headers.site_photographs']}</p>
				<div className="online__content-photos">
					{site_photographs?.map((item, index) => (
							<div className="online__content-photo" key={item?.id}>
								<div className="online__content-photoContainer">
									<img src={item?.photo} alt=""/>
								</div>
								<p className="online__content-desc">
									{item?.description}
								</p>
							</div>
					))}
				</div>
			</div>
	);
}

export default QuarterlySidePhotographs;
