import React, {useState} from "react";
import Table from "../Table";
import AdvancedTable from "../AdvancedTable";

const FinancialRatio = ({ tableData, reportId, url, translations, isReportSubmitted }) => {
  const [isOpenEdit, setIsOpenEdit] = useState(false);

  return (
        <div className="financialRatio">
            <div className="editReport__sectionHeader">
                <h4>
                    {translations?.['covenants.financial_covenants.headers.financial_ratios_to_be_compiled']}
                </h4>
                {!isReportSubmitted &&
                    <button
                      className="editReport__sectionHeader__addRowBtn"
                      onClick={() => setIsOpenEdit(true)}>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25Z" stroke="#0052CC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M11.8687 9.22595L11.9111 14.6141" stroke="#0052CC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M9.1958 11.8988L14.584 11.9412" stroke="#0052CC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      {translations?.['covenants.loan_covenants.actions.add_row']}
                    </button>
                }
            </div>
            <div className="table-container">
              <AdvancedTable
                setIsOpenEdit={setIsOpenEdit}
                isOpenEdit={isOpenEdit}
                tableData={tableData}
                reportId={reportId}
                url={url}
                translations={translations}
                isReportSubmitted={isReportSubmitted}
              />
            </div>

        </div>
    )
}

export default FinancialRatio;