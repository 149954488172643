import React, { useEffect, useState } from "react";
import { ProjectStatus } from "./ProjectStatus";
import { Level2Program } from "./Level2Program";
import { ProgramProgress } from "./ProgramProgress";
import { ProgramOverview } from "./ProgramOverview";
import { CriticalPath } from "./CriticalPath";
import { OneMonthAhead } from "./OneMonthAhead";
import { VariationsAndClaims } from "./VariationsAndClaims";
import { VariationsRegister } from "./VariationsRegister";
import { ClaimRegister } from "./ClaimRegister";
import { EOTcomponent } from "./EOT";
import MoreInfoModal from "../../MoreInfoModal";

export const ProjectMonitoring = ({
                                      glossaryData,
                                      id,
                                      data,
                                      refProgramProgress,
                                      translations
                                  }) => {
    const [moreInfo, setMoreInfo] = useState(null);
    const [infoModal, setInfoModal] = useState(false);
    const [show, setShow] = useState(true);

    useEffect(() => {
        setMoreInfo(
            glossaryData?.data?.filter(
                (el) => el?.section_name === "Project Monitoring"
            )[0]
        );
    }, [glossaryData]);

    let showBr =
        data?.project_settings?.project_status ||
        data?.project_settings?.major_project_milestones ||
        data?.project_settings?.program_progress ||
        data?.project_settings?.program_overview ||
        data?.project_settings?.critical_path ||
        data?.project_settings?.one_month_look_ahead ||
        data?.project_settings?.variations_and_claim ||
        data?.project_settings?.variation_register ||
        data?.project_settings?.claim_register;

    return (
        <div
            ref={refProgramProgress}
            id={refProgramProgress}
            className={`${showBr ? "project__white-box" : ""} ${show ? "" : "close"}`}
        >
            {data?.project_settings?.project_status ? (
                <div className="project__site">
                    {infoModal && (
                        <MoreInfoModal
                            title={translations?.['project_monitoring.project_monitoring.headers.project_monitoring']}
                            moreInfo={translations?.['project_monitoring.project_monitoring.more_info.project_monitoring']}
                            close={()=> setInfoModal(false)}
                        />
                    )}
                    <div className="project__block-subtitles">
                        <div className="d-f">
                            <div
                                onClick={() => setShow(!show)}
                                className="project__block-title data__title"
                            >
                                <span>{translations?.['project_monitoring.project_monitoring.headers.project_monitoring']}</span>
                            </div>
                            <span
                                onClick={() => setInfoModal(true)}
                                className="project__block-more"
                            >
                <span className="img">
                  <img src="/assets/icons/info.svg" alt="info" />
                </span>
                <span className="text">{translations?.['project_monitoring.project_monitoring.titles.more_info']}</span>
              </span>
                        </div>
                    </div>
                </div>
            ) : null}
            <div className="project__monitoring">
                {data?.project_settings?.project_status ? (
                    <ProjectStatus data={data} translations={translations}/>
                ) : null}
                {data?.project_settings?.major_project_milestones ? (
                    <>
                        <Level2Program levelData={data} glossaryData={glossaryData} translations={translations}/>
                        <div className="project__block-hr"></div>
                    </>
                ) : null}
                {data?.project_settings?.program_progress ? (
                    <ProgramProgress data={data} glossaryData={glossaryData} translations={translations}/>
                ) : null}
                {data?.project_settings?.program_overview ? (
                    <ProgramOverview data={data} glossaryData={glossaryData} translations={translations}/>
                ) : null}
                {data?.project_settings?.critical_path ? (
                    <>
                        <CriticalPath data={data} glossaryData={glossaryData} translations={translations} />
                        <div className="project__block-hr"></div>
                    </>
                ) : null}
                {data?.project_settings?.one_month_look_ahead ? (
                    <OneMonthAhead monthData={data} glossaryData={glossaryData} translations={translations}/>
                ) : null}
                {data?.project_settings?.variations_and_claims ? (
                    <VariationsAndClaims
                        variationsData={data}
                        glossaryData={glossaryData}
                        translations={translations}
                    />
                ) : null}
                {data?.project_settings?.variation_register ? (
                    <>
                        <VariationsRegister
                            variationsData={data}
                            glossaryData={glossaryData}
                            translations={translations}
                        />
                        <div className="project__block-hr"></div>
                    </>
                ) : null}
                {data?.project_settings?.claim_register ? (
                    <ClaimRegister claimData={data} glossaryData={glossaryData} translations={translations}/>
                ) : null}
                {data?.project_settings?.extension_of_time ? (
                    <EOTcomponent claimData={data} glossaryData={glossaryData} translations={translations}/>
                ) : null}
            </div>
        </div>
    );
};
