import React from 'react';

const Tabs = ({tab, handleTab, tabTitles }) => {
    return(
        <div className="tabs">
            <button
                className={`tabs__tab ${tab === 1 ? "tabs__tab_active" : ""}`}
                onClick={()=>handleTab(1)}>
                {tabTitles?.['index.index.headers.user_access_activity']}
            </button>
            <button
                className={`tabs__tab ${tab === 2 ? "tabs__tab_active" : ""}`}
                onClick={()=>handleTab(2)}>
                {tabTitles?.['index.index.headers.project_data_input_page']}
            </button>
            <button
                className={`tabs__tab ${tab === 3 ? "tabs__tab_active" : ""}`}
                onClick={()=>handleTab(3)}>
                {tabTitles?.['index.index.headers.secondary_tables']}
            </button>
            <button
                className={`tabs__tab ${tab === 4 ? "tabs__tab_active" : ""}`}
                onClick={()=>handleTab(4)}>
                {tabTitles?.['index.index.headers.reports_and_cmp_downloaded']}
            </button>
            <button
                className={`tabs__tab ${tab === 5 ? "tabs__tab_active" : ""}`}
                onClick={()=>handleTab(5)}>
                {tabTitles?.['index.index.headers.system_errors']}
            </button>
        </div>
    )
}

export default Tabs;