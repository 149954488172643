import React, {useEffect, useState} from 'react';
import useDebounce from "../../global/useDebounce";
import {useLazyGetAccessActivityQuery} from "../../store/service/adbService";
import Table from "./Table";
import Toolbar from "./Toolbar";

const UserAccessActivity = ({translations, eventOption }) => {
    const [event, setEvent] = useState(null);
    const [selectOptions, setSelectOptions] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    useEffect(()=>{
      if (eventOption)
      {
          setSelectOptions(Object.keys(eventOption)?.map((key)=>({
              value: key,
              label: eventOption[key],
          })))
      }
    },[eventOption])

    const tableHeader = [
        translations['user_access_activity.index.table_headers.first_name'],
        translations['user_access_activity.index.table_headers.last_name'],
        translations['user_access_activity.index.table_headers.company_name'],
        translations['user_access_activity.index.table_headers.timestamp'],
        translations['user_access_activity.index.table_headers.location'],
        translations['user_access_activity.index.table_headers.device'],
        translations['user_access_activity.index.table_headers.event_type'],
    ];
    const dataFetcher = useLazyGetAccessActivityQuery;
    const exportEndpoint =`/audit-log/user-access-activity/export/csv`

    return (
       <>
           <Toolbar
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            startDate={startDate}
            endDate={endDate}
            setDateRange={setDateRange}
            translations={translations}
            setEvent={setEvent}
            event={event}
            exportEndpoint={exportEndpoint}
            exportFileName='user-access-activity'
            selectOptions={selectOptions}
            debouncedSearchTerm={debouncedSearchTerm}
            selectTitle={translations['user_access_activity.index.actions.event_type']}
            searchPlaceholder={translations['user_access_activity.index.actions.search_by_name_company']}
           />
           <Table
               event={event}
               dateRange={dateRange}
               tableHeader={tableHeader}
               dataFetcher={dataFetcher}
               debouncedSearchTerm={debouncedSearchTerm}
               emptyDataText={"No Activities"}
           />
       </>
    );
}

export default UserAccessActivity;
