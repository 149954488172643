import moment from "moment";
import isEmpty from "lodash/isEmpty";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useErrorHandler } from "../../../../global/ErrorsHandler";
import {
    useCreateClaimRegisterMutation,
    useDeleteClaimRegisterMutation,
    useLazyGetAllCompaniesQuery,
    useLazyGetProjectQuery,
    useUpdateClaimRegisterMutation,
} from "../../../../store/service/adbService";
import { setLoader } from "../../../../store/slice/dashboard";
import { Switch } from "../../../../UI/Switch";
import { DeleteModal } from "../../DeleteModal";
import CustomDatePicker from "../../../CustomDatePicker";

export const ClaimRegister = ({
                                  claimData,
                                  glossaryData,
                                  translations
                              }) => {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [show, setShow] = useState(true);
    const [showByActive, setShowByActive] = useState(true);
    const [hasArchived, setHasArchived] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [newItem, setNewItem] = useState(false);
    const [options, setOptions] = useState([]);
    const [editable, setEditable] = useState(false);
    const [deleteModal, setDeleteModal] = useState(null);
    const [moreInfo, setMoreInfo] = useState(null);
    const [currentRow, setCurrentRow] = useState(null);
    const user = useSelector((state) => state?.adbReducer?.user);
    const expandedRef = useRef(null);
    const [expanded, setExpanded] = useState(false);
    const [tableHeaders, setTableHeaders] = useState([]);

    const scrollRef = useRef(null);
    const descrRef = useRef(null);
    const [decrExpanded, setDecrExpanded] = useState(false);
    const localeLan = localStorage.getItem("local_lan" );
    moment.locale(localeLan)

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                let array = data?.map((item) => {
                    item.edit = false;
                    return item;
                });
                if (newItem) {
                    setNewItem(false);
                    let s = data?.filter((el, i) => i !== data.length - 1);
                    setData(s);
                }
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, [data]);

    const [
        createClaimRegister,
        {
            isLoading: createClaimRegisterIsLoading,
            isSuccess: createClaimRegisterIsSuccess,
            error: createClaimRegisterError,
        },
    ] = useCreateClaimRegisterMutation();

    const [
        updateClaimRegister,
        {
            isLoading: updateClaimRegisterIsLoading,
            isSuccess: updateClaimRegisterIsSuccess,
            error: updateClaimRegisterError,
        },
    ] = useUpdateClaimRegisterMutation();

    const [
        deleteClaimRegister,
        {
            isLoading: deleteClaimRegisterIsLoading,
            isSuccess: deleteClaimRegisterIsSuccess,
            error: deleteClaimRegisterError,
        },
    ] = useDeleteClaimRegisterMutation();

    useEffect(() => {
        if (claimData?.claim_register) {
            let data = claimData?.claim_register?.map((item) => ({
                claim_reference: item?.claim_reference,
                claim_description: item?.claim_description,
                notice_date: item?.notice_date,
                detailed_date: item?.detailed_date,
                submitted_amount: item?.submitted_amount,
                certified: item?.certified,
                certified_amount: item?.certified_amount,
                current_status: item?.current_status,
                project_id: claimData?.id,
                id: item?.id,
                edit: false,
                archived: item?.archived,
                submitted: item?.submitted,
                old_report: item?.old_report,
                submitted_date: item?.submitted_date,
                date_claim_certified: item?.date_claim_certified
            }));
            let isHaveArchived = isEmpty(data.find(item => item.archived===1));
            setHasArchived(!isHaveArchived);
            if (showByActive===true && !isHaveArchived){
                const filteredData =  data.filter(item => item.archived ===0);
                setData(filteredData);
            } else setData(data);
            setEditable(false);
        }
    }, [claimData?.claim_register, showByActive]);

    const [
        getCompanies,
    ] = useLazyGetAllCompaniesQuery();

    useEffect(() => {
        getCompanies({
            per_page: 1000,
        });
    }, []);
    const [
        trigger,
    ] = useLazyGetProjectQuery();

    useEffect(() => {
        if (claimData?.responsible_party) {
            let data = claimData?.responsible_party?.map((item) => {
                return {
                    value: item?.id,
                    label: item?.short_name,
                };
            });
            setOptions(data);
        }
    }, [claimData]);

    const [errorHandler, setErrorHandler] = useErrorHandler([
        createClaimRegisterError,
        updateClaimRegisterError,
        deleteClaimRegisterError,
    ]);

    useEffect(() => {
        if(translations){
            const headers = [
                {
                    tooltip_name: translations?.['project_monitoring.claim_register.table_headers.claim_ref'],
                    tooltip_text: translations?.['project_monitoring.claim_register.tooltips.claim_ref']
                },
                {
                    tooltip_name: translations?.['project_monitoring.claim_register.table_headers.claim_description'],
                    tooltip_text: translations?.['project_monitoring.claim_register.tooltips.claim_description']
                },
                {
                    tooltip_name: translations?.['project_monitoring.claim_register.table_headers.submission_of_notice_of_claim'],
                    tooltip_text: translations?.['project_monitoring.claim_register.tooltips.submission_of_notice_of_claim']
                },
                {
                    tooltip_name: translations?.['project_monitoring.claim_register.table_headers.submission_of_fully_detailed_claim'],
                    tooltip_text: translations?.['project_monitoring.claim_register.tooltips.submission_of_fully_detailed_claim']
                },
                {
                    tooltip_name: translations?.['project_monitoring.claim_register.table_headers.claimed_amount'],
                    tooltip_text: translations?.['project_monitoring.claim_register.tooltips.claimed_amount']
                },
                {
                    tooltip_name: translations?.['project_monitoring.claim_register.table_headers.claim_certified'],
                    tooltip_text: translations?.['project_monitoring.claim_register.tooltips.claim_certified']
                },
                {
                    tooltip_name: translations?.['project_monitoring.claim_register.table_headers.date_claim_certified'],
                    tooltip_text: translations?.['project_monitoring.claim_register.tooltips.date_claim_certified']
                },
                {
                    tooltip_name: translations?.['project_monitoring.claim_register.table_headers.amount_certified'],
                    tooltip_text: translations?.['project_monitoring.claim_register.tooltips.amount_certified']
                },
                {
                    tooltip_name: translations?.['project_monitoring.claim_register.table_headers.current_status'],
                    tooltip_text: translations?.['project_monitoring.claim_register.tooltips.current_status']
                },
            ]

            setTableHeaders(
                headers.map((item) => ({
                    ...item,
                    showTooltip: false,
                }))
            );
        }
    }, [translations])

    useEffect(() => {
        if (glossaryData?.data?.length) {
            setMoreInfo(
                glossaryData?.data?.filter(
                    (el) => el?.section_name === "Claim Register"
                )[0]
            );
        }
        setData((old) => {
            let data = old.map((item) => ({ ...item, edit: false }));
            return data;
        });
        setEditable(false);
    }, [
        createClaimRegisterIsSuccess,
        updateClaimRegisterIsSuccess,
        deleteClaimRegisterIsSuccess,
        glossaryData,
    ]);

    useEffect(() => {
        const d = () => {
            dispatch(setLoader(false));
        };

        createClaimRegisterIsLoading ||
        updateClaimRegisterIsLoading ||
        deleteClaimRegisterIsLoading
            ? dispatch(setLoader(true))
            : d();
    }, [
        createClaimRegisterIsLoading,
        updateClaimRegisterIsLoading,
        deleteClaimRegisterIsLoading,
    ]);

    const setterData = (input) => {
        const body = {
            claim_reference: input?.claim_reference,
            claim_description: input?.claim_description,
            notice_date: moment(input?.notice_date).format("YYYY-MM-DD"),
            submitted_amount: input?.submitted_amount
                ?.toString()
                ?.split(",")
                .join(""),
            project_id: claimData?.id,
        };

        let s = [];

        if (currentItem?.certified_amount !== null) {
            body.certified_amount = currentItem?.certified_amount
                ?.toString()
                ?.split(",")
                .join("");
        }

        if (currentItem?.certified) {
            body.date_claim_certified = moment(input?.date_claim_certified).format("YYYY-MM-DD")
        }

        for (let key in body) {
            if (!body[key] || body[key] === "Invalid date" || body[key] === "") {
                toast.error("Please enter " + key?.split("_")?.join(" "), {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
                s.push("false");
            }
        }

        if (s.length === 0) {

            if (input?.detailed_date) {
                body.detailed_date = moment(input?.detailed_date).format("YYYY-MM-DD")
            }
            return body;
        } else {
            return null;
        }
    };

    const submitHandler = (e, item) => {
        e.preventDefault();
        if (newItem) {
            const dataCreated = setterData(currentItem);
            if (dataCreated) {
                createClaimRegister({
                    ...dataCreated,
                    certified: currentItem?.certified,
                    current_status: currentItem?.current_status,
                }).then((res) => {
                    if (res?.error) {
                    } else {
                        setCurrentItem(null);
                        setNewItem(false);
                        setCurrentRow(null);
                        dispatch(setLoader(true));
                        trigger(localStorage.getItem("itemId"));
                    }
                });
            }
        } else {
            const dataUpdated = setterData(currentItem);
            if (dataUpdated) {
                updateClaimRegister({
                    id: item?.id,
                    body: {
                        ...dataUpdated,
                        certified: currentItem?.certified,
                        current_status: currentItem?.current_status,
                    },
                }).then((res) => {
                    if (res?.error) {
                    } else {
                        setCurrentItem(null);
                        setNewItem(false);
                        setCurrentRow(null);
                        dispatch(setLoader(true));
                        trigger(localStorage.getItem("itemId"));
                    }
                });
            }
        }
    };

    return (
        <div className="project__goods">
            {deleteModal?.show && (
                <DeleteModal
                    id={deleteModal?.id}
                    action={deleteClaimRegister}
                    setDeleteModal={setDeleteModal}
                    cencelText={translations?.['main.main.actions.cancel']}
                    deleteText={translations?.['main.main.actions.delete']}
                    modalTitle={translations?.['main.main.headers.confirm_your_action']}
                    clarifyingText={translations?.['main.main.titles.you_are_going_to_delete_this_row']}
                />
            )}

            <div className={`project__block ${show ? "" : "close"}`}>
                <div className="project__block-subtitles">
                    <div className="project__block-subtitle">{translations?.['project_monitoring.claim_register.headers.claim_register']}</div>
                    <div className="project__block__buttonsContainer">
                        {hasArchived && <button
                            onClick={() => setShowByActive(!showByActive)}
                            className="project__block-add-row">
                            <span> <img src="/assets/icons/blue-eye.svg" alt="plus"/></span>
                            <span>{showByActive ? translations?.['project_monitoring.claim_register.actions.show_all']
                                : translations?.['project_monitoring.claim_register.actions.show_active']}</span>
                        </button>}
                        {user?.permissions?.edit_project && (
                            <button
                                onClick={() => {
                                    let randomId = Math.floor(Math.random() * 1000);
                                    setCurrentRow(randomId);
                                    scrollRef !== null && scrollRef.current.scrollIntoView();
                                    if (!newItem) {
                                        setNewItem(true);
                                        setEditable(true);
                                        setData((old) => [
                                            ...old,
                                            {
                                                id: randomId,
                                                claim_reference: "",
                                                claim_description: "",
                                                notice_date: null,
                                                detailed_date: null,
                                                submitted_amount: null,
                                                certified: 0,
                                                certified_amount: null,
                                                date_claim_certified: null,
                                                current_status: "",
                                                edit: true,
                                            },
                                        ]);
                                        setCurrentItem({
                                            id: randomId,
                                            claim_reference: "",
                                            claim_description: "",
                                            notice_date: null,
                                            detailed_date: null,
                                            submitted_amount: null,
                                            certified: 0,
                                            certified_amount: null,
                                            date_claim_certified: null,
                                            current_status: "",
                                            edit: true,
                                        });
                                    }
                                }}
                                className="project__block-add-row"
                            >
              <span>
                <img src="/assets/icons/blue-plus.svg" alt="plus" />
              </span>
                                <span>{translations?.['project_monitoring.claim_register.actions.add_row']}</span>
                            </button>
                        )}
                    </div>
                </div>
                <div
                    className={`project__block-content permits ${
                        editable ? "editable" : ""
                    }`}
                >
                    <div
                        className={`project__block-table project-table ${
                            currentItem ? "n" : ""
                        }`}
                    >
                        <div className={`project-table__header  grid-9`} ref={scrollRef}>
                            {tableHeaders?.map((el, idx) => {
                                return (
                                    <div
                                        key={idx}
                                        onMouseEnter={() => {
                                            setTableHeaders(
                                                tableHeaders.map((item) => ({
                                                    ...item,
                                                    showTooltip: el.tooltip_name === item.tooltip_name,
                                                }))
                                            );
                                        }}
                                        onMouseLeave={() => {
                                            setTableHeaders(
                                                tableHeaders.map((item) => ({
                                                    ...item,
                                                    showTooltip: false,
                                                }))
                                            );
                                        }}
                                        className="project-table__th"
                                    >
                                        {el?.showTooltip ? (
                                            <span
                                                className={
                                                    el?.tooltip_text?.length > 20
                                                        ? "lengthy"
                                                        : "not-lengthy"
                                                }
                                            >
                        {el?.tooltip_text}
                      </span>
                                        ) : (
                                            ""
                                        )}
                                        {el?.tooltip_name}
                                    </div>
                                );
                            })}
                        </div>
                        <div className="project-table__body">
                            {data?.map((item, index) => (
                                <div key={index}>
                                    {index !== 0 && currentRow === item?.id && (
                                        <div className={`project-table__header grid-9`} key={index}>
                                            {tableHeaders?.map((el, idx) => {
                                                return (
                                                    <div key={idx} className="project-table__th">
                                                        {el?.showTooltip ? (
                                                            <span
                                                                className={
                                                                    el?.tooltip_text?.length > 20
                                                                        ? "lengthy"
                                                                        : "not-lengthy"
                                                                }
                                                            >
                                {el?.tooltip_text}
                              </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {el?.tooltip_name}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                    <form
                                        className={`project-table__row grid-9 ${
                                            item?.edit ? "edit" : currentItem ? "n" : ""
                                        } ${
                                            Boolean(item.submitted) ||
                                            !user?.permissions?.edit_project
                                                ? "submitted"
                                                : ""
                                        }`}
                                        id={item?.id}
                                        key={item?.id}
                                        onSubmit={(e) => submitHandler(e, item)}
                                    >
                                        <div className="project-table__td">
                                            <span>{item?.claim_reference}</span>
                                            <div className="project__block-input project-table__field">
                        <textarea
                            maxLength={190}
                            defaultValue={item?.claim_reference}
                            onChange={(e) => {
                                setCurrentItem((old) => ({
                                    ...old,
                                    claim_reference: e.target.value,
                                }));
                            }}
                        />
                                            </div>
                                        </div>
                                        <div className="project-table__td">
                                            <span>{item?.claim_description}</span>
                                            <div className="project__block-input project-table__field">
                        <textarea
                            className={`${
                                expanded ? "project__block-expandedInput" : ""
                            }`}
                            ref={expandedRef}
                            onFocus={() => setExpanded(true)}
                            onBlur={() => setExpanded(false)}
                            maxLength={190}
                            defaultValue={item?.claim_description}
                            onChange={(e) => {
                                setCurrentItem((old) => ({
                                    ...old,
                                    claim_description: e.target.value,
                                }));
                            }}
                        />
                                            </div>
                                        </div>
                                        <div className="project-table__td">
                      <span>
                        {moment(item?.notice_date).format("DD/MM/YYYY") ===
                        "Invalid date" ||
                        !moment(item?.notice_date).format("DD/MM/YYYY")
                            ? ""
                            : moment(item?.notice_date).format("DD/MM/YYYY")}
                      </span>
                                            <div className="project__block-item-calendar project-table__field">
                                                <CustomDatePicker
                                                    portalId="root-portal"
                                                    autoComplete="off"
                                                    id="approved_date"
                                                    placeholderText={translations?.['main.main.placeholder.dd_mm_yyyy']}
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={
                                                        currentItem?.notice_date
                                                            ? moment(currentItem?.notice_date).toDate()
                                                            : ""
                                                    }
                                                    onChange={(date) => {
                                                        setCurrentItem((old) => ({
                                                            ...old,
                                                            notice_date: date,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="project-table__td">
                      <span>
                        {moment(item?.detailed_date).format("DD/MM/YYYY") ===
                        "Invalid date" ||
                        !moment(item?.detailed_date).format("DD/MM/YYYY")
                            ? ""
                            : moment(item?.detailed_date).format("DD/MM/YYYY")}
                      </span>
                                            <div className="project__block-item-calendar project-table__field">
                                                <CustomDatePicker
                                                    portalId="root-portal"
                                                    autoComplete="off"
                                                    id="approved_date"
                                                    placeholderText={translations?.['main.main.placeholder.dd_mm_yyyy']}
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={
                                                        currentItem?.detailed_date
                                                            ? moment(currentItem?.detailed_date).toDate()
                                                            : ""
                                                    }
                                                    onChange={(date) => {
                                                        setCurrentItem((old) => ({
                                                            ...old,
                                                            detailed_date: date,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="project-table__td">
                      <span>
                        $
                          {String(item?.submitted_amount).replace(
                              /(\d)(?=(\d{3})+([^\d]|$))/g,
                              "$1,"
                          )}
                      </span>
                                            <div className="project__block-input project-table__field">
                                                <input
                                                    maxLength={190}
                                                    value={
                                                        currentItem
                                                            ? currentItem?.submitted_amount?.toLocaleString()
                                                            : item?.submitted_amount?.toLocaleString()
                                                    }
                                                    onChange={(e) => {
                                                        let newValue = e.target.value.replace(/,/g, "");
                                                        let abc = newValue
                                                            .replace(/[^0-9.-]/g, "")
                                                            .replace(/(?!^-)-/g, "");
                                                        let formattedValue = abc.replace(
                                                            /\B(?=(\d{3})+(?!\d))/g,
                                                            ","
                                                        );
                                                        setCurrentItem((old) => ({
                                                            ...old,
                                                            submitted_amount: formattedValue,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="project-table__td">
                                            <Switch
                                                value={item?.certified}
                                                id={index + 931}
                                                disabled={item?.edit ? false : true}
                                                checked={
                                                    item?.edit ? currentItem?.certified : item?.certified
                                                }
                                                handler={(value) => {
                                                    setCurrentItem((old) => ({
                                                        ...old,
                                                        certified: value,
                                                    }));
                                                }}
                                            />
                                        </div>
                                        <div className="project-table__td">
                      <span>
                        {item?.certified ? item?.date_claim_certified ? moment(item?.date_claim_certified).format("DD/MM/YYYY") ===
                        "Invalid date" ||
                        !moment(item?.date_claim_certified).format("DD/MM/YYYY")
                            ? ""
                            : moment(item?.date_claim_certified).format("DD/MM/YYYY") : '' : ''}
                      </span>
                                            <div className="project__block-item-calendar project-table__field">
                                                <CustomDatePicker
                                                    portalId="root-portal"
                                                    autoComplete="off"
                                                    id="approved_date"
                                                    placeholderText={translations?.['main.main.placeholder.dd_mm_yyyy']}
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={
                                                        currentItem?.date_claim_certified
                                                            ? moment(currentItem?.date_claim_certified).toDate()
                                                            : ""
                                                    }
                                                    onChange={(date) => {
                                                        setCurrentItem((old) => ({
                                                            ...old,
                                                            date_claim_certified: date,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="project-table__td">
                      <span>
                        {item?.certified
                            ? item?.certified_amount !== null
                                ? "$" +
                                String(item?.certified_amount).replace(
                                    /(\d)(?=(\d{3})+([^\d]|$))/g,
                                    "$1,"
                                )
                                : ""
                            : ""}
                      </span>
                                            <div className="project__block-input project-table__field">
                                                <input
                                                    disabled={!currentItem?.certified}
                                                    maxLength={190}
                                                    value={
                                                        currentItem
                                                            ? currentItem?.certified_amount?.toLocaleString()
                                                            : item?.certified_amount?.toLocaleString()
                                                    }
                                                    onChange={(e) => {
                                                        let newValue = e.target.value.replace(/,/g, "");
                                                        let abc = newValue
                                                            .replace(/[^0-9.-]/g, "")
                                                            .replace(/(?!^-)-/g, "");
                                                        let formattedValue = abc.replace(
                                                            /\B(?=(\d{3})+(?!\d))/g,
                                                            ","
                                                        );
                                                        setCurrentItem((old) => ({
                                                            ...old,
                                                            certified_amount: formattedValue,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="project-table__td">
                                            <span>{item?.current_status}</span>
                                            <div className="project__block-input project-table__field">
                        <textarea
                            className={`${
                                decrExpanded ? "project__block-expandedInput" : ""
                            }`}
                            ref={descrRef}
                            onFocus={() => setDecrExpanded(true)}
                            onBlur={() => setDecrExpanded(false)}
                            maxLength={190}
                            defaultValue={item?.current_status}
                            onChange={(e) => {
                                setCurrentItem((old) => ({
                                    ...old,
                                    current_status: e.target.value,
                                }));
                            }}
                        />
                                            </div>
                                        </div>
                                        <div className="project__block-btns">
                                            {currentItem ? (
                                                <div
                                                    className="project__block-cancel"
                                                    onClick={() => {
                                                        setCurrentRow(null);
                                                        let array = data?.map((item) => {
                                                            item.edit = false;
                                                            // if (currentItem?.completed) {
                                                            //     item.completed = false;
                                                            //     setCurrentItem((old) => ({
                                                            //         ...old,
                                                            //         completed: false,
                                                            //     }));
                                                            // }
                                                            return item;
                                                        });
                                                        setCurrentItem(null);
                                                        setData(array);
                                                        if (newItem) {
                                                            setNewItem(false);
                                                            let s = data?.filter(
                                                                (el, i) => i !== data.length - 1
                                                            );
                                                            setData(s);
                                                        }
                                                    }}
                                                >
                                                    <img src="/assets/icons/close-mark.svg" alt="close" />
                                                    {translations?.['project_monitoring.claim_register.actions.cancel']}
                                                </div>
                                            ) : (
                                                <div
                                                    className="project__block-delete"
                                                    onClick={() => {
                                                        // deleteClaimRegister(item?.id);
                                                        setDeleteModal({ id: item?.id, show: true });
                                                    }}
                                                >
                                                    <img src="/assets/icons/trash-icon.svg" alt="arrow" />
                                                    {translations?.['project_monitoring.claim_register.actions.delete']}
                                                </div>
                                            )}
                                            {currentItem ? (
                                                <button className="project__block-save">
                                                    <img src="/assets/icons/save-arrow.svg" alt="arrow" />
                                                    {translations?.['project_monitoring.claim_register.actions.save']}
                                                </button>
                                            ) : (
                                                <div
                                                    onClick={() => {
                                                        setCurrentRow(item?.id);
                                                        setCurrentItem(item);
                                                        setEditable(true);
                                                        setData(() => {
                                                            let array = data?.map((elem) => {
                                                                if (elem?.id === item?.id) {
                                                                    elem.edit = true;
                                                                }
                                                                return elem;
                                                            });
                                                            return array;
                                                        });
                                                    }}
                                                    className="project__block-update"
                                                >
                                                    {translations?.['project_monitoring.claim_register.actions.edit']}
                                                </div>
                                            )}
                                        </div>
                                    </form>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="project__status-show">
                <div></div>
            </div>
            <div className="project__status-content">
                <div className="varandclaims__boxLong">
                    <p className="project__status-subtitle">{translations?.['project_monitoring.claim_register.titles.total_claims']}</p>
                    <span className="project__status-text">
            ${claimData?.claims_info?.total_variations}
          </span>
                </div>
                <div className="varandclaims__boxLong">
                    <p className="project__status-subtitle">{translations?.['project_monitoring.claim_register.titles.total_claims_approved']}</p>
                    <span className="project__status-text">
            ${claimData?.claims_info?.total_variations_approved}
          </span>
                </div>
                <div className="varandclaims__boxLong">
                    <p className="project__status-subtitle">
                        {translations?.['project_monitoring.claim_register.titles.total_claims_pending_approval']}
                    </p>
                    <span className="project__status-text">
            ${claimData?.claims_info?.total_variation_pending_approval}
          </span>
                </div>
            </div>
        </div>
    );
};
