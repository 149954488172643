import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import React, { useEffect, useRef, useState } from 'react';
import ItemEdit from "../EditQuarterlyReport/Covenants/ItemEdit";
import {
    useLazyGetSubmissionsAuditedQuery,
    useUpdateSubmissionsAuditedMutation,
} from "../../store/service/adbService";
import { errorHandle } from "../../global/errorHandle";
import { setLoader } from "../../store/slice/dashboard";
import moment from "moment/moment";

const Table = ({ tableData, reportId, translations, url, isReportSubmitted }) => {
    const headers = [
        translations?.['covenants.financial_covenants.table_headers.ea'],
        translations?.['covenants.financial_covenants.table_headers.fy'],
        translations?.['covenants.financial_covenants.table_headers.fy_end'],
        translations?.['covenants.financial_covenants.table_headers.months_due_after_fy'],
        translations?.['covenants.financial_covenants.table_headers.due_date'],
        translations?.['covenants.financial_covenants.table_headers.received_date'],
        translations?.['covenants.financial_covenants.table_headers.delays_months'],
        translations?.['covenants.financial_covenants.table_headers.acceptable'],
        translations?.['covenants.financial_covenants.table_headers.auditors_opinion'],
        translations?.['covenants.financial_covenants.table_headers.status_of_compliance'],
        translations?.['covenants.financial_covenants.table_headers.rating'],
    ];
    const dispatch = useDispatch();
    const headerRef = useRef();
    const [isOpenEdit, setIsOpenEdit] = useState(false);
    const [auditedData, setAuditedData] = useState(tableData);
    const [cellWidths, setCellWidths] = useState([]);


    const [
        getSubmissionsAudited,
        {
            data: newSubmissionsAuditedData,
            isLoading: submissionsAuditedIsLoading,
            error: submissionsAuditedError,
            isError: submissionsAuditedIsError,
            isSuccess: submissionsAuditedIsSuccess,
        },
    ] = useLazyGetSubmissionsAuditedQuery();

    useEffect(() => {
        setAuditedData(tableData);
    }, [tableData]);

    const updateSubmissions = () => {
        getSubmissionsAudited({reportId:reportId, url: url});
    };

    useEffect(() => {
        if (submissionsAuditedIsSuccess) {
            setAuditedData(newSubmissionsAuditedData?.data);
        }
    }, [submissionsAuditedIsSuccess, newSubmissionsAuditedData]);

    useEffect(() => {
        if (submissionsAuditedIsError) {
            toast.error(submissionsAuditedError?.data.message);
            errorHandle(submissionsAuditedError);
        }
    }, [submissionsAuditedError, submissionsAuditedIsError]);

    useEffect(() => {
        submissionsAuditedIsLoading
            ? dispatch(setLoader(true))
            : dispatch(setLoader(false));
    }, [submissionsAuditedIsLoading]);

    useEffect(() => {
        if (headerRef.current) {
            const widths = Array.from(headerRef.current.querySelectorAll('th')).map(th => th?.offsetWidth -22);
            setCellWidths(widths);
        }
    }, [headerRef]);

    return (
        <table className='report__table' ref={headerRef} style={{ paddingBottom: '35px' }}>
            <thead className="report__table__header">
            <tr className="report__table__row">
                {headers && headers?.map((item, index) => <th key={index}>{item}</th>)}
                <th  style={{ padding: 0, width: 0 }} ></th>
            </tr>
            </thead>
            <tbody className="report__table__body">
            {auditedData &&
                <React.Fragment>
                    {!isOpenEdit ? (
                        <tr className="report__table__row">
                            {  Object.keys(auditedData)?.map((key, idx) => {
                                if (key==='due_date' || key==='received_date') {
                                    return  (
                                        <td key={idx}>{auditedData[key] ? moment(auditedData[key], "YYYY-MM-DD").format("DD/MM/YYYY") : ''}</td>
                                    )
                                }
                                else return (
                                    <td key={idx}>{auditedData[key]}</td>
                                )

                            })}
                            {!isReportSubmitted && <td style={{padding: 0, width: 0}}>
                                <div className="editReport__table__settingsButtons">
                                    <button
                                        className="editReport__table__updateBtn"
                                        onClick={() => setIsOpenEdit(true)}
                                    >
                                        {translations?.['covenants.financial_covenants.actions.update']}
                                    </button>
                                </div>
                            </td>}
                        </tr>
                    ) : (
                        <ItemEdit
                            url={url}
                            headerRef={headerRef}
                            itemData={auditedData}
                            reportId={reportId}
                            setIsOpenEdit={setIsOpenEdit}
                            getItems={updateSubmissions}
                            cancelTranslation={translations?.['financing_plan.index.actions.cancel']}
                            saveTranslation={translations?.['financing_plan.index.actions.save']}
                            useUpdateItemMutation={useUpdateSubmissionsAuditedMutation}
                            fields={Object.keys(auditedData)}
                            feldLabels ={ [
                                'EA',
                                'FY',
                                'FY End',
                                'Months Due After FY',
                                'Due Date',
                                'Received Date',
                                'Delays',
                                'Acceptable',
                                'Auditors Opinion',
                                'Status of Compliance',
                                'Rating',
                            ]}
                            placeHolders={[
                                translations?.['index.index.placeholder.text'],
                                translations?.['index.index.placeholder.text'],
                                translations?.['index.index.placeholder.text'],
                                translations?.['index.index.placeholder.number'],
                                translations?.['index.index.placeholder.choose_date'],
                                translations?.['index.index.placeholder.choose_date'],
                                translations?.['index.index.placeholder.number'],
                                translations?.['index.index.placeholder.text'],
                                translations?.['index.index.placeholder.text'],
                                translations?.['index.index.placeholder.text'],
                                translations?.['index.index.placeholder.number'],
                            ]}
                            cellWidths={cellWidths}
                            isShowLabel={false}

                        />
                    )}
                </React.Fragment>
            }
            </tbody>
        </table>
    );
};

export default Table;
