import React, {useRef, useState} from "react";
import OtherCovenants from "./OtherCovenants";
import FinancialCovenants from "./FinancialCovenants";
import MoreInfoModal from "../../../components/MoreInfoModal";
import './index.scss'

const Covenants = ({reportId, translations, data, hiddenSectionHandler, isReportSubmitted, sections} ) => {
  const sectionsRef = useRef();
    const [isOpenMoreInfo, setOpenMoreInfo] = useState(false)

    const keysToCheck = ['financial_covenants', 'loan_covenants', 'safeguards_covenants', 'environmental_covenants', 'gender_covenants', 'other_covenants'];

    const checkAnyKey = (obj, keys) => {
        return keys.some(key => key in obj);
    };

    const Header = () => {
        if (!checkAnyKey(sections, keysToCheck)) {
            return null;
        }
        return (
            <>
                <div className="editReport__sectionHeader">
                    <span  onClick={() => hiddenSectionHandler(sectionsRef)}/>
                    <h2  onClick={() => hiddenSectionHandler(sectionsRef)}>
                        {translations?.['covenants.index.headers.covenants']}
                    </h2>
                    <button className="editReport__sectionHeader__moreInfo" onClick={()=>setOpenMoreInfo(!isOpenMoreInfo)}>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.99805 6.33325V9.43992" stroke="#767676" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M6.99805 5.05322C7.27419 5.05322 7.49805 4.82937 7.49805 4.55322C7.49805 4.27708 7.27419 4.05322 6.99805 4.05322C6.7219 4.05322 6.49805 4.27708 6.49805 4.55322C6.49805 4.82937 6.7219 5.05322 6.99805 5.05322Z" fill="#767676"/>
                            <path d="M6.9987 13.1666C10.4045 13.1666 13.1654 10.4057 13.1654 6.99992C13.1654 3.59416 10.4045 0.833252 6.9987 0.833252C3.59294 0.833252 0.832031 3.59416 0.832031 6.99992C0.832031 10.4057 3.59294 13.1666 6.9987 13.1666Z" stroke="#767676" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        {translations?.['covenants.index.titles.more_info']}
                    </button>
                </div>
                <hr />
            </>
        )
    }


    return (
        <section className="covenants" ref={sectionsRef}>
            {isOpenMoreInfo &&
                <MoreInfoModal
                    title= {translations?.['covenants.index.headers.covenants']}
                    moreInfo={translations?.['covenants.index.more_info.more_info']}
                    close={()=>setOpenMoreInfo(false)}
                />
            }
            <Header/>
            <FinancialCovenants
                reportId={reportId}
                data={data?.financial_covenants}
                translations={translations}
                sectionId='financial_covenants'
                isReportSubmitted={isReportSubmitted}
                sections={sections}
            />
            {sections?.hasOwnProperty('financial_covenants') && <hr/>}
            <OtherCovenants
                title={translations?.['covenants.loan_covenants.headers.loan_covenants']}
                reportId={reportId}
                data={data?.loan_covenants}
                translations={translations}
                covenantsType={'loan'}
                sectionId='loan_covenants'
                isReportSubmitted={isReportSubmitted}
                sections={sections}
            />
            {sections?.hasOwnProperty('loan_covenants') && <hr/>}
            <OtherCovenants
                title={translations?.['covenants.safeguards_covenants.headers.safeguards_covenants']}
                reportId={reportId}
                data={data?.safeguards_covenants}
                translations={translations}
                covenantsType={'safeguards'}
                sectionId="safeguards_covenants"
                isReportSubmitted={isReportSubmitted}
                sections={sections}
            />
            {sections?.hasOwnProperty('safeguards_covenants') && <hr/>}
            <OtherCovenants
                title={translations?.['covenants.environmental_covenants.headers.environmental_covenants']}
                reportId={reportId}
                data={data?.environmental_covenants}
                translations={translations}
                covenantsType={'environmental'}
                sectionId="environmental_covenants"
                isReportSubmitted={isReportSubmitted}
                sections={sections}
            />
            {sections?.hasOwnProperty('environmental_covenants') && <hr/>}
            <OtherCovenants
                title={translations?.['covenants.gender_covenants.headers.gender_covenants']}
                reportId={reportId}
                data={data?.gender_covenants}
                translations={translations}
                covenantsType={'gender'}
                sectionId="gender_covenants"
                isReportSubmitted={isReportSubmitted}
                sections={sections}
            />
            {sections?.hasOwnProperty('gender_covenants') && <hr/>}
            <OtherCovenants
                title={translations?.['covenants.other_covenants.headers.other_covenants']}
                reportId={reportId}
                data={data?.other_covenants}
                translations={translations}
                covenantsType={'other'}
                sectionId="other_covenants"
                isReportSubmitted={isReportSubmitted}
                sections={sections}
            />
        </section>
    )
}

export default Covenants;
