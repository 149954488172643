import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {useForgotSendEmailMutation, useLazyForgotPasswordQuery} from "../../store/service/adbService";
import AuthWrapper from "../../components/AuthWrapper";
import AxelInput from "../../UI/AxelInput";
import AxelButton from "../../UI/AxelButton";
import { validationEmail } from "../../global/validate";
import Loader from "../../components/Loader";
import { useDispatch } from "react-redux";
import { setLoader } from "../../store/slice/dashboard";
import { useErrorHandler } from "../../global/ErrorsHandler";
import {setEmail} from "../../store/slice/forgetSlice";

export default function ForgotPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [state, setState] = useState({
    email: {
      value: "",
      id: "email",
      error: "Please enter correct email",
      errorShow: false,
    },
  });

  const [forgotHandler, { data, isError, isLoading, error }] =
    useLazyForgotPasswordQuery();
  const [errorHandler, setErrorHandler] = useErrorHandler([error]);

  const inputHandler = (e) => {
    setState((old) => ({
      ...old,
      [e.target.id]: { ...old[e.target.id], value: e.target.value },
    }));
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (validationEmail(state.email.value)) {
      const l = await forgotHandler(state.email.value);
      if (l.isSuccess) {
        navigate("/forgot-pin")
        dispatch(setEmail(state.email.value))
        // navigate("/forgot-pin", { email: state.email.value });
      }
      if (l.isError) {
      }
    } else {
      setState((old) => ({ ...old, email: { ...old.email, errorShow: true } }));
    }
  };

  useEffect(() => {
    isLoading ? dispatch(setLoader(true)) : dispatch(setLoader(false));
  }, [isLoading]);

  return (
    <>
      <AuthWrapper>
        <h1 className="auth__title">Forgot password </h1>
        <p className="auth__descr">
          Enter the email address associated with your account and we'll send
          you a link to reset your password.
        </p>
        <form
          action="#"
          className="auth__form login__form"
          onSubmit={submitHandler}
        >
          <AxelInput
            placeholder="Email"
            type="email"
            value={state.email.value}
            handler={inputHandler}
            id={state.email.id}
            error={state.email.error}
            errorShow={state.email.errorShow}
          />
          <AxelButton text="Send verification code" />
        </form>
      </AuthWrapper>
    </>
  );
}
