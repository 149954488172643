import React from "react";
import {useDispatch} from "react-redux";
import exportSectionAsCSV from "../../global/exportSectionAsCSV";
import './index.scss'

const DownloadCSV = ({url, btnTitle, fileName, tooltipTitle, className}) => {
    const dispatch = useDispatch();

    const handleExport = (url) => {
        exportSectionAsCSV(url, fileName, dispatch);
    };

    return(
      <>
          <button className={`downloadBtn ${className}`}  onClick={() => handleExport(url)}>
             <img src="/assets/icons/green-download.svg" alt="downloader icon"/>
             {btnTitle}
              {tooltipTitle && <span className="downloadBtn__tooltip">{tooltipTitle}</span>}
          </button>
      </>
    )
}

export default DownloadCSV;