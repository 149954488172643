import React, {useEffect, useState} from "react";
import {GenderTracking} from "./GenderTracking";
import {SsempMonitoring} from "./SsempMonitoring";
import {SocialMonitoring} from "./SocialMonitoring";
import {HealthMonitoring} from "./HealthMonitoring";
import {HealthStatistics} from "./HealthStatistics";
import {HealthTable} from "./HealthTable";
import {Complaints} from "./Complaints";
import {ComplaintStatistics} from "./ComplaintStatistics";
import {SecurityMonitoring} from "./SecurityMonitoring";
import { Activities } from "../Activities";
import MoreInfoModal from "../../MoreInfoModal";


const customStyles = {
    menu: (provided, state = false) => ({
        ...provided,
        width: "150%",
        color: state.selectProps.menuColor,
        cursor: "pointer",
        height: 150,
        overflow: "hidden",
    }),

    control: (_, {selectProps: {width}}) => ({
        width: "100%",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        display: "flex",
        background: "#FFFFFF",
        borderRadius: 8,
    }),
};

export const Environment = ({glossaryData, id, data, refEnvironment, mainData, larpData, idLarp, showLarp, setShowLarp, translations}) => {

    const [moreInfo, setMoreInfo] = useState(null)
    const [infoModal, setInfoModal] = useState(false)
    const [show, setShow] = useState(true)

    useEffect(() => {
        setMoreInfo(glossaryData?.data?.filter(el => el?.section_name === "Environment, Social Health & Safety and Security Obligations")[0])
    }, [glossaryData])

    const showSection = data?.project_settings?.gender_tracking || data?.project_settings?.ssemp_activity || data?.project_settings?.social_safeguard ||
        data?.project_settings?.larp || data?.project_settings?.health_and_safety_monitoring || data?.project_settings?.health_and_safety_statistic ||
        data?.project_settings?.complaints || data?.project_settings?.security_monitoring

    return (
        <>
            {
                showSection ? <div ref={refEnvironment} className={`project__white-box ${show ? "" : "close"}`}>
                    <div className="project__site">
                        {infoModal && (
                            <MoreInfoModal
                                title={translations?.['environment_social_health_safety_and_security_obligations.environment_social_health_safety_and_security_obligations.headers.environment_social_health_safety_and_security_obligations']}
                                moreInfo={translations?.['environment_social_health_safety_and_security_obligations.environment_social_health_safety_and_security_obligations.more_info.environment_social_health_safety_and_security_obligations']}
                                close={()=> setInfoModal(false)}
                            />
                        )}
                        <div className="project__block-subtitles">
                            <div className="d-f">
                                <div
                                    onClick={() => setShow(!show)}
                                    className="project__block-title data__title"
                                >
                                    <span>{translations?.['environment_social_health_safety_and_security_obligations.environment_social_health_safety_and_security_obligations.headers.environment_social_health_safety_and_security_obligations']}</span>
                                </div>
                                <span
                                    onClick={() => setInfoModal(true)}
                                    className="project__block-more"
                                >
              <span className="img">
                <img src="/assets/icons/info.svg" alt="info"/>
              </span>
              <span className="text">{translations?.['environment_social_health_safety_and_security_obligations.environment_social_health_safety_and_security_obligations.titles.more_info']}</span>
            </span>
                            </div>
                        </div>
                    </div>
                    <div className="project__monitoring">
                        {
                            data?.project_settings?.gender_tracking ?
                                <GenderTracking id={data?.id} mainData={data} genderData={data?.environment}  glossaryData={glossaryData} translations={translations}/> : null
                        }
                        {
                            data?.project_settings?.ssemp_activity ?
                                <SsempMonitoring id={data?.id} mainData={data} ssempData={data?.environment} glossaryData={glossaryData} translations={translations}/> : null
                        }
                        {
                            data?.project_settings?.social_safeguard ?
                                <SocialMonitoring id={data?.id} mainData={data} socialData={data?.environment} glossaryData={glossaryData} translations={translations}/> : null
                        }
                        {
                            data?.project_settings?.larp ?
                                <Activities
                                    mainData={mainData}
                                    larpData={larpData}
                                    glossaryData={glossaryData}
                                    id={idLarp}
                                    show={showLarp}
                                    setShow={setShowLarp}
                                    translations={translations}
                                /> : null
                        }
                        {
                            data?.project_settings?.health_and_safety_monitoring ?
                                <HealthMonitoring id={data?.id} mainData={data} healthData={data?.environment} glossaryData={glossaryData} translations={translations}/> : null
                        }
                        {
                            data?.project_settings?.health_and_safety_statistic ?
                                <HealthStatistics id={data?.id} mainData={data} healthData={data?.environment} glossaryData={glossaryData} translations={translations}/> : null
                        }
                        {
                            data?.project_settings?.health_and_safety_statistic ?
                                <HealthTable id={data?.id} mainData={data} healthData={data?.environment} glossaryData={glossaryData} translations={translations}/> : null
                        }
                        {
                            data?.project_settings?.complaints ?
                                <Complaints id={data?.id} mainData={data} complaintsData={data?.environment} glossaryData={glossaryData} translations={translations}/> : null
                        }
                        {
                            data?.project_settings?.complaints ?
                                <ComplaintStatistics mainData={data} complaintsData={data?.environment} translations={translations}/> : null
                        }
                        {
                            data?.project_settings?.security_monitoring ?
                                <SecurityMonitoring id={data?.id} mainData={data} data={data?.environment} glossaryData={glossaryData} translations={translations}/> : null
                        }
                    </div>

                </div> : null
            }

        </>
    );
};