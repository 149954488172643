import Select from "react-select";
import {toast} from "react-toastify";
import isEmpty from "lodash/isEmpty";
import React, {useCallback, useEffect, useState} from 'react';
import AxelCheckbox from "../../UI/AxelCheckbox";
import SaveFilterModal from "./SaveFilterModal";
import {
    useCreateFilterMutation,
    useLazyGetAllSectorsQuery,
    useLazyGetAllRegionsQuery,
    useLazyGetAllCountriesQuery,
    useLazyGetAllCompaniesQuery,
    useLazyGetSavedFiltersQuery,
    useLazyGetProjectsToStatisticQuery,
} from "../../store/service/adbService";
import './index.scss';

const customStyles = {
    menu: (provided, state = false) => ({
        ...provided,
        width: "100%",
        color: state.selectProps.menuColor,
        cursor: "pointer",
        zIndex: 50,
        overflow: "hidden",
    }),

    control: (_, { selectProps: { } }) => ({
        width: "100%",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        display: "flex",
        background: "#FFFFFF",
        borderRadius: 8,
        padding: 8,
    }),
};

const StatisticFilter = ({ closeModal, statisticsPage, ...props })=>{
    const {
        selectedProjects,
        setSelectedProjects,
        selectedValues,
        setSelectedValues,
        selectAllIsChecked,
        setSelectAllIsChecked,
        projects,
        setProjects,
    } = props
    const [searchText, setSearchText] = useState('');
    const [isEmptyForm, setIsEmptyForm] = useState(true);
    const [isSaveFilterModalOpen,setIsSaveFilterModalOpen] = useState(false);
    const [options,setOptions] = useState();

    const [getSavedFilters, { data: savedFilters }] = useLazyGetSavedFiltersQuery();
    const [getRegions, { data: regions }] = useLazyGetAllRegionsQuery();
    const [getCountries, { data: allCountries }] = useLazyGetAllCountriesQuery();
    const [getSectors, { data: sectors }] = useLazyGetAllSectorsQuery();
    const [getCompanies, { data: companies }] = useLazyGetAllCompaniesQuery();
    const [getProjectsToStatistic, { data: allProjects }] = useLazyGetProjectsToStatisticQuery();
    const [ saveFilter,{ isSuccess: saveFilterIsSuccess} ] = useCreateFilterMutation();

    useEffect(() => {
        getSavedFilters();
        getCountries({ per_page: 1000 });
        getRegions({ per_page: 1000 });
        getSectors({ per_page: 1000 });
        getCompanies({ per_page: 1000 });
    }, []);

    useEffect(() => {
        getSavedFilters();
    }, [saveFilterIsSuccess]);

    useEffect(() => {
        addItemToOptions(allCountries?.data?.data, 'countries');
        addItemToOptions(sectors?.data?.data, 'sectors');
        addItemToOptions(regions?.data?.data, 'regions');

        const defaultSavedFilters =  savedFilters?.data?.filter(item=>item.default===1);
        if(!isEmpty(defaultSavedFilters) && isEmpty(selectedProjects)){
            setDefaultSelectValues(defaultSavedFilters[0].id);
        }
        if (savedFilters?.data) {
            let data = savedFilters?.data?.map((item) => ({
                value: item?.id,
                label: item?.name,
            }));
            setOptions(prevState=>({
                ...prevState,
                savedFilters:data
            }));
        }

        if (companies?.data?.data) {
            let data = companies?.data?.data?.map((item) => ({
                value: item?.id,
                label: item?.long_name,
            }));
            setOptions(oldData=>({
                ...oldData,
                executingAgencies:data
            }));
        }

    }, [savedFilters, allCountries, companies, sectors, regions ]);

    useEffect(() => {
        setProjects(
            allProjects?.data.map(project => ({
                ...project,
                checked: selectAllIsChecked
            })))
    }, [allProjects]);

    useEffect(() => {
        if (selectedProjects?.length > 0) {
            setProjects(prevProjects => prevProjects?.map(project => ({
                ...project,
                checked: selectedProjects.some(selectedProject => selectedProject.id === project.id)
            })));
        }
    }, [selectedProjects, allProjects]);

    useEffect(() => {
        getProjectsToStatistic(selectedValues);

    }, [selectedValues]);

    useEffect(() => {
        if (!isEmpty(projects)){
            const allChecked = projects?.every(project => project.checked === true);
            const someChecked = projects?.some(project => project.checked === true);
            setIsEmptyForm(someChecked)
            setSelectAllIsChecked(allChecked );
        }

    }, [projects]);

    const addItemToOptions = (data, fieldName) => {
        if (data) {
            let   newOptionItem = data.map((item) => ({
                value: item.id,
                label: item.name,
            }));
            setOptions(oldData=>({
                ...oldData,
                [fieldName]:newOptionItem
            }));
        }
    };

    const applyToGetStatistics = useCallback( () => {
        const  filteredProjectIds=  projects.filter(project => project.checked === true);
        if(!isEmpty(filteredProjectIds)) {
            setSelectedProjects(filteredProjectIds);
        } else toast.error('Please select a project');
    }, [projects, setSelectedProjects])

    const saveFilterRequest = useCallback( async (name, isSelected) => {
        await saveFilter({
            name: name,
            default: isSelected,
            ...selectedValues
        })
            .then((e) => {
                toast.success(e.data.message);
                setIsSaveFilterModalOpen(false)
            })
            .catch((error) => toast.error(error))
    }, [saveFilter, selectedValues])


    const inputHandler = (fieldName, id) => {
        setSelectedValues((old) => ({...old, [fieldName]: id}));
    }

    const setDefaultSelectValues = useCallback(( id) => {
        const values = savedFilters?.data?.filter(item => item.id == id);
        setSelectedValues(
            {
                saved_filter_id: id,
                country_id: values[0]?.country_id,
                company_id:values[0]?.company_id,
                region_id: values[0]?.region_id,
                sector_id: values[0]?.sector_id
            }
        );
    },[savedFilters])

    const handleSelectAllProject = (id, checked) => {
        setSelectAllIsChecked(checked)
        setProjects(projects?.map(project => {
                return {
                    ...project,
                    checked: checked
                };
        }))
    }

    const handleSelectProject = (id, checked) => {
        setProjects(oldData => oldData.map(project => {
            if (project.id == id) {
                return {
                    ...project,
                    checked: checked
                };
            }
            return project;
        }))
    }

    const handleClearFromData = () => {
        setSelectedValues(null);
        setSelectedProjects(null)
        setProjects(
            allProjects?.data?.map(project => ({
                ...project,
                checked: false
            })))
    }
    const filteredProjects = projects?.filter(project => project?.contract_title.toLowerCase().includes(searchText.toLowerCase()));

    return (
        <div className="statisticFilterModal">
            <div className="statisticFilter">
               <div className="statisticFilter__header">
                   <h3>
                       {statisticsPage?.['filter.filter.headers.filter']}
                   </h3>
                   <button
                       className="statisticFilter__header__closeIcon"
                       onClick={()=>closeModal(false)}
                   >
                       <img src="/assets/icons/close-dark.svg" alt="close" />
                   </button>
               </div>
                <form className="statisticFilter__form">
                    <div>
                        <p>{statisticsPage?.['filter.filter.titles.saved_filters']}</p>
                        <Select
                            options={options?.savedFilters}
                            styles={{
                                ...customStyles,
                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                            id="saved_filters"
                            isSearchable={true}
                            placeholder={statisticsPage?.['filter.filter.placeholder.select']}
                            value={selectedValues?.saved_filter_id ? options?.savedFilters?.find(option => option.value === selectedValues.saved_filter_id) : null}
                            onChange={(selected) => setDefaultSelectValues(selected.value)}
                        />
                    </div>
                    <div>
                        <p>{statisticsPage?.['filter.filter.titles.region']}</p>
                        <Select
                            styles={{
                                ...customStyles,
                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                            id="regions"
                            isSearchable={true}
                            placeholder={statisticsPage?.['filter.filter.placeholder.select']}
                            options={options?.regions}
                            value={selectedValues?.region_id ? options?.regions?.find(option => option.value === selectedValues.region_id) : null}
                            onChange={(selected) => inputHandler('region_id',selected.value)}
                        />
                    </div>
                    <div>
                        <p>{statisticsPage?.['filter.filter.titles.country']}</p>
                        <Select
                            styles={{
                                ...customStyles,
                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                            id="countries"
                            isSearchable={true}
                            placeholder={statisticsPage?.['filter.filter.placeholder.select']}
                            options={options?.countries}
                            value={selectedValues?.country_id ? options?.countries?.find(option => option.value === selectedValues.country_id) : null}
                            onChange={(selected) => inputHandler('country_id',selected.value)}
                        />
                    </div>
                    <div>
                        <p>{statisticsPage?.['filter.filter.titles.sector']}</p>
                        <Select
                            styles={{
                                ...customStyles,
                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                            id="sectors"
                            isSearchable={true}
                            placeholder={statisticsPage?.['filter.filter.placeholder.select']}
                            options={options?.sectors}
                            value={selectedValues?.sector_id ? options?.sectors?.find(option => option.value === selectedValues.sector_id) : null}
                            onChange={(selected) => inputHandler('sector_id',selected.value)}
                        />
                    </div>
                    <div>
                        <p>{statisticsPage?.['filter.filter.titles.ea_contractor_consultant_supplier']}</p>
                        <Select
                            styles={{
                                ...customStyles,
                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                            id="company_id"
                            isSearchable={true}
                            placeholder={statisticsPage?.['filter.filter.placeholder.select']}
                            options={options?.executingAgencies}
                            value={selectedValues?.company_id ? options?.executingAgencies?.find(option => option.value === selectedValues.company_id) : null}
                            onChange={(selected) => inputHandler('company_id',selected.value)}
                        />
                    </div>
                    <div>
                        <div className="statisticFilter_df">
                            <p>{statisticsPage?.['filter.filter.titles.projects']}</p>
                            <AxelCheckbox
                                id="selectAllProjests"
                                label={statisticsPage?.['filter.filter.actions.select_all']}
                                checked={selectAllIsChecked}
                                handler={handleSelectAllProject}
                            />
                        </div>
                        <div className="statisticFilter__form__multiSelect">
                            <div className="search-block">
                                <input
                                    type="text"
                                    className="search"
                                    placeholder={`${statisticsPage?.['filter.filter.actions.search']}...`}
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                />
                            </div>
                            {filteredProjects?.map(project => (
                                <div className="statisticFilter__form__multiSelect__checkingItem" key={project.id}>
                                    <AxelCheckbox
                                        id={project.id}
                                        checked={project.checked}
                                        label={project?.contract_title}
                                        handler={handleSelectProject}
                                    />
                                    <span>{project.contract_number}</span>
                                </div>
                            ))}
                            { isEmpty(filteredProjects) && <div className="statisticFilter__form__notFoundDat">
                                <img src={'/assets/images/emptyStatisticPlaceHolder.png'} alt="empty file image"/>
                                <p>{statisticsPage?.['filter.filter.titles.no_projects_found_based_on_this_data']}</p>
                            </div>}
                        </div>
                    </div>
                    <div className="statisticFilter__form__footer">
                        <div className="statisticFilter_df">
                            <button
                                type="button"
                                className="statisticFilter__form__btnSaveFilter"
                                onClick={() => setIsSaveFilterModalOpen(true)}
                            >
                                <img src="/assets/icons/save.svg" alt="save icon"/>
                                {statisticsPage?.['filter.filter.actions.save_filter']}
                            </button>
                        </div>
                        <div className="statisticFilter_df">
                            {(selectedValues || isEmptyForm) &&
                                <button
                                    type="reset"
                                    className="statisticFilter__form__btnReset"
                                    onClick={ handleClearFromData}
                                >
                                    {statisticsPage?.['filter.filter.actions.clear']}
                                </button>
                            }
                            <button type="button" className="statisticFilter__form__btnSubmit"
                                    onClick={applyToGetStatistics}
                            >
                                {statisticsPage?.['filter.filter.actions.apply']}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            {isSaveFilterModalOpen && <SaveFilterModal
                statisticsPage={statisticsPage}
                closeModal={setIsSaveFilterModalOpen}
                saveFilterRequest={saveFilterRequest}
            />}
            <div
                className="statisticFilterModal__bg"
                onClick={() => closeModal(false)}>
            </div>
        </div>
    )
}

export default StatisticFilter;