import React, {useEffect, useState} from "react";
import ProjectReports from "./ProjectReports";
import {useLazyGetReportingTranslationsQuery} from '../../store/service/adbService'
import './index.scss';

export const Reporting = ({}) => {
  const [tab, setTab] = useState(1);
  const [translations, setTranslations] = useState(false);
  const [ isAllProject, setIsAllProject ] = useState(false);

  const handleTab = (tabIndex) => {
    setTab(tabIndex);
  };

  const [getReportingTranslations, {
      data: reportingTranslations
  }] = useLazyGetReportingTranslationsQuery();

    useEffect(() => {
        getReportingTranslations()
    }, [])

    useEffect(() => {
        setTranslations(reportingTranslations?.data?.translations);
    }, [reportingTranslations]);

  return (
      <div className="wrapper pl-[100px] pb-[150px] pt-[35px] pr-[25px] min-h-[100vh] reporting">
        <div className="s-pages">
          <div className="s-page__header" style={{justifyContent:"start"}}>
              <h1>{translations?.['index.index.headers.reporting']}</h1>
              {tab ===1 && <div className="s-page__checkbox">
                  <div className="a-checkbox">
                      <input type="checkbox" id="1-radio" name="projects" value={isAllProject}
                             onChange={() => setIsAllProject(!isAllProject)}
                      />
                      <label htmlFor="1-radio">{translations?.['index.index.actions.active_projects']}</label>
                  </div>
              </div>}
          </div>
          <div className="reporting-tabs">
            <div
                className={`reporting-tab ${tab === 1 ? "active" : ""}`}
                data-tab={true}
                onClick={()=>handleTab(1)}
            >
                {translations?.['index.index.titles.project_reports']}
            </div>
            <div
                className={`reporting-tab ${tab === 2 ? "active" : ""}`}
                data-tab={false}
                onClick={()=>handleTab(2)}
            >
                {translations?.['index.index.titles.quarterly_reports']}
            </div>
          </div>
          <ProjectReports
              tab={tab}
              translations={translations}
              isAllProject={isAllProject}
          />
        </div>
      </div>
  );
};
