import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {ProgressChart} from "../ProgressChart/ProgressChart";
import QuarterlyProgressChart from "./QuarterlyProgressChart";
import {Cell, Pie, PieChart} from "recharts";

const RISK_COLORS = ["#0CAF60", "#ECC949", "#E7984F", "#E68484"]

const formattedData = (data) => {
	const months = data?.map(item => {
		return item?.data?.map(el => el.date)
	})
	const cumulative_actual = data?.map(item => {
		return item?.data?.map(el => el.cumulative_actual)
	})
	const cumulative_planned = data?.map(item => {
		return item?.data?.map(el => el.cumulative_planned)
	})
	return {months, cumulative_planned, cumulative_actual}
}

const TableView = ({data, cumulativeActual, cumulativePlanned, totalCumulativeActual, totalCumulativePlanned}) => {
	const {months, cumulative_actual, cumulative_planned} = formattedData(data?.projects_data);

	return data?.projects_data?.map((item, index) => (
			<React.Fragment key={index}>
				<p style={{marginTop: 20}}>{item?.contract_title} - {item?.contract_number}</p>
				<div className={"quarterly-online-table"} style={{marginTop: 20}}>
					<table border="1">
						<thead>
						<tr>
							<th style={{minWidth: 200}}></th>
							{months[index]?.map((date, idx) => (
									<th style={{minWidth: 120}} key={idx}>{date}</th>
							))}
						</tr>
						</thead>
						<tbody>
						<tr>
							<td style={{textAlign: 'left'}}>
								<span className={"cube_blue"}></span>
								<span>{cumulativePlanned}</span>
							</td>
							{cumulative_planned[index]?.map((planned, idx) => (
									<td key={idx}>{planned}</td>
							))}
						</tr>
						<tr>
							<td style={{textAlign: 'left'}}>
								<span className="cube_yellow"></span>
								<span>{cumulativeActual}</span>
							</td>
							{cumulative_actual[index]?.map((actual, idx) => (
									<td key={idx}>{actual}</td>
							))}
						</tr>
						</tbody>
					</table>
					<div className="plannedProject__total-block">
						<div className="plannedProject__total-text">{totalCumulativePlanned}</div>
						<div className="plannedProject__total-text">{item?.total_cumulative_planned}</div>
					</div>
					<div className="plannedProject__total-block">
						<div className="plannedProject__total-text">{totalCumulativeActual}</div>
						<div className="plannedProject__total-text">{item?.total_cumulative_actual}</div>
					</div>
				</div>
			</React.Fragment>
	))
}

const PhysicalContracts = ({data, title, paragraph, tr}) => {
	return (
			<>
				<p>7.1. {title}</p>
				<TableView
					data={data}
					cumulativePlanned={tr?.['lists_of_tables.physical_progress_of_contracts.titles.cumulative_planned']}
					cumulativeActual={tr?.['lists_of_tables.physical_progress_of_contracts.titles.cumulative_actual']}
					totalCumulativeActual={tr?.['lists_of_tables.physical_progress_of_contracts.titles.total_cumulative_actual']}
					totalCumulativePlanned={tr?.['lists_of_tables.physical_progress_of_contracts.titles.total_cumulative_planned']}/>
				<div style={{marginTop: 20}}>
					<p>{paragraph}</p>
					<QuarterlyProgressChart
						data={data?.chart_data}
						emptyDataText={tr?.['lists_of_tables.physical_progress_of_contracts.titles.there_is_no_data_yet']}
						subtitle={tr?.['lists_of_tables.physical_progress_of_contracts.headers.physical_progress_of_contracts']}
						cumulativePlanned={tr?.['lists_of_tables.physical_progress_of_contracts.titles.cumulative_planned']}
						cumulativeActual={tr?.['lists_of_tables.physical_progress_of_contracts.titles.cumulative_actual']}
					/>
				</div>
			</>
	)
}

const CashflowContracts = ({data, title, paragraph, tr}) => {
	return (
			<>
				<p style={{marginTop: 50, fontWeight: 500}}>7.2. {title}</p>
				<TableView
					data={data}
					cumulativePlanned={tr?.['lists_of_tables.cashflow_of_contracts.titles.cumulative_planned']}
					cumulativeActual={tr?.['lists_of_tables.cashflow_of_contracts.titles.cumulative_actual']}
					totalCumulativePlanned={tr?.['lists_of_tables.cashflow_of_contracts.titles.total_cumulative_planned_project_payments_amount']}
					totalCumulativeActual={tr?.['lists_of_tables.cashflow_of_contracts.titles.total_cumulative_actual_project_payments_amount']}
				/>
				<p style={{marginTop: 50, fontWeight: 500}}>{paragraph}</p>
				<QuarterlyProgressChart
					data={data?.chart_data}
					type={false}
					emptyDataText={tr?.['lists_of_tables.physical_progress_of_contracts.titles.there_is_no_data_yet']}
					subtitle={tr?.['lists_of_tables.cashflow_of_contracts.titles.actual_versus_planned_disbursements_across_all_contracts']}
					cumulativePlanned={tr?.['lists_of_tables.cashflow_of_contracts.titles.cumulative_planned']}
					cumulativeActual={tr?.['lists_of_tables.cashflow_of_contracts.titles.cumulative_actual']}
				/>
			</>
	)
}

const RiskContracts = ({data, pageTranslations}) => {
	return (
			<>
				<p style={{marginTop: 50, fontWeight: 500}}>7.3. {pageTranslations?.['lists_of_tables.risk_register_of_contracts.headers.risk_register_of_contracts']}</p>
				{
					data?.map((item, index) => (
							<React.Fragment key={index}>
								<p style={{marginTop: 30}}>{item?.contract_title} - {item?.contract_number}</p>
								<div className={"quarterly-online-table"} style={{marginTop: 20}}>
									<table border="1">
										<thead>
										<tr className={"row_gray"}>
											<th>{pageTranslations?.['lists_of_tables.risk_register_of_contracts.table_headers.ref']}</th>
											<th>{pageTranslations?.['lists_of_tables.risk_register_of_contracts.table_headers.risk_description']}</th>
											<th>{pageTranslations?.['lists_of_tables.risk_register_of_contracts.table_headers.impact_rating']}</th>
											<th>{pageTranslations?.['lists_of_tables.risk_register_of_contracts.table_headers.likelihood_rating']}</th>
											<th>{pageTranslations?.['lists_of_tables.risk_register_of_contracts.table_headers.risk_level']}</th>
											<th>{pageTranslations?.['lists_of_tables.risk_register_of_contracts.table_headers.mitigation_measure']}</th>
											<th>{pageTranslations?.['lists_of_tables.risk_register_of_contracts.table_headers.responsible_party']}</th>
											<th>{pageTranslations?.['lists_of_tables.risk_register_of_contracts.table_headers.current_status_or_comments']}</th>
										</tr>
										</thead>
										<tbody>
										{item?.data?.length ? item?.data?.map((el, idx) => (
												<tr key={idx}>
													<td>{el?.ref}</td>
													<td>{el?.description}</td>
													<td>{el?.impact_rating}</td>
													<td>{el?.likelihood_rating}</td>
													<td>{el?.risk_level}</td>
													<td>{el?.mitigation_measure}</td>
													<td>{el?.responsible_party}</td>
													<td>{el?.current_status}</td>
												</tr>
										)) : <tr>
											<td colSpan={8}>Nothing Data</td>
										</tr>}
										</tbody>
									</table>
								</div>
								<div className="plannedProject__chart" style={{marginTop: 0}}>
									<div className='plannedProject__charts'>
										<p className="plannedProject__chart-title">{pageTranslations?.['lists_of_tables.risk_register_of_contracts.titles.risk_level']}</p>
										<div className="project__dashboard-pieChart"
										     style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
											<div className="project__dashboard-pieBlock">
												<PieChart height={152} width={152}>
													<Pie
															data={item?.chart_data}
															data={
																[
																	{
																		name: pageTranslations?.['lists_of_tables.risk_register_of_contracts.titles.low'],
																		value: item?.chart_data?.low == 0 ? 1 : item?.chart_data?.low
																	},
																	{
																		name: pageTranslations?.['lists_of_tables.risk_register_of_contracts.titles.moderate'],
																		value: item?.chart_data?.moderate == 0 ? 1 : item?.chart_data?.moderate
																	},
																	{
																		name: pageTranslations?.['lists_of_tables.risk_register_of_contracts.titles.substantial'],
																		value: item?.chart_data?.substantial == 0 ? 1 : item?.chart_data?.substantial
																	},
																	{
																		name: pageTranslations?.['lists_of_tables.risk_register_of_contracts.titles.high'],
																		value: item?.chart_data?.high == 0 ? 1 : item?.chart_data?.high
																	}
																]
															}
															dataKey="value"
															nameKey="name"
															color="#000000"
															fill="#8884d8"
															cx="50%"
															cy="50%"
															innerRadius={35}
													>
														{
															[1, 2, 3, 4].map((el, idx) => (
																	<Cell key={`cell-${idx}`}
																	      fill={RISK_COLORS[idx % RISK_COLORS.length]}
																	/>
															))
														}
													</Pie>
												</PieChart>
											</div>
											<div className="project__dashboard-stats">
												<div className="project__dashboard-stat">
													<div className="project__dashboard-statBox">
														<div className="project__program-color green"></div>
														<p>{pageTranslations?.['lists_of_tables.risk_register_of_contracts.titles.low']}</p>
													</div>
													<span>{item?.chart_data?.low || '0'}%</span>
												</div>
												<div className="project__dashboard-stat">
													<div className="project__dashboard-statBox">
														<div className="project__program-color yellow"></div>
														<p>{pageTranslations?.['lists_of_tables.risk_register_of_contracts.titles.moderate']}</p>
													</div>
													<span>{item?.chart_data?.moderate || '0'}%</span>
												</div>
												<div className="project__dashboard-stat">
													<div className="project__dashboard-statBox">
														<div className="project__program-color orange"></div>
														<p>{pageTranslations?.['lists_of_tables.risk_register_of_contracts.titles.substantial']}</p>
													</div>
													<span>{item?.chart_data?.substantial || '0'}%</span>
												</div>
												<div className="project__dashboard-stat">
													<div className="project__dashboard-statBox">
														<div className="project__program-color red"></div>
														<p>{pageTranslations?.['lists_of_tables.risk_register_of_contracts.titles.high']}</p>
													</div>
													<span>{item?.chart_data?.high || '0'}%</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</React.Fragment>
					))
				}
			</>
	)
}

function QuarterlyListsTables({pageTranslations}) {
	const sections_title = useSelector(state => state?.quarterlyReducer?.sections_title) ?? null;
	const list_of_tables = useSelector(state => state?.quarterlyReducer?.online_view?.sections?.lists_of_tables) ?? null;
	const physical_progress_of_contracts = useSelector(state => state?.quarterlyReducer?.online_view?.sections?.lists_of_tables?.physical_progress_of_contracts) ?? null;
	const cashflow_of_contracts = useSelector(state => state?.quarterlyReducer?.online_view?.sections?.lists_of_tables?.cashflow_of_contracts) ?? null;
	const risk_register_of_contracts = useSelector(state => state?.quarterlyReducer?.online_view?.sections?.lists_of_tables?.risk_register_of_contracts) ?? null;
	
	if (!list_of_tables) {
		return <div></div>
	}
	
	return (
			<div className="online__content-projectRow">
				<p className="online__content-boldTitle">7. {pageTranslations?.['lists_of_tables.index.headers.lists_of_tables']}</p>
				{physical_progress_of_contracts ?
					<PhysicalContracts
						title={pageTranslations?.['lists_of_tables.physical_progress_of_contracts.headers.physical_progress_of_contracts']}
						paragraph={pageTranslations?.['lists_of_tables.physical_progress_of_contracts.titles.program_progress_across_all_contracts']}
						tr={pageTranslations}
						data={physical_progress_of_contracts}/> : null}
				{cashflow_of_contracts ?
					<CashflowContracts
						title={pageTranslations?.['lists_of_tables.cashflow_of_contracts.headers.cashflow_of_contracts']}
						paragraph={pageTranslations?.['lists_of_tables.cashflow_of_contracts.titles.actual_versus_planned_disbursements_across_all_contracts']}
						tr={pageTranslations}
						data={cashflow_of_contracts}/> : null}
				{risk_register_of_contracts ?
					<RiskContracts
						pageTranslations={pageTranslations}
						data={risk_register_of_contracts}/> : null}
			</div>
	);
}

export default QuarterlyListsTables;
