import React, { useState, useEffect } from "react";

import "./index.scss";
import AxelButton from "../../../UI/AxelButton";
import { Overview } from "./Overview";
import { SubcontractorPayments } from "./SubcontractorPayments";
import moment from "moment";
import {
    useUpdatePlannedProjectPaymentsMutation,
} from "../../../store/service/adbService";
import { setLoader } from "../../../store/slice/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {VersusChart} from "../../VersusChart";

export const FinancialManagement = ({
                                        data,
                                        glossaryData,
                                        refFinancialManagement,
                                        translations
                                    }) => {
    const dispatch = useDispatch();
    const [paymentsData, setPaymentsData] = useState([]);
    const [currentId, setCurrentId] = useState({ id: null, type: null });
    const [plannedAmount, setPlannedAmount] = useState(null);
    const [actualAmount, setActualAmount] = useState(null);
    const [paymentsList, setPaymentsList] = useState([]);
    const [edit, setEdit] = useState(false);
    const [showSave, setShowSave] = useState(false);
    const user = useSelector((state) => state?.adbReducer?.user);
    const [show, setShow] = useState(true);
    const [tableHeaders, setTableHeaders] = useState([]);
    const [totalHeaders, setTotalHeaders] = useState([]);
    const [currentPlannedAmount, setCurrentPlannedAmount] = useState(null);

    useEffect(() => {
        if(translations){
            const table_headers = [
                {
                    tooltip_name: translations?.['payments.planned_project_payments.titles.planned'],
                    tooltip_text: translations?.['payments.planned_project_payments.titles.planned']
                },
                {
                    tooltip_name: translations?.['payments.planned_project_payments.titles.actual'],
                    tooltip_text: translations?.['payments.planned_project_payments.titles.actual']
                },
                {
                    tooltip_name: translations?.['payments.planned_project_payments.titles.cumulative_planned'],
                    tooltip_text: translations?.['payments.planned_project_payments.titles.cumulative_planned']
                },
                {
                    tooltip_name: translations?.['payments.planned_project_payments.titles.cumulative_actual'],
                    tooltip_text: translations?.['payments.planned_project_payments.titles.cumulative_actual']
                },
            ]

            setTableHeaders(
                table_headers.map((item) => ({
                    ...item,
                    showTooltip: false,
                }))
            );
        }
    }, [translations])

    useEffect(() => {
        if (glossaryData?.data?.length) {
            setTotalHeaders(
                glossaryData?.data
                    ?.filter(
                        (el) => el?.section_name === "Total Planned Project Payments"
                    )
                    ?.map((item) => ({
                        ...item,
                        tooltips: item?.tooltips?.map((tooltip) => ({
                            ...tooltip,
                            showTooltip: false,
                        })),
                    }))[0]
            );
        }
        setPaymentsData(
            data?.planned_project_payments?.data?.map((el) => ({
                ...el,
                edit: false,
            }))
        );
        setPaymentsList(
            data?.planned_project_payments?.data
                ?.filter((el) => el?.actual_amount !== null)
                .map((el) => ({
                    ...el,
                    date: el?.date,
                    planned_amount: +el?.planned_amount,
                    actual_amount: el?.actual_amount !== null ? +el?.actual_amount : null,
                }))
        );
    }, [data, glossaryData]);

    const [
        updatePlannedProjectPayments,
        {
            data: updatePlannedProjectPaymentsData,
            isError: updatePlannedProjectPaymentsIsError,
            isLoading: updatePlannedProjectPaymentsIsLoading,
            isSuccess: updatePlannedProjectPaymentsIsSuccess,
            error: updatePlannedProjectPaymentsError,
        },
    ] = useUpdatePlannedProjectPaymentsMutation();

    useEffect(() => {
        const d = () => {
            setPaymentsData((old) => {
                let data = old?.map((item) => ({ ...item, edit: false }));
                return data;
            });
            dispatch(setLoader(false));
        };

        if (updatePlannedProjectPaymentsIsSuccess) {
            setActualAmount(null);
            setPlannedAmount(null);
            setEdit(false);
            setCurrentId(null);
            setPaymentsData(paymentsData?.map((item) => ({ ...item, edit: false })));
            setShowSave(false);
        }

        updatePlannedProjectPaymentsIsLoading ? dispatch(setLoader(true)) : d();
    }, [
        updatePlannedProjectPaymentsIsLoading,
        updatePlannedProjectPaymentsIsSuccess,
    ]);

    useEffect(() => {
        return window.addEventListener("click", (e) => {
            if (
                paymentsData?.length !== 0 &&
                e.target.closest(".plannedProject__month-box") === null &&
                e.target.closest(".project__block-update") === null
            ) {
                setPaymentsData((old) => [
                    ...old?.map((el) => ({ ...el, edit: false })),
                ]);
                setEdit(false);
            }
        });
    }, [data]);

    const updateProjectPayments = (el) => {
        const body = {
            planned_amount: Number(plannedAmount?.toString()?.split(",").join("")),
        };
        if (
            Number(plannedAmount?.toString()?.split(",").join("")) <= 999999999999
        ) {
            updatePlannedProjectPayments({
                id: el?.id,
                body,
            });
            setPaymentsData(paymentsData?.map((el) => ({ ...el, edit: false })));
        } else {
            toast.error("The planned amount must not be greater than 999999999999.", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 8000,
            });
        }
    };

    let showBr =
        data?.project_settings?.subcontractor_payments ||
        data?.project_settings?.payments_overview;

    return (
        <div
            className={`${showBr ? "project__white-box" : ""} ${show ? "" : "close"}`}
        >
            {data?.project_settings?.payments_overview ? (
                <>
                    <Overview
                        show={show}
                        setShow={setShow}
                        refFinancialManagement={refFinancialManagement}
                        id={refFinancialManagement}
                        glossaryData={glossaryData}
                        data={data}
                        translations={translations}
                    />
                    <div className="plannedProject">
                        <p className="plannedProject__title">{translations?.['payments.planned_project_payments.headers.planned_project_payments']}</p>
                        <div className="plannedProject__table">
                            <div
                                className={`plannedProject__table-row ${
                                    edit && paymentsData?.length > 10 && "active"
                                }`}
                            >
                                <div className="plannedProject__table-months">
                                    <div className="plannedProject__table-monthsBox">
                                        <span>{translations?.['payments.planned_project_payments.titles.months']}</span>
                                    </div>
                                    {tableHeaders?.map((el, i) => {
                                        return (
                                            <div
                                                key={i}
                                                className="plannedProject__table-monthsBox progressChart"
                                            >
                                                <div
                                                    className={
                                                        el?.tooltip_name === translations?.["payments.planned_project_payments.titles.planned"] ? "red"
                                                            : el?.tooltip_name === translations?.['payments.planned_project_payments.titles.actual'] ? "lightblue"
                                                                : el?.tooltip_name === translations?.['payments.planned_project_payments.titles.cumulative_planned'] ? "blue" : "orange"
                                                    }
                                                ></div>
                                                <p
                                                    onMouseOver={(event) => {
                                                        setTableHeaders(
                                                            tableHeaders.map((item) =>
                                                                ({...item, showTooltip: el.tooltip_name === item.tooltip_name})
                                                            ),
                                                        );
                                                    }}
                                                    onMouseLeave={() => {
                                                        setTableHeaders(
                                                            tableHeaders.map((item) =>({...item, showTooltip: false})),
                                                        );
                                                    }}
                                                >
                                                    {el?.tooltip_name}
                                                </p>
                                                {el?.showTooltip ? (
                                                    <span
                                                        style={{
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                        }}
                                                        className={
                                                            el?.tooltip_text?.length > 20
                                                                ? "lengthy"
                                                                : "not-lengthy"
                                                        }
                                                    >
                            {el?.tooltip_text}
                          </span>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                                {paymentsData?.map((el, i) => {
                                    return (
                                        <div key={i} className={`plannedProject__month`}>
                                            <div className="plannedProject__month-box">
                                                {moment(el?.date).format("MMM YYYY")}
                                            </div>
                                            <div
                                                onClick={() => {
                                                    if (user?.permissions?.edit_project) {
                                                        if (!el?.editable) {
                                                            return null;
                                                        }
                                                        setPaymentsData(
                                                            paymentsData?.map((item) => ({
                                                                ...item,
                                                                edit: el?.id === item?.id ? true : false,
                                                            }))
                                                        );
                                                        setCurrentId({ id: el?.id, type: "planned" });
                                                        setEdit(true);
                                                    }
                                                }}
                                                className={`plannedProject__month-box ${
                                                    !el?.editable
                                                        ? "plannedProject__month-box_disable"
                                                        : "plannedProject__month-box_normal"
                                                }`}
                                            >
                        <span>
                          $
                            {currentPlannedAmount !== null &&
                            currentPlannedAmount?.id === el.id
                                ? currentPlannedAmount?.value
                                    ? String(currentPlannedAmount.value).replace(
                                        /(\d)(?=(\d{3})+([^\d]|$))/g,
                                        "$1,"
                                    )
                                    : "-"
                                : el?.planned_amount !== null
                                    ? String(el?.planned_amount).replace(
                                        /(\d)(?=(\d{3})+([^\d]|$))/g,
                                        "$1,"
                                    )
                                    : "0"}
                        </span>
                                                {currentId?.type === "planned" &&
                                                    currentId?.id === el?.id &&
                                                    el?.edit && (
                                                        <div className="plannedProject__month-box-edit ">
                                                            <input
                                                                className={"input-planned"}
                                                                autoFocus={true}
                                                                maxLength={15}
                                                                value={el.planned_amount}
                                                                onFocus={() => {
                                                                    setCurrentPlannedAmount({
                                                                        value: el.planned_amount,
                                                                        id: el.id,
                                                                    });
                                                                }}
                                                                onChange={(e) => {
                                                                    let newValue = e.target.value.replace(
                                                                        /,/g,
                                                                        ""
                                                                    );
                                                                    let abc = newValue.replace(
                                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                                        ","
                                                                    );
                                                                    let formattedValue = abc.replace(
                                                                        /[^\d,]+/g,
                                                                        ""
                                                                    );
                                                                    setPlannedAmount(formattedValue);
                                                                    setPaymentsData((old) => {
                                                                        let s = old.map((item) => {
                                                                            if (item?.id === el?.id) {
                                                                                el.planned_amount = formattedValue;
                                                                            }
                                                                            return item;
                                                                        });
                                                                        return s;
                                                                    });
                                                                }}
                                                            />
                                                            <div className="plannedProject__table-editBtn">
                                                                <AxelButton
                                                                    handler={() => {
                                                                        setCurrentPlannedAmount(null);
                                                                        updateProjectPayments(el);
                                                                    }}
                                                                    text={translations?.['payments.planned_project_payments.actions.apply']}
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                            </div>
                                            <div
                                                className={`plannedProject__month-box ${
                                                    !el?.editable
                                                        ? "plannedProject__month-box_disable"
                                                        : "plannedProject__month-box_normal"
                                                }`}
                                            >
                        <span>
                          $
                            {el?.actual_amount !== null
                                ? String(el?.actual_amount).replace(
                                    /(\d)(?=(\d{3})+([^\d]|$))/g,
                                    "$1,"
                                )
                                : "0"}
                        </span>

                                                {currentId?.type === "actual" &&
                                                    currentId?.id === el?.id &&
                                                    el?.edit && (
                                                        <div className="plannedProject__month-box-edit">
                                                            <input
                                                                autoFocus={true}
                                                                type={"text"}
                                                                value={
                                                                    actualAmount
                                                                        ? actualAmount?.toLocaleString()
                                                                        : el?.actual_amount
                                                                }
                                                                onChange={(e) => {
                                                                    let newValue = e.target.value.replace(
                                                                        /,/g,
                                                                        ""
                                                                    );
                                                                    let abc = newValue.replace(
                                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                                        ","
                                                                    );
                                                                    let formattedValue = abc.replace(
                                                                        /[^\d,]+/g,
                                                                        ""
                                                                    );
                                                                    setActualAmount(formattedValue);
                                                                }}
                                                            />
                                                            <div className="plannedProject__table-editBtn">
                                                                <AxelButton
                                                                    handler={() => updateProjectPayments(el)}
                                                                    text={translations?.['payments.planned_project_payments.actions.apply']}
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                            </div>
                                            <div
                                                className={`plannedProject__month-box ${
                                                    !el?.editable
                                                        ? "plannedProject__month-box_disable"
                                                        : "plannedProject__month-box_normal"
                                                }`}
                                            >
                        <span>
                          $
                            {el?.cumulative_planned_amount !== null
                                ? String(el?.cumulative_planned_amount).replace(
                                    /(\d)(?=(\d{3})+([^\d]|$))/g,
                                    "$1,"
                                )
                                : "0"}
                        </span>
                                            </div>
                                            <div
                                                className={`plannedProject__month-box ${
                                                    !el?.editable
                                                        ? "plannedProject__month-box_disable"
                                                        : "plannedProject__month-box_normal"
                                                }`}
                                            >
                        <span>
                          $
                            {el?.cumulative_actual_amount !== null
                                ? String(el?.cumulative_actual_amount).replace(
                                    /(\d)(?=(\d{3})+([^\d]|$))/g,
                                    "$1,"
                                )
                                : "0"}
                        </span>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="plannedProject__total">
                            <div className="plannedProject__total-block">
                                <p className="plannedProject__total-text">
                                    {translations?.['payments.planned_project_payments.titles.cumulative_planned_project_payments_amount']}
                                </p>
                                <span>
                                     ${String(data?.planned_project_payments?.total_planned).replace(
                                         /(\d)(?=(\d{3})+([^\d]|$))/g,
                                         "$1,"
                                     ) ?? "0"}
                                </span>
                            </div>
                            <div className="plannedProject__total-block">
                                <p className="plannedProject__total-text">
                                    {translations?.['payments.planned_project_payments.titles.cumulative_actual_project_payments_amount']}
                                </p>
                                <span>
                                      ${String(data?.planned_project_payments?.total_actual).replace(
                                          /(\d)(?=(\d{3})+([^\d]|$))/g,
                                          "$1,"
                                      ) ?? "0"}
                                </span>
                            </div>
                        </div>
                        <div className="project__block-subtitle mt-10">{translations?.['payments.actual_versus_planned_project_payments.headers.actual_versus_planned_project_payments']}</div>
                        {paymentsList?.length !== 0 ? (
                            <VersusChart
                                reportId={data?.latest_report?.id}
                                id={data?.id}
                                data={data}
                                translations={translations}
                                isPage={true}
                            />
                        ) : (
                            <div className="plannedProject__placeholder">
                                <img src="/assets/icons/planned_chart.svg" alt="" />
                                There is no Data yet
                            </div>
                        )}
                    </div>
                </>
            ) : null}
            {data?.project_settings?.subcontractor_payments ? (
                <SubcontractorPayments glossaryData={glossaryData} data={data} translations={translations}/>
            ) : null}
        </div>
    );
};
